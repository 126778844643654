import React, { useState, useEffect, useRef } from 'react'
import "./ListNewsStats.css"
import { httpGetListNews } from '../../../../services/requestService'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { translateFormatTimestamp } from '../../../calendarEvents/calendar/Calendar';
import { INewsLetter } from '../../../news/newsLetter/NewsLetter';
import GeneralServices from '../../../../services/generalService.json'


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteNewsModal, { IDeleteNews } from '../deleteNewsModal/DeleteNewsModal';
import EditNewsModal, { IEditNews } from '../editNewsModal/EditNewsModal';
import SnackKekax from '../../../kekax/SnackKekax';

interface IProps extends StoreProps {

}

interface SnackKekax {
    open: boolean,
    message: string
}


export const ListNewsStats: React.FC<IProps> = (props) => {

    const [newsArray, setNewsArray] = useState<INewsLetter[]>([]);
    const [totalNews, setTotalNews] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const [deleteModal, setDeleteModal] = useState<IDeleteNews>({ open: false, news: '', newsId: '' });
    const [editModal, setEditModal] = useState<IEditNews>({ open: false, newsId: "" });//KEKAXEDIT - false

    const maxNewsArray: number = 10;
    const maxPagesArray: number = 5;
    const snackKekaxRef: any = useRef();


    useEffect(() => {

        httpGetListNews(null).then(
            res => {
                //SETTING TOTAL NEWS
                if (res.headers.total !== undefined) {
                    setTotalNews(parseInt(res.headers.total, 10));
                } else {
                    setTotalNews(parseInt('1', 10));
                }
                setNewsArray([...res.data]);
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }, [])


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalNews / maxNewsArray);
        const extra = totalNews % maxNewsArray;

        if (extra !== 0 || totalNews === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalNews])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, scroll: boolean) => {
        const queryPage = page - 1;
        httpGetListNews('?page=' + queryPage).then(
            res => {
                setNewsArray([...res.data]);
                if (scroll) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }

            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }


    const deleteNewsHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageNews.delete.success)
            setTotalNews((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageNews.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal({ ...deleteModal, open: false });
        } else {
            setDeleteModal({ ...deleteModal, open: !deleteModal.open });
        }
        loadNewPage(counter, false)
    }


    const editNewsHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setEditModal({ ...editModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageNews.edit.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageNews.edit.failed)
            setEditModal({ ...editModal, open: false });
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal({ ...editModal, open: false });
        } else {
            setEditModal({ ...editModal, open: !editModal.open });
        }
        loadNewPage(counter, false)
    }





    return (
        <React.Fragment>
            <div className="wrapper-list-news-stats">
                <div className="list-news-stats-header">

                </div>
                <div className="list-news-stats-body">
                    {
                        newsArray.map((news: INewsLetter, index: number) => (
                            <div className="news-stats-object" key={index}>
                                <div className="news-stats-object-union">
                                    <div className="news-stats-object-content">
                                        <span className="news-object-title">{news.title}</span>
                                        <div className="news-object-date">
                                            <span className="news-object-datestart">{translateFormatTimestamp(news.timestamp, props.appLanguage)}</span>
                                        </div>
                                    </div>
                                    <div className="news-stats-object-info">
                                        <div className="object-info-slice">
                                            <span className="info-slice-title">{props.appLanguage.backoffice.manageNews.views}</span>
                                            <span className="info-slice-content">{news.views}</span>
                                        </div>
                                    </div>

                                    <div className="news-stats-object-menu">
                                        <div className="object-menu-opt opt-delete" onClick={() => setDeleteModal({ open: true, news: news.title, newsId: news.id })}>
                                            <DeleteIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageNews.delete.option}</span>
                                        </div>
                                        <div className="object-menu-opt opt-edit" onClick={() => setEditModal({ open: true, newsId: news.id })}>
                                            <EditIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageNews.edit.option}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
                <div className="list-news-stats-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>
            </div>
            <DeleteNewsModal news={deleteModal} callback={deleteNewsHandler} />
            <EditNewsModal news={editModal} callback={editNewsHandler} />


            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(ListNewsStats);

