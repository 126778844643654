import React, { useEffect } from 'react';
import "./NoMatch.css"
import GeneralServices from '../../services/generalService.json'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';


interface IProps extends RouteComponentProps<any>, StoreProps { }


export const NoMatch: React.FC<IProps> = (props) => {

    useEffect(() => {
        props.history.push(GeneralServices.homePath)

    }, [])

    return (
        <div className="wrapper-nomatch">
            <p>404 octopus</p>
        </div>
    );

}


export default connect(mapStateToProps)(withRouter(NoMatch));
