import React, { useEffect, useRef } from "react";
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import './InfoApp.css'
import { connect } from "react-redux";

interface IProps extends StoreProps {
    myRef: any;
    triggerAnime: any;
}



export const InfoApp: React.FC<IProps> = (props) => {


    const myref = useRef<any>(null);

    useEffect(() => {
        props.myRef(myref.current);
    }, [])




    //animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ opacity: { duration: 2 } }}
    //animate={{ opacity: shouldShowActions ? 1 : 0 }} initial={{ opacity: 0 }} transition={{ opacity: { duration: 2 } }} 
    return (
        <div className="wrapper-infoapp" ref={myref} >
            {
                props.triggerAnime ?
                    <div className="container-info">
                        <div
                            className="info-img"
                        >
                        </div>

                        <div className="info-content">
                            <span className='text-title'>{props.appLanguage.infoApp.title}</span>
                            <span className='text-description'>{props.appLanguage.infoApp.description}</span>
                            <span className='text-author'>{props.appLanguage.infoApp.author}</span>

                        </div>

                    </div>
                    : null
            }
        </div >
    );

}

export default connect(mapStateToProps)(InfoApp)