import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mineStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddMine.css';

import GeneralServices from "../../../../services/generalService.json"
import SnackKekax from '../../../kekax/SnackKekax';
import ReactMapGL, { Marker } from 'react-map-gl';
import { IMap, mapToken } from '../../../mineMap/MineMap';
import { alphabet } from '../../../game/quizPage/questionPage/QuestionPage';
import TooltipKekax from '../../../kekax/TooltipKekax';
import FacilitiesInfoAdd, { IFormErrorFacilitiesInfo, IFormFocusFacilitiesInfo, IFormInputFacilitiesInfo, IFormValidFacilitiesInfo } from './facilitiesInfoAdd/FacilitiesInfoAdd';
import MineProductionAdd, { IFormErrorMineProductions, IFormFocusMineProductions, IFormInputMineProductions, IFormValidMineProductions } from './mineProductionAdd/MineProductionAdd';
import LegendsHistoryEventsAdd, { IFormErrorLegendsHistoryEvents, IFormFocusLegendsHistoryEvents, IFormInputLegendsHistoryEvents, IFormValidLegendsHistoryEvents } from './legendsHistoryEventsAdd/LegendsHistoryEventsAdd';
import BibliographyAdd, { IFormErrorBibliography, IFormFocusBibliography, IFormInputBibliography, IFormValidBibliography } from './bibliographyAdd/BibliographyAdd';
import StepperKekax from '../../../kekax/StepperKekax';
import { httpPostMineInventory, httpPostMineFacility, httpPostMineSiteLegends, httpPostMineProduction, httpPostMineBibliography, httpGetAuxCountryAll, httpGetAuxFacilityTypeAll, httpGetAuxMineTypeAll, httpGetAuxOfficialLanguageAll, httpGetAuxSituationAll, httpGetAuxCategoriesAll, httpPostMineQuestion, httpPostMineSiteHistoricEvents } from '../../../../services/requestService'
import { IFormInputAuxCountry } from '../auxCountry/addAuxCountry/AddAuxCountry';
import { IFormInputAuxFacilityType } from '../auxFacilityType/addAuxFacilityType/AddAuxFacilityType';
import { IFormInputAuxMineTypes } from '../auxMineTypes/addAuxMineTypes/AddAuxMineTypes';
import { IFormInputAuxOfficialLanguages } from '../auxOfficialLanguages/addAuxOfficialLanguages/AddAuxOfficialLanguages';
import { IFormInputAuxSituation } from '../auxSituation/addAuxSituation/AddAuxSituation';
import { IFormInputAuxCategories } from '../auxCategories/addAuxCategories/AddAuxCategories';

interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorMine {

    //inventory
    mineCodeError: string
    websiteError: string
    siteNameENGError: string
    siteNameNOLError: string
    countryError: string
    regionStateProvinceError: string
    municipalityError: string
    touristicnameError: string
    minePopDescError: string
    complexError: string
    complexNameError: string
    mineHeritageRouteError: string
    projectFigError: string
    infoFigureError: string
    carParkError: string
    cafeteriaError: string
    giftShopError: string
    addressError: string
    address2Error: string
    postalCodeError: string
    phoneError: string
    mailError: string
    otherInfoContError: string
    pubTransportError: string
    pubTransportDescError: string



    roundsError: IFormRoundError[]

}
export interface IFormInputMine {

    //inventory
    mineCode: string
    lat: any
    lon: any
    website: string
    siteNameENG: string
    siteNameNOL: string
    country: string
    regionStateProvince: string
    municipality: string
    touristicname: string
    minePopDesc: string
    complex: string
    complexName: string
    mineHeritageRoute: string
    projectFig: string
    infoFigure: string
    carPark: string
    cafeteria: string
    giftShop: string
    address: string
    address2: string
    postalCode: string
    phone: string
    mail: string
    otherInfoCont: string
    pubTransport: string
    pubTransportDesc: string
    //---------inventory

    rounds: IFormRound[]
}

export interface IFormValidMine {
    mineCodeValid: boolean
    websiteValid: boolean
    siteNameENGValid: boolean
    siteNameNOLValid: boolean
    countryValid: boolean
    regionStateProvinceValid: boolean
    municipalityValid: boolean
    touristicnameValid: boolean
    minePopDescValid: boolean
    complexValid: boolean
    complexNameValid: boolean
    mineHeritageRouteValid: boolean
    projectFigValid: boolean
    infoFigureValid: boolean
    carParkValid: boolean
    cafeteriaValid: boolean
    giftShopValid: boolean
    addressValid: boolean
    address2Valid: boolean
    postalCodeValid: boolean
    phoneValid: boolean
    mailValid: boolean
    otherInfoContValid: boolean
    pubTransportValid: boolean
    pubTransportDescValid: boolean


    roundsValid: IFormRoundValid[]
    pointValid: boolean
}

export interface IFormFocusMine {
    mineCodeFocus: boolean
    websiteFocus: boolean
    siteNameENGFocus: boolean
    siteNameNOLFocus: boolean
    countryFocus: boolean
    regionStateProvinceFocus: boolean
    municipalityFocus: boolean
    touristicnameFocus: boolean
    minePopDescFocus: boolean
    complexFocus: boolean
    complexNameFocus: boolean
    mineHeritageRouteFocus: boolean
    projectFigFocus: boolean
    infoFigureFocus: boolean
    carParkFocus: boolean
    cafeteriaFocus: boolean
    giftShopFocus: boolean
    addressFocus: boolean
    address2Focus: boolean
    postalCodeFocus: boolean
    phoneFocus: boolean
    mailFocus: boolean
    otherInfoContFocus: boolean
    pubTransportFocus: boolean
    pubTransportDescFocus: boolean

    roundsFocus: IFormRoundFocus[]
}

export interface IFormRound {
    question: string
    correct: string
    answers: string[]
    status: number
}

export interface IFormRoundValid {
    questionValid: boolean
    correctValid: boolean
    answersValid: boolean[]

}

export interface IFormRoundError {
    questionError: string
    correctError: string
    answersError: string[]
}

export interface IFormRoundFocus {
    questionFocus: boolean
    correctFocus: boolean
    answersFocus: boolean[]
}

export interface IMineValidationSteps {
    inventory: boolean
    quiz: boolean
    facilities: boolean
    mineProductions: boolean
}


export interface FlagOption {
    id: number
    tag: string
    shortNameENG: string
    shortNameNOL: string
    officialName: string
    flag: any


}


//Site
const FORMMINECODEID: string = 'mineCode';
const FORMWEBSITEID: string = 'website';
const FORMSITENAMEENGID: string = 'siteNameENG;'
const FORMSITENAMENOLID: string = 'siteNameNOL';
const FORMCOUNTRYID: string = 'country';
const FORMREGIONSTATEPROVINCEID: string = 'regionStateProvince'
const FORMMUNICIPALITYID: string = 'municipality'
const FORMTOURISTICNAMEID: string = 'touristicname'
const FORMMINEPOPDESCID: string = 'minePopDesc'
const FORMCOMPLEXID: string = 'complex'
const FORMCOMPLEXNAMEID: string = 'complexName'
const FORMMINEHERITAGEROUTEID: string = 'mineHeritageRoute'
const FORMPROJECTFIGID: string = 'projectFig'
const FORMINFOFIGUREID: string = 'infoFigure'
const FORMCARPARKID: string = 'carPark'
const FORMCAFETARIAID: string = 'cafeteria'
const FORMGIFTSHOPID: string = 'giftShop'
const FORMADDRESSID: string = 'address'
const FORMADDRESS2ID: string = 'address2'
const FORMPOSTALCODEID: string = 'postalCode'
const FORMPHONEID: string = 'phone'
const FORMMAILID: string = 'mail'
const FORMOTHERINFOCONTID: string = 'otherInfoCont'
const FORMPUBTRANSPORTID: string = 'pubTransport'
const FORMPUBTRANSPORTDESCID: string = 'pubTransportDesc'

//Quiz
const FORMROUNDID: string = 'round';
const FORMQUESTIONID: string = 'question';
const FORMANSWERID: string = 'answer';



const MAX_STEPS = 5


export const AddMine: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<IMineValidationSteps>({ inventory: false, facilities: false, mineProductions: false, quiz: false, });
    const [facilitiesInfoValid, setFacilitiesInfoValid] = useState<boolean>(true)
    const [mineProductionsValid, setMineProductionsValid] = useState<boolean>(true)
    const [legendsHistoryEventsValid, setLegendsHistoryEventsValid] = useState<boolean>(true)
    const [bibliographyValid, setBibliographyValid] = useState<boolean>(true)

    const [formError, setFormError] = useState<IFormErrorMine>({
        mineCodeError: '', websiteError: '', siteNameENGError: '', siteNameNOLError: '', countryError: '',
        regionStateProvinceError: '', municipalityError: '', touristicnameError: '', minePopDescError: '', complexError: '', complexNameError: '', mineHeritageRouteError: ''
        , projectFigError: '', infoFigureError: '', carParkError: '', cafeteriaError: '', giftShopError: '', addressError: '', address2Error: '', postalCodeError: '', phoneError: '',
        mailError: '', otherInfoContError: '', pubTransportError: '', pubTransportDescError: '',
        roundsError: []
    });
    const [formInput, setFormInput] = useState<IFormInputMine>({
        lat: null, lon: null, mineCode: '', website: '', siteNameENG: '', siteNameNOL: '', country: '',
        regionStateProvince: '', municipality: '', touristicname: '', minePopDesc: '', complex: '', complexName: '', mineHeritageRoute: '', projectFig: '', infoFigure: '',
        carPark: '', cafeteria: '', giftShop: '', address: '', address2: '', postalCode: '', phone: '', mail: '', otherInfoCont: '', pubTransport: '', pubTransportDesc: '',
        rounds: []
    });
    const [formValid, setFormValid] = useState<IFormValidMine>({
        mineCodeValid: false, websiteValid: false, siteNameENGValid: false, siteNameNOLValid: false, countryValid: false,
        regionStateProvinceValid: false, municipalityValid: false, touristicnameValid: false, minePopDescValid: false, complexValid: false, complexNameValid: false,
        mineHeritageRouteValid: false, projectFigValid: false, infoFigureValid: false, carParkValid: false, cafeteriaValid: false, giftShopValid: false, addressValid: false,
        address2Valid: false, postalCodeValid: false, phoneValid: false, mailValid: false, otherInfoContValid: false, pubTransportValid: false, pubTransportDescValid: false,
        roundsValid: [], pointValid: false
    });

    const [formFocus, setFormFocus] = useState<IFormFocusMine>({
        mineCodeFocus: false, websiteFocus: false, siteNameENGFocus: false, siteNameNOLFocus: false, countryFocus: false,
        regionStateProvinceFocus: false, municipalityFocus: false, touristicnameFocus: false, minePopDescFocus: false, complexFocus: false, complexNameFocus: false,
        mineHeritageRouteFocus: false, projectFigFocus: false, infoFigureFocus: false, carParkFocus: false, cafeteriaFocus: false, giftShopFocus: false, addressFocus: false,
        address2Focus: false, postalCodeFocus: false, phoneFocus: false, mailFocus: false, otherInfoContFocus: false, pubTransportFocus: false, pubTransportDescFocus: false,
        roundsFocus: []
    });

    const [formInputFacilitiesInfo, setFormInputFacilitiesInfo] = useState<IFormInputFacilitiesInfo[]>([]);
    const [formErrorFacilitiesInfo, setFormErrorFacilitiesInfo] = useState<IFormErrorFacilitiesInfo[]>([]);
    const [formValidFacilitiesInfo, setFormValidFacilitiesInfo] = useState<IFormValidFacilitiesInfo[]>([]);
    const [formFocusFacilitiesInfo, setFormFocusFacilitiesInfo] = useState<IFormFocusFacilitiesInfo[]>([]);

    const [formInputMineProductions, setFormInputMineProductions] = useState<IFormInputMineProductions[]>([]);
    const [formErrorMineProductions, setFormErrorMineProductions] = useState<IFormErrorMineProductions[]>([]);
    const [formValidMineProductions, setFormValidMineProductions] = useState<IFormValidMineProductions[]>([]);
    const [formFocusMineProductions, setFormFocusMineProductions] = useState<IFormFocusMineProductions[]>([]);

    const [formInputLegendsHistoryEvents, setFormInputLegendsHistoryEvents] = useState<IFormInputLegendsHistoryEvents>({
        idSiteLegends: "", idSiteHistoricEvents: "",
        timePeriodLegend: "",
        storyLegendDesc: "", datePeriodHistoricRecord: "", historyEventDesc: ""
    });
    const [formErrorLegendsHistoryEvents, setFormErrorLegendsHistoryEvents] = useState<IFormErrorLegendsHistoryEvents>({
        timePeriodLegendError: "",
        storyLegendDescError: "", datePeriodHistoricRecordError: "", historyEventDescError: ""
    });
    const [formValidLegendsHistoryEvents, setFormValidLegendsHistoryEvents] = useState<IFormValidLegendsHistoryEvents>({
        timePeriodLegendValid: false,
        storyLegendDescValid: false, datePeriodHistoricRecordValid: false, historyEventDescValid: false
    });
    const [formFocusLegendsHistoryEvents, setFormFocusLegendsHistoryEvents] = useState<IFormFocusLegendsHistoryEvents>({
        timePeriodLegendFocus: false,
        storyLegendDescFocus: false, datePeriodHistoricRecordFocus: false, historyEventDescFocus: false
    });


    const [formInputBibliography, setFormInputBibliography] = useState<IFormInputBibliography>(
        {
            id:"",
            mineCode: "",
            bibliographicalResource: "",
            webInfoResource: "",
            otherBibliographicalInfo: ""
        });
    const [formErrorBibliography, setFormErrorBibliography] = useState<IFormErrorBibliography>(
        {
            mineCodeError: "",
            bibliographicalResourceError: "",
            webInfoResourceError: "",
            otherBibliographicalInfoError: ""
        }
    );
    const [formValidBibliography, setFormValidBibliography] = useState<IFormValidBibliography>(
        {
            mineCodeValid: false,
            bibliographicalResourceValid: false,
            webInfoResourceValid: false,
            otherBibliographicalInfoValid: false
        }
    );
    const [formFocusBibliography, setFormFocusBibliography] = useState<IFormFocusBibliography>(
        {
            mineCodeFocus: false,
            bibliographicalResourceFocus: false,
            webInfoResourceFocus: false,
            otherBibliographicalInfoFocus: false
        }
    );


    const formRef = useRef<any>(null);
    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);

    const [viewport, setViewport] = useState<IMap>({ longitude: 0, latitude: 0, zoom: 0 });
    const mapRef = useRef(null)

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [flagsDropdown, setFlagsDropdown] = useState({ open: false, class: 'flags-menu-drop' });
    const refFlagsDropdownMenu = useRef<any>(null);
    const refFlagsDropdownButton = useRef<any>(null);
    //const [flagsArrayAll, setFlagsArrayAll] = useState<FlagOption[]>([])

    const [countryAuxArray, setCountryAuxArray] = useState<IFormInputAuxCountry[]>([])
    const [facilityTypeAuxArray, setFacilityTypeAuxArray] = useState<IFormInputAuxFacilityType[]>([])
    const [mineTypeAuxArray, setMineTypeAuxArray] = useState<IFormInputAuxMineTypes[]>([])
    const [officialLanguageAuxArray, setOfficialLanguageAuxArray] = useState<IFormInputAuxOfficialLanguages[]>([])
    const [situationeAuxArray, setSituationAuxArray] = useState<IFormInputAuxSituation[]>([])
    const [categoriesAuxArray, setCategoriesAuxArray] = useState<IFormInputAuxCategories[]>([])


    useEffect(() => {
        setViewport({
            longitude: 3.4834177590148587,
            latitude: 43.59630686976215,
            zoom: 5
        })
        //setFormInput({ ...formInput, rounds: [{ question: "", correct: "", answers: ["", ""] }] })
        document.addEventListener('mousedown', handleEventListeners);
        window.addEventListener('resize', handleEventListeners);
        /*
        let countries: FlagOption[] = []
        for (let i = 0; i < GeneralServices.countries.length; i++) {
            countries.push({
                id: parseInt(GeneralServices.countries[i].id),
                tag: GeneralServices.countries[i].tag,
                shortNameENG: GeneralServices.countries[i].shortNameENG,
                shortNameNOL: GeneralServices.countries[i].shortNameNOL,
                officialName: GeneralServices.countries[i].officialName,
                flag: require('../../../../assets/flags/' + GeneralServices.countries[i].tag + '.png')

            })
            if (i === GeneralServices.countries.length - 1) {
                setFlagsArrayAll([...countries])
            }
        }
        */
        httpGetAuxCountryAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setCountryAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )

        httpGetAuxFacilityTypeAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                let bb: IFormInputAuxFacilityType[] = []

                aa.forEach(single => {
                    bb.push({ facilityType: single.faciType })
                })

                setFacilityTypeAuxArray([...bb])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxMineTypeAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setMineTypeAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxOfficialLanguageAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setOfficialLanguageAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxSituationAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setSituationAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxCategoriesAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setCategoriesAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )

        return () => {
            document.removeEventListener('mousedown', handleEventListeners);
            window.removeEventListener('resize', handleEventListeners);
        }
    }, [])


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);



    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {

                    if (refFlagsDropdownMenu.current && refFlagsDropdownButton.current && !refFlagsDropdownMenu.current.contains(event.target) && !refFlagsDropdownButton.current.contains(event.target)) {
                        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    }

                    /*
                    if (event.target.contains(overlayRef.current)) {
                        closeModalHandler(GeneralServices.constants.child.closed);
                    }
                    */
                    break
                }
                case 'resize': {
                    setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    break
                }
                default: {

                }
            }
        }



    }



    const closeAddEventHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid({ inventory: false, quiz: false, mineProductions: false, facilities: false });
        setFormError({
            mineCodeError: '', websiteError: '', siteNameENGError: '', siteNameNOLError: '', countryError: '',
            regionStateProvinceError: '', municipalityError: '', touristicnameError: '', minePopDescError: '', complexError: '', complexNameError: '', mineHeritageRouteError: ''
            , projectFigError: '', infoFigureError: '', carParkError: '', cafeteriaError: '', giftShopError: '', addressError: '', address2Error: '', postalCodeError: '', phoneError: '',
            mailError: '', otherInfoContError: '', pubTransportError: '', pubTransportDescError: '',
            roundsError: []
        });
        setFormInput({
            lat: null, lon: null, mineCode: '', website: '', siteNameENG: '', siteNameNOL: '', country: '',
            regionStateProvince: '', municipality: '', touristicname: '', minePopDesc: '', complex: '', complexName: '', mineHeritageRoute: '', projectFig: '', infoFigure: '',
            carPark: '', cafeteria: '', giftShop: '', address: '', address2: '', postalCode: '', phone: '', mail: '', otherInfoCont: '', pubTransport: '', pubTransportDesc: '',
            rounds: []
        })
        setFormValid({
            mineCodeValid: false, websiteValid: false, siteNameENGValid: false, siteNameNOLValid: false, countryValid: false,
            regionStateProvinceValid: false, municipalityValid: false, touristicnameValid: false, minePopDescValid: false, complexValid: false, complexNameValid: false,
            mineHeritageRouteValid: false, projectFigValid: false, infoFigureValid: false, carParkValid: false, cafeteriaValid: false, giftShopValid: false, addressValid: false,
            address2Valid: false, postalCodeValid: false, phoneValid: false, mailValid: false, otherInfoContValid: false, pubTransportValid: false, pubTransportDescValid: false,
            roundsValid: [], pointValid: false
        });
        setFormFocus({
            mineCodeFocus: false, websiteFocus: false, siteNameENGFocus: false, siteNameNOLFocus: false, countryFocus: false,
            regionStateProvinceFocus: false, municipalityFocus: false, touristicnameFocus: false, minePopDescFocus: false, complexFocus: false, complexNameFocus: false,
            mineHeritageRouteFocus: false, projectFigFocus: false, infoFigureFocus: false, carParkFocus: false, cafeteriaFocus: false, giftShopFocus: false, addressFocus: false,
            address2Focus: false, postalCodeFocus: false, phoneFocus: false, mailFocus: false, otherInfoContFocus: false, pubTransportFocus: false, pubTransportDescFocus: false,
            roundsFocus: []
        })
        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })

        setTempDisabled(0)
        setSelectedStepper("0");

        setFormInputFacilitiesInfo([])
        setFormErrorFacilitiesInfo([])
        setFormValidFacilitiesInfo([])
        setFormFocusFacilitiesInfo([])

        setFormInputMineProductions([])
        setFormErrorMineProductions([])
        setFormValidMineProductions([])
        setFormFocusMineProductions([])

        setFormInputLegendsHistoryEvents({
            idSiteLegends: "", idSiteHistoricEvents: "",
            timePeriodLegend: "",
            storyLegendDesc: "", datePeriodHistoricRecord: "", historyEventDesc: ""
        })
        setFormErrorLegendsHistoryEvents({
            timePeriodLegendError: "",
            storyLegendDescError: "", datePeriodHistoricRecordError: "", historyEventDescError: ""
        })
        setFormValidLegendsHistoryEvents({
            timePeriodLegendValid: false,
            storyLegendDescValid: false, datePeriodHistoricRecordValid: false, historyEventDescValid: false
        })
        setFormFocusLegendsHistoryEvents({
            timePeriodLegendFocus: false,
            storyLegendDescFocus: false, datePeriodHistoricRecordFocus: false, historyEventDescFocus: false
        })

        setFormInputBibliography({
            id:"",
            mineCode: "",
            bibliographicalResource: "",
            webInfoResource: "",
            otherBibliographicalInfo: ""
        })
        setFormErrorBibliography({
            mineCodeError: "",
            bibliographicalResourceError: "",
            webInfoResourceError: "",
            otherBibliographicalInfoError: ""
        })
        setFormValidBibliography({
            mineCodeValid: false,
            bibliographicalResourceValid: false,
            webInfoResourceValid: false,
            otherBibliographicalInfoValid: false
        })
        setFormFocusBibliography({
            mineCodeFocus: false,
            bibliographicalResourceFocus: false,
            webInfoResourceFocus: false,
            otherBibliographicalInfoFocus: false
        })


        //setFlagsArrayAll([])
        /*
        setCountryAuxArray([])
        setFacilityTypeAuxArray([])
        setMineTypeAuxArray([])
        setOfficialLanguageAuxArray([])
        setSituationAuxArray([])
        setCategoriesAuxArray([])
        */

        props.cbMessage(what);

    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, indexRound: any, indexAnswer: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {

            /*INVENTORY*/
            case FORMMINECODEID: {
                setFormInput({ ...formInput, mineCode: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            case FORMSITENAMEENGID: {
                setFormInput({ ...formInput, siteNameENG: value })
                break;
            }
            case FORMSITENAMENOLID: {
                setFormInput({ ...formInput, siteNameNOL: value })
                break;
            }
            case FORMCOUNTRYID: {
                setFormInput({ ...formInput, country: value })
                break;
            }
            case FORMREGIONSTATEPROVINCEID: {
                setFormInput({ ...formInput, regionStateProvince: value })
                break;
            }
            case FORMMUNICIPALITYID: {
                setFormInput({ ...formInput, municipality: value })
                break;
            }
            case FORMTOURISTICNAMEID: {
                setFormInput({ ...formInput, touristicname: value })
                break;
            }
            case FORMMINEPOPDESCID: {
                setFormInput({ ...formInput, minePopDesc: value })
                break;
            }
            case FORMCOMPLEXID: {
                setFormInput({ ...formInput, complex: value })
                break;
            }
            case FORMCOMPLEXNAMEID: {
                setFormInput({ ...formInput, complexName: value })
                break;
            }
            case FORMMINEHERITAGEROUTEID: {
                setFormInput({ ...formInput, mineHeritageRoute: value })
                break;
            }
            case FORMPROJECTFIGID: {
                setFormInput({ ...formInput, projectFig: value })
                break;
            }
            case FORMINFOFIGUREID: {
                setFormInput({ ...formInput, infoFigure: value })
                break;
            }
            case FORMCARPARKID: {
                setFormInput({ ...formInput, carPark: value })
                break;
            }
            case FORMCAFETARIAID: {
                setFormInput({ ...formInput, cafeteria: value })
                break;
            }
            case FORMGIFTSHOPID: {
                setFormInput({ ...formInput, giftShop: value })
                break;
            }
            case FORMADDRESSID: {
                setFormInput({ ...formInput, address: value })
                break;
            }
            case FORMADDRESS2ID: {
                setFormInput({ ...formInput, address2: value })
                break;
            }
            case FORMPOSTALCODEID: {
                setFormInput({ ...formInput, postalCode: value })
                break;
            }
            case FORMPHONEID: {
                setFormInput({ ...formInput, phone: value })
                break;
            }
            case FORMMAILID: {
                setFormInput({ ...formInput, mail: value })
                break;
            }
            case FORMOTHERINFOCONTID: {
                setFormInput({ ...formInput, otherInfoCont: value })
                break;
            }
            case FORMPUBTRANSPORTID: {
                setFormInput({ ...formInput, pubTransport: value })
                break;
            }
            case FORMPUBTRANSPORTDESCID: {
                setFormInput({ ...formInput, pubTransportDesc: value })
                break;
            }
            /*----INVENTORY*/

            case FORMROUNDID + indexRound + FORMQUESTIONID: {

                let copyQuiz = [...formInput.rounds]
                copyQuiz[indexRound].question = value
                setFormInput({ ...formInput, rounds: [...copyQuiz] })
                break;
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                let copyQuiz = [...formInput.rounds]
                copyQuiz[indexRound].answers[indexAnswer] = value
                setFormInput({ ...formInput, rounds: [...copyQuiz] })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;

        let upd_mineCodeValid = formValid.mineCodeValid;
        if (formInput.mineCode === '') {
            upd_mineCodeValid = false;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mineCodeValid = true;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.mineCodeAccept;
        }

        let upd_websiteValid = formValid.websiteValid;
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
        }

        let upd_siteNameENGValid = formValid.siteNameENGValid;
        if (formInput.siteNameENG === '') {
            upd_siteNameENGValid = false;
            fieldValidationErrors.siteNameENGError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_siteNameENGValid = true;
            fieldValidationErrors.siteNameENGError = props.appLanguage.backoffice.manageMines.add.siteNameENGAccept;
        }

        let upd_siteNameNOLValid = formValid.siteNameNOLValid;
        if (formInput.siteNameNOL === '') {
            upd_siteNameNOLValid = false;
            fieldValidationErrors.siteNameNOLError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_siteNameNOLValid = true;
            fieldValidationErrors.siteNameNOLError = props.appLanguage.backoffice.manageMines.add.siteNameNOLAccept;
        }

        let upd_countryValid = formValid.countryValid;
        if (formInput.country === '') {
            upd_countryValid = false;
            fieldValidationErrors.countryError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_countryValid = true;
            fieldValidationErrors.countryError = props.appLanguage.backoffice.manageMines.add.countryAccept;
        }

        let upd_regionStateProvinceValid = formValid.regionStateProvinceValid;
        if (formInput.regionStateProvince === '') {
            upd_regionStateProvinceValid = false;
            fieldValidationErrors.regionStateProvinceError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_regionStateProvinceValid = true;
            fieldValidationErrors.regionStateProvinceError = props.appLanguage.backoffice.manageMines.add.regionStateProvinceAccept;
        }

        let upd_municipalityValid = formValid.municipalityValid;
        if (formInput.municipality === '') {
            upd_municipalityValid = false;
            fieldValidationErrors.municipalityError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_municipalityValid = true;
            fieldValidationErrors.municipalityError = props.appLanguage.backoffice.manageMines.add.municipalityAccept;
        }

        let upd_touristicnameValid = formValid.touristicnameValid;
        if (formInput.touristicname === '') {
            upd_touristicnameValid = false;
            fieldValidationErrors.touristicnameError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_touristicnameValid = true;
            fieldValidationErrors.touristicnameError = props.appLanguage.backoffice.manageMines.add.touristicnameAccept;
        }

        let upd_minePopDescValid = formValid.minePopDescValid;
        if (formInput.minePopDesc === '') {
            upd_minePopDescValid = false;
            fieldValidationErrors.minePopDescError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_minePopDescValid = true;
            fieldValidationErrors.minePopDescError = props.appLanguage.backoffice.manageMines.add.minePopDescAccept;
        }

        let upd_complexValid = formValid.complexValid;
        if (formInput.complex === '') {
            upd_complexValid = false;
            fieldValidationErrors.complexError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_complexValid = true;
            fieldValidationErrors.complexError = props.appLanguage.backoffice.manageMines.add.complexAccept;
        }

        let upd_complexNameValid = formValid.complexNameValid;
        if (formInput.complexName === '') {
            upd_complexNameValid = false;
            fieldValidationErrors.complexNameError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_complexNameValid = true;
            fieldValidationErrors.complexNameError = props.appLanguage.backoffice.manageMines.add.complexNameAccept;
        }

        let upd_mineHeritageRouteValid = formValid.mineHeritageRouteValid;
        if (formInput.mineHeritageRoute === '') {
            upd_mineHeritageRouteValid = false;
            fieldValidationErrors.mineHeritageRouteError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mineHeritageRouteValid = true;
            fieldValidationErrors.mineHeritageRouteError = props.appLanguage.backoffice.manageMines.add.mineHeritageRouteAccept;
        }

        let upd_projectFigValid = formValid.projectFigValid;
        if (formInput.projectFig === '') {
            upd_projectFigValid = false;
            fieldValidationErrors.projectFigError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_projectFigValid = true;
            fieldValidationErrors.projectFigError = props.appLanguage.backoffice.manageMines.add.projectFigAccept;
        }

        let upd_infoFigureValid = formValid.infoFigureValid;
        if (formInput.infoFigure === '') {
            upd_infoFigureValid = false;
            fieldValidationErrors.infoFigureError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_infoFigureValid = true;
            fieldValidationErrors.infoFigureError = props.appLanguage.backoffice.manageMines.add.infoFigureAccept
        }

        let upd_carParkValid = formValid.carParkValid;
        if (formInput.carPark === '') {
            upd_carParkValid = false;
            fieldValidationErrors.carParkError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_carParkValid = true;
            fieldValidationErrors.carParkError = props.appLanguage.backoffice.manageMines.add.carParkAccept;
        }

        let upd_cafeteriaValid = formValid.cafeteriaValid;
        if (formInput.cafeteria === '') {
            upd_cafeteriaValid = false;
            fieldValidationErrors.cafeteriaError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_cafeteriaValid = true;
            fieldValidationErrors.cafeteriaError = props.appLanguage.backoffice.manageMines.add.cafeteriaAccept;
        }

        let upd_giftShopValid = formValid.giftShopValid;
        if (formInput.giftShop === '') {
            upd_giftShopValid = false;
            fieldValidationErrors.giftShopError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_giftShopValid = true;
            fieldValidationErrors.giftShopError = props.appLanguage.backoffice.manageMines.add.giftShopAccept;
        }


        let upd_addressValid = formValid.addressValid;
        if (formInput.address === '') {
            upd_addressValid = false;
            fieldValidationErrors.addressError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_addressValid = true;
            fieldValidationErrors.addressError = props.appLanguage.backoffice.manageMines.add.addressAccept;
        }

        let upd_address2Valid = formValid.address2Valid;
        if (formInput.address2 === '') {
            upd_address2Valid = false;
            fieldValidationErrors.address2Error = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_address2Valid = true;
            fieldValidationErrors.address2Error = props.appLanguage.backoffice.manageMines.add.address2Accept;
        }

        let upd_postalCodeValid = formValid.postalCodeValid;
        if (formInput.postalCode === '') {
            upd_postalCodeValid = false;
            fieldValidationErrors.postalCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_postalCodeValid = true;
            fieldValidationErrors.postalCodeError = props.appLanguage.backoffice.manageMines.add.postalCodeAccept;
        }

        let upd_phoneValid = formValid.phoneValid;
        if (formInput.phone === '') {
            upd_phoneValid = false;
            fieldValidationErrors.phoneError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_phoneValid = true;
            fieldValidationErrors.phoneError = props.appLanguage.backoffice.manageMines.add.phoneAccept;
        }

        let upd_mailValid = formValid.mailValid;
        if (formInput.mail === '') {
            upd_mailValid = false;
            fieldValidationErrors.mailError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mailValid = true;
            fieldValidationErrors.mailError = props.appLanguage.backoffice.manageMines.add.mailAccept;
        }

        let upd_otherInfoContValid = formValid.otherInfoContValid;
        if (formInput.otherInfoCont === '') {
            upd_otherInfoContValid = false;
            fieldValidationErrors.otherInfoContError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_otherInfoContValid = true;
            fieldValidationErrors.otherInfoContError = props.appLanguage.backoffice.manageMines.add.otherInfoContAccept;
        }

        let upd_pubTransportValid = formValid.pubTransportValid;
        if (formInput.pubTransport === '') {
            upd_pubTransportValid = false;
            fieldValidationErrors.pubTransportError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_pubTransportValid = true;
            fieldValidationErrors.pubTransportError = props.appLanguage.backoffice.manageMines.add.pubTransportAccept;
        }

        let upd_pubTransportDescValid = formValid.pubTransportDescValid;
        if (formInput.pubTransportDesc === '') {
            upd_pubTransportDescValid = false;
            fieldValidationErrors.pubTransportDescError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_pubTransportDescValid = true;
            fieldValidationErrors.pubTransportDescError = props.appLanguage.backoffice.manageMines.add.pubTransportDescAccept;
        }


        let upd_roundsValid = formValid.roundsValid;
        for (let i = 0; i < upd_roundsValid.length && upd_roundsValid.length === formInput.rounds.length; i++) {
            if (formInput.rounds[i].question === '') {
                upd_roundsValid[i].questionValid = false;
                fieldValidationErrors.roundsError[i].questionError = props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_roundsValid[i].questionValid = true;
                fieldValidationErrors.roundsError[i].questionError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
            }

            if (formInput.rounds[i].correct === '') {
                upd_roundsValid[i].correctValid = false;
                fieldValidationErrors.roundsError[i].correctError = props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_roundsValid[i].correctValid = true;
                fieldValidationErrors.roundsError[i].correctError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
            }

            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (formInput.rounds[i].answers[z] === '') {
                    upd_roundsValid[i].answersValid[z] = false;
                    fieldValidationErrors.roundsError[i].answersError[z] = props.appLanguage.backoffice.manageMines.add.requiredError;
                } else {
                    upd_roundsValid[i].answersValid[z] = true;
                    fieldValidationErrors.roundsError[i].answersError[z] = props.appLanguage.backoffice.manageMines.add.websiteAccept;
                }
            }
        }

        let upd_pointValid = formValid.pointValid;
        if (formInput.lat === null || formInput.lon === null) {
            upd_pointValid = false;
        } else {
            upd_pointValid = true;
        }


        setFormError(fieldValidationErrors)
        setFormValid({
            mineCodeValid: upd_mineCodeValid, websiteValid: upd_websiteValid, siteNameENGValid: upd_siteNameENGValid, siteNameNOLValid: upd_siteNameNOLValid, countryValid: upd_countryValid,
            regionStateProvinceValid: upd_regionStateProvinceValid, municipalityValid: upd_municipalityValid, touristicnameValid: upd_touristicnameValid, minePopDescValid: upd_minePopDescValid,
            complexValid: upd_complexValid, complexNameValid: upd_complexNameValid, mineHeritageRouteValid: upd_mineHeritageRouteValid, projectFigValid: upd_projectFigValid,
            infoFigureValid: upd_infoFigureValid, carParkValid: upd_carParkValid, cafeteriaValid: upd_cafeteriaValid, giftShopValid: upd_giftShopValid, addressValid: upd_addressValid,
            address2Valid: upd_address2Valid, postalCodeValid: upd_postalCodeValid, phoneValid: upd_phoneValid, mailValid: upd_mailValid, otherInfoContValid: upd_otherInfoContValid,
            pubTransportValid: upd_pubTransportValid, pubTransportDescValid: upd_pubTransportDescValid,
            roundsValid: upd_roundsValid, pointValid: upd_pointValid
        })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        let roundValidation = true

        for (let i = 0; i < formInput.rounds.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                roundValidation = false;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    roundValidation = false;
                    break;
                }
            }
        }


        //setFormSubmitValid({ inventory: formValid.pointValid && formValid.siteNameENGValid && formValid.addressValid && formValid.countryValid, quiz: roundValidation })
        setFormSubmitValid({ inventory: formValid.pointValid && formValid.countryValid, facilities: facilitiesInfoValid, mineProductions: mineProductionsValid, quiz: roundValidation })

    }, [formValid, facilitiesInfoValid, mineProductionsValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, indexRound: any, indexAnswer: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            /*INVENTORY*/
            case FORMMINECODEID: {
                setFormFocus({ ...formFocus, mineCodeFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            case FORMSITENAMEENGID: {
                setFormFocus({ ...formFocus, siteNameENGFocus: true })
                break;
            }
            case FORMSITENAMENOLID: {
                setFormFocus({ ...formFocus, siteNameNOLFocus: true })
                break;
            }
            case FORMCOUNTRYID: {
                setFormFocus({ ...formFocus, countryFocus: true })
                break;
            }
            case FORMREGIONSTATEPROVINCEID: {
                setFormFocus({ ...formFocus, regionStateProvinceFocus: true })
                break;
            }
            case FORMMUNICIPALITYID: {
                setFormFocus({ ...formFocus, municipalityFocus: true })
                break;
            }
            case FORMTOURISTICNAMEID: {
                setFormFocus({ ...formFocus, touristicnameFocus: true })
                break;
            }
            case FORMMINEPOPDESCID: {
                setFormFocus({ ...formFocus, minePopDescFocus: true })
                break;
            }
            case FORMCOMPLEXID: {
                setFormFocus({ ...formFocus, complexFocus: true })
                break;
            }
            case FORMCOMPLEXNAMEID: {
                setFormFocus({ ...formFocus, complexNameFocus: true })
                break;
            }
            case FORMMINEHERITAGEROUTEID: {
                setFormFocus({ ...formFocus, mineHeritageRouteFocus: true })
                break;
            }
            case FORMPROJECTFIGID: {
                setFormFocus({ ...formFocus, projectFigFocus: true })
                break;
            }
            case FORMINFOFIGUREID: {
                setFormFocus({ ...formFocus, infoFigureFocus: true })
                break;
            }

            case FORMCARPARKID: {
                setFormFocus({ ...formFocus, carParkFocus: true })
                break;
            }
            case FORMCAFETARIAID: {
                setFormFocus({ ...formFocus, cafeteriaFocus: true })
                break;
            }
            case FORMGIFTSHOPID: {
                setFormFocus({ ...formFocus, giftShopFocus: true })
                break;
            }
            case FORMADDRESSID: {
                setFormFocus({ ...formFocus, addressFocus: true })
                break;
            }
            case FORMADDRESS2ID: {
                setFormFocus({ ...formFocus, address2Focus: true })
                break;
            }
            case FORMPOSTALCODEID: {
                setFormFocus({ ...formFocus, postalCodeFocus: true })
                break;
            }
            case FORMPHONEID: {
                setFormFocus({ ...formFocus, phoneFocus: true })
                break;
            }
            case FORMMAILID: {
                setFormFocus({ ...formFocus, mailFocus: true })
                break;
            }
            case FORMOTHERINFOCONTID: {
                setFormFocus({ ...formFocus, otherInfoContFocus: true })
                break;
            }
            case FORMPUBTRANSPORTID: {
                setFormFocus({ ...formFocus, pubTransportFocus: true })
                break;
            }
            case FORMPUBTRANSPORTDESCID: {
                setFormFocus({ ...formFocus, pubTransportDescFocus: true })
                break;
            }
            /*----INVENTORY*/


            case FORMROUNDID + indexRound + FORMQUESTIONID: {

                let copyQuiz = [...formFocus.roundsFocus]
                copyQuiz[indexRound].questionFocus = true
                setFormFocus({ ...formFocus, roundsFocus: [...copyQuiz] })
                break;
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                let copyQuiz = [...formFocus.roundsFocus]
                copyQuiz[indexRound].answersFocus[indexAnswer] = true
                setFormFocus({ ...formFocus, roundsFocus: [...copyQuiz] })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);

        httpPostMineInventory(
            {
                id:"",
                website: formInput.website,
                mineCode: formInput.country,
                siteNameENG: formInput.siteNameENG,
                siteNameNOL: formInput.siteNameNOL,
                country: formInput.country,
                regionStateProvince: formInput.regionStateProvince,
                municipality: formInput.municipality,
                lat: formInput.lat,
                lng: formInput.lon,
                touristicname: formInput.touristicname,
                minePopDesc: formInput.minePopDesc,
                complex: formInput.complex,
                complexName: formInput.complexName,
                mineHeritageRoute: formInput.mineHeritageRoute,
                projectFig: formInput.projectFig,
                infoFigure: formInput.infoFigure,
                carPark: formInput.carPark,
                cafeteria: formInput.cafeteria,
                giftShop: formInput.giftShop,
                address: formInput.address,
                address2: formInput.address2,
                postalCode: formInput.postalCode,
                phone: formInput.phone,
                mail: formInput.mail,
                otherInfoCont: formInput.otherInfoCont,
                pubTransport: formInput.pubTransport,
                pubTransportDesc: formInput.pubTransportDesc
            }
        ).then(
            (res: any) => {
                const mineCode = res.data.mineCode
                const facilities: IFormInputFacilitiesInfo[] = [...formInputFacilitiesInfo]
                for (let i = 0; i < facilities.length; i++) {
                    const facility: IFormInputFacilitiesInfo = { ...facilities[i] }
                    httpPostMineFacility(
                        {
                            id:"",
                            mineCode: mineCode,
                            faciType: facility.facilityType,
                            faciName: facility.facilityName,
                            faciName2: facility.facilityOtherName,
                            faciPopDesc: facility.facilityDescription,
                            category: facility.mineCategory,
                            mineType: facility.mineType,
                            situation: facility.situation,
                            openingYear: facility.openingYear,
                            otherMineUses: facility.usesMine,
                            visitRoute: facility.visitRouteAvailable,
                            timeNeed: facility.timeNeededForTheVisit,
                            routeDesc: facility.routeDescription,
                            guided: facility.guidedVisit,
                            typeFreeTour: facility.freeTourAvailable,
                            typeAdventure: facility.adventure,
                            typeAdapted: facility.adaptedVisitAvailable,
                            eduresourBrochures: facility.brochuresPrintedInfo,
                            eduresourExplainChild: facility.explanationsForChildren,
                            eduresourExplainDisabled: facility.explanationsForDisabled,
                            eduresourAudioVisual: facility.audioVisualResources,
                            eduresourARVR: facility.arVrResources,
                            eduresourWorkshopChild: facility.workshopsForChildren,
                            eduresourWorkshopYoung: facility.workshopsForYoungAdults,
                            eduresourWorkshopDisabled: facility.workshopsForDisabled,
                            eduresourWorkshopAdventure: facility.workshopsForAdventure,
                            language: facility.languages,
                            english: facility.educationalVisitAvailableInEnglish
                        }
                    ).then(
                        (res: any) => {

                            if (i === facilities.length - 1) {
                                const mineProductions: IFormInputMineProductions[] = [...formInputMineProductions]
                                for (let x = 0; x < mineProductions.length; x++) {
                                    const mineProduction: IFormInputMineProductions = { ...mineProductions[i] }

                                    httpPostMineProduction(
                                        {
                                            id:"",
                                            mineCode: mineCode,
                                            faciCode: mineProduction.faciCode,
                                            product: mineProduction.product,
                                            mainOres: mineProduction.mainOresProduced,
                                            workingPeriod: mineProduction.workingPeriod,
                                            productDestination: mineProduction.productionDestination,
                                            productDestination2: mineProduction.productionDestination2
                                        }
                                    ).then(
                                        (res: any) => {
                                        }
                                    ).catch(
                                        (err: any) => {
                                        }
                                    )
                                }
                            }


                        }
                    ).catch(
                        (err: any) => {
                        }
                    )

                }

                /*SPAMMMMMM HERE */
                if (formInputLegendsHistoryEvents.timePeriodLegend !== "" || formInputLegendsHistoryEvents.storyLegendDesc !== "") {
                    httpPostMineSiteLegends(
                        {
                            id:"",
                            mineCode: mineCode,
                            legendDatePeriod: formInputLegendsHistoryEvents.timePeriodLegend,
                            storyDesc: formInputLegendsHistoryEvents.storyLegendDesc
                        }
                    ).then(
                        (res: any) => {
                        }
                    ).catch(
                        (err: any) => {
                        }
                    )
                }



                if (formInputLegendsHistoryEvents.datePeriodHistoricRecord !== "" || formInputLegendsHistoryEvents.historyEventDesc !== "") {
                    httpPostMineSiteHistoricEvents(
                        {
                            id:"",
                            mineCode: mineCode,
                            datePeriod: formInputLegendsHistoryEvents.datePeriodHistoricRecord,
                            event: formInputLegendsHistoryEvents.historyEventDesc
                        }
                    ).then(
                        (res: any) => {
                        }
                    ).catch(
                        (err: any) => {
                        }
                    )
                }


                if (formInputBibliography.bibliographicalResource !== "" || formInputBibliography.webInfoResource !== "" || formInputBibliography.otherBibliographicalInfo !== "") {

                    httpPostMineBibliography(
                        {
                            id:"",
                            mineCode: mineCode,
                            biblioSource: formInputBibliography.bibliographicalResource,
                            webInfo: formInputBibliography.webInfoResource,
                            otherBibInfo: formInputBibliography.otherBibliographicalInfo
                        }
                    ).then(
                        (res: any) => {
                        }
                    ).catch(
                        (err: any) => {
                        }
                    )
                }
                for (let i = 0; i < formInput.rounds.length; i++) {
                    httpPostMineQuestion(
                        {
                            mineCode: mineCode,
                            question: formInput.rounds[i].question,
                            ansA: formInput.rounds[i].answers[0],
                            ansB: formInput.rounds[i].answers[1],
                            ansC: formInput.rounds[i].answers[2],
                            ansD: formInput.rounds[i].answers[3],
                            ansE: formInput.rounds[i].answers[4],
                            correctAns: formInput.rounds[i].correct,
                            explanation: "",
                            institute: "",
                            photo: ""
                        }
                    ).then(
                        (res: any) => {
                        }
                    ).catch(
                        (err: any) => {
                        }
                    )
                }

                /*SPAMMMMMM HERE */




                closeAddEventHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeAddEventHandler(GeneralServices.constants.child.failed);
            }
        )


        /*closeAddEventHandler(GeneralServices.constants.child.closed);*/

    }

    const newMarkerHandler = (e: any) => {
        setFormInput({ ...formInput, lat: e.lngLat[1], lon: e.lngLat[0], })
    }

    const addRoundHandler = () => {
        if (formInput.rounds.length === 0) {
            setFormInput({ ...formInput, rounds: [{ question: "", correct: "", answers: ["", ""], status: 0 }] })
            setFormFocus({ ...formFocus, roundsFocus: [{ questionFocus: false, correctFocus: false, answersFocus: [false, false] }] })
            setFormError({ ...formError, roundsError: [{ questionError: "", correctError: "", answersError: ["", ""] }] })
            setFormValid({ ...formValid, roundsValid: [{ questionValid: false, correctValid: false, answersValid: [false, false] }], pointValid: false })

        }

    }

    const addRoundUpHandler = (index: number) => {

        let ilegal = false;
        for (let i = 0; i < formInput.rounds.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                ilegal = true;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    ilegal = true;
                    break;
                }
            }
        }


        if (!ilegal) {
            const copyRoundInput = []
            const copyRoundValid = []
            const copyRoundFocus = []
            const copyRoundError = []

            for (let i = 0; i < formInput.rounds.length; i++) {
                if (index === i) {
                    copyRoundInput.push({ question: "", correct: "", answers: ["", ""], status: 0 })
                    copyRoundValid.push({ questionValid: false, correctValid: false, answersValid: [false, false] })
                    copyRoundFocus.push({ questionFocus: false, correctFocus: false, answersFocus: [false, false] })
                    copyRoundError.push({ questionError: "", correctError: "", answersError: ["", ""] })

                }
                copyRoundInput.push(formInput.rounds[i])
                copyRoundValid.push(formValid.roundsValid[i])
                copyRoundFocus.push(formFocus.roundsFocus[i])
                copyRoundError.push(formError.roundsError[i])


            }
            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundYesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundCantAdd)

        }
    }
    const addRoundDownHandler = (index: number) => {
        let ilegal = false;
        for (let i = 0; i < formInput.rounds.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                ilegal = true;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    ilegal = true;
                    break;
                }
            }
        }


        if (!ilegal) {
            const copyRoundInput = []
            const copyRoundValid = []
            const copyRoundFocus = []
            const copyRoundError = []

            for (let i = 0; i < formInput.rounds.length; i++) {
                copyRoundInput.push(formInput.rounds[i])
                copyRoundValid.push(formValid.roundsValid[i])
                copyRoundFocus.push(formFocus.roundsFocus[i])
                copyRoundError.push(formError.roundsError[i])
                if (index === i) {
                    copyRoundInput.push({ question: "", correct: "", answers: ["", ""], status: 0 })
                    copyRoundValid.push({ questionValid: false, correctValid: false, answersValid: [false, false] })
                    copyRoundFocus.push({ questionFocus: false, correctFocus: false, answersFocus: [false, false] })
                    copyRoundError.push({ questionError: "", correctError: "", answersError: ["", ""] })
                }
            }
            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundYesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundCantAdd)

        }
    }


    const rmvRoundHandler = (index: number) => {
        const copyRoundInput = [...formInput.rounds]
        const copyRoundValid = [...formValid.roundsValid]
        const copyRoundFocus = [...formFocus.roundsFocus]
        const copyRoundError = [...formError.roundsError]

        copyRoundInput.splice(index, 1);
        copyRoundValid.splice(index, 1);
        copyRoundFocus.splice(index, 1);
        copyRoundError.splice(index, 1);

        setFormInput({ ...formInput, rounds: [...copyRoundInput] })
        setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
        setFormError({ ...formError, roundsError: [...copyRoundError] })
        setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })

    }


    const addAnswerHandler = (roundIndex: number) => {
        if (formInput.rounds[roundIndex].answers.length < alphabet.length) {
            const copyRoundInput = [...formInput.rounds]
            const copyRoundValid = [...formValid.roundsValid]
            const copyRoundFocus = [...formFocus.roundsFocus]
            const copyRoundError = [...formError.roundsError]


            const copyRoundInputAnswers = [...copyRoundInput[roundIndex].answers]
            const copyRoundValidAnswers = [...copyRoundValid[roundIndex].answersValid]
            const copyRoundFocusAnswers = [...copyRoundFocus[roundIndex].answersFocus]
            const copyRoundErrorAnswers = [...copyRoundError[roundIndex].answersError]

            copyRoundInputAnswers.push("")
            copyRoundValidAnswers.push(false)
            copyRoundFocusAnswers.push(false)
            copyRoundErrorAnswers.push("")


            copyRoundInput[roundIndex].answers = [...copyRoundInputAnswers]
            copyRoundValid[roundIndex].answersValid = [...copyRoundValidAnswers]
            copyRoundFocus[roundIndex].answersFocus = [...copyRoundFocusAnswers]
            copyRoundError[roundIndex].answersError = [...copyRoundErrorAnswers]



            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        }
    }

    const rmvAnswerHandler = (roundIndex: number) => {
        if (formInput.rounds[roundIndex].answers.length > 2) {

            const copyRoundInput = [...formInput.rounds]
            const copyRoundValid = [...formValid.roundsValid]
            const copyRoundFocus = [...formFocus.roundsFocus]
            const copyRoundError = [...formError.roundsError]


            copyRoundInput[roundIndex].answers.splice(copyRoundInput[roundIndex].answers.length - 1, 1);
            copyRoundValid[roundIndex].answersValid.splice(copyRoundValid[roundIndex].answersValid.length - 1, 1);
            copyRoundFocus[roundIndex].answersFocus.splice(copyRoundFocus[roundIndex].answersFocus.length - 1, 1);
            copyRoundError[roundIndex].answersError.splice(copyRoundError[roundIndex].answersError.length - 1, 1);

            if (formInput.rounds[roundIndex].correct === alphabet[formInput.rounds[roundIndex].answers.length]) {
                copyRoundInput[roundIndex].correct = ""
            }

            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        }

    }




    const validatorFeedback = (formId: any, indexRound: any, indexAnswer: any) => {
        switch (formId) {

            /*INVENTORY*/
            case FORMMINECODEID: {
                return formFocus.mineCodeFocus && !formValid.mineCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.mineCodeError}</span>
                    : formValid.mineCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.mineCodeAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && formValid.websiteValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                    : null

                /*
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
                */
            }
            case FORMSITENAMEENGID: {
                return formFocus.siteNameENGFocus && formValid.siteNameENGValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.siteNameENGAccept}</span>
                    : null
            }
            case FORMSITENAMENOLID: {
                return formFocus.siteNameNOLFocus && formValid.siteNameNOLValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.siteNameNOLAccept}</span>
                    : null
            }
            case FORMCOUNTRYID: {
                return formFocus.countryFocus && formValid.countryValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.countryAccept}</span>
                    : null
            }
            case FORMREGIONSTATEPROVINCEID: {
                return formFocus.regionStateProvinceFocus && formValid.regionStateProvinceValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.regionStateProvinceAccept}</span>
                    : null
            }
            case FORMMUNICIPALITYID: {
                return formFocus.municipalityFocus && formValid.municipalityValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.municipalityAccept}</span>
                    : null
            }
            case FORMTOURISTICNAMEID: {
                return formFocus.touristicnameFocus && formValid.touristicnameValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.touristicnameAccept}</span>
                    : null
            }
            case FORMMINEPOPDESCID: {
                return formFocus.minePopDescFocus && formValid.minePopDescValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.minePopDescAccept}</span>
                    : null
            }
            case FORMCOMPLEXID: {
                return formFocus.complexFocus && formValid.complexValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.complexAccept}</span>
                    : null
            }
            case FORMCOMPLEXNAMEID: {
                return formFocus.complexNameFocus && formValid.complexNameValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.complexNameAccept}</span>
                    : null
            }
            case FORMMINEHERITAGEROUTEID: {
                return formFocus.mineHeritageRouteFocus && formValid.mineHeritageRouteValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.mineHeritageRouteAccept}</span>
                    : null
            }
            case FORMPROJECTFIGID: {
                return formFocus.projectFigFocus && formValid.projectFigValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.projectFigAccept}</span>
                    : null
            }
            case FORMINFOFIGUREID: {
                return formFocus.infoFigureFocus && formValid.infoFigureValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.infoFigureAccept}</span>
                    : null
            }
            case FORMCARPARKID: {
                return formFocus.carParkFocus && formValid.carParkValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.carParkAccept}</span>
                    : null
            }
            case FORMCAFETARIAID: {
                return formFocus.cafeteriaFocus && formValid.cafeteriaValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.cafeteriaAccept}</span>
                    : null
            }
            case FORMGIFTSHOPID: {
                return formFocus.giftShopFocus && formValid.giftShopValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.giftShopAccept}</span>
                    : null
            }
            case FORMADDRESSID: {
                return formFocus.addressFocus && formValid.addressValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.addressAccept}</span>
                    : null
            }
            case FORMADDRESS2ID: {
                return formFocus.address2Focus && formValid.address2Valid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.address2Accept}</span>
                    : null
            }
            case FORMPOSTALCODEID: {
                return formFocus.postalCodeFocus && formValid.postalCodeValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.postalCodeAccept}</span>
                    : null
            }
            case FORMPHONEID: {
                return formFocus.phoneFocus && formValid.phoneValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.phoneAccept}</span>
                    : null
            }
            case FORMMAILID: {
                return formFocus.mailFocus && formValid.mailValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.mailAccept}</span>
                    : null
            }
            case FORMOTHERINFOCONTID: {
                return formFocus.otherInfoContFocus && formValid.otherInfoContValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.otherInfoContAccept}</span>
                    : null
            }
            case FORMPUBTRANSPORTID: {
                return formFocus.pubTransportFocus && formValid.pubTransportValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.pubTransportAccept}</span>
                    : null
            }
            case FORMPUBTRANSPORTDESCID: {
                return formFocus.pubTransportDescFocus && formValid.pubTransportDescValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.pubTransportDescAccept}</span>
                    : null
            }
            /*----INVENTORY*/




            case FORMROUNDID + indexRound + FORMQUESTIONID: {
                return formFocus.roundsFocus[indexRound].questionFocus && !formValid.roundsValid[indexRound].questionValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.roundsValid[indexRound].questionValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                return formFocus.roundsFocus[indexRound].answersFocus[indexAnswer] && !formValid.roundsValid[indexRound].answersValid[indexAnswer] ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.roundsValid[indexRound].answersValid[indexAnswer] ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }


    const checkCorrectOptionHandler = (indexRound: any, indexAnswer: any) => {
        let copyRounds = [...formInput.rounds]

        if (formInput.rounds[indexRound].correct === alphabet[indexAnswer]) {
            copyRounds[indexRound].correct = ""

        } else {
            copyRounds[indexRound].correct = alphabet[indexAnswer]

        }
        setFormInput({ ...formInput, rounds: copyRounds })

    }

    const checkIsCorrectOption = (indexRound: any, indexAnswer: any) => {
        if (formInput.rounds[indexRound].correct === alphabet[indexAnswer]) {
            return true
        } else {
            return false;
        }

    }

    const flagDropdownHandler = () => {
        if (refFlagsDropdownMenu.current !== null) {
            const myElement: HTMLElement | null = document.getElementById(FORMCOUNTRYID);
            const myParent: HTMLElement | null = document.getElementById("addmine-parent-container");


            if (myElement !== null && myParent !== null) {
                const yPos = (myParent.getBoundingClientRect().y + myParent.getBoundingClientRect().height - myElement.getBoundingClientRect().y)
                refFlagsDropdownMenu.current.style.bottom = yPos + 'px';
                refFlagsDropdownMenu.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';
            }

        }

        if (!flagsDropdown.open) {
            setFlagsDropdown({ open: true, class: 'flags-menu-drop flags-menu-drop-open' })
        } else {
            setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
        }

    }

    const changeInputCountryHandler = (option: string) => {
        setFormInput({ ...formInput, country: option })
    }

    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div className="container-add-mine-inventory">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.firstTitle}</span></div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.profile.editProfileModal.country.title} *
                            </label>
                            <button type="button" className="input-country" id={FORMCOUNTRYID} onClick={(event: any) => { validateFocusFields(event, null, null); flagDropdownHandler() }} ref={refFlagsDropdownButton}>
                                {
                                    formInput.country === "" ?
                                        <>
                                            <div className="text-ph"><span>{props.appLanguage.register.countryph}</span></div>
                                            <div className="arrow-down"></div>
                                        </>
                                        :
                                        <>
                                            <div className="text-country"><span>{formInput.country.toUpperCase()}</span></div>
                                            <div className="arrow-down"></div>

                                        </>
                                }

                            </button>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOUNTRYID, null, null)
                                }
                            </div>

                        </div>
                    </div>
                    <div className="map-wrapper">
                        <div className="sidebar">
                            {props.appLanguage.mapPage.lng}: {viewport.longitude} | {props.appLanguage.mapPage.lat}: {viewport.latitude} | {props.appLanguage.mapPage.zoom}: {viewport.zoom}
                        </div>

                        <ReactMapGL className='map-container' {...viewport} width="60%" height="30vmax" onViewportChange={(viewport) => setViewport(viewport)}
                            ref={mapRef}
                            mapboxApiAccessToken={mapToken}
                            mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
                            onClick={(e: any) => newMarkerHandler(e)}
                        >
                            {
                                formInput.lat !== null && formInput.lon !== null ?
                                    <Marker latitude={formInput.lat} longitude={formInput.lon} offsetLeft={-20} offsetTop={-50} draggable onDragEnd={(e: any) => newMarkerHandler(e)}>
                                        <div className="marker"></div>
                                    </Marker>
                                    : null
                            }
                        </ReactMapGL>
                        <div className="info-container">
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[0]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[1]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[2]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[3]}</span>
                        </div>
                    </div>


                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mineCode} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.mineCodeph}
                                id={FORMMINECODEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mineCode || ""}
                                disabled

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINECODEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.siteNameENG}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.siteNameENGph}
                                id={FORMSITENAMEENGID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.siteNameENG || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSITENAMEENGID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.siteNameNOL}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.siteNameNOLph}
                                id={FORMSITENAMENOLID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.siteNameNOL || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSITENAMENOLID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.regionStateProvince}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.regionStateProvinceph}
                                id={FORMREGIONSTATEPROVINCEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.regionStateProvince || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMREGIONSTATEPROVINCEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.municipality}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.municipalityph}
                                id={FORMMUNICIPALITYID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.municipality || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMUNICIPALITYID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.address}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.addressph}
                                id={FORMADDRESSID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.address || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMADDRESSID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.address2}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.address2ph}
                                id={FORMADDRESS2ID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.address2 || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMADDRESS2ID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.postalCode}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.postalCodeph}
                                id={FORMPOSTALCODEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.postalCode || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPOSTALCODEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.phone}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.phoneph}
                                id={FORMPHONEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.phone || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPHONEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.website}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.websiteph}
                                id={FORMWEBSITEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.website || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMWEBSITEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mail}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.mailph}
                                id={FORMMAILID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mail || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMAILID, null, null)
                                }
                            </div>
                        </div>
                    </div>


                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.touristicname}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.touristicnameph}
                                id={FORMTOURISTICNAMEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.touristicname || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMTOURISTICNAMEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.complex}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCOMPLEXID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.complex || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.complexph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOMPLEXID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.complexName}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.complexNameph}
                                id={FORMCOMPLEXNAMEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.complexName || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOMPLEXNAMEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.projectFig}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMPROJECTFIGID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.projectFig || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.projectFigph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPROJECTFIGID, null, null)
                                }
                            </div>
                        </div>



                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.infoFigure}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.infoFigureph}
                                id={FORMINFOFIGUREID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.infoFigure || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMINFOFIGUREID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.minePopDesc}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.minePopDescph}
                                id={FORMMINEPOPDESCID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.minePopDesc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINEPOPDESCID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.secondTitle}</span></div>

                    <div className="main-row-triple">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.carPark}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCARPARKID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.carPark || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.carParkph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCARPARKID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.cafeteria}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCAFETARIAID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.cafeteria || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.cafeteriaph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCAFETARIAID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.giftShop}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMGIFTSHOPID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.giftShop || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.giftShop}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMGIFTSHOPID, null, null)
                                }
                            </div>
                        </div>

                    </div>


                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.pubTransport}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.pubTransportph}
                                id={FORMPUBTRANSPORTID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.pubTransport || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPUBTRANSPORTID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.pubTransportDesc}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.pubTransportDescph}
                                id={FORMPUBTRANSPORTDESCID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.pubTransportDesc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPUBTRANSPORTDESCID, null, null)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.thirdTitle}</span></div>

                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mineHeritageRoute}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMMINEHERITAGEROUTEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mineHeritageRoute || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.mineHeritageRouteph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINEHERITAGEROUTEID, null, null)
                                }
                            </div>
                        </div>
                    </div>



                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.otherInfoCont}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.otherInfoContph}
                                id={FORMOTHERINFOCONTID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.otherInfoCont || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMOTHERINFOCONTID, null, null)
                                }
                            </div>
                        </div>
                    </div>





                </div>
            )
        } else if (selectedStepper === "1") {
            return (
                <FacilitiesInfoAdd
                    formInput={(childData: any) => setFormInputFacilitiesInfo(childData)}
                    formError={(childData: any) => setFormErrorFacilitiesInfo(childData)}
                    formValid={(childData: any) => setFormValidFacilitiesInfo(childData)}
                    formFocus={(childData: any) => setFormFocusFacilitiesInfo(childData)}
                    formValidation={(childData: any) => setFacilitiesInfoValid(childData)}
                    injectData={{
                        injectInput: formInputFacilitiesInfo, injectValid: formValidFacilitiesInfo, injectFocus: formFocusFacilitiesInfo, injectError: formErrorFacilitiesInfo,
                        injectAuxCountry: facilityTypeAuxArray, injectAuxMineType: mineTypeAuxArray, injectAuxSituation: situationeAuxArray, injectAuxCategories: categoriesAuxArray, injectAuxFaciltyType: facilityTypeAuxArray
                    }}


                />
            )
        } else if (selectedStepper === "2") {
            return (
                <MineProductionAdd
                    formInput={(childData: any) => setFormInputMineProductions(childData)}
                    formError={(childData: any) => setFormErrorMineProductions(childData)}
                    formValid={(childData: any) => setFormValidMineProductions(childData)}
                    formFocus={(childData: any) => setFormFocusMineProductions(childData)}
                    formValidation={(childData: any) => setMineProductionsValid(childData)}
                    injectData={{ injectInput: formInputMineProductions, injectValid: formValidMineProductions, injectFocus: formFocusMineProductions, injectError: formErrorMineProductions, facilities: formInputFacilitiesInfo }}


                />
            )
        } else if (selectedStepper === "3") {
            return (
                <LegendsHistoryEventsAdd
                    formInput={(childData: any) => setFormInputLegendsHistoryEvents(childData)}
                    formError={(childData: any) => setFormErrorLegendsHistoryEvents(childData)}
                    formValid={(childData: any) => setFormValidLegendsHistoryEvents(childData)}
                    formFocus={(childData: any) => setFormFocusLegendsHistoryEvents(childData)}
                    formValidation={(childData: any) => setLegendsHistoryEventsValid(childData)}
                    injectData={{ injectInput: formInputLegendsHistoryEvents, injectValid: formValidLegendsHistoryEvents, injectFocus: formFocusLegendsHistoryEvents, injectError: formErrorLegendsHistoryEvents }}


                />
            )

        } else if (selectedStepper === "4") {
            return (
                <BibliographyAdd
                    formInput={(childData: any) => setFormInputBibliography(childData)}
                    formError={(childData: any) => setFormErrorBibliography(childData)}
                    formValid={(childData: any) => setFormValidBibliography(childData)}
                    formFocus={(childData: any) => setFormFocusBibliography(childData)}
                    formValidation={(childData: any) => setBibliographyValid(childData)}
                    injectData={{ injectInput: formInputBibliography, injectValid: formValidBibliography, injectFocus: formFocusBibliography, injectError: formErrorBibliography }}


                />
            )

        } else if (selectedStepper === "5") {
            return (
                <div className="container-add-mine-quiz">

                    {
                        formInput.rounds.length === 0 &&
                        <div className="quiz-header">
                            <span className="start-quiz">{props.appLanguage.backoffice.manageMines.add.quiz.start[0]}</span>
                            <div className="add-round" onClick={addRoundHandler}>
                                <span>{props.appLanguage.backoffice.manageMines.add.quiz.start[1]}</span>
                            </div>

                        </div>
                    }
                    {
                        formInput.rounds.length > 0 &&
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.game[0]}</span></div>

                    }
                    {
                        formInput.rounds.map((round: IFormRound, index1: number) => (
                            <div className="quiz-round" key={"round" + index1}>
                                <div className="round-header">
                                    <div className="add-up" onClick={() => addRoundUpHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addRound[0]} direction="top">
                                            <div className="add-up-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                    <div className="add-down" onClick={() => addRoundDownHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addRound[1]} direction="top">
                                            <div className="add-down-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                    <div className="rmv" onClick={() => rmvRoundHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.rmvRound} direction="top">
                                            <div className="rmv-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                </div>
                                <div className="main-question">
                                    <div className="input-question">
                                        <input className="input-question-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.quiz.questionph}
                                            id={FORMROUNDID + index1 + FORMQUESTIONID}
                                            onChange={(event: any) => changeInputHandler(event, index1, null)}
                                            onFocus={(event: any) => validateFocusFields(event, index1, null)}
                                            value={round.question || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMROUNDID + index1 + FORMQUESTIONID, index1, null)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="container-answers">
                                    <div className="answers-header">
                                        {
                                            formInput.rounds[index1].answers.length < alphabet.length &&
                                            <div className="add-answer" onClick={() => addAnswerHandler(index1)}>
                                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addAnswer} direction="top">
                                                    <div className="add-icon"></div>
                                                </TooltipKekax>
                                            </div>
                                        }
                                        {
                                            round.answers.length > 2 &&
                                            <div className="rmv-answer" onClick={() => rmvAnswerHandler(index1)}>
                                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.rmvAnswer} direction="top">
                                                    <div className="rmv-icon"></div>
                                                </TooltipKekax>
                                            </div>
                                        }
                                    </div>

                                    {
                                        round.answers.map((answer: string, index2: number) => (
                                            <div key={"round" + index1 + "answer" + index2} className="main-answer">
                                                <div className="input-answer">
                                                    <input className="input-answer-text kekax-input-box"
                                                        type="text"
                                                        placeholder={props.appLanguage.backoffice.manageMines.add.quiz.answerph + alphabet[index2]}
                                                        id={FORMROUNDID + index1 + FORMANSWERID + index2}
                                                        onChange={(event: any) => changeInputHandler(event, index1, index2)}
                                                        onFocus={(event: any) => validateFocusFields(event, index1, index2)}
                                                        value={answer || ""}

                                                    />
                                                    <div className="feedback-block">
                                                        {
                                                            validatorFeedback(FORMROUNDID + index1 + FORMANSWERID + index2, index1, index2)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={checkIsCorrectOption(index1, index2) ? "input-check input-check-active" : "input-check"} onClick={() => checkCorrectOptionHandler(index1, index2)}>
                                                    <TooltipKekax content={checkIsCorrectOption(index1, index2) ? props.appLanguage.backoffice.manageMines.add.quiz.uncheckCorrect :
                                                        props.appLanguage.backoffice.manageMines.add.quiz.checkCorrect} direction="top">
                                                        <div className="check-icon">
                                                        </div>
                                                    </TooltipKekax>
                                                </div>

                                            </div>
                                        ))
                                    }

                                </div>

                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const printNextButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 1: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 2: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 3: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid || !legendsHistoryEventsValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 4: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid || !legendsHistoryEventsValid || !bibliographyValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            default: {
                return null;
            }
        }
    }

    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        if (count === 5) {
            return <div className="submit"><button type="submit" disabled={!formSubmitValid.inventory || !formSubmitValid.facilities || !mineProductionsValid || !legendsHistoryEventsValid || !bibliographyValid || !formSubmitValid.quiz || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageMines.add.submit} </button></div>
        } else {
            return null
        }
    }


    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
    }


    return (
        <React.Fragment>
            <div className="wrapper-add-mine" id="addmine-parent-container">
                <form ref={formRef} id='add-mine-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-stepper">
                        <StepperKekax active={selectedStepper}>
                            <div key="0">{props.appLanguage.backoffice.manageMines.add.information.sites.tab}</div>
                            <div key="1">{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.tab}</div>
                            <div key="2">{props.appLanguage.backoffice.manageMines.add.information.mineProductions.tab}</div>
                            <div key="3">{props.appLanguage.backoffice.manageMines.add.information.legendsHistoryEvents.tab}</div>
                            <div key="4">{props.appLanguage.backoffice.manageMines.add.information.bibliography.tab}</div>
                            <div key="5">{props.appLanguage.backoffice.manageMines.add.information.game[1]}</div>

                        </StepperKekax>
                    </div>

                    {
                        printActiveContent()
                    }

                    <div className="container-form-bottom">
                        <div className="previous"><button type="button" className="previous-btn" disabled={selectedStepper === "0" ? true : false} onClick={() => changeStepperHandler("PREVIOUS")}><span className="info">{props.appLanguage.backoffice.manageMines.add.previous}</span></button></div>
                        {

                            printNextButton()
                        }
                        {
                            printSubmitButton()

                        }

                    </div>

                </form>
                <div className={flagsDropdown.class} ref={refFlagsDropdownMenu}>
                    <div className="wrapper-flags-menu-dropdown">
                        <div className="drop-filter-wrapper">
                            {
                                countryAuxArray.map((flag: IFormInputAuxCountry, index: number) => (
                                    <div key={"countryMine" + index} className={formInput.country === flag.countryCode ? "drop-option opt-active" : "drop-option"} onClick={() => { changeInputCountryHandler(flag.countryCode); setFlagsDropdown({ open: false, class: 'flags-menu-drop' }) }}>
                                        <span className="text">{flag.officialName.toUpperCase()}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );
}

export default connect(mineStateToProps)(AddMine);

/*
<div className="icon" style={{ backgroundImage: "url(" + flag.countryCode.toLowerCase() + ")" }}></div>
<div className="opt-flag" style={{ backgroundImage: "url(" + require("../../../../assets/flags/" + formInput.country + ".png") + ")" }}></div>

*/