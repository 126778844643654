import React, { useEffect, useRef } from 'react';
import "./DeleteProfileModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../services/generalService.json'
import { httpUserDeleteAccountRequest } from '../../../services/requestService';

interface IProps extends StoreProps{
    open: boolean
    callback: any
}

export function DeleteProfileModal(props: IProps) {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);


    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("remove-profile-modal-open");
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);

            handleOffset();
        } else {
            modalRef.current.classList.remove("remove-profile-modal-open");
        }
    }, [props.open])




    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--deleteModalProfile-topOffset", posY + "px");
    }

    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(false);
        }
        */
       
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback:string) => {
        if(modalRef.current !== null)
            modalRef.current.classList.remove("remove-profile-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);

        props.callback(feedback)


    }

    const deleteAccountHandler = () =>{

        httpUserDeleteAccountRequest().then(
            res => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            e => {
                const errorStatus = e.status;
                if (errorStatus === GeneralServices.errorStatus.forbidden) {
                    closeModalHandler(GeneralServices.constants.child.rmvSelfAdminImmune);
                }else{
                    closeModalHandler(GeneralServices.constants.child.failed);

                }

            }
        )


        
    }




    return (
        <div className="wrapper-delete-profile" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>
            <div className="content" ref={contentRef}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.profile.deleteProfileModal.title}</span>
                    <div className="close"><button className="close-btn" onClick={()=>closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <span>{props.appLanguage.profile.deleteProfileModal.question}</span>
                </div>
                <div className="footer">
                    <div className="submit"><button className="submit-btn" onClick={()=>closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.profile.deleteProfileModal.no}</span></button></div>
                    <div className="cancel"><button className="cancel-btn" onClick={deleteAccountHandler}><span className="info">{props.appLanguage.profile.deleteProfileModal.yes}</span></button></div>
                </div>
            </div>
        </div>
    );

}


export default connect(mapStateToProps)(DeleteProfileModal);
