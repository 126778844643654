import React, { useState, useEffect } from "react";
import './Links.css'
import { connect } from "react-redux";
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import TooltipKekax from "../kekax/TooltipKekax";
import { httpGetListLinks } from "../../services/requestService";



export interface ILink {
    linkId: string
    title: string
    description: string
    website: string
    order: number
}

/*
const staticLinks: LinkObject[] = [
    {
        title: "Tourism of Ivanec - Croatial", info: "Information about Ivanca's mining, in Croatia.", href: "http://www.ivanec-turizam.hr/index.php/kultura-zivota-i-rada/rudarstvo"
    },
    {
        title: "CENIMAT - Centro de Investigação em Materiais", info: "CENIMAT|i3N", href: "https://www.cenimat.fct.unl.pt/"
    },
    {
        title: "Roteiro das Minas e Pontos de Interesse Mineiro e Geológico de Portugal", info: "Direção Geral de Energia e Geologia (DGEG)", href: "http://www.roteirodeminas.pt/"
    }
]
*/

interface IProps extends StoreProps { }

export const Links: React.FC<IProps> = (props) => {

    const [allLinks, setAllLinks] = useState<ILink[]>([]);
    const [totalLinks, setTotalLinks] = useState<number>(0);
    const [actualPage, setActualPage] = useState<number>(0);
    const [reachedMaxPage, setReachedMaxPage] = useState<boolean>(false);

    const limitPerPage: number = 12

    useEffect(() => {


        httpGetListLinks('?limit=' + limitPerPage)
            .then(
                res => {
                    //SETTING TOTAL DOCUMENTS
                    const totalDocs = res.headers.total
                    if (totalDocs !== undefined) {
                        setTotalLinks(parseInt(totalDocs, 10));
                    } else {
                        setTotalLinks(parseInt('1', 10));
                    }

                    setAllLinks([...res.data])
                })
            .catch(
                err => {
                }
            )

    }, [])

    const handleShowMore = () => {

        const thisPage = actualPage + 1
        setActualPage(thisPage)

        httpGetListLinks('?limit=' + limitPerPage + '&page=' + thisPage)
            .then(
                res => {
                    setAllLinks([...allLinks, ...res.data])
                })
            .catch(
                err => {
                }
            )


    }

    useEffect(() => {

        if (allLinks.length !== 0 && allLinks.length === totalLinks && totalLinks !== 0) {
            setReachedMaxPage(true)
        }
    }, [allLinks])


    return (
        <div className="wrapper-links">

            <div className="content-all">
                <div className="container-header">
                    <div className="content">
                        <span>{props.appLanguage.links.header[0]}</span>
                    </div>
                </div>
                <div className="container-body">
                    {
                        allLinks.length > 0 ?

                            <React.Fragment>
                                {
                                    allLinks.map((content: ILink, index: number) => (
                                        <div key={index} className="links-object">
                                            <div className="object-flex">
                                                <span className="links-object-title">{content.title}</span>
                                                <span className="links-object-content">{content.description}</span>
                                            </div>
                                            <div className="object-options">
                                                <a className="links-object-link" href={content.website} target="_blank" rel="noopener noreferrer">
                                                    <TooltipKekax content={props.appLanguage.links.website} direction="bottom">
                                                        <div className='object-link-icon' >
                                                        </div>
                                                    </TooltipKekax>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    !reachedMaxPage ?
                                        <div className="show-more">
                                            <button className="button-show" onClick={handleShowMore}>{props.appLanguage.links.showmore}</button>
                                        </div>
                                        : null
                                }

                            </React.Fragment>

                            :
                            <span className="no-content">{props.appLanguage.msg_feedback.no_content}</span>
                    }

                </div>

            </div>
        </div>
    );
}

export default connect(mapStateToProps)(Links);

/*
                                           <div className="link-content">
                                                    <span className="main">{content.title}</span>
                                                    <span className="split">|</span>
                                                    <span className="info">{content.info}</span>
                                                </div>
                                                <div className="link-link">
                                                    <a href={content.href} target="_blank">Website</a>
                                                </div>
                                                */