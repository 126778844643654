import React, { useState, useEffect } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './CalendarHeader.css'
import * as dateFns from "date-fns";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


interface IProps extends StoreProps {
    cbCurrentDate: any;

}

const CalendarHeader: React.FC<IProps> = (props) => {
    const [dayWeeks, setDayWeeks] = useState<string[]>(new Array(7));
    const [currentDate, setCurrentDate] = useState(new Date())
    const [monthYear, setMonthYear] = useState<string>("");


    useEffect(() => {
        if (props.appLanguage) {
            translateHeader(props.appLanguage.lang_code);
        }
        //eslint-disable-next-line
    }, [props.appLanguage, currentDate])

    const translateHeader = (lang: any) => {
        //const lmao = require('date-fns/locale/' + lang + '/index.js');
        const daysOfWeek = props.appLanguage.events.daysOfWeek
        //setDayWeeks([...dummyArray].map((i: any, index: number) => lmao.localize.day(index, { width: 'abbreviated' }).toUpperCase()))
        setDayWeeks([...daysOfWeek])
        //setMonthYear(lmao.localize.month(dateFns.getMonth(currentDate)) + ' ' + dateFns.format(currentDate, "yyyy"))
        setMonthYear(props.appLanguage.events.monthsOfYear[dateFns.getMonth(currentDate)] + ' ' + dateFns.format(currentDate, "yyyy"))
    }

    useEffect(() => {
        props.cbCurrentDate(currentDate);
        //eslint-disable-next-line
    }, [currentDate])


    const nextMonthHandler = () => {
        setCurrentDate(dateFns.addMonths(currentDate, 1));
    }
    const prevMonthHandler = () => {
        setCurrentDate(dateFns.subMonths(currentDate, 1));
    }
    const nextYearHandler = () => {
        setCurrentDate(dateFns.addYears(currentDate, 1));
    }
    const prevYearHandler = () => {
        setCurrentDate(dateFns.subYears(currentDate, 1));
    }

    return (
        <div className="wrapper-calendar-header">
            <div className="container-calendar-header-menu">
                <div className="container-calendar-header-menu-left" >
                    <ArrowLeftIcon className="move-years" onClick={prevYearHandler}/>
                    <ChevronLeftIcon onClick={prevMonthHandler}/>
                </div>
                <div className="container-calendar-header-menu-center">
                    <span>{monthYear}</span>
                </div>
                <div className="container-calendar-header-menu-right" >
                    <ChevronRightIcon onClick={nextMonthHandler}/>
                    <ArrowRightIcon className="move-years" onClick={nextYearHandler}/>
                </div>
            </div>
            <div className="container-calendar-header-week">
                {dayWeeks.map((day, index) => (
                    <div key={index} className="container-calendar-header-cell">
                        <span>{day}</span>
                    </div>
                ))

                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(CalendarHeader);


