import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddLink.css';
import { httpPostLink } from '../../../../services/requestService';

import GeneralServices from "../../../../services/generalService.json"
import SnackKekax from '../../../kekax/SnackKekax';


interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorLink {
    titleError: string
    descriptionError: string
    websiteError: string
}
export interface IFormInputLink {
    title: string
    description: string
    website: string

}
export interface IFormValidLink {
    titleValid: boolean
    descriptionValid: boolean
    websiteValid: boolean

}
export interface IFormFocusLink {
    titleFocus: boolean
    descriptionFocus: boolean
    websiteFocus: boolean
}


interface SnackKekax {
    open: boolean,
    message: string
}

const FORMTITLEID: string = 'title';
const FORMDESCRIPTIONID: string = 'description';
const FORMWEBSITEID: string = 'website';


export const AddLink: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormErrorLink>({ titleError: '', descriptionError: '', websiteError: '' });
    const [formInput, setFormInput] = useState<IFormInputLink>({ title: '', description: '', website: '' });
    const [formValid, setFormValid] = useState<IFormValidLink>({ titleValid: false, descriptionValid: false, websiteValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusLink>({ titleFocus: false, descriptionFocus: false, websiteFocus: false });


    const formRef = useRef<any>(null);
    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);



    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);






    const closeAddEventHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid(false);
        setFormError({ titleError: '', descriptionError: '', websiteError: '' });
        setFormInput({ title: '', description: '', website: '' })
        setFormValid({ titleValid: false, descriptionValid: false, websiteValid: false })
        setFormFocus({ titleFocus: false, descriptionFocus: false, websiteFocus: false })


        setTempDisabled(0)

        props.cbMessage(what);

    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMDESCRIPTIONID: {
                setFormInput({ ...formInput, description: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;
        let upd_descriptionValid = formValid.descriptionValid;
        let upd_websiteValid = formValid.websiteValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageLinks.add.titleAccept;
        }

        /*DESCRIPTION VALIDATION*/
        if (formInput.description === '') {
            upd_descriptionValid = false;
            fieldValidationErrors.descriptionError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_descriptionValid = true;
            fieldValidationErrors.descriptionError = props.appLanguage.backoffice.manageLinks.add.descriptionAccept;
        }

        /*WEBSITE VALIDATION*/
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageLinks.add.websiteAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid, descriptionValid: upd_descriptionValid, websiteValid: upd_websiteValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.titleValid && formValid.descriptionValid && formValid.websiteValid)
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMDESCRIPTIONID: {
                setFormFocus({ ...formFocus, descriptionFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);

        
        httpPostLink({
            title:formInput.title,
            description:formInput.description,
            website:formInput.website
        }).then(
            (res: any) => {
                closeAddEventHandler(GeneralServices.constants.child.worked);
            },
            (err: any) => {
                closeAddEventHandler(GeneralServices.constants.child.failed);
            }

        )
        

        /*closeAddEventHandler(GeneralServices.constants.child.closed);*/

    }




    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.titleAccept}</span>
                        : null
            }
            case FORMDESCRIPTIONID: {
                return formFocus.descriptionFocus && !formValid.descriptionValid ?
                    <span className="input-invalid-feedback-kekax">{formError.descriptionError}</span>
                    : formValid.descriptionValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.descriptionAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && !formValid.websiteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }


    return (
        <React.Fragment>
            <div className="wrapper-add-link">
                <form ref={formRef} id='add-link-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-add-link-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageLinks.add.information.main}</span></div>

                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-description">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.description} *

                                </label>
                                <input className="input-description-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.descriptionph}
                                    id={FORMDESCRIPTIONID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.description || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDESCRIPTIONID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-website">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.website} *

                                </label>
                                <input className="input-website-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.websiteph}
                                    id={FORMWEBSITEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.website || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMWEBSITEID)
                                    }
                                </div>
                            </div>
                        </div>



                    </div>




                    <div className="container-form-bottom">
                        <div className="submit"><button type="submit" className="submit-btn" disabled={!formSubmitValid || tempDisabled === 1}><span className="info">{props.appLanguage.backoffice.manageLinks.add.submit}</span></button></div>
                    </div>

                </form>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );
}

export default connect(mapStateToProps)(AddLink);