import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddBooklet.css';
import { httpPostBooklet } from '../../../../services/requestService';

import GeneralServices from "../../../../services/generalService.json"
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';

interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorBooklet {
    titleError: string

}
export interface IFormInputBooklet {
    title: string

}
export interface IFormValidBooklet {
    titleValid: boolean

}
export interface IFormFocusBooklet {
    titleFocus: boolean

}

export interface AttachFileBooklet {
    name: string
    content: any
}

interface SnackKekax {
    open: boolean,
    message: string
}

const FORMTITLEID: string = 'title';


export const AddBooklet: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormErrorBooklet>({ titleError: '' });
    const [formInput, setFormInput] = useState<IFormInputBooklet>({ title: '' });
    const [formValid, setFormValid] = useState<IFormValidBooklet>({ titleValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusBooklet>({ titleFocus: false });


    const [attachDrag, setAttachDrag] = useState<boolean>(false);

    const [attachArray, setAttachArray] = useState<AttachFileBooklet[]>([]);
    const [transferedAttach, setTransferedAttach] = useState<any[]>([]);

    let dragAttachCounter = 0;

    const attachDropRef: any = React.createRef();
    const formRef = useRef<any>(null);

    const MAX_FILES = 1

    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);


    useEffect(() => {
        let xx = attachDropRef.current;
        if (xx !== null) {
            xx.addEventListener('dragenter', handleDragInAttach)
            xx.addEventListener('dragleave', handleDragOutAttach)
            xx.addEventListener('dragover', handleDragAttach)
            xx.addEventListener('drop', handleDropAttach)
        }

    }, [])

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);


    const handleDragAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }


    const handleDragInAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setAttachDrag(true)
        }
    }

    const handleDragOutAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter--
        if (dragAttachCounter === 0) {
            setAttachDrag(false)
        }
    }

    const handleDropAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setAttachDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedAttach(e.dataTransfer.files)
            e.dataTransfer.clearData();
            dragAttachCounter = 0;
        }
    }


    const handleDeleteAttach = (pos: number) => {
        let attachArrayDummy: AttachFileBooklet[] = [...attachArray];
        attachArrayDummy.splice(pos, 1);
        setAttachArray([...attachArrayDummy])
    }




    useEffect(() => {
        if (transferedAttach.length > 0 && attachArray.length === 0) {
            let fileList = [...attachArray];
            let counter = 0;
            for (let i = 0; i < MAX_FILES; i++) {
                if (!transferedAttach[i].name) {
                    return
                }
                let ext = transferedAttach[i].type.split('/').pop();
                if (ext !== 'pdf') {
                    let systemMsg = props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[2];

                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedAttach[i].name))

                } else {
                    counter++;

                    fileList.push({ name: transferedAttach[i].name, content: transferedAttach[i] })
                    let systemMsg = props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[3];
                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))
                }

            }

            setAttachArray(fileList)
        } else if (transferedAttach.length > 0 && attachArray.length > 0) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[4])

        }


    }, [transferedAttach, props.appLanguage])



    const closeAddEventHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid(false);
        setFormError({ titleError: '' });
        setFormInput({ title: '' })
        setFormValid({ titleValid: false })
        setFormFocus({ titleFocus: false })

        let xx = attachDropRef.current;
        if (xx !== null) {
            xx.removeEventListener('dragenter', handleDragInAttach)
            xx.removeEventListener('dragleave', handleDragOutAttach)
            xx.removeEventListener('dragover', handleDragAttach)
            xx.removeEventListener('drop', handleDropAttach)
        }

        setAttachDrag(false);
        setAttachArray([]);
        setTransferedAttach([]);
        dragAttachCounter = 0;
        setTempDisabled(0)

        props.cbMessage(what);

    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, galleryId: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageBooklets.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageBooklets.add.titleAccept;
        }

        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.titleValid && attachArray.length > 0)
    }, [formValid, attachArray])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);


        httpPostBooklet({
            content: attachArray[0].content,
            name: formInput.title
        }).then(
            (res: any) => {
                closeAddEventHandler(GeneralServices.constants.child.worked);
            },
            (err: any) => {
                closeAddEventHandler(GeneralServices.constants.child.failed);
            }

        )

        /*closeAddEventHandler(GeneralServices.constants.child.closed);*/

    }

    let attachBox = "add-booklet-attach "
    if (attachDrag) {
        attachBox = attachBox.concat("droppin")
    }
    const attachFunction = () => {
        if (attachArray.length > 0) {
            return <div>
                {
                    (attachArray.map((file: any, index) =>
                        <div key={index} className="booklet-attach-object">
                            <div className="booklet-attach-object-main">
                                <span className="attach-name">{file.name}</span>
                            </div>
                            <div className="booklet-attach-object-menu">
                                <div className="booklet-attach-object-menu-option">
                                    <TooltipKekax content={props.appLanguage.backoffice.manageBooklets.add.fileOption[0]} direction="top">
                                        <div className="kxicon" onClick={() => handleDeleteAttach(index)} />
                                    </TooltipKekax>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        }
        return null
    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageBooklets.add.titleAccept}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }


    return (
        <React.Fragment>
            <div className="wrapper-add-booklet">
                <form ref={formRef} id='add-booklet-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-add-booklet-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageBooklets.add.information.main}</span></div>

                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageBooklets.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageBooklets.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event, null)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-add-booklet-attach">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageBooklets.add.information.content}</span></div>

                        <div className={attachBox} ref={attachDropRef}>
                            {
                                attachFunction()
                            }

                            {
                                attachDrag ?
                                    <div className="absolute-message">
                                        <span>{props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[1]}</span>
                                    </div>
                                    : attachArray.length === 0 && !attachDrag ?
                                        <div className="absolute-message">
                                            <span>{props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[0]}</span>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                    <div className="container-form-bottom">
                        <div className="submit"><button type="submit" className="submit-btn" disabled={!formSubmitValid || tempDisabled === 1}><span className="info">{props.appLanguage.backoffice.manageBooklets.add.submit}</span></button></div>
                    </div>
                </form>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );
}

export default connect(mapStateToProps)(AddBooklet);