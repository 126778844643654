import React, { useState, useEffect, useRef } from 'react'
import "./AddAuxCountry.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../../services/generalService.json'
import StepperKekax from '../../../../kekax/StepperKekax';
import { httpPostAuxCountry } from '../../../../../services/requestService';
import { IAuxCountry } from '../AuxCountry';

interface IProps extends StoreProps {
    callback: any
    data: IAddAuxCountry
}





export interface IAddAuxCountry {
    open: boolean
    country: IAuxCountry
}



export interface IFormErrorAuxCountry {
    shortNameError: string
    NOLNameError: string
    officialNameError: string
    countryCodeError: string

}
export interface IFormInputAuxCountry {
    shortName: string
    NOLName: string
    officialName: string
    countryCode: string

}
export interface IFormValidAuxCountry {
    shortNameValid: boolean
    NOLNameValid: boolean
    officialNameValid: boolean
    countryCodeValid: boolean


}
export interface IFormFocusAuxCountry {
    shortNameFocus: boolean
    NOLNameFocus: boolean
    officialNameFocus: boolean
    countryCodeFocus: boolean
}

interface IAddAuxCountryValidation {
    main: boolean
}




const FORMSHORTNAMEID: string = 'shortName';
const FORMNOLNAMEID: string = 'NOLName';
const FORMOFFICIALNAMEID: string = 'officialName';
const FORMCOUNTRYCODEID: string = 'countryCode';


export const AddAuxCountry: React.FC<IProps> = (props) => {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);


    const [formSubmitValid, setFormSubmitValid] = useState<IAddAuxCountryValidation>({ main: false });
    const [formError, setFormError] = useState<IFormErrorAuxCountry>({ shortNameError: '', NOLNameError: '', officialNameError: '', countryCodeError: '' });
    const [formInput, setFormInput] = useState<IFormInputAuxCountry>({ shortName: '', NOLName: '', officialName: '', countryCode: '' });
    const [formValid, setFormValid] = useState<IFormValidAuxCountry>({ shortNameValid: false, NOLNameValid: false, officialNameValid: false, countryCodeValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusAuxCountry>({ shortNameFocus: false, NOLNameFocus: false, officialNameFocus: false, countryCodeFocus: false });


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    useEffect(() => {

        if (props.data.open && modalRef.current) {
            modalRef.current.classList.add("addAuxCountry-modal-open");

            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();

        } else {
            modalRef.current.classList.remove("addAuxCountry-modal-open");
        }

    }, [props.data.open])


    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        setTempDisabled(0)
        formEndup();
        props.callback(feedback);
        modalRef.current.classList.remove("addAuxCountry-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--addModalAuxCountry-topOffset", posY + "px");
    }


    const formEndup = () => {
        contentRef.current.reset();
        setFormSubmitValid({ main: false });
        setFormError({ shortNameError: '', NOLNameError: '', officialNameError: '', countryCodeError: '' });
        setFormInput({ shortName: '', NOLName: '', officialName: '', countryCode: '' });
        setFormValid({ shortNameValid: false, NOLNameValid: false, officialNameValid: false, countryCodeValid: false });
        setFormFocus({ shortNameFocus: false, NOLNameFocus: false, officialNameFocus: false, countryCodeFocus: false });

        setTempDisabled(0)


    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMSHORTNAMEID: {
                setFormInput({ ...formInput, shortName: value })
                break;
            }
            case FORMNOLNAMEID: {
                setFormInput({ ...formInput, NOLName: value })
                break;
            }
            case FORMOFFICIALNAMEID: {
                setFormInput({ ...formInput, officialName: value })
                break;
            }
            case FORMCOUNTRYCODEID: {
                setFormInput({ ...formInput, countryCode: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_shortNameValid = formValid.shortNameValid;
        let upd_NOLNameValid = formValid.NOLNameValid;
        let upd_officialNameValid = formValid.officialNameValid;
        let upd_countryCodeValid = formValid.countryCodeValid;

        /*TITLE VALIDATION*/
        if (formInput.shortName === '') {
            upd_shortNameValid = false;
            fieldValidationErrors.shortNameError = props.appLanguage.backoffice.manageMines.aux.country.add.requiredError;
        } else {
            upd_shortNameValid = true;
            fieldValidationErrors.shortNameError = props.appLanguage.backoffice.manageMines.aux.country.add.shortNameAccept;
        }


        /*NOLName VALIDATION*/
        if (formInput.NOLName === '') {
            upd_NOLNameValid = false;
            fieldValidationErrors.NOLNameError = props.appLanguage.backoffice.manageMines.aux.country.add.requiredError;
        } else {
            upd_NOLNameValid = true;
            fieldValidationErrors.NOLNameError = props.appLanguage.backoffice.manageMines.aux.country.add.NOLNameAccept;
        }

        /*officialName VALIDATION*/
        if (formInput.officialName === '') {
            upd_officialNameValid = false;
            fieldValidationErrors.officialNameError = props.appLanguage.backoffice.manageMines.aux.country.add.requiredError;
        } else {
            upd_officialNameValid = true;
            fieldValidationErrors.officialNameError = props.appLanguage.backoffice.manageMines.aux.country.add.officialNameError;
        }

        /*countryCode VALIDATION*/
        if (formInput.countryCode === '') {
            upd_countryCodeValid = false;
            fieldValidationErrors.countryCodeError = props.appLanguage.backoffice.manageMines.aux.country.add.requiredError;
        } else {
            upd_countryCodeValid = true;
            fieldValidationErrors.countryCodeError = props.appLanguage.backoffice.manageMines.aux.country.add.countryCodeAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({ shortNameValid: upd_shortNameValid, NOLNameValid: upd_NOLNameValid, officialNameValid: upd_officialNameValid, countryCodeValid: upd_countryCodeValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])




    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.shortNameValid && formValid.NOLNameValid && formValid.officialNameValid && formValid.countryCodeValid });
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMSHORTNAMEID: {
                setFormFocus({ ...formFocus, shortNameFocus: true })
                break;
            }
            case FORMNOLNAMEID: {
                setFormFocus({ ...formFocus, NOLNameFocus: true })
                break;
            }
            case FORMOFFICIALNAMEID: {
                setFormFocus({ ...formFocus, officialNameFocus: true })
                break;
            }
            case FORMCOUNTRYCODEID: {
                setFormFocus({ ...formFocus, countryCodeFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)

        
        httpPostAuxCountry(
            {
                shortName: formInput.shortName,
                NOLName: formInput.NOLName,
                officialName: formInput.officialName,
                countryCode: formInput.countryCode
            }
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
    
        //closeModalHandler(GeneralServices.constants.child.closed);

    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMSHORTNAMEID: {
                return formFocus.shortNameFocus && !formValid.shortNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.shortNameError}</span>
                    : formValid.shortNameValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.country.add.shortNameAccept}</span>
                        : null
            }
            case FORMNOLNAMEID: {
                return formFocus.NOLNameFocus && !formValid.NOLNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.NOLNameError}</span>
                    : formValid.NOLNameValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.country.add.NOLNameAccept}</span>
                        : null
            }
            case FORMOFFICIALNAMEID: {
                return formFocus.officialNameFocus && !formValid.officialNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.officialNameError}</span>
                    : formValid.officialNameValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.country.add.officialNameAccept}</span>
                        : null
            }
            case FORMCOUNTRYCODEID: {
                return formFocus.countryCodeFocus && !formValid.countryCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.countryCodeError}</span>
                    : formValid.countryCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.country.add.countryCodeAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }



    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (

                <div className="container-add-auxCountry-main">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.aux.country.add.information.main}</span></div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.country.add.shortName} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.country.add.shortNameph}
                                id={FORMSHORTNAMEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.shortName || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSHORTNAMEID)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.country.add.NOLName} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.country.add.NOLNameph}
                                id={FORMNOLNAMEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.NOLName || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMNOLNAMEID)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.country.add.officialName} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.country.add.officialNameph}
                                id={FORMOFFICIALNAMEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.officialName || ""}
                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMOFFICIALNAMEID)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.country.add.countryCode} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.country.add.countryCodeph}
                                id={FORMCOUNTRYCODEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.countryCode || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOUNTRYCODEID)
                                }
                            </div>
                        </div>
                    </div>

                </div>


            )
        } else if (selectedStepper === "1") {
            return null
        } else if (selectedStepper === "2") {
            return null
        }
    }


    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="submit"><button type="submit" disabled={!formSubmitValid.main || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageMines.aux.country.add.submit} </button></div>
                )
            }
            default: {
                return null;
            }
        }
    }


    return (


        <div className="wrapper-add-auxCountry" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>

            <form className="content" ref={contentRef} onSubmit={(event: any) => submitHandler(event)}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.backoffice.manageMines.aux.country.add.header}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <div className="container-stepper">
                        <StepperKekax active={selectedStepper}>
                            <div key="0">{props.appLanguage.backoffice.manageMines.aux.country.add.information.main}</div>
                        </StepperKekax>
                    </div>
                    <div className="container-stepper-body">
                        <div className="container-form-add-auxCountry">

                            {
                                printActiveContent()
                            }


                        </div>
                    </div>

                </div>
                <div className="footer">
                    <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageMines.aux.country.add.close}</span></button></div>
                    {
                        printSubmitButton()

                    }
                </div>
            </form>
        </div>
    );

}


export default connect(mapStateToProps)(AddAuxCountry);
