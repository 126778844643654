import React, { useState, useEffect, useRef } from 'react'
import "./ShowAuxFacilityType.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../../services/generalService.json'
import StepperKekax from '../../../../kekax/StepperKekax';
//import { httpPostAuxFacilityType } from '../../../../../services/requestService';
import { IAddAuxFacilityType } from '../addAuxFacilityType/AddAuxFacilityType';

interface IProps extends StoreProps {
    callback: any
    data: IAddAuxFacilityType
}


interface IValuesAuxFacilityType {
    faciType: string


}





export const ShowAuxFacilityType: React.FC<IProps> = (props) => {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [tempDisabled, setTempDisabled] = useState<number>(0);


    const [formValues, setFormValues] = useState<IValuesAuxFacilityType>({ faciType: '' });



    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    useEffect(() => {

        if (props.data.open && modalRef.current) {
            modalRef.current.classList.add("showAuxFacilityType-modal-open");

            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();
            console.log( props.data)
            setFormValues({ faciType: props.data.facilityType.faciType })

        } else {
            modalRef.current.classList.remove("showAuxFacilityType-modal-open");
        }

    }, [props.data.open])


    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        setTempDisabled(0)
        formEndup();
        props.callback(feedback);
        modalRef.current.classList.remove("showAuxFacilityType-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--showModalAuxFacilityType-topOffset", posY + "px");
    }


    const formEndup = () => {
        setFormValues({ faciType: '' });
        setTempDisabled(0)
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setTempDisabled(1)
        closeModalHandler(GeneralServices.constants.child.closed);

    }



    return (


        <div className="wrapper-show-auxFacilityType" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>

            <div className="content" ref={contentRef}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.backoffice.manageMines.aux.facilityType.show.header}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <div className="container-stepper-body">
                        <div className="container-form-show-auxFacilityType">
                            <div className="container-show-auxFacilityType-main">
                                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.aux.facilityType.add.information.main}</span></div>

                                <div className="main-row">
                                    <div className="input-normal">
                                        <div className="label">
                                            <span>{props.appLanguage.backoffice.manageMines.aux.facilityType.add.facilityType}</span>
                                        </div>
                                        <div className="input-normal-text kekax-input-box"><span>{formValues.faciType}</span></div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>

                </div>
                <div className="footer">
                    <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageMines.aux.facilityType.show.close}</span></button></div>
                </div>
            </div>
        </div>
    );

}


export default connect(mapStateToProps)(ShowAuxFacilityType);
