import axios from 'axios';
import GeneralServices from '../services/generalService.json'

//server url
const server = GeneralServices.serverUrl;
const rootPath: string = '/rest'
const userPath: string = '/user'
const newsPath: string = '/news'
const eventPath: string = '/event'
const bookletPath: string = '/booklet'
const linkPath: string = '/links'
const publicationPath: string = '/publications'
const consortiumPath: string = '/consorcio'
const minePath: string = '/mines'
const inventoryPath: string = '/inventory'
const questionsPath: string = '/questions'
const auxPath: string = '/aux'



//params with 
const cookieParams = { "withCredentials": true };


/* HTTP REQUESTS */

//SIGN UP REQUEST
const userSignUpRequest = server + rootPath + userPath;

export interface dataUserSignUpRequest {
    userId: string
    realName: string
    email: string
    pass: string
    country: string
}

export async function httpUserSignUpRequest(data: dataUserSignUpRequest) {
    try {
        const res = await axios.post(userSignUpRequest, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//SIGN IN REQUEST
const userSignInRequest = server + rootPath + userPath + '/login';

export interface dataUserSignIpRequest {
    u: string
    p: string
    k: boolean
}

export async function httpUserSignInRequest(data: dataUserSignIpRequest) {
    try {
        const res = await axios.post(userSignInRequest, data);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//SIGN OUT REQUEST
const userSignOutRequest = server + rootPath + userPath + '/logout';

export async function httpUserSignOutRequest() {
    try {
        const res = await axios.get(userSignOutRequest, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//USER GET INFO
const getUserInfoRequest = server + rootPath + userPath;

export async function httpGetUserInfo() {
    try {
        const res = await axios.get(getUserInfoRequest, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//USER DELETE ACCOUNT
const userDeleteAccountRequest = server + rootPath + userPath;

export async function httpUserDeleteAccountRequest() {
    try {
        const res = await axios.delete(userDeleteAccountRequest, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//USER DELETE OTHER ACCOUNT
const userDeleteOtherAccountRequest = server + rootPath + userPath + '/{user:id}';

export async function httpUserDeleteOtherAccountRequest(userid: string) {
    try {
        const res = await axios.delete(userDeleteOtherAccountRequest.replace('{user:id}', userid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//USER REFRESH COOKIES
const userRefreshCookiesRequest = server + rootPath + userPath + '/cookie';

export async function httpUserRefreshCookiesRequest() {
    try {
        const res = await axios.get(userRefreshCookiesRequest, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET PROFILE PICTURE REQUEST
export const getUserProfilePicURL = server + rootPath + userPath + '/picture';

export async function httpGetUserUserPhotoRequest() {
    try {
        const res = await axios.head(getUserProfilePicURL, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


export interface dataEditAccountpRequest {
    realName: string
    email: string
    country: boolean
}

//USER EDIT ACCOUNT
const userEditAccountRequest = server + rootPath + userPath

export async function httpUserPutAccountRequest(data: dataEditAccountpRequest) {
    try {
        const res = await axios.put(userEditAccountRequest, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//USER EDIT PWD ACCOUNT
const userEditPwdAccountRequest = server + rootPath + userPath + "/pass"

export async function httpUserPutPwdAccountRequest(pwd: string) {
    try {
        const res = await axios.put(userEditPwdAccountRequest, { i: pwd }, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//CHANGE PROFILE PICTURE REQUEST
export interface dataChangeUserPhoto {
    img: any
    ext: string
}

const changeUserPhotoRequest = server + rootPath + userPath + '/picture';

export async function httpChangeUserPhotoRequest(data: dataChangeUserPhoto) {
    const sendData = new FormData()
    sendData.append('picture', new Blob([data.img], {
        type: "image/" + data.ext
    }));
    //sendData.append('picture', data.img)
    sendData.append('extension', data.ext)
    /*

    sendData.append('extension', new Blob([JSON.stringify(data.ext)], {
        type: "application/json"
    }));
    */
    try {
        const res = await axios.put(changeUserPhotoRequest, sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


/*---------------------NEWS HTTP REQUESTS start--------------------*/

//GET LIST OF NEWS
const getListNews = server + rootPath + newsPath + '/list{query:param}';

export async function httpGetListNews(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getListNews.replace('{query:param}', '');
        } else {
            finalPath = getListNews.replace('{query:param}', queryParam)
        }
        const res = await axios.get(finalPath);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET NEWSLETTER IMAGE
export const getNewsletterImageURL = server + rootPath + newsPath + '/{news:id}/img';

export async function httpGetNewsletterImg(newsid: string) {
    try {
        const res = await axios.head(getNewsletterImageURL.replace('{news:id}', newsid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET NEWSLETTER DETAILS
const getNewsletterDetails = server + rootPath + newsPath + '/{news:id}';

export async function httpGetNewsletterDetails(newsid: string) {
    try {
        const res = await axios.get(getNewsletterDetails.replace('{news:id}', newsid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET NEWSLETTER CONTENT
const getNewsletterContent = server + rootPath + newsPath + '/{news:id}/content?order={contentOrder:id}';

export async function httpGetNewsletterContent(newsid: string, contentId: string) {
    try {
        const res = await axios.get(getNewsletterContent.replace('{news:id}', newsid).replace('{contentOrder:id}', contentId));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET NEWSLETTER ATTACH LIST
const getNewsletterAttachList = server + rootPath + newsPath + '/attach/list{query:param}';

export async function httpGetNewsletterAttachList(queryParam: any) {
    try {
        const res = await axios.get(getNewsletterAttachList.replace('{query:param}', queryParam));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET NEWSLETTER ATTACH
export const getNewsletterAttachUrl = server + rootPath + newsPath + '/attach/{attach:id}'

export async function httpGetNewsletterAttach(attachid: any) {
    try {
        const res = await axios.get(getNewsletterAttachUrl.replace('{attach:id}', attachid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET NEWSLETTER GALLERY LIST
const getNewsletterGalleryList = server + rootPath + newsPath + '/gallery/list{query:param}';

export async function httpGetNewsletterGalleryList(queryParam: any) {
    try {
        const res = await axios.get(getNewsletterGalleryList.replace('{query:param}', queryParam));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET NEWSLETTER GALLERY
export const getNewsletterGalleryUrl = server + rootPath + newsPath + '/gallery/{gallery:id}'

export async function httpGetNewsletterGallery(galleryid: any) {
    try {
        const res = await axios.get(getNewsletterGalleryUrl.replace('{gallery:id}', galleryid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//POST NEWSLETTER
const postNewsletter = server + rootPath + newsPath;

export interface datePostNewsletter {
    title: string
    tag: string
    importance: number
    desc: string
    date: any
}

export async function httpPostNewsletter(data: datePostNewsletter) {
    try {
        const res = await axios.post(postNewsletter, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;

    }
}

//PUT NEWSLETTER
const putNewsletter = server + rootPath + newsPath + '/{news:id}';

export interface datePutNewsletter {
    title: string
    tag: string
    importance: number
    desc: string
    date: any
}

export async function httpPutNewsletter(data: datePutNewsletter, newsid: string) {
    try {
        const res = await axios.put(putNewsletter.replace('{news:id}', newsid), data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;

    }
}

//DELETE NEWSLETTER DETAILS
const deleteNewsletter = server + rootPath + newsPath + '/{news:id}';

export async function httpDeleteNewsletter(newsid: string) {
    try {
        const res = await axios.delete(deleteNewsletter.replace('{news:id}', newsid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT NEWSLETTER IMAGE
const putNewsletterImage = server + rootPath + newsPath + '/{news:id}/img'

export interface DataNewsletterImage {
    img: any
    ext: string
}

export async function httpPutNewsletterImage(data: DataNewsletterImage, newsid: string) {
    const sendData = new FormData()
    sendData.append('picture', new Blob([data.img], {
        type: "image/" + data.ext
    }));
    sendData.append('extension', data.ext)
    try {
        const res = await axios.put(putNewsletterImage.replace('{news:id}', newsid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//REMOVE NEWSLETTER IMAGE
const deleteNewsletterImage = server + rootPath + newsPath + '/{news:id}/img'

export async function httpDeleteNewsletterImage(newsid: string) {
    try {
        const res = await axios.delete(deleteNewsletterImage.replace('{news:id}', newsid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//POST NEWSLETTER GALLERY
const postNewsletterGallery = server + rootPath + newsPath + '/gallery/{news:id}?order={order:id}'

export interface DataNewslettergGallery {
    img: any
    ext: any
    desc: any
}

export async function httpPostNewsletterGallery(data: DataNewslettergGallery, newsid: string, orderid: string) {
    const sendData = new FormData()
    sendData.append('content', new Blob([data.img], {
        type: "image/" + data.ext
    }));
    sendData.append('extension', data.ext)
    sendData.append('desc', data.desc)

    try {
        const res = await axios.post(postNewsletterGallery.replace('{news:id}', newsid).replace('{order:id}', orderid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT NEWSLETTER GALLERY
const putNewsletterGallery = server + rootPath + newsPath + '/gallery/{gallery:id}?order={order:id}'

export interface DataNewslettergGallery {
    img: any
    ext: any
    desc: any
}

export async function httpPutNewsletterGallery(data: DataNewslettergGallery | null, galleryid: string, orderid: string) {
    if (data !== null) {
        const sendData = new FormData()

        if (data.img !== null && data.ext !== null) {
            sendData.append('content', new Blob([data.img], {
                type: "image/" + data.ext
            }));
            sendData.append('extension', data.ext)
        }

        sendData.append('desc', data.desc)

        try {
            const res = await axios.put(putNewsletterGallery.replace('{gallery:id}', galleryid).replace('{order:id}', orderid), sendData, cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    } else {
        try {
            const res = await axios.put(putNewsletterGallery.replace('{gallery:id}', galleryid).replace('{order:id}', orderid), cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    }
}


//DELETE NEWSLETTER GALLERY
const deleteNewsletterGallery = server + rootPath + newsPath + '/gallery/{gallery:id}'

export async function httpDeleteNewsletterGallery(galleryid: string) {
    try {
        const res = await axios.delete(deleteNewsletterGallery.replace('{gallery:id}', galleryid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//PUT NEWSLETTER CONTENT
const putNewsletterContent = server + rootPath + newsPath + '/{news:id}/content?order={order:id}'

export interface DataPutNewsletterContent {
    content: string
}

export async function httpPutNewsletterContent(data: DataPutNewsletterContent, newsid: string, orderid: string) {
    const sendData = new FormData()
    sendData.append('content', data.content)

    try {
        const res = await axios.put(putNewsletterContent.replace('{news:id}', newsid).replace('{order:id}', orderid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE NEWSLETTER CONTENT
const deleteNewsletterContent = server + rootPath + newsPath + '/{news:id}/content?order={order:id}'

export async function httpDeleteNewsletterContent(newsid: string, orderid: string) {
    try {
        const res = await axios.delete(deleteNewsletterContent.replace('{news:id}', newsid).replace('{order:id}', orderid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//POST NEWSLETTER ATTACH
const postNewsletterAttach = server + rootPath + newsPath + '/attach/{news:id}'

export interface DataPostNewsletterAttach {
    content: any
    name: any
}

export async function httpPostNewsletterAttach(data: DataPostNewsletterAttach, newsid: string) {
    const sendData = new FormData()
    sendData.append('content', data.content)
    sendData.append('nome', data.name)


    try {
        const res = await axios.post(postNewsletterAttach.replace('{news:id}', newsid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT NEWSLETTER ATTACH
const puttNewsletterAttach = server + rootPath + newsPath + '/attach/{attach:id}'

export interface DataPutNewsletterAttach {
    content: any
    name: any
}

export async function httpPutNewsletterAttach(data: DataPutNewsletterAttach | null, attachid: string) {
    if (data !== null) {
        const sendData = new FormData()
        sendData.append('content', data.content)
        sendData.append('nome', data.name)
        try {
            const res = await axios.put(puttNewsletterAttach.replace('{attach:id}', attachid), sendData, cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    } else {
        try {
            const res = await axios.put(puttNewsletterAttach.replace('{attach:id}', attachid), cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    }

}


//DELETE NEWSLETTER ATTACH

const deleteNewsletterAttach = server + rootPath + newsPath + '/attach/{attach:id}'

export async function httpDeleteNewsletterAttach(attachid: string) {
    try {
        const res = await axios.delete(deleteNewsletterAttach.replace('{attach:id}', attachid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


/*---------------------NEWS HTTP REQUESTS end--------------------*/


/*---------------------EVENTS HTTP REQUESTS start--------------------*/

//GET LIST OF EVENTS
const getListEvents = server + rootPath + eventPath + '/list{query:param}';

export async function httpGetListEvents(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getListEvents.replace('{query:param}', '');
        } else {
            finalPath = getListEvents.replace('{query:param}', queryParam)
        }
        const res = await axios.get(finalPath);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET EVENTS IMAGE
export const getEventImageUrl = server + rootPath + eventPath + '/{event:id}/img';

export async function httpGetEventImg(eventid: string) {
    try {
        const res = await axios.head(getEventImageUrl.replace('{event:id}', eventid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET EVENTS DETAILS
const getEventDetails = server + rootPath + eventPath + '/{event:id}';

export async function httpGetEventDetails(eventid: string) {
    try {
        const res = await axios.get(getEventDetails.replace('{event:id}', eventid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET EVENT CONTENT
const getEventContent = server + rootPath + eventPath + '/{event:id}/content?order={contentOrder:id}';

export async function httpGetEventContent(eventid: string, contentId: string) {
    try {
        const res = await axios.get(getEventContent.replace('{event:id}', eventid).replace('{contentOrder:id}', contentId));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET EVENT GALLERY LIST
const getEventGalleryList = server + rootPath + eventPath + '/gallery/list{query:param}';

export async function httpGetEventGalleryList(queryParam: any) {
    try {
        const res = await axios.get(getEventGalleryList.replace('{query:param}', queryParam));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET EVENT GALLERY
export const getEventGalleryUrl = server + rootPath + eventPath + '/gallery/{gallery:id}';

export async function httpGetEventGallery(galleryid: any) {
    try {
        const res = await axios.get(getEventGalleryUrl.replace('{gallery:id}', galleryid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET EVENT ATTACH LIST
const getEventAttachList = server + rootPath + eventPath + '/attach/list{query:param}';

export async function httpGetEventAttachList(queryParam: any) {
    try {
        const res = await axios.get(getEventAttachList.replace('{query:param}', queryParam));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//GET EVENT ATTACH
export const getEventAttachUrl = server + rootPath + eventPath + '/attach/{attach:id}';

export async function httpGetEventAttach(attachid: any) {
    try {
        const res = await axios.get(getEventAttachUrl.replace('{attach:id}', attachid));
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//POST EVENT
const postEvent = server + rootPath + eventPath;

export interface datePostEvent {
    title: string
    tag: string
    desc: string
    date: any
    dateEnd: any

}

export async function httpPostEvent(data: datePostEvent) {
    try {
        const res = await axios.post(postEvent, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT EVENT
const putEvent = server + rootPath + eventPath + '/{event:id}';

export interface datePutEvent {
    title: string
    tag: string
    desc: string
    date: any
    dateEnd: any

}

export async function httpPutEvent(data: datePutEvent, eventid: string) {
    try {
        const res = await axios.put(putEvent.replace('{event:id}', eventid), data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;

    }
}


//DELETE EVENT DETAILS
const deleteEvent = server + rootPath + eventPath + '/{event:id}';

export async function httpDeleteEvent(eventid: string) {
    try {
        const res = await axios.delete(deleteEvent.replace('{event:id}', eventid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//PUT EVENT IMAGE
const putEventImage = server + rootPath + eventPath + '/{event:id}/img'

export interface dataEventImage {
    img: any
    ext: string
}

export async function httpPutEventImage(data: dataEventImage, eventid: string) {
    const sendData = new FormData()
    sendData.append('picture', new Blob([data.img], {
        type: "image/" + data.ext
    }));
    sendData.append('extension', data.ext)
    try {
        const res = await axios.put(putEventImage.replace('{event:id}', eventid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//REMOVE EVENT IMAGE
const deleteEventImage = server + rootPath + eventPath + '/{event:id}/img'

export async function httpDeleteEventImage(eventid: string) {
    try {
        const res = await axios.delete(deleteEventImage.replace('{event:id}', eventid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//PUT EVENT CONTENT
const putEventContent = server + rootPath + eventPath + '/{event:id}/content?order={order:id}'

export interface DataPutEventContent {
    content: string
}

export async function httpPutEventContent(data: DataPutEventContent, eventid: string, orderid: string) {
    const sendData = new FormData()
    sendData.append('content', data.content)

    try {
        const res = await axios.put(putEventContent.replace('{event:id}', eventid).replace('{order:id}', orderid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE EVENT CONTENT
const deleteEventContent = server + rootPath + eventPath + '/{event:id}/content?order={order:id}'

export async function httpDeleteEventContent(eventid: string, orderid: string) {
    try {
        const res = await axios.delete(deleteEventContent.replace('{event:id}', eventid).replace('{order:id}', orderid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



//POST EVENT GALLERY
const postEventGallery = server + rootPath + eventPath + '/gallery/{event:id}?order={order:id}'

export interface DataEventGallery {
    img: any
    ext: any
    desc: any
}

export async function httpPostEventGallery(data: DataEventGallery, eventid: string, orderid: string) {
    const sendData = new FormData()
    sendData.append('content', new Blob([data.img], {
        type: "image/" + data.ext
    }));
    sendData.append('extension', data.ext)
    sendData.append('desc', data.desc)

    try {
        const res = await axios.post(postEventGallery.replace('{event:id}', eventid).replace('{order:id}', orderid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//PUT EVENT GALLERY
const putEventGallery = server + rootPath + eventPath + '/gallery/{gallery:id}?order={order:id}'

export interface DataEventGallery {
    img: any
    ext: any
    desc: any
}

export async function httpPutEventGallery(data: DataEventGallery | null, galleryid: string, orderid: string) {
    if (data !== null) {
        const sendData = new FormData()

        if (data.img !== null && data.ext !== null) {
            sendData.append('content', new Blob([data.img], {
                type: "image/" + data.ext
            }));
            sendData.append('extension', data.ext)
        }

        sendData.append('desc', data.desc)

        try {
            const res = await axios.put(putEventGallery.replace('{gallery:id}', galleryid).replace('{order:id}', orderid), sendData, cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    } else {
        try {
            const res = await axios.put(putEventGallery.replace('{gallery:id}', galleryid).replace('{order:id}', orderid), cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    }
}


//DELETE EVENT GALLERY
const deleteEventGallery = server + rootPath + eventPath + '/gallery/{event:id}'

export async function httpDeleteEventGallery(eventid: string) {
    try {
        const res = await axios.delete(deleteEventGallery.replace('{event:id}', eventid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



//POST EVENT ATTACH
const postEventAttach = server + rootPath + eventPath + '/attach/{event:id}'

export interface DataPostEventAttach {
    content: any
    name: any
}

export async function httpPostEventAttach(data: DataPostEventAttach, eventid: string) {
    const sendData = new FormData()
    sendData.append('content', data.content)
    sendData.append('nome', data.name)


    try {
        const res = await axios.post(postEventAttach.replace('{event:id}', eventid), sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT EVENT ATTACH
const putEventAttach = server + rootPath + eventPath + '/attach/{attach:id}'

export interface DataPutEventAttach {
    content: any
    name: any
}

export async function httpPutEventAttach(data: DataPutEventAttach | null, attachid: string) {
    if (data !== null) {
        const sendData = new FormData()
        sendData.append('content', data.content)
        sendData.append('nome', data.name)
        try {
            const res = await axios.put(putEventAttach.replace('{attach:id}', attachid), sendData, cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    } else {
        try {
            const res = await axios.put(putEventAttach.replace('{attach:id}', attachid), cookieParams);
            return res;
        }
        catch (err) {
            throw err.response;
        }
    }

}


//DELETE EVENT ATTACH

const deleteEventAttach = server + rootPath + eventPath + '/attach/{attach:id}'

export async function httpDeleteEventAttach(attachid: string) {
    try {
        const res = await axios.delete(deleteEventAttach.replace('{attach:id}', attachid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


/*---------------------EVENTS HTTP REQUESTS end--------------------*/


/*---------------------BOOKLET HTTP REQUESTS start--------------------*/


//GET Booklet List
const getBooklets = server + rootPath + bookletPath + '/list{query:param}';

export async function httpGetListBooklets(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getBooklets.replace('{query:param}', '');
        } else {
            finalPath = getBooklets.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}


//POST Booklet
const postBooklet = server + rootPath + bookletPath;

export interface dataPostBooklet {
    content: any
    name: any
}

export async function httpPostBooklet(data: dataPostBooklet) {
    const sendData = new FormData()
    sendData.append('content', data.content)
    sendData.append('nome', data.name)

    try {
        const res = await axios.post(postBooklet, sendData, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET Booklet Details
export const getBookletUrl = server + rootPath + bookletPath + '/{booklet:id}';

export async function httpGetBookletDetails(bookletId: string) {
    try {
        return await axios.get(getBookletUrl.replace('{booklet:id}', bookletId), cookieParams);
    }
    catch (err) {
        throw err.response;
    }
}


//PUT Booklet Details
const putBookletDetailsUrl = server + rootPath + bookletPath + '/{booklet:id}';

export interface datePutBooklet {
    i: any
}

export async function httpPutBooklet(data: datePutBooklet, bookletId: string) {
    try {
        const res = await axios.put(putBookletDetailsUrl.replace('{booklet:id}', bookletId), data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//DELETE BOOKLET DETAILS
const deletebookletUrl = server + rootPath + bookletPath + '/{booklet:id}';

export async function httpDeleteBooklet(bookletid: string) {
    try {
        const res = await axios.delete(deletebookletUrl.replace('{booklet:id}', bookletid), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



/*---------------------BOOKLET HTTP REQUESTS end--------------------*/


/*---------------------LINKS HTTP REQUESTS start--------------------*/

//GET Link List
const getLinksUrl = server + rootPath + linkPath + '/list{query:param}';

export async function httpGetListLinks(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getLinksUrl.replace('{query:param}', '');
        } else {
            finalPath = getLinksUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//POST Link
const postLinkUrl = server + rootPath + linkPath;

export interface dataPostLink {
    title: string
    description: string
    website: string
}

export async function httpPostLink(data: dataPostLink) {
    try {
        const res = await axios.post(postLinkUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Link Details
const deleteLinkUrl = server + rootPath + linkPath + '/{link:id}';

export async function httpDeleteLink(linkId: string) {
    try {
        const res = await axios.delete(deleteLinkUrl.replace('{link:id}', linkId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Link Details
const putLinkDetailsUrl = server + rootPath + linkPath;

export interface datePutLink {
    linkId: string
    title: string
    description: string
    website: string
}

export async function httpPutLink(data: datePutLink) {
    try {
        const res = await axios.put(putLinkDetailsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET Link Details
export const getLinkUrl = server + rootPath + linkPath + '/{link:id}';

export async function httpGetLinkDetails(linkId: string) {
    try {
        return await axios.get(getLinkUrl.replace('{link:id}', linkId));
    }
    catch (err) {
        throw err.response;
    }
}


/*---------------------LINKS HTTP REQUESTS end--------------------*/


/*---------------------PUBLICATIONS HTTP REQUESTS start--------------------*/

//GET Publications List
const getPublicationsUrl = server + rootPath + publicationPath + '/list{query:param}';

export async function httpGetListPublications(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getPublicationsUrl.replace('{query:param}', '');
        } else {
            finalPath = getPublicationsUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}


//POST Publication
const postPublicationUrl = server + rootPath + publicationPath;

export interface dataPostPublication {
    year: string
    text1: string
    text2: string
    title: string
    url: string
}

export async function httpPostPublication(data: dataPostPublication) {
    try {
        const res = await axios.post(postPublicationUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Publication Details
const deletePublicationUrl = server + rootPath + publicationPath + '/{publication:id}';

export async function httpDeletePublication(publicationId: string) {
    try {
        const res = await axios.delete(deletePublicationUrl.replace('{publication:id}', publicationId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Publication Details
const putPublicationDetailsUrl = server + rootPath + publicationPath;

export interface datePutPublication {
    publicationId: string
    year: string
    text1: string
    text2: string
    title: string
    url: string
}

export async function httpPutPublication(data: datePutPublication) {
    try {
        const res = await axios.put(putPublicationDetailsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET Publication Details
export const getPublicationUrl = server + rootPath + publicationPath + '/{publication:id}';

export async function httpGetPublicationDetails(publicationId: string) {
    try {
        return await axios.get(getPublicationUrl.replace('{publication:id}', publicationId));
    }
    catch (err) {
        throw err.response;
    }
}

/*---------------------PUBLICATIONS HTTP REQUESTS end--------------------*/

/*---------------------CONSORTIUM HTTP REQUESTS start--------------------*/
//POST Consortium
const postConsortiumUrl = server + rootPath + consortiumPath;

export interface dataPostConsortium {
    title: string
    website: string
    text: string
    img: string
}

export async function httpPostConsortium(data: dataPostConsortium) {
    try {
        const res = await axios.post(postConsortiumUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//GET Consortiums List
const getConsortiumsUrl = server + rootPath + consortiumPath + '/list{query:param}';

export async function httpGetListConsortiums(queryParam: any) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getConsortiumsUrl.replace('{query:param}', '');
        } else {
            finalPath = getConsortiumsUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//GET Consortium Details
export const getConsortiumUrl = server + rootPath + consortiumPath + '/{consortium:id}';

export async function httpGetConsortiumDetails(consortiumId: string) {
    try {
        return await axios.get(getConsortiumUrl.replace('{consortium:id}', consortiumId));
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Consortium Details
const putConsortiumDetailsUrl = server + rootPath + consortiumPath;

export interface datePutConsortium {
    id: string
    title: string
    website: string
    text: string
    img: string
}

export async function httpPutConsortium(data: datePutConsortium) {
    try {
        const res = await axios.put(putConsortiumDetailsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Consortium Details
const deleteConsortiumUrl = server + rootPath + consortiumPath + '/{consortium:id}';

export async function httpDeleteConsortium(consortiumId: string) {
    try {
        const res = await axios.delete(deleteConsortiumUrl.replace('{consortium:id}', consortiumId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



/*---------------------CONSORTIUM HTTP REQUESTS end--------------------*/


/*---------------------AUX HTTP REQUESTS start--------------------*/

//GET All Aux Country
export const getAuxCountryAllUrl = server + rootPath + auxPath + '/countries{query:param}';

export async function httpGetAuxCountryAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxCountryAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxCountryAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux Country
export const deleteAuxCountryUrl = server + rootPath + auxPath + '/countries/{countries:id}';

export async function httpDeleteAuxCountry(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxCountryUrl.replace('{countries:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxCountry {
    shortName: string
    NOLName: string
    officialName: string
    countryCode: string
}

//POST Aux Country
export const postAuxCountryUrl = server + rootPath + auxPath + '/countries';

export async function httpPostAuxCountry(data: datePostAuxCountry) {
    try {
        const res = await axios.post(postAuxCountryUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

/*-----------------------------*/

//GET All Aux FacilityType
export const getAuxFacilityTypeAllUrl = server + rootPath + auxPath + '/faciType{query:param}';

export async function httpGetAuxFacilityTypeAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxFacilityTypeAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxFacilityTypeAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux FacilityType
export const deleteAuxFacilityTypeUrl = server + rootPath + auxPath + '/faciType/{faciType:id}';

export async function httpDeleteAuxFacilityType(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxFacilityTypeUrl.replace('{faciType:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxFacilityType {
    faciType: string
}

//POST Aux FacilityType
export const postAuxFacilityTypeUrl = server + rootPath + auxPath + '/faciType';

export async function httpPostAuxFacilityType(data: datePostAuxFacilityType) {
    try {
        const res = await axios.post(postAuxFacilityTypeUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

/*-----------------------------*/

//GET All Aux MineType
export const getAuxMineTypeAllUrl = server + rootPath + auxPath + '/mineType{query:param}';

export async function httpGetAuxMineTypeAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxMineTypeAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxMineTypeAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux MineType
export const deleteAuxMineTypeUrl = server + rootPath + auxPath + '/mineType/{mineType:id}';

export async function httpDeleteAuxMineType(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxMineTypeUrl.replace('{mineType:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxMineType {
    type: string
    desc: string
    explanation: string
}

//POST Aux MineType
export const postAuxMineTypeUrl = server + rootPath + auxPath + '/mineType';

export async function httpPostAuxMineType(data: datePostAuxMineType) {
    try {
        const res = await axios.post(postAuxMineTypeUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


/*-----------------------------*/

//GET All Aux OfficialLanguage
export const getAuxOfficialLanguageAllUrl = server + rootPath + auxPath + '/officialLanguages{query:param}';

export async function httpGetAuxOfficialLanguageAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxOfficialLanguageAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxOfficialLanguageAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux OfficialLanguage
export const deleteAuxOfficialLanguageUrl = server + rootPath + auxPath + '/officialLanguages/{officialLanguage:id}';

export async function httpDeleteAuxOfficialLanguage(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxOfficialLanguageUrl.replace('{officialLanguage:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxOfficialLanguage {
    language: string
}

//POST Aux OfficialLanguage
export const postAuxOfficialLanguageUrl = server + rootPath + auxPath + '/officialLanguages';

export async function httpPostAuxOfficialLanguage(data: datePostAuxOfficialLanguage) {
    try {
        const res = await axios.post(postAuxOfficialLanguageUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


/*-----------------------------*/

//GET All Aux Situation
export const getAuxSituationAllUrl = server + rootPath + auxPath + '/situation{query:param}';

export async function httpGetAuxSituationAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxSituationAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxSituationAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux Situation
export const deleteAuxSituationUrl = server + rootPath + auxPath + '/situation/{situation:id}';

export async function httpDeleteAuxSituation(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxSituationUrl.replace('{situation:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxSituation {
    situation: string
}

//POST Aux Situation
export const postAuxSituationUrl = server + rootPath + auxPath + '/situation';

export async function httpPostAuxSituation(data: datePostAuxSituation) {
    try {
        const res = await axios.post(postAuxSituationUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

/*-----------------------------*/

//GET All Aux Categories
export const getAuxCategoriesAllUrl = server + rootPath + auxPath + '/categories{query:param}';

export async function httpGetAuxCategoriesAll(queryParam: any) {

    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = getAuxCategoriesAllUrl.replace('{query:param}', '');
        } else {
            finalPath = getAuxCategoriesAllUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);

    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Aux Categories
export const deleteAuxCategoriesUrl = server + rootPath + auxPath + '/categories/{categories:id}';

export async function httpDeleteAuxCategories(auxId: string) {
    try {
        const res = await axios.delete(deleteAuxCategoriesUrl.replace('{categories:id}', auxId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

export interface datePostAuxCategories {
    category: string
    desc: string
    explanation: string
}

//POST Aux Categories
export const postAuxCategoriesUrl = server + rootPath + auxPath + '/categories';

export async function httpPostAuxCategories(data: datePostAuxCategories) {
    try {
        const res = await axios.post(postAuxCategoriesUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}
/*---------------------AUX HTTP REQUESTS end--------------------*/


//Get Mine Inventory
const getMines = server + rootPath + minePath + inventoryPath;

export async function httpGetAllMines() {
    try {
        return await axios.get(getMines);
    } catch (err) {
        throw err.response;
    }
}

//POST Mine Inventory
export const postMineInventoryUrl = server + rootPath + minePath + inventoryPath;

export async function httpPostMineInventory(data: dataPostMineInventory) {
    try {
        const res = await axios.post(postMineInventoryUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Mine Inventory

export async function httpPutMineInventory(data: dataPostMineInventory) {
    try {
        const res = await axios.put(postMineInventoryUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//DELETE Mine Inventory

export const deleteMineInventoryUrl = server + rootPath + minePath + inventoryPath + '/{query:param}';


export async function httpDeleteMineInventory(queryParam: string) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineInventoryUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineInventoryUrl.replace('{query:param}', queryParam)
        }

        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


export interface dataPostMineFacility {
    id:string
    mineCode: string
    faciType: string
    faciName: string
    faciName2: string
    faciPopDesc: string
    category: string
    mineType: string
    situation: string
    openingYear: string
    otherMineUses: string
    visitRoute: string
    timeNeed: string
    routeDesc: string
    guided: string
    typeFreeTour: string
    typeAdventure: string
    typeAdapted: string
    eduresourBrochures: string
    eduresourExplainChild: string
    eduresourExplainDisabled: string
    eduresourAudioVisual: string
    eduresourARVR: string
    eduresourWorkshopChild: string
    eduresourWorkshopYoung: string
    eduresourWorkshopDisabled: string
    eduresourWorkshopAdventure: string
    language: string
    english: string
}

//POST Mine Facility/SiteInfo
export const postMineFacilityUrl = server + rootPath + minePath + '/siteInfo';

export async function httpPostMineFacility(data: dataPostMineFacility) {
    try {
        const res = await axios.post(postMineFacilityUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Mine Facility/SiteInfo

export async function httpPutMineFacility(data: dataPostMineFacility) {
    try {
        const res = await axios.put(postMineFacilityUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//DELETE Mine Facility/SiteInfo

export const deleteMineFacilityUrl = server + rootPath + minePath + '/siteInfo/{query:param}';

export async function httpDeleteMineFacility(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineFacilityUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineFacilityUrl.replace('{query:param}', queryParam)
        }

        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


//GET Mine Facility/SiteInfo

export const getMineFacilityUrl = server + rootPath + minePath + '/siteInfo?mineCode={query:param}';

export async function httpGetMineFacility(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = getMineFacilityUrl.replace('{query:param}', '');
        } else {
            finalPath = getMineFacilityUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}



export interface dataPostSiteLegends {
    id:string
    mineCode: string
    legendDatePeriod: string
    storyDesc: string
}


//POST Mine SiteLegends
export const postMineSiteLegendsUrl = server + rootPath + minePath + '/siteLegends';

export async function httpPostMineSiteLegends(data: dataPostSiteLegends) {
    try {
        const res = await axios.post(postMineSiteLegendsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Mine SiteLegends

export async function httpPutMineSiteLegends(data: dataPostSiteLegends) {
    try {
        const res = await axios.put(postMineSiteLegendsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



//DELETE Mine SiteLegends

export const deleteMineSiteLegendsUrl = server + rootPath + minePath + '/siteLegends/{query:param}';


export async function httpDeleteMineSiteLegends(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineSiteLegendsUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineSiteLegendsUrl.replace('{query:param}', queryParam)
        }

        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


//GET Mine SiteLegends

export const getMineSiteLegendsUrl = server + rootPath + minePath + '/siteLegends?mineCode={query:param}';


export async function httpGetMineSiteLegends(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = getMineSiteLegendsUrl.replace('{query:param}', '');
        } else {
            finalPath = getMineSiteLegendsUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}



export interface dataPostSiteHistoricEvents {
    id:string
    mineCode: string
    datePeriod: string
    event: string
}

//POST Mine Site Historic Events
export const postMineSiteHistoricEventsUrl = server + rootPath + minePath + '/siteHistoricEvents';

export async function httpPostMineSiteHistoricEvents(data: dataPostSiteHistoricEvents) {
    try {
        const res = await axios.post(postMineSiteHistoricEventsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Mine Site Historic Events

export async function httpPutMineSiteHistoricEvents(data: dataPostSiteHistoricEvents) {
    try {
        const res = await axios.put(postMineSiteHistoricEventsUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//DELETE Mine Site Historic Events

export const deleteMineSiteHistoricEventsUrl = server + rootPath + minePath + '/siteHistoricEvents/{query:param}';

export async function httpDeleteMineSiteHistoricEvents(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineSiteHistoricEventsUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineSiteHistoricEventsUrl.replace('{query:param}', queryParam)
        }

        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


//GET Mine Site Historic Events

export const getMineSiteHistoricEventsUrl = server + rootPath + minePath + '/siteHistoricEvents?mineCode={query:param}';


export async function httpGetMineSiteHistoricEvents(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = getMineSiteHistoricEventsUrl.replace('{query:param}', '');
        } else {
            finalPath = getMineSiteHistoricEventsUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}



export interface dataPostMineProduction {
    id:string
    mineCode: string
    faciCode: string
    product: string
    mainOres: string
    workingPeriod: string
    productDestination: string
    productDestination2: string
}

//POST MineProduction
export const postMineProductionUrl = server + rootPath + minePath + '/mineProduction';

export async function httpPostMineProduction(data: dataPostMineProduction) {
    try {
        const res = await axios.post(postMineProductionUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT MineProduction

export async function httpPutMineProduction(data: dataPostMineProduction) {
    try {
        const res = await axios.put(postMineProductionUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}



//DELETE MineProduction
export const deleteMineProductionUrl = server + rootPath + minePath + '/mineProduction/{query:param}';

export async function httpDeleteMineProduction(queryParam: string) {
    try {
        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineProductionUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineProductionUrl.replace('{query:param}', queryParam)
        }
        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


//GET MineProduction

export const getMineProductionUrl = server + rootPath + minePath + '/mineProduction?mineCode={query:param}';

export async function httpGetMineProdution(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = getMineProductionUrl.replace('{query:param}', '');
        } else {
            finalPath = getMineProductionUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}


export interface dataPostBibliography {
    id:string
    mineCode: string
    biblioSource: string
    webInfo: string
    otherBibInfo: string
}

//POST Mine Bibliograpghy
export const postMineBibliographyUrl = server + rootPath + minePath + '/biblioInfo';

export async function httpPostMineBibliography(data: dataPostBibliography) {
    try {
        const res = await axios.post(postMineBibliographyUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT Mine Bibliograpghy

export async function httpPutMineBibliography(data: dataPostBibliography) {
    try {
        const res = await axios.put(postMineBibliographyUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//DELETE Mine Bibliography

export const deleteMineBibliographyUrl = server + rootPath + minePath + '/biblioInfo/{query:param}';

export async function httpDeleteMineBibliography(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = deleteMineBibliographyUrl.replace('{query:param}', '');
        } else {
            finalPath = deleteMineBibliographyUrl.replace('{query:param}', queryParam)
        }

        return await axios.delete(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}

//GET Mine Bibliography

export const getMineBibliographyUrl = server + rootPath + minePath + '/biblioInfo?mineCode={query:param}';

export async function httpGetMineBibliography(queryParam: string) {
    try {

        let finalPath = '';
        if (queryParam === null) {
            finalPath = getMineBibliographyUrl.replace('{query:param}', '');
        } else {
            finalPath = getMineBibliographyUrl.replace('{query:param}', queryParam)
        }

        return await axios.get(finalPath);
    }
    catch (err) {
        throw err.response;
    }
}



//GET Mine Question
const getQuestionMine = server + rootPath + questionsPath + '/list/{mine:id}';

export async function httpGetQuestionMine(mineId: string) {
    try {
        const res = await axios.get(getQuestionMine.replace('{mine:id}', mineId));
        return res;
    } catch (err) {
        throw err.response;
    }
}

export interface dataPostMineInventory {
    id:string
    website: string
    mineCode: string
    siteNameENG: string
    siteNameNOL: string
    country: string
    regionStateProvince: string
    municipality: string
    lat: number
    lng: number
    touristicname: string
    minePopDesc: string
    complex: string
    complexName: string
    mineHeritageRoute: string
    projectFig: string
    infoFigure: string
    carPark: string
    cafeteria: string
    giftShop: string
    address: string
    address2: string
    postalCode: string
    phone: string
    mail: string
    otherInfoCont: string
    pubTransport: string
    pubTransportDesc: string
}


//POST Mine Question

export interface dataPostMineQuestion {
    mineCode: string
    question: string
    ansA: string
    ansB: string
    ansC: string
    ansD: string
    ansE: string
    correctAns: string
    explanation: string
    institute: string
    photo: string
}

//POST MineProduction
export const postMineQuestionUrl = server + rootPath + questionsPath;

export async function httpPostMineQuestion(data: dataPostMineQuestion) {
    try {
        const res = await axios.post(postMineQuestionUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}

//PUT MineProduction

export async function httpPutMineQuestion(data: dataPostMineQuestion) {
    try {
        const res = await axios.put(postMineQuestionUrl, data, cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}


//DELETE Mine Question
export const deleteMineQuestionUrl = server + rootPath + minePath + '/{question:id}';

export async function httpDeleteMineQuestion(paramId: string) {
    try {
        const res = await axios.delete(deleteMineQuestionUrl.replace('{question:id}', paramId), cookieParams);
        return res;
    }
    catch (err) {
        throw err.response;
    }
}
