import { SET_LANGUAGE, SetLanguageTypes } from "./types";
import {LanguageJson} from "../../services/languages/langTypes";
import defaultState from '../../services/languages/uk.json';

const initialState: LanguageJson = defaultState;

export type languageReducerType = typeof languageReducer

function languageReducer(state = initialState, action: SetLanguageTypes): LanguageJson {
  switch (action.type) {
    case SET_LANGUAGE: {
      state = {
        ...state,
        ...action.data,
      };
      break;
    }
    default: {
      break;
    }

  }
  return state;
};
export default languageReducer;
