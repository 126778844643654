import React, { useState, useEffect, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './QuestionPage.css'

import TooltipKekax from '../../../kekax/TooltipKekax';
import { IGameHelper, IGameRound, IRound, ITalkAboutOption, maxFreezeTime } from '../QuizPage';



interface IProps extends StoreProps {
    time: number
    roundInfo: IRound
    gameHelper: IGameHelper
    gameRound: IGameRound
    breakTime: number

    sendAnswer: any
    gameHelperManager: any

    talkAboutAnswer: number
    talkAboutOption: ITalkAboutOption

}

export const alphabet = ["A", "B", "C", "D", "E"] //, "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]


export const QuestionPage: React.FC<IProps> = (props) => {

    const [answer, setAnswer] = useState<number>(-1)
    const answersRef = useRef<any>([]);
    const optionsRef = useRef<any>([]);
    const freezeRef = useRef<any>([]);


    const handleAnswer = (option: number) => {
        props.sendAnswer(option)
    }

    useEffect(() => {


        if (props.talkAboutAnswer >= 0 && props.talkAboutAnswer < props.roundInfo.answers.length) { //respondeu
            for (let i = 0; i < answersRef.current.length; i++) {
                if (answersRef.current[i] !== undefined && answersRef.current[i] !== null) {
                    answersRef.current[i].classList.remove("quiz-answer-anime")
                }
            }
            if (answersRef.current[props.talkAboutAnswer] !== undefined && answersRef.current[props.talkAboutAnswer] !== null) {
                answersRef.current[props.talkAboutAnswer].classList.add("blinky")
            }
            setAnswer(props.talkAboutAnswer)

        } else if (props.talkAboutAnswer === -1337) { //há miss
            for (let i = 0; i < answersRef.current.length; i++) {
                if (answersRef.current[i] !== undefined && answersRef.current[i] !== null) {
                    answersRef.current[i].classList.remove("quiz-answer-anime")
                    answersRef.current[i].classList.add("blinky")
                }
            }
        } else if (props.talkAboutAnswer === -69) { //right or wrong

            if (answer !== -1 && answersRef.current[answer] !== undefined && answersRef.current[answer] !== null) {
                answersRef.current[answer].classList.remove("blinky")

                if (props.roundInfo.correct === alphabet[answer]) {
                    answersRef.current[answer].classList.add("quiz-answer-correct")
                } else {
                    answersRef.current[answer].classList.add("quiz-answer-wrong")

                    const correctAnswer: number = findSolutionIndex()
                    if (answersRef.current[correctAnswer] !== undefined && answersRef.current[correctAnswer] !== null) {
                        answersRef.current[correctAnswer].classList.add("quiz-answer-correct")
                    }

                }
            } else {
                for (let i = 0; i < answersRef.current.length; i++) {
                    if (answersRef.current[i] !== undefined && answersRef.current[i] !== null) {
                        answersRef.current[i].classList.remove("blinky")
                    }
                }
                const correctAnswer: number = findSolutionIndex()
                if (answersRef.current[correctAnswer] !== undefined && answersRef.current[correctAnswer] !== null) {
                    answersRef.current[correctAnswer].classList.add("quiz-answer-correct")
                }
            }



        } else if (props.talkAboutAnswer === -99) { //bye animation
            for (let i = 0; i < answersRef.current.length; i++) {
                if (answersRef.current[i] !== undefined && answersRef.current[i] !== null) {
                    answersRef.current[i].classList.add("quiz-answer-bye")
                }
            }

        } else if (props.talkAboutAnswer === -1) { //next round reset
            for (let i = 0; i < answersRef.current.length; i++) {
                if (answersRef.current[i] !== undefined && answersRef.current[i] !== null) {
                    answersRef.current[i].classList.remove("quiz-answer-correct")
                    answersRef.current[i].classList.remove("quiz-answer-wrong")
                    answersRef.current[i].classList.remove("quiz-answer-hidden")
                    answersRef.current[i].classList.remove("quiz-answer-bye")
                    answersRef.current[i].classList.add("quiz-answer-anime")

                }
            }

            setAnswer(-1)
        }

    }, [props.talkAboutAnswer, props.roundInfo])

    const findSolutionIndex: any = () => {
        for (let i = 0; i < alphabet.length; i++) {
            if (alphabet[i] === props.roundInfo.correct) {
                return i
            }
        }
        return -1
    }



    useEffect(() => {


        if (props.talkAboutOption.option === 0 && optionsRef.current[props.talkAboutOption.option] !== undefined && optionsRef.current[props.talkAboutOption.option] !== null) {
            console.log("aaa")

            optionsRef.current[props.talkAboutOption.option].classList.remove("active")

            let answersAvailable: number[] = []
            const correctAnswer: number = findSolutionIndex()

            for (let x = 0; x < props.roundInfo.answers.length; x++) {
                if (x !== correctAnswer) {
                    answersAvailable.push(x)
                }
            }


            let howManyToCut = Math.floor(props.roundInfo.answers.length / 2);

            if (howManyToCut < 1) {
                howManyToCut = 1;
            }
            let answersToRmv: number[] = [];
            for (let i = 0; i < howManyToCut; i++) {
                if (answersToRmv.length === 0) {
                    let result = answersAvailable[Math.floor(Math.random() * answersAvailable.length)];
                    answersToRmv.push(result)
                } else {
                    let keepCicle = true;
                    do {
                        let foundIt = false;
                        let resultIndex = Math.floor(Math.random() * answersAvailable.length)
                        let result = answersAvailable[resultIndex];
                        for (let x = 0; x < answersToRmv.length; x++) {
                            if (answersToRmv[x] === result) {
                                foundIt = true;
                                break;
                            }
                        }
                        if (!foundIt) {
                            keepCicle = false;
                            answersToRmv.push(result)
                            answersAvailable.splice(resultIndex, 1)
                        }
                    }
                    while (keepCicle);
                }

            }

            let messageToQuizPage = ""
            for (let z = 0; z < answersToRmv.length; z++) {


                if (answersRef.current[answersToRmv[z]] !== undefined && answersRef.current[answersToRmv[z]] !== null) {
                    messageToQuizPage = messageToQuizPage.concat(answersToRmv[z] + ";");
                    answersRef.current[answersToRmv[z]].classList.add("quiz-answer-hidden")
                }
            }

            if (messageToQuizPage !== undefined && messageToQuizPage !== "") {
                messageToQuizPage = messageToQuizPage.slice(0, -1)
                props.gameHelperManager({ id: props.talkAboutOption.option, info: "answers;" + messageToQuizPage })
            }



        } else if (props.talkAboutOption.option === 1 && optionsRef.current[props.talkAboutOption.option] !== undefined && optionsRef.current[props.talkAboutOption.option] !== null && freezeRef.current !== null) {
            if (props.talkAboutOption.show) {
                optionsRef.current[props.talkAboutOption.option].classList.remove("active")
                freezeRef.current.classList.add("active")
            } else {
                freezeRef.current.classList.remove("active")
            }
        } else if (props.talkAboutOption.option === 2 && optionsRef.current[props.talkAboutOption.option] !== undefined && optionsRef.current[props.talkAboutOption.option] !== null) {
            optionsRef.current[props.talkAboutOption.option].classList.remove("active")
        }
    }, [props.talkAboutOption])


    const handleClickHelp = (help: any) => {
        props.gameHelperManager({ id: help, info: "" })
    }


    const freezeTimeTooltip = props.appLanguage.quizPage.quiz.freezeHelper.tooltip[0] + maxFreezeTime + props.appLanguage.quizPage.quiz.freezeHelper.tooltip[1]
    return (
            <div className="wrapper-quiz-question">
                <div className="wrap-the-info"> </div>
                <div className="wrap-the-quiz">
                    <div className="container-quiz-question">
                        <div className="quiz-menu">

                            <div className="options">

                                <div className="fifty-option active" ref={el => optionsRef.current[0] = el}>
                                    <TooltipKekax content={props.appLanguage.quizPage.quiz.fiftyHelper} direction="top">
                                        <button onClick={() => handleClickHelp(0)} className="fifty"></button>
                                    </TooltipKekax>
                                </div>

                                <div className="freeze-option active" ref={el => optionsRef.current[1] = el} >
                                    <TooltipKekax content={freezeTimeTooltip} direction="top">
                                        <button onClick={() => handleClickHelp(1)} className="freeze"></button>
                                    </TooltipKekax>
                                </div>

                                <div className="call-option active" ref={el => optionsRef.current[2] = el} >
                                    <TooltipKekax content={props.appLanguage.quizPage.quiz.godCallHelper.tooltip} direction="top">
                                        <button onClick={() => handleClickHelp(2)} className="call"></button>
                                    </TooltipKekax>
                                </div>

                            </div>
                            <div className="info">

                                <div className="help">
                                    <TooltipKekax content={props.appLanguage.quizPage.quiz.help} direction="top">
                                        <button className="question"></button>
                                    </TooltipKekax>
                                </div>

                                <div className="board">
                                    <TooltipKekax content={props.appLanguage.quizPage.quiz.rounds} direction="top">
                                        <div className="rounds"><span>{props.gameRound.nRound + "/" + props.gameRound.maxRound}</span></div>
                                    </TooltipKekax>
                                </div>
                            </div>

                        </div>
                        <div className="quiz-question">
                            <div className="outside-black"><div className="quiz-text"><span>{props.roundInfo.question}</span></div></div>
                            <div className="inside-white"><div className="inside-white-left"></div><div className="inside-white-right"></div></div>
                        </div>
                        <div className="quiz-timer" >
                            <span className="time">{props.time}</span>
                            <div className="freeze" ref={freezeRef}>
                                <div className="freeze-text">
                                    <span>{props.appLanguage.quizPage.quiz.freezeHelper.info}</span>
                                </div>
                                <div className="freeze-time">
                                    <span>{props.breakTime}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-quiz-answers">
                        {
                            props.roundInfo.answers.map((answer: any, index: number) => (
                                <div key={"answer" + index} className="quiz-answer quiz-answer-anime" ref={el => answersRef.current[index] = el} onClick={() => handleAnswer(index)}>
                                    <div className="outside-black"><div className="quiz-text"><div className="answer"><span >{props.roundInfo.answers[index].id}</span></div><div className="text"><span >{props.roundInfo.answers[index].answer}</span></div></div></div>
                                    <div className="inside-white"><div className="inside-white-left"></div><div className="inside-white-right"></div></div>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div >


    );

}

export default connect(mapStateToProps)(QuestionPage);


//disabled={props.gameHelper.call !== -1 ? true : false}