import React, { useState, useEffect, useRef } from 'react'
import "./PublicationManage.css"
import GeneralServices from "../../../services/generalService.json"

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import GraphPublication from './graphPublication/GraphPublication';
/*
import AddBooklet from './addBooklet/AddBooklet';
import ListBookletStats from './listBookletStats/ListBookletStats';
*/
import SnackKekax from '../../kekax/SnackKekax';
import ListPublicationStats from './listPublicationStats/ListPublicationStats';
import AddPublication from './addPublication/AddPublication';



interface IProps extends StoreProps {

}

interface SnackKekax {
    open: boolean
    message: string
}

interface OptionPainel {
    id: string
    class: string
    selected: boolean
    icon: any
    title: string
}

const STATS_SHOW: string = "STATS_SHOW";
const STATS_LIST: string = "STATS_LIST";
const STATS_ADD: string = "STATS_ADD";
const OPTION_DEFAULT_CLASS: string = "stats-opt";
const OPTION_SELECTED_CLASS: string = "stats-opt opt-selected";





export const PublicationManage: React.FC<IProps> = (props) => {


    const [statsOption, setStatsOption] = useState<string>(STATS_LIST);
    const [painelOptions, setPainelOptions] = useState<OptionPainel[]>([]);

    const snackKekaxRef: any = useRef();


    const PainelOptionsStatic: OptionPainel[] = [
        /*{ id: STATS_SHOW, class: OPTION_SELECTED_CLASS, selected: true, icon: <div className="opt-icon icon-chart" />, title: props.appLanguage.backoffice.managePublications.graph },*/
        { id: STATS_LIST, class: OPTION_SELECTED_CLASS, selected: true, icon: <div className="opt-icon icon-list" />, title: props.appLanguage.backoffice.managePublications.listTab },
        { id: STATS_ADD, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-add" />, title: props.appLanguage.backoffice.managePublications.addTab },
        { id: STATS_SHOW, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-chart" />, title: props.appLanguage.backoffice.managePublications.graphTab }
    ]


    useEffect(() => {
        if (painelOptions.length === 0) {
            setPainelOptions([...PainelOptionsStatic]);

        } else {
            let copyPainel = [...painelOptions]
            for (let x = 0; x < copyPainel.length; x++) {
                if (copyPainel[x].id === STATS_ADD) {
                    copyPainel[x].title = props.appLanguage.backoffice.managePublications.addTab
                } else if (copyPainel[x].id === STATS_LIST) {
                    copyPainel[x].title = props.appLanguage.backoffice.managePublications.listTab
                } else if (copyPainel[x].id === STATS_SHOW) {
                    copyPainel[x].title = props.appLanguage.backoffice.managePublications.graphTab
                }
            }
            setPainelOptions([...copyPainel]);
        }
    }, [props.appLanguage])


    const handleChangeOptionPainel = (id: string) => {
        let newOptionArray: OptionPainel[] = [];
        for (let x in painelOptions) {
            const option: OptionPainel = painelOptions[x];
            if (option.id === id) {
                newOptionArray.push({ ...option, class: OPTION_SELECTED_CLASS, selected: true })
            } else {
                newOptionArray.push({ ...option, class: OPTION_DEFAULT_CLASS, selected: false })
            }
        }

        setPainelOptions(newOptionArray);
        setStatsOption(id);
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
    }


    const cbAddPublicationFunction = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.managePublications.add.submitSuccess)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.managePublications.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
        }
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })

    }




    return (
        <React.Fragment>
            <div className="wrapper-bo-manage-publication">
                <div className="container-stats-flex">
                    <div className="stats-menu">
                        {
                            painelOptions.map((option: OptionPainel, index: number) => (
                                <div className={option.class} onClick={() => handleChangeOptionPainel(option.id)} key={index}>
                                    {option.icon}
                                    <span className="opt-text">{option.title}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="stats-display">
                    {
                            statsOption === STATS_ADD ?
                                <AddPublication cbMessage={cbAddPublicationFunction} />
                                : statsOption === STATS_SHOW ?
                                    <GraphPublication />
                                    : statsOption === STATS_LIST ?
                                        <ListPublicationStats /> : null

                        }
                    </div>
                </div>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(PublicationManage);
