import React, { useState, useEffect, useRef } from 'react'
import "./AddAuxMineTypes.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../../services/generalService.json'
import StepperKekax from '../../../../kekax/StepperKekax';
import { IAuxMineTypes } from '../AuxMineTypes';
import { httpPostAuxMineType } from '../../../../../services/requestService';
//import { httpPostAuxMineTypes } from '../../../../../services/requestService';

interface IProps extends StoreProps {
    callback: any
    data: IAddAuxMineTypes
}





export interface IAddAuxMineTypes {
    open: boolean
    mineTypes:IAuxMineTypes
}



export interface IFormErrorAuxMineTypes {
    typeError: string
    descError: string
    explanationError: string

}
export interface IFormInputAuxMineTypes {
    type: string
    desc: string
    explanation: string

}
export interface IFormValidAuxMineTypes {
    typeValid: boolean
    descValid: boolean
    explanationValid: boolean


}
export interface IFormFocusAuxMineTypes {
    typeFocus: boolean
    descFocus: boolean
    explanationFocus: boolean
}

interface IAddAuxMineTypesValidation {
    main: boolean
}




const FORMTYPEID: string = 'type';
const FORMDESCID: string = 'desc';
const FORMEXPLANATIONID: string = 'explanation';


export const AddAuxMineTypes: React.FC<IProps> = (props) => {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);


    const [formSubmitValid, setFormSubmitValid] = useState<IAddAuxMineTypesValidation>({ main: false });
    const [formError, setFormError] = useState<IFormErrorAuxMineTypes>({ typeError: '', descError: '', explanationError: '', });
    const [formInput, setFormInput] = useState<IFormInputAuxMineTypes>({ type: '', desc: '', explanation: ''});
    const [formValid, setFormValid] = useState<IFormValidAuxMineTypes>({ typeValid: false, descValid: false, explanationValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusAuxMineTypes>({ typeFocus: false, descFocus: false, explanationFocus: false });


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    useEffect(() => {

        if (props.data.open && modalRef.current) {
            modalRef.current.classList.add("addAuxMineTypes-modal-open");

            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();

        } else {
            modalRef.current.classList.remove("addAuxMineTypes-modal-open");
        }

    }, [props.data.open])


    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        setTempDisabled(0)
        formEndup();
        props.callback(feedback);
        modalRef.current.classList.remove("addAuxMineTypes-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--addModalAuxMineTypes-topOffset", posY + "px");
    }


    const formEndup = () => {
        contentRef.current.reset();
        setFormSubmitValid({ main: false });
        setFormError({ typeError: '', descError: '', explanationError: '', });
        setFormInput({ type: '', desc: '', explanation: ''});
        setFormValid({ typeValid: false, descValid: false, explanationValid: false });
        setFormFocus({ typeFocus: false, descFocus: false, explanationFocus: false });

        setTempDisabled(0)


    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTYPEID: {
                setFormInput({ ...formInput, type: value })
                break;
            }
            case FORMDESCID: {
                setFormInput({ ...formInput, desc: value })
                break;
            }
            case FORMEXPLANATIONID: {
                setFormInput({ ...formInput, explanation: value })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_typeValid = formValid.typeValid;
        let upd_descValid = formValid.descValid;
        let upd_explanationValid = formValid.explanationValid;

        /*TITLE VALIDATION*/
        if (formInput.type === '') {
            upd_typeValid = false;
            fieldValidationErrors.typeError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.requiredError;
        } else {
            upd_typeValid = true;
            fieldValidationErrors.typeError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.typeAccept;
        }


        /*desc VALIDATION*/
        if (formInput.desc === '') {
            upd_descValid = false;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.requiredError;
        } else {
            upd_descValid = true;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.descAccept;
        }

        /*explanation VALIDATION*/
        if (formInput.explanation === '') {
            upd_explanationValid = false;
            fieldValidationErrors.explanationError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.requiredError;
        } else {
            upd_explanationValid = true;
            fieldValidationErrors.explanationError = props.appLanguage.backoffice.manageMines.aux.mineTypes.add.explanationError;
        }


        setFormError(fieldValidationErrors)
        setFormValid({ typeValid: upd_typeValid, descValid: upd_descValid, explanationValid: upd_explanationValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])




    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.typeValid && formValid.descValid && formValid.explanationValid});
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTYPEID: {
                setFormFocus({ ...formFocus, typeFocus: true })
                break;
            }
            case FORMDESCID: {
                setFormFocus({ ...formFocus, descFocus: true })
                break;
            }
            case FORMEXPLANATIONID: {
                setFormFocus({ ...formFocus, explanationFocus: true })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)
        httpPostAuxMineType(
            {
                type: formInput.type,
                desc: formInput.desc,
                explanation: formInput.explanation            }
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
        
        //closeModalHandler(GeneralServices.constants.child.closed);

    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTYPEID: {
                return formFocus.typeFocus && !formValid.typeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.typeError}</span>
                    : formValid.typeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.typeAccept}</span>
                        : null
            }
            case FORMDESCID: {
                return formFocus.descFocus && !formValid.descValid ?
                    <span className="input-invalid-feedback-kekax">{formError.descError}</span>
                    : formValid.descValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.descAccept}</span>
                        : null
            }
            case FORMEXPLANATIONID: {
                return formFocus.explanationFocus && !formValid.explanationValid ?
                    <span className="input-invalid-feedback-kekax">{formError.explanationError}</span>
                    : formValid.explanationValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.explanationAccept}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }



    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (

                <div className="container-add-auxMineTypes-main">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.information.main}</span></div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.mineTypes.add.type} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.mineTypes.add.typeph}
                                id={FORMTYPEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.type || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMTYPEID)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.mineTypes.add.desc} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.mineTypes.add.descph}
                                id={FORMDESCID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.desc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMDESCID)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.mineTypes.add.explanation} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.mineTypes.add.explanationph}
                                id={FORMEXPLANATIONID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.explanation || ""}
                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMEXPLANATIONID)
                                }
                            </div>
                        </div>
                    </div>


                </div>


            )
        } else if (selectedStepper === "1") {
            return null
        } else if (selectedStepper === "2") {
            return null
        }
    }


    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="submit"><button type="submit" disabled={!formSubmitValid.main || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.submit} </button></div>
                )
            }
            default: {
                return null;
            }
        }
    }


    return (


        <div className="wrapper-add-auxMineTypes" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>

            <form className="content" ref={contentRef} onSubmit={(event: any) => submitHandler(event)}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.header}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <div className="container-stepper">
                        <StepperKekax active={selectedStepper}>
                            <div key="0">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.information.main}</div>
                        </StepperKekax>
                    </div>
                    <div className="container-stepper-body">
                        <div className="container-form-add-auxMineTypes">

                            {
                                printActiveContent()
                            }


                        </div>
                    </div>

                </div>
                <div className="footer">
                    <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageMines.aux.mineTypes.add.close}</span></button></div>
                    {
                        printSubmitButton()

                    }
                </div>
            </form>
        </div>
    );

}


export default connect(mapStateToProps)(AddAuxMineTypes);
