import * as React from 'react'
import './Videos.css';


import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

interface IProps extends StoreProps { }

const listVideoIds: string[] = [
    "YTp_yIRB9pQ" //La Mine d'Argent de l'Argentière l'Abessée - Hauts-Alps, France
    /*
    "skne8JBfpPM", //Las Médulas - León, Spai
    "Z90-BoKXllU", //Industrial stroll along the Ourthe River - Liège, Belgium
    "jqDVNeB7tcg", //Porto Flavia - Sardinia, Italy
    "42Zn0lF7qN8", //Riotinto Mining Park - Andalusia, Spain
    "bNUP7iSgopw", //Phosphorite Mining in Estonia
    "b5X_g2Aw15o", //Tharsis - Andalusia, Spain
    "Bkx0Jj_zwMM", //Galleria Henry - Sardinia, Italy
    "izE8NMMs3S4", //Złoty Stok - Lower Silesia Province, Poland
    "rkHUkymLS_Y", //ADVENTURE CALLS in Agordo, Belluno, Italy - Exploring Precious Metals Mines of the ALPS
    "lgpTGcFoJCA", //3D Mapping of the Ancient Aveleira Mine | Tibães Mine - Braga, Portugal
    "aBmrN3OmEsk", //Baňa Starovšechsvätých - Banská Bystrica Region, Slovakia
    "Z1MAb4HvCRw", //Aljustrel Mine - Alentejo, Portugal
    "4KAnKy7jUzQ", //Radoboj - Krapina-Zagorje County, Croatia
    "RKrED6JZOLs" //Trgovska Gora - Sisak-Moslavina County, Croatia
    */
]


export const Videos: React.FC<IProps> = (props) => {

    const youtubeLink = "https://www.youtube.com/embed/"
    //const flags = "?autoplay=0&loop=0&mute=0&controls=1"
    //const playlist = "?list=PLw9crcBYEAhvElGBwBFh_-TOrCSK2hJGz"
    const activeFlags = "?rel=0&autoplay=0&controls=1&list=PLw9crcBYEAhvElGBwBFh_-TOrCSK2hJGz"

    const [allVideos, setAllVideos] = useState<string[]>([])
    //const [actualDisplayed, setActualDisplayed] = useState<number>(0);
    //const [reachedMaxPage, setReachedMaxPage] = useState<boolean>(false);

    //const limitPerPage: number = 4

    useEffect(() => {
        setAllVideos([...listVideoIds])
        //setActualDisplayed(limitPerPage)
    }, [])

    /*
    const handleShowMore = () => {
        const toShow = (actualDisplayed + limitPerPage) <= allVideos.length ? (actualDisplayed + limitPerPage) : allVideos.length;
        setActualDisplayed(toShow)
    }
    */
   /*
    useEffect(() => {
        if (allVideos.length !== 0 && actualDisplayed !== 0 && allVideos.length === actualDisplayed) {
            //setReachedMaxPage(true)
        }
    }, [actualDisplayed])
    */

    return (
        <div className="wrapper-videos">
            <div className="container-header">
                <div className="content">
                    <span>{props.appLanguage.videos.header[0]}</span>
                </div>
            </div>
            <div className="container-videos">
                <div className="video-box">
                    <div className="video-title">
                        <span>{props.appLanguage.videos.title[0]}
                        </span><span>{props.appLanguage.videos.title[1]}</span>
                    </div>
                        <div className="video">
                            <iframe title="video-frame" className="responsive-iframe" allowFullScreen frameBorder="0" allow="autoplay;encrypted-media"
                                src={youtubeLink + allVideos[0] + activeFlags}>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
    );

}

export default connect(mapStateToProps)(Videos);
/*

                {
                    allVideos.map((id: string, index: number) => (
                        index < actualDisplayed ?
                            <div className="video-box" key={"video" + id}>
                                <div className="video">
                                    <iframe className="responsive-iframe" allowFullScreen frameBorder="0" allow="autoplay;encrypted-media"
                                        src={youtubeLink + id + activeFlags}>
                                    </iframe>
                                </div>
                            </div>
                            : null
                    ))
                }


                                {
                    !reachedMaxPage ?
                        <div className="show-more">
                            <button className="button-show" onClick={handleShowMore}>{props.appLanguage.documents.show}</button>
                        </div>
                        : null
                }

*/