import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mineStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import './LegendsHistoryEventsEdit.css';

import GeneralServices from "../../../../../services/generalService.json"
import TooltipKekax from '../../../../kekax/TooltipKekax';

interface IProps extends StoreProps {
    formInput: any
    formError: any
    formValid: any
    formFocus: any
    injectData: IInjectData
    formValidation: any

}



export interface IFormErrorLegendsHistoryEvents {
    timePeriodLegendError: string
    storyLegendDescError: string
    datePeriodHistoricRecordError: string
    historyEventDescError: string


}
export interface IFormInputLegendsHistoryEvents {
    idSiteLegends:string
    idSiteHistoricEvents:string
    timePeriodLegend: string
    storyLegendDesc: string
    datePeriodHistoricRecord: string
    historyEventDesc: string
}

export interface IFormValidLegendsHistoryEvents {
    timePeriodLegendValid: boolean
    storyLegendDescValid: boolean
    datePeriodHistoricRecordValid: boolean
    historyEventDescValid: boolean
}

export interface IFormFocusLegendsHistoryEvents {
    timePeriodLegendFocus: boolean
    storyLegendDescFocus: boolean
    datePeriodHistoricRecordFocus: boolean
    historyEventDescFocus: boolean
}

interface IInjectData {
    injectInput: IFormInputLegendsHistoryEvents | null
    injectValid: IFormValidLegendsHistoryEvents | null
    injectFocus: IFormFocusLegendsHistoryEvents | null
    injectError: IFormErrorLegendsHistoryEvents | null
}


const FORMTIMEPERIODLEGENDID: string = 'timePeriodLegend'
const FORMSTORYLEGENDDESCID: string = 'storyLegendDesc'
const FORMDATEPERIODHISTORICRECORDID: string = 'datePeriodHistoricRecord'
const FORMHISTORYEVENTDESCID: string = 'historyEventDesc'


export const LegendsHistoryEventsEdit: React.FC<IProps> = (props) => {

    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(true);

    const [formInput, setFormInput] = useState<IFormInputLegendsHistoryEvents>({idSiteLegends:'',idSiteHistoricEvents:'', timePeriodLegend: '', storyLegendDesc: '', datePeriodHistoricRecord: '', historyEventDesc: '' });
    const formInputRef = useRef<any>(null)

    const [formError, setFormError] = useState<IFormErrorLegendsHistoryEvents>({ timePeriodLegendError: '', storyLegendDescError: '', datePeriodHistoricRecordError: '', historyEventDescError: '' });
    const formErrorRef = useRef<any>(null)

    const [formValid, setFormValid] = useState<IFormValidLegendsHistoryEvents>({ timePeriodLegendValid: false, storyLegendDescValid: false, datePeriodHistoricRecordValid: false, historyEventDescValid: false });
    const formValidRef = useRef<any>(null)

    const [formFocus, setFormFocus] = useState<IFormFocusLegendsHistoryEvents>({ timePeriodLegendFocus: false, storyLegendDescFocus: false, datePeriodHistoricRecordFocus: false, historyEventDescFocus: false });
    const formFocusRef = useRef<any>(null)


    /*
    useEffect(() => {
        if (props.injectData.injectValid !== null && props.injectData.injectError !== null && props.injectData.injectFocus !== null && props.injectData.injectInput !== null) {
            setFormValid(props.injectData.injectValid)
            setFormError(props.injectData.injectError)

            setFormFocus(props.injectData.injectFocus)
            setFormInput(props.injectData.injectInput)
        }

    }, [props.injectData])
    */
    useEffect(() => {
        if (props.injectData.injectValid !== null && props.injectData.injectError !== null && props.injectData.injectFocus !== null && props.injectData.injectInput !== null) {
            setFormValid(props.injectData.injectValid)
            setFormError(props.injectData.injectError)

            setFormFocus(props.injectData.injectFocus)
            setFormInput(props.injectData.injectInput)
        }
        return () => {
            props.formError(formErrorRef.current)
            props.formFocus(formFocusRef.current)
            props.formInput(formInputRef.current)
            props.formValid(formValidRef.current)

        }
    }, [])

    useEffect(() => {
        props.formValidation(formSubmitValid)
    }, [formSubmitValid])

    useEffect(() => {
        formInputRef.current = formInput
    }, [formInput])
    useEffect(() => {
        formValidRef.current = formValid
    }, [formValid])
    useEffect(() => {
        formFocusRef.current = formFocus
    }, [formFocus])
    useEffect(() => {
        formErrorRef.current = formError
    }, [formError])





    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {

            case FORMTIMEPERIODLEGENDID: {
                setFormInput({ ...formInput, timePeriodLegend: value })
                break;
            }
            case FORMSTORYLEGENDDESCID: {
                setFormInput({ ...formInput, storyLegendDesc: value })

                break;
            }
            case FORMDATEPERIODHISTORICRECORDID: {
                setFormInput({ ...formInput, datePeriodHistoricRecord: value })

                break;
            }
            case FORMHISTORYEVENTDESCID: {
                setFormInput({ ...formInput, historyEventDesc: value })

                break;
            }


            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors: IFormErrorLegendsHistoryEvents = ({ timePeriodLegendError: '', storyLegendDescError: '', datePeriodHistoricRecordError: '', historyEventDescError: '' });


        let upd_timePeriodLegendValid = false;
        if (formInput.timePeriodLegend === '') {
            upd_timePeriodLegendValid = false;
            fieldValidationErrors.timePeriodLegendError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_timePeriodLegendValid = true;
            fieldValidationErrors.timePeriodLegendError = props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.timePeriodLegendAccept;
        }

        let upd_storyLegendDescValid = false;
        if (formInput.storyLegendDesc === '') {
            upd_storyLegendDescValid = false;
            fieldValidationErrors.storyLegendDescError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_storyLegendDescValid = true;
            fieldValidationErrors.storyLegendDescError = props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.storyLegendDescAccept;
        }

        let upd_datePeriodHistoricRecordValid = false;
        if (formInput.datePeriodHistoricRecord === '') {
            upd_datePeriodHistoricRecordValid = false;
            fieldValidationErrors.datePeriodHistoricRecordError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_datePeriodHistoricRecordValid = true;
            fieldValidationErrors.datePeriodHistoricRecordError = props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.datePeriodHistoricRecordAccept;
        }

        let upd_historyEventDescValid = false;
        if (formInput.historyEventDesc === '') {
            upd_historyEventDescValid = false;
            fieldValidationErrors.historyEventDescError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_historyEventDescValid = true;
            fieldValidationErrors.historyEventDescError = props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.historyEventDescAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({
            timePeriodLegendValid: upd_timePeriodLegendValid, storyLegendDescValid: upd_storyLegendDescValid, datePeriodHistoricRecordValid: upd_datePeriodHistoricRecordValid, historyEventDescValid: upd_historyEventDescValid
        })

        //eslint-disable-next-line
    }, [formInput, formFocus])



    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTIMEPERIODLEGENDID: {
                setFormFocus({ ...formFocus, timePeriodLegendFocus: true })
                break;
            }
            case FORMSTORYLEGENDDESCID: {
                setFormFocus({ ...formFocus, storyLegendDescFocus: true })
                break;
            }
            case FORMDATEPERIODHISTORICRECORDID: {
                setFormFocus({ ...formFocus, datePeriodHistoricRecordFocus: true })
                break;
            }

            case FORMHISTORYEVENTDESCID: {
                setFormFocus({ ...formFocus, historyEventDescFocus: true })
                break;
            }



            default: {
                break;
            }
        }//switch end
    }


    const validatorFeedback = (formId: any) => {

        switch (formId) {
            /*INVENTORY*/
            case FORMTIMEPERIODLEGENDID: {
                return formFocus.timePeriodLegendFocus && !formValid.timePeriodLegendValid ?
                    <span className="input-invalid-feedback-kekax">{formError.timePeriodLegendError}</span>
                    : formValid.timePeriodLegendValid ?
                        <span className="input-valid-feedback-kekax">{formError.timePeriodLegendError}</span>
                        : null
            }


            case FORMSTORYLEGENDDESCID: {
                return formFocus.storyLegendDescFocus && !formValid.storyLegendDescValid ?
                    <span className="input-invalid-feedback-kekax">{formError.storyLegendDescError}</span>
                    : formValid.storyLegendDescValid ?
                        <span className="input-valid-feedback-kekax">{formError.storyLegendDescError}</span>
                        : null
            }
            case FORMDATEPERIODHISTORICRECORDID: {
                return formFocus.datePeriodHistoricRecordFocus && !formValid.datePeriodHistoricRecordValid ?
                    <span className="input-invalid-feedback-kekax">{formError.datePeriodHistoricRecordError}</span>
                    : formValid.datePeriodHistoricRecordValid ?
                        <span className="input-valid-feedback-kekax">{formError.datePeriodHistoricRecordError}</span>
                        : null
            }
            case FORMHISTORYEVENTDESCID: {
                return formFocus.historyEventDescFocus && !formValid.historyEventDescValid ?
                    <span className="input-invalid-feedback-kekax">{formError.historyEventDescError}</span>
                    : formValid.historyEventDescValid ?
                        <span className="input-valid-feedback-kekax">{formError.historyEventDescError}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }



    return (
        <div className="wrapper-edit-mine-legendsHistoryEvents">

            <div className="container-legendsHistoryEvents-form">
                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.legendsHistoryEvents.firstTitle}</span></div>

                <div className="main-row">
                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.timePeriodLegend}

                        </label>
                        <input className="input-normal-text kekax-input-box"
                            type="text"
                            placeholder={props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.timePeriodLegendph}
                            id={FORMTIMEPERIODLEGENDID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.timePeriodLegend || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMTIMEPERIODLEGENDID)
                            }
                        </div>
                    </div>
                </div>

                <div className="main-row">

                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.storyLegendDesc}
                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.storyLegendDescph}
                            id={FORMSTORYLEGENDDESCID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.storyLegendDesc || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMSTORYLEGENDDESCID)
                            }
                        </div>
                    </div>
                </div>
                <div className="main-row">

                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.datePeriodHistoricRecord}
                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.datePeriodHistoricRecordph}
                            id={FORMDATEPERIODHISTORICRECORDID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.datePeriodHistoricRecord || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMDATEPERIODHISTORICRECORDID)
                            }
                        </div>
                    </div>
                </div>

                <div className="main-row">
                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.historyEventDesc}

                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.legendsHistoryEvents.historyEventDescph}
                            id={FORMHISTORYEVENTDESCID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.historyEventDesc || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMHISTORYEVENTDESCID)
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>


    )
}


export default connect(mineStateToProps)(LegendsHistoryEventsEdit);