import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import './SideNav.css';
import GeneralServices from '../../../services/generalService.json'
import { mapStateToProps, StoreStateProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';

interface IProps extends StoreProps {
  opened: boolean;
  callSideNav: any;
}


export const SideNav: React.FC<IProps> = (props) => {

  const [opened, setOpened] = useState<boolean>(false);
  const kekaxSideNav: any = useRef(null);


  useEffect(() => {
    setOpened(props.opened)
  }, [props.opened])

  useEffect(() => {
    if (!opened) {
      props.callSideNav(false);
    }
    //eslint-disable-next-line
  }, [opened])



  const closeHandler = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault();

    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setOpened(false)

  }



  const eventClickAway = (event: React.KeyboardEvent | React.MouseEvent) => {
    if ((event.target as HTMLElement) !== kekaxSideNav.current) {
      setOpened(false)
    }
  }

  const eventScrollAway = () => {
    setOpened(false)
  }


  useEffect(() => {
    if (opened) {
      document.documentElement.style.setProperty("--sideNav-display", "block");
      window.addEventListener('click', (event: any) => eventClickAway(event), false);
      window.addEventListener('scroll', eventScrollAway, false);


    } else {
      document.documentElement.style.setProperty("--sideNav-display", "none");
      window.removeEventListener('click', (event: any) => eventClickAway(event), false);
      window.removeEventListener('scroll', eventScrollAway, false);


    }
  }, [opened])

  return (
    <div className="wrapper-sidenav">
      <button className="sidenav-button" onClick={closeHandler}>
        <div className="menu-icon" />
      </button>
      <div onClick={closeHandler} onKeyDown={closeHandler} className='menu-wrapper' ref={kekaxSideNav}>
        <div className='menu-container'>
          <NavLink to={GeneralServices.homePath} className='menu-option'>
            <div className='home-icon' />
            <span className='text'>{props.appLanguage.navbar.home}</span>
          </NavLink>

          <NavLink to={GeneralServices.calendarEventsPath} className='menu-option'>
            <div className='calendar-icon' />
            <span className='text'>{props.appLanguage.navbar.calendarEvents}</span>
          </NavLink>

          <NavLink to={GeneralServices.newsPath} className='menu-option'>
            <div className='flag-icon' />
            <span className='text'>{props.appLanguage.navbar.news}</span>
          </NavLink>

          <NavLink to={GeneralServices.mineMapPath} className='menu-option'>
            <div className='explore-icon' />
            <span className='text'>{props.appLanguage.navbar.mineMap}</span>
          </NavLink>

          <NavLink to={GeneralServices.projectBackPath} className='menu-option'>
            <div className='info-icon' />
            <span className='text'>{props.appLanguage.navbar.projectBack}</span>
          </NavLink>

          <NavLink to={GeneralServices.consortiumPath} className='menu-option'>
            <div className='consortium-icon' />
            <span className='text'>{props.appLanguage.navbar.consortium}</span>
          </NavLink>

          <NavLink to={GeneralServices.documentsPath} className='menu-option'>
            <div className='document-icon' />
            <span className='text'>{props.appLanguage.navbar.documents}</span>
          </NavLink>
          
          <NavLink to={GeneralServices.publicationsPath} className='menu-option'>
            <div className='lightbulb-icon' />
            <span className='text'>{props.appLanguage.navbar.publications}</span>
          </NavLink>

          <NavLink to={GeneralServices.linksPath} className='menu-option'>
            <div className='links-icon' />
            <span className='text'>{props.appLanguage.navbar.links}</span>
          </NavLink>

          <NavLink to={GeneralServices.gamePagePath} className='menu-option'>
            <div className='game-icon' />
            <span className='text'>{props.appLanguage.navbar.game}</span>
          </NavLink>

          <NavLink to={GeneralServices.videosPath} className='menu-option'>
            <div className='videos-icon' />
            <span className='text'>{props.appLanguage.navbar.videos}</span>
          </NavLink>

          <NavLink to={GeneralServices.modelsPath} className='menu-option'>
            <div className='models-icon' />
            <span className='text'>{props.appLanguage.navbar.models}</span>
          </NavLink>

        </div>
        {
          (props.sessionRole === GeneralServices.roles.manager) || (props.sessionRole === GeneralServices.roles.admin) ?
            <div className='menu-container sub-menu-container'>
              <NavLink to={GeneralServices.backOfficePath} className='menu-option'>
                <div className='admin-icon' />
                <span className='text'>{props.appLanguage.navbar.backOffice}</span>
              </NavLink>
            </div>
            :
            null
        }
      </div>
    </div >
  );

}

export default connect(mapStateToProps)(SideNav);
