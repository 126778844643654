import React, { useState, useEffect } from 'react';
import "./FutureEvents.css"
import { IEvent } from '../eventPage/EventPage';
import { httpGetListEvents, httpGetEventImg, getEventImageUrl } from '../../../services/requestService';
import { getTime } from 'date-fns';
import dummyEvent from '../../../assets/news/newsDelta.png'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from './../../../store/storeApp';
import { connect } from 'react-redux';
import ListEvents, { maxEventArray } from '../listEvents/ListEvents';

interface IProps extends StoreProps {
}


export const FutureEvents: React.FC<IProps> = (props) => {

    const [eventsArray, setEventsArray] = useState<IEvent[]>([]);
    const [totalEvents, setTotalEvents] = useState<number>(0);


    useEffect(() => {

        httpGetListEvents('?from=' + getTime(new Date()) + '&limit=' + maxEventArray).then(
            res => {
                //SETTING TOTAL EVENT
                if (res.headers.total !== undefined) {
                    setTotalEvents(parseInt(res.headers.total, 10));
                } else {
                    setTotalEvents(parseInt('1', 10));
                }
                const dataArray: IEvent[] = [...res.data];
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].img === undefined) {
                            httpGetEventImg(dataArray[i].id).then(
                                (res: any) => {
                                    dataArray[i].img = getEventImageUrl.replace('{event:id}', dataArray[i].id)
                                    setEventsArray([...dataArray]);
                                }
                            ).catch(
                                (err: any) => {
                                    dataArray[i].img = dummyEvent
                                    setEventsArray([...dataArray]);
                                }
                            )
                        } else {
                            setEventsArray([...dataArray]);
                        }
                    }
                }
            }
        ).catch(
            err => {
            }
        )
    }, [])

    const cbPageChangeFunction = (childData: any) => {
        httpGetListEvents('?page=' + childData + '&from=' + getTime(new Date()) + '&limit=' + maxEventArray).then(
            res => {
                const dataArray: IEvent[] = [...res.data];
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].img === undefined) {
                            httpGetEventImg(dataArray[i].id).then(
                                (res: any) => {
                                    dataArray[i].img = getEventImageUrl.replace('{event:id}', dataArray[i].id)
                                    setEventsArray([...dataArray]);
                                }
                            ).catch(
                                (err: any) => {
                                    dataArray[i].img = dummyEvent
                                    setEventsArray([...dataArray]);
                                }
                            )
                        } else {
                            setEventsArray([...dataArray]);
                        }
                    }
                }
            }
        ).catch(
            err => {
            }
        )
    }




    return (
        <div className="wrapper-future-event">
            <ListEvents eventsArray={eventsArray} totalEvents={totalEvents} cbPageChange={cbPageChangeFunction} />
        </div>
    );

}

export default connect(mapStateToProps)(FutureEvents);
