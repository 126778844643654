import React, { useState } from 'react';
import "./TooltipInfo.css"

interface IProps {
    children: any
    direction?: any
    content?: string[]
    delay?: any
}

export function TooltipInfo(props: IProps) {

    let timeout: any;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div
            className="wrapper-tooltip-info" onMouseEnter={showTip} onMouseLeave={hideTip}>
            {props.children}
            {active && (
                <div className={`tooltip-object ${props.direction || "top"}`}>
                    {
                        props.content ?
                            props.content.map((content: string, index: number) => (
                                <span key={"lineToolInfo"+index}>{content}</span>
                            ))
                            : null


                    }
                </div>
            )}
        </div>
    );

}

export default TooltipInfo
