import React, { useState, useEffect } from 'react';
import Background from './background/Background';
import InfoApp from './infoApp/InfoApp';
import MoreInfoApp from './moreinfoapp/MoreInfoApp';
import Perks from './perks/Perks';
import Goddess from './goddess/Goddess';
import "./Home.css";
import { RouteComponentProps } from 'react-router';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';


interface IProps extends RouteComponentProps<any>, StoreProps {
}

export const Home: React.FC<IProps> = (props) => {

    const [infoAppTrigger, setInfoAppTrigger] = useState<boolean>(false);
    const [infoAppRef, setInfoAppRef] = useState<any>(null);

    const [perksTrigger, setPerksTrigger] = useState<boolean>(false);
    const [perksRef, setPerksRef] = useState<any>(null);

    const [moreInfoAppTrigger, setMoreInfoAppTrigger] = useState<boolean>(false);
    const [moreInfoAppRef, setMoreInfoAppRef] = useState<any>(null);

    useEffect(() => {
        if (infoAppRef && perksRef && moreInfoAppRef) {
            window.addEventListener('scroll', handleScroll, false);

            return () => {
                window.removeEventListener('scroll', handleScroll, false);
            }
        }

    }, [infoAppRef, perksRef, moreInfoAppRef])

    const handleScroll = () => {
        const posY = window.pageYOffset || document.documentElement.scrollTop;
        //const posX = window.pageXOffset || document.documentElement.scrollLeft;

        const hitInfoApp = posY > infoAppRef.offsetTop - window.innerHeight / 1.3;
        setInfoAppTrigger(hitInfoApp);

        const hitPerks = posY > perksRef.offsetTop - window.innerHeight / 1.1;
        setPerksTrigger(hitPerks);

        const hitMoreInfoApp = posY > moreInfoAppRef.offsetTop - window.innerHeight / 1.1;
        setMoreInfoAppTrigger(hitMoreInfoApp);

    }


    const getInfoAppRef = (childData: any) => {
        setInfoAppRef(childData);
    }

    const getPerkspRef = (childData: any) => {
        setPerksRef(childData);
    }

    const getMoreInfoApppRef = (childData: any) => {
        setMoreInfoAppRef(childData);
        //console.log(childData)
    }


    return (
        <div className="wrapper-homepage">
            <Background />

            <InfoApp myRef={getInfoAppRef} triggerAnime={infoAppTrigger} />
            <Perks myRef={getPerkspRef} triggerAnime={perksTrigger} routerHistory={props.history}/>

            <MoreInfoApp myRef={getMoreInfoApppRef} triggerAnime={moreInfoAppTrigger} />
            <Goddess />
        </div>

    );
}


export default connect(mapStateToProps)(Home);
