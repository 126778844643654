import React, { useEffect, useRef, useState } from 'react';
import "./FireStaffModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../services/generalService.json'
import { httpUserDeleteAccountRequest, httpUserDeleteOtherAccountRequest } from '../../../../services/requestService';
import SnackKekax from '../../../kekax/SnackKekax';

interface IProps extends StoreProps {
    open: boolean
    callback: any
}

interface IFormError {
    username: string
}

interface IFormInput {
    username: string
}

interface IFormValid {

    usernameValid: boolean
}

interface IFormFocus {
    usernameFocus: boolean
}

const FORMUSERNAMEID: string = 'usernameFire';

export function FireStaffModal(props: IProps) {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);
    const submitRef: any = useRef(null);
    const snackKekaxRef: any = useRef();

    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormError>({ username: '' });
    const [formInput, setFormInput] = useState<IFormInput>({ username: '' });
    const [formValid, setFormValid] = useState<IFormValid>({ usernameValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocus>({ usernameFocus: false });

    const [tempDisabled, setTempDisabled] = useState<number>(0);


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);


    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("fire-staff-modal-open");
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);

            handleOffset();
        } else {
            modalRef.current.classList.remove("fire-staff-modal-open");
        }
    }, [props.open])




    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--fireStaffModal-topOffset", posY + "px");
    }

    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(false);
        }
        */

        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        modalRef.current.classList.remove("fire-staff-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);

        //RESET FORM
        setTempDisabled(0)
        setFormSubmitValid(false);

        setFormError({ username: '' });
        setFormInput({ username: '' })
        setFormValid({ usernameValid: false })
        setFormFocus({ usernameFocus: false })
        props.callback(feedback)


    }


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMUSERNAMEID: {
                setFormInput({ ...formInput, username: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {

        let fieldValidationErrors = formError;
        let upd_usernameValid = formValid.usernameValid;

        /*USERNAME VALIDATION*/
        if (formInput.username === '') {
            upd_usernameValid = false;
            fieldValidationErrors.username = props.appLanguage.backoffice.manageAdmins.fire.required;
        } else {
            upd_usernameValid = true;
            //fieldValidationErrors.username = props.appLanguage.register.userAccept;
            fieldValidationErrors.username = ""
        }
        

        setFormError(fieldValidationErrors)
        setFormValid({ usernameValid: upd_usernameValid })

        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMUSERNAMEID: {
                setFormFocus({ ...formFocus, usernameFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end

    }


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.usernameValid)
    }, [formValid])


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setTempDisabled(1);
        httpUserDeleteOtherAccountRequest(formInput.username).then(
            res => {
                console.log(res.data)
                if(res.data === GeneralServices.errorStatus.adminImmune){
                    snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageAdmins.fire.adminImmune)

                }else{
                    closeModalHandler(GeneralServices.constants.child.worked);
                 }   
                
            }
        ).catch(
            e => {
                const errorStatus = e.status;
                if (errorStatus === GeneralServices.errorStatus.forbidden) {
                    snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageAdmins.fire.forbidden)
                } else if (errorStatus === GeneralServices.errorStatus.notFound) {
                    snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageAdmins.fire.noFound)
                } else {
                    closeModalHandler(GeneralServices.constants.child.failed);
                }
            }
        )

    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMUSERNAMEID: {
                return formFocus.usernameFocus && !formValid.usernameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.username}</span>
                    : formValid.usernameValid ? <span className="input-valid-feedback-kekax">{formError.username}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }



    return (
        <React.Fragment>
            <div className="wrapper-fire-staff" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <div className="content" ref={contentRef}>
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageAdmins.fire.title}</span>
                        <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                    </div>
                    <div className="body">
                        <div className="fire-question">
                            <span>{props.appLanguage.backoffice.manageAdmins.fire.question}</span>
                        </div>
                        <form className="body-form" id='fire-form' onSubmit={(event: any) => submitHandler(event)}>
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.user}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.username}
                                            type="text"
                                            placeholder={props.appLanguage.register.userph}
                                            id={FORMUSERNAMEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}

                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMUSERNAMEID)
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <div className="submit"><button className="submit-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.profile.deleteProfileModal.no}</span></button></div>
                        <div className="cancel"><button disabled={!formSubmitValid || tempDisabled === 1} ref={submitRef} type="submit" className="cancel-btn"  onClick={(event: any) => submitHandler(event)}><span className="info">{props.appLanguage.profile.deleteProfileModal.yes}</span></button></div>
                    </div>
                </div>
            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(FireStaffModal);
