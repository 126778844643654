import React, { forwardRef, useImperativeHandle, useState } from 'react';
import "./SnackKekax.css"

interface IProps {
    delay?: any
    snackRef?: any
}

export const SnackKekax = forwardRef((props: IProps, ref: any) => {
    const [state, setState] = useState({ open: false, msg: "" });
    const [timer, setTimer] = useState<any>(null);

    useImperativeHandle(ref, () => ({

        openSnackBar(message: string) {

            if(timer){
                clearTimeout(timer)
                setTimer(null)
            }
            setState({ open: true, msg: message });

            let timeout = setTimeout(() => {
                setState({ open: false, msg: "" });
                setTimer(null)
            }, props.delay || 3000)

            setTimer(timeout)
        }

    }));


    return (
        <div ref={ref} className={state.open ? "wrapper-kekax-snackbar show" : "wrapper-kekax-snackbar"}>
            <span>
                {state.msg}
            </span>
        </div>
    );

})

export default SnackKekax
