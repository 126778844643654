import React, { useEffect, useRef, useState } from 'react';
import "./EditConsortiumModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import StepperEditConsortium from './stepperEditConsortium/StepperEditConsortium';
import { IFormErrorConsortium, IFormFocusConsortium, IFormInputConsortium, IFormValidConsortium } from '../addConsortium/AddConsortium';
import GeneralServices from '../../../../services/generalService.json'
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipInfo from '../../newsManage/addNewsletter/tooltipInfo/TooltipInfo';
import ReactMarkdown from 'react-markdown';
import TooltipKekax from '../../../kekax/TooltipKekax';
import dummyConsortium from '../../../../assets/news/newsDelta.png'
import { httpGetConsortiumDetails, httpPutConsortium } from '../../../../services/requestService';
import StepperKekax from '../../../kekax/StepperKekax';


interface IProps extends StoreProps {
    callback: any
    consortium: IEditConsortium
}

export interface IEditConsortium {
    open: boolean
    consortiumId: any
    consortiumName: string
}

interface SnackKekax {
    open: boolean
    message: string
}

interface IEditConsortiumValidation {
    main: boolean
    content: boolean
}

interface SelectedImg {
    content: any
    from: number //-2 deletedServer | -1 deletedLocal | 2 fromServer | 1 fromLocal
}

const FORMTITLEID: string = 'title';
const FORMCONTENTID: string = 'content';
const FORMWEBSITEID: string = 'website';

const MAX_STEPS = 1

export function EditConsortiumModal(props: IProps) {

    const [formSubmitValid, setFormSubmitValid] = useState<IEditConsortiumValidation>({ main: true, content: true });
    const [formError, setFormError] = useState<IFormErrorConsortium>({ titleError: '', websiteError: '', contentError: '' });
    const [formInput, setFormInput] = useState<IFormInputConsortium>({ title: '', website: '', content: '' });
    const [formValid, setFormValid] = useState<IFormValidConsortium>({ titleValid: false, contentValid: false, websiteValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusConsortium>({ titleFocus: false, contentFocus: false, websiteFocus: false });

    const snackKekaxRef: any = useRef();


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);

    const [selectedImage, setSelectedImage] = useState<SelectedImg>({ content: dummyConsortium, from: 1 });
    const [consortiumImage, setConsortiumImage] = useState<any>(null);

    const refSelectedImage: any = useRef(null);


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    const loadConsortiumById = () => {
        const consortiumId = props.consortium.consortiumId


        httpGetConsortiumDetails(consortiumId).then(
            async res => {
                setFormInput({ title: res.data.title, website: res.data.website, content: res.data.text })
                if (res.data.img !== undefined && res.data.img !== null && res.data.img.trim() !== ""){
                    setSelectedImage({ content: res.data.img, from: 2 })
                }
            }
        ).catch(
            err => {
            }
        )

    }



    useEffect(() => {

        if (props.consortium.open && modalRef.current) {
            modalRef.current.classList.add("editConsortium-modal-open");

            loadConsortiumById()
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();



        } else {
            modalRef.current.classList.remove("editConsortium-modal-open");

        }

    }, [props.consortium.open])




    const handleClickAway = (event: any) => {
        /*
        if (contentRef && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editModalConsortium-topOffset", posY + "px");
    }




    /*
    const changeStepperSelectedHandler = (active: any) => {
        setSelectedStepper(active)
    }
 */
    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }




    /*MODAL SETUP UP*/



    const formEndup = () => {

        contentRef.current.reset();
        setFormSubmitValid({ main: false, content: false });
        setFormError({ titleError: '', websiteError: '', contentError: '' });
        setFormInput({ title: '', website: '', content: '' })
        setFormValid({ titleValid: false, contentValid: false, websiteValid: false })
        setFormFocus({ titleFocus: false, contentFocus: false, websiteFocus: false })
        setSelectedImage({ content: dummyConsortium, from: 1 });
        setConsortiumImage(null);

        setTempDisabled(0)
    }

    const onClickFile = () => {
        refSelectedImage.current.click();
    }

    const onFileSelected = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage({ content: reader.result, from: 1 });
                setConsortiumImage(reader.result)

            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    const removePhotoHandler = () => {
        if (consortiumImage !== null && selectedImage.from === 1) {
            setConsortiumImage(null)
            setSelectedImage({ content: dummyConsortium, from: -1 })
        } else if (consortiumImage === null && selectedImage.from === 2) {
            setSelectedImage({ content: dummyConsortium, from: -2 })

        }
    }



    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            case FORMCONTENTID: {
                setFormInput({ ...formInput, content: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }



    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_contentValid = formValid.contentValid;
        let upd_titleValid = formValid.titleValid;
        let upd_websiteValid = formValid.websiteValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageConsortiums.add.titleAccept;
        }
        /*WEBSITE VALIDATION*/
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageConsortiums.add.websiteAccept;
        }
        /*CONTENT VALIDATION*/
        if (formInput.content === '') {
            upd_contentValid = false;
            fieldValidationErrors.contentError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_contentValid = true;
            fieldValidationErrors.contentError = props.appLanguage.backoffice.manageConsortiums.add.contentAccept;
        }

        setFormError(fieldValidationErrors)
        setFormValid({ contentValid: upd_contentValid, titleValid: upd_titleValid, websiteValid: upd_websiteValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])

    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.titleValid && formValid.websiteValid, content: formValid.contentValid });
    }, [formValid])

    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMCONTENTID: {
                setFormFocus({ ...formFocus, contentFocus: true })
                break;
            }
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)

        httpPutConsortium(
            {
                id: props.consortium.consortiumId,
                title: formInput.title,
                website: formInput.website,
                text: formInput.content,
                img: consortiumImage !== null ? consortiumImage : consortiumImage === null && selectedImage.from === -2 ? " " : ""
            }
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
        

    }



    const closeModalHandler = (feedback: string) => {
        formEndup()
        modalRef.current.classList.remove("editConsortium-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
        setSelectedStepper("0");
        props.callback(feedback)

    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMCONTENTID: {
                return formFocus.contentFocus && !formValid.contentValid ?
                    <span className="input-invalid-feedback-kekax">{formError.contentError}</span>
                    : formValid.contentValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.contentAccept}</span>
                        : null
            }
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.titleAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && !formValid.websiteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }



    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div>
                    <div className="container-edit-consortium-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.main}</span></div>
                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageConsortiums.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageConsortiums.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-website">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageConsortiums.add.website} *

                                </label>
                                <input className="input-website-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageConsortiums.add.websiteph}
                                    id={FORMWEBSITEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.website || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMWEBSITEID)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            )
        } else if (selectedStepper === "1") {
            return (
                <div className="container-edit-consortium-content">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.title}</span></div>


                    <div className="edit-consortium-image">
                        <div className="edit-consortium-image-preview">
                            <img className="image" src={selectedImage.content} alt='consortiumImgAdd'></img>
                            <div className="image-options" >
                                <TooltipKekax content={props.appLanguage.backoffice.manageConsortiums.add.imageOptions[0]} direction="right">
                                    <button type="button" className="image-change-fab" onClick={onClickFile}><div className="change" /></button >
                                </TooltipKekax>
                                <input ref={refSelectedImage} hidden type="file" accept='image/*' onChange={onFileSelected} />
                                <TooltipKekax content={props.appLanguage.backoffice.manageConsortiums.add.imageOptions[1]} direction="right">
                                    <button type="button" className={(selectedImage.from === 1 && consortiumImage !== null) || (selectedImage.from === 2 && consortiumImage === null) ? "image-delete-fab" : "image-delete-fab image-hidden-fab"} onClick={removePhotoHandler}><div className="delete" /></button >
                                </TooltipKekax>
                            </div>
                        </div>
                        <div className="edit-consortium-image-content">
                            <span className="text-one">{props.appLanguage.backoffice.manageConsortiums.add.imageph[0]}</span>
                            <span className="text-two">{props.appLanguage.backoffice.manageConsortiums.add.imageph[1]}</span>

                        </div>
                    </div>

                    <div className="container-toolbar">
                        <div className="title"><span>{props.appLanguage.backoffice.manageConsortiums.add.content} *</span></div>
                        <div className="options">
                            <TooltipInfo content={props.appLanguage.backoffice.manageConsortiums.add.information.content.info} direction="bottom">
                                <div className="opt-info">
                                    <div className="icon"></div>
                                </div>
                            </TooltipInfo>
                        </div>
                    </div>


                    <div className="feedback-block">
                        {
                            validatorFeedback(FORMCONTENTID)
                        }
                    </div>

                    <div className="edit-consortium-content-label">
                        <div className="side-label"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.input}</span></div>
                        <div className="side-label"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.preview}</span></div>
                    </div>
                    <div className="edit-consortium-content-text">
                        <div className="edit-consortium-content-text-input">
                            <textarea required className="content-text" id={FORMCONTENTID} placeholder={props.appLanguage.backoffice.manageConsortiums.add.contentph}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.content || ""}
                            />
                        </div>
                        <div className="edit-consortium-content-text-preview">
                            <ReactMarkdown className="content-preview" source={formInput.content + ""} escapeHtml={false} />
                        </div>
                    </div>

                </div>
            )
        } else if (selectedStepper === "2") {
            return null
        }
    }

    const printNextButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.main} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageConsortiums.edit.next}</button></div>
                )
            }
            default: {
                return null;
            }
        }
    }

    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)
        if (count === 1) {
            return <div className="submit"><button type="submit" disabled={!formSubmitValid.main || !formSubmitValid.content || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageConsortiums.edit.submit} </button></div>
        } else {
            return null
        }
    }



    return (
        <React.Fragment>
            <div className="wrapper-edit-consortium" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <form className="content" ref={contentRef} id='edit-consortium-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageConsortiums.edit.title}</span>
                        <div className="close"><button type="button" className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}></button></div>
                    </div>
                    <div className="body">
                        <div className="container-stepper">
                            <StepperKekax active={selectedStepper}>
                                <div key="0">{props.appLanguage.backoffice.manageConsortiums.edit.steps[0]}</div>
                                <div key="1">{props.appLanguage.backoffice.manageConsortiums.edit.steps[1]}</div>
                            </StepperKekax>
                        </div>
                        <div className="container-stepper-body">
                            <div className="container-form-edit-consortium">

                                {
                                    printActiveContent()
                                }


                            </div>
                        </div>

                    </div>
                    <div className="footer">
                        <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageEvents.edit.cancel}</span></button></div>
                        <div className="previous"><button type="button" className="previous-btn" disabled={selectedStepper === "0" ? true : false}  onClick={() => changeStepperHandler("PREVIOUS")}><span className="info">{props.appLanguage.backoffice.manageEvents.edit.previous}</span></button></div>
                        {

                            printNextButton()
                        }
                        {
                            printSubmitButton()

                        }
                    </div>
                </form>
            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}



export default connect(mapStateToProps)(EditConsortiumModal);
