import * as React from 'react'
import './StepperKekax.css';
import { useRef, useEffect } from 'react';

interface IProps {
    onChange?: any
    active?: any
    children?: any
}



export const StepperKekax: React.FC<IProps> = (props) => {

    /*
    const getUnderlineStyle = () => {
        if (props.active == null || Object.keys(sizes).length === 0) {
            return { left: '0', right: '100%' }
        }

        const size = sizes[props.active]

        return { left: size.left + 'px', right: size.right + 'px' }
    }
    */
    const stepperRef: any = useRef(null);
    const stepperEachRef = useRef(new Array(props.children.length));

    //const [nStepper, setNStepper] = React.useState<number>(0)

    //const [sizes, setSizes] = useState<any>({})

    useEffect(() => {
        window.addEventListener('resize', getSizes)
    }, [])

    useEffect(() => {
        getSizes();
        /*
        //SETTING STEPPER SIZE
        if (props.children !== undefined && props.children !== null) {
            const totalChildren = props.children.length;
            let result = Math.floor(totalChildren / 5);
            const extra = props.children.length % 5;

            if (extra !== 0 || totalChildren === 0) {
                result += 1
            }
            setNStepper(result)

        }
        */
        if (props.children !== undefined && props.children !== null) {
            document.documentElement.style.setProperty("--kekax-stepper-nSteps", props.children.length !== undefined ? props.children.length : 1);
        }
    }, [props.children, props.active])

    const getSizes = () => {
        if (stepperRef.current != null) {
            const rootBounds = stepperRef.current.getBoundingClientRect();
            const sizes: any = {}

            stepperEachRef.current.forEach((pos, i) => {

                const bounds = pos.getBoundingClientRect();
                const left = bounds.left - rootBounds.left;
                const right = rootBounds.right - bounds.right;

                sizes[i] = { left, right }

            })

            //setSizes(sizes)
            return sizes;
        } else {
            return null;
        }
    }


    const renderStepper = () => {
        /*
        const nRows = []
        for (let i = 0; i < nStepper; i++) {
            nRows.push(i)
        }
        return (nRows.map((elem: any, x: number) => (
        z >= x * 5 && z < (x + 1) * 5 ?

        */
        return (
            <div className="container-stepper" ref={stepperRef}>

                {React.Children.map(props.children, (child: any, z: any) => (


                    <div className={child.key === props.active ? "stepper-normal stepper-active" : child.key < props.active ? "stepper-normal stepper-before" : "stepper-normal"}
                        ref={el => stepperEachRef.current[z] = el}>
                        <hr className="line"></hr>

                        <div className="circle">
                            {z + 1}
                        </div>

                        <span>{child}</span>

                    </div>

                ))
                }
            </div>
        )

    }


    return (
        <div className="wrapper-stepper-kekax">


            {
                renderStepper()
            }



        </div >
    );

}

/*onClick={() => props.onChange(child.key)}*/

export default (StepperKekax);
