import React, { useState, useEffect, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './FilterNews.css'

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import GeneralServices from '../../../services/generalService.json'

import dummyNews from '../../../assets/news/newsDelta.png'

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { INewsLetter } from '../newsLetter/NewsLetter';
import { format as dateFormat } from "date-fns";
import { getNewsletterImageURL, httpGetListNews, httpGetNewsletterImg } from '../../../services/requestService';
import { translateFormatTimestamp } from '../../calendarEvents/calendar/Calendar';


const dummyNewsArray: INewsLetter[] = [
    {
        id: '0', tag: "0", title: "News Title Main", desc: "News main description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '1', tag: "0", title: "News Title Number 1", desc: "News number 1 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '2', tag: "0", title: "News Title Number 2", desc: "News number 2 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '3', tag: "0", title: "News Title Number 3", desc: "News number 3 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '4', tag: "0", title: "News Title Number 4", desc: "News number 4 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '5', tag: "0", title: "News Title Number 5", desc: "News number 5 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    }
]



interface FilterOptions {
    order: string //desc , asc
    by: {
        what: string //"all","tag","title"
        search: string
    }
    query: string
    go: boolean
}


const FORMKEKAXSEARCH: string = 'kekaxsearch';
const QUERYSTART: string = '?';
const QUERYBREAK: string = '&';
const QUERYEQUAL: string = '=';


interface IProps extends StoreProps, RouteComponentProps<any> { }

export const FilterNews: React.FC<IProps> = (props) => {

    const [newsArray, setNewsArray] = useState<INewsLetter[]>([]);


    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);
    const [totalNews, setTotalNews] = useState<number>(0);

    const [tags, setTags] = useState<number[]>([]);
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({
        order: GeneralServices.newsletter.orderFilter.desc,
        by: { what: GeneralServices.newsletter.searchFilter.all, search: '' },
        query: "?limit=5",
        go: false
    });

    const [renderAll, setRenderAll] = useState<string>("filter-news-header-options-opt options-opt-all opt-active");
    const [renderOrder, setRenderOrder] = useState<string>("");


    const refSearchDetails = useRef<any>(null);
    const refSearchDetailsIcon = useRef<any>(null);
    const [searchDetailsPop, setSearchDetailsPop] = useState({ open: false, class: 'search-news-details' });

    const refFilterDrop = useRef<any>(null);
    const refFilterDropIcon = useRef<any>(null);
    const [filterPop, setFilterPop] = useState({ open: false, class: 'filter-news-drop' });



    const kekaxsearch: any = useRef(null);

    const maxNewsArray: number = 5;
    const maxPagesArray: number = 5;

    useEffect(() => {
        setTags([GeneralServices.event.tag.general])
        httpGetListNews(filterOptions.query).then(
            res => {
                //SETTING TOTAL NEWS
                if (res.headers.total !== undefined) {
                    setTotalNews(parseInt(res.headers.total, 10));
                } else {
                    setTotalNews(parseInt('1', 10));
                }
                const dataArray: INewsLetter[] = res.data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].img === undefined) {
                            httpGetNewsletterImg(dataArray[i].id).then(
                                res => {
                                    dataArray[i].img = getNewsletterImageURL.replace('{news:id}', dataArray[i].id)
                                    setNewsArray([...dataArray]);
                                }
                            ).catch(
                                err => {
                                    dataArray[i].img = dummyNews
                                    setNewsArray([...dataArray]);
                                }
                            )
                        }
                        //}
                    }
                } else {
                    //setNewsArray([...dummyNewsArray]);

                }
            }
        ).catch(
            err => {
                //setNewsArray([...dummyNewsArray]);
            }
        )

        window.addEventListener('mousedown', handleEventListeners);
        window.addEventListener('resize', handleEventListeners);
        /*
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        */
        return () => {
            window.removeEventListener('mousedown', handleEventListeners);
            window.removeEventListener('resize', handleEventListeners);
        }

    }, [])

    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {
                    if (refSearchDetails.current && refSearchDetailsIcon.current && !refSearchDetails.current.contains(event.target) && !refSearchDetailsIcon.current.contains(event.target)) {
                        setSearchDetailsPop({ open: false, class: 'search-news-details' })
                    }

                    if (refFilterDrop.current && refFilterDropIcon.current && !refFilterDrop.current.contains(event.target) && !refFilterDropIcon.current.contains(event.target)) {
                        setFilterPop({ open: false, class: 'filter-news-drop' })
                    }
                    break
                }
                case 'resize': {
                    setSearchDetailsPop({ open: false, class: 'search-news-details' })
                    setFilterPop({ open: false, class: 'filter-news-drop' })

                    break
                }
                default: {

                }
            }
        }



    }


    useEffect(() => {
        if (totalNews > 0) {
            //SETTING PAGINATION
            let result = Math.floor(totalNews / maxNewsArray);
            const extra = totalNews % maxNewsArray;

            if (extra !== 0 || totalNews === 0) {
                result += 1
            }
            setMaxCounter(result);
        }
    }, [totalNews])



    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])





    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }

    }, [counter])

    const loadNewPage = (page: any) => {
        const myParent = document.getElementById("filternews-parent-container")
        if (myParent) {
            window.scrollTo({
                top: myParent.offsetTop + myParent.offsetHeight,
                behavior: 'auto'
            })
        }

        const queryPage = page - 1;

        httpGetListNews('?page=' + queryPage + '&limit=5').then(
            res => {
                const dataArray: INewsLetter[] = res.data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].img === undefined) {
                            httpGetNewsletterImg(dataArray[i].id).then(
                                res => {
                                    dataArray[i].img = getNewsletterImageURL.replace('{news:id}', dataArray[i].id)
                                    setNewsArray([...dataArray]);
                                }
                            ).catch(
                                err => {
                                    dataArray[i].img = dummyNews
                                    setNewsArray([...dataArray]);
                                }
                            )
                        }
                        //}
                    }
                }
            }
        ).catch(
            err => {
                setNewsArray([...dummyNewsArray]);
            }
        )
    }

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }



    const handleAccessNewsLetter = (id: any) => {
        let newsLetterURL = GeneralServices.newsLetterPath.replace(':id', id)
        props.history.push(newsLetterURL);
    }

    const changeSearchInput = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMKEKAXSEARCH: {
                setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, search: value } });
                break;
            }

            default: {
                break;
            }
        }//switch end


    }

    const handleOpenSearchDetails = () => {

        if (refSearchDetails) {
            const myElement: HTMLElement | null = document.getElementById("open-search-details");
            const myParent: HTMLElement | null = document.getElementById("filternews-parent-container");

            if (myElement !== null && myParent !== null) {
                refSearchDetails.current.style.top = (myElement.offsetHeight + myElement.offsetTop) * (1.8) + 'px';
                // refSearchDetails.current.style.left = myElement.offsetLeft + 'px';
                refSearchDetails.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';

            }

        }

        if (!searchDetailsPop.open) {
            setSearchDetailsPop({ open: true, class: 'search-news-details search-news-details-open' })
        } else {
            setSearchDetailsPop({ open: false, class: 'search-news-details' })
        }

    }


    const handleOpenFilterDrop = () => {
        if (refFilterDrop) {
            const myElement: HTMLElement | null = document.getElementById("open-filter-drop");
            const myParent: HTMLElement | null = document.getElementById("filternews-parent-container");


            if (myElement !== null && myParent !== null) {
                refFilterDrop.current.style.top = (myElement.offsetHeight + myElement.offsetTop) * (2.7) + 'px';
                refFilterDrop.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';
            }

        }

        if (!filterPop.open) {
            setFilterPop({ open: true, class: 'filter-news-drop filter-news-drop-open' })
        } else {
            setFilterPop({ open: false, class: 'filter-news-drop' })
        }

    }



    const handleSearchSelectedChecked = (name: string) => {

        if (filterOptions.by.what !== name) {
            return false;
        }
        return true;
    }

    const handleAutofillTag = (content: number) => {
        const newsTag = props.appLanguage.newsletter.tag[content];
        setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, search: newsTag } })
    }

    const queryAddId = (inputQuery: string, id: string, value: string) => {
        let result = "";
        if (inputQuery !== "") {
            let query: { what: string, val: string }[] = [];
            let pairs = (inputQuery[0] === '?' ? inputQuery.substr(1) : inputQuery).split('&');
            let hasId: boolean = false;
            for (let i = 0; i < pairs.length; i++) {
                let pair = pairs[i].split('=');
                query[i] = { what: pair[0], val: pair[1] };
                if (pair[0] === id)
                    hasId = true;
            }
            if (!hasId) {
                query[query.length] = { what: id, val: value }
            }
            for (let i = 0; i < query.length; i++) {
                if (hasId && query[i].what === id) {
                    if (i > 0) {
                        result = result.concat(QUERYBREAK + query[i].what + QUERYEQUAL + value)
                    } else {
                        result = result.concat(QUERYSTART + query[i].what + QUERYEQUAL + value)
                    }
                } else {
                    if (i > 0) {
                        result = result.concat(QUERYBREAK + query[i].what + QUERYEQUAL + query[i].val)
                    } else {
                        result = result.concat(QUERYSTART + query[i].what + QUERYEQUAL + query[i].val)
                    }
                }
            }
        } else {
            result = QUERYSTART + id + QUERYEQUAL + value;
        }
        //let result:string = QUERYSTART + query.join(QUERYBREAK);
        return result
    }


    const queryRemId = (inputQuery: string, ids: string[]) => {
        let result = "";
        if (inputQuery !== "") {
            let query: { what: string, val: string }[] = [];
            let pairs = (inputQuery[0] === '?' ? inputQuery.substr(1) : inputQuery).split('&');
            for (let i = 0; i < pairs.length; i++) {
                let pair = pairs[i].split('=');
                query[i] = { what: pair[0], val: pair[1] };

            }

            for (let i = 0; i < query.length; i++) {
                if (!ids.includes(query[i].what)) {
                    if (i > 0) {
                        result = result.concat(QUERYBREAK + query[i].what + QUERYEQUAL + query[i].val)
                    } else {
                        result = result.concat(QUERYSTART + query[i].what + QUERYEQUAL + query[i].val)
                    }
                }

            }
        }
        //let result:string = QUERYSTART + query.join(QUERYBREAK);
        return result
    }


    const handleClickSearchButton = () => {
        handleByOption(filterOptions.by.what, true, null)
    }

    useEffect(() => {

        if (filterOptions.go) {

            if (filterOptions.by.what === GeneralServices.newsletter.searchFilter.all) {
                setRenderAll(renderAll.concat(" opt-active"))
            } else {
                setRenderAll("filter-news-header-options-opt options-opt-all")
            }


            setFilterOptions({ ...filterOptions, go: false })

            httpGetListNews(filterOptions.query).then(
                res => {
                    //SETTING TOTAL NEWS
                    if (res.headers.total !== undefined) {
                        setTotalNews(parseInt(res.headers.total, 10));
                    } else {
                        setTotalNews(parseInt('1', 10));
                    }
                    const dataArray: INewsLetter[] = res.data;
                    if (dataArray.length > 0) {
                        for (let i = 0; i < dataArray.length; i++) {
                            if (dataArray[i].img === undefined) {
                                httpGetNewsletterImg(dataArray[i].id).then(
                                    res => {
                                        dataArray[i].img = getNewsletterImageURL.replace('{news:id}', dataArray[i].id)
                                        setNewsArray([...dataArray]);
                                    }
                                ).catch(
                                    err => {
                                        dataArray[i].img = dummyNews
                                        setNewsArray([...dataArray]);
                                    }
                                )
                            }
                            //}
                        }
                    } else {
                        //setNewsArray([...dummyNewsArray]);
                        setNewsArray([]);
                    }
                }
            ).catch(
                err => {
                    //setNewsArray([...dummyNewsArray]);
                }
            )


        }
    }, [filterOptions.go])


    const handleByOption = (option: string, go: boolean, event: any) => {
        switch (option) {
            case GeneralServices.newsletter.searchFilter.all: { //all

                const query = queryRemId(filterOptions.query, [GeneralServices.newsletter.searchFilter.tag, GeneralServices.newsletter.searchFilter.title]);
                if (filterOptions.by.what === option) {
                    if (filterOptions.by.search !== '') {//se pesquisar qualquer cena sem selecionar nada faz force no title
                        const queryAddTitle = queryAddId(filterOptions.query, GeneralServices.newsletter.searchFilter.title, filterOptions.by.search);
                        setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: GeneralServices.newsletter.searchFilter.title }, query: queryAddTitle, go: go })
                    } else { //se nao tiver escrito nada, faz o all normal
                        //setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: query, go: go })
                    }

                } else {//se ainda nao estava selecionado pesquisa logo ao selecionar e limpa search
                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option, search: '' }, query: query, go: true })
                }

                break;
            }
            case GeneralServices.newsletter.searchFilter.tag: {   //tag

                if (filterOptions.by.what !== GeneralServices.newsletter.searchFilter.tag) { // first time checking the box

                    const queryRmvTitle = queryRemId(filterOptions.query, [GeneralServices.newsletter.searchFilter.title]);

                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: queryRmvTitle, go: go })

                } else if (event !== null) { // other time checking the box
                    const queryRmvTitleTag = queryRemId(filterOptions.query, [GeneralServices.newsletter.searchFilter.tag, GeneralServices.newsletter.searchFilter.title]);
                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: GeneralServices.newsletter.searchFilter.all }, query: queryRmvTitleTag, go: go })
                } else { // this time lets search

                    const searchField = filterOptions.by.search.toUpperCase();
                    let tagId = -1;
                    for (let i = 0; i < tags.length; i++) {
                        if (props.appLanguage.newsletter.tag[tags[i]].toUpperCase() === searchField) {
                            tagId = tags[i];
                        }
                    }

                    if (tagId !== -1) {
                        const queryAddTag = queryAddId(filterOptions.query, option, tagId.toString());
                        setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: queryAddTag, go: go })
                    } else {
                        //isto dava set no General que é default
                        //const queryAddTag = queryAddId(filterOptions.query, option, tags[0].toString());
                        //setFilterOptions({ ...filterOptions, by: { search: props.appLanguage.newsletter.tag[0], what: option }, query: queryAddTag, go: go })
                        const queryAddTag = queryAddId(filterOptions.query, option, "-1");
                        setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: queryAddTag, go: go })
                        //bug, vem vazio se -1 mas continuam la cenas, tenho de atualizar lista de filter news
                    }

                }

                break;
            }
            case GeneralServices.newsletter.searchFilter.title: {   //title


                if (filterOptions.by.what !== GeneralServices.newsletter.searchFilter.title) {// first time checking the box
                    const queryRmvTag = queryRemId(filterOptions.query, [GeneralServices.newsletter.searchFilter.tag]);
                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: queryRmvTag, go: go })


                } else if (event !== null) {// other time checking the box
                    const query = queryRemId(filterOptions.query, [GeneralServices.newsletter.searchFilter.tag, GeneralServices.newsletter.searchFilter.title]);
                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: GeneralServices.newsletter.searchFilter.all }, query: query, go: go })
                } else { // this time lets search
                    const queryAddTitle = queryAddId(filterOptions.query, option, filterOptions.by.search);
                    setFilterOptions({ ...filterOptions, by: { ...filterOptions.by, what: option }, query: queryAddTitle, go: go })
                }
                break;
            }
            default: {

            }
        }

    }





    const handleOrderOption = (name: string) => {
        if (name === GeneralServices.newsletter.orderFilter.desc) {
            const query = queryAddId(filterOptions.query, "asc", "false");
            setFilterOptions({ ...filterOptions, order: GeneralServices.newsletter.orderFilter.desc, query: query, go: true })
            setRenderOrder(props.appLanguage.news.filterNews.filters[0])
        } else if (name === GeneralServices.newsletter.orderFilter.asc) {
            const query = queryAddId(filterOptions.query, "asc", "true");
            setFilterOptions({ ...filterOptions, order: GeneralServices.newsletter.orderFilter.asc, query: query, go: true })
            setRenderOrder(props.appLanguage.news.filterNews.filters[1])
        }

        setFilterPop({ open: false, class: 'filter-news-drop' })
    }


    const renderDropFilterOptions = () => {

        let descStyle: string = "drop-option";
        let ascStyle: string = "drop-option";


        if (filterOptions.order === GeneralServices.newsletter.orderFilter.desc) {
            descStyle = descStyle.concat(" opt-active");
        } else if (filterOptions.order === GeneralServices.newsletter.orderFilter.asc) {
            ascStyle = descStyle.concat(" opt-active");
        }


        return (
            <div className="drop-filter-wrapper">
                <div className={descStyle} onClick={() => handleOrderOption(GeneralServices.newsletter.orderFilter.desc)}><span>{props.appLanguage.news.filterNews.filters[0]}</span></div>
                <div className={ascStyle} onClick={() => handleOrderOption(GeneralServices.newsletter.orderFilter.asc)}><span>{props.appLanguage.news.filterNews.filters[1]}</span></div>
            </div>
        )
    }

    return (
        <div className="wrapper-filter-news" id="filternews-parent-container">
            <div className="container-filter-news-header">
                <div className="filter-news-header-title">
                    <span>{props.appLanguage.news.filterNews.more}</span>
                </div>
                <div className="filter-news-header-separator" />

                <div className="filter-news-header-menu">
                    <div className="filter-news-header-options">
                        <div className="filter-news-header-options-flex" >
                            <div className="filter-news-header-options-opt" id="open-filter-drop" ref={refFilterDropIcon} onClick={handleOpenFilterDrop}>
                                <div className="kekax-span">
                                    <span>{renderOrder === "" ? props.appLanguage.news.filterNews.filters[0] : renderOrder}</span>
                                </div>
                                <div className="kekax-icon">
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className={renderAll} onClick={() => handleByOption(GeneralServices.newsletter.searchFilter.all, false, null)}>
                                <span>{props.appLanguage.news.filterNews.filters[2]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="filter-news-header-search" id="open-search-details">
                        <div className="filter-news-header-search-flex">
                            <div className="kekax-input-text">
                                <input
                                    ref={kekaxsearch}
                                    id="kekaxsearch"
                                    className="kekax-real-input"
                                    type='text'
                                    placeholder={props.appLanguage.news.filterNews.search}
                                    onChange={(event: any) => changeSearchInput(event)}
                                    value={filterOptions.by.search}
                                />
                            </div>
                            <div className="kekax-input-search" onClick={handleClickSearchButton}>
                                <SearchIcon className="search-icon" />
                            </div>
                            <div className="kekax-input-split"></div>
                            <div className="kekax-input-details" ref={refSearchDetailsIcon} onClick={handleOpenSearchDetails}>
                                <ExpandMoreIcon className="expand-icon" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="container-filter-news">
                {
                    newsArray.map((content: INewsLetter, index: number) => (
                        <div key={index} className="object-filter-news">
                            <div className="object-filter-news-media" onClick={() => handleAccessNewsLetter(content.id)}>
                                {
                                    content.img !== null &&
                                    <img src={content.img} className="object-filter-news-img" alt="object-news" />

                                }
                            </div>
                            <div className="object-filter-news-content" onClick={() => handleAccessNewsLetter(content.id)}>
                                <span className="object-filter-news-title">{content.title}</span>
                                <div className="object-filter-news-sub-title">
                                    <span className="object-filter-news-date">{translateFormatTimestamp(content.timestamp, props.appLanguage)}</span>
                                    <span className="object-filter-news-dot">•</span>
                                    <span className="object-filter-news-type">{props.appLanguage.newsletter.tag[parseInt(content.tag)]}</span>
                                </div>
                                <span className="object-filter-news-desc">{content.desc}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="container-filter-news-footer">
                <div className="filter-news-footer-info">

                </div>
                <div className="filter-news-footer-options">
                    <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                    {
                        pagesArray.map((pos, index) => (
                            pos === counter ?
                                <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                :
                                <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                        ))
                    }

                    <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                </div>
            </div>



            <div className={filterPop.class} ref={refFilterDrop}>
                {
                    renderDropFilterOptions()
                }
            </div>

            <div className={searchDetailsPop.class} ref={refSearchDetails}>
                <div className="search-news-details-wrapper">
                    <div className="search-news-header">
                        <div className="search-news-header-opt" onClick={(event: any) => handleByOption(GeneralServices.newsletter.searchFilter.tag, false, event)}>
                            <div className="opt-icon">
                                <input type="checkbox"
                                    readOnly
                                    checked={handleSearchSelectedChecked(GeneralServices.newsletter.searchFilter.tag)}
                                />
                            </div>
                            <span className="opt-label">{props.appLanguage.news.filterNews.searchFilter[0]}</span>

                        </div>
                        <div className="search-news-header-opt" onClick={(event: any) => handleByOption(GeneralServices.newsletter.searchFilter.title, false, event)}>
                            <div className="opt-icon">
                                <input type="checkbox"
                                    readOnly
                                    checked={handleSearchSelectedChecked(GeneralServices.newsletter.searchFilter.title)}
                                />
                            </div>
                            <span className="opt-label">{props.appLanguage.news.filterNews.searchFilter[1]}</span>

                        </div>
                    </div>
                    {
                        filterOptions.by.what === GeneralServices.newsletter.searchFilter.tag ?
                            <div className="search-news-body">
                                {
                                    tags.map((content: number, index: number) => (
                                        <div key={index} className="object-search-news" onClick={() => handleAutofillTag(content)}>
                                            <span>{props.appLanguage.newsletter.tag[content]}</span>
                                        </div>
                                    ))

                                }
                            </div>
                            : null
                    }

                </div>
            </div>
        </div >

    );

}
export default connect(mapStateToProps)(withRouter(FilterNews));