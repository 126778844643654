import React, { useState, useEffect, useRef } from 'react'
import './NavMain.css';
import GeneralServices from '../../services/generalService.json'
import { httpUserSignOutRequest, httpChangeUserPhotoRequest } from '../../services/requestService';
import { changeLanguage } from "../../App"

import { RouteComponentProps, withRouter } from 'react-router';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { mapStateToProps, mapDispatchToProps, StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';

import TooltipKekax from '../kekax/TooltipKekax';
import SideNav from './sideNav/SideNav'
import SnackKekax from '../kekax/SnackKekax';

import logo from '../../assets/mineheritageLogo/logoSimple.png'
import worldFlag from '../../assets/flags/world.png'



interface IProps extends RouteComponentProps<any>, ReactCookieProps, StoreProps {
}

interface LanguageOption {
    id: string
    language: string
    tag: string
    flag: any
    counter: number
}





export function NavMain(props: IProps) {



    const [sideNav, setSideNav] = useState<boolean>(false);

    //const [openRegister, setOpenRegister] = useState<boolean>(false);
    //const [openLogin, setOpenLogin] = useState<boolean>(false);

    const profileChangeRef: any = useRef(null);
    const profileRef = useRef<any>(null);
    const profileRefIcon = useRef<any>(null);
    const [profileMenu, setProfileMenu] = useState({ open: false, class: 'profile-popper' });

    const languageRef = useRef<any>(null);
    const languageRefIcon = useRef<any>(null);
    const [languageMenu, setLanguageMenu] = useState({ open: false, class: 'language-popper' });
    const [selectedLang, setSelectedLang] = useState<any>(worldFlag);
    const [languageArrayAll, setLanguageArrayAll] = useState<LanguageOption[]>([])



    const [languageArray, setLanguageArray] = useState<LanguageOption[]>([])

    const snackKekaxRef: any = useRef();

    useEffect(() => {

        const copyLangsAll: LanguageOption[] = [...languageArrayAll]
        if (copyLangsAll.length > 0) {
            for (let x = 0; x < languageArrayAll.length; x++) {
                copyLangsAll[x].language = props.appLanguage.languages[languageArrayAll[x].counter]
            }
            setLanguageArrayAll([...copyLangsAll])
        }

        const copyLangs: LanguageOption[] = [...languageArray]
        if (copyLangs.length > 0) {
            for (let x = 0; x < languageArray.length; x++) {
                copyLangs[x].language = props.appLanguage.languages[languageArrayAll[x].counter]
            }
            setLanguageArray([...copyLangs])
        }



    }, [props.appLanguage])


    const handlerScrollLanguage = (what: number) => {
        //0-left | 1-right
        let newLanguageArray: LanguageOption[] = [...languageArray];
        if (what === 0) {
            for (let pos = 0; pos < newLanguageArray.length; pos++) {
                if (newLanguageArray[pos].counter === 0) {
                    newLanguageArray[pos] = languageArrayAll[languageArrayAll.length - 1];
                } else {
                    newLanguageArray[pos] = languageArrayAll[newLanguageArray[pos].counter - 1]
                }
            }
        } else if (what === 1) {
            for (let pos = 0; pos < newLanguageArray.length; pos++) {
                if (newLanguageArray[pos].counter === languageArrayAll.length-1) {
                    newLanguageArray[pos] = languageArrayAll[0];
                } else {
                    newLanguageArray[pos] = languageArrayAll[newLanguageArray[pos].counter + 1]
                }
            }
        }
        setLanguageArray(newLanguageArray);
    }


    useEffect(() => {
        //GET ROUTING TO DECLARE NAVBAR POSITION ON HARD LINKING (FIRST PAGE)
        if (props.location !== undefined && props.location.pathname) {
            if (props.location.pathname === GeneralServices.homePath) {
                document.documentElement.style.setProperty("--navMain-position", "absolute");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, transparent 80%, black 150%), rgba(81, 83, 48, 0.1)");
                //document.documentElement.style.setProperty("--navMain-bg-color", "rgba(168, 167, 167, 0.137)");
                document.documentElement.style.setProperty("--navMain-border", "none");
                document.documentElement.style.setProperty("--navMain-shadow", "none");
                /*document.documentElement.style.setProperty("--navMain-sub-shadow", "radial-gradient( circle, rgba(187, 187, 28, 0.1) 70%, rgba(0, 0, 0, 0.884) 170%), rgba(206, 206, 142, 0.123)");*/
                document.documentElement.style.setProperty("--navMain-sub-shadow", "rgba(221, 221, 186, 0.5)");
                document.documentElement.style.setProperty("--navMain-sub-bg", "radial-gradient( circle, rgba(190, 190, 151, 0.164) 50%, rgba(0, 0, 0, 0.205) 120%), rgba(219, 219, 55, 0.144)");


            } /*else if (props.location.pathname === GeneralServices.profilePath) {
                document.documentElement.style.setProperty("--navMain-position", "absolute");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, transparent 95%, black 150%), rgba(81, 83, 48, 0.02)");
                document.documentElement.style.setProperty("--navMain-border", "1px solid #c0c0c0");
            }*/ else {
                document.documentElement.style.setProperty("--navMain-position", "relative");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, rgba(145, 148, 92, 0.281) 20%, rgba(0, 0, 0, 0.884) 150%), rgba(111, 112, 14, 0.418)");
                document.documentElement.style.setProperty("--navMain-border", "1px solid #c0c0c0");
                document.documentElement.style.setProperty("--navMain-shadow", "rgba(0, 0, 0, 0.8) 0px 0px 4px, inset rgba(0,0,0,0.15) 0px 0px 20px");
                document.documentElement.style.setProperty("--navMain-sub-shadow", "rgba(0, 0, 0, 0.164)");
                document.documentElement.style.setProperty("--navMain-sub-bg", "radial-gradient( circle, rgba(189, 190, 123, 0.247) 50%, rgba(0, 0, 0, 0.205) 120%), rgba(255, 255, 255, 0.356)");


            }
        }
        //--GET ROUTING TO DECLARE NAVBAR POSITION ON HARD LINKING (FIRST PAGE)

        initFlag();
        document.documentElement.style.setProperty("--language-img", "url(" + selectedLang + ")");

        document.addEventListener('mousedown', handleEventListeners);
        window.addEventListener('resize', handleEventListeners);


        const langs: LanguageOption[] = []
        for (let i = 0; i < GeneralServices.languages.length; i++) {
            if (parseInt(GeneralServices.languages[i].counter) < 2) {
                langs.push({
                    id: GeneralServices.languages[i].id,
                    language: GeneralServices.languages[i].langEU,
                    tag: GeneralServices.languages[i].tag,
                    flag: require('../../assets/flags/' + GeneralServices.languages[i].tag + '.png'),
                    counter: parseInt(GeneralServices.languages[i].counter)

                })
            }
            if (i === GeneralServices.languages.length - 1) {

                setLanguageArrayAll([...langs])
                //setLanguageArray([langs[0], langs[1], langs[2], langs[3], langs[4]])
                setLanguageArray([...langs])

            }
        }


        return () => {
            document.removeEventListener('mousedown', handleEventListeners);
            window.removeEventListener('resize', handleEventListeners);

        }

    }, [])




    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {
                    if (languageRef.current !== null && languageRefIcon.current !== null && !languageRef.current.contains(event.target) && !languageRefIcon.current.contains(event.target)) {
                        setLanguageMenu({ open: false, class: 'language-popper' })
                        //setLanguageArray([languageArrayAllRef.current[0], languageArrayAllRef.current[1], languageArrayAllRef.current[2], languageArrayAllRef.current[3], languageArrayAllRef.current[4]]);
                        if(languageArrayAll.length>0){
                            setLanguageArray([languageArrayAll[0], languageArrayAll[1]]);
                        }

                    }
                    if (profileRef.current !== null && profileRefIcon.current !== null && !profileRef.current.contains(event.target) && !profileRefIcon.current.contains(event.target)) {
                        setProfileMenu({ open: false, class: 'profile-popper' })
                    }

                    break
                }
                case 'resize': {
                    handleLanguageMenu(true);
                    handleProfileMenu(true);

                    break
                }
                default: {

                }
            }
        }



    }
    useEffect(() => {
        //if (prevProps.location.pathname !== props.location.pathname && props.location !== undefined) {
        if (props.location !== undefined && props.location.pathname) {
            if (props.location.pathname === GeneralServices.homePath) {
                document.documentElement.style.setProperty("--navMain-position", "absolute");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, transparent 80%, black 150%), rgba(81, 83, 48, 0.1)");
                //document.documentElement.style.setProperty("--navMain-bg-color", "rgba(168, 167, 167, 0.137)");
                document.documentElement.style.setProperty("--navMain-border", "none");
                document.documentElement.style.setProperty("--navMain-shadow", "none");
                /*document.documentElement.style.setProperty("--navMain-sub-shadow", "radial-gradient( circle, rgba(187, 187, 28, 0.1) 70%, rgba(0, 0, 0, 0.884) 170%), rgba(206, 206, 142, 0.123)");*/
                document.documentElement.style.setProperty("--navMain-sub-shadow", "rgba(221, 221, 186, 0.5)");
                document.documentElement.style.setProperty("--navMain-sub-bg", "radial-gradient( circle, rgba(190, 190, 151, 0.164) 50%, rgba(0, 0, 0, 0.205) 120%), rgba(219, 219, 55, 0.144)");

            }/* else if (props.location.pathname === GeneralServices.profilePath) {
                document.documentElement.style.setProperty("--navMain-position", "absolute");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, transparent 95%, black 150%), rgba(81, 83, 48, 0.02)");
                document.documentElement.style.setProperty("--navMain-border", "1px solid #c0c0c0");
            } */else {
                document.documentElement.style.setProperty("--navMain-position", "relative");
                document.documentElement.style.setProperty("--navMain-bg-color", "radial-gradient( circle, rgba(145, 148, 92, 0.281) 20%, rgba(0, 0, 0, 0.884) 150%), rgba(111, 112, 14, 0.418)");
                /*radial-gradient( circle, rgba(145, 148, 92, 0.281) 20%, rgba(0, 0, 0, 0.884) 150%), rgba(60, 61, 16, 0.219)*/
                document.documentElement.style.setProperty("--navMain-border", "1px solid #c0c0c0");
                document.documentElement.style.setProperty("--navMain-shadow", "rgba(0, 0, 0, 0.8) 0px 0px 4px, inset rgba(0,0,0,0.15) 0px 0px 20px");
                document.documentElement.style.setProperty("--navMain-sub-shadow", "rgba(0, 0, 0, 0.164)");
                document.documentElement.style.setProperty("--navMain-sub-bg", "radial-gradient( circle, rgba(189, 190, 123, 0.247) 50%, rgba(0, 0, 0, 0.205) 120%), rgba(255, 255, 255, 0.356)");

            }
        }

    }, [props.location.pathname])




    useEffect(() => {
        document.documentElement.style.setProperty("--language-img", "url(" + selectedLang + ")");
    }, [selectedLang])


    useEffect(() => {

        if (!props.loggedIn && profileMenu.open && (props.sessionRole === "" && props.sessionToken === "" && props.sessionUser === "")) {
            setProfileMenu({ open: false, class: 'profile-popper' })
        }


    }, [props.sessionToken, props.sessionUser, props.sessionRole, props.loggedIn])



    const initFlag = () => {
        if (localStorage.getItem(GeneralServices.localStorage.localLang)) {
            let flag = localStorage.getItem(GeneralServices.localStorage.localLang);
            if (flag !== null)
                changeLanguageFunction(flag);
        }
    }

    const handleSideNav = () => {
        if (sideNav) {
            setSideNav(false)
        } else {
            setSideNav(true)
        }
    }



    const handleProfileMenu = (isResize: boolean) => {
        if (profileRef && profileRefIcon) {
            const myElement: HTMLElement | null = profileRefIcon.current;
            const myParent: HTMLElement | null = document.getElementById("navmain-parent-container");

            if (myElement !== null && myParent !== null) {
                profileRef.current.style.top = myElement.getBoundingClientRect().height * 2 + 'px';
                // refSearchDetails.current.style.left = myElement.offsetLeft + 'px';
                profileRef.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left - myElement.getBoundingClientRect().width * 1.7 + 'px';

            }

        }

        if (!isResize) {
            if (!profileMenu.open) {
                setProfileMenu({ open: true, class: 'profile-popper profile-popper-open' })

            } else {
                setProfileMenu({ open: false, class: 'profile-popper' })
            }
        }

    }

    const changeLanguageFunction = (flag: string) => {
        switch (flag) {
            case GeneralServices.languagesTags.Portuguese: {
                setSelectedLang(require('../../assets/flags/' + GeneralServices.languagesTags.Portuguese + '.png'));
                break;
            }
            case GeneralServices.languagesTags.English: {
                setSelectedLang(require('../../assets/flags/' + GeneralServices.languagesTags.English + '.png'));
                break;
            }
            default: {
                setSelectedLang(worldFlag);
                break;
            }
        }
        props.setLanguage(changeLanguage(flag))
    }


    const handleLanguageMenu = (isResize: boolean) => {
        if (languageRef && languageRefIcon) {
            const myElement: HTMLElement | null = languageRefIcon.current;
            const myParent: HTMLElement | null = document.getElementById("navmain-parent-container");

            if (myElement !== null && myParent !== null) {
                languageRef.current.style.top = myElement.getBoundingClientRect().height * 4 + 'px';
                // refSearchDetails.current.style.left = myElement.offsetLeft + 'px';
                languageRef.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left - myElement.getBoundingClientRect().width * 4 + 'px';

            }
        }
        if (!isResize) {
            if (!languageMenu.open) {
                setLanguageMenu({ open: true, class: 'language-popper language-popper-open' })

            } else {
                setLanguageMenu({ open: false, class: 'language-popper' })
                //setLanguageArray([languageArrayAllRef.current[0], languageArrayAllRef.current[1], languageArrayAllRef.current[2], languageArrayAllRef.current[3], languageArrayAllRef.current[4]]);
                if(languageArrayAll.length>0){
                    setLanguageArray([languageArrayAll[0], languageArrayAll[1]]);
                }
            }
        }


    }



    const changeLangHandler = (flag: string) => {
        changeLanguageFunction(flag);
        localStorage.setItem(GeneralServices.localStorage.localLang, flag);
        setLanguageMenu({ open: false, class: 'language-popper' })
        //setLanguageArray([languageArrayAllRef.current[0], languageArrayAllRef.current[1], languageArrayAllRef.current[2], languageArrayAllRef.current[3], languageArrayAllRef.current[4]]);
        if(languageArrayAll.length>0){
            setLanguageArray([languageArrayAll[0], languageArrayAll[1]]);
        }
    }


    const callSideNavFunction = (childData: any) => {
        setSideNav(childData)
    }


    //PUSHING HISTORY TO NAVIGATE TO HOMEPAGE ON ICON LOGO CLICK
    const handleNavigateToHome = () => {
        props.history.push(GeneralServices.homePath);
    }




    /*REGISTER MODAL HANDLERS*/
    /*
    const registerHandler = () => {
        openRegister ? setOpenRegister(false) : setOpenRegister(true);

    }
    const callbackRegisterFunction = (childData: any) => {
        if (childData === 'closed' || childData === 'worked') {
            setOpenRegister(false);

            if (childData === 'worked') {
                snackKekaxRef.current.openSnackBar(props.appLanguage.register.registerSuccess)
            }
        }
    }
    */


    /*LOGIN MODAL HANDLERS*/
    /*
    const loginHandler = () => {
        openLogin ? setOpenLogin(false) : setOpenLogin(true);
    }
    const callbackLoginFunction = (childData: any) => {

        let callMeMaybe: string[] = childData.split('-');

        if (callMeMaybe[0] === 'closed' || callMeMaybe[0] === 'worked') {
            setOpenLogin(false)
            if (callMeMaybe[0] === 'worked') {
                if (props.cookies !== undefined) {

                    if (GeneralServices.develop) {
                        props.cookies.set(GeneralServices.cookies.cookieToken, GeneralServices.cookies.cookieToken, { path: '/', domain: 'localhost' });
                        props.cookies.set(GeneralServices.cookies.cookieUser, callMeMaybe[1], { path: '/', domain: 'localhost' });
                        //ROLES
                        props.cookies.set(GeneralServices.cookies.cookieRole, "1", { path: '/', domain: 'localhost' });
                    }
                    //console.log(props.cookies.get(GeneralServices.cookies.cookieToken))
                    //console.log(props.cookies.get(GeneralServices.cookies.cookieUser))
                    //console.log(props.cookies.get(GeneralServices.cookies.cookieRole))
                    
                    props.loginSession({
                        sessionToken: props.cookies.get(GeneralServices.cookies.cookieToken),
                        sessionUser: props.cookies.get(GeneralServices.cookies.cookieUser),
                        sessionRole: props.cookies.get(GeneralServices.cookies.cookieRole)
                    });
                }
                snackKekaxRef.current.openSnackBar(props.appLanguage.login.authSuccess)
            }
        }
    }
    */

    /*LOGOUT HANDLER*/
    const logoutHandler = () => {
        httpUserSignOutRequest().then(
            res => {
                if (props.cookies !== undefined) {
                    if (GeneralServices.develop) {
                        props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfigDevelop);
                        props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfigDevelop);
                        props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfigDevelop);
                    }
                    /* O pedido já remove as cookies
                    else {
                        props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfig);
                        props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfig);
                        props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfig);
                    }
                    */
                    localStorage.clear();
                    props.logoutSession({ situation: 0 });
                    setProfileMenu({ open: false, class: 'profile-popper' })
                    props.history.push(GeneralServices.homePath);
                }
                snackKekaxRef.current.openSnackBar(props.appLanguage.logout)
            }
        );
    }




    const onFileSelected = (event: any) => {
        const fileTarget = event.target.files[0];
        if (fileTarget) {
            let fileExt = fileTarget.name.split('.').pop();
            httpChangeUserPhotoRequest({ img: fileTarget, ext: fileExt }).then(
                res => {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        let newLogoProfile = reader.result;
                        localStorage.setItem(GeneralServices.localStorage.localProfileImage, newLogoProfile as string);
                        props.changeUserImage({ userImage: newLogoProfile as string })

                    }
                    reader.readAsDataURL(fileTarget)
                    //setState({logoProfile: URL.createObjectURL(selectedLogoProfile)},()=>{console.log(logoProfile)})
                }
            )
        }
    }



    const onClickFile = () => {
        profileChangeRef.current.click();
    }


    const handleClickProfile = () => {
        props.history.push(GeneralServices.profilePath);
        //snackKekaxRef.current.openSnackBar(props.appLanguage.msg_feedback.blocked)

    }


    const handleClickBookletsPage = () => {
        props.history.push(GeneralServices.documentsPath);
    }

    const handleClickGamePage = () => {
        props.history.push(GeneralServices.gamePagePath);
    }

    const handleClickMinePage = () => {
        props.history.push(GeneralServices.mineMapPath);
    }

    const handleClickVideosPage = () => {
        props.history.push(GeneralServices.videosPath);
    }


    const handleClick3DModelsPage = () => {
        props.history.push(GeneralServices.modelsPath);
    }


    return (
        <React.Fragment>
            <header className='header-wrapper' id="navmain-parent-container">
                <nav className="nav-wrapper">
                    <div className="nav-main">
                        <div className="left-container">
                            <button className="left-menu" onClick={handleSideNav}>
                                <div className="menu-icon"></div>
                            </button>
                        </div>
                        <div className="center-container">
                            <div className="center-drawer" onClick={handleNavigateToHome}>
                                <div className='logo-wrapper'>
                                    <img src={logo} style={{ 'width': '100%', 'height': '100%' }} alt='logo MineHeritage' />
                                </div>
                            </div>
                        </div>
                        <div className="right-container" >
                            <div className="right-flex">
                                <div className="lang-block">
                                    <button ref={languageRefIcon} className="lang-block-avatar" onClick={() => handleLanguageMenu(false)} />
                                </div>

                                {
                                    props.loggedIn ?
                                        <div className="user-block">
                                            <button className="user-block-avatar" ref={profileRefIcon} onClick={() => handleProfileMenu(false)}><img src={props.userImage} alt="userImgIcon"></img></button>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </div>
                    <div className="nav-sub">
                        <div className="nav-sub-tab" onClick={handleClickBookletsPage}>
                            <div className="info"><span>{props.appLanguage.navbar.documents}</span></div>
                        </div>
                        <div className="nav-sub-tab" onClick={handleClickGamePage}>
                            <div className="info"><span>{props.appLanguage.navbar.game}</span></div>
                        </div>
                        <div className="nav-sub-tab" onClick={handleClickMinePage}>
                            <div className="info"><span>{props.appLanguage.navbar.mineMap}</span></div>
                        </div>
                        <div className="nav-sub-tab" onClick={handleClickVideosPage}>
                            <div className="info"><span>{props.appLanguage.navbar.videos}</span></div>
                        </div>
                        <div className="nav-sub-tab" onClick={handleClick3DModelsPage}>
                            <div className="info"><span>{props.appLanguage.navbar.models}</span></div>
                        </div>
                    </div>
                </nav>

                <div className={languageMenu.class} ref={languageRef}>
                    <div className='lang-wrapper' >
                        <div className="lang-left" onClick={() => handlerScrollLanguage(0)}>
                            <div className="arrow-left"></div>
                        </div>
                        <div className="lang-center">

                            {
                                languageArray.map((lang: LanguageOption, index: number) => (
                                    <div className='lang-option' key={index}>
                                        <TooltipKekax content={lang.language} direction="top">
                                            <button className="lang-option-button" style={{ backgroundImage: "url(" + lang.flag + ")" }} onClick={() => changeLangHandler(lang.tag)} />
                                        </TooltipKekax>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="lang-right" onClick={() => handlerScrollLanguage(1)}>
                            <div className="arrow-right"></div>
                        </div>
                    </div>
                </div>

                <div className={profileMenu.class} ref={profileRef}>
                    <div className='profile-wrapper'>
                        <div className="profile-container">
                            <div className="profile-block-image">
                                <div className="profile-image" >
                                    <img src={props.userImage} alt='userImgPop'></img>
                                </div>
                                <div className="image-change">
                                    <div className="image-change-fab" onClick={onClickFile}><div className="camera-icon"></div></div >
                                    <input ref={profileChangeRef} hidden type="file" accept='image/*' onChange={onFileSelected} />
                                </div>
                            </div>

                            <div className="profile-block-text">
                                <span className="profile-user">{props.sessionUser}</span>
                            </div>
                            <hr className="profile-block-divider"></hr>
                            <div className="profile-block-menu">
                                <button className="menu-button" onClick={handleClickProfile}>{props.appLanguage.navbar.profile}</button>
                                <button className="menu-button" onClick={logoutHandler}>{props.appLanguage.navbar.out}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

            <SnackKekax ref={snackKekaxRef} />

            <SideNav opened={sideNav} callSideNav={callSideNavFunction} />
        </React.Fragment >
    );

}
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(NavMain)));


/*
<LoginModal open={openLogin} callback={callbackLoginFunction} />
<RegisterModal open={openRegister} callback={callbackRegisterFunction} />

<div className="visitor-block">
    <button className="sign-in" onClick={loginHandler}>{props.appLanguage.navbar.in}</button>
    <div className="sign-up">
        <span className="text1">{props.appLanguage.navbar.visitor}</span><span className="text2" onClick={registerHandler}>{props.appLanguage.navbar.up}</span>
    </div>
</div>
*/