import React, { useState, useEffect, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './CalendarMain.css'
import * as dateFns from "date-fns";
import { IEvent } from '../../eventPage/EventPage';
import { Badge } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { httpGetListEvents } from '../../../../services/requestService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GeneralServices from '../../../../services/generalService.json'


interface IProps extends StoreProps, RouteComponentProps<any> {
    currentDate: Date;
    eventsArray: IEvent[];
    cbCurrentEventDate: any;
}

interface CalendarCell {
    dateStart: any
    dateEnd: any
    day: any
    paint: any
    in: boolean
    hasEvent: boolean
    index: number

}



const CalendarMain: React.FC<IProps> = (props) => {
    const [rows, setRows] = useState<any[]>([]);
    const [currentEventDate, setCurrentEventDate] = React.useState<CalendarCell | null>(null);
    const [currentDayEventArray, setCurrentDayEventArray] = useState<IEvent[]>([]);


    const calendarCellRefs = useRef<any>([]);
    const eventPopupRef = useRef<any>(null);
    const [eventPopper, setEventPopper] = useState<any>({ open: false, class: 'event-day-popper' });


    useEffect(() => {
        document.addEventListener('mousedown', handleEventListeners);
        window.addEventListener('resize', handleEventListeners);
    }, [])

    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {
                    if (eventPopupRef.current && !eventPopupRef.current.contains(event.target)) {
                        let foundIt = false;
                        for (let x = 0; x < calendarCellRefs.current.length; x++) {
                            if (calendarCellRefs.current[x] && calendarCellRefs.current[x].classList.contains('kekax-cell-selected') && !calendarCellRefs.current[x].contains(event.target)) {
                                //calendarCellRefs.current[x].classList.remove('kekax-cell-selected')
                                setEventPopper({ open: false, class: 'event-day-popper' })
                            }

                            if (calendarCellRefs.current[x].contains(event.target)) {
                                foundIt = true;
                            }

                        }
                        if (!foundIt) {
                            //setCurrentEventDate(null)
                        }


                    }

                    break
                }

                case 'resize': {
                    setEventPopper({ open: false, class: 'event-day-popper' })
                    break
                }
                default: {

                }
            }
        }



    }

    useEffect(() => {
        const monthStart = dateFns.startOfMonth(props.currentDate); //first day month - its a date
        const monthEnd = dateFns.endOfMonth(monthStart); //last day month - its a date
        const startDate = dateFns.startOfWeek(monthStart); //pos 0 - its a date
        let endDate = dateFns.endOfWeek(monthEnd); //end pos -its a date

        const todayDate = new Date();
        const todayStart = dateFns.startOfDay(todayDate); //midnight

        const auxRows = [];
        const dateFormat = "d";

        let day = startDate; //midnight
        let days: any[] = [];
        let formattedDate = "";

        let totalDaysCalendarPage = 0;
        let greysBefore = 0;
        let greysAfter = 0;

        //add greys before
        if (!dateFns.isEqual(monthStart, startDate)) {
            totalDaysCalendarPage = dateFns.getDaysInMonth(startDate) - dateFns.getDate(startDate) + 1;
            greysBefore = totalDaysCalendarPage;

        }

        //add this month days
        totalDaysCalendarPage += dateFns.getDaysInMonth(monthStart);

        //add greys after
        if (!dateFns.isEqual(endDate, monthEnd)) {
            greysAfter = dateFns.getDate(endDate);
            totalDaysCalendarPage += greysAfter;
        }


        //if has 5 rows
        if (totalDaysCalendarPage === 35) {
            if (greysAfter >= greysBefore) {
                day = dateFns.subWeeks(day, 1);
            } else {
                endDate = dateFns.addWeeks(endDate, 1);
            }
        }


        let globalCounter = 0;
        while (day <= endDate) {

            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);

                let classType: string = "container-calendar-main-table-column ";
                let inType: boolean = false;
                let hasEventType: boolean = false;
                const endDay = dateFns.endOfDay(day);

                if (currentEventDate && currentEventDate.dateStart === dateFns.getTime(day) && currentEventDate.dateEnd === dateFns.getTime(endDay)) {
                    classType = classType.concat(currentEventDate.paint + ' ');

                }

                if (day < monthStart || day > monthEnd) {
                    classType = classType.concat('kekax-paint')
                    inType = false;
                } else {
                    classType = classType.concat('kekax-cell')
                    inType = true;
                }

                for (let i = 0; i < props.eventsArray.length; i++) {
                    if (dateFns.isSameDay(props.eventsArray[i].timestamp, dateFns.getTime(day)) && inType) {
                        hasEventType = true;
                    }
                }


                if (dateFns.isEqual(todayStart, day)) {
                    classType = "container-calendar-main-table-column kekax-cell kekax-cell-today";
                }

                if (!currentEventDate) {
                    const todayEnd = dateFns.endOfDay(todayDate);
                    const todayDayFormatted = dateFns.format(todayDate, dateFormat);
                    classType = "container-calendar-main-table-column kekax-cell kekax-cell-today";
                    setCurrentEventDate({ dateStart: dateFns.getTime(todayStart), dateEnd: dateFns.getTime(todayEnd), day: todayDayFormatted, paint: classType, in: inType, hasEvent: hasEventType, index: globalCounter })
                }

                days.push(
                    { dateStart: dateFns.getTime(day), dateEnd: dateFns.getTime(endDay), day: formattedDate, paint: classType, in: inType, hasEvent: hasEventType, index: globalCounter }
                )

                day = dateFns.addDays(day, 1);
                globalCounter++
            }
            auxRows.push(days)
            days = [];
        }
        if (todayDate.getTime() >= monthStart.getTime() && todayDate.getTime() <= monthEnd.getTime()) {
            //today é neste mês
        } else {

        }
        //const finalDay = new Date(monthStart.getFullYear(),monthStart.getMonth()+1,0).getDate()
        //console.log(finalDay)
        setRows([...auxRows])

    }, [props.currentDate, props.eventsArray, currentEventDate])






    const handleClickCell = (event: any, cell: CalendarCell) => {
        if (eventPopupRef.current && calendarCellRefs.current[cell.index] && cell.in) {



            if (cell.hasEvent) {
                const myElement: HTMLElement | null = calendarCellRefs.current[cell.index];
                const myParent: HTMLElement | null = document.getElementById("calendarmain-parent-container");
                if (myElement !== null && myParent !== null) {
                    eventPopupRef.current.style.top = myElement.offsetTop + myElement.offsetHeight + 'px';
                    // refSearchDetails.current.style.left = myElement.offsetLeft + 'px';
                    eventPopupRef.current.style.left = myElement.offsetLeft + 'px';
                }



                if (!eventPopper.open) {
                    calendarCellRefs.current[cell.index].classList.add('kekax-cell-selected')
                    setCurrentEventDate({ ...cell, paint: "kekax-cell-selected" })
                    setEventPopper({ open: true, class: 'event-day-popper event-day-popper-open' })


                } else {
                    if (calendarCellRefs.current[cell.index].classList.contains('kekax-cell-selected')) {
                        calendarCellRefs.current[cell.index].classList.remove('kekax-cell-selected')
                        setCurrentEventDate(null)
                    }
                    setEventPopper({ open: false, class: 'event-day-popper' })

                }


            } else {
                if (calendarCellRefs.current[cell.index].classList.contains('kekax-cell-selected')) {
                    calendarCellRefs.current[cell.index].classList.remove('kekax-cell-selected')
                    setCurrentEventDate(null)

                } else {
                    calendarCellRefs.current[cell.index].classList.add('kekax-cell-selected')
                    setCurrentEventDate({ ...cell, paint: "kekax-cell-selected" })
                }


            }

        }


    }



    const handleAccessEvent = (id: any) => {
        let eventURL = GeneralServices.eventPath.replace(':id', id);
        /*
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        */
        props.history.push(eventURL);
    }

    useEffect(() => {
        if (currentEventDate && !currentEventDate.hasEvent) {
            props.cbCurrentEventDate({ date: currentEventDate, events: [] });
        } else if (currentEventDate && currentEventDate.hasEvent) {
            httpGetListEvents('?from=' + currentEventDate.dateStart + '&to=' + currentEventDate.dateEnd).then(
                res => {
                    const dataArray: IEvent[] = [...res.data];
                    if (dataArray.length > 0) {
                        setCurrentDayEventArray(dataArray);
                        props.cbCurrentEventDate({ date: currentEventDate, events: dataArray });
                        /*
                        for (let i = 0; i < dataArray.length; i++) {
                            //const timeToDate = dateFormat(new Date(dataArray[i].timestamp), "MMMM dd, yyyy");
                            //dataArray[i].timestamp = dateFormat(new Date(timeToDate), "MMMM dd, yyyy");
                        }
                        */
                    }
                }
            ).catch(
                err => {
                }
            )
        }
    }, [currentEventDate])


    return (
        <div className="wrapper-calendar-main" id="calendarmain-parent-container">
            {
                rows.map((row: any, indexR: any) => (
                    <div className="container-calendar-main-table-row" key={indexR}>
                        {
                            row.map((cell: CalendarCell, indexC: any) => (

                                <div ref={el => calendarCellRefs.current[cell.index] = el} className={cell.paint} key={indexC} onClick={(event: any) => handleClickCell(event, cell)}>
                                    <div className="container-flex-day">
                                        <div className="day-events">
                                            {
                                                cell.hasEvent ?
                                                    <div className="event-badge">
                                                        <div className="event-badge-icon"></div>
                                                    </div>

                                                    : null
                                            }

                                        </div>

                                        {
                                            cell.hasEvent ?
                                                <div className="day-number">
                                                    <span className="ayy-event">{cell.day}</span>
                                                </div>
                                                :
                                                <div className="day-number">
                                                    <span>{cell.day}</span>
                                                </div>

                                        }
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                ))
            }
            <div ref={eventPopupRef} className={eventPopper.class}>

                <div className="event-paper-wrapper">
                    <div className="container-event-paper-header">
                        <span>{props.appLanguage.events.eventDay}</span>
                    </div>

                    <div className="container-event-paper-body">
                        {
                            currentDayEventArray.map((event: IEvent, index: any) => (
                                <div className="event-day-object" key={index} onClick={() => handleAccessEvent(event.id)}>
                                    <span>{event.title}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default connect(mapStateToProps)(withRouter(CalendarMain));


