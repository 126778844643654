import React, { useEffect, useState, useRef, } from 'react';
import "./Profile.css"
import { mapStateToProps, mapDispatchToProps, StoreProps } from '../../store/storeApp';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { httpGetUserInfo, httpChangeUserPhotoRequest } from '../../services/requestService';
import GeneralServices from '../../services/generalService.json'
import banner from '../../assets/homepage/ay.jpg'
import DeleteProfileModal from './deleteProfileModal/DeleteProfileModal';
import EditProfileModal from './editProfileModal/EditProfileModal';

import SnackKekax from '../kekax/SnackKekax';
import TooltipKekax from '../kekax/TooltipKekax';
import { ReactCookieProps, withCookies } from 'react-cookie';

interface IProps extends StoreProps, RouteComponentProps<any>, ReactCookieProps {

}

export interface IUser {
    userId: string
    realName: string
    email: string
    country: string
}

/*
const userBot: IUser = {
    userId: "Drakekax",
    realName: "Daniel Silva",
    email: "dpsilvaaa@gmail.com",
    country: "PT",
}
*/

/*
interface IAddButton {
    id: number
    info: string
    style: string
}

interface IShowButton {
    span: string
    status: boolean
}
*/

export const Profile: React.FC<IProps> = (props) => {

    const [userData, setUserData] = useState<IUser>({ userId: "", realName: "", email: "", country: "" })
    const profileChangeRef: any = useRef(null);
    //const [changedImage, setChangedImage] = useState<any>(null);
    //const [addButton, setAddButton] = useState<IAddButton>({ id: 0, info: "", style: "" });
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    //const [showButton, setShowButton] = useState<IShowButton>({ span: "show more", status: false })
    //const [selectedTab, setSelectedTab] = useState<string>("0")

    const snackKekaxRef: any = useRef(null);


    useEffect(() => {
        document.documentElement.style.setProperty("--profile-banner", "url(" + banner + ")");
        //friendStatus(1);
    }, [props.appLanguage])


    useEffect(() => {
        if (props.sessionToken && userData.userId === "") {

            httpGetUserInfo().then(
                res => {
                    setUserData({ ...res.data });
                }
            ).catch(
                err => {
                    //setUserData({ ...userData, img: props.userImage });
                }
            )

            //setUserData({ ...userBot })

        }
    }, [props.sessionToken])




    const onFileSelected = (event: any) => {
        const fileTarget = event.target.files[0];
        if (fileTarget) {
            let fileExt = fileTarget.name.split('.').pop();
            httpChangeUserPhotoRequest({ img: fileTarget, ext: fileExt }).then(
                res => {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        let newLogoProfile = reader.result;
                        localStorage.setItem(GeneralServices.localStorage.localProfileImage, newLogoProfile as string);
                        props.changeUserImage({ userImage: newLogoProfile as string })

                    }
                    reader.readAsDataURL(fileTarget)
                    //setState({logoProfile: URL.createObjectURL(selectedLogoProfile)},()=>{console.log(logoProfile)})
                }
            )
        }
    }


    const onClickFile = () => {
        profileChangeRef.current.click();
    }

    /*
    const friendStatus = (status: any) => {

        switch (status) {

            //if not friends == 0
            case 0: {
                setAddButton({ id: 0, info: props.appLanguage.profile.options.friend.add, style: "add" })
                break;
            }
            //if friends == 1
            case 1: {
                setAddButton({ id: 1, info: props.appLanguage.profile.options.friend.remove, style: "remove" })
                break;
            }
            //if waiting for request == 2
            case 2: {
                setAddButton({ id: 2, info: props.appLanguage.profile.options.friend.cancel, style: "cancel" })
                break;
            }
            //if not friends == 0
            default: {
                setAddButton({ id: 0, info: props.appLanguage.profile.options.friend.add, style: "add" })
            }
        }

    }
  
    const friendStatusHandler = (status: any) => {

        switch (status) {

            //IF IM NOT YOUR FRIEND IM ADDING YOU SO I CAN CANCEL THE REQUEST
            case 0: {
                setAddButton({ id: 2, info: props.appLanguage.profile.options.friend.cancel, style: "cancel" })
                break;
            }
            //IF IM YOUR FRIEND THEN IM REMOVING YOU SO I CAN ADD YOU
            case 1: {
                setAddButton({ id: 0, info: props.appLanguage.profile.options.friend.add, style: "add" })
                break;
            }
            //IF IM CANCELING THE REQUEST IM NOT YOUR FRIEND
            case 2: {
                setAddButton({ id: 0, info: props.appLanguage.profile.options.friend.add, style: "add" })
                break;
            }
            //IM NOT YOUR FRIEND BYE
            default: {
                setAddButton({ id: 0, info: props.appLanguage.profile.options.friend.add, style: "add" })
            }
        }

    }
    */

    const editProfileHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.profile.editProfileModal.editProfileSuccess)
            setEditModal(false);
            httpGetUserInfo().then(
                res => {
                    setUserData({ ...res.data });
                }
            ).catch(
                err => {
                    //setUserData({ ...userData, img: props.userImage });
                }
            )
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.profile.editProfileModal.editProfileFailed)
            setEditModal(false);
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal(false);
        } else {
            setEditModal((val) => !val);
        }
    }

    const deleteProfileHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal(false);
            if (props.cookies !== undefined) {
                if (GeneralServices.develop) {
                    props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfigDevelop);
                    props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfigDevelop);
                    props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfigDevelop);
                }
            }
            localStorage.clear();
            props.logoutSession({ situation: 1 });
            props.history.push(GeneralServices.homePath)
            //const time:any = setTimeout(() =>  {snackKekaxRef.current.openSnackBar(props.appLanguage.profile.deleteProfileModal.deleteProfileSuccess);clearTimeout(time)}, 1000)


        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.profile.deleteProfileModal.deleteProfileFailed)
            setDeleteModal(false);
        } else if (childData === GeneralServices.constants.child.rmvSelfAdminImmune) {
            setDeleteModal(false);
            snackKekaxRef.current.openSnackBar(props.appLanguage.profile.deleteProfileModal.cantDeleteAdminError)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal(false);
        } else {
            setDeleteModal((val) => !val);
        }
    }




    /*
    const showButtonHandler = () => {
        let newSpan = null;
    
        if (showButton.status) {
            newSpan = "show more";
        } else {
            newSpan = "show less";
    
        }
        setShowButton({ span: newSpan, status: !showButton.status });
    }
    
    
    
    
        <div className="show-more" onClick={showButtonHandler}>
            <span>{showButton.span}</span>
        </div>
        style={showButton.status ? { display: 'block' } : { display: 'none' }}
    */

    return (
        <React.Fragment>
            <div className="wrapper-profile">
                <div className="profile-banner"></div>
                <div className="profile-container">
                    <div className="profile-image-label">
                        <div className="profile-image">
                            <img src={props.userImage} alt="profile-img" ></img>
                        </div>
                        <div className="image-change" onClick={onClickFile}>
                            <button className="image-change-fab"><div className="change" /></button >
                            <input ref={profileChangeRef} hidden type="file" accept='image/*' onChange={onFileSelected} />
                        </div>
                    </div>

                    <div className="profile-id-label">
                        <span className="user">{userData.userId}</span>
                        <span className="country">#{userData.country}</span>
                    </div>

                    <div className="profile-info-label">
                        <div className="info-left">
                            <div className="name">
                                <span className="label">{props.appLanguage.profile.label.name}</span>
                                <span className="data">{userData.realName}</span>
                            </div>
                        </div>
                        <div className="info-right">
                            <div className="email">
                                <span className="label">{props.appLanguage.profile.label.email}</span>
                                <span className="data">{userData.email}</span>
                            </div>
                        </div>
                    </div>


                    <div className="profile-content">
                        <div className="edit-profile" >
                            <TooltipKekax content={props.appLanguage.profile.editProfileModal.title} direction="top">
                                <button onClick={() => setEditModal(true)}>
                                    <div className="img" />
                                </button>
                            </TooltipKekax>
                        </div>
                        <div className="delete-profile" >
                            <TooltipKekax content={props.appLanguage.profile.deleteProfileModal.title} direction="top">
                                <button onClick={() => setDeleteModal(true)}>
                                    <div className="img" />
                                </button>
                            </TooltipKekax>
                        </div>
                    </div>
                </div>



            </div>
            <DeleteProfileModal open={deleteModal} callback={deleteProfileHandler} />
            <EditProfileModal open={editModal} callback={editProfileHandler} user={userData} />
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Profile));

/*
                    <div className="profile-menu-label">
                        <div className="menu-left">

                        </div>
                        <div className="menu-right">
                            <button className={addButton.style} onClick={() => friendStatusHandler(addButton.id)}>
                                <div className="img" />
                                <span className="info">{addButton.info}</span>
                            </button>
                        </div>
                    </div>
*/

/*
                        <button className="achivement-profile">
                            <div className="img" />
                        </button>
*/