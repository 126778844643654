import React, { useState, useEffect, useRef } from 'react'
import "./AddAuxSituation.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../../services/generalService.json'
import StepperKekax from '../../../../kekax/StepperKekax';
import { IAuxSituation } from '../AuxSituation';
import { httpPostAuxSituation } from '../../../../../services/requestService';
//import { httpPostAuxSituation } from '../../../../../services/requestService';

interface IProps extends StoreProps {
    callback: any
    data: IAddAuxSituation
}





export interface IAddAuxSituation {
    open: boolean
    situation:IAuxSituation
}



export interface IFormErrorAuxSituation {
    situationError: string


}
export interface IFormInputAuxSituation {
    situation: string

}
export interface IFormValidAuxSituation {
    situationValid: boolean


}
export interface IFormFocusAuxSituation {
    situationFocus: boolean

}

interface IAddAuxSituationValidation {
    main: boolean
}




const FORMSITUATIONEID: string = 'situation';



export const AddAuxSituation: React.FC<IProps> = (props) => {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);


    const [formSubmitValid, setFormSubmitValid] = useState<IAddAuxSituationValidation>({ main: false });
    const [formError, setFormError] = useState<IFormErrorAuxSituation>({ situationError: '' });
    const [formInput, setFormInput] = useState<IFormInputAuxSituation>({ situation: '' });
    const [formValid, setFormValid] = useState<IFormValidAuxSituation>({ situationValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusAuxSituation>({ situationFocus: false});


    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    useEffect(() => {

        if (props.data.open && modalRef.current) {
            modalRef.current.classList.add("addAuxSituation-modal-open");

            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();

        } else {
            modalRef.current.classList.remove("addAuxSituation-modal-open");
        }

    }, [props.data.open])


    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        setTempDisabled(0)
        formEndup();
        props.callback(feedback);
        modalRef.current.classList.remove("addAuxSituation-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--addModalAuxSituation-topOffset", posY + "px");
    }


    const formEndup = () => {
        contentRef.current.reset();
        setFormSubmitValid({ main: false });
        setFormError({ situationError: '' });
        setFormInput({ situation: '' });
        setFormValid({ situationValid: false});
        setFormFocus({ situationFocus: false });

        setTempDisabled(0)


    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMSITUATIONEID: {
                setFormInput({ ...formInput, situation: value })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_situationValid = formValid.situationValid;


        /*TITLE VALIDATION*/
        if (formInput.situation === '') {
            upd_situationValid = false;
            fieldValidationErrors.situationError = props.appLanguage.backoffice.manageMines.aux.situation.add.requiredError;
        } else {
            upd_situationValid = true;
            fieldValidationErrors.situationError = props.appLanguage.backoffice.manageMines.aux.situation.add.situationAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({ situationValid: upd_situationValid})


        //eslint-disable-next-line
    }, [formInput, formFocus])




    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.situationValid});
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMSITUATIONEID: {
                setFormFocus({ ...formFocus, situationFocus: true })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)

        
        httpPostAuxSituation(
            {
                situation: formInput.situation,
            }
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
    
        //closeModalHandler(GeneralServices.constants.child.closed);

    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMSITUATIONEID: {
                return formFocus.situationFocus && !formValid.situationValid ?
                    <span className="input-invalid-feedback-kekax">{formError.situationError}</span>
                    : formValid.situationValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.aux.situation.add.situationAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }



    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (

                <div className="container-add-auxSituation-main">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.aux.situation.add.information.main}</span></div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.aux.situation.add.situation} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.aux.situation.add.situationph}
                                id={FORMSITUATIONEID}
                                onChange={(event: any) => changeInputHandler(event)}
                                onFocus={(event: any) => validateFocusFields(event)}
                                value={formInput.situation || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSITUATIONEID)
                                }
                            </div>
                        </div>
                    </div>
                 
                </div>


            )
        } else if (selectedStepper === "1") {
            return null
        } else if (selectedStepper === "2") {
            return null
        }
    }


    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="submit"><button type="submit" disabled={!formSubmitValid.main || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageMines.aux.situation.add.submit} </button></div>
                )
            }
            default: {
                return null;
            }
        }
    }


    return (


        <div className="wrapper-add-auxSituation" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>

            <form className="content" ref={contentRef} onSubmit={(event: any) => submitHandler(event)}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.backoffice.manageMines.aux.situation.add.header}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <div className="container-stepper">
                        <StepperKekax active={selectedStepper}>
                            <div key="0">{props.appLanguage.backoffice.manageMines.aux.situation.add.information.main}</div>
                        </StepperKekax>
                    </div>
                    <div className="container-stepper-body">
                        <div className="container-form-add-auxSituation">

                            {
                                printActiveContent()
                            }


                        </div>
                    </div>

                </div>
                <div className="footer">
                    <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageMines.aux.situation.add.close}</span></button></div>
                    {
                        printSubmitButton()

                    }
                </div>
            </form>
        </div>
    );

}


export default connect(mapStateToProps)(AddAuxSituation);
