import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddPublication.css';
import { httpPostPublication } from '../../../../services/requestService';

import GeneralServices from "../../../../services/generalService.json"
import SnackKekax from '../../../kekax/SnackKekax';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorPublication {
    dateError: string
    titleError: string
    text1Error: string
    text2Error: string
    urlError: string

}
export interface IFormInputPublication {
    date: any
    title: string
    text1: string
    text2: string
    url: string

}
export interface IFormValidPublication {
    dateValid: boolean
    titleValid: boolean
    text1Valid: boolean
    text2Valid: boolean
    urlValid: boolean


}
export interface IFormFocusPublication {
    dateFocus: boolean
    titleFocus: boolean
    text1Focus: boolean
    text2Focus: boolean
    urlFocus: boolean
}




const FORMDATEID: string = 'date';
const FORMTEXT1ID: string = 'text1';
const FORMTEXT2ID: string = 'text2';
const FORMTITLEID: string = 'title';
const FORMURLID: string = 'url';


export const AddPublication: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormErrorPublication>({ dateError: '', titleError: '', text1Error: '', text2Error: '', urlError: '' });
    const [formInput, setFormInput] = useState<IFormInputPublication>({ date: new Date(), title: '', text1: '', text2: '', url: '' });
    const [formValid, setFormValid] = useState<IFormValidPublication>({ dateValid: false, titleValid: false, text1Valid: false, text2Valid: false, urlValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusPublication>({ dateFocus: false, titleFocus: false, text1Focus: false, text2Focus: false, urlFocus: false });


    const formRef = useRef<any>(null);
    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);



    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);






    const closeAddEventHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid(false);
        setFormError({ dateError: '', titleError: '', text1Error: '', text2Error: '', urlError: '' });
        setFormInput({ date: new Date(), title: '', text1: '', text2: '', url: '' })
        setFormValid({ dateValid: false, titleValid: false, text1Valid: false, text2Valid: false, urlValid: false })
        setFormFocus({ dateFocus: false, titleFocus: false, text1Focus: false, text2Focus: false, urlFocus: false })


        setTempDisabled(0)

        props.cbMessage(what);

    }

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMTEXT1ID: {
                setFormInput({ ...formInput, text1: value })
                break;
            }
            case FORMTEXT2ID: {
                setFormInput({ ...formInput, text2: value })
                break;
            }
            case FORMURLID: {
                setFormInput({ ...formInput, url: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    const changeDateHandler = (date: Date | null, value: any, id: any) => {
        if (id === FORMDATEID) {
            console.log(date)
            setFormInput({ ...formInput, date: date })
        }
    };


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;
        let upd_dateValid = formValid.dateValid;
        let upd_text1Valid = formValid.text1Valid;
        let upd_text2Valid = formValid.text2Valid;
        let upd_urlValid = formValid.urlValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.managePublications.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.managePublications.add.titleAccept;
        }

        /*DATE VALIDATION*/
        upd_dateValid = true;
        fieldValidationErrors.dateError = props.appLanguage.backoffice.managePublications.add.dateAccept;

        /*TEXT1 VALIDATION*/
        if (formInput.text1 === '') {
            upd_text1Valid = false;
            fieldValidationErrors.text1Error = props.appLanguage.backoffice.managePublications.add.requiredError;
        } else {
            upd_text1Valid = true;
            fieldValidationErrors.text1Error = props.appLanguage.backoffice.managePublications.add.text1Accept;
        }

        /*TEXT2 VALIDATION*/
        if (formInput.text2 === '') {
            upd_text2Valid = false;
            fieldValidationErrors.text2Error = props.appLanguage.backoffice.managePublications.add.requiredError;
        } else {
            upd_text2Valid = true;
            fieldValidationErrors.text2Error = props.appLanguage.backoffice.managePublications.add.text2Error;
        }

        /*URL VALIDATION*/
        if (formInput.url === '') {
            upd_urlValid = false;
            fieldValidationErrors.urlError = props.appLanguage.backoffice.managePublications.add.requiredError;
        } else {
            upd_urlValid = true;
            fieldValidationErrors.urlError = props.appLanguage.backoffice.managePublications.add.urlAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid, dateValid: upd_dateValid, text1Valid: upd_text1Valid, text2Valid: upd_text2Valid, urlValid: upd_urlValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.titleValid && formValid.dateValid && formValid.text1Valid && formValid.text2Valid)
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMDATEID: {
                setFormFocus({ ...formFocus, dateFocus: true })
                break;
            }
            case FORMTEXT1ID: {
                setFormFocus({ ...formFocus, text1Focus: true })
                break;
            }
            case FORMTEXT2ID: {
                setFormFocus({ ...formFocus, text2Focus: true })
                break;
            }
            case FORMURLID: {
                setFormFocus({ ...formFocus, urlFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);


        httpPostPublication({
            year: formInput.date.getFullYear(),
            title: formInput.title,
            text1:formInput.text1,
            text2:formInput.text2,
            url:formInput.url
        }).then(
            (res: any) => {
                closeAddEventHandler(GeneralServices.constants.child.worked);
            },
            (err: any) => {
                closeAddEventHandler(GeneralServices.constants.child.failed);
            }

        )


        /*closeAddEventHandler(GeneralServices.constants.child.closed);*/

    }




    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.managePublications.add.titleAccept}</span>
                        : null
            }
            case FORMDATEID: {
                return formFocus.dateFocus && !formValid.dateValid ?
                    <span className="input-invalid-feedback-kekax">{formError.dateError}</span>
                    : formValid.dateValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.managePublications.add.dateAccept}</span>
                        : null
            }
            case FORMTEXT1ID: {
                return formFocus.text1Focus && !formValid.text1Valid ?
                    <span className="input-invalid-feedback-kekax">{formError.text1Error}</span>
                    : formValid.text1Valid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.managePublications.add.text1Accept}</span>
                        : null
            }
            case FORMTEXT2ID: {
                return formFocus.text2Focus && !formValid.text2Valid ?
                    <span className="input-invalid-feedback-kekax">{formError.text2Error}</span>
                    : formValid.text2Valid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.managePublications.add.text2Accept}</span>
                        : null
            }
            case FORMURLID: {
                return formFocus.urlFocus && !formValid.urlValid ?
                    null
                    : formValid.urlValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.managePublications.add.urlAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }


    return (
        <React.Fragment>
            <div className="wrapper-add-publication">
                <form ref={formRef} id='add-publication-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-add-publication-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.managePublications.add.information.main}</span></div>

                        <div className="main-row">

                            <div className="input-calendar">
                                <label className="label">
                                    {props.appLanguage.backoffice.managePublications.add.date}
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="input-calendar-datepicker"
                                        disableToolbar
                                        variant="inline"
                                        margin="normal"
                                        views={["year"]}
                                        value={formInput.date || ""}
                                        onChange={(date: any, value: any) => changeDateHandler(date, value, FORMDATEID)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage={props.appLanguage.backoffice.managePublications.add.dateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDATEID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.managePublications.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.managePublications.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="main-row">
                            <div className="input-text1">
                                <label className="label">
                                    {props.appLanguage.backoffice.managePublications.add.text1} *

                                </label>
                                <textarea className="input-text1-text kekax-input-box"
                                    placeholder={props.appLanguage.backoffice.managePublications.add.text1ph}
                                    id={FORMTEXT1ID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.text1 || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTEXT1ID)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="main-row">
                            <div className="input-text2">
                                <label className="label">
                                    {props.appLanguage.backoffice.managePublications.add.text2} *

                                </label>
                                <textarea className="input-text2-text kekax-input-box"
                                    placeholder={props.appLanguage.backoffice.managePublications.add.text2ph}
                                    id={FORMTEXT2ID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.text2 || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTEXT2ID)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="main-row">
                            <div className="input-url">
                                <label className="label">
                                    {props.appLanguage.backoffice.managePublications.add.url}

                                </label>
                                <input className="input-url-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.managePublications.add.urlph}
                                    id={FORMURLID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.url || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMURLID)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-add-publication-content">
                        <div className="container-title"><span>{props.appLanguage.backoffice.managePublications.add.information.content}</span></div>


                        <div className="add-publication-content-text">
                            <div className="add-publication-content-text-input">

                                <span className="content-text-text1" >
                                    {formInput.text1}
                                </span>
                                <span className="content-text-title" >
                                    {formInput.title}
                                </span>
                                <span className="content-text-text2" >
                                    {formInput.text2}
                                </span>
                            </div>

                        </div>


                    </div>

                    <div className="container-form-bottom">
                        <div className="submit"><button type="submit" className="submit-btn" disabled={!formSubmitValid || tempDisabled === 1}><span className="info">{props.appLanguage.backoffice.managePublications.add.submit}</span></button></div>
                    </div>
                </form>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );
}

export default connect(mapStateToProps)(AddPublication);