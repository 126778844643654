import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './GamePage.css';

import gameSearch from '../../assets/figma/game/gameSearch.svg'
import gamePlay from '../../assets/figma/game/gamePlay.svg'
import gameMap from '../../assets/figma/game/gameMap.svg'

import { RouteComponentProps } from 'react-router-dom';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';

import GeneralServices from '../../services/generalService.json'
import { httpGetAllMines, httpGetQuestionMine } from '../../services/requestService';
import { IMineInfo } from '../mineMap/MineMap';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WannaPlayModal from './wannaPlayModal/WannaPlayModal';
import { connect } from 'react-redux';
import SnackKekax from '../kekax/SnackKekax';

interface IProps extends RouteComponentProps<any>, StoreProps { }

/*
const dummyMines: IMineInfoForList[] = [
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false },
    { mineCode: "-1", name: "Mina do Alface", country: "Cidade da Alface", quizz: false }
]
*/

export interface IMineInfoForList {
    mineCode: string
    name: string
    country: string
    quizz: boolean
}

interface ModalSelect {
    what: IMineInfoForList
    show: boolean
}


export const GamePage: React.FC<IProps> = props => {

    const cardRef1 = useRef<any>(null);
    const optionRef1 = useRef<any>(null);


    const cardRef2 = useRef<any>(null);
    const optionRef2 = useRef<any>(null);

    const cardRef3 = useRef<any>(null);
    const optionRef3 = useRef<any>(null);



    const menuRef = useRef<any>(null);



    const [showWhat, setShowWhat] = useState(0);
    const [allMinesList, setAllMinesList] = useState<IMineInfoForList[]>([]);
    const [displayMinesList, setDisplayMinesList] = useState<IMineInfoForList[]>([]);

    const maxMinesArray: number = 15;
    const maxPagesArray: number = 5;

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);
    const [totalMines, setTotalMines] = useState<number>(0);

    const [modalShow, setModalShow] = useState<ModalSelect>({ what: { mineCode: "-1", name: "", country: "", quizz: false }, show: false });
    const snackKekaxRef: any = React.useRef();

    useEffect(() => {
        if (optionRef1.current !== null) {
            optionRef1.current.addEventListener('mousemove', (event: any) => firstOptionEventMove(event), true);
        }
        if (optionRef2.current !== null) {
            optionRef2.current.addEventListener('mousemove', (event: any) => secondOptionEventMove(event), true);
        }
        if (optionRef3.current !== null) {
            optionRef3.current.addEventListener('mousemove', (event: any) => thirdOptionEventMove(event), true);
        }

        /*
        if (menuRef.current !== null) {
            menuRef.current.addEventListener('mouseleave', (event: any) => menuOptionEventLeave(event), true);
        }
        */



        /*
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        */

        return () => {
            if (optionRef1.current !== null && optionRef2.current !== null && optionRef3.current !== null && menuRef.current !== null) {
                optionRef1.current.removeEventListener('mousemove', firstOptionEventMove)
                optionRef2.current.removeEventListener('mousemove', secondOptionEventMove)
                optionRef3.current.removeEventListener('mousemove', thirdOptionEventMove)

                //menuRef.current.removeEventListener('mouseleave', menuOptionEventLeave)
            }

        }
    }, [])


    const firstOptionEventMove = (e: any) => {
        let x = -1 + 20 * (e.pageX / window.innerWidth * 2.5);
        let y = 10 - 15 * (e.pageY / window.innerHeight);

        if (cardRef1.current !== null) {
            cardRef1.current.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)"
        }
    }
    const secondOptionEventMove = (e: any) => {
        //let x = -60 - 20 + 40 * (e.pageX / window.innerWidth * 2.5);
        //let y = 20 - 40 * (e.pageY / window.innerHeight);
        let x = -50 + 25 * (e.pageX / window.innerWidth * 2.1);
        let y = 9 - 15 * (e.pageY / window.innerHeight);

        if (cardRef2.current !== null) {
            cardRef2.current.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)"
        }
    }

    const thirdOptionEventMove = (e: any) => {
        //let x = -60 - 20 + 40 * (e.pageX / window.innerWidth * 2.5);
        //let y = 20 - 40 * (e.pageY / window.innerHeight);
        let x = -15 + 20 * (e.pageX / window.innerWidth * 1.5);
        let y = 5 - 10 * (e.pageY / window.innerHeight);

        if (cardRef3.current !== null) {
            cardRef3.current.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)"
        }
    }

    /*
    const menuOptionEventLeave = (e: any) => {
        if (cardRef2.current !== null && cardRef1.current !== null) {
            cardRef2.current.style.transform = "rotateX(0deg) rotateY(0deg) "
            cardRef1.current.style.transform = "rotateX(0deg) rotateY(0deg)"
        }
    }
    */




    useEffect(() => {
        if (allMinesList.length > 0) {
            loadNewPage(0, true)
        }
    }, [allMinesList])

    const handleClickGoToMap = () => {
        const minePageUrl = GeneralServices.mineMapPath
        props.history.push(minePageUrl);
    }


    const handleClickGoToSearch = () => {
        httpGetAllMines().then(
            res => {
                const allMinesRes: IMineInfo[] = res.data
                let allMinesForList: IMineInfoForList[] = []
                //SETTING TOTAL NEWS
                if (allMinesRes !== undefined) {
                    setTotalMines(allMinesRes.length);
                } else {
                    setTotalMines(parseInt('1', 10));
                }
                for (let i = 0; i < allMinesRes.length; i++) {
                    allMinesForList.push({ mineCode: allMinesRes[i].mineCode, name: allMinesRes[i].siteNameNOL, country: allMinesRes[i].country, quizz: true })
                }
                
                setAllMinesList(allMinesForList)

            }
        ).catch(
            err => {
            }
        )
        setShowWhat(1)

    }

    const handleClickGoToMenu = () => {
        setShowWhat(0)
    }

    const handleClickGoToPlay = () => {
        const quizPageUrl = GeneralServices.quizPagePath.replace(":id", "general")
        props.history.push(quizPageUrl);
    }

    useEffect(() => {
        //SETTING PAGINATION

        let result = Math.floor(totalMines / maxMinesArray);
        const extra = totalMines % maxMinesArray;

        if (extra !== 0 || totalMines === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalMines])

    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])



    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }

    }, [counter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, false)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, false)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, false)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }
        setCounter(counterHax)
    }


    const loadNewPage = (page: any, init: boolean) => {
        const startFrom = page === 0 ? 0 : (page - 1) * maxMinesArray

        let newPageMines: IMineInfoForList[] = []
        for (let i = startFrom, counter = 0; i < allMinesList.length && counter < maxMinesArray; i++, counter++) {

            //const promise = new Promise((resolve: any) => {
            httpGetQuestionMine(allMinesList[i].mineCode).then(
                res => {
                    if (res.data.length === 0) {
                        allMinesList[i].quizz = false
                    } else {
                        allMinesList[i].quizz = true
                    }
                    //resolve(allMinesList[i])
                    newPageMines.push(allMinesList[i]);

                    if (newPageMines.length === maxMinesArray  || i === allMinesList.length-1) {
                        setDisplayMinesList([...newPageMines])
                    }
                }
            ).catch(
                err => {
                }
            )
            //})
            /*
            promise.then((result: any) => {
                newPageMines.push(result)
                if (newPageMines.length === maxMinesArray) {
                    setDisplayMinesList([...newPageMines])
                }
            }, function (error) {
            });
            */

        }

        if (!init) {
            const myParent = document.getElementById("gamepage-parent-container")
            if (myParent) {
                window.scrollTo({
                    top: myParent.getBoundingClientRect().top,
                    behavior: 'smooth'
                })
            }
        }

    }


    const callbackModalFunction = (childData: any) => {
        setModalShow({ ...modalShow, show: false });
    }





    return (
        <React.Fragment>
            <div className="wrapper-gamepage" id="gamepage-parent-container">
                {
                    showWhat === 0 ?
                        <div className="container-menu" ref={menuRef}>
                            <div className="menu-option" ref={optionRef1}>
                                <div className="card" ref={cardRef1} onClick={handleClickGoToSearch}>
                                    <div className="title"><span>{props.appLanguage.gamepage.home.search}</span></div>
                                    <div className="game-search">
                                        <img src={gameSearch} alt="gamesearch" ></img>
                                    </div>

                                </div>
                            </div>
                            <div className="menu-option" ref={optionRef3} >
                                <div className="card" ref={cardRef3} onClick={handleClickGoToPlay}>
                                    <div className="title"><span>{props.appLanguage.gamepage.home.play}</span></div>
                                    <div className="game-play">
                                        <img src={gamePlay} alt="gameplay" ></img>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-option" ref={optionRef2} >
                                <div className="card" ref={cardRef2} onClick={handleClickGoToMap}>
                                    <div className="title"><span>{props.appLanguage.gamepage.home.map}</span></div>
                                    <div className="game-map">
                                        <img src={gameMap} alt="gamemap" ></img>

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container-list">
                            <div className="backToLife">
                                <button className="return" onClick={handleClickGoToMenu}>
                                    <div className="icon"></div>
                                    <div className="text">
                                        <span >{props.appLanguage.gamepage.search.back}</span>

                                    </div>
                                </button>
                            </div>
                            <div className="list-mines">

                                <div className="row-mines row-mines-header">
                                    <div className="row-mines-name">
                                        <span>{props.appLanguage.gamepage.search.tabs[0]}</span>
                                    </div>
                                    <div className="row-mines-country">
                                        <span>{props.appLanguage.gamepage.search.tabs[1]}</span>
                                    </div>
                                    <div className="row-mines-quizz">
                                        <span>{props.appLanguage.gamepage.search.tabs[2]}</span>
                                    </div>
                                </div>

                                {

                                    displayMinesList.map((mine: IMineInfoForList, index: number) => (
                                        <div className="row-mines" key={"mines-row" + index} onClick={() => setModalShow({ what: mine, show: true })
                                    }>
                                            <div className="row-mines-name">
                                                <span>{mine.name}</span>
                                            </div>
                                            <div className="row-mines-country">
                                                <span>{mine.country}</span>
                                            </div>

                                            <div className={mine.quizz ? "row-mines-quizz mines-quizz-true" : "row-mines-quizz mines-quizz-true"}>
                                            </div>


                                        </div>
                                    ))
                                }

                            </div>
                            <div className="container-list-footer">
                                <div className="container-list-footer-info">

                                </div>
                                <div className="container-list-footer-options">
                                    <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                                    {
                                        pagesArray.map((pos, index) => (
                                            pos === counter ?
                                                <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                                :
                                                <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                                        ))
                                    }

                                    <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                                </div>
                            </div>
                        </div>


                }

            </div>
            <WannaPlayModal open={modalShow.show} what={modalShow.what} callback={callbackModalFunction} {...props} />
            <SnackKekax ref={snackKekaxRef} />
        </React.Fragment>
    )
};

export default connect(mapStateToProps)(GamePage);
/*
<div className="row-mines" key={"mines-row" + index} onClick={() => mine.quizz ? setModalShow({ what: mine, show: true }) : snackKekaxRef.current.openSnackBar(props.appLanguage.mapPage.noGame)
*/
