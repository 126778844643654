import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mineStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import './BibliographyAdd.css';

import GeneralServices from "../../../../../services/generalService.json"
import TooltipKekax from '../../../../kekax/TooltipKekax';

interface IProps extends StoreProps {
    formInput: any
    formError: any
    formValid: any
    formFocus: any
    injectData: IInjectData
    formValidation: any

}



export interface IFormErrorBibliography {
    mineCodeError: string
    bibliographicalResourceError: string
    webInfoResourceError: string
    otherBibliographicalInfoError: string


}
export interface IFormInputBibliography {

    id:string
    mineCode: string
    bibliographicalResource: string
    webInfoResource: string
    otherBibliographicalInfo: string
}

export interface IFormValidBibliography {
    mineCodeValid: boolean
    bibliographicalResourceValid: boolean
    webInfoResourceValid: boolean
    otherBibliographicalInfoValid: boolean
}

export interface IFormFocusBibliography {
    mineCodeFocus: boolean
    bibliographicalResourceFocus: boolean
    webInfoResourceFocus: boolean
    otherBibliographicalInfoFocus: boolean
}

interface IInjectData {
    injectInput: IFormInputBibliography | null
    injectValid: IFormValidBibliography | null
    injectFocus: IFormFocusBibliography | null
    injectError: IFormErrorBibliography | null
}


const FORMMINECODEDID: string = 'mineCode'
const FORMBIBLIOGRAPHICALRESOURCEID: string = 'bibliographicalResource'
const FORMWEBINFORESOURCEID: string = 'webInfoResource'
const FORMOTHERBIBLIOGRAPHICALINFOID: string = 'otherBibliographicalInfo'


export const BibliographyAdd: React.FC<IProps> = (props) => {

    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(true);

    const [formInput, setFormInput] = useState<IFormInputBibliography>({ id:'',mineCode: '', bibliographicalResource: '', webInfoResource: '', otherBibliographicalInfo: '' });
    const formInputRef = useRef<any>(null)

    const [formError, setFormError] = useState<IFormErrorBibliography>({ mineCodeError: '', bibliographicalResourceError: '', webInfoResourceError: '', otherBibliographicalInfoError: '' });
    const formErrorRef = useRef<any>(null)

    const [formValid, setFormValid] = useState<IFormValidBibliography>({ mineCodeValid: false, bibliographicalResourceValid: false, webInfoResourceValid: false, otherBibliographicalInfoValid: false });
    const formValidRef = useRef<any>(null)

    const [formFocus, setFormFocus] = useState<IFormFocusBibliography>({ mineCodeFocus: false, bibliographicalResourceFocus: false, webInfoResourceFocus: false, otherBibliographicalInfoFocus: false });
    const formFocusRef = useRef<any>(null)


    /*
    useEffect(() => {
        if (props.injectData.injectValid !== null && props.injectData.injectError !== null && props.injectData.injectFocus !== null && props.injectData.injectInput !== null) {
            setFormValid(props.injectData.injectValid)

            setFormError(props.injectData.injectError)

            setFormFocus(props.injectData.injectFocus)
            setFormInput(props.injectData.injectInput)
        }

    }, [props.injectData])
*/
    useEffect(() => {
        if (props.injectData.injectValid !== null && props.injectData.injectError !== null && props.injectData.injectFocus !== null && props.injectData.injectInput !== null) {
            setFormValid(props.injectData.injectValid)

            setFormError(props.injectData.injectError)

            setFormFocus(props.injectData.injectFocus)
            setFormInput(props.injectData.injectInput)
        }
        return () => {
            props.formError(formErrorRef.current)
            props.formFocus(formFocusRef.current)
            props.formInput(formInputRef.current)
            props.formValid(formValidRef.current)

        }
    }, [])

    useEffect(() => {
        props.formValidation(formSubmitValid)
    }, [formSubmitValid])

    useEffect(() => {
        formInputRef.current = formInput
    }, [formInput])
    useEffect(() => {
        formValidRef.current = formValid
    }, [formValid])
    useEffect(() => {
        formFocusRef.current = formFocus
    }, [formFocus])
    useEffect(() => {
        formErrorRef.current = formError
    }, [formError])





    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {

            case FORMMINECODEDID: {
                setFormInput({ ...formInput, mineCode: value })
                break;
            }
            case FORMBIBLIOGRAPHICALRESOURCEID: {
                setFormInput({ ...formInput, bibliographicalResource: value })

                break;
            }
            case FORMWEBINFORESOURCEID: {
                setFormInput({ ...formInput, webInfoResource: value })

                break;
            }
            case FORMOTHERBIBLIOGRAPHICALINFOID: {
                setFormInput({ ...formInput, otherBibliographicalInfo: value })

                break;
            }


            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors: IFormErrorBibliography = ({ mineCodeError: '', bibliographicalResourceError: '', webInfoResourceError: '', otherBibliographicalInfoError: '' });


        let upd_mineCodeValid = false;
        if (formInput.mineCode === '') {
            upd_mineCodeValid = false;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mineCodeValid = true;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.bibliography.mineCodeAccept;
        }

        let upd_bibliographicalResourceValid = false;
        if (formInput.bibliographicalResource === '') {
            upd_bibliographicalResourceValid = false;
            fieldValidationErrors.bibliographicalResourceError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_bibliographicalResourceValid = true;
            fieldValidationErrors.bibliographicalResourceError = props.appLanguage.backoffice.manageMines.add.bibliography.bibliographicalResourceAccept;
        }

        let upd_webInfoResourceValid = false;
        if (formInput.webInfoResource === '') {
            upd_webInfoResourceValid = false;
            fieldValidationErrors.webInfoResourceError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_webInfoResourceValid = true;
            fieldValidationErrors.webInfoResourceError = props.appLanguage.backoffice.manageMines.add.bibliography.webInfoResourceAccept;
        }

        let upd_otherBibliographicalInfoValid = false;
        if (formInput.otherBibliographicalInfo === '') {
            upd_otherBibliographicalInfoValid = false;
            fieldValidationErrors.otherBibliographicalInfoError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_otherBibliographicalInfoValid = true;
            fieldValidationErrors.otherBibliographicalInfoError = props.appLanguage.backoffice.manageMines.add.bibliography.otherBibliographicalInfoAccept;
        }




        setFormError(fieldValidationErrors)
        setFormValid({
            mineCodeValid: upd_mineCodeValid, bibliographicalResourceValid: upd_bibliographicalResourceValid, webInfoResourceValid: upd_webInfoResourceValid, otherBibliographicalInfoValid: upd_otherBibliographicalInfoValid
        })

        //eslint-disable-next-line
    }, [formInput, formFocus])



    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMMINECODEDID: {
                setFormFocus({ ...formFocus, mineCodeFocus: true })
                break;
            }
            case FORMBIBLIOGRAPHICALRESOURCEID: {
                setFormFocus({ ...formFocus, bibliographicalResourceFocus: true })
                break;
            }
            case FORMWEBINFORESOURCEID: {
                setFormFocus({ ...formFocus, webInfoResourceFocus: true })
                break;
            }

            case FORMOTHERBIBLIOGRAPHICALINFOID: {
                setFormFocus({ ...formFocus, otherBibliographicalInfoFocus: true })
                break;
            }



            default: {
                break;
            }
        }//switch end
    }


    const validatorFeedback = (formId: any) => {

        switch (formId) {
            /*INVENTORY*/
            case FORMMINECODEDID: {
                return formFocus.mineCodeFocus && !formValid.mineCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.mineCodeError}</span>
                    : formValid.mineCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.bibliography.mineCodeAccept}</span>
                        : null
            }


            case FORMBIBLIOGRAPHICALRESOURCEID: {
                return formFocus.bibliographicalResourceFocus && formValid.bibliographicalResourceValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.bibliography.bibliographicalResourceAccept}</span>
                    : null
            }
            case FORMWEBINFORESOURCEID: {
                return formFocus.webInfoResourceFocus && formValid.webInfoResourceValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.bibliography.webInfoResourceAccept}</span>
                    : null
            }
            case FORMOTHERBIBLIOGRAPHICALINFOID: {
                return formFocus.otherBibliographicalInfoFocus && formValid.otherBibliographicalInfoValid ?
                    <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.bibliography.otherBibliographicalInfoAccept}</span>
                    : null
            }

            default: {
                break;
            }
        }//switch end
    }



    return (
        <div className="wrapper-add-mine-bibliography">

            <div className="container-bibliography-form">
                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.bibliography.firstTitle}</span></div>

                <div className="main-row">

                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.bibliography.bibliographicalResource}
                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.bibliography.bibliographicalResourceph}
                            id={FORMBIBLIOGRAPHICALRESOURCEID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.bibliographicalResource || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMBIBLIOGRAPHICALRESOURCEID)
                            }
                        </div>
                    </div>
                </div>
                <div className="main-row">

                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.bibliography.webInfoResource}
                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.bibliography.webInfoResourceph}
                            id={FORMWEBINFORESOURCEID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.webInfoResource || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMWEBINFORESOURCEID)
                            }
                        </div>
                    </div>
                </div>

                <div className="main-row">
                    <div className="input-normal">
                        <label className="label">
                            {props.appLanguage.backoffice.manageMines.add.bibliography.otherBibliographicalInfo}

                        </label>
                        <textarea className="input-area-text kekax-input-box"
                            placeholder={props.appLanguage.backoffice.manageMines.add.bibliography.otherBibliographicalInfoph}
                            id={FORMOTHERBIBLIOGRAPHICALINFOID}
                            onChange={(event: any) => changeInputHandler(event)}
                            onFocus={(event: any) => validateFocusFields(event)}
                            value={formInput.otherBibliographicalInfo || ""}

                        />
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMOTHERBIBLIOGRAPHICALINFOID)
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>


    )
}


export default connect(mineStateToProps)(BibliographyAdd);