import React from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './Advertise.css'


interface IProps extends StoreProps { }

export const Advertise: React.FC<IProps> = (props) => {

    return (
        <div className="wrapper-advertise">
            <div className="container-advertise">
                <span>{props.appLanguage.news.ads[0]}</span>
                <span>{props.appLanguage.news.ads[1]}</span>
                <span>{props.appLanguage.news.ads[2]}</span>
            </div>
        </div>
    );

}

export default connect(mapStateToProps)(Advertise);
