import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';

import GeneralServices from './services/generalService.json'

import { httpUserSignOutRequest, httpGetUserUserPhotoRequest, getUserProfilePicURL } from './services/requestService';


import { withCookies, ReactCookieProps } from 'react-cookie';
import { StoreProps, mapStateToProps, mapDispatchToProps } from './store/storeApp';
import { connect } from 'react-redux';

import NavMain from './components/nav/NavMain';
import Home from './components/home/Home';
import MineMap from './components/mineMap/MineMap';
import ProjectBackground from './components/projectBackground/ProjectBackground';
import NoMatch from './components/nomatch/NoMatch';
import Footer from './components/footer/Footer';
import News from './components/news/News';
import NewsLetter from './components/news/newsLetter/NewsLetter';
import CalendarEvents from './components/calendarEvents/CalendarEvents';
import Consortium from './components/consortium/Consortium';
import Links from './components/links/Links';
import Documents from './components/documents/Documents';
import Publications from './components/publications/Publications';
import BackOffice from './components/backoffice/BackOffice';
import EventPage from './components/calendarEvents/eventPage/EventPage';
import Profile from './components/profile/Profile';
import GamePage from './components/game/GamePage';
import QuizPage from './components/game/quizPage/QuizPage';
import AdminAuth from './components/adminAuth/AdminAuth';
import Videos from './components/videos/Videos';
import Models from './components/models/Models';
import SnackKekax from './components/kekax/SnackKekax';



export function changeLanguage(newLang: any) {
  switch (newLang) {
    case GeneralServices.languagesTags.Portuguese: {
      return require('./services/languages/pt.json');
    }
    case GeneralServices.languagesTags.English: {
      return require('./services/languages/uk.json');
    }
    default: {
      return require('./services/languages/uk.json');
    }
  }
}


interface IProps extends RouteComponentProps<any>, ReactCookieProps, StoreProps { }

function App(props: IProps) {

  const snackKekaxRef: any = useRef(null);

  //const [prevLocation, setPrevLocation] = useState<string>("")

  /*
  useEffect(() => {
      if(props.history.action === "POP" && props.location.pathname.includes(GeneralServices.quizPagePath.slice(0,-3)) && prevLocation!==""){
        props.history.replace(prevLocation)
      }else{
        setPrevLocation(props.history.location.pathname)
      }

  }, [props.history,props.location,prevLocation])
  */


  /*ACCESS CONTROL*/
  useEffect(() => {

    if (props.cookies !== undefined) {
      const cookieToken = props.cookies.get(GeneralServices.cookies.cookieToken);
      const cookieUser = props.cookies.get(GeneralServices.cookies.cookieUser);
      const cookieRole = props.cookies.get(GeneralServices.cookies.cookieRole);

      //se tiver logado, cookies bem definidas e iguais ao redux
      if (props.loggedIn && cookieToken !== undefined && cookieUser !== undefined && cookieRole !== undefined && cookieToken === props.sessionToken && cookieUser === props.sessionUser && cookieRole === props.sessionRole) {

        //se role nao for backoffice e se quiser aceder ao backoffice
        if ((props.sessionRole !== GeneralServices.roles.manager && props.sessionRole !== GeneralServices.roles.admin) && props.location.pathname.includes(GeneralServices.backOfficePath)) {
          //não pode!
          props.history.push(GeneralServices.homePath)
        }

        //se quiser aceder ao backoffice mas não tiver sessão
      } else if (props.location.pathname.includes(GeneralServices.backOfficePath) && (cookieToken === undefined || cookieUser === undefined || cookieRole === undefined)) {
        props.history.push(GeneralServices.homePath)
      }
    }

  }, [props.history, props.location, props.sessionToken])


  useEffect(() => {
    initCheckSession();
    initLanguage();


    //SIMULATE LOGGIN
    /*
    const {cookies} = props;      
    if(cookies!==undefined){
        if(GeneralServices.develop){
            cookies.set(GeneralServices.cookies.cookieUser, GeneralServices.cookies.cookieToken);
            cookies.set(GeneralServices.cookies.cookieUser, "kekax");
            cookies.set(GeneralServices.cookies.cookieRole, GeneralServices.cookies.cookieRole);
        }
        props.loginSession({
            sessionToken: cookies.get(GeneralServices.cookies.cookieToken),
            sessionUser: cookies.get(GeneralServices.cookies.cookieUser),
            sessionRole: cookies.get(GeneralServices.cookies.cookieToken)
        }); 
    }   
    */
    //SIMULATE LOGOUT
    /*
    const { cookies } = props;
    if (cookies !== undefined) {
      cookies.remove(GeneralServices.cookies.cookieToken);
      cookies.remove(GeneralServices.cookies.cookieUser);
      cookies.remove(GeneralServices.cookies.cookieRole);
    }
    */
    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);

    }

  }, [])

  const unloadHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }, [props.history, props.location])



  useEffect(() => {
    //console.log("ola " + props.sessionToken)
    if (props.sessionToken !== "") {
      window.addEventListener('focus', doSomeJustice);
    } else {
      window.removeEventListener("focus", doSomeJustice)
    }
    /*
        window.addEventListener('blur', () => {
        //not yet
      });
      */

    return () => window.removeEventListener("focus", doSomeJustice)
  }, [props.sessionToken])



  const doSomeJustice = () => {
    verifySession();
  }

  //CHECKS IF THERE ARE ALL COOKIES IN THE BROWSER AND LOG IN - AFTER FOCUS
  const verifySession = () => {
    if (props.cookies !== undefined) {
      const cookieToken = props.cookies.get(GeneralServices.cookies.cookieToken);
      const cookieUser = props.cookies.get(GeneralServices.cookies.cookieUser);
      const cookieRole = props.cookies.get(GeneralServices.cookies.cookieRole);
      /*
      console.log(cookieToken + ' '+ cookieUser + ' '+ cookieRole)
      console.log( props.loggedIn + ' '+ props.sessionToken + ' '+ props.sessionUser + ' '+ props.sessionRole)
      */

      if (props.loggedIn && ((cookieToken === undefined || cookieUser === undefined || cookieRole === undefined) || (cookieToken !== props.sessionToken))) {

        karmaLogout();
      } else if (props.loggedIn && (cookieUser !== props.sessionUser || cookieRole !== props.sessionRole)) {


        /*YOU WANNA PLAY WITH ME - GET KARMA INSTEAD
        if (!GeneralServices.develop) {
          props.cookies.set(GeneralServices.cookies.cookieUser, props.sessionUser, GeneralServices.cookieConfig);
          props.cookies.set(GeneralServices.cookies.cookieRole, props.sessionRole, GeneralServices.cookieConfig);
        } else {
          props.cookies.set(GeneralServices.cookies.cookieUser, props.sessionUser);
          props.cookies.set(GeneralServices.cookies.cookieRole, props.sessionRole);
        }
        */
        karmaLogout();
      }


      /*
      else if (!props.loggedIn && (cookieToken !== undefined || cookieUser !== undefined || cookieRole !== undefined)) {
        props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfig);
        props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfig);
        props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfig);

        if (GeneralServices.develop) {
          props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfigDevelop);
          props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfigDevelop);
          props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfigDevelop);
        }
      }
      */

    }
  }



  const karmaLogout = () => {
    if (props.cookies !== undefined) {
      if (GeneralServices.develop) {
        props.cookies.set(GeneralServices.cookies.cookieToken, props.sessionToken, GeneralServices.cookieConfigDevelop);
        props.cookies.set(GeneralServices.cookies.cookieUser, props.sessionUser, GeneralServices.cookieConfigDevelop);
        props.cookies.set(GeneralServices.cookies.cookieRole, props.sessionRole, GeneralServices.cookieConfigDevelop);
      } else {
        props.cookies.set(GeneralServices.cookies.cookieToken, props.sessionToken, GeneralServices.cookieConfig);
        props.cookies.set(GeneralServices.cookies.cookieUser, props.sessionUser, GeneralServices.cookieConfig);
        props.cookies.set(GeneralServices.cookies.cookieRole, props.sessionRole, GeneralServices.cookieConfig);
      }


    }
    httpUserSignOutRequest().then(
      res => {
        if (props.cookies !== undefined) {
          //CAUTION TO REMOVE DUMMY COOKIES OR ELSE MAKE IT GLOBAL
          if (GeneralServices.develop) {
            props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfigDevelop);
            props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfigDevelop);
            props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfigDevelop);
          }
          /* O pedido já remove as cookies
          else {
            props.cookies.remove(GeneralServices.cookies.cookieToken, GeneralServices.cookieConfig);
            props.cookies.remove(GeneralServices.cookies.cookieUser, GeneralServices.cookieConfig);
            props.cookies.remove(GeneralServices.cookies.cookieRole, GeneralServices.cookieConfig);
          }
          */
          localStorage.clear();
          props.logoutSession({ situation: 0 });
          props.history.push(GeneralServices.homePath);
        }
      }
    );
  }

  //CHECKS IF THERE ARE ALL COOKIES IN THE BROWSER AND LOG IN - AFTER LOAD THE PAGE
  const initCheckSession = () => {
    if (props.cookies !== undefined) {
      const cookieToken = props.cookies.get(GeneralServices.cookies.cookieToken);
      const cookieUser = props.cookies.get(GeneralServices.cookies.cookieUser);
      const cookieRole = props.cookies.get(GeneralServices.cookies.cookieRole);


      if (cookieToken !== undefined && cookieUser !== undefined && cookieRole !== undefined) {

        props.loginSession({
          sessionToken: cookieToken,
          sessionUser: cookieUser,
          sessionRole: cookieRole,
        });

        initUserProfileImage();
        //MUST CHECK COOKIE
        /*
        httpUserRefreshCookiesRequest().then(
          res => {
          }
        ).catch(
          err => {
            console.log(err)
          }
        )
        */

      }
    }
  }

  const initUserProfileImage = () => {
    if (localStorage.getItem(GeneralServices.localStorage.localProfileImage)) {
      let trySrc = localStorage.getItem(GeneralServices.localStorage.localProfileImage);
      let test = new Image(); if (trySrc !== null) test.src = trySrc;
      test.onload = () => {
        props.changeUserImage({ userImage: localStorage.getItem(GeneralServices.localStorage.localProfileImage) as string })
      }
      //props.changeUserImage({userImage: localStorage.getItem(GeneralServices.localStorage.localProfileImage) as string})
    } else {
      httpGetUserUserPhotoRequest().then(
        res => {
          props.changeUserImage({
            userImage: getUserProfilePicURL
          })
          localStorage.setItem(GeneralServices.localStorage.localProfileImage, getUserProfilePicURL as string);
        }
      ).catch(
        err => {
        }
      )
    }
  }


  const initLanguage = () => {
    if (localStorage.getItem(GeneralServices.localStorage.localLang)) {
      let flag = localStorage.getItem(GeneralServices.localStorage.localLang);
      if (flag !== null)
        props.setLanguage(changeLanguage(flag))
    }

    //navigator.geolocation.getCurrentPosition(locationGetInfo, locationHandleError);
  }


  /*
  const locationGetInfo = (position: any) => {
    //console.log(position.coords.longitude)
    //console.log(position.coords.latitude)
  }

  const locationHandleError = (error: any) => {
    switch (error.code) {
      case 3:
        console.log('Expired!'); //NEVER ENTER HERE, CUZ THERE IS NO TIMEOUT
        break;
      case 2:
        console.log('You dont support geolocation, so we cant initialize your default language');
        break;
      case 1:
        console.log('You dont allow geolocation, so we cant initialize your default language');
    }
  }
*/


  /*
  getCurrentSession(){
    return {
      loggedIn: store.getState().sessionApp.loggedIn,
      cookieToken:store.getState().sessionApp.cookieToken,
      cookieUser: store.getState().sessionApp.cookieUser
    }
  }
  */

  useEffect(() => {
    if (props.sessionToken !== undefined && props.sessionToken !== "") {
      initUserProfileImage();
    }

  }, [props.sessionToken])




  /* WORKS BUT MEH
  store.dispatch(loginSession({
    cookieToken: 'ola',
    cookieUser: 'adeus'
  }))
  */

  /*
  props.loginSession({
    cookieToken: 'ola',
    cookieUser: 'adeus'
  });  
  */

  useEffect(() => {
    if (props.userSituation === 1) {
      snackKekaxRef.current.openSnackBar(props.appLanguage.profile.deleteProfileModal.deleteProfileSuccess)
    }
    //console.log(props.userSituation)
  }, [props.userSituation])

  return (
    <React.Fragment>
      <NavMain />
      <main>
        <Switch>
          <Route exact path='/' render={(props) => <Home {...props} />} />
          <Route exact path={GeneralServices.newsPath} render={(props) => <News {...props} />} />
          <Route exact path={GeneralServices.calendarEventsPath} render={(props) => <CalendarEvents {...props} />} />
          <Route exact path={GeneralServices.mineMapPath} render={(props) => <MineMap  {...props} />} />
          <Route exact path={GeneralServices.projectBackPath} render={(props) => <ProjectBackground  {...props} />} />
          <Route exact path={GeneralServices.newsLetterPath} render={(props) => <NewsLetter {...props} />} />
          <Route exact path={GeneralServices.eventPath} render={(props) => <EventPage {...props} />} />
          <Route exact path={GeneralServices.consortiumPath} render={(props) => <Consortium />} />
          <Route exact path={GeneralServices.publicationsPath} render={(props) => <Publications />} />
          <Route exact path={GeneralServices.documentsPath} render={(props) => <Documents />} />
          <Route exact path={GeneralServices.linksPath} render={(props) => <Links />} />
          <Route exact path={GeneralServices.backOfficePath} render={(props) => <BackOffice {...props} />} />
          <Route exact path={GeneralServices.gamePagePath} render={(props) => <GamePage {...props} />} />
          <Route exact path={GeneralServices.quizPagePath} render={(props) => <QuizPage {...props} />} />
          <Route exact path={GeneralServices.adminAuthPath} render={(props) => <AdminAuth {...props} />} />
          <Route exact path={GeneralServices.videosPath} render={(props) => <Videos {...props} />} />
          <Route exact path={GeneralServices.profilePath} render={(props) => <Profile {...props} />} />
          <Route exact path={GeneralServices.modelsPath} render={(props) => <Models {...props} />} />
          <Route component={NoMatch} />
        </Switch>
      </main>
      <Footer />
      <SnackKekax ref={snackKekaxRef} />

    </React.Fragment>
  );

}
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(App)));
