import React, { useEffect } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import './News.css'
import Advertise from './advertise/Advertise';
import MainNews from './mainNews/MainNews';
import FilterNews from './filterNews/FilterNews';
import { translateTodayDate } from '../calendarEvents/calendar/Calendar';

interface IProps extends StoreProps { }

export const News: React.FC<IProps> = (props) => {



    return (
        <div className="wrapper-news-page">
            <div className="container-header">
                <div className="content">
                    <span>{props.appLanguage.news.header[0]}</span>
                </div>
                <div className="today-date">
                    <span>{translateTodayDate(props.appLanguage)}</span>
                </div>
            </div>

            <div className="container-news-ads-full">
                <div className="background"> 

                </div>
                <div className="left-side">

                </div>
                <div className="container-news-ads">
                    <div className="container-news">
                        <MainNews />
                    </div>
                    <div className="container-ads">
                        <Advertise />
                    </div>
                </div >
                <div className="right-side">

                </div>
            </div>

            <div className="container-news-filter">
                <FilterNews />
            </div>
        </div>
    );

}

export default connect(mapStateToProps)(News);

/*
<span>{props.appLanguage.news.header[0]}</span>
*/