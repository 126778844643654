import { LOGOUT_SESSION, LOGIN_SESSION, SessionState, SessionActionTypes, SET_USERIMAGE } from "./types"
import defaultPic from '../../assets/images/profileclick3.png'

const initialState: SessionState = {
  loggedIn: false,
  sessionToken: "",
  sessionUser: "",
  sessionRole: "",
  userImage: defaultPic,
  situation: 0 //0 normal | 1 deleted | 2 banned
  //userImage: require('../../assets/images/profileclick3.png')
};

function sessionReducer(state = initialState, action: SessionActionTypes): SessionState {
  switch (action.type) {
    case LOGOUT_SESSION: {
        state = {
          ...state,
          ...action.data,
          loggedIn: false,
          sessionToken: "",  
          sessionUser: "",
          sessionRole: "",
          userImage: defaultPic
          //userImage: require('../../assets/images/profileclick3.png')
    };
      break;
    }
    case LOGIN_SESSION: {
      state = {
        ...state,
        ...action.data,
        loggedIn: true,
        situation: 0
      };
      break;
    }
    case SET_USERIMAGE: {
      state = {
        ...state,
        ...action.data
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};
export default sessionReducer;
