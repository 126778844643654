import React, { useEffect, useRef, useState } from 'react';
import "./EditBookletModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import StepperEditBooklet from './stepperEditBooklet/StepperEditBooklet';

import { AttachFileBooklet, IFormErrorBooklet, IFormFocusBooklet, IFormInputBooklet, IFormValidBooklet } from '../addBooklet/AddBooklet';

import { httpGetBookletDetails, httpPutBooklet } from '../../../../services/requestService';
import GeneralServices from '../../../../services/generalService.json'
import TooltipKekax from '../../../kekax/TooltipKekax';
import SnackKekax from '../../../kekax/SnackKekax';
import StepperKekax from '../../../kekax/StepperKekax';


interface IProps extends StoreProps {
    callback: any
    booklet: IEditBooklet
}

export interface IEditBooklet {
    open: boolean
    bookletId: any
    bookletName: string
}

interface SnackKekax {
    open: boolean
    message: string
}

interface IEditBookletValidation {
    main: boolean
}

const FORMTITLEID: string = 'title';


const MAX_STEPS = 0

export function EditBookletModal(props: IProps) {

    const [formSubmitValid, setFormSubmitValid] = useState<IEditBookletValidation>({ main: true });
    const [formError, setFormError] = useState<IFormErrorBooklet>({ titleError: '' });
    const [formInput, setFormInput] = useState<IFormInputBooklet>({ title: '' });
    const [formValid, setFormValid] = useState<IFormValidBooklet>({ titleValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusBooklet>({ titleFocus: false });

    const [fileStatus, setFileStatus] = useState<boolean>(false);//true-deleted | false-not deleted

    const [attachDrag, setAttachDrag] = useState<boolean>(false);

    const [attachArray, setAttachArray] = useState<AttachFileBooklet[]>([]);
    const [transferedAttach, setTransferedAttach] = useState<any[]>([]);

    let dragAttachCounter = 0;

    const snackKekaxRef: any = useRef(null);
    const attachDropRef: any = useRef(null);

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const MAX_FILES = 1

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    const loadBookletById = () => {
        const bookletId = props.booklet.bookletId





        httpGetBookletDetails(bookletId).then(
            async res => {
                setFormInput({ title: props.booklet.bookletName })
                setAttachArray([{ name: props.appLanguage.backoffice.manageBooklets.edit.fileName, content: res.data }])


            }
        ).catch(
            err => {
            }
        )
    }



    useEffect(() => {

        if (props.booklet.open && modalRef.current) {
            modalRef.current.classList.add("editBooklet-modal-open");

            loadBookletById()
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();



        } else {
            modalRef.current.classList.remove("editBooklet-modal-open");

        }

    }, [props.booklet.open])


    useEffect(() => {

        let attachListener = attachDropRef.current;
        if (attachListener !== null) {
            attachListener.addEventListener('dragenter', handleDragInAttach)
            attachListener.addEventListener('dragleave', handleDragOutAttach)
            attachListener.addEventListener('dragover', handleDragAttach)
            attachListener.addEventListener('drop', handleDropAttach)
        } else {
            attachListener.removeEventListener('dragenter', handleDragInAttach)
            attachListener.removeEventListener('dragleave', handleDragOutAttach)
            attachListener.removeEventListener('dragover', handleDragAttach)
            attachListener.removeEventListener('drop', handleDropAttach)
        }


    }, [attachDropRef])




    const handleClickAway = (event: any) => {
        /*
        if (contentRef && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editModalBooklet-topOffset", posY + "px");
    }




    /*
    const changeStepperSelectedHandler = (active: any) => {
        setSelectedStepper(active)
    }

    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }
    */



    /*MODAL SETUP UP*/



    const formEndup = () => {

        contentRef.current.reset();
        setFormSubmitValid({ main: false });
        setFormError({ titleError: '' });
        setFormInput({ title: '' })
        setFormValid({ titleValid: false })
        setFormFocus({ titleFocus: false })

        let xx = attachDropRef.current;
        if (xx !== null) {
            xx.removeEventListener('dragenter', handleDragInAttach)
            xx.removeEventListener('dragleave', handleDragOutAttach)
            xx.removeEventListener('dragover', handleDragAttach)
            xx.removeEventListener('drop', handleDropAttach)
        }

        setAttachDrag(false);
        setAttachArray([]);
        setTransferedAttach([]);
        dragAttachCounter = 0;
        setTempDisabled(0)

    }

    const handleDragAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragInAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setAttachDrag(true)
        }
    }

    const handleDragOutAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter--
        if (dragAttachCounter === 0) {
            setAttachDrag(false)
        }
    }

    const handleDropAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setAttachDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedAttach(e.dataTransfer.files)
            e.dataTransfer.clearData();
            dragAttachCounter = 0;
        }
    }


    useEffect(() => {
        if (transferedAttach.length > 0 && attachArray.length === 0) {
            let fileList = [...attachArray];
            let counter = 0;
            for (let i = 0; i < MAX_FILES; i++) {
                if (!transferedAttach[i].name) {
                    return
                }
                let ext = transferedAttach[i].type.split('/').pop();
                if (ext !== 'pdf') {
                    let systemMsg = props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[2];


                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedAttach[i].name))

                } else {
                    counter++;

                    fileList.push({ name: transferedAttach[i].name, content: transferedAttach[i] })
                    let systemMsg = props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[3];


                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))

                }

            }

            setAttachArray(fileList)
        } else if (attachArray.length > 0) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[4])

        }


    }, [transferedAttach, props.appLanguage])



    const handleDeleteAttach = (pos: number) => {
        let attachArrayDummy: AttachFileBooklet[] = [...attachArray];
        attachArrayDummy.splice(pos, 1);
        setAttachArray([...attachArrayDummy])
        setFileStatus(true)
    }


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, galleryId: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageBooklets.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageBooklets.add.titleAccept;
        }

        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])



    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.titleValid && attachArray.length > 0 });
    }, [formValid, attachArray])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end

    }
    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)
        //console.log(fileStatus)

        httpPutBooklet(
            {
                i: formInput.title
            },
            props.booklet.bookletId
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )

        closeModalHandler(GeneralServices.constants.child.closed);

    }



    const closeModalHandler = (feedback: string) => {
        formEndup()
        modalRef.current.classList.remove("editBooklet-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
        setSelectedStepper("0");
        props.callback(feedback)

    }


    let attachBox = "edit-booklet-attach "
    if (attachDrag) {
        attachBox = attachBox.concat("droppin")
    }
    const attachFunction = () => {
        if (attachArray.length > 0) {
            return <div>
                {
                    (attachArray.map((file: any, index) =>
                        <div key={index} className="booklet-attach-object">
                            <div className="booklet-attach-object-main">
                                <span className="attach-name">{file.name}</span>
                            </div>

                        </div>
                    ))
                }
            </div>
        }
        return null
    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageBooklets.add.titleAccept}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }




    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div>
                    <div className="container-edit-booklet-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageBooklets.add.information.main}</span></div>
                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageBooklets.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageBooklets.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event, null)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-edit-booklet-attach">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageBooklets.add.information.content}</span></div>

                        <div className={attachBox} ref={attachDropRef}>
                            {
                                attachFunction()
                            }

                            {
                                attachDrag ?
                                    <div className="absolute-message">
                                        <span>{props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[1]}</span>
                                    </div>
                                    : attachArray.length === 0 && !attachDrag ?
                                        <div className="absolute-message">
                                            <span>{props.appLanguage.backoffice.manageBooklets.add.dragDropAttach[0]}</span>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            )
        } else if (selectedStepper === "1") {
            return null
        } else if (selectedStepper === "2") {
            return null
        }
    }


    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="submit"><button type="submit" disabled={!formSubmitValid.main || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageBooklets.edit.submit} </button></div>
                )
            }
            default: {
                return null;
            }
        }
    }



    return (
        <React.Fragment>
            <div className="wrapper-edit-booklet" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <form className="content" ref={contentRef} id='edit-booklet-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageBooklets.edit.title}</span>
                        <div className="close"><button type="button" className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}></button></div>
                    </div>
                    <div className="body">
                        <div className="container-stepper">
                            <StepperKekax active={selectedStepper}>
                                <div key="0">{props.appLanguage.backoffice.manageBooklets.edit.steps[0]}</div>
                            </StepperKekax>
                        </div>
                        <div className="container-stepper-body">
                            <div className="container-form-edit-booklet">

                                {
                                    printActiveContent()
                                }


                            </div>
                        </div>

                    </div>
                    <div className="footer">
                        <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageBooklets.edit.cancel}</span></button></div>
                        {
                            printSubmitButton()

                        }



                    </div>
                </form>
            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}



export default connect(mapStateToProps)(EditBookletModal);

/*
                            <div className="booklet-attach-object-menu">
                                <div className="booklet-attach-object-menu-option">
                                    <TooltipKekax content={props.appLanguage.backoffice.manageBooklets.add.fileOption[0]} direction="top">
                                        <div className="kxicon" onClick={() => handleDeleteAttach(index)} />
                                    </TooltipKekax>
                                </div>
                            </div>
*/
