import React, { useEffect, useState } from 'react';
import { mapStateToProps, StoreStateProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import "./BackOffice.css"


import TabsBackOffice from './tabsBackOffice/TabsBackOffice';

//import GlobalManage from './globalManage/GlobalManage';
import AdminManage from './adminManage/AdminManage';
import NewsManage from './newsManage/NewsManage';
import EventManage from './eventManage/EventManage';
import BookletManage from './bookletManage/BookletManage';
import PublicationManage from './publicationManage/PublicationManage';
import LinkManage from './linkManage/LinkManage';
import ConsortiumManage from './consortiumManage/ConsortiumManage';
import MineManage from './mineManage/MineManage';



interface IProps extends StoreProps, RouteComponentProps<any> { }


/*
const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];
*/

export interface IAdminTabs {
  index: number
  text: string
}


export const BackOffice: React.FC<IProps> = (props) => {

  useEffect(() => {
    //props.history.push('/');
  }, [props.loggedIn, props.sessionRole])

  const NUMBEROFTABS = 5;


  const [selectedTab, setSelectedTab] = useState<string>("0") //KEKAXEDIT
  const [allTabs, setAllTabs] = useState<IAdminTabs[]>([])
  const [showTabs, setShowTabs] = useState<IAdminTabs[]>([])


  useEffect(() => {
    if (props.appLanguage) {
      let tabs: IAdminTabs[] = [
        {
          index: 0,
          text: props.appLanguage.backoffice.manageAdmins.tab
        },
        {
          index: 1,
          text: props.appLanguage.backoffice.manageNews.tab
        },
        {
          index: 2,
          text: props.appLanguage.backoffice.manageEvents.tab
        },
        {
          index: 3,
          text: props.appLanguage.backoffice.manageBooklets.tab
        },
        {
          index: 4,
          text: props.appLanguage.backoffice.managePublications.tab
        },
        {
          index: 5,
          text: props.appLanguage.backoffice.manageLinks.tab
        },
        {
          index: 6,
          text: props.appLanguage.backoffice.manageConsortiums.tab
        },
        {
          index: 7,
          text: props.appLanguage.backoffice.manageMines.tab
        }

      ]


      setAllTabs([...tabs])
      if (showTabs.length === 0) {
        setShowTabs([
          tabs[0], tabs[1], tabs[2], tabs[3], tabs[4]
        ])
      } else {
        let newShowTabs: IAdminTabs[] = []
        for (let x = 0; x < showTabs.length; x++) {
          newShowTabs.push(tabs[showTabs[x].index])
        }

        setShowTabs([...newShowTabs])
      }

    }

  }, [props.appLanguage])

  const changeTabSelectedHandler = (active: any) => {
    setSelectedTab(active)
  }

  const printActiveContent = () => {
    if (selectedTab === "0") {
      //return <GlobalManage />
      return <AdminManage />
    } else if (selectedTab === "1") {
      return <NewsManage />
    } else if (selectedTab === "2") {
      return <EventManage />
    } else if (selectedTab === "3") {
      return <BookletManage />
    } else if (selectedTab === "4") {
      return <PublicationManage />
    } else if (selectedTab === "5") {
      return <LinkManage />
    } else if (selectedTab === "6") {
      return <ConsortiumManage />
    } else if (selectedTab === "7") {
      return <MineManage />
    }
  }

  const moveLeftHandler = () => {
    const newShowTabs: IAdminTabs[] = [...showTabs]
    if (newShowTabs[0].index !== 0) {

      //setSelectedTab((data:string)=> (parseInt(data)+1).toString())

      newShowTabs.pop();
      setShowTabs([allTabs[newShowTabs[0].index - 1], ...newShowTabs])

    }
  }

  const moveRightHandler = () => {
    const newShowTabs: IAdminTabs[] = [...showTabs]
    if (newShowTabs[newShowTabs.length - 1].index !== allTabs[allTabs.length - 1].index) {

      //setSelectedTab((data:string)=> (parseInt(data)-1).toString())

      newShowTabs.splice(0, 1)
      setShowTabs([...newShowTabs, allTabs[showTabs[showTabs.length - 1].index + 1]])

    }
  }




  return (
    <div className="wrapper-page-backoffice">
      <div className="container-page-backoffice-header">
        <div className="left-side" >
          <button className="icon-left" onClick={moveLeftHandler} disabled={showTabs.length > 0 && showTabs[0].index === 0}>
          </button>
        </div>

        <div className="center-side">

          <TabsBackOffice active={selectedTab} onChange={(active: any) => changeTabSelectedHandler(active)} allTabs={allTabs}>
            {
              showTabs.map((tab: IAdminTabs, index: number) => (
                <div key={tab.index}>{tab.text}</div>
              ))
            }


          </TabsBackOffice>
        </div>
        <div className="right-side">
          <button className="icon-right" disabled={showTabs.length > 0 && showTabs[showTabs.length - 1].index === allTabs[allTabs.length - 1].index} onClick={moveRightHandler}>
          </button>
        </div>

      </div>

      <div className="container-page-backoffice-body">
        {
          printActiveContent()
        }
      </div>
    </div>
  );

}


export default connect(mapStateToProps)(BackOffice);
