import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddEvent.css';
import { httpPostEvent, httpPutEventImage, httpPutEventContent, httpPostEventGallery, httpPostEventAttach } from '../../../../services/requestService';
import { getTime, startOfDay } from "date-fns";
import dummyEvents from '../../../../assets/news/newsDelta.png'
import GeneralServices from '../../../../services/generalService.json'
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ReactMarkdown from 'react-markdown';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
import TooltipInfo from '../../newsManage/addNewsletter/tooltipInfo/TooltipInfo';


interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorEvent {
    titleError: string
    tagError: string
    descError: string
    dateStartError: string
    dateEndError: string
    contentError: string[]
}
export interface IFormInputEvent {
    title: string
    tag: string
    desc: string
    dateStart: any
    dateEnd: any
    content: string[]
}
export interface IFormValidEvent {
    titleValid: boolean
    tagValid: boolean
    descValid: boolean
    dateStartValid: boolean
    dateEndValid: boolean
    contentValid: boolean[]
}
export interface IFormFocusEvent {
    titleFocus: boolean
    tagFocus: boolean
    descFocus: boolean
    dateStartFocus: boolean
    dateEndFocus: boolean
    contentFocus: boolean[]
}

const FORMTITLEID: string = 'title';
const FORMTAGID: string = 'password';
const FORMDESCRIPTIONCID: string = 'description';
const FORMDATESTARTID: string = 'datestart';
const FORMDATEENDID: string = 'dateend';
const FORMCONTENTID: string = 'content';
const FORMLEGENDID: string = 'legend';


export interface AttachFileEvent {
    name: string
    content: any
}
export interface GalleryFileEvent {
    id: string
    name: string
    content: any
    legend: {
        text: string,
    }
    preview: any
    articleIndex: number
}


interface ITransferedGallery {
    data: any[]
    articleIndex: number
}

export const AddEvent: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);

    const [formError, setFormError] = useState<IFormErrorEvent>({ titleError: '', tagError: '', descError: '', dateStartError: '', dateEndError: '', contentError: [''] });
    const [formInput, setFormInput] = useState<IFormInputEvent>({ title: '', tag: '', desc: '', dateStart: getTime(new Date()), dateEnd: getTime(new Date()), content: [''] });
    const [formValid, setFormValid] = useState<IFormValidEvent>({ titleValid: false, tagValid: false, descValid: false, dateStartValid: false, dateEndValid: false, contentValid: [false] });
    const [formFocus, setFormFocus] = useState<IFormFocusEvent>({ titleFocus: false, tagFocus: false, descFocus: false, dateStartFocus: false, dateEndFocus: false, contentFocus: [false] });

    const [selectedImage, setSelectedImage] = useState<any>(dummyEvents);
    const [eventImage, setEventImage] = useState<any>(null);

    const [attachArray, setAttachArray] = useState<AttachFileEvent[]>([]);
    const [transferedAttach, setTransferedAttach] = useState<any[]>([]);
    const [attachDrag, setAttachDrag] = useState<boolean>(false);



    const [galleryArray, setGalleryArray] = useState<GalleryFileEvent[]>([]);
    const [transferedGallery, setTransferedGallery] = useState<ITransferedGallery>({ articleIndex: -1, data: [] });
    const [galleryDrag, setGalleryDrag] = useState<boolean[]>([false]);


    let dragAttachCounter = 0;
    let dragGalleryCounter = 0;



    const refSelectedImage: any = useRef(null);
    const attachDropRef: any = useRef(null);
    const galleryDropRef: any = useRef([]);
    const formRef = useRef<any>(null);

    const snackKekaxRef: any = useRef(null);

    const [tempDisabled, setTempDisabled] = useState<number>(0);
    useEffect(() => {
        let attachListener = attachDropRef.current;
        if (attachListener !== null) {
            attachListener.addEventListener('dragenter', handleDragInAttach)
            attachListener.addEventListener('dragleave', handleDragOutAttach)
            attachListener.addEventListener('dragover', handleDragAttach)
            attachListener.addEventListener('drop', handleDropAttach)
        }


    }, [])

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled===1)
            var timer = setTimeout(() => {setTempDisabled(0)}, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);


    const handleDragAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragInAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setAttachDrag(true)
        }
    }
    const handleDragOutAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter--
        if (dragAttachCounter === 0) {
            setAttachDrag(false)
        }
    }
    const handleDropAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setAttachDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedAttach(e.dataTransfer.files)
            e.dataTransfer.clearData();
            dragAttachCounter = 0;
        }
    }


    const handleDragGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragInGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        dragGalleryCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const dummyGalleryDrag = [...galleryDrag]
            dummyGalleryDrag[i] = true
            setGalleryDrag([...dummyGalleryDrag])
        }
    }
    const handleDragOutGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        dragGalleryCounter--
        if (dragGalleryCounter === 0) {
            const dummyGalleryDrag = [...galleryDrag]
            dummyGalleryDrag[i] = false
            setGalleryDrag([...dummyGalleryDrag])
        }
    }
    const handleDropGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        const dummyGalleryDrag = [...galleryDrag]
        dummyGalleryDrag[i] = false
        setGalleryDrag([...dummyGalleryDrag])
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedGallery({ articleIndex: i, data: e.dataTransfer.files })
            e.dataTransfer.clearData();
            dragGalleryCounter = 0;
        }
    }


    useEffect(() => {
        if (transferedAttach.length > 0) {
            let fileList: any[] = [...attachArray];
            let counter = 0;
            for (let i = 0; i < transferedAttach.length; i++) {
                if (!transferedAttach[i].name) {
                    return
                }
                let ext = transferedAttach[i].type.split('/').pop();
                if (ext !== 'pdf') {
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropAttach[2];

                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedAttach[i].name))

                } else {
                    counter++;

                    /*
                    const fileName = transferedAttach[i].name.split('.');
                    fileName.pop();
                    let kxName = '';
                    for (let i = 0; i < fileName.length; i++) {
                        kxName = kxName.concat(fileName[i])
                    }
                    */
                    fileList.push({ name: transferedAttach[i].name, content: transferedAttach[i] })
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropAttach[3];


                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))
                }

            }

            setTransferedAttach([]);
            setAttachArray([...fileList])

        }
    }, [transferedAttach, props.appLanguage])




    useEffect(() => {

        if (transferedGallery.articleIndex !== -1 && transferedGallery.data.length > 0) {
            let fileList: any[] = [...galleryArray];
            let counter = 0;
            for (let i = 0; i < transferedGallery.data.length; i++) {
                if (!transferedGallery.data[i].name) {
                    return
                }
                let ext = transferedGallery.data[i].type.split('/').pop();
                if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png') {
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropGallery[2];
                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedGallery.data[i].name))

                } else {
                    counter++;
                    let imagePreview: any = null;
                    let reader = new FileReader();
                    const promise = new Promise((resolve: any) => {
                        reader.onload = () => {
                            imagePreview = reader.result;
                            const entity: GalleryFileEvent = { id: "", name: transferedGallery.data[i].name, content: transferedGallery.data[i], legend: { text: '' }, preview: imagePreview, articleIndex: transferedGallery.articleIndex }
                            resolve(entity)
                        }
                    })
                    promise.then((result: any) => {
                        fileList.push(result)
                        if (i === transferedGallery.data.length - 1) {
                            setTransferedGallery({ articleIndex: -1, data: [] });
                            setGalleryArray([...fileList])
                        }
                    }, function (error) {
                        //console.log("error reader")
                    });

                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropGallery[3];
                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))

                    reader.readAsDataURL(transferedGallery.data[i])
                }
            }
        }

    }, [transferedGallery, props.appLanguage])

    const handleDeleteAttach = (pos: number) => {
        let attachArrayDummy: AttachFileEvent[] = [...attachArray];
        attachArrayDummy.splice(pos, 1);
        setAttachArray([...attachArrayDummy])
    }

    const handleDeleteGallery = (pos: number) => {
        let galleryArrayDummy: GalleryFileEvent[] = [...galleryArray];
        galleryArrayDummy.splice(pos, 1);
        setGalleryArray([...galleryArrayDummy])
    }

    const closeAddEventHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid(false);
        setFormError({ titleError: '', tagError: '', descError: '', dateStartError: '', dateEndError: '', contentError: [''] });
        setFormInput({ title: '', tag: '', desc: '', dateStart: getTime(new Date()), dateEnd: getTime(new Date()), content: [''] })
        setFormValid({ titleValid: false, tagValid: false, descValid: false, dateStartValid: false, dateEndValid: false, contentValid: [false] })
        setFormFocus({ titleFocus: false, tagFocus: false, descFocus: false, dateStartFocus: false, dateEndFocus: false, contentFocus: [false] })
        setSelectedImage(dummyEvents);
        setEventImage(null);
        let attachListener = attachDropRef.current;
        if (attachListener !== null) {
            attachListener.removeEventListener('dragenter', handleDragInAttach)
            attachListener.removeEventListener('dragleave', handleDragOutAttach)
            attachListener.removeEventListener('dragover', handleDragAttach)
            attachListener.removeEventListener('drop', handleDropAttach)
        }
        for (let i = 0; i < galleryDropRef.current.length; i++) {
            let galleryListener = galleryDropRef.current[i];
            if (galleryListener !== null) {
                galleryListener.removeEventListener('dragenter', handleDragInGallery)
                galleryListener.removeEventListener('dragleave', handleDragOutGallery)
                galleryListener.removeEventListener('dragover', handleDragGallery)
                galleryListener.removeEventListener('drop', handleDropGallery)
            }
        }

        setGalleryDrag([false]);
        setGalleryArray([]);
        dragGalleryCounter = 0;

        setAttachDrag(false);
        setAttachArray([]);
        setTransferedAttach([]);
        dragAttachCounter = 0;

        setTransferedGallery({ articleIndex: -1, data: [] });
        setTempDisabled(0)

        props.cbMessage(what);
    }

    const changeDateHandler = (date: Date | null, value: any, id: any) => {
        if (id === FORMDATESTARTID) {
            setFormInput({ ...formInput, dateStart: date })
        } else if (id === FORMDATEENDID) {
            setFormInput({ ...formInput, dateEnd: date })
        }
    };


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, index: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMTAGID: {
                setFormInput({ ...formInput, tag: value })
                break;
            }
            case FORMDESCRIPTIONCID: {
                setFormInput({ ...formInput, desc: value })
                break;
            }

            //deprecated here
            case FORMDATESTARTID: {
                setFormInput({ ...formInput, dateStart: value })
                break;
            }

            //deprecated here
            case FORMDATEENDID: {
                setFormInput({ ...formInput, dateEnd: value })
                break;
            }

            case FORMCONTENTID + index: {
                let contentInputAux = [...formInput.content];
                contentInputAux[index] = value;
                setFormInput({ ...formInput, content: contentInputAux })
                break;
            }
            case FORMLEGENDID + index: {
                let lmao: GalleryFileEvent[] = [...galleryArray]
                lmao[index].legend.text = value
                setGalleryArray(lmao)
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;
        let upd_tagValid = formValid.tagValid;
        let upd_descValid = formValid.descValid;
        let upd_dateStartValid = formValid.dateStartValid;
        let upd_dateEndValid = formValid.dateEndValid;
        let upd_contentValid = [...formValid.contentValid];


        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageEvents.add.titleAccept;
        }

        /*TAG VALIDATION*/
        if (formInput.tag === '') {
            upd_tagValid = false;
            fieldValidationErrors.tagError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_tagValid = true;
            fieldValidationErrors.tagError = props.appLanguage.backoffice.manageEvents.add.tagAccept;
        }


        /*DESCRIPTION VALIDATION*/
        /*
        if (formInput.desc === '') {
            upd_descValid = false;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_descValid = true;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageEvents.add.descAccept;
        }
        */



        /*DATE VALIDATION*/
        //const now = new Date();
        //const nowDate = startOfDay(now);
        const startDate = startOfDay(formInput.dateStart);
        const endDate = startOfDay(formInput.dateEnd);


        /*DATE START VALIDATION*/
        upd_dateStartValid = true;
        fieldValidationErrors.dateStartError = props.appLanguage.backoffice.manageEvents.add.dateAccept;

        /*DATE END VALIDATION*/
        if (endDate < startDate) {
            upd_dateEndValid = false;
            fieldValidationErrors.dateEndError = props.appLanguage.backoffice.manageEvents.add.dateError;
        } else {
            upd_dateEndValid = true;
            fieldValidationErrors.dateEndError = props.appLanguage.backoffice.manageEvents.add.dateAccept;
        }




        /*CONTENT VALIDATION*/
        for (let i = 0; i < formInput.content.length; i++) {
            if (formInput.content[i] === '') {
                upd_contentValid[i] = false;
                fieldValidationErrors.contentError[i] = props.appLanguage.backoffice.manageEvents.add.requiredError;
            } else {
                upd_contentValid[i] = true;
                fieldValidationErrors.contentError[i] = props.appLanguage.backoffice.manageEvents.add.contentAccept;
            }
        }


        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid, tagValid: upd_tagValid, descValid: upd_descValid, dateStartValid: upd_dateStartValid, dateEndValid: upd_dateEndValid, contentValid: upd_contentValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        let contentValidation = true;
        for (let i = 0; i < formValid.contentValid.length; i++) {
            if (!formValid.contentValid[i]) {
                contentValidation = false;
                break;
            }
        }
        setFormSubmitValid(formValid.titleValid && formValid.tagValid
            && formValid.dateStartValid && formValid.dateEndValid && contentValidation)

    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, index: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMTAGID: {
                setFormFocus({ ...formFocus, tagFocus: true })
                break;
            }
            case FORMDESCRIPTIONCID: {
                setFormFocus({ ...formFocus, descFocus: true })
                break;
            }
            case FORMDATESTARTID: {
                setFormFocus({ ...formFocus, dateStartFocus: true })
                break;
            }
            case FORMDATEENDID: {
                setFormFocus({ ...formFocus, dateEndFocus: true })
                break;
            }
            case FORMCONTENTID + index: {
                let contentFocusAux = [...formFocus.contentFocus];
                contentFocusAux[index] = true;
                setFormFocus({ ...formFocus, contentFocus: contentFocusAux })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);

        /*
        console.log("title " + formInput.title)
        console.log("tag " + formInput.tag)
        console.log("importance " + formInput.importance)
        console.log("desc " + formInput.desc)
        console.log("dateStart " + getTime(formInput.dateStart))
        console.log("dateEnd " + getTime(formInput.dateEnd))

        let orderCounter = 0;

        if (eventImage !== null) {
            console.log("image name" + eventImage.name)
        }


        if (formInput.content.length > 0) {
            for (let i = 0; i < formInput.content.length; i++) {
                console.log('content ' + formInput.content[i])
                console.log("content index " + i)
                console.log('content count ' + orderCounter)

                if (galleryArray.length > 0) {
                    for (let x = 0; x < galleryArray.length; x++) {
                        if (galleryArray[x].articleIndex === i) {//se pertencer ao content atual
                            orderCounter++
                            console.log("gallery name " + galleryArray[x].name)
                            console.log("gallery index" + galleryArray[x].articleIndex)
                            console.log("gallery count " + orderCounter)
                            console.log("gallery legend " + galleryArray[x].legend.text)

                        }
                    }
                }
                orderCounter++
            }
        }

        if (attachArray.length > 0) {
            for (let i = 0; i < attachArray.length; i++) {
                console.log("attach name" + attachArray[i].name)
            }
        }
        */
        
        httpPostEvent({
            title: formInput.title,
            tag: formInput.tag,
            desc: formInput.desc,
            date: getTime(formInput.dateStart),
            dateEnd: getTime(formInput.dateEnd)

        }).then(
            async res => {

                let orderCounter = 0;
                const eventID = res.data.id;

                if (eventImage !== null) {
                    let fileExt = eventImage.name.split('.').pop();
                    await httpPutEventImage({ img: eventImage, ext: fileExt }, eventID).then(
                        res => {
                        }
                    )
                }


                if (formInput.content.length > 0) {
                    for (let i = 0; i < formInput.content.length; i++) {
                        //console.log("content " + orderCounter)
                        await httpPutEventContent({ content: formInput.content[i] }, eventID, orderCounter.toString()).then(
                            async res => {
                                orderCounter++
                                if (galleryArray.length > 0) {
                                    for (let x = 0; x < galleryArray.length; x++) {
                                        //console.log("image " + orderCounter)
                                        if (galleryArray[x].articleIndex === i) {//se pertencer ao content atual
                                            let fileExt = galleryArray[x].name.split('.').pop();
                                            await httpPostEventGallery({ img: galleryArray[x].content, ext: fileExt, desc: galleryArray[x].legend.text }, eventID, orderCounter.toString()).then(
                                                res => {
                                                    orderCounter++
                                                    console.log("mama mia")
                                                }
                                            )
                                        }
                                    }

                                }
                            }
                        )
                    }
                }


                if (attachArray.length > 0) {
                    for (let i = 0; i < attachArray.length; i++) {

                        await httpPostEventAttach({ content: attachArray[i].content, name: attachArray[i].name }, eventID).then(
                            res => {
                            }
                        )

                    }

                }
                closeAddEventHandler(GeneralServices.constants.child.worked);

            }
        ).catch(
            err => {
                closeAddEventHandler(GeneralServices.constants.child.failed);
            }
        )
        /*closeAddEventHandler(GeneralServices.constants.child.closed);*/
    }

    const onClickFile = () => {
        refSelectedImage.current.click();
    }

    const onFileSelected = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.files[0]) {
            setEventImage(event.target.files[0])
            let reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }


    const galleryFunction = (articleIndex: number) => {
        if (galleryArray.length > 0) {
            return <div>
                {
                    (galleryArray.map((file: GalleryFileEvent, index: number) =>
                        file.articleIndex === articleIndex ?
                            <div key={index} className="article-gallery-object">
                                <div className="article-gallery-object-main">

                                    <div className="article-gallery-object-main-preview">
                                        <img className="image" src={file.preview} alt={'eventGalleryAdd' + index}></img>
                                    </div>

                                    <div className="article-gallery-object-main-text">
                                        <span>{file.name}</span>
                                        <textarea className="text-legend" id={FORMLEGENDID + index} placeholder={props.appLanguage.backoffice.manageEvents.add.legendph}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            maxLength={100}
                                            value={file.legend.text || ""}

                                        />
                                    </div>

                                </div>

                                <div className="article-gallery-object-menu">
                                    <div className="article-gallery-object-menu-option">
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.fileOption[1]} direction="top">
                                            <div className="kxicon" onClick={() => handleDeleteGallery(index)} />
                                        </TooltipKekax>
                                    </div>
                                </div>
                            </div>
                            : null
                    ))
                }
            </div>
        }
        return null
    }

    let attachBox = "add-event-attach "
    if (attachDrag) {
        attachBox = attachBox.concat("droppin")
    }
    const attachFunction = () => {
        if (attachArray.length > 0) {
            return <div>
                {
                    (attachArray.map((file: any, index) =>
                        <div key={index} className="event-attach-object">
                            <div className="event-attach-object-main">
                                <span className="attach-name">{file.name}</span>
                            </div>
                            <div className="event-attach-object-menu">
                                <div className="event-attach-object-menu-option">
                                    <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.fileOption[2]} direction="top">
                                        <div className="kxicon" onClick={() => handleDeleteAttach(index)} />
                                    </TooltipKekax>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        }
        return null
    }



    const validatorFeedback = (formId: any, index: any) => {
        let searchKey = formId;
        if (index !== -1) {
            searchKey = formId + index;
        }
        switch (searchKey) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageEvents.add.titleAccept}</span>
                        : null
            }
            case FORMTAGID: {
                return formFocus.tagFocus && !formValid.tagValid ?
                    <span className="input-invalid-feedback-kekax">{formError.tagError}</span>
                    : formValid.tagValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageEvents.add.tagAccept}</span>
                        : null
            }
            case FORMDESCRIPTIONCID: {
                return formFocus.descFocus && !formValid.descValid ?
                    <span className="input-invalid-feedback-kekax">{formError.descError}</span>
                    : formValid.descValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageEvents.add.descAccept}</span>
                        : null
            }
            case FORMDATESTARTID: {
                return !formValid.dateStartValid ?
                    <span className="input-invalid-feedback-kekax">{formError.dateStartError}</span>
                    : formValid.dateStartValid ?
                        <span className="input-valid-feedback-kekax">{formError.dateStartError}</span>
                        : null
            }
            case FORMDATEENDID: {
                return !formValid.dateEndValid ?
                    <span className="input-invalid-feedback-kekax">{formError.dateEndError}</span>
                    : formValid.dateEndValid ?
                        <span className="input-valid-feedback-kekax">{formError.dateEndError}</span>
                        : null
            }
            case FORMCONTENTID + index: {
                return formFocus.contentFocus[index] && !formValid.contentValid[index] ?
                    <span className="input-invalid-feedback-kekax">{formError.contentError[index]}</span>
                    : formValid.contentValid[index] ?
                        <span className="input-valid-feedback-kekax">{formError.contentError[index]}</span>
                        : null
            }


            default: {
                break;
            }
            /*
            default: {
                if (formId.includes(FORMCONTENTID)) {
                    const index: number = parseInt(formId.replace(FORMCONTENTID, ""))
                    return formFocus.contentFocus[index] && !formValid.contentValid[index] ?
                        <span className="input-invalid-feedback-kekax">{formError.contentError[index]}</span>
                        : formValid.contentValid[index] ?
                            <span className="input-valid-feedback-kekax">{formError.contentError[index]}</span>
                            : null
                }
                break;
            }
            */
        }//switch end
    }

    const handlerCreateArticle = (index: any, where: number) => {
        //where 0-below 1-above


        const numberOfArticles = formInput.content.length;
        let ilegal = false;
        for (let i = 0; i < numberOfArticles; i++) {
            if (!formValid.contentValid[i]) {
                ilegal = true;
                break;
            }
        }

        if (!ilegal) {
            let dummyError = []
            let dummyInput = []
            let dummyValid = []
            let dummyFocus = []


            if (where === 0) {
                for (let i = 0; i < numberOfArticles; i++) {
                    if (i === index + 1) {
                        dummyError.push("")
                        dummyInput.push("")
                        dummyValid.push(false)
                        dummyFocus.push(false)
                    }
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                }

                if (index + 1 === numberOfArticles) {
                    dummyError.push("")
                    dummyInput.push("")
                    dummyValid.push(false)
                    dummyFocus.push(false)
                } else {
                    addSlotArticleImages(index + 1, where)
                }

            } else if (where === 1) {

                if (index - 1 === -1) {
                    dummyError.push("")
                    dummyInput.push("")
                    dummyValid.push(false)
                    dummyFocus.push(false)
                }
                for (let i = 0; i < numberOfArticles; i++) {
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                    if (i === index - 1) {
                        dummyError.push("")
                        dummyInput.push("")
                        dummyValid.push(false)
                        dummyFocus.push(false)
                    }
                }

                addSlotArticleImages(index - 1, where)

            }




            setFormError({ ...formError, contentError: dummyError })
            setFormInput({ ...formInput, content: dummyInput })
            setFormValid({ ...formValid, contentValid: dummyValid })
            setFormFocus({ ...formFocus, contentFocus: dummyFocus })

            rebootGalleryDropRef()



            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.yesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.cantAdd)

        }
    }


    const handlerDeleteArticle = (index: any) => {

        if (formInput.content.length > 1) {
            let dummyError = []
            let dummyInput = []
            let dummyValid = []
            let dummyFocus = []

            const numberOfArticles = formInput.content.length;

            for (let i = 0; i < numberOfArticles; i++) {
                if (i !== index) {
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                }
            }
            setFormError({ ...formError, contentError: dummyError })
            setFormInput({ ...formInput, content: dummyInput })
            setFormValid({ ...formValid, contentValid: dummyValid })
            setFormFocus({ ...formFocus, contentFocus: dummyFocus })
            removeArticleImages(index)
            rebootGalleryDropRef()
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.yesRmv)
        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.cantRmv)
        }
    }



    const checkArticleHasImages = (index: number) => {
        for (let i = 0; i < galleryArray.length; i++) {
            if (galleryArray[i].articleIndex === index) {
                return true;
            }
        }
        return false;
    }
    const removeArticleImages = (index: number) => {
        let dummyGalleryArray: GalleryFileEvent[] = []
        for (let i = 0; i < galleryArray.length; i++) {
            if (galleryArray[i].articleIndex > index) {
                dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex - 1 })
            } else if (galleryArray[i].articleIndex < index) {
                dummyGalleryArray.push({ ...galleryArray[i] })
            }
        }
        setGalleryArray([...dummyGalleryArray])
    }

    const addSlotArticleImages = (index: number, where: number) => {
        let dummyGalleryArray: GalleryFileEvent[] = []
        for (let i = 0; i < galleryArray.length; i++) {
            if (where === 0) {
                if (galleryArray[i].articleIndex >= index) {
                    dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex + 1 })
                } else if (galleryArray[i].articleIndex < index) {
                    dummyGalleryArray.push({ ...galleryArray[i] })
                }
            } else if (where === 1) {
                if (galleryArray[i].articleIndex > index) {
                    dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex + 1 })
                } else if (galleryArray[i].articleIndex <= index) {
                    dummyGalleryArray.push({ ...galleryArray[i] })
                }
            }

        }
        setGalleryArray([...dummyGalleryArray])
    }


    const galleryBoxFunction = (index: number) => {
        let galleryBox = "add-event-article-gallery "
        if (galleryDrag[index]) {
            galleryBox = galleryBox.concat("droppin")
        }
        return galleryBox;
    }


    //Clear all listeners of each article
    const rebootGalleryDropRef = () => {
        for (let i = 0; i < galleryDropRef.current.length; i++) {
            let galleryListener = galleryDropRef.current[i];
            if (galleryListener !== null) {
                galleryListener.removeEventListener('dragenter', handleDragInGallery)
                galleryListener.removeEventListener('dragleave', handleDragOutGallery)
                galleryListener.removeEventListener('dragover', handleDragGallery)
                galleryListener.removeEventListener('drop', handleDropGallery)
            }
        }
        galleryDropRef.current = []
    }

    //Re-insert all listeners to each article
    useEffect(() => {
        if (galleryDropRef.current.length > 0) {
            for (let i = 0; i < galleryDropRef.current.length; i++) {
                let galleryListener = galleryDropRef.current[i];
                if (galleryListener !== null) {
                    galleryListener.addEventListener('dragenter', (event: any) => handleDragInGallery(event, i))
                    galleryListener.addEventListener('dragleave', (event: any) => handleDragOutGallery(event, i))
                    galleryListener.addEventListener('dragover', (event: any) => handleDragGallery(event, i))
                    galleryListener.addEventListener('drop', (event: any) => handleDropGallery(event, i))
                }
            }
        }
    }, [galleryDropRef.current])


    const removePhotoHandler = () => {
        if (eventImage !== null) {
            setEventImage(null)
            setSelectedImage(dummyEvents)
        }
    }



    return (
        <React.Fragment>
            <div className="wrapper-add-event">
                <form ref={formRef} id='add-event-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-add-event-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageEvents.add.information.main}</span></div>

                        <div className="main-row">
                            <div className="input-calendar">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageEvents.add.dateStart}
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="input-calendar-datepicker"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id={FORMDATESTARTID}
                                        value={formInput.dateStart || ""}
                                        onChange={(date: any, value: any) => changeDateHandler(date, value, FORMDATESTARTID)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage={props.appLanguage.backoffice.manageEvents.add.dateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDATESTARTID, -1)
                                    }
                                </div>
                            </div>

                            <div className="input-calendar">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageEvents.add.dateEnd}
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="input-calendar-datepicker"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id={FORMDATEENDID}
                                        value={formInput.dateEnd || ""}
                                        onChange={(date: any, value: any) => changeDateHandler(date, value, FORMDATEENDID)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage={props.appLanguage.backoffice.manageEvents.add.dateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDATEENDID, -1)
                                    }
                                </div>
                            </div>

                            <div className="input-tag">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageEvents.add.tag} *
                                </label>
                                <select className="input-tag-select kekax-input-box"
                                    id={FORMTAGID}
                                    onChange={(event: any) => changeInputHandler(event, null)}
                                    onFocus={(event: any) => validateFocusFields(event, null)}
                                    value={formInput.tag || ""}
                                >
                                    <option hidden>{props.appLanguage.backoffice.manageEvents.add.tagph}</option>
                                    <option value={GeneralServices.event.tag.general}>{props.appLanguage.event.tag[0]}</option>
                                </select>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTAGID, -1)
                                    }
                                </div>
                            </div>
                        </div>


                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageEvents.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageEvents.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event, null)}
                                    onFocus={(event: any) => validateFocusFields(event, null)}
                                    value={formInput.title || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID, -1)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-description">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageEvents.add.desc}

                                </label>
                                <input className="input-description-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageEvents.add.descph}
                                    id={FORMDESCRIPTIONCID}
                                    onChange={(event: any) => changeInputHandler(event, null)}
                                    onFocus={(event: any) => validateFocusFields(event, null)}
                                    value={formInput.desc || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDESCRIPTIONCID, -1)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="add-event-image">
                            <div className="add-event-image-preview">
                                <img className="image" src={selectedImage} alt='eventImgAdd'></img>
                                <div className="image-options" >
                                    <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.imageOptions[0]} direction="right">
                                        <button type="button" className="image-change-fab" onClick={onClickFile}><div className="change" /></button >
                                    </TooltipKekax>
                                    <input ref={refSelectedImage} hidden type="file" accept='image/*' onChange={onFileSelected} />
                                    <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.imageOptions[1]} direction="right">
                                        <button type="button" className={eventImage !== null ? "image-delete-fab" : "image-delete-fab image-hidden-fab"} onClick={removePhotoHandler}><div className="delete" /></button >
                                    </TooltipKekax>
                                </div>
                            </div>


                            <div className="add-event-image-content">
                                <span className="text-one">{props.appLanguage.backoffice.manageEvents.add.imageph[0]}</span>
                                <span className="text-two">{props.appLanguage.backoffice.manageEvents.add.imageph[1]}</span>

                            </div>
                        </div>
                    </div>

                    {
                        formInput.content.map((content: any, index: number) => (
                            <div className="container-add-event-article" key={"content" + index}>
                                <div className="container-title">
                                    <div className="title"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.title}</span></div>
                                    <div className="options">
                                        <TooltipInfo content={props.appLanguage.backoffice.manageEvents.add.information.article.info} direction="top">
                                            <div className="opt-info">
                                                <div className="icon"></div>
                                            </div>
                                        </TooltipInfo>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.addDown} direction="top">
                                            <div className="opt-addDown" onClick={() => handlerCreateArticle(index, 0)}>
                                                <div className="icon"></div>
                                            </div>
                                        </TooltipKekax>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.addUp} direction="top">
                                            <div className="opt-addUp" onClick={() => handlerCreateArticle(index, 1)}>
                                                <div className="icon"></div>
                                            </div>
                                        </TooltipKekax>
                                        {
                                            formInput.content.length > 1 ?
                                                <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.delete} direction="top">
                                                    <div className="opt-rmv" onClick={() => handlerDeleteArticle(index)}>
                                                        <div className="icon"></div>
                                                    </div>
                                                </TooltipKekax>
                                                : null
                                        }

                                    </div>
                                </div>
                                <span className="label">{props.appLanguage.backoffice.manageEvents.add.content} *

                                </span>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMCONTENTID, index)
                                    }
                                </div>

                                <div className="add-event-article-label">
                                    <div className="side-label"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.input}</span></div>
                                    <div className="side-label"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.preview}</span></div>
                                </div>
                                <div className="add-event-article-text">
                                    <div className="add-event-article-text-input">
                                        <textarea required className="article-text" id={FORMCONTENTID + index} placeholder={props.appLanguage.backoffice.manageEvents.add.contentph}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={content || ""}
                                        />
                                    </div>
                                    <div className="add-event-article-text-preview">
                                        <ReactMarkdown className="article-preview" source={content + ""} escapeHtml={false} />
                                    </div>
                                </div>

                                <div className={galleryBoxFunction(index)} ref={el => galleryDropRef.current[index] = el}
                                >
                                    {
                                        galleryFunction(index)
                                    }
                                    {
                                        galleryDrag[index] ?
                                            <div className="absolute-message">
                                                <span>{props.appLanguage.backoffice.manageEvents.add.dragDropGallery[1]}</span>
                                            </div>
                                            : !checkArticleHasImages(index) && !galleryDrag[index] ?
                                                <div className="absolute-message">
                                                    <span>{props.appLanguage.backoffice.manageEvents.add.dragDropGallery[0]}</span>
                                                </div>
                                                : null
                                    }
                                </div>

                            </div>
                        ))
                    }



                    <div className="container-add-event-attach">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageEvents.add.information.attachments}</span></div>
                        <div className={attachBox} ref={attachDropRef}>
                            {
                                attachFunction()
                            }

                            {
                                attachDrag ?
                                    <div className="absolute-message">
                                        <span>{props.appLanguage.backoffice.manageEvents.add.dragDropAttach[1]}</span>
                                    </div>
                                    : attachArray.length === 0 && !attachDrag ?
                                        <div className="absolute-message">
                                            <span>{props.appLanguage.backoffice.manageEvents.add.dragDropAttach[0]}</span>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>


                    <div className="container-form-bottom">
                        <div className="submit"><button type="submit" className="submit-btn" disabled={!formSubmitValid || tempDisabled === 1}><span className="info">{props.appLanguage.backoffice.manageEvents.add.submit}</span></button></div>
                    </div>
                </form>

            </div>

            <SnackKekax ref={snackKekaxRef} />



        </React.Fragment>
    );
}

export default connect(mapStateToProps)(AddEvent);