import React, { useEffect, useState, useRef } from "react";

import Avatar from "../../../assets/icons/pickicon.png";
import { connect } from "react-redux";

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import './MoreInfoApp.css'
interface IProps extends StoreProps {
    myRef: any;
    triggerAnime: any;
}

export const MoreInfoApp: React.FC<IProps> = (props) => {


    const [objectiveArray, setOjectiveArray] = useState<string[]>([]);

    const myref = useRef<any>(null);

    useEffect(() => {
        props.myRef(myref.current);
    }, [])

    useEffect(() => {
        const auxObjectiveArray: string[] = [
            props.appLanguage.objectives[1],
            props.appLanguage.objectives[2],
            props.appLanguage.objectives[3],
            props.appLanguage.objectives[4],
            props.appLanguage.objectives[5],
            props.appLanguage.objectives[6],
            props.appLanguage.objectives[7],
            props.appLanguage.objectives[8],
        ]

        setOjectiveArray(auxObjectiveArray);

    }, [props.appLanguage])

    return (
        <div className="wrapper-moreinfoapp" ref={myref}>
            <div className="container-moreinfoapp">
                <div className="container-moreinfoapp-intro"><span>{props.appLanguage.objectives.title}</span></div>
                <div className="container-moreinfoapp-desc"><span>{props.appLanguage.objectives.description}</span> </div>

                <div className="container-moreinfoapp-list">
                    {
                        objectiveArray.map((text: string, index: number) => (
                            <div className="moreinfoapp-list-object" key={index}>
                                <img src={Avatar} alt="dot-pick" className="imavatar" />
                                <div className="moreinfoapp-list-object-content">
                                    <span>{text}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );

}

export default connect(mapStateToProps)(MoreInfoApp)


/*
        <div className="wrapper-moreinfoapp" ref={myref}>
            {props.triggerAnime ?
                <motion.div
                    className="container-moreinfoapp"
                    initial={
                        {
                            x: 0,
                            y: 400,
                            opacity: 0
                        }
                    }
                    animate={
                        {
                            x: 0,
                            y: 0,
                            opacity: 1
                        }
                    }
                    transition={{
                        duration: 1.5,
                    }}>                    <div className="container-moreinfoapp-intro">
                        <span className="moreinfoapp-intro-title">{props.appLanguage.objectives.title}</span>
                        <span className="moreinfoapp-intro-desc">{props.appLanguage.objectives.description}</span>
                    </div>


                    <div className="container-moreinfoapp-list">
                        {
                            objectiveArray.map((text: string, index: number) => (
                                <div className="moreinfoapp-list-object" key={index}>
                                    <img src={Avatar} alt="dot-pick" className="imavatar" />
                                    <div className="moreinfoapp-list-object-content">
                                        <span>{text}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </motion.div>

                : null
            }
        </div>
*/