import * as React from 'react'
import './TabsCalendarEvents.css';

import { useRef, useEffect, useState } from 'react';

interface IProps  {
    onChange?: any
    active?: any
    children?: any
}



export const CalendarEvent: React.FC<IProps> = (props) => {

    const getUnderlineStyle = () => {
        if (props.active == null || Object.keys(sizes).length === 0) {
            return { left: '0', right: '100%' }
        }

        const size = sizes[props.active]

        return { left: size.left + 'px', right: size.right + 'px' }
    }

    const tabsRef: any = React.useRef();
    const tabsEachRef = useRef(new Array(props.children.length));


    const [sizes, setSizes] = useState<any>({})

    useEffect(() => {
        window.addEventListener('resize', getSizes)
    }, [])

    useEffect(() => {
        getSizes();
    }, [props.children, props.active])

    const getSizes = () => {
        if (tabsRef.current != null) {
            const rootBounds = tabsRef.current.getBoundingClientRect();
            const sizes: any = {}

            tabsEachRef.current.forEach((pos, i) => {

                const bounds = pos.getBoundingClientRect();
                const left = bounds.left - rootBounds.left;
                const right = rootBounds.right - bounds.right;

                sizes[i] = { left, right }

            })

            setSizes(sizes)
            return sizes;
        } else {
            return null;
        }
    }


    return (
        <div className="wrapper-tabs-calendarEvents">

            <div className="container-tabs"
                ref={tabsRef}
            >

                {React.Children.map(props.children, (child: any, i: any) => {

                    let myClassName = "tabs-normal"
                    if (child.key === props.active) {
                        myClassName = myClassName + " tabs-active"
                    }

                    return (
                        <div className={myClassName}
                            onClick={() => props.onChange(child.key)}
                            ref={el => tabsEachRef.current[i] = el}

                        >
                            {child}
                        </div>

                    )
                })}

                <div className="tabs-underline" style={getUnderlineStyle()}></div>
            </div>
        </div >
    );

}

export default CalendarEvent;
