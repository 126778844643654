import React, { useState, useEffect, useRef } from 'react'
import "./AdminManage.css"
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import RegisterModal from './registerModal/RegisterModal';
import SnackKekax from '../../kekax/SnackKekax';
import GeneralServices from '../../../services/generalService.json'
import FireStaffModal from './fireStaffModal/FireStaffModal';


interface IProps extends StoreProps {

}
export const AdminManage: React.FC<IProps> = (props) => {


    const [openHire, setOpenHire] = useState<boolean>(false);
    const [openFire, setOpenFire] = useState<boolean>(false);

    const snackKekaxRef: any = useRef();

    useEffect(() => {
    }, [])



    const callbackHireFunction = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenHire(false);
            snackKekaxRef.current.openSnackBar(props.appLanguage.register.registerSuccess)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.register.registerFailed)
            setOpenHire(false);
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenHire(false);
        } else {
            setOpenHire((val)=>!val);
        }
    }


    const callbackFireFunction = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenFire(false);
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageAdmins.fire.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageAdmins.fire.error)
            setOpenFire(false);
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenFire(false);
        } else {
            setOpenFire((val)=>!val);
        }
    }

    const handleClickHire = () => {
        openHire ? setOpenHire(false) : setOpenHire(true);
    }

    const handleClickFire = () => {
        openFire ? setOpenFire(false) : setOpenFire(true);
    }


    return (
        <React.Fragment>
            <div className="wrapper-bo-manage-admin">
                <div className="container-display">
                    <div className="display-option" onClick={handleClickHire}>
                        <div className="opt-icon icon-hire"></div>
                        <div className="opt-text"><span>{props.appLanguage.backoffice.manageAdmins.hire.opt}</span></div>
                    </div>
                    <div className="display-option" onClick={handleClickFire}>
                        <div className="opt-icon icon-fire"></div>
                        <div className="opt-text"><span>{props.appLanguage.backoffice.manageAdmins.fire.opt}</span></div>
                    </div>
                </div>

            </div>

            <RegisterModal open={openHire} callback={callbackHireFunction} />
            <FireStaffModal open={openFire} callback={callbackFireFunction} />

            <SnackKekax ref={snackKekaxRef} />
        </React.Fragment>
    );

}


export default connect(mapStateToProps)(AdminManage);
