import { LanguageJson } from "../../services/languages/langTypes";

// Describing the shape of the system's slice of state
export const SET_LANGUAGE = "SET_LANGUAGE";
interface SetLanguageAction {
    type: typeof SET_LANGUAGE;
    data: LanguageJson;
}


export type SetLanguageTypes = SetLanguageAction;
