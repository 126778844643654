import * as React from 'react'
import './TabsBackOffice.css';

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import { IAdminTabs } from '../BackOffice';

interface IProps extends StoreProps {
    onChange?: any
    active?: any
    children?: any
    allTabs?: IAdminTabs[]
}



export const TabsBackOffice: React.FC<IProps> = (props) => {

    const getUnderlineStyle = () => {
        if (sizes[props.active] === null || sizes[props.active] === undefined ||props.active === null || Object.keys(sizes).length === 0) {
            
            return { left: '-10%', right: '200%' }
        }

        const size = sizes[props.active]

        return { left: size.left + 'px', right: size.right + 'px' }
    }

    const tabsRef: any = React.useRef();
    const tabsEachRef = useRef(new Array(7));


    const [sizes, setSizes] = useState<any>({})

    useEffect(() => {
        window.addEventListener('resize', getSizes)
    }, [])

    useEffect(() => {
        getSizes();

    }, [props.children, props.active])

    useEffect(() => {
        getSizes();
    }, [props.active])

    const getSizes = () => {
        if (tabsRef.current != null && parseInt(props.active) >-1) {
            const rootBounds = tabsRef.current.getBoundingClientRect();
            const sizes: any = {}

            tabsEachRef.current.forEach((pos, i) => {   
                if(pos !==null){
                    const bounds = pos.getBoundingClientRect();
                    const left = bounds.left - rootBounds.left;
                    const right = rootBounds.right - bounds.right;
    
                    sizes[i] = { left, right }
                }else{
                    sizes[i] = null
                }


            })

            setSizes(sizes)
            return sizes;


        } else {
            return null;
        }
    }


    return (
        <div className="wrapper-tabs-backoffice">
            <div className="container-tabs"
                ref={tabsRef}
            >

                {React.Children.map(props.children, (child: any, i: any) => {

                    let myClassName = "tabs-normal"
                    if (child.key === props.active) {
                        myClassName = myClassName + " tabs-active"
                    }

                    return (
                        <div className={myClassName}
                            onClick={() => props.onChange(child.key)}
                            ref={el => tabsEachRef.current[child.key] = el}

                        >
                            {child}
                        </div>

                    )
                })}
                <div className="tabs-underline" style={getUnderlineStyle()}></div>
            </div>

        </div >
    );

}

export default connect(mapStateToProps)(TabsBackOffice);
