import React, { useState, useEffect, useRef } from 'react'
import './RegisterModal.css';
import visibility from "../../../../assets/figma/visibility.svg";
import visibility_off from "../../../../assets/figma/visibility_off.svg";
import { httpUserSignUpRequest } from '../../../../services/requestService';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import GeneralServices from '../../../../services/generalService.json'
import SnackKekax from '../../../kekax/SnackKekax';


interface IProps extends StoreProps {
    open: boolean
    callback: any
}

interface IFormError {
    firstName: string
    lastName: string
    username: string
    email: string
    password: string
    cpassword: string
    role: string
    country: string

}

interface IFormInput {
    firstName: string
    lastName: string
    username: string
    email: string
    password: string
    cpassword: string
    role: string
    country: string

}
interface IFormValid {
    firstNameValid: boolean
    lastNameValid: boolean
    usernameValid: boolean
    emailValid: boolean
    passwordValid: boolean
    cpasswordValid: boolean
    roleValid: boolean
    countryValid: boolean

}

interface IFormFocus {
    firstNameFocus: boolean
    lastNameFocus: boolean
    usernameFocus: boolean
    emailFocus: boolean
    passwordFocus: boolean
    cpasswordFocus: boolean
    roleFocus: boolean
    countryFocus: boolean

}

interface FlagOption {
    id: number
    tag: string
    shortNameENG: string
    shortNameNOL: string
    officialName: string
    flag: any
}

export interface IShowPassword {
    type: string
    icon: any
}

const FORMFIRSTNAMEID: string = 'firstName';
const FORMLASTNAMEID: string = 'lastName';
const FORMUSERNAMEID: string = 'username';
const FORMEMAILID: string = 'email';
const FORMPASSWORDID: string = 'password';
const FORMCPASSWORDID: string = 'cpassword';
const FORMROLEID: string = 'role';
const FORMCOUNTRYID: string = 'country';


export const RegisterModal: React.FC<IProps> = (props) => {

    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<IShowPassword>({ type: 'password', icon: visibility_off })
    const [showCPassword, setShowCPassword] = useState<IShowPassword>({ type: 'password', icon: visibility_off })

    const [formError, setFormError] = useState<IFormError>({ firstName: '', lastName: '', username: '', email: '', password: '', cpassword: '', role: '', country: '' });
    const [formInput, setFormInput] = useState<IFormInput>({ firstName: '', lastName: '', username: '', email: '', password: '', cpassword: '', role: GeneralServices.roles.manager, country: '' });
    const [formValid, setFormValid] = useState<IFormValid>({ firstNameValid: false, lastNameValid: false, usernameValid: false, emailValid: false, passwordValid: false, cpasswordValid: false, roleValid: true, countryValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocus>({ firstNameFocus: false, lastNameFocus: false, usernameFocus: false, emailFocus: false, passwordFocus: false, cpasswordFocus: false, roleFocus: true, countryFocus: false });


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const submitRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const snackKekaxRef: any = useRef();

    const [tempDisabled, setTempDisabled] = useState<number>(0);
    const [flagsDropdown, setFlagsDropdown] = useState({ open: false, class: 'flags-menu-drop' });
    const refFlagsDropdownMenu = useRef<any>(null);
    const refFlagsDropdownButton = useRef<any>(null);
    const [flagsArrayAll, setFlagsArrayAll] = useState<FlagOption[]>([])

    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("register-modal-open");
            document.addEventListener('mousedown', handleEventListeners);
            document.addEventListener('scroll', handleOffset);
            window.addEventListener('resize', handleEventListeners);


            document.addEventListener('keydown', (e: any) => handleKeyDown(e));

            handleOffset();
            let countries: FlagOption[] = []
            for (let i = 0; i < GeneralServices.countries.length; i++) {
                countries.push({
                    id: parseInt(GeneralServices.countries[i].id),
                    tag: GeneralServices.countries[i].tag,
                    shortNameENG: GeneralServices.countries[i].shortNameENG,
                    shortNameNOL: GeneralServices.countries[i].shortNameNOL,
                    officialName: GeneralServices.countries[i].officialName,
                    flag: require('../../../../assets/flags/' + GeneralServices.countries[i].tag + '.png')

                })
                if (i === GeneralServices.countries.length - 1) {
                    setFlagsArrayAll([...countries])
                }
            }
        } else {
            modalRef.current.classList.remove("register-modal-open");

        }
    }, [props.open])

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {

                    if (refFlagsDropdownMenu.current && refFlagsDropdownButton.current && !refFlagsDropdownMenu.current.contains(event.target) && !refFlagsDropdownButton.current.contains(event.target)) {
                        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    }

                    if (event.target.contains(overlayRef.current)) {
                        closeModalHandler(GeneralServices.constants.child.closed);
                    }
                    break
                }
                case 'resize': {
                    setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    break
                }
                default: {

                }
            }
        }



    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--registerModal-topOffset", posY + "px");
    }


    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            submitRef.current.click();
        }
    }


    const closeModalHandler = (feedback: string) => {

        modalRef.current.classList.remove("register-modal-open");
        document.removeEventListener('mousedown', handleEventListeners);
        document.removeEventListener('scroll', handleOffset);
        window.removeEventListener('resize', handleEventListeners);
        document.removeEventListener('keydown', (e: any) => handleKeyDown(e));

        //RESET FORM
        setTempDisabled(0)
        setFormSubmitValid(false);
        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
        setShowPassword({ type: 'password', icon: visibility_off })
        setShowCPassword({ type: 'password', icon: visibility_off })
        setFormError({ firstName: '', lastName: '', username: '', email: '', password: '', cpassword: '', role: '', country: '' });
        setFormInput({ firstName: '', lastName: '', username: '', email: '', password: '', cpassword: '', role: GeneralServices.roles.manager, country: '' })
        setFormValid({ firstNameValid: false, lastNameValid: false, usernameValid: false, emailValid: false, passwordValid: false, cpasswordValid: false, roleValid: true, countryValid: false })
        setFormFocus({ firstNameFocus: false, lastNameFocus: false, usernameFocus: false, emailFocus: false, passwordFocus: false, cpasswordFocus: false, roleFocus: true, countryFocus: false })
        setFlagsArrayAll([])

        props.callback(feedback)
    }






    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMFIRSTNAMEID: {
                setFormInput({ ...formInput, firstName: value })
                break;
            }
            case FORMLASTNAMEID: {
                setFormInput({ ...formInput, lastName: value })
                break;
            }
            case FORMUSERNAMEID: {
                setFormInput({ ...formInput, username: value })
                break;
            }
            case FORMEMAILID: {
                setFormInput({ ...formInput, email: value })
                break;
            }
            case FORMPASSWORDID: {
                setFormInput({ ...formInput, password: value })
                break;
            }
            case FORMCPASSWORDID: {
                setFormInput({ ...formInput, cpassword: value })
                break;
            }
            case FORMROLEID: {
                setFormInput({ ...formInput, role: value })
                break;
            }
            case FORMCOUNTRYID: {
                setFormInput({ ...formInput, country: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {

        let fieldValidationErrors = formError;
        let upd_firstNameValid = formValid.firstNameValid;
        let upd_lastNameValid = formValid.lastNameValid;
        let upd_usernameValid = formValid.usernameValid;
        let upd_emailValid = formValid.emailValid;
        let upd_passwordValid = formValid.passwordValid;
        let upd_cpasswordValid = formValid.cpasswordValid;
        let upd_roleValid = formValid.roleValid;
        let upd_countryValid = formValid.countryValid;


        /*FIRST NAME VALIDATION*/
        if (formInput.firstName === '') {
            upd_firstNameValid = false;
            fieldValidationErrors.firstName = props.appLanguage.register.error_required;
        } else {
            upd_firstNameValid = true;
            fieldValidationErrors.firstName = props.appLanguage.register.fnameAccept;
        }

        /*LAST NAME VALIDATION*/
        if (formInput.lastName === '') {
            upd_lastNameValid = false;
            fieldValidationErrors.lastName = props.appLanguage.register.error_required;
        } else {
            upd_lastNameValid = true;
            fieldValidationErrors.lastName = props.appLanguage.register.lnameAccept;
        }


        /*USERNAME VALIDATION*/
        if (formInput.username === '') {
            upd_usernameValid = false;
            fieldValidationErrors.username = props.appLanguage.register.error_required;
        } else {
            upd_usernameValid = true;
            fieldValidationErrors.username = props.appLanguage.register.userAccept;
        }


        /*EMAIL VALIDATION*/
        //eslint-disable-next-line
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (formInput.email.match(emailRegex) !== null) {
            upd_emailValid = true;
        } else {
            upd_emailValid = false;
        }

        if (formInput.email === '') {
            fieldValidationErrors.email = props.appLanguage.register.error_required;
        } else if (!upd_emailValid) {
            fieldValidationErrors.email = props.appLanguage.register.error_email;
        } else {
            fieldValidationErrors.email = props.appLanguage.register.emailAccept;
        }


        /*PASSWORD VALIDATION*/
        upd_passwordValid = formInput.password.length >= 6;

        if (formInput.password === '') {
            fieldValidationErrors.password = props.appLanguage.register.error_required;
        } else if (!upd_passwordValid) {
            fieldValidationErrors.password = props.appLanguage.register.error_pwd;
        } else {
            fieldValidationErrors.password = props.appLanguage.register.pwdAccept;
        }

        upd_cpasswordValid = formInput.cpassword.length >= 6 && formInput.password === formInput.cpassword;

        if (formInput.cpassword === '') {
            fieldValidationErrors.cpassword = props.appLanguage.register.error_required;
        } else if (!upd_cpasswordValid) {
            fieldValidationErrors.cpassword = props.appLanguage.register.error_cpwd;
        } else {
            fieldValidationErrors.cpassword = props.appLanguage.register.cpwdAccept;
        }


        /*ROLE VALIDATION*/
        if (formInput.role === '') {
            upd_roleValid = false;
            fieldValidationErrors.role = props.appLanguage.register.error_required;
        } else {
            upd_roleValid = true;
            fieldValidationErrors.role = props.appLanguage.register.roleAccept
        }

        /*ROLE VALIDATION*/
        if (formInput.country === '') {
            upd_countryValid = false;
            fieldValidationErrors.country = props.appLanguage.register.error_required;
        } else {
            upd_countryValid = true;
            fieldValidationErrors.country = props.appLanguage.register.countryAccept
        }

        setFormError(fieldValidationErrors)
        setFormValid({ firstNameValid: upd_firstNameValid, lastNameValid: upd_lastNameValid, usernameValid: upd_usernameValid, emailValid: upd_emailValid, passwordValid: upd_passwordValid, cpasswordValid: upd_cpasswordValid, roleValid: upd_roleValid, countryValid: upd_countryValid })

        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.firstNameValid && formValid.lastNameValid && formValid.usernameValid
            && formValid.emailValid && formValid.passwordValid && formValid.cpasswordValid && formValid.roleValid && formValid.countryValid)
    }, [formValid])



    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMFIRSTNAMEID: {
                setFormFocus({ ...formFocus, firstNameFocus: true })
                break;
            }
            case FORMLASTNAMEID: {
                setFormFocus({ ...formFocus, lastNameFocus: true })
                break;
            }
            case FORMUSERNAMEID: {
                setFormFocus({ ...formFocus, usernameFocus: true })
                break;
            }
            case FORMEMAILID: {
                setFormFocus({ ...formFocus, emailFocus: true })
                break;
            }
            case FORMPASSWORDID: {
                setFormFocus({ ...formFocus, passwordFocus: true })
                break;
            }
            case FORMCPASSWORDID: {
                setFormFocus({ ...formFocus, cpasswordFocus: true })
                break;
            }
            case FORMROLEID: {
                setFormFocus({ ...formFocus, roleFocus: true })
                break;
            }
            case FORMCOUNTRYID: {
                setFormFocus({ ...formFocus, countryFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end

    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setTempDisabled(1);

        httpUserSignUpRequest({
            userId: formInput.username,
            realName: formInput.firstName + ' ' + formInput.lastName,
            email: formInput.email,
            pass: formInput.password,
            country: formInput.country,
        }).then(res => {
            closeModalHandler(GeneralServices.constants.child.worked);
        }).catch(
            e => {
                const errorStatus = e.status;
                if (errorStatus === GeneralServices.errorStatus.forbidden) {
                    snackKekaxRef.current.openSnackBar(props.appLanguage.register.forbidden)
                } else if (errorStatus === GeneralServices.errorStatus.conflict) {
                    snackKekaxRef.current.openSnackBar(props.appLanguage.register.conflict)
                } else {
                    closeModalHandler(GeneralServices.constants.child.failed);
                }
            }
        )

    }


    const showPasswordHandler = () => {
        if (showPassword.type === 'password') {
            setShowPassword({ type: 'text', icon: visibility })
        } else {
            setShowPassword({ type: 'password', icon: visibility_off })
        }
    }


    const showCPasswordHandler = () => {
        if (showCPassword.type === 'password') {
            setShowCPassword({ type: 'text', icon: visibility })
        } else {
            setShowCPassword({ type: 'password', icon: visibility_off })
        }


    }



    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMUSERNAMEID: {
                return formFocus.usernameFocus && !formValid.usernameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.username}</span>
                    : formValid.usernameValid ? <span className="input-valid-feedback-kekax">{formError.username}</span>
                        : null

            }

            case FORMPASSWORDID: {
                return formFocus.passwordFocus && !formValid.passwordValid ?
                    <span className="input-invalid-feedback-kekax">{formError.password}</span>
                    : formValid.passwordValid ? <span className="input-valid-feedback-kekax">{formError.password}</span>
                        : <span className="input-info-feedback-kekax">{props.appLanguage.register.pwdInfo}</span>
            }

            case FORMCPASSWORDID: {
                return formFocus.cpasswordFocus && !formValid.cpasswordValid ?
                    <span className="input-invalid-feedback-kekax">{formError.cpassword}</span>
                    : formValid.cpasswordValid ? <span className="input-valid-feedback-kekax">{formError.cpassword}</span>
                        : null
            }


            case FORMFIRSTNAMEID: {
                return formFocus.firstNameFocus && !formValid.firstNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.firstName}</span>
                    : formValid.firstNameValid ? <span className="input-valid-feedback-kekax">{formError.firstName}</span>
                        : null
            }

            case FORMLASTNAMEID: {
                return formFocus.lastNameFocus && !formValid.lastNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.lastName}</span>
                    : formValid.lastNameValid ? <span className="input-valid-feedback-kekax">{formError.lastName}</span>
                        : null
            }


            case FORMEMAILID: {
                return formFocus.emailFocus && !formValid.emailValid ?
                    <span className="input-invalid-feedback-kekax">{formError.email}</span>
                    : formValid.emailValid ? <span className="input-valid-feedback-kekax">{formError.email}</span>
                        : null
            }

            case FORMROLEID: {
                return formFocus.roleFocus && !formValid.roleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.role}</span>
                    : formValid.roleValid ? <span className="input-valid-feedback-kekax">{formError.role}</span>
                        : null
            }

            case FORMCOUNTRYID: {
                return formFocus.countryFocus && !formValid.countryValid ?
                    <span className="input-invalid-feedback-kekax">{formError.country}</span>
                    : formValid.countryValid ? <span className="input-valid-feedback-kekax">{formError.country}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }


    const flagDropdownHandler = () => {
        if (refFlagsDropdownMenu.current !== null) {
            const myElement: HTMLElement | null = document.getElementById(FORMCOUNTRYID);
            const myParent: HTMLElement | null = document.getElementById("register-parent-container");

            if (myElement !== null && myParent !== null) {
                const yPos = (myParent.getBoundingClientRect().y + myParent.getBoundingClientRect().height - myElement.getBoundingClientRect().y)
                refFlagsDropdownMenu.current.style.bottom = yPos + 'px';
                refFlagsDropdownMenu.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';
            }

        }

        if (!flagsDropdown.open) {
            setFlagsDropdown({ open: true, class: 'flags-menu-drop flags-menu-drop-open' })
        } else {
            setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
        }

    }

    const changeInputCountryHandler = (option: string) => {
        setFormInput({ ...formInput, country: option })
    }

    return (

        <React.Fragment>
            <div className="wrapper-register-modal" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <div className="content" ref={contentRef} id="register-parent-container">
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.register.title}</span>
                        <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                    </div>
                    <div className="body">
                        <form className="body-form" id='register-form'>
                            <div className="main-row-split">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.fname}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.firstName}
                                            type="text"
                                            placeholder={props.appLanguage.register.fnameph}
                                            id={FORMFIRSTNAMEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}

                                        />
                                    </div>
                                    <div className="feedback-block feedback-block-left">
                                        {
                                            validatorFeedback(FORMFIRSTNAMEID)
                                        }
                                    </div>
                                </div>


                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.lname}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.lastName}
                                            type="text"
                                            placeholder={props.appLanguage.register.lnameph}
                                            id={FORMLASTNAMEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}

                                        />
                                    </div>
                                    <div className="feedback-block feedback-block-right">
                                        {
                                            validatorFeedback(FORMLASTNAMEID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.user}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.username}
                                            type="text"
                                            placeholder={props.appLanguage.register.userph}
                                            id={FORMUSERNAMEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}

                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMUSERNAMEID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.email}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.email}
                                            type="text"
                                            placeholder={props.appLanguage.register.emailph}
                                            id={FORMEMAILID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}

                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMEMAILID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.pwd}
                                    </label>
                                    <div className="field-flex">
                                        <input className="input-text"
                                            value={formInput.password}
                                            type={showPassword.type}
                                            placeholder={props.appLanguage.register.pwdph}
                                            id={FORMPASSWORDID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}
                                        />
                                        <div className="input-icon" onClick={showPasswordHandler} style={{ "backgroundImage": "url(" + showPassword.icon + ")" }}></div>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMPASSWORDID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.cpwd}
                                    </label>
                                    <div className="field-flex">
                                        <input className="input-text"
                                            value={formInput.cpassword}
                                            type={showCPassword.type}
                                            placeholder={props.appLanguage.register.cpwdph}
                                            id={FORMCPASSWORDID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}
                                        />
                                        <div className="input-icon" onClick={showCPasswordHandler} style={{ "backgroundImage": "url(" + showCPassword.icon + ")" }}></div>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMCPASSWORDID)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.register.role} *
                                    </label>
                                    <div className="field">
                                        <select className="input-text input-role"
                                            id={FORMROLEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            onFocus={(event: any) => validateFocusFields(event)}
                                        >
                                            <option className="ayy" defaultValue={GeneralServices.roles.manager}>{props.appLanguage.register.roleOption[1]}</option>

                                        </select>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMROLEID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.country.title} *
                                    </label>
                                    <div className="field">
                                        <button type="button" className="input-text input-country" id={FORMCOUNTRYID} onClick={(event: any) => { validateFocusFields(event); flagDropdownHandler() }} ref={refFlagsDropdownButton}>
                                            {
                                                formInput.country === "" ?
                                                    <>
                                                        <div className="text-ph"><span>{props.appLanguage.register.countryph}</span></div>
                                                        <div className="arrow-down"></div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="opt-flag" style={{ backgroundImage: "url(" + require("../../../../assets/flags/" + formInput.country + ".png") + ")" }}></div>
                                                        <div className="text-country"><span>{formInput.country.toUpperCase()}</span></div>
                                                        <div className="arrow-down"></div>

                                                    </>
                                            }

                                        </button>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMCOUNTRYID)
                                        }
                                    </div>

                                </div>
                            </div>

                        </form>
                    </div>
                    <div className={flagsDropdown.class} ref={refFlagsDropdownMenu}>
                        <div className="wrapper-flags-menu-dropdown">
                            <div className="drop-filter-wrapper">
                                {
                                    flagsArrayAll.map((flag: FlagOption, index: number) => (

                                        <div key={"register-flag-"+index} className={formInput.country === flag.tag ? "drop-option opt-active" : "drop-option"} onClick={() => { changeInputCountryHandler(flag.tag); setFlagsDropdown({ open: false, class: 'flags-menu-drop' }) }}>
                                            <div className="icon" style={{ backgroundImage: "url(" + flag.flag + ")" }}></div>
                                            <span className="text">{flag.shortNameENG.toUpperCase()}</span>
                                        </div>
                                    ))

                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="submit"><button disabled={!formSubmitValid || tempDisabled === 1} ref={submitRef} type="submit" className="submit-btn" onClick={(event: any) => submitHandler(event)}><span className="info">{props.appLanguage.register.submit}</span></button></div>
                        <div className="cancel"><button className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.register.close}</span></button></div>
                    </div>
                </div>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    )

}

export default connect(mapStateToProps)(RegisterModal);
