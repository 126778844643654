import React, { useState, useEffect, useRef } from 'react'
import "./AuxSituation.css"
import GeneralServices from "../../../../services/generalService.json"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
//import { httpGetAuxSituation } from '../../../../services/requestService';

import AddAuxSituation from './addAuxSituation/AddAuxSituation';
import EditAuxSituation from './editAuxSituation/EditAuxSituation';
import RmvAuxSituation from './rmvAuxSituation/RmvAuxSituation';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShowAuxSituation from './showAuxSituation/ShowAuxSituation';
import { httpGetAuxSituationAll } from '../../../../services/requestService';


interface IProps extends StoreProps {

}


interface IOpenAuxSituation {
    add: boolean
    edit: boolean
    rmv: boolean
    show: boolean
    situation: IAuxSituation

}

const AUXCOUNTRYADD = "add"
const AUXCOUNTRYRMV = "rmv"
const AUXCOUNTRYEDIT = "edit"
const AUXCOUNTRYSHOW = "show"

export interface IAuxSituation {
    id: string
    situation: string

}

export const AuxSituation: React.FC<IProps> = (props) => {

    const maxArray: number = 10;
    const maxPagesArray: number = 5;

    const [situationArray, setSituationArray] = useState<IAuxSituation[]>([]);
    //const [situationArrayDisplay, setSituationArrayDisplay] = useState<IAuxSituation[]>([]);

    const [totalSituation, setTotalSituation] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const snackKekaxRef: any = useRef();

    const [openAuxSituation, setOpenAuxSituation] = useState<IOpenAuxSituation>({ edit: false, rmv: false, add: false, show: false, situation: { id: "", situation: "" } })



    useEffect(() => {
        /*
        const dummyAuxCountries = GeneralServices.taux6Situation
        let dummyAuxCountriesToAdd: IAuxSituation[] = []
        for (let i = 0; i < dummyAuxCountries.length; i++) {
            dummyAuxCountriesToAdd.push({
                id: i + 1 + "", situation: dummyAuxCountries[i]
            })
        }
        setSituationArray([...dummyAuxCountriesToAdd])
        setTotalSituation(dummyAuxCountriesToAdd.length)
        console.log(dummyAuxCountriesToAdd.length)
        */
        httpGetAuxSituationAll('?limit=' + maxArray).then(
            res => {
                //SETTING TOTAL Publication

                if (res.headers.total !== undefined) {
                    setTotalSituation(parseInt(res.headers.total, 10));
                } else {
                    setTotalSituation(parseInt('1', 10));
                }
                console.log(res.data)
                setSituationArray([...res.data]);


            }
        ).catch(
            err => {

            }
        )

    }, [])



    const handleOpenAuxSituation = (what: string, auxSituation: IAuxSituation | any) => {

        if (what === AUXCOUNTRYADD) {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...data, add: !data.add }))

        } else if (what === AUXCOUNTRYEDIT) {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...data, edit: !data.edit, situation:auxSituation}))

        } else if (what === AUXCOUNTRYRMV) {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...data, rmv: !data.rmv, situation:auxSituation}))

        } else if (what === AUXCOUNTRYSHOW) {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...data, show: !data.show, situation: auxSituation }))

        }
    }

    const addAuxSituationHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxSituation({ ...openAuxSituation, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.add.submitSuccess)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxSituation({ ...openAuxSituation, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxSituation({ ...openAuxSituation, add: false })
        } else {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...openAuxSituation, add: !data.add }))
        }
        loadNewPage(counter,false)
    }

    const editAuxSituationHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxSituation({ ...openAuxSituation, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.edit.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxSituation({ ...openAuxSituation, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.edit.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxSituation({ ...openAuxSituation, edit: false })
        } else {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...openAuxSituation, edit: !data.edit }))
        }
        loadNewPage(counter,false)
    }

    const rmvAuxSituationHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxSituation({ ...openAuxSituation, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.delete.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxSituation({ ...openAuxSituation, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.situation.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxSituation({ ...openAuxSituation, rmv: false })
        } else {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...openAuxSituation, rmv: !data.rmv }))
        }
        loadNewPage(counter,false)
    }

    const showAuxSituationHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxSituation({ ...openAuxSituation, show: false })
        } else {
            setOpenAuxSituation((data: IOpenAuxSituation) => ({ ...openAuxSituation, show: !data.show }))
        }
        loadNewPage(counter,false)
    }


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalSituation / maxArray);
        const extra = totalSituation % maxArray;

        if (extra !== 0 || totalSituation === 0) {
            result += 1
        }

        setMaxCounter(result);
    }, [totalSituation])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, init: boolean) => {
        /*
        const startFrom = page === 0 ? 0 : (page - 1) * maxArray

        let newPageMines: IAuxSituation[] = []
        for (let i = startFrom, counter = 0; i < situationArray.length && counter < maxArray; i++, counter++) {
            newPageMines.push(situationArray[i]);

            if (newPageMines.length === maxArray || i === situationArray.length - 1) {
                setSituationArrayDisplay([...newPageMines])
            }
            if (init) {
                window.scrollTo({
                    top: 0,
                    behavior: 'auto'
                })

            }
        }
        */
        const queryPage = page - 1;
        httpGetAuxSituationAll('?page=' + queryPage + '&limit=' + maxArray).then(
            res => {
                setSituationArray([...res.data]);
                if (init) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }

    /*
    useEffect(() => {
        if (situationArray.length > 0) {
            loadNewPage(0, true)
        }
    }, [situationArray])
    */
    return (
        <React.Fragment>
            <div className="wrapper-aux-situation">

                <div className="container-auxSituation">
                    <div className="auxSituation-menu">
                        <div className="auxSituation-menu-title"><span>{props.appLanguage.backoffice.manageMines.aux.situation.title}</span></div>
                        <div className="auxSituation-menu-options">
                            <div className="auxSituation-opt-add" onClick={() => handleOpenAuxSituation(AUXCOUNTRYADD, null)}>
                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.situation.addOpt} direction="top">
                                    <div className="auxSituation-opt-add-icon"></div>
                                    <span>{props.appLanguage.backoffice.manageMines.aux.situation.addOpt}</span>
                                </TooltipKekax>
                            </div>
                        </div>
                    </div>

                    <div className="auxSituation-list">
                        {
                            situationArray.map((auxSituation: IAuxSituation, index: number) => (
                                <div className="auxSituation-list-obj" key={"situation" + index}>
                                    <div className="auxSituation-id"><span>{auxSituation.id}</span></div>
                                    <div className="auxSituation-title">
                                        <span>{auxSituation.situation} </span>
                                    </div>
                                    <div className="auxSituation-options">
                                        <div className="auxSituation-obj-show" onClick={() => handleOpenAuxSituation(AUXCOUNTRYSHOW, auxSituation)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.situation.showOpt} direction="top">
                                                <div className="auxSituation-obj-show-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                        <div className="auxSituation-obj-rmv" onClick={() => handleOpenAuxSituation(AUXCOUNTRYRMV, auxSituation)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.situation.rmvOpt} direction="top">
                                                <div className="auxSituation-obj-rmv-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>

                </div>
                <div className="container-auxSituation-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>

            </div>

            <SnackKekax ref={snackKekaxRef} />
            <AddAuxSituation data={{ open: openAuxSituation.add, situation: openAuxSituation.situation }} callback={addAuxSituationHandler} />
            <RmvAuxSituation data={{ open: openAuxSituation.rmv, situation: openAuxSituation.situation }} callback={rmvAuxSituationHandler} />
            <ShowAuxSituation data={{ open: openAuxSituation.show, situation: openAuxSituation.situation }} callback={showAuxSituationHandler} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(AuxSituation);
