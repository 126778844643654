
import * as React from 'react';
import './MineMap.css';
import "mapbox-gl/dist/mapbox-gl.css"
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css"
import { useEffect, useRef, useState } from "react";
import { httpGetAllMines, httpGetQuestionMine } from '../../services/requestService';
import { RouteComponentProps } from 'react-router-dom';
import GeneralServices from '../../services/generalService.json'
import SnackKekax from '../kekax/SnackKekax';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';


interface IProps extends RouteComponentProps<any> { }

export interface IMineInfo {
  address: string
  address2: string
  cafeteria: string
  carPark: string
  complex: string
  complexName: string
  country: string //this
  giftShop: string
  id: number
  infoFigure: string
  lat: number
  lng: number
  mail: string
  mineCode: string
  mineHeritageRoute: string
  minePopDesc: string
  municipality: string
  otherInfoCont: string
  phone: string
  postalCode: string
  projectFig: string
  pubTransport: string
  pubTransportDesc: string
  regionStateProvince: string
  siteNameENG: string
  siteNameNOL: string //this
  touristicname: string
  website: string
}

interface ForcePopupLang {
  force: boolean
  type: number //0-no 1-yes
}

export interface IMap {
  longitude: number
  latitude: number
  zoom: number
}

interface IProps extends StoreProps { }

//mapboxgl.accessToken = 'pk.eyJ1IjoiZHBzaWx2YWE5NyIsImEiOiJjazJyeGVvcWwwZmpoM2NvM3VteG1icjdqIn0.-7SYaFF4kjFU-7oMx5OTdQ';
export const mapToken = 'pk.eyJ1IjoiZHBzaWx2YWE5NyIsImEiOiJjazJyeGVvcWwwZmpoM2NvM3VteG1icjdqIn0.-7SYaFF4kjFU-7oMx5OTdQ';
export const MineMap: React.FC<IProps> = props => {

  const snackKekaxRef: any = React.useRef();
  //const [forcePopupLang, setForcePopupLang] = useState<ForcePopupLang>({ force: false, type: 0 })


  const [viewport, setViewport] = useState<IMap>({ longitude: 0, latitude: 0, zoom: 0 });
  const mapRef = useRef(null)

  const [markers, setMarkers] = useState<IMineInfo[]>([])
  const [selectedMarker, setSelectedMarker] = useState<IMineInfo | null>(null)
  const [selectedGame, setSelectedGame] = useState<boolean>(false)

  /*
  const mapContainerRef = useRef<any>(null);
  const [lat, setLat] = useState(48.210033);
  const [lng, setLng] = useState(16.363449);
  const [zoom, setZoom] = useState(4);
  

  useEffect(() => {
    const map: any = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // change cursor to pointer when user hovers over a clickable feature
    map.on('mouseenter', (e: any) => {
      if (e.features.length) {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    // reset cursor to default when user is no longer hovering over a clickable feature
    map.on('mouseleave', () => {
      map.getCanvas().style.cursor = '';
    });

    
    map.on('mouseenter', 'places', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('load', function () {
      map.resize();
    });
    

    httpGetAllMines().then(
      res => {
        res.data.forEach(function (item: any) {
          const el = document.createElement('div');
          el.className = 'marker';
          new mapboxgl.Marker(el, { anchor: 'bottom' })
            .setLngLat([item.lng, item.lat])
            .setPopup(popup(item.mineCode, item.siteNameNOL, item.phone, item.mail, item.website))
            .addTo(map);
        })
      }
    ).catch(
      err => {
      }
    )

    // Clean up on unmount
    return () => map.remove();
  }, []);// eslint-disable-line import/no-webpack-loader-syntax
  */

  /*

  const popup = (mineCode: string, mineName: string, phone: string, email: string, website: string) => { //meter isto no servidor - usar redux

    const el = document.createElement("div")
    el.className = "mine-marker"
    const titleDiv = document.createElement("div")
    titleDiv.className = "mine-marker-title"
    const titleSpan = document.createElement("span")
    titleSpan.textContent = mineName
    titleDiv.appendChild(titleSpan)

    const contentDiv = document.createElement("div")
    contentDiv.className = "mine-marker-content"

    const phoneDiv = document.createElement("div")
    phoneDiv.className = "mine-marker-text"
    const phoneSpan = document.createElement("span")
    phoneSpan.textContent = phone
    phoneDiv.appendChild(phoneSpan)

    const emailDiv = document.createElement("div")
    emailDiv.className = "mine-marker-text"
    const emailSpan = document.createElement("span")
    emailSpan.textContent = email
    emailDiv.appendChild(emailSpan)

    const websiteDiv = document.createElement("div")
    websiteDiv.className = "mine-marker-hl"
    const websiteA = document.createElement("a")
    websiteA.textContent = website
    websiteA.href = website

    websiteDiv.appendChild(websiteA)



    el.appendChild(titleDiv)
    contentDiv.appendChild(phoneDiv)
    contentDiv.appendChild(emailSpan)
    el.appendChild(contentDiv)
    el.appendChild(websiteDiv)




    const popupp = new mapboxgl.Popup({
      offset: { 'bottom': [0, -10] },  // To prevent popup from over shadowing the marker. other: offset: 25 
    }).setDOMContent(el);


    popupp.once('open', function (e: any) {
      httpGetQuestionMine(mineCode).then(
        res => {
          console.log(res.data)
          if (res.data.length > 0) {
            console.log("a")
            const gameDiv = document.createElement("div")
            gameDiv.className = "mine-marker-game"
            const gameSpan = document.createElement("span")
            //gameSpan.id = "lmfao"
            gameSpan.textContent = props.appLanguage.mapPage.yesGame
            gameDiv.appendChild(gameSpan)
            gameDiv.onclick = () => (props.history.push(GeneralServices.quizPagePath.replace(":id", mineCode)))
            el.appendChild(gameDiv)
            //setForcePopupLang((forcePopupLang: ForcePopupLang) => ({ force: !forcePopupLang, type: 1 }))
          } else {
            //snackKekaxRef.current.openSnackBar(props.appLanguage.mapPage.noGame)
            console.log("b")
            const gameDiv = document.createElement("div")
            gameDiv.className = "mine-marker-nogame"
            const gameSpan = document.createElement("span")
            gameSpan.textContent = props.appLanguage.mapPage.noGame
            //gameSpan.id = "lmfao"
            gameDiv.appendChild(gameSpan)
            el.appendChild(gameDiv)
            //setForcePopupLang((forcePopupLang: ForcePopupLang) => ({ force: !forcePopupLang, type: 0 }))
          }

        }
      ).catch(
        err => {

        }
      )
    })
    popupp.once('op', function (e: any) {
      setForcePopupLang((forcePopupLang: ForcePopupLang) => ({ force: !forcePopupLang, type: 0 }))
    })
    popupp.once('close', function (e: any) {
      setForcePopupLang((forcePopupLang: ForcePopupLang) => ({ force: !forcePopupLang, type: 0 }))
    })

    return popupp;


  }

*/

  const handleGoToGame = (id: string) => {
    httpGetQuestionMine(id).then(
      res => {
        if (res.data.length !== 0) {
          const quizPageUrl = GeneralServices.quizPagePath.replace(":id", id)
          props.history.push(quizPageUrl);
        } else {
          snackKekaxRef.current.openSnackBar(props.appLanguage.mapPage.noGame)

        }
      }
    ).catch(
      err => {
      }
    )

  }



  /*
  useEffect(() => {
    const sup = document.getElementById("lmfao")
    if (sup) {
      if (forcePopupLang.type === 1) {
        sup.textContent = props.appLanguage.mapPage.yesGame
      } else if (forcePopupLang.type === 0) {
        sup.textContent = props.appLanguage.mapPage.noGame
      }
    }
  }, [props.appLanguage, forcePopupLang])
*/
  /*
    return (
      <React.Fragment>
        <div className="wrapper-minemap">
          <div className="container-header">
            <div className="content">
              <span className="title">{props.appLanguage.mapPage.title}</span>
            </div>
          </div>
          <div className="map-wrapper">
            <div className="sidebar">
              Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>
            <div className='map-container' ref={mapContainerRef} />
          </div>
        </div>
        <SnackKekax ref={snackKekaxRef} />
      </React.Fragment>
  
    )
  };
  */


  useEffect(() => {
    httpGetAllMines().then(
      res => {
        setMarkers(res.data)
      }
    ).catch(
      err => {
      }
    )
    setViewport({
      longitude: 3.4834177590148587,
      latitude: 43.59630686976215,
      zoom: 5
    })
  }, [])


  useEffect(() => {
    if (selectedMarker !== null) {
      httpGetQuestionMine(selectedMarker.mineCode).then(
        res => {
          if (res.data.length > 0) {
            setSelectedGame(true)
          } else {
            setSelectedGame(false)
          }

        }
      ).catch(
        err => {
          setSelectedGame(false)
        }
      )
    } else {
    }
  }, [selectedMarker])


  // Only rerender markers if props.data has changed
  const markerss = React.useMemo(() =>
    markers.map((marker: IMineInfo, index: number) => (

      <Marker key={marker.mineCode} latitude={marker.lat} longitude={marker.lng} offsetLeft={-20} offsetTop={-50}>

        <div className="marker" onClick={() => setSelectedMarker(marker)}></div>
      </Marker>

    )
    ), [markers]);



  return (
    <React.Fragment>
      <div className="wrapper-minemap">
        <div className="container-header">
          <div className="content">
            <span className="title">{props.appLanguage.mapPage.title}</span>
          </div>
        </div>
        <div className="map-wrapper">
          <div className="sidebar">
            {props.appLanguage.mapPage.lng}: {viewport.longitude} | {props.appLanguage.mapPage.lat}: {viewport.latitude} | {props.appLanguage.mapPage.zoom}: {viewport.zoom}
          </div>

          <ReactMapGL className='map-container' {...viewport} width="100%" height="50vmax" onViewportChange={(viewport) => setViewport(viewport)}
            ref={mapRef}
            mapboxApiAccessToken={mapToken}
            mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
            onClick={() => (selectedMarker !== null && setSelectedMarker(null))}

          >
            {
              markerss
            }

            {
              selectedMarker !== null ?
                (
                  <Popup latitude={selectedMarker.lat} longitude={selectedMarker.lng}
                    onClose={() => setSelectedMarker(null)}
                    offsetTop={-25}
                    offsetLeft={2}
                    anchor="bottom"
                    closeOnClick={false}
                    closeButton={true}
                  >

                    <div className="mine-popup" >
                      <div className="mine-popup-title ">
                        <span>{selectedMarker.siteNameENG}</span>
                      </div>
                      <div className="mine-popup-content">
                        <div className="mine-popup-text"><span>{selectedMarker.address}</span></div>
                        <div className="mine-popup-text"><span>{selectedMarker.phone}</span></div>
                        <div className="mine-popup-text"><span>{selectedMarker.mail}</span></div>
                        <div className="mine-popup-text mine-popup-web" ><span>{selectedMarker.website}</span></div>
                      </div>
                      {
                        selectedGame ?
                          <div className="mine-popup-game" onClick={() => (props.history.push(GeneralServices.quizPagePath.replace(":id", selectedMarker.mineCode)))}>
                            <span>{props.appLanguage.mapPage.yesGame}</span>
                          </div>
                          :
                          <div className="mine-popup-game" onClick={() => (props.history.push(GeneralServices.quizPagePath.replace(":id", "general")))}>
                            <span>{props.appLanguage.mapPage.yesGame}</span>

                          </div>
                      }

                    </div>
                  </Popup>
                )
                : null
            }

          </ReactMapGL>

        </div>
      </div>
      <SnackKekax ref={snackKekaxRef} />
    </React.Fragment >

  )
}
export default connect(mapStateToProps)(MineMap);

/*
export const PopupMoi = ({ children, latitude, longitude, map, ...mapboxPopupProps }:any) => {
  const popupRef = useRef<any>(null);
  useEffect(() => {
      console.log(map)
      const popup = new mapboxgl.Popup()
          .setLngLat([longitude, latitude])
          .setDOMContent(popupRef.current)
          .addTo(map.props.container);

      return ()=> popupRef.current.remove;

  }, [children, mapboxPopupProps, longitude, latitude]);

  return (

       <div style={{ display: 'none' }}>
       <div ref={popupRef}>
           {children}
       </div>
   </div>
);
};
 */