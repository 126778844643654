import React, { useEffect, useState } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './NewsLetter.css'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { httpGetNewsletterDetails, httpGetNewsletterImg, httpGetNewsletterContent, httpGetNewsletterAttachList, getNewsletterImageURL, getNewsletterAttachUrl, getNewsletterGalleryUrl } from '../../../services/requestService';
import GeneralServices from '../../../services/generalService.json'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactMarkdown from 'react-markdown/with-html';
import { translateFormatTimestamp } from '../../calendarEvents/calendar/Calendar';


export interface INewsLetterRes {
    contentList: ContentFile[]
    data: INewsLetter
    picList: GalleryFile[]

}

export interface INewsLetter {
    id: any
    tag: string
    title: string
    desc: string
    timestamp: any
    importance: number
    views: number
    img: any
}


export interface AttachFile {
    content: any

    attachId: string
    newsId: string
    fileName: string
    path: string
}


export interface ContentFile {
    contentName: string
    newsId: string
    order: number
    views: number


    content: string
    gallery: GalleryFile[]
}


export interface GalleryFile {
    desc: string
    extension: string
    newsId: string
    order: number
    path: string
    picId: string

    content: string
}



//content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."



interface IProps extends StoreProps, RouteComponentProps<any> { }

/*
export const newsTagLanguage = (propsLanguage: StoreProps, idTag: string) => {
    switch (idTag) {
        case '0': {
            return propsLanguage.appLanguage.newsletter.tag[idTag]
        }

        default: {
            return propsLanguage.appLanguage.newsletter.tag[0]
        }
    }
}
*/




export const NewsLetter: React.FC<IProps> = (props) => {

    const [newsLetterDisplay, setNewsLetterDisplay] = useState<INewsLetter>({ id: null, tag: '', title: '', desc: '', img: null, timestamp: null, importance: -1, views: -1 })
    const [contentArray, setContentArray] = useState<ContentFile[]>([{ contentName: '', newsId: '', order: 0, views: 0, content: '', gallery: [] }]);
    const [attachArray, setAttachArray] = useState<AttachFile[]>([{ content: null, attachId: '', newsId: '', fileName: '', path: '' }]);


    useEffect(() => {
        /*
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        */
        const newsLetterId = props.match.params.id;
        httpGetNewsletterDetails(newsLetterId).then(
            async res => {

                let result: INewsLetterRes = { ...res.data };
                let allContent: ContentFile[] = [...result.contentList];
                let allGallery: GalleryFile[] = [...result.picList];

                const newsImage = await httpGetNewsletterImg(newsLetterId).then(
                    res => {
                        return getNewsletterImageURL.replace('{news:id}', newsLetterId)
                    }
                ).catch(
                    err => {
                        return null;
                    }
                )


                for (let i = 0; i < allContent.length; i++) {
                    let contentOrder = allContent[i].order;
                    let nextContentOrder = allContent[allContent.length - 1].order;

                    if (allContent[i + 1] !== undefined) {
                        nextContentOrder = allContent[i + 1].order
                    }

                    allContent[i].content = await httpGetNewsletterContent(newsLetterId, contentOrder.toString()).then(
                        res => {
                            allContent[i].gallery = []
                            for (let x = 0; x < allGallery.length; x++) {

                                if (allGallery[x].order > contentOrder && (allGallery[x].order < nextContentOrder || contentOrder === nextContentOrder)) {

                                    allGallery[x].content = getNewsletterGalleryUrl.replace('{gallery:id}', allGallery[x].picId)
                                    allContent[i].gallery.push(allGallery[x])
                                }
                            }

                            return res.data;
                        }
                    ).catch(
                        err => {
                            return [];
                        }
                    )
                }


                const newsAttach = await httpGetNewsletterAttachList('?newsId=' + newsLetterId).then(
                    res => {
                        let newsAttachRes: AttachFile[] = res.data;
                        for (let i = 0; i < newsAttachRes.length; i++) {
                            newsAttachRes[i].content = getNewsletterAttachUrl.replace('{attach:id}', newsAttachRes[i].attachId)
                        }
                        return newsAttachRes;
                    }
                ).catch(
                    err => {
                        return [];
                    }
                )


                setNewsLetterDisplay({ ...result.data, img: newsImage });
                setContentArray([...allContent]);
                setAttachArray([...newsAttach])

            }
        ).catch(
            err => {
                props.history.push(GeneralServices.newsPath);
                /*
                setNewsLetterDisplay({ ...dummyNewsLetter, timestamp: dateFormat(dummyNewsLetter.timestamp, "MMMM dd, yyyy") })
                setAttachArray([dummyAttachFile])
                */

            }

        )
    }, [])



    return (
        <div className="wrapper-page-newsletter">
            {
                newsLetterDisplay.id !== null ?
                    <React.Fragment>
                        <div className="header-page-newsletter">
                            <span className="header-page-newsletter-title">
                                {newsLetterDisplay.title}
                            </span>
                            <span className="header-page-newsletter-sub">
                                {translateFormatTimestamp(newsLetterDisplay.timestamp, props.appLanguage)}
                            </span>
                        </div>
                        <div className="body-page-newsletter">
                            {
                                contentArray && contentArray.map((contentBlock: ContentFile, index1: number) => (
                                    <div key={"content" + index1}>
                                        <div className="body-page-newsletter-content">
                                            <span>
                                                <ReactMarkdown source={contentBlock.content+""} escapeHtml={false} />
                                            </span>
                                        </div>

                                        <div className="body-page-newsletter-gallery">
                                            {
                                                contentBlock.gallery && (contentBlock.gallery.map((file: any, index2) =>
                                                    <div className="media-object" key={"gallery" + index2}>
                                                        <div className="media-object-img">
                                                            <img src={file.content} alt="media-news" />
                                                            <div className="media-object-legend">
                                                                <span>{file.desc}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))

                                            }
                                        </div>

                                    </div>
                                ))

                            }


                            <div className="body-page-newsletter-attach">
                                {
                                    attachArray.length > 0 ?
                                        (attachArray.map((file: AttachFile, index) =>
                                            <div key={index} className="attach-object">
                                                <div className="attach-icon"><PictureAsPdfIcon className="ayy-icon" /></div>
                                                <div className="attach-name"><span>{file.fileName}</span></div>
                                                <div className="attach-menu">
                                                    <div className="attach-menu-option-show">
                                                        <a href={file.content} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                                                            <VisibilityIcon className="byy-icon" />
                                                        </a>
                                                    </div>
                                                    <div className="attach-menu-option-download">
                                                        <a href={file.content} download={file.fileName} style={{ color: 'black' }}>
                                                            <GetAppIcon className="byy-icon" />
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                        : null
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    : null
            }
        </div>

    );

}


export default connect(mapStateToProps)(withRouter(NewsLetter));


/*
                    {
                        newsLetterDisplay.img !== null && galleryArray.length <= 0 ?
                            <div className="body-page-newsletter-media">
                                <img src={newsLetterDisplay.img} alt="object-news" />
                            </div>
                            : null
                    }
*/
