import React, { useEffect, useRef, useState } from 'react';
import "./EditEventModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import StepperEditEvent from './stepperEditEvent/StepperEditEvent';
import { httpGetEventAttachList, httpGetEventContent, httpGetEventDetails, httpGetEventImg, getEventImageUrl, getEventGalleryUrl, getEventAttachUrl, httpPutEvent, httpPutEventImage, httpDeleteEventImage, httpPutEventContent, httpPutEventGallery, httpDeleteEventGallery, httpPostEventGallery, httpDeleteEventContent, httpDeleteEventAttach, httpPostEventAttach } from '../../../../services/requestService';
//httpGetEventGalleryList, httpPutEvent, httpPutEventImage, httpPutEventContent, httpPutEventGallery, httpPutEventAttach, 
import { IFormErrorEvent, IFormFocusEvent, IFormInputEvent, IFormValidEvent } from '../addEvent/AddEvent';
import GeneralServices from '../../../../services/generalService.json'

import dummyEvents from '../../../../assets/news/newsDelta.png'
import { getTime, startOfDay } from "date-fns";
import { AttachFile, ContentFile, GalleryFile, IEventRes } from '../../../calendarEvents/eventPage/EventPage';
import ReactMarkdown from 'react-markdown';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ITransferedGallery } from '../../newsManage/addNewsletter/AddNewsletter';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
import TooltipInfo from '../../newsManage/addNewsletter/tooltipInfo/TooltipInfo';
import StepperKekax from '../../../kekax/StepperKekax';


interface IProps extends StoreProps {
    callback: any
    event: IEditEvent
}

export interface IEditEvent {
    open: boolean
    eventId: any
    /*
    event:{
        title: string
        tag: string
        importance: number
        desc: string
        date: any
        img: DataEventImage
        content: DataPutEventContent
        gallery: DataEventGallery[]
        attach: DataPostEventAttach[]

    }
    */

}

interface IEditEventValidation {
    main: boolean
    article: boolean
}

interface AttachFileEditEvent {
    name: string
    content: any
    status: number //-1 inserted after(no effect) | 0 deleted | 1 not touched | 2 changed
    id: string

}
interface GalleryFileEditEvent {
    id: string
    name: string
    content: any
    legend: {
        text: string,
    }
    preview: any
    articleIndex: number
    status: number //-1 inserted after(no effect) | 0 deleted | 1 not touched | 2 changed

}

interface SelectedImg {
    content: any
    from: number //-2 deletedServer | -1 deletedLocal | 2 fromServer | 1 fromLocal
}


const FORMTITLEID: string = 'title';
const FORMTAGID: string = 'password';
const FORMDESCRIPTIONCID: string = 'description';
const FORMDATESTARTID: string = 'datestart';
const FORMDATEENDID: string = 'dateend';
const FORMCONTENTID: string = 'content';
const FORMLEGENDID: string = 'legend';

const MAX_STEPS = 2

export function EditEventModal(props: IProps) {

    const [formSubmitValid, setFormSubmitValid] = useState<IEditEventValidation>({ main: true, article: true });
    const [formError, setFormError] = useState<IFormErrorEvent>({ titleError: '', tagError: '', descError: '', dateStartError: '', dateEndError: '', contentError: [''] });
    const [formInput, setFormInput] = useState<IFormInputEvent>({ title: '', tag: '', desc: '', dateStart: getTime(new Date()), dateEnd: getTime(new Date()), content: [''] });
    const [formValid, setFormValid] = useState<IFormValidEvent>({ titleValid: false, tagValid: false, descValid: false, dateStartValid: false, dateEndValid: false, contentValid: [false] });
    const [formFocus, setFormFocus] = useState<IFormFocusEvent>({ titleFocus: false, tagFocus: false, descFocus: false, dateStartFocus: false, dateEndFocus: false, contentFocus: [false] });
    const [logContent, setLogContent] = useState<ContentFile[]>([{ contentName: '', eventId: '', order: 0, views: 0, content: '', gallery: [] }])

    const [selectedImage, setSelectedImage] = useState<SelectedImg>({ content: dummyEvents, from: 1 });
    const [eventImage, setEventImage] = useState<any>(null);

    const [attachArray, setAttachArray] = useState<AttachFileEditEvent[]>([]);
    const [transferedAttach, setTransferedAttach] = useState<any[]>([]);
    const [attachDrag, setAttachDrag] = useState<boolean>(false);



    const [galleryArray, setGalleryArray] = useState<GalleryFileEditEvent[]>([]);
    const [transferedGallery, setTransferedGallery] = useState<ITransferedGallery>({ articleIndex: -1, data: [] });
    const [galleryDrag, setGalleryDrag] = useState<boolean[]>([false]);


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const snackKekaxRef: any = useRef(null);


    let dragAttachCounter = 0;
    let dragGalleryCounter = 0;


    const refSelectedImage: any = useRef(null);
    const attachDropRef: any = useRef(null);
    const galleryDropRef: any = useRef([]);


    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    const loadEventById = () => {
        const eventId = props.event.eventId;
        httpGetEventDetails(eventId).then(
            async res => {
                let result: IEventRes = { ...res.data };
                let allContent: ContentFile[] = [...result.contentList];
                let allGallery: GalleryFile[] = [...result.picList];
                setLogContent([...allContent])

                const eventImage = await httpGetEventImg(eventId).then(
                    res => {
                        return getEventImageUrl.replace('{event:id}', eventId)
                    }
                ).catch(
                    err => {
                        return null;
                    }
                )


                for (let i = 0; i < allContent.length; i++) {
                    let contentOrder = allContent[i].order;
                    let nextContentOrder = allContent[allContent.length - 1].order;

                    if (allContent[i + 1] !== undefined) {
                        nextContentOrder = allContent[i + 1].order
                    }

                    allContent[i].content = await httpGetEventContent(eventId, contentOrder.toString()).then(
                        res => {
                            allContent[i].gallery = []
                            for (let x = 0; x < allGallery.length; x++) {

                                if (allGallery[x].order > contentOrder && (allGallery[x].order < nextContentOrder || contentOrder === nextContentOrder)) {
                                    allGallery[x].content = getEventGalleryUrl.replace('{gallery:id}', allGallery[x].picId)
                                    allContent[i].gallery.push(allGallery[x])
                                }
                            }

                            return res.data;
                        }
                    ).catch(
                        err => {
                            return [];
                        }
                    )
                }


                const eventAttach = await httpGetEventAttachList('?eventId=' + eventId).then(
                    res => {
                        let eventAttachRes: AttachFile[] = [...res.data];
                        let eventAttachToReturn: AttachFileEditEvent[] = [];

                        for (let i = 0; i < eventAttachRes.length; i++) {
                            eventAttachRes[i].content = getEventAttachUrl.replace('{attach:id}', eventAttachRes[i].attachId)
                            eventAttachToReturn.push({
                                content: eventAttachRes[i].content,
                                name: eventAttachRes[i].fileName,
                                id: eventAttachRes[i].attachId,
                                status: 1
                            })
                        }
                        return eventAttachToReturn;
                    }
                ).catch(
                    err => {
                        return [];
                    }
                )


                let contents = []
                for (let i = 0; i < allContent.length; i++) {
                    contents.push(allContent[i].content)
                }
                setFormInput({ title: result.data.title, tag: result.data.tag, desc: result.data.desc, dateStart: result.data.timestamp, dateEnd: result.data.timestampEnd, content: contents })
                if (eventImage !== null)
                    setSelectedImage({ content: eventImage, from: 2 })

                setAttachArray([...eventAttach])


                const finalGallery: GalleryFileEditEvent[] = []
                for (let i = 0; i < allContent.length; i++) {
                    for (let j = 0; j < allContent[i].gallery.length; j++) {
                        let toRead = allContent[i].gallery[j].desc
                        if (toRead === " ")
                            toRead = ""

                        finalGallery.push({
                            id: allContent[i].gallery[j].picId,
                            name: allContent[i].gallery[j].picId + "." + allContent[i].gallery[j].extension,
                            content: getEventGalleryUrl.replace('{gallery:id}', allContent[i].gallery[j].picId),
                            legend: { text: toRead },
                            preview: getEventGalleryUrl.replace('{gallery:id}', allContent[i].gallery[j].picId),
                            articleIndex: i,
                            status: 1
                        })
                    }
                }

                setGalleryArray([...finalGallery])
            }
        ).catch(
            err => {
                /*
                setEventDisplay({ ...dummyEvent, timestamp: dateFormat(dummyEvent.timestamp, "MMMM dd, yyyy") })
                setAttachArray([dummyAttachFile])
                */
            }
        )
    }



    useEffect(() => {

        if (props.event.open && modalRef.current) {
            modalRef.current.classList.add("editEvent-modal-open");

            loadEventById()
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();



        } else {
            modalRef.current.classList.remove("editEvent-modal-open");

        }

    }, [props.event.open])


    const handleClickAway = (event: any) => {

        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        /*
        if (contentRef && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editModalEvent-topOffset", posY + "px");
    }


    /*
    const changeStepperSelectedHandler = (active: any) => {
        setSelectedStepper(active)
    }
    */

    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }

    const formEndup = () => {

        contentRef.current.reset();
        setFormSubmitValid({ main: false, article: false });
        setFormError({ titleError: '', tagError: '', descError: '', dateStartError: '', dateEndError: '', contentError: [''] });
        setFormInput({ title: '', tag: '', desc: '', dateStart: getTime(new Date()), dateEnd: getTime(new Date()), content: [''] })
        setFormValid({ titleValid: false, tagValid: false, descValid: false, dateStartValid: false, dateEndValid: false, contentValid: [false] })
        setFormFocus({ titleFocus: false, tagFocus: false, descFocus: false, dateStartFocus: false, dateEndFocus: false, contentFocus: [false] })
        setSelectedImage({ content: dummyEvents, from: 1 });
        setEventImage(null);
        let attachListener = attachDropRef.current;
        if (attachListener !== null) {
            attachListener.removeEventListener('dragenter', handleDragInAttach)
            attachListener.removeEventListener('dragleave', handleDragOutAttach)
            attachListener.removeEventListener('dragover', handleDragAttach)
            attachListener.removeEventListener('drop', handleDropAttach)
        }
        for (let i = 0; i < galleryDropRef.current.length; i++) {
            let galleryListener = galleryDropRef.current[i];
            if (galleryListener !== null) {
                galleryListener.removeEventListener('dragenter', handleDragInGallery)
                galleryListener.removeEventListener('dragleave', handleDragOutGallery)
                galleryListener.removeEventListener('dragover', handleDragGallery)
                galleryListener.removeEventListener('drop', handleDropGallery)
            }
        }
        setGalleryDrag([false]);
        setGalleryArray([]);
        dragGalleryCounter = 0;

        setAttachDrag(false);
        setAttachArray([]);
        setTransferedAttach([]);
        dragAttachCounter = 0;

        setTransferedGallery({ articleIndex: -1, data: [] });
        setTempDisabled(0)

    }


    const handleDragAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragInAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setAttachDrag(true)
        }
    }
    const handleDragOutAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragAttachCounter--
        if (dragAttachCounter === 0) {
            setAttachDrag(false)
        }
    }
    const handleDropAttach = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setAttachDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedAttach(e.dataTransfer.files)
            e.dataTransfer.clearData();
            dragAttachCounter = 0;
        }
    }


    const handleDragGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragInGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        dragGalleryCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const dummyGalleryDrag = [...galleryDrag]
            dummyGalleryDrag[i] = true
            setGalleryDrag([...dummyGalleryDrag])
        }
    }
    const handleDragOutGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        dragGalleryCounter--
        if (dragGalleryCounter === 0) {
            const dummyGalleryDrag = [...galleryDrag]
            dummyGalleryDrag[i] = false
            setGalleryDrag([...dummyGalleryDrag])
        }
    }
    const handleDropGallery = (e: any, i: number) => {
        e.preventDefault()
        e.stopPropagation()
        const dummyGalleryDrag = [...galleryDrag]
        dummyGalleryDrag[i] = false
        setGalleryDrag([...dummyGalleryDrag])
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setTransferedGallery({ articleIndex: i, data: e.dataTransfer.files })
            e.dataTransfer.clearData();
            dragGalleryCounter = 0;
        }
    }


    useEffect(() => {
        if (transferedAttach.length > 0) {
            let fileList: any[] = [...attachArray];
            let counter = 0;
            for (let i = 0; i < transferedAttach.length; i++) {
                if (!transferedAttach[i].name) {
                    return
                }
                let ext = transferedAttach[i].type.split('/').pop();
                if (ext !== 'pdf') {
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropAttach[2];

                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedAttach[i].name))

                } else {
                    counter++;

                    /*
                    const fileName = transferedAttach[i].name.split('.');
                    fileName.pop();
                    let kxName = '';
                    for (let i = 0; i < fileName.length; i++) {
                        kxName = kxName.concat(fileName[i])
                    }
                    */
                    fileList.push({ name: transferedAttach[i].name, content: transferedAttach[i], status: -1 })
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropAttach[3];


                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))
                }

            }

            setTransferedAttach([]);
            setAttachArray([...fileList])

        }
    }, [transferedAttach, props.appLanguage])




    useEffect(() => {

        if (transferedGallery.articleIndex !== -1 && transferedGallery.data.length > 0) {
            let fileList: any[] = [...galleryArray];
            let counter = 0;
            for (let i = 0; i < transferedGallery.data.length; i++) {
                if (!transferedGallery.data[i].name) {
                    return
                }
                let ext = transferedGallery.data[i].type.split('/').pop();
                if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png') {
                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropGallery[2];
                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:id]', transferedGallery.data[i].name))

                } else {
                    counter++;
                    let imagePreview: any = null;
                    let reader = new FileReader();
                    const promise = new Promise((resolve: any) => {
                        reader.onload = () => {
                            imagePreview = reader.result;
                            const entity: GalleryFileEditEvent = { id: "", name: transferedGallery.data[i].name, content: transferedGallery.data[i], legend: { text: '' }, preview: imagePreview, articleIndex: transferedGallery.articleIndex, status: -1 }
                            resolve(entity)
                        }
                    })
                    promise.then((result: any) => {
                        fileList.push(result)
                        if (i === transferedGallery.data.length - 1) {
                            setTransferedGallery({ articleIndex: -1, data: [] });
                            setGalleryArray([...fileList])
                        }
                    }, function (error) {
                        //console.log("error reader")
                    });

                    let systemMsg = props.appLanguage.backoffice.manageEvents.add.dragDropGallery[3];
                    snackKekaxRef.current.openSnackBar(systemMsg.replace('[file:counter]', counter.toString()))

                    reader.readAsDataURL(transferedGallery.data[i])
                }
            }
        }

    }, [transferedGallery, props.appLanguage])

    const handleDeleteAttach = (pos: number) => {
        let attachArrayDummy: AttachFileEditEvent[] = [...attachArray];
        if (attachArrayDummy[pos].status !== -1) {
            attachArrayDummy[pos].status = 0
        } else {
            attachArrayDummy.splice(pos, 1);
        }
        setAttachArray([...attachArrayDummy])
    }

    const handleDeleteGallery = (pos: number) => {
        let galleryArrayDummy: GalleryFileEditEvent[] = [...galleryArray];
        if (galleryArrayDummy[pos].status !== -1) {
            galleryArrayDummy[pos].status = 0
        } else {
            galleryArrayDummy.splice(pos, 1);
        }

        setGalleryArray([...galleryArrayDummy])
    }


    const changeDateHandler = (date: Date | null, value: any, id: any) => {
        if (id === FORMDATESTARTID) {
            setFormInput({ ...formInput, dateStart: date })
        } else if (id === FORMDATEENDID) {
            setFormInput({ ...formInput, dateEnd: date })
        }
    };


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, index: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMTAGID: {
                setFormInput({ ...formInput, tag: value })
                break;
            }
            case FORMDESCRIPTIONCID: {
                setFormInput({ ...formInput, desc: value })
                break;
            }

            //deprecated here
            case FORMDATESTARTID: {
                setFormInput({ ...formInput, dateStart: value })
                break;
            }

            //deprecated here
            case FORMDATEENDID: {
                setFormInput({ ...formInput, dateEnd: value })
                break;
            }

            case FORMCONTENTID + index: {
                let contentInputAux = [...formInput.content];
                contentInputAux[index] = value;
                setFormInput({ ...formInput, content: contentInputAux })
                break;
            }
            case FORMLEGENDID + index: {
                let lmao: GalleryFileEditEvent[] = [...galleryArray]
                lmao[index].legend.text = value
                if (lmao[index].status !== -1) {
                    lmao[index].status = 2
                }
                setGalleryArray(lmao)
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;
        let upd_tagValid = formValid.tagValid;
        let upd_descValid = formValid.descValid;
        let upd_dateStartValid = formValid.dateStartValid;
        let upd_dateEndValid = formValid.dateEndValid;
        let upd_contentValid = formValid.contentValid;


        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageEvents.add.titleAccept;
        }

        /*TAG VALIDATION*/
        if (formInput.tag === '') {
            upd_tagValid = false;
            fieldValidationErrors.tagError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_tagValid = true;
            fieldValidationErrors.tagError = props.appLanguage.backoffice.manageEvents.add.tagAccept;
        }

        /*DESCRIPTION VALIDATION*/
        /*
        if (formInput.desc === '') {
            upd_descValid = false;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageEvents.add.requiredError;
        } else {
            upd_descValid = true;
            fieldValidationErrors.descError = props.appLanguage.backoffice.manageEvents.add.descAccept;
        }
        */


        /*DATE VALIDATION*/
        //const now = new Date();
        //const nowDate = startOfDay(now);
        const startDate = startOfDay(formInput.dateStart);
        const endDate = startOfDay(formInput.dateEnd);


        /*DATE START VALIDATION*/
        upd_dateStartValid = true;
        fieldValidationErrors.dateStartError = props.appLanguage.backoffice.manageEvents.add.dateAccept;

        /*DATE END VALIDATION*/
        if (endDate < startDate) {
            upd_dateEndValid = false;
            fieldValidationErrors.dateEndError = props.appLanguage.backoffice.manageEvents.add.dateError;
        } else {
            upd_dateEndValid = true;
            fieldValidationErrors.dateEndError = props.appLanguage.backoffice.manageEvents.add.dateAccept;
        }



        /*CONTENT VALIDATION*/
        for (let i = 0; i < formInput.content.length; i++) {
            if (formInput.content[i] === '') {
                upd_contentValid[i] = false;
                fieldValidationErrors.contentError[i] = props.appLanguage.backoffice.manageEvents.add.requiredError;
            } else {
                upd_contentValid[i] = true;
                fieldValidationErrors.contentError[i] = props.appLanguage.backoffice.manageEvents.add.contentAccept;
            }
        }
        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid, tagValid: upd_tagValid, descValid: upd_descValid, dateStartValid: upd_dateStartValid, dateEndValid: upd_dateEndValid, contentValid: upd_contentValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])




    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        let contentValidation = true;
        for (let i = 0; i < formValid.contentValid.length; i++) {
            if (!formValid.contentValid[i]) {
                contentValidation = false;
                break;
            }
        }

        setFormSubmitValid({ main: formValid.titleValid && formValid.tagValid && formValid.dateStartValid && formValid.dateEndValid, article: contentValidation })
    }, [formValid])

    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, index: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMTAGID: {
                setFormFocus({ ...formFocus, tagFocus: true })
                break;
            }
            case FORMDESCRIPTIONCID: {
                setFormFocus({ ...formFocus, descFocus: true })
                break;
            }
            case FORMDATESTARTID: {
                setFormFocus({ ...formFocus, dateStartFocus: true })
                break;
            }
            case FORMDATEENDID: {
                setFormFocus({ ...formFocus, dateEndFocus: true })
                break;
            }
            case FORMCONTENTID + index: {
                let contentFocusAux = [...formFocus.contentFocus];
                contentFocusAux[index] = true;
                setFormFocus({ ...formFocus, contentFocus: contentFocusAux })
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)

        /*
        console.log(formInput.title)
        console.log(formInput.tag)
        console.log(formInput.desc)
        console.log(formInput.dateStart)
        console.log(formInput.dateEnd)
        console.log(attachArray.length)
        console.log(galleryArray.length)
        console.log(formInput.content)
        console.log(eventImage)
        console.log(selectedImage)



        for (let i = 0; i < attachArray.length; i++) {
            console.log(attachArray[i].name + " --- " + attachArray[i].status)

        }

        for (let i = 0; i < galleryArray.length; i++) {
            let fileExt = galleryArray[i].name.split('.').pop();
            console.log(galleryArray[i].name + " " + fileExt + " legend: " + galleryArray[i].legend.text + " --- " + galleryArray[i].status)
        }

        console.log(logContent)
        */
        
        httpPutEvent(
            {
                title: formInput.title,
                tag: formInput.tag,
                desc: formInput.desc,
                date: getTime(formInput.dateStart),
                dateEnd: getTime(formInput.dateEnd)
            }, props.event.eventId
        ).then(
            async res => {
                let orderCounter = 0;

                const eventID = res.data.id;

                if (eventImage !== null) {
                    let fileExt = eventImage.name.split('.').pop();
                    await httpPutEventImage({ img: eventImage, ext: fileExt }, eventID).then(
                        res => {
                        }
                    ).catch(
                        err => {
                        }
                    )

                } else if (eventImage === null && selectedImage.from === -2) {
                    await httpDeleteEventImage(eventID).then(
                        res => {
                        }
                    ).catch(
                        err => {
                        }
                    )
                }

                let galleryPosAuxToDelete: boolean[] = []
                for (let i = 0; i < galleryArray.length; i++) {
                    galleryPosAuxToDelete.push(false)
                }

                for (let o = 0; o < logContent.length; o++) {
                    await httpDeleteEventContent(logContent[o].eventId, logContent[o].order.toString()).then(
                        async res => {

                            if (o === logContent.length - 1) {
                                if (formInput.content.length > 0) {
                                    for (let i = 0; i < formInput.content.length; i++) {
                                        await httpPutEventContent({ content: formInput.content[i] }, eventID, orderCounter.toString()).then(
                                            async res => {

                                                orderCounter++
                                                if (galleryArray.length > 0) {
                                                    for (let x = 0; x < galleryArray.length; x++) {
                                                        if (galleryArray[x].articleIndex === i) {
                                                            galleryPosAuxToDelete[x] = true;
                                                            if (galleryArray[x].status === 0) {

                                                                await httpDeleteEventGallery(galleryArray[x].id).then(
                                                                    async res => {
                                                                    }
                                                                )

                                                            } else if (galleryArray[x].status === 1) {
                                                                await httpPutEventGallery({ img: null, ext: null, desc: "" }, galleryArray[x].id, orderCounter.toString()).then(
                                                                    res => {
                                                                        orderCounter++
                                                                    }
                                                                ).catch(
                                                                    err => {
                                                                    }
                                                                )
                                                            } else if (galleryArray[x].status === -1) {
                                                                let fileExt = galleryArray[x].name.split('.').pop();
                                                                await httpPostEventGallery({ img: galleryArray[x].content, ext: fileExt, desc: galleryArray[x].legend.text }, eventID, orderCounter.toString()).then(
                                                                    res => {

                                                                        orderCounter++
                                                                    }
                                                                ).catch(
                                                                    err => {
                                                                    }
                                                                )
                                                            } else if (galleryArray[x].status === 2) {
                                                                let toWrite = " "
                                                                if (galleryArray[x].legend.text !== "") {
                                                                    toWrite = galleryArray[x].legend.text
                                                                }
                                                                await httpPutEventGallery({ img: null, ext: null, desc: toWrite }, galleryArray[x].id, orderCounter.toString()).then(
                                                                    res => {
                                                                        orderCounter++
                                                                    }
                                                                ).catch(
                                                                    err => {
                                                                    }
                                                                )
                                                            }
                                                        }
                                                        if (i === formInput.content.length - 1 && x === galleryArray.length - 1) {
                                                            for (let z = 0; z < galleryPosAuxToDelete.length; z++) {
                                                                if (!galleryPosAuxToDelete[z]) {
                                                                    await httpDeleteEventGallery(galleryArray[z].id).then(
                                                                        async res => {
                                                                        }
                                                                    )
                                                                }

                                                            }

                                                        }
                                                    }
                                                }

                                            }

                                        )



                                    }



                                }


                                if (attachArray.length > 0) {
                                    for (let i = 0; i < attachArray.length; i++) {
                                        if (attachArray[i].status === 0) {
                                            await httpDeleteEventAttach(attachArray[i].id).then(
                                                res => {
                                                }
                                            )
                                        } else if (attachArray[i].status === -1) {
                                            await httpPostEventAttach({ content: attachArray[i].content, name: attachArray[i].name }, eventID).then(
                                                res => {
                                                }
                                            )
                                        }
                                    }

                                }



                                closeModalHandler(GeneralServices.constants.child.worked);

                            }





                        }

                    )
                }



            }
        ).catch(
            err => {
                closeModalHandler(GeneralServices.constants.child.failed);

            }
        )

        closeModalHandler(GeneralServices.constants.child.closed);
        
    }



    const closeModalHandler = (feedback: string) => {
        formEndup()
        modalRef.current.classList.remove("editEvent-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
        setSelectedStepper("0");
        props.callback(feedback)

    }

    const onClickFile = () => {
        refSelectedImage.current.click();
    }

    const onFileSelected = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.files[0]) {
            setEventImage(event.target.files[0])
            let reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage({ content: reader.result, from: 1 });
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }


    const handlerCreateArticle = (index: any, where: number) => {
        //where 0-below 1-above


        const numberOfArticles = formInput.content.length;
        let ilegal = false;
        for (let i = 0; i < numberOfArticles; i++) {
            if (!formValid.contentValid[i]) {
                ilegal = true;
                break;
            }
        }

        if (!ilegal) {
            let dummyError = []
            let dummyInput = []
            let dummyValid = []
            let dummyFocus = []


            if (where === 0) {
                for (let i = 0; i < numberOfArticles; i++) {
                    if (i === index + 1) {
                        dummyError.push("")
                        dummyInput.push("")
                        dummyValid.push(false)
                        dummyFocus.push(false)
                    }
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                }

                if (index + 1 === numberOfArticles) {
                    dummyError.push("")
                    dummyInput.push("")
                    dummyValid.push(false)
                    dummyFocus.push(false)
                } else {
                    addSlotArticleImages(index + 1, where)
                }

            } else if (where === 1) {

                if (index - 1 === -1) {
                    dummyError.push("")
                    dummyInput.push("")
                    dummyValid.push(false)
                    dummyFocus.push(false)
                }
                for (let i = 0; i < numberOfArticles; i++) {
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                    if (i === index - 1) {
                        dummyError.push("")
                        dummyInput.push("")
                        dummyValid.push(false)
                        dummyFocus.push(false)
                    }
                }

                addSlotArticleImages(index - 1, where)

            }




            setFormError({ ...formError, contentError: dummyError })
            setFormInput({ ...formInput, content: dummyInput })
            setFormValid({ ...formValid, contentValid: dummyValid })
            setFormFocus({ ...formFocus, contentFocus: dummyFocus })

            rebootGalleryDropRef()



            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.yesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.cantAdd)

        }
    }


    const handlerDeleteArticle = (index: any) => {

        if (formInput.content.length > 1) {
            let dummyError = []
            let dummyInput = []
            let dummyValid = []
            let dummyFocus = []

            const numberOfArticles = formInput.content.length;

            for (let i = 0; i < numberOfArticles; i++) {
                if (i !== index) {
                    dummyError.push(formError.contentError[i])
                    dummyInput.push(formInput.content[i])
                    dummyValid.push(formValid.contentValid[i])
                    dummyFocus.push(formFocus.contentFocus[i])
                }
            }
            setFormError({ ...formError, contentError: dummyError })
            setFormInput({ ...formInput, content: dummyInput })
            setFormValid({ ...formValid, contentValid: dummyValid })
            setFormFocus({ ...formFocus, contentFocus: dummyFocus })
            removeArticleImages(index)
            rebootGalleryDropRef()
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.yesRmv)
        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.add.information.article.cantRmv)
        }
    }



    const checkArticleHasImages = (index: number) => {
        for (let i = 0; i < galleryArray.length; i++) {
            if (galleryArray[i].status !== 0 && galleryArray[i].articleIndex === index) {
                return true;
            }
        }
        return false;
    }
    const removeArticleImages = (index: number) => {
        let dummyGalleryArray: GalleryFileEditEvent[] = []
        for (let i = 0; i < galleryArray.length; i++) {
            if (galleryArray[i].articleIndex > index) {
                dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex - 1 })
            } else if (galleryArray[i].articleIndex < index) {
                dummyGalleryArray.push({ ...galleryArray[i] })
            } else if (galleryArray[i].status !== -1) {
                dummyGalleryArray.push({ ...galleryArray[i], status: 0 })
            }
        }
        setGalleryArray([...dummyGalleryArray])
    }

    const addSlotArticleImages = (index: number, where: number) => {
        let dummyGalleryArray: GalleryFileEditEvent[] = []
        for (let i = 0; i < galleryArray.length; i++) {
            if (where === 0) {
                if (galleryArray[i].articleIndex >= index) {
                    dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex + 1 })
                } else if (galleryArray[i].articleIndex < index) {
                    dummyGalleryArray.push({ ...galleryArray[i] })
                }
            } else if (where === 1) {
                if (galleryArray[i].articleIndex > index) {
                    dummyGalleryArray.push({ ...galleryArray[i], articleIndex: galleryArray[i].articleIndex + 1 })
                } else if (galleryArray[i].articleIndex <= index) {
                    dummyGalleryArray.push({ ...galleryArray[i] })
                }
            }

        }
        setGalleryArray([...dummyGalleryArray])
    }


    const checkHasAttach = () => {
        for (let i = 0; i < attachArray.length; i++) {
            if (attachArray[i].status !== 0) {
                return true;
            }
        }
        return false;
    }

    const galleryBoxFunction = (index: number) => {
        let galleryBox = "edit-event-article-gallery "
        if (galleryDrag[index]) {
            galleryBox = galleryBox.concat("droppin")
        }
        return galleryBox;
    }


    //Clear all listeners of each article
    const rebootGalleryDropRef = () => {
        for (let i = 0; i < galleryDropRef.current.length; i++) {
            let galleryListener = galleryDropRef.current[i];
            if (galleryListener !== null) {
                galleryListener.removeEventListener('dragenter', handleDragInGallery)
                galleryListener.removeEventListener('dragleave', handleDragOutGallery)
                galleryListener.removeEventListener('dragover', handleDragGallery)
                galleryListener.removeEventListener('drop', handleDropGallery)
            }
        }
        galleryDropRef.current = []
    }

    //Re-insert all listeners to each article
    useEffect(() => {
        if (galleryDropRef.current.length > 0) {
            for (let i = 0; i < galleryDropRef.current.length; i++) {
                let galleryListener = galleryDropRef.current[i];
                if (galleryListener !== null) {
                    galleryListener.addEventListener('dragenter', (event: any) => handleDragInGallery(event, i))
                    galleryListener.addEventListener('dragleave', (event: any) => handleDragOutGallery(event, i))
                    galleryListener.addEventListener('dragover', (event: any) => handleDragGallery(event, i))
                    galleryListener.addEventListener('drop', (event: any) => handleDropGallery(event, i))
                }
            }
        }
    }, [galleryDropRef.current, selectedStepper])


    const galleryFunction = (articleIndex: number) => {
        if (galleryArray.length > 0) {
            return <div>
                {
                    (galleryArray.map((file: GalleryFileEditEvent, index: number) =>
                        file.articleIndex === articleIndex && file.status !== 0 ?
                            <div key={index} className="article-gallery-object">
                                <div className="article-gallery-object-main">

                                    <div className="article-gallery-object-main-preview">
                                        <img className="image" src={file.preview} alt={'eventGallery' + index}></img>
                                    </div>

                                    <div className="article-gallery-object-main-text">
                                        <span>{file.name}</span>
                                        <textarea className="text-legend" id={FORMLEGENDID + index} placeholder={props.appLanguage.backoffice.manageEvents.add.legendph}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            maxLength={100}
                                            value={file.legend.text || ""}

                                        />
                                    </div>

                                </div>

                                <div className="article-gallery-object-menu">
                                    <div className="article-gallery-object-menu-option">
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.fileOption[1]} direction="top">
                                            <div className="kxicon" onClick={() => handleDeleteGallery(index)} />
                                        </TooltipKekax>
                                    </div>
                                </div>
                            </div>
                            : null
                    ))
                }
            </div>
        }
        return null
    }



    let attachBox = "edit-event-attach "
    if (attachDrag) {
        attachBox = attachBox.concat("droppin")
    }
    const attachFunction = () => {
        if (attachArray.length > 0) {
            return <div>
                {
                    (attachArray.map((file: any, index) =>
                        file.status !== 0 ?
                            <div key={index} className="event-attach-object">
                                <div className="event-attach-object-main">
                                    <span className="attach-name">{file.name}</span>
                                </div>
                                <div className="event-attach-object-menu">
                                    <div className="event-attach-object-menu-option">
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.fileOption[2]} direction="top">
                                            <div className="kxicon" onClick={() => handleDeleteAttach(index)} />
                                        </TooltipKekax>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    ))
                }
            </div>
        }
        return null
    }


    const validatorFeedback = (formId: any, index: any) => {
        let searchKey = formId;
        if (index !== -1) {
            searchKey = formId + index;
        }

        switch (searchKey) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{formError.titleError}</span>
                        : null
            }
            case FORMTAGID: {
                return formFocus.tagFocus && !formValid.tagValid ?
                    <span className="input-invalid-feedback-kekax">{formError.tagError}</span>
                    : formValid.tagValid ?
                        <span className="input-valid-feedback-kekax">{formError.tagError}</span>
                        : null
            }
            case FORMDESCRIPTIONCID: {
                return formFocus.descFocus && !formValid.descValid ?
                    <span className="input-invalid-feedback-kekax">{formError.descError}</span>
                    : formValid.descValid ?
                        <span className="input-valid-feedback-kekax">{formError.descError}</span>
                        : null
            }
            case FORMDATESTARTID: {
                return !formValid.dateStartValid ?
                    <span className="input-invalid-feedback-kekax">{formError.dateStartError}</span>
                    : formValid.dateStartValid ?
                        <span className="input-valid-feedback-kekax">{formError.dateStartError}</span>
                        : null
            }
            case FORMDATEENDID: {
                return !formValid.dateEndValid ?
                    <span className="input-invalid-feedback-kekax">{formError.dateEndError}</span>
                    : formValid.dateEndValid ?
                        <span className="input-valid-feedback-kekax">{formError.dateEndError}</span>
                        : null
            }
            case FORMCONTENTID + index: {
                return formFocus.contentFocus[index] && !formValid.contentValid[index] ?
                    <span className="input-invalid-feedback-kekax">{formError.contentError[index]}</span>
                    : formValid.contentValid[index] ?
                        <span className="input-valid-feedback-kekax">{formError.contentError[index]}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }


    useEffect(() => {

        if (selectedStepper === "2") {
            let attachListener = attachDropRef.current;
            if (attachListener !== null) {
                attachListener.addEventListener('dragenter', handleDragInAttach)
                attachListener.addEventListener('dragleave', handleDragOutAttach)
                attachListener.addEventListener('dragover', handleDragAttach)
                attachListener.addEventListener('drop', handleDropAttach)
            } else {
                attachListener.removeEventListener('dragenter', handleDragInAttach)
                attachListener.removeEventListener('dragleave', handleDragOutAttach)
                attachListener.removeEventListener('dragover', handleDragAttach)
                attachListener.removeEventListener('drop', handleDropAttach)
            }
        }

    }, [selectedStepper])


    const removePhotoHandler = () => {
        if (eventImage !== null && selectedImage.from === 1) {
            setEventImage(null)
            setSelectedImage({ content: dummyEvents, from: -1 })
        } else if (eventImage === null && selectedImage.from === 2) {
            setSelectedImage({ content: dummyEvents, from: -2 })

        }
    }



    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div className="container-edit-event-main">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageEvents.add.information.main}</span></div>

                    <div className="main-row">
                        <div className="input-calendar">
                            <label className="label">
                                {props.appLanguage.backoffice.manageEvents.add.dateStart}
                            </label>
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="input-calendar-datepicker"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id={FORMDATESTARTID}
                                        value={formInput.dateStart || ""}
                                        onChange={(date: any, value: any) => changeDateHandler(date, value, FORMDATESTARTID)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage={props.appLanguage.backoffice.manageEvents.add.dateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDATESTARTID, -1)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-calendar">
                            <label className="label">
                                {props.appLanguage.backoffice.manageEvents.add.dateEnd}
                            </label>
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className="input-calendar-datepicker"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id={FORMDATEENDID}
                                        value={formInput.dateEnd || ""}
                                        onChange={(date: any, value: any) => changeDateHandler(date, value, FORMDATEENDID)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage={props.appLanguage.backoffice.manageEvents.add.dateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDATEENDID, -1)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-tag">
                            <label className="label">
                                {props.appLanguage.backoffice.manageEvents.add.tag} *
                            </label>
                            <select className="input-tag-select kekax-input-box"
                                id={FORMTAGID}
                                onChange={(event: any) => changeInputHandler(event, null)}
                                onFocus={(event: any) => validateFocusFields(event, null)}
                                value={formInput.tag || ""}

                            >
                                <option hidden>{props.appLanguage.backoffice.manageEvents.add.tagph}</option>
                                <option value={GeneralServices.event.tag.general}>{props.appLanguage.event.tag[0]}</option>
                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMTAGID, -1)
                                }
                            </div>
                        </div>
                    </div>


                    <div className="main-row">
                        <div className="input-title">
                            <label className="label">
                                {props.appLanguage.backoffice.manageEvents.add.title} *

                            </label>
                            <input className="input-title-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageEvents.add.titleph}
                                id={FORMTITLEID}
                                onChange={(event: any) => changeInputHandler(event, null)}
                                onFocus={(event: any) => validateFocusFields(event, null)}
                                value={formInput.title || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMTITLEID, -1)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row">
                        <div className="input-description">
                            <label className="label">
                                {props.appLanguage.backoffice.manageEvents.add.desc}

                            </label>
                            <input className="input-description-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageEvents.add.descph}
                                id={FORMDESCRIPTIONCID}
                                onChange={(event: any) => changeInputHandler(event, null)}
                                onFocus={(event: any) => validateFocusFields(event, null)}
                                value={formInput.desc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMDESCRIPTIONCID, -1)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="edit-event-image">

                        <div className="edit-event-image-preview">
                            <img className="image" src={selectedImage.content} alt='eventImg'></img>
                            <div className="image-options" >
                                <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.imageOptions[0]} direction="right">
                                    <button type="button" className="image-change-fab" onClick={onClickFile}><div className="change" /></button >
                                </TooltipKekax>
                                <input ref={refSelectedImage} hidden type="file" accept='image/*' onChange={onFileSelected} />
                                <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.imageOptions[1]} direction="right">
                                    <button type="button" className={(selectedImage.from === 1 && eventImage !== null) || (selectedImage.from === 2 && eventImage === null) ? "image-delete-fab" : "image-delete-fab image-hidden-fab"} onClick={removePhotoHandler}><div className="delete" /></button >
                                </TooltipKekax>
                            </div>
                        </div>

                        <div className="edit-event-image-content">
                            <span className="text-one">{props.appLanguage.backoffice.manageEvents.add.imageph[0]}</span>
                            <span className="text-two">{props.appLanguage.backoffice.manageEvents.add.imageph[1]}</span>

                        </div>
                    </div>
                </div>
            )
        } else if (selectedStepper === "1") {
            return (
                formInput.content.map((content: any, index: number) => (
                    <div className="container-edit-event-article" key={"content" + index}>
                        <div className="container-title">
                            <div className="title"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.title}</span></div>
                            <div className="options">
                                <TooltipInfo content={props.appLanguage.backoffice.manageEvents.add.information.article.info} direction="bottom">
                                    <div className="opt-info">
                                        <div className="icon"></div>
                                    </div>
                                </TooltipInfo>
                                <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.addDown} direction="bottom">
                                    <div className="opt-addDown" onClick={() => handlerCreateArticle(index, 0)}>
                                        <div className="icon"></div>
                                    </div>
                                </TooltipKekax>
                                <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.addUp} direction="bottom">
                                    <div className="opt-addUp" onClick={() => handlerCreateArticle(index, 1)}>
                                        <div className="icon"></div>
                                    </div>
                                </TooltipKekax>
                                {
                                    formInput.content.length > 1 ?
                                        <TooltipKekax content={props.appLanguage.backoffice.manageEvents.add.information.article.delete} direction="bottom">
                                            <div className="opt-rmv" onClick={() => handlerDeleteArticle(index)}>
                                                <div className="icon"></div>
                                            </div>
                                        </TooltipKekax>
                                        : null
                                }

                            </div>
                        </div>
                        <span className="label">{props.appLanguage.backoffice.manageEvents.add.content} *
                        </span>
                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMCONTENTID, index)

                            }
                        </div>

                        <div className="edit-event-article-label">
                            <div className="side-label"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.input}</span></div>
                            <div className="side-label"><span>{props.appLanguage.backoffice.manageEvents.add.information.article.preview}</span></div>
                        </div>
                        <div className="edit-event-article-text">
                            <div className="edit-event-article-text-input">
                                <textarea required className="article-text" id={FORMCONTENTID + index} placeholder={props.appLanguage.backoffice.manageEvents.add.contentph}
                                    onChange={(event: any) => changeInputHandler(event, index)}
                                    onFocus={(event: any) => validateFocusFields(event, index)}
                                    value={content || ""}
                                />
                            </div>
                            <div className="edit-event-article-text-preview">
                                <ReactMarkdown className="article-preview" source={content + ""} escapeHtml={false} />
                            </div>
                        </div>

                        <div className={galleryBoxFunction(index)} ref={el => galleryDropRef.current[index] = el}
                        >
                            {
                                galleryFunction(index)
                            }
                            {
                                galleryDrag[index] ?
                                    <div className="absolute-message">
                                        <span>{props.appLanguage.backoffice.manageEvents.add.dragDropGallery[1]}</span>
                                    </div>
                                    : !checkArticleHasImages(index) && !galleryDrag[index] ?
                                        <div className="absolute-message">
                                            <span>{props.appLanguage.backoffice.manageEvents.add.dragDropGallery[0]}</span>
                                        </div>
                                        : null
                            }
                        </div>

                    </div>
                ))
            )
        } else if (selectedStepper === "2") {
            return (
                <div className="container-edit-event-attach">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageEvents.add.information.attachments}</span></div>
                    <div className={attachBox} ref={attachDropRef}>
                        {
                            attachFunction()
                        }

                        {
                            attachDrag ?
                                <div className="absolute-message">
                                    <span>{props.appLanguage.backoffice.manageEvents.add.dragDropAttach[1]}</span>
                                </div>
                                : !checkHasAttach() && !attachDrag ?
                                    <div className="absolute-message">
                                        <span>{props.appLanguage.backoffice.manageEvents.add.dragDropAttach[0]}</span>
                                    </div>
                                    : null
                        }
                    </div>
                </div>
            )
        }
    }


    const printNextButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.main} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageEvents.edit.next}</button></div>
                )
            }
            case 1: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.article} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageEvents.edit.next}</button></div>
                )
            }
            default: {
                return null;
            }
        }
    }

    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        if (count === 2) {
            return <div className="submit"><button type="submit" disabled={!formSubmitValid.main || !formSubmitValid.article || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageEvents.edit.submit} </button></div>
        } else {
            return null
        }
    }



    return (
        <React.Fragment>
            <div className="wrapper-edit-event" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <form className="content" ref={contentRef} id='edit-event-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageEvents.edit.title}</span>
                        <div className="close"><button type="button" className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}></button></div>
                    </div>
                    <div className="body">
                        <div className="container-stepper">
                            <StepperKekax active={selectedStepper}>
                                <div key="0">{props.appLanguage.backoffice.manageEvents.edit.steps[0]}</div>
                                <div key="1">{props.appLanguage.backoffice.manageEvents.edit.steps[1]}</div>
                                <div key="2">{props.appLanguage.backoffice.manageEvents.edit.steps[2]}</div>
                            </StepperKekax>
                        </div>
                        <div className="container-stepper-body">
                            <div className="container-form-edit-event">

                                {
                                    printActiveContent()
                                }


                            </div>
                        </div>

                    </div>
                    <div className="footer">
                        <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageEvents.edit.cancel}</span></button></div>
                        <div className="previous"><button type="button" className="previous-btn" disabled={selectedStepper === "0" ? true : false}  onClick={() => changeStepperHandler("PREVIOUS")}><span className="info">{props.appLanguage.backoffice.manageEvents.edit.previous}</span></button></div>
                        {

                            printNextButton()
                        }
                        {
                            printSubmitButton()

                        }



                    </div>
                </form>
            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}

/*onClick={(event: any) => editEventHandler(event)}*/
/*onChange={(active: any) => changeStepperSelectedHandler(active)}*/

export default connect(mapStateToProps)(EditEventModal);
