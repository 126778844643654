import React, { useEffect, useRef, useState } from 'react';

import './ProjectBackground.css'
import { connect } from "react-redux";
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';


interface IProps extends StoreProps { }

export interface ISlide {
    title: string
    info: string[][]
}

interface IOffsets {
    x: number
    margin: number
}


export const ProjectBackground: React.FC<IProps> = (props) => {

    const slideExampleRef: any = useRef([]);


    const [offsets, setOffsets] = useState<IOffsets[]>([])
    const myOffsetRef = React.useRef<any>(null);
    const setTheOffeset = (data: any) => {
        myOffsetRef.current = data;
        setOffsets(data);
    };

    const [counter, setCounter] = useState(0)
    const myCounterRef = React.useRef(counter);
    const setTheCounter = (data: any) => {
        myCounterRef.current = data;
        setCounter(data);
    };

    useEffect(() => {
        let copyOfOffsets: IOffsets[] = []
        if (slideExampleRef.current !== null) {

            for (let i = 0; i < slideExampleRef.current.length; i++) {
                copyOfOffsets.push({ x: 0, margin: 0 })
            }
        }
        setTheOffeset([...copyOfOffsets])

        window.addEventListener('resize', resizeStructure);
        return () => {
            window.removeEventListener('resize', resizeStructure);
        }

    }, [])


    const resizeStructure = () => {
        /*
        setTheCounter(0)
        for (let i = 0; i < slideExampleRef.current.length; i++) {
            slideExampleRef.current[i].style.transform = "translateX(calc(" + 0 + "px + " + 0 + "vmax))"
        }
        let copyOfOffsets: IOffsets[] = []
        for (let i = 0; i < slideExampleRef.current.length; i++) {
            if (slideExampleRef.current !== null) {
                copyOfOffsets.push({ x: 0, margin: 0 })
            }
        }
        setTheOffeset([...copyOfOffsets])
   */
        if (slideExampleRef.current !== null && myCounterRef.current !== null && myOffsetRef.current !== null) {
            let copyOfOffsets: IOffsets[] = [...myOffsetRef.current]
            for (let i = 0; i < slideExampleRef.current.length; i++) {
                const offLeft = (-1) * (slideExampleRef.current[i].getBoundingClientRect().width * (myCounterRef.current));
                copyOfOffsets[i] = { x: offLeft, margin: copyOfOffsets[i].margin };
        
                console.log(copyOfOffsets)
                slideExampleRef.current[i].style.transform = "translateX(calc(" + copyOfOffsets[i].x + "px + " + copyOfOffsets[i].margin + "vmax))"
            }
            setTheOffeset([...copyOfOffsets])

        }
    }



    const handleClickMenu = (what: string) => {
        let copyCounter = counter
        if (what === "previous") {
            copyCounter--
            if (copyCounter < 0) {
                setTheCounter(0)
            } else if (copyCounter >= 0) {
                setTheCounter(copyCounter)
                let copyOfOffsets: IOffsets[] = [...offsets]
                for (let i = 0; i < slideExampleRef.current.length; i++) {
                    //const offRight = (1) * slideExampleRef.current[i].clientWidth;
                    if (slideExampleRef.current[i] !== null) {
                        const offRight = (1) * slideExampleRef.current[i].clientWidth;
                        copyOfOffsets[i] = { x: copyOfOffsets[i].x + offRight, margin: copyOfOffsets[i].margin };
                        slideExampleRef.current[i].style.transform = "translateX(calc(" + copyOfOffsets[i].x + "px + " + copyOfOffsets[i].margin + "vmax))"
                    }
                }
                setTheOffeset([...copyOfOffsets])

            }

        } else if (what === "next") {
            copyCounter++
            if (slideExampleRef.current !== null) {
                if (copyCounter === slideExampleRef.current.length) {
                    setTheCounter(slideExampleRef.current.length - 1)
                } else if (copyCounter <= slideExampleRef.current.length - 1) {
                    setTheCounter(copyCounter)
                    let copyOfOffsets: IOffsets[] = [...offsets]
                    for (let i = 0; i < slideExampleRef.current.length; i++) {
                        if (slideExampleRef.current[i] !== null) {
                            const offLeft = (-1) * slideExampleRef.current[i].clientWidth;
                            console.log(offLeft)
                            copyOfOffsets[i] = { x: copyOfOffsets[i].x + offLeft, margin: copyOfOffsets[i].margin };
                            slideExampleRef.current[i].style.transform = "translateX(calc(" + copyOfOffsets[i].x + "px + " + copyOfOffsets[i].margin + "vmax))"
                        }
                    }
                    setTheOffeset([...copyOfOffsets])

                }

            }
        }
    }



    return (
        <div className="wrapper-projectback">
            <div className="container-header">
                <div className="content">
                    <span>{props.appLanguage.projectBackground.header[0]}</span>
                </div>
            </div>

            <div className="container-carousel">
                <div className="left" >
                    {
                        counter !== 0 ?
                            <div className="icon" onClick={() => handleClickMenu("previous")}>
                            </div>
                            : null
                    }

                </div>
                <div className="slides">
                    {
                        props.appLanguage.projectBackground.slides.map((content: ISlide, index1: number) => (
                            <div className="slide" key={"slide" + index1} ref={el => slideExampleRef.current[index1] = el}>
                                {
                                    content.info.map((infoContent: string[], index2: number) => (
                                        <span className='text' key={"info" + index2}>{infoContent}</span>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="right">
                    {
                        counter !== slideExampleRef.current.length - 1 ?
                            <div className="icon" onClick={() => handleClickMenu("next")}>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>

    );


    /*
                <div className="container-menu">
                <button type="button" className="previous" onClick={() => handleClickMenu("previous")}>
                    <span>{props.appLanguage.projectBackground.menu.previous}</span>
                </button>
                <button type="button" className="next" onClick={() => handleClickMenu("next")}>
                    <span>{props.appLanguage.projectBackground.menu.next}</span>
                </button>
            </div>
    */

}
export default connect(mapStateToProps)(ProjectBackground);
