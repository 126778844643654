import React, { useEffect, useState } from "react";

import './Publications.css'
import { connect } from "react-redux";
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { httpGetListPublications } from "../../services/requestService";

interface IProps extends StoreProps { }

export interface IPublication {
    publicationId: string
    year: string
    title: string
    text1: string
    text2: string
    url: string
    order: number

}


/*
const staticPublications: PublicationObject[] = [
    {
        date: "2019", author: "Mileusnić, M, et al.  2019.", title: "Croatian geological heritage related to historical mining and quarrying.", info: "European Geologist Journal. (48):5-9.",
        ref: { href: "http://scholar.google.com/scholar?btnG=Search%2BScholar&as_q=%22Croatian%2Bgeological%2Bheritage%2Brelated%2Bto%2Bhistorical%2Bmining%2Band%2Bquarrying%22&as_sauthors=Mileusni%C4%87&as_occt=any&as_epq=&as_oq=&as_eq=&as_publication=&as_ylo=&as_yhi=&as_sdtAAP=1&as_sdtp=1", tref: "Google Scholar Link" }
    }
]
*/

export const Publications: React.FC<IProps> = (props) => {

    const [allPublications, setAllPublications] = useState<IPublication[]>([]);
    const [totalPublications, setTotalPublications] = useState<number>(0);
    const [actualPage, setActualPage] = useState<number>(0);
    const [reachedMaxPage, setReachedMaxPage] = useState<boolean>(false);

    const limitPerPage: number = 3



    useEffect(() => {


        httpGetListPublications('?limit=' + limitPerPage)
            .then(
                res => {
                    //SETTING TOTAL DOCUMENTS
                    const totalDocs = res.headers.total
                    if (totalDocs !== undefined) {
                        setTotalPublications(parseInt(totalDocs, 10));
                    } else {
                        setTotalPublications(parseInt('1', 10));
                    }
                    setAllPublications([...res.data])
                })
            .catch(
                err => {
                }
            )

    }, [])

    const handleShowMore = () => {

        const thisPage = actualPage + 1
        setActualPage(thisPage)

        httpGetListPublications('?limit=' + limitPerPage + '&page=' + thisPage)
            .then(
                res => {

                    setAllPublications([...allPublications, ...res.data])
                })
            .catch(
                err => {
                }
            )


    }

    useEffect(() => {

        if (allPublications.length !== 0 && allPublications.length === totalPublications && totalPublications !== 0) {
            setReachedMaxPage(true)
        }
    }, [allPublications])


    return (
        <div className="wrapper-publication">
            <div className="content-all">
                <div className="container-header">
                    <div className="content">
                        <span>{props.appLanguage.publications.header[0]}</span>
                    </div>
                </div>

                <div className="container-body">
                    {
                        allPublications.length > 0 ?

                            <React.Fragment>
                                {
                                    allPublications.map((content: IPublication, index: number) => (
                                        <div key={index} className="publication-object">
                                            <div className="object-flex">
                                                <span className="publication-object-title">{content.year}</span>
                                                <span className="publication-object-content">{content.text1} <b>{content.title}</b> {content.text2}</span>
                                                <a className="publication-object-link" href={content.url} target="_blank" rel="noopener noreferrer">{props.appLanguage.publications.downloadUrl}</a>                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    !reachedMaxPage ?
                                        <div className="show-more">
                                            <button className="button-show" onClick={handleShowMore}>{props.appLanguage.publications.showmore}</button>
                                        </div>
                                        : null
                                }

                            </React.Fragment>

                            :
                            <span className="no-content">{props.appLanguage.msg_feedback.no_content}</span>
                    }

                </div>
            </div>
        </div>
    );

}

export default connect(mapStateToProps)(Publications);


/*
                                                <a className="publication-object-link" href={content.ref.href} target="_blank" rel="noopener noreferrer">{content.ref.tref}</a>

                                        */