import React, { useRef, useEffect } from 'react';
import Mine from '../../../assets/icons/mine.png';
import Exam from '../../../assets/icons/exam.png';
import Save from '../../../assets/icons/save.png';
import GeneralServices from '../../../services/generalService.json'

import { connect } from 'react-redux';

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import "./Perks.css"


interface IProps extends StoreProps {
    myRef: any;
    triggerAnime: any;
    routerHistory: any
}


export const Perks: React.FC<IProps> = (props) => {


    const myref = useRef<any>(null);

    useEffect(() => {
        props.myRef(myref.current);
    }, [])
    useEffect(() => {
        //console.log(props.triggerAnime)
    }, [props.triggerAnime])


    return (
        <div ref={myref} className="wrapper-perks">
            {
                props.triggerAnime ?
                    <div className="container-perks">

                        <div className="container-perk-object" onClick={()=>props.routerHistory.push(GeneralServices.mineMapPath)}>
                            <div className="perk-object-image">
                                <img src={Mine} alt="imgPerk" />
                            </div>
                            <div className="perk-object-text">
                                <h4 className="perk-object-text-title">{props.appLanguage.perks[1].title}</h4>
                                <p className="perk-object-text-desc">{props.appLanguage.perks[1].description}</p>
                            </div>
                        </div>

                        <div className="container-perk-object"  onClick={()=>props.routerHistory.push(GeneralServices.gamePagePath)}>
                            <div className="perk-object-image">
                                <img src={Exam} alt="imgPerk" />
                            </div>
                            <div className="perk-object-text">
                                <h4 className="perk-object-text-title">{props.appLanguage.perks[2].title}</h4>
                                <p className="perk-object-text-desc">{props.appLanguage.perks[2].description}</p>
                            </div>
                        </div>

                        
                        <a className="container-perk-object" href={"https://facebook.com/MineHeritage.eu/"} target="_blank" rel="noopener noreferrer">
                            <div className="perk-object-image">
                                <img src={Save} alt="imgPerk" />
                            </div>
                            <div className="perk-object-text">
                                <h4 className="perk-object-text-title">{props.appLanguage.perks[3].title}</h4>
                                <p className="perk-object-text-desc">{props.appLanguage.perks[3].description}</p>
                            </div>
                        </a >

                    </div >
                    : null

            }
        </div >

    );
}


export default connect(mapStateToProps)(Perks)