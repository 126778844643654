import React, { useState, useEffect, useRef } from 'react'
import "./ListLinkStats.css"
import { httpGetListLinks, } from '../../../../services/requestService'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import GeneralServices from '../../../../services/generalService.json'


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DeleteLinkModal, { IDeleteLink } from '../../linkManage/deleteLinkModal/DeleteLinkModal';
import EditLinkModal, { IEditLink } from '../../linkManage/editLinkModal/EditLinkModal';
import SnackKekax from '../../../kekax/SnackKekax';
import { ILink } from '../../../links/Links';

interface IProps extends StoreProps {

}



export const ListLinkStats: React.FC<IProps> = (props) => {

    const [linkArray, setLinkArray] = useState<ILink[]>([]);
    const [totalLink, setTotalLink] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const [deleteModal, setDeleteModal] = useState<IDeleteLink>({ open: false, link: '', linkId: '' });
    const [editModal, setEditModal] = useState<IEditLink>({ open: false, linkId: "", linkName: "" });//KEKAXEDIT - false

    const maxLinkArray: number = 10;
    const maxPagesArray: number = 5;
    const snackKekaxRef: any = useRef();



    useEffect(() => {

        httpGetListLinks('?limit=' + maxLinkArray).then(
            res => {
                //SETTING TOTAL Link
                if (res.headers.total !== undefined) {
                    setTotalLink(parseInt(res.headers.total, 10));
                } else {
                    setTotalLink(parseInt('1', 10));
                }
                setLinkArray([...res.data]);
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }, [])




    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalLink / maxLinkArray);
        const extra = totalLink % maxLinkArray;

        if (extra !== 0 || totalLink === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalLink])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax,true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax,true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax,true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, scroll: boolean) => {
        const queryPage = page - 1;
        httpGetListLinks('?page=' + queryPage + '&limit=' + maxLinkArray).then(
            res => {
                setLinkArray([...res.data]);
                if (scroll) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }

            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }



    const deleteLinkHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageLinks.delete.success)
            setTotalLink((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageLinks.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal({ ...deleteModal, open: false });
        } else {
            setDeleteModal({ ...deleteModal, open: !deleteModal.open });
        }
        loadNewPage(counter,false)

    }


    const editLinkHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setEditModal({ ...editModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageLinks.edit.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageLinks.edit.failed)
            setEditModal({ ...editModal, open: false });
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal({ ...editModal, open: false });
        } else {
            setEditModal({ ...editModal, open: !editModal.open });
        }
        loadNewPage(counter,false)

    }

    return (
        <React.Fragment>
            <div className="wrapper-list-link-stats">
                <div className="list-link-stats-header">

                </div>
                <div className="list-link-stats-body">
                    {
                        linkArray.map((link: ILink, index: number) => (
                            <div className="link-stats-object" key={index}>
                                <div className="link-stats-object-union">
                                    <div className="link-stats-object-content">
                                        <span className="link-object-title">{link.title}</span>
                                    </div>

                                    <div className="link-stats-object-menu">
                                        <div className="object-menu-opt opt-delete" onClick={() => setDeleteModal({ open: true, link: link.title, linkId: link.linkId })}>
                                            <DeleteIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageLinks.delete.option}</span>
                                        </div>
                                        <div className="object-menu-opt opt-edit" onClick={() => setEditModal({ open: true, linkId: link.linkId, linkName: link.title })} >
                                            <EditIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageLinks.edit.option}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
                <div className="list-link-stats-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>
            </div>

            <DeleteLinkModal link={deleteModal} callback={deleteLinkHandler} />
            <EditLinkModal link={editModal} callback={editLinkHandler} />
            <SnackKekax ref={snackKekaxRef} />


        </React.Fragment>
    );

}
/*
<EditLinkModal link={editModal} callback={editLinkHandler} />
onClick={() => setEditModal({ open: true, linkId: link.id })}
*/
export default connect(mapStateToProps)(ListLinkStats);

