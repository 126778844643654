import React, { useState, useEffect, useRef } from 'react'
import "./ListConsortiumStats.css"
import { httpGetListConsortiums, } from '../../../../services/requestService'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import GeneralServices from '../../../../services/generalService.json'


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DeleteConsortiumModal, { IDeleteConsortium } from '../../consortiumManage/deleteConsortiumModal/DeleteConsortiumModal';
import EditConsortiumModal, { IEditConsortium } from '../../consortiumManage/editConsortiumModal/EditConsortiumModal';
import SnackKekax from '../../../kekax/SnackKekax';
import { IConsortium } from '../../../consortium/Consortium';

interface IProps extends StoreProps {

}



export const ListConsortiumStats: React.FC<IProps> = (props) => {

    const [consortiumArray, setConsortiumArray] = useState<IConsortium[]>([]);
    const [totalConsortium, setTotalConsortiums] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const [deleteModal, setDeleteModal] = useState<IDeleteConsortium>({ open: false, consortium: '', consortiumId: '' });
    const [editModal, setEditModal] = useState<IEditConsortium>({ open: false, consortiumId: "", consortiumName: "" });//KEKAXEDIT - false

    const maxConsortiumArray: number = 10;
    const maxPagesArray: number = 5;
    const snackKekaxRef: any = useRef();



    useEffect(() => {
        httpGetListConsortiums('?limit='+maxConsortiumArray)
            .then(
                res => {
                    //SETTING TOTAL CONSORTIUMS
                    const totalDocs = res.headers.total
                    if (totalDocs !== undefined) {
                        setTotalConsortiums(parseInt(totalDocs, 10));
                    } else {
                        setTotalConsortiums(parseInt('1', 10));
                    }

                    setConsortiumArray([...res.data])
                })
            .catch(
                err => {
                }
            )
    }, [])




    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalConsortium / maxConsortiumArray);
        const extra = totalConsortium % maxConsortiumArray;

        if (extra !== 0 || totalConsortium === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalConsortium])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax,true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax,true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax,true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, scroll: boolean) => {
        const queryPage = page - 1;
        httpGetListConsortiums('?page=' + queryPage+'&limit='+maxConsortiumArray).then(
            res => {
                setConsortiumArray([...res.data]);
                if (scroll) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }

            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }



    const deleteConsortiumHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageConsortiums.delete.success)
            setTotalConsortiums((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageConsortiums.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal({ ...deleteModal, open: false });
        } else {
            setDeleteModal({ ...deleteModal, open: !deleteModal.open });
        }
        loadNewPage(counter,false)

    }


    const editBookletHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setEditModal({ ...editModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageConsortiums.edit.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageConsortiums.edit.failed)
            setEditModal({ ...editModal, open: false });
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal({ ...editModal, open: false });
        } else {
            setEditModal({ ...editModal, open: !editModal.open });
        }
        loadNewPage(counter,false)

    }

    return (
        <React.Fragment>
            <div className="wrapper-list-consortium-stats">
                <div className="list-consortium-stats-header">

                </div>
                <div className="list-consortium-stats-body">
                    {
                        consortiumArray.map((consortium: IConsortium, index: number) => (
                            <div className="consortium-stats-object" key={index}>
                                <div className="consortium-stats-object-union">
                                    <div className="consortium-stats-object-content">
                                        <span className="consortium-object-title">{consortium.title}</span>
                                    </div>
                                    <div className="consortium-stats-object-menu">
                                        <div className="object-menu-opt opt-delete" onClick={() => setDeleteModal({ open: true, consortium: consortium.title, consortiumId: consortium.id })}>
                                            <DeleteIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageConsortiums.delete.option}</span>
                                        </div>
                                        <div className="object-menu-opt opt-edit" onClick={() => setEditModal({ open: true, consortiumId: consortium.id, consortiumName: consortium.title })} >
                                            <EditIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageConsortiums.edit.option}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
                <div className="list-consortium-stats-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>
            </div>

            <DeleteConsortiumModal consortium={deleteModal} callback={deleteConsortiumHandler} />
            <EditConsortiumModal consortium={editModal} callback={editBookletHandler} />
            <SnackKekax ref={snackKekaxRef} />


        </React.Fragment>
    );

}
/*
<EditBookletModal consortium={editModal} callback={editBookletHandler} />
onClick={() => setEditModal({ open: true, consortiumId: consortium.id })}
*/
export default connect(mapStateToProps)(ListConsortiumStats);

