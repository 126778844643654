import React, { useState, useEffect } from 'react'
import { StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import './EndPage.css'
import { IRound } from '../QuizPage';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends StoreProps, RouteComponentProps<any> {
    allRounds: IRound[]
    quitGame: any
}

interface IResult {
    total: number
    correct: number
    wrong: number
    empty: number
}

export const EndPage: React.FC<IProps> = (props) => {

    const [result, setResult] = useState<IResult>({ total: 0, correct: 0, wrong: 0, empty: 0 })

    useEffect(() => {
        if (props.allRounds.length > 0) {
            let finalResult: IResult = { total: 0, correct: 0, wrong: 0, empty: 0 }
            finalResult.total = props.allRounds.length
            for (let i = 0; i < props.allRounds.length; i++) {
                if (props.allRounds[i].decision === "") {
                    finalResult.empty += 1
                } else if (props.allRounds[i].decision !== props.allRounds[i].correct) {

                    finalResult.wrong += 1
                } else if (props.allRounds[i].decision === props.allRounds[i].correct) {
                    finalResult.correct += 1
                }
            }
            setResult({ ...result, ...finalResult })
        }
    }, [props.allRounds])

    const handleClickToReturn = () => {
        props.quitGame(true)
    }

    return (
        <div className="wrapper-quiz-end">
            <div className="container-quiz-end">
                <div className="msg">
                    <span>{props.appLanguage.quizPage.end.title}</span>
                </div>
                <div className="total">
                    <span className="text">{props.appLanguage.quizPage.end.categories[0]}</span>
                    <span className="score">{result.total}</span>
                </div>
                <div className="correct">
                    <span className="text">{props.appLanguage.quizPage.end.categories[1]}</span>
                    <span className="score"> {result.correct}</span>
                </div>
                <div className="wrong">
                    <span className="text">{props.appLanguage.quizPage.end.categories[2]}</span>
                    <span className="score">{result.wrong}</span>
                </div>
                <div className="empty">
                    <span className="text">{props.appLanguage.quizPage.end.categories[3]}</span>
                    <span className="score">{result.empty}</span>
                </div>
                <div className="return" onClick={handleClickToReturn}>
                    <span className="text">{props.appLanguage.quizPage.end.return}</span>
                </div>
            </div>
        </div>
    );

}

export default (EndPage);
