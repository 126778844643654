// Describing the shape of the system's slice of state
export interface SessionState {
    loggedIn: boolean;
    sessionToken: string;
    sessionUser: string;
    sessionRole: string;
    userImage: string;
    situation: number
}

export interface SessionCookies {
    sessionToken: string;
    sessionUser: string;
    sessionRole: string;
}

export interface UserImage {
    userImage: string;
}

export interface UserSituation {
    situation: number;
}
  
// Describing the different ACTION NAMES available
export const LOGOUT_SESSION = "LOGOUT_SESSION";
interface LogoutSessionAction {
    type: typeof LOGOUT_SESSION;
    data: UserSituation;
}

export const LOGIN_SESSION = "LOGIN_SESSION";
interface LoginSessionAction {
    type: typeof LOGIN_SESSION;
    data: SessionCookies;
}

export const SET_USERIMAGE = "SET_USERIMAGE";
interface ChangeUserImageAction {
    type: typeof SET_USERIMAGE;
    data: UserImage;
}
  
export type SessionActionTypes = LogoutSessionAction | LoginSessionAction | ChangeUserImageAction;
  