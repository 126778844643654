import React, { useState, useEffect, useRef } from 'react'
import "./MineManage.css"
import GeneralServices from "../../../services/generalService.json"

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';

import SnackKekax from '../../kekax/SnackKekax';

import ListMineStats from './listMineStats/ListMineStats';
import AddMine from './addMine/AddMine';
import GraphMine from './graphMine/GraphMine';

import AuxCountry from './auxCountry/AuxCountry';
import AuxFacilityType from './auxFacilityType/AuxFacilityType';
import AuxCategories from './auxCategories/AuxCategories';
import AuxMineTypes from './auxMineTypes/AuxMineTypes';
import AuxSituation from './auxSituation/AuxSituation';
import AuxOfficialLanguages from './auxOfficialLanguages/AuxOfficialLanguages';


interface IProps extends StoreProps {

}

interface SnackKekax {
    open: boolean
    message: string
}

interface OptionPainel {
    id: string
    class: string
    selected: boolean
    icon: any
    title: string
}

const STATS_SHOW: string = "STATS_SHOW";
const STATS_LIST: string = "STATS_LIST";
const STATS_ADD: string = "STATS_ADD";
const STATS_AUX_COUNTRY: string = "STATS_AUX_COUNTRY";
const STATS_AUX_FACILITYTYPE: string = "STATS_AUX_FACILITYTYPE";
const STATS_AUX_CATEGORIES: string = "STATS_AUX_CATEGORIES";
const STATS_AUX_MINETYPES: string = "STATS_AUX_MINETYPES";
const STATS_AUX_SITUATION: string = "STATS_AUX_SITUATION";
const STATS_AUX_OFFICIALLANGUAGES: string = "STATS_AUX_OFFICIALLANGUAGES";

const OPTION_DEFAULT_CLASS: string = "stats-opt";
const OPTION_SELECTED_CLASS: string = "stats-opt opt-selected";





export const MineManage: React.FC<IProps> = (props) => {


    const [statsOption, setStatsOption] = useState<string>(STATS_LIST);
    const [painelOptions, setPainelOptions] = useState<OptionPainel[]>([]);

    const snackKekaxRef: any = useRef();


    const PainelOptionsStatic: OptionPainel[] = [
        /*{ id: STATS_SHOW, class: OPTION_SELECTED_CLASS, selected: true, icon: <div className="opt-icon icon-chart" />, title: props.appLanguage.backoffice.manageMines.graph },*/
        { id: STATS_LIST, class: OPTION_SELECTED_CLASS, selected: true, icon: <div className="opt-icon icon-list" />, title: props.appLanguage.backoffice.manageMines.listTab },
        { id: STATS_ADD, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-add" />, title: props.appLanguage.backoffice.manageMines.addTab },
        { id: STATS_AUX_COUNTRY, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxCountry" />, title: props.appLanguage.backoffice.manageMines.auxCountryTab },
        { id: STATS_AUX_FACILITYTYPE, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxFacilityType" />, title: props.appLanguage.backoffice.manageMines.auxFacilityTypeTab },
        { id: STATS_AUX_CATEGORIES, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxCategories" />, title: props.appLanguage.backoffice.manageMines.auxCategoriesTab },
        { id: STATS_AUX_MINETYPES, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxMineTypes" />, title: props.appLanguage.backoffice.manageMines.auxMineTypesTab },
        { id: STATS_AUX_SITUATION, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxSituation" />, title: props.appLanguage.backoffice.manageMines.auxSituationTab },
        { id: STATS_AUX_OFFICIALLANGUAGES, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-auxOfficialLanguages" />, title: props.appLanguage.backoffice.manageMines.auxOfficialLanguagesTab },

        { id: STATS_SHOW, class: OPTION_DEFAULT_CLASS, selected: false, icon: <div className="opt-icon icon-chart" />, title: props.appLanguage.backoffice.manageMines.graphTab },


    ]


    useEffect(() => {
        if (painelOptions.length === 0) {
            setPainelOptions([...PainelOptionsStatic]);

        } else {
            let copyPainel = [...painelOptions]
            for (let x = 0; x < copyPainel.length; x++) {
                if (copyPainel[x].id === STATS_ADD) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.addTab
                } else if (copyPainel[x].id === STATS_LIST) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.listTab
                } else if (copyPainel[x].id === STATS_SHOW) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.graphTab
                } else if (copyPainel[x].id === STATS_AUX_COUNTRY) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxCountryTab
                } else if (copyPainel[x].id === STATS_AUX_FACILITYTYPE) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxFacilityTypeTab
                } else if (copyPainel[x].id === STATS_AUX_CATEGORIES) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxCategoriesTab
                } else if (copyPainel[x].id === STATS_AUX_MINETYPES) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxMineTypesTab
                } else if (copyPainel[x].id === STATS_AUX_SITUATION) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxSituationTab
                } else if (copyPainel[x].id === STATS_AUX_OFFICIALLANGUAGES) {
                    copyPainel[x].title = props.appLanguage.backoffice.manageMines.auxOfficialLanguagesTab
                }
            }
            setPainelOptions([...copyPainel]);
        }
    }, [props.appLanguage])


    const handleChangeOptionPainel = (id: string) => {
        let newOptionArray: OptionPainel[] = [];
        for (let x in painelOptions) {
            const option: OptionPainel = painelOptions[x];
            if (option.id === id) {
                newOptionArray.push({ ...option, class: OPTION_SELECTED_CLASS, selected: true })
            } else {
                newOptionArray.push({ ...option, class: OPTION_DEFAULT_CLASS, selected: false })
            }
        }

        setPainelOptions(newOptionArray);
        setStatsOption(id);
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
    }


    const cbAddPublicationFunction = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.submitSuccess)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
        }
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })

    }




    return (
        <React.Fragment>
            <div className="wrapper-bo-manage-mine">
                <div className="container-stats-flex">
                    <div className="stats-menu">
                        {
                            painelOptions.map((option: OptionPainel, index: number) => (
                                <div className={option.class} onClick={() => handleChangeOptionPainel(option.id)} key={index}>
                                    {option.icon}
                                    <span className="opt-text">{option.title}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="stats-display">
                        {
                            statsOption === STATS_ADD ?
                                <AddMine cbMessage={cbAddPublicationFunction} />
                                 : statsOption === STATS_SHOW ?
                                    <GraphMine />
                                    : statsOption === STATS_LIST ?
                                        <ListMineStats />
                                        : statsOption === STATS_AUX_COUNTRY ?
                                            <AuxCountry />
                                            : statsOption === STATS_AUX_FACILITYTYPE ?
                                                <AuxFacilityType />
                                                : statsOption === STATS_AUX_CATEGORIES ?
                                                    <AuxCategories />
                                                    : statsOption === STATS_AUX_MINETYPES ?
                                                        <AuxMineTypes />
                                                        : statsOption === STATS_AUX_SITUATION ?
                                                            <AuxSituation />
                                                            : statsOption === STATS_AUX_OFFICIALLANGUAGES ?
                                                            <AuxOfficialLanguages />
                                                            : null

                        }
                    </div>
                </div>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(MineManage);
