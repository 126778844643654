import React, { useState } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import './CalendarEvents.css'
import FutureEvents from './futureEvents/FutureEvents';
import PastEvents from './pastEvents/PastEvents';
import CalendarPage from './calendarPage/CalendarPage';
import TabsCalendarEvents from './tabsCalendarEvents/TabsCalendarEvents';


interface IProps extends StoreProps { }


export const CalendarEvents: React.FC<IProps> = (props) => {


    const [selectedTab, setSelectedTab] = useState<string>("0")

    const changeTabSelectedHandler = (active: any) => {
        setSelectedTab(active)
    }

    const printActiveContent = () => {
        if (selectedTab === "1") {
            return <PastEvents />
        } else if (selectedTab === "2") {
            return <FutureEvents />
        } else if (selectedTab === "0") {
            return <CalendarPage />
        }
    }

    return (
        <div className="wrapper-calendarEvents">
            <div className="container-calendarEvents-header">
                <hr className="calendarEvents-header-line" />
                <div className="calendarEvents-header-text">
                    <span>{props.appLanguage.events.header[0]}</span>
                </div>
            </div>

            <div className="container-calendarEvents-tabs-header">
                <TabsCalendarEvents active={selectedTab} onChange={(active: any) => changeTabSelectedHandler(active)}>
                    <div key="0">{props.appLanguage.events.tabs[0]}</div>
                    <div key="1">{props.appLanguage.events.tabs[1]}</div>
                    <div key="2">{props.appLanguage.events.tabs[2]}</div>
                </TabsCalendarEvents>
            </div>
            <div className="container-calendarEvents-tabs-body">
                {
                    printActiveContent()
                }
            </div>



        </div>
    );


}

export default connect(mapStateToProps)(CalendarEvents);
