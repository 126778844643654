import * as React from 'react'
import './Footer.css';

/*
import europe from "../../assets/images/europe.jpg";
import eit from "../../assets/mineheritageLogo/suportEit.png";
*/
import eitEurope from "../../assets/mineheritageLogo/Supported_by_RM_Academy_EU_H2020_White_v2.png";

import fct from "../../assets/images/fct.png";
import liege from "../../assets/images/liege.png";
import alpes from "../../assets/images/alpes.png";
import padova from "../../assets/images/padova.png";
import consiglio from "../../assets/images/consiglio.png";
import taltech from "../../assets/images/taltech.png";
import inesc from "../../assets/images/inesc.png";
import gtk from "../../assets/images/gtk.png";
import slaska from "../../assets/images/slaska.png";
import tuc from "../../assets/images/tuc.png";
import madrid from "../../assets/images/madrid.png";
import zagreb from "../../assets/images/zagreb.png";
import tuke from "../../assets/images/TUKE_logo.jpg";

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';

interface IProps extends StoreProps { }


export const Footer: React.FC<IProps> = (props) => {

    return (
        <div className="wrapper-footer">
            <div className="container-footer-content">
                <div className="footer-content-flex">
                    <div className="footer-content-eit">
                        <img src={eitEurope} alt="eitRMA-EU-logo" />
                    </div>

                </div>
               
            </div>
            <div className="container-footer-list">

                <div className="footer-list-row">
                    <div className="footer-list-object">
                        <img src={fct} alt="university-fct" />
                    </div>

                    <div className="footer-list-object">
                        <img src={liege} alt="university-liege" />
                    </div>

                    <div className="footer-list-object">
                        <img src={alpes} alt="university-alpes" />
                    </div>

                    <div className="footer-list-object">
                        <img src={padova} alt="university-padova" />
                    </div>
                </div>

                <div className="footer-list-row">
                    <div className="footer-list-object">
                        <img src={consiglio} alt="university-consiglio" />
                    </div>

                    <div className="footer-list-object">
                        <img src={taltech} alt="university-taltech" />
                    </div>

                    <div className="footer-list-object">
                        <img src={inesc} alt="university-inesc" />
                    </div>

                    <div className="footer-list-object">
                        <img src={gtk} alt="university-gtk" />
                    </div>
                </div>

                <div className="footer-list-row">
                    <div className="footer-list-object">
                        <img src={slaska} alt="university-slaska" />
                    </div>

                    <div className="footer-list-object">
                        <img src={tuc} alt="university-tuc" />
                    </div>

                    <div className="footer-list-object">
                        <img src={zagreb} alt="university-zagreb" />
                    </div>

                    <div className="footer-list-object">
                        <img src={tuke} alt="university-tuke" />
                    </div>

                </div>

                <div className="footer-list-row">
                    <div className="footer-list-object">
                        <img src={madrid} alt="university-madrid" />
                    </div>
                </div>

            </div>
        </div>
    );

}

export default connect(mapStateToProps)(Footer);


/*
            <div className="container-footer-content">
                <div className="footer-content-flex">
                    <div className="footer-content-eit">
                        <img src={eit} alt="eit-logo" />
                    </div>

                </div>
                <div className="footer-content-flex">
                    <div className="footer-content-europe">
                        <div className="footer-content-europe-text">
                            <span>{props.appLanguage.footer.europe}</span>
                        </div>
                        <div className="footer-content-europe-img">
                            <img src={europe} alt="europe-logo" />
                        </div>
                    </div>
                    <div className="footer-content-right"><span>{props.appLanguage.footer.right}</span></div>

                </div>
            </div>
*/