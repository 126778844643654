import React from "react";

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import "./Goddess.css"
import { connect } from "react-redux";


interface IProps extends StoreProps { }

export const Goddess: React.FC<IProps> = (props) => {

    return (
        <div className="wrapper-goddess">
            <div className="container-goddess">
                <div className="container-goddess-title"><span>{props.appLanguage.goddess.intro}</span></div>
                <div className="container-goddess-flex">
                    <div className="goddess-story">
                        <span className="goddess-story-god">{props.appLanguage.goddess.god}</span>
                        <span className="goddess-story-description">{props.appLanguage.goddess.description}</span>
                    </div>
                    <div className="goddess-image"></div>
                </div>
            </div>
        </div>

    );

}

export default connect(mapStateToProps)(Goddess)