import React, { useState, useEffect, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './MainNews.css'
import GeneralServices from '../../../services/generalService.json'

//import dummyVideo from '../../../assets/news/video.png'
import dummyNews from '../../../assets/news/newsDelta.png'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { INewsLetter } from '../newsLetter/NewsLetter';
//import { format as dateFormat } from "date-fns";
import { getNewsletterImageURL, httpGetListNews, httpGetNewsletterImg } from '../../../services/requestService';
import { translateFormatTimestamp } from '../../calendarEvents/calendar/Calendar';

interface IProps extends StoreProps, RouteComponentProps<any> { }

/*
const dummyNewsArray: INewsLetter[] = [
    {
        id: '0', tag: "0", title: "News Title Main", desc: "News main description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    { id: 1, tag: "Sport", title: "News Title Number 1", desc: "News number 1 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1 },
    {
        id: '2', tag: "0", title: "News Title Number 2", desc: "News number 2 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '3', tag: "0", title: "News Title Number 3", desc: "News number 3 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '4', tag: "0", title: "News Title Number 4", desc: "News number 4 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    },
    {
        id: '5', tag: "0", title: "News Title Number 5", desc: "News number 5 description", img: dummyNews, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1,

    }
]
*/

/*HOOK USEINTERVAL*/
function useInterval(callback: any, delay: any) {
    const savedCallback = useRef();

    // remember latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // setup the interval
    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const MainNews: React.FC<IProps> = (props) => {
    const [newsMainDisplay, setNewsMainDisplay] = useState<INewsLetter>({ id: null, tag: '', title: '', desc: '', img: null, timestamp: null, importance: -1, views: -1 })
    const [newsArrayDisplay, setNewsArrayDisplay] = useState<INewsLetter[]>([])
    const [newsArrayData, setNewsArrayData] = useState<INewsLetter[]>([])
    const [newsArrayLeft, setNewsArrayLeft] = useState<INewsLetter[]>([])

    //const [totalNews, setTotalNews] = useState<number>(0);

    const maxNewsArray = 5;
    const maxPerRequest = 10;

    useEffect(() => {
        httpGetListNews('?limit=' + maxPerRequest).then(
            res => {

                //SETTING TOTAL NEWS
                /*
                if (res.headers.total !== undefined) {
                    setTotalNews(parseInt(res.headers.total, 10));
                } else {
                    setTotalNews(parseInt('1', 10));
                }
                */
                const dataArray: INewsLetter[] = [...res.data];
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].img === undefined) {
                            httpGetNewsletterImg(dataArray[i].id).then(
                                res => {
                                    dataArray[i].img = getNewsletterImageURL.replace('{news:id}', dataArray[i].id)
                                    /*
                                    if (i === dataArray.length - 1) {
                                        setNewsArrayData([...dataArray]);
                                    }
                                    */
                                    setNewsArrayData([...dataArray]);

                                }
                            ).catch(
                                err => {
                                    dataArray[i].img = dummyNews
                                    /*
                                    if (i === dataArray.length - 1) {
                                        setNewsArrayData([...dataArray]);
                                    }
                                    */
                                    setNewsArrayData([...dataArray]);

                                }
                            )
                        }
                        //}
                    }
                } else {
                    //setNewsArrayData([...dummyNewsArray]);

                }
            }
        ).catch(
            err => {
                //setNewsArrayData([...dummyNewsArray]);
            }
        )
        //console.log(dateFns.format(new Date(), "MMMM dd, yyyy"))
    }, [])

    /*
    useEffect(() => {
        if (totalNews > 0) {
            //SETTING PAGINATION
            let result = Math.floor(totalNews / maxPerRequest);
            const extra = totalNews % maxPerRequest;

            if (extra !== 0 || totalNews === 0) {
                result += 1
            }
            for (let i = 1; i < result; i++) {
                httpGetListNews('?page=' + i + '&limit='+maxPerRequest).then(
                    res => {

                        const dataArray: INewsLetter[] = [...res.data];
                        if (dataArray.length > 0) {

                            for (let i = 0; i < dataArray.length; i++) {
                                if (dataArray[i].img === undefined) {
                                    httpGetNewsletterImg(dataArray[i].id).then(
                                        res => {

                                            dataArray[i].img = getNewsletterImageURL.replace('{news:id}', dataArray[i].id)
                                            if(i===dataArray.length-1){
                                                setNewsArrayData((data:INewsLetter[])=>[...data,...dataArray]);
                                            }
                                        }
                                    ).catch(
                                        err => {

                                            dataArray[i].img = dummyNews
                                            if(i===dataArray.length-1){
                                                setNewsArrayData((data:INewsLetter[])=>[...data,...dataArray]);
                                            }                                        }
                                    )
                                }
                                //}
                            }
                        } else {
                            //setNewsArrayData([...dummyNewsArray]);

                        }
                    }
                ).catch(
                    err => {
                        //setNewsArrayData([...dummyNewsArray]);
                    }
                )
            }


        }
    }, [totalNews])
    */

    useInterval(() => {
        setNewsArrayData([...newsArrayDisplay, ...newsArrayLeft, newsMainDisplay])
    }, 5000);



    useEffect(() => {
        //if (newsArrayData.length > 0 && totalNews>0 && newsArrayData.length === totalNews) {
        const copyArray = [...newsArrayData]
        const main = copyArray.shift()
        const sliced = copyArray.splice(0, maxNewsArray)
        if (main) setNewsMainDisplay(main)
        setNewsArrayDisplay([...sliced])
        setNewsArrayLeft([...copyArray])
        //}
    }, [newsArrayData/*,totalNews*/])




    const handleAccessNewsLetter = (id: any) => {
        let newsLetterURL = GeneralServices.newsLetterPath.replace(':id', id)
        props.history.push(newsLetterURL);
    }



    useEffect(() => {
    }, [newsArrayData])

    return (
        <React.Fragment>
            {
                newsMainDisplay.id !== null ?
                    <div className="wrapper-news">
                        {
                            newsArrayDisplay.length > 0 &&
                            <div className="container-content">

                                <div className="content-display" onClick={() => handleAccessNewsLetter(newsMainDisplay.id)}>
                                    <div className="content-display-img" >

                                        <img src={newsMainDisplay.img} className="content-display-img-main" alt="main-news" />

                                    </div>
                                    <div className="content-display-text">
                                        <span className="content-display-text-type">{props.appLanguage.newsletter.tag[parseInt(newsMainDisplay.tag)]}</span>
                                        <span className="content-display-text-title">{newsMainDisplay.title}</span>
                                        <span className="content-display-text-date">{translateFormatTimestamp(newsMainDisplay.timestamp, props.appLanguage)}</span>
                                        <span className="content-display-text-info">{newsMainDisplay.desc}</span>

                                    </div>
                                </div>
                                <div className="content-side">
                                    {
                                        (newsArrayDisplay.map((content: INewsLetter, index: number) => {
                                            let classNameType = 'content-side-object'

                                            if (index === 0) {
                                                classNameType = classNameType.concat(' content-side-object-first')
                                            } else if (index === 1) {
                                                classNameType = classNameType.concat(' content-side-object-last')
                                            }

                                            if (index < 2) {

                                                return (
                                                    <div key={index} className={classNameType} onClick={() => handleAccessNewsLetter(content.id)}>
                                                        <div className="content-side-object-media">

                                                            <img src={content.img} className="content-side-object-media-video" alt="object-news" />

                                                        </div>
                                                        <div className="content-side-object-text">
                                                            <span className="content-side-object-text-type">{props.appLanguage.newsletter.tag[parseInt(content.tag)]}</span>
                                                            <span className="content-side-object-text-title">{content.title}</span>
                                                            <span className="content-side-object-text-date">{translateFormatTimestamp(content.timestamp, props.appLanguage)}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        }))
                                    }
                                </div>
                            </div>
                        }
                        <div className="container-list-flex">
                            {
                                (newsArrayDisplay.map((content: INewsLetter, index: number) => {
                                    let classNameType = 'list-object'
                                    if (index > 1) {
                                        if (index === 2) {
                                            classNameType = classNameType.concat(' list-object-first')
                                        } else if (index === newsArrayDisplay.length - 1) {
                                            classNameType = classNameType.concat(' list-object-last')
                                        }
                                        return (
                                            <div key={index} className={classNameType} onClick={() => handleAccessNewsLetter(content.id)}>
                                                <div className="list-object-img">

                                                    <img src={content.img} className="list-object-img-main" alt="object-news" />

                                                </div>
                                                <div className="list-object-text">
                                                    <span className="list-object-text-type">{props.appLanguage.newsletter.tag[parseInt(content.tag)]}</span>
                                                    <span className="list-object-text-title">{content.title}</span>
                                                    <span className="list-object-text-date">{translateFormatTimestamp(content.timestamp, props.appLanguage)}</span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                }))

                            }
                        </div>

                    </div>
                    : null
            }
        </React.Fragment>
    );
    /*
    <span className="content-side-object-text-title">Video</span>
    <span className="content-side-object-text-info">Video info here</span>
    */
}

export default connect(mapStateToProps)(withRouter(MainNews));
