import React, { useState, useEffect, useRef } from 'react'
import "./AuxFacilityType.css"
import GeneralServices from "../../../../services/generalService.json"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
//import { httpGetAuxFacilityType } from '../../../../services/requestService';

import AddAuxFacilityType from './addAuxFacilityType/AddAuxFacilityType';
import EditAuxFacilityType from './editAuxFacilityType/EditAuxFacilityType';
import RmvAuxFacilityType from './rmvAuxFacilityType/RmvAuxFacilityType';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShowAuxFacilityType from './showAuxFacilityType/ShowAuxFacilityType';
import { httpGetAuxFacilityTypeAll } from '../../../../services/requestService';


interface IProps extends StoreProps {

}


interface IOpenAuxFacilityType {
    add: boolean
    edit: boolean
    rmv: boolean
    show: boolean
    facilityType:IAuxFacilityType

}

const AUXCOUNTRYADD = "add"
const AUXCOUNTRYRMV = "rmv"
const AUXCOUNTRYEDIT = "edit"
const AUXCOUNTRYSHOW = "show"

export interface IAuxFacilityType {
    id: string
    faciType: string

}

export const AuxFacilityType: React.FC<IProps> = (props) => {

    const maxArray: number = 10;
    const maxPagesArray: number = 5;

    const [facilityTypeArray, setFacilityTypeArray] = useState<IAuxFacilityType[]>([]);
    //const [facilityTypeArrayDisplay, setFacilityTypeArrayDisplay] = useState<IAuxFacilityType[]>([]);

    const [totalFacilityTypes, setTotalFacilityTypes] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const snackKekaxRef: any = useRef();

    const [openAuxFacilityType, setOpenAuxFacilityType] = useState<IOpenAuxFacilityType>({ edit: false, rmv: false, add: false, show: false, facilityType: {id:"",faciType:""}})



    useEffect(() => {
        /*
        const dummyAuxFacilityTypes = GeneralServices.taux3FacilityType
        let dummyAuxFacilityTypesToAdd: IAuxFacilityType[] = []
        for (let i = 0; i < dummyAuxFacilityTypes.length; i++) {
            dummyAuxFacilityTypesToAdd.push({
                id: i + 1 + "", facilityType: dummyAuxFacilityTypes[i]
            })
        }
        setFacilityTypeArray([...dummyAuxFacilityTypesToAdd])
        setTotalFacilityTypes(dummyAuxFacilityTypesToAdd.length)
        */
        httpGetAuxFacilityTypeAll('?limit=' + maxArray).then(
            res => {
                //SETTING TOTAL Publication
                
                if (res.headers.total !== undefined) {
                    setTotalFacilityTypes(parseInt(res.headers.total, 10));
                } else {
                    setTotalFacilityTypes(parseInt('1', 10));
                }

                setFacilityTypeArray([...res.data]);


            }
        ).catch(
            err => {

            }
        )
    }, [])



    const handleOpenAuxFacilityType = (what: string, auxFacilityType: IAuxFacilityType | any) => {

        if (what === AUXCOUNTRYADD) {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...data, add: !data.add }))

        } else if (what === AUXCOUNTRYEDIT) {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...data, edit: !data.edit, facilityType:auxFacilityType}))

        } else if (what === AUXCOUNTRYRMV) {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...data, rmv: !data.rmv, facilityType: auxFacilityType}))

        } else if (what === AUXCOUNTRYSHOW) {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...data, show: !data.show, facilityType: auxFacilityType}))

        }
    }

    const addAuxFacilityTypeHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.add.submitSuccess)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, add: false })
        } else {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...openAuxFacilityType, add: !data.add }))
        }
        loadNewPage(counter,false)
    }

    const editAuxFacilityTypeHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.edit.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.edit.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, edit: false })
        } else {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...openAuxFacilityType, edit: !data.edit }))
        }
        loadNewPage(counter,false)
    }

    const rmvAuxFacilityTypeHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.delete.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.facilityType.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, rmv: false })
        } else {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...openAuxFacilityType, rmv: !data.rmv }))
        }
        loadNewPage(counter,false)
    }

    const showAuxFacilityTypeHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxFacilityType({ ...openAuxFacilityType, show: false })
        } else {
            setOpenAuxFacilityType((data: IOpenAuxFacilityType) => ({ ...openAuxFacilityType, show: !data.show }))
        }
        loadNewPage(counter,false)
    }


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalFacilityTypes / maxArray);
        const extra = totalFacilityTypes % maxArray;

        if (extra !== 0 || totalFacilityTypes === 0) {
            result += 1
        }

        setMaxCounter(result);
    }, [totalFacilityTypes])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, init: boolean) => {
        /*
        const startFrom = page === 0 ? 0 : (page - 1) * maxArray

        let newPageMines: IAuxFacilityType[] = []
        for (let i = startFrom, counter = 0; i < facilityTypeArray.length && counter < maxArray; i++, counter++) {
            newPageMines.push(facilityTypeArray[i]);

            if (newPageMines.length === maxArray || i === facilityTypeArray.length - 1) {
                setFacilityTypeArrayDisplay([...newPageMines])
            }

            if (init) {
                window.scrollTo({
                    top: 0,
                    behavior: 'auto'
                })

            }
        }
        */
        const queryPage = page - 1;
        httpGetAuxFacilityTypeAll('?page=' + queryPage + '&limit=' + maxArray).then(
            res => {
                setFacilityTypeArray([...res.data]);
                if (init) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )

    }

    /*
    useEffect(() => {
        if (facilityTypeArray.length > 0) {
            loadNewPage(0, true)
        }
    }, [facilityTypeArray])
    */

    return (
        <React.Fragment>
            <div className="wrapper-aux-facilityType">

                <div className="container-auxFacilityType">
                    <div className="auxFacilityType-menu">
                        <div className="auxFacilityType-menu-title"><span>{props.appLanguage.backoffice.manageMines.aux.facilityType.title}</span></div>
                        <div className="auxFacilityType-menu-options">
                            <div className="auxFacilityType-opt-add" onClick={() => handleOpenAuxFacilityType(AUXCOUNTRYADD, null)}>
                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.facilityType.addOpt} direction="top">
                                    <div className="auxFacilityType-opt-add-icon"></div>
                                    <span>{props.appLanguage.backoffice.manageMines.aux.facilityType.addOpt}</span>
                                </TooltipKekax>
                            </div>
                        </div>
                    </div>

                    <div className="auxFacilityType-list">
                        {
                            facilityTypeArray.map((auxFacilityType: IAuxFacilityType, index: number) => (
                                <div className="auxFacilityType-list-obj" key={"facilityType" + index}>
                                    <div className="auxFacilityType-id"><span>{auxFacilityType.id}</span></div>
                                    <div className="auxFacilityType-title">
                                        <span>{auxFacilityType.faciType} </span>
                                    </div>
                                    <div className="auxFacilityType-options">
                                        <div className="auxFacilityType-obj-show" onClick={() => handleOpenAuxFacilityType(AUXCOUNTRYSHOW, auxFacilityType)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.facilityType.showOpt} direction="top">
                                                <div className="auxFacilityType-obj-show-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                        <div className="auxFacilityType-obj-rmv" onClick={() => handleOpenAuxFacilityType(AUXCOUNTRYRMV, auxFacilityType)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.facilityType.rmvOpt} direction="top">
                                                <div className="auxFacilityType-obj-rmv-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>

                </div>
                <div className="container-auxFacilityType-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>

            </div>

            <SnackKekax ref={snackKekaxRef} />
            <AddAuxFacilityType data={{ open: openAuxFacilityType.add, facilityType: openAuxFacilityType.facilityType }} callback={addAuxFacilityTypeHandler} />
            <RmvAuxFacilityType data={{ open: openAuxFacilityType.rmv, facilityType: openAuxFacilityType.facilityType }} callback={rmvAuxFacilityTypeHandler} />
            <ShowAuxFacilityType data={{ open: openAuxFacilityType.show, facilityType: openAuxFacilityType.facilityType}} callback={showAuxFacilityTypeHandler} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(AuxFacilityType);
