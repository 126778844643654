import React, { useEffect, useRef, useState } from 'react';
import "./AdminAuth.css"
import { mapStateToProps, mapDispatchToProps, StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import visibility from "../../assets/figma/visibility.svg";
import visibility_off from "../../assets/figma/visibility_off.svg";
import { httpUserSignInRequest } from '../../services/requestService';
import GeneralServices from '../../services/generalService.json'
import { ReactCookieProps, withCookies } from 'react-cookie';
import SnackKekax from '../kekax/SnackKekax';

interface IProps extends RouteComponentProps<any>, ReactCookieProps, StoreProps { }

interface IFormError {
    username: string
    password: string
}

interface IFormInput {
    username: string
    password: string
}
interface IFormValid {
    usernameValid: boolean
    passwordValid: boolean
}

interface IShowPassword {
    type: string
    icon: any
}



const FORMUSERNAMEID: string = 'username';
const FORMPASSWORDID: string = 'password';


export const AdminAuth: React.FC<IProps> = (props) => {

    const [formFocusError, setFormFocusError] = useState<boolean>(false);
    const [formSubmitAttempt, setFormSubmitAttempt] = useState<boolean>(false);

    const [formError, setFormError] = useState<IFormError>({ username: '', password: '' });
    const [formInput, setFormInput] = useState<IFormInput>({ username: '', password: '' });
    const [formValid, setFormValid] = useState<IFormValid>({ usernameValid: false, passwordValid: false });
    const [showPassword, setShowPassword] = useState<IShowPassword>({ type: 'password', icon: visibility_off })

    const snackKekaxRef: any = useRef();


    useEffect(() => {
        document.documentElement.style.setProperty("--ayy-visibility", "url(" + showPassword.icon + ")");
    }, [showPassword])

    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMUSERNAMEID: {
                setFormInput({ ...formInput, username: value })
                if (formFocusError) {
                    setFormValid({ ...formValid, usernameValid: true })
                }
                break;
            }
            case FORMPASSWORDID: {
                setFormInput({ ...formInput, password: value })
                if (formFocusError) {
                    setFormValid({ ...formValid, passwordValid: true })
                }
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    const submitHandler = (e: any) => {

        e.preventDefault();
        e.stopPropagation();

        let fieldValidationErrors = formError;
        let upd_usernameValid = formValid.usernameValid;
        let upd_passwordValid = formValid.passwordValid;

        if (formInput.username === '') {
            upd_usernameValid = false;
            fieldValidationErrors.username = props.appLanguage.login.error_required;
        } else {
            upd_usernameValid = true;
        }

        if (formInput.password === '') {
            upd_passwordValid = false;
            fieldValidationErrors.password = props.appLanguage.login.error_required;
        } else {
            upd_passwordValid = true;
        }

        setFormFocusError(true)
        setFormSubmitAttempt(true);
        setFormError(fieldValidationErrors)
        setFormValid({ usernameValid: upd_usernameValid, passwordValid: upd_passwordValid })

    }

    /*HTTP REQUEST SUBMIT*/
    useEffect(() => {
        if (formValid.usernameValid && formValid.passwordValid && formSubmitAttempt) {

            httpUserSignInRequest({
                u: formInput.username,
                p: formInput.password,
                k: true
            }).then(
                res => {
                    processLogin()
                }
            ).catch(
                err => {
                    setFormSubmitAttempt(false);
                    snackKekaxRef.current.openSnackBar(props.appLanguage.login.authWrong)
                }
            )
        } else {
            setFormSubmitAttempt(false);
        }
        //eslint-disable-next-line
    }, [formSubmitAttempt, formValid])


    const processLogin = () => {
        if (props.cookies !== undefined) {
            if (GeneralServices.develop) {
                props.cookies.set(GeneralServices.cookies.cookieToken, GeneralServices.cookies.cookieToken, { path: '/', domain: 'localhost' });
                props.cookies.set(GeneralServices.cookies.cookieUser, formInput.username, { path: '/', domain: 'localhost' });
                /*ROLES*/
                props.cookies.set(GeneralServices.cookies.cookieRole, "1", { path: '/', domain: 'localhost' });
            }
            /*
            console.log(props.cookies.get(GeneralServices.cookies.cookieToken))
            console.log(props.cookies.get(GeneralServices.cookies.cookieUser))
            console.log(props.cookies.get(GeneralServices.cookies.cookieRole))
            */
            props.loginSession({
                sessionToken: props.cookies.get(GeneralServices.cookies.cookieToken),
                sessionUser: props.cookies.get(GeneralServices.cookies.cookieUser),
                sessionRole: props.cookies.get(GeneralServices.cookies.cookieRole)
            });
        }
        snackKekaxRef.current.openSnackBar(props.appLanguage.login.authSuccess)

        setFormError({ username: '', password: '' });
        setFormFocusError(false);
        setFormSubmitAttempt(false);
        setShowPassword({ type: 'password', icon: visibility_off })
        setFormInput({ username: '', password: '' })
        setFormValid({ usernameValid: false, passwordValid: false })

        //props.history.push(GeneralServices.homePath);

    }



    const showPasswordHandler = () => {
        if (showPassword.type === 'password') {
            setShowPassword({ type: 'text', icon: visibility })
        } else {
            setShowPassword({ type: 'password', icon: visibility_off })
        }


    }

    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMUSERNAMEID: {
                return formFocusError && !formValid.usernameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.username}</span>
                    : null
            }

            case FORMPASSWORDID: {
                return formFocusError && !formValid.passwordValid ?
                    <span className="input-invalid-feedback-kekax">{formError.password}</span>
                    : null
            }

            default: {
                break;
            }
        }//switch end
    }



    return (
        <div className="wrapper-adminAuth">
            {
                !props.loggedIn ?
                    <div className="container-login">
                        <div className="login-header">
                            <span>{props.appLanguage.adminAuth.login.title}</span>
                        </div>
                        <form className="login-form" id='login-form' onSubmit={(event: any) => submitHandler(event)}>
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.login.user}
                                    </label>
                                    <div className="field">
                                        <input className="input-text"
                                            value={formInput.username}
                                            type="text"
                                            placeholder={props.appLanguage.login.userph}
                                            id={FORMUSERNAMEID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMUSERNAMEID)
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.login.pwd}
                                    </label>
                                    <div className="field-flex">
                                        <input className="input-text"
                                            value={formInput.password}
                                            type={showPassword.type}
                                            placeholder={props.appLanguage.login.pwdph}
                                            id={FORMPASSWORDID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                        />
                                        <div className="input-icon" onClick={showPasswordHandler}></div>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMPASSWORDID)
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="submit"><button type="submit" className="submit-btn"><span className="info">{props.appLanguage.login.submit}</span></button></div>
                        </form>

                    </div>
                    :
                    <div className="container-loggedin">
                        <div className="message">
                            <span>{props.appLanguage.adminAuth.loggedin.msg[0]}</span>
                            <span> {props.sessionUser}</span>
                            <span>{props.appLanguage.adminAuth.loggedin.msg[1]}</span>
                        </div>
                    </div>
            }
            <SnackKekax ref={snackKekaxRef} />
        </div>
    );

}


export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(AdminAuth)));
