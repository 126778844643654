import React, { useEffect, useRef, useState } from 'react';
import "./EditProfileModal.css"

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import { IUser } from '../Profile';
import TooltipKekax from '../../kekax/TooltipKekax';
import visibility from "../../../assets/figma/visibility.svg";
import visibility_off from "../../../assets/figma/visibility_off.svg";
import { IShowPassword } from '../../backoffice/adminManage/registerModal/RegisterModal';
import GeneralServices from '../../../services/generalService.json'
import { httpUserPutAccountRequest, httpUserPutPwdAccountRequest } from '../../../services/requestService';

interface IProps extends StoreProps {
    open: boolean
    callback: any
    user: IUser
}

interface IFormError {
    realnameError: string
    userError: string
    emailError: string
    countryError: string
    passwordError: string
    cpasswordError: string
}

interface IFormInput {
    realname: string
    user: string
    email: string
    country: string
    password: string
    cpassword: string
}

interface IFormValid {
    realnameValid: boolean
    userValid: boolean
    emailValid: boolean
    countryValid: boolean
    passwordValid: boolean
    cpasswordValid: boolean

}

interface IFormChange {
    realnameChange: boolean
    userChange: boolean
    emailChange: boolean
    countryChange: boolean
    passwordChange: boolean
    cpasswordChange: boolean
}

interface IFormFocus {
    realnameFocus: boolean
    userFocus: boolean
    emailFocus: boolean
    countryFocus: boolean
    passwordFocus: boolean
    cpasswordFocus: boolean
}

interface FlagOption {
    id: number
    tag: string
    shortNameENG: string
    shortNameNOL: string
    officialName: string
    flag: any
}

const FORMREALNAMEID: string = 'realname';
const FORMUSERID: string = 'user';
const FORMEMAILID: string = 'email';
const FORMCOUNTRYID: string = 'country';
const FORMPASSWORDID: string = 'password';
const FORMCPASSWORDID: string = 'cpassword';


export function EditProfileModal(props: IProps) {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const formRef = useRef<any>(null);

    const [tempDisabled, setTempDisabled] = useState<number>(0);
    const [flagsDropdown, setFlagsDropdown] = useState({ open: false, class: 'flags-menu-drop' });
    const refFlagsDropdownMenu = useRef<any>(null);
    const refFlagsDropdownButton = useRef<any>(null);

    //const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormError>({ realnameError: '', userError: '', emailError: '', countryError: '', passwordError: '', cpasswordError: '' });
    const [formInput, setFormInput] = useState<IFormInput>({ realname: '', user: '', email: '', country: '', password: '', cpassword: '' });
    const [formChange, setFormChange] = useState<IFormChange>({ realnameChange: false, userChange: false, emailChange: false, countryChange: false, passwordChange: false, cpasswordChange: false });
    const [formValid, setFormValid] = useState<IFormValid>({ realnameValid: false, userValid: false, emailValid: false, countryValid: false, passwordValid: false, cpasswordValid: false });
    const [showPassword, setShowPassword] = useState<IShowPassword>({ type: 'password', icon: visibility_off })
    const [flagsArrayAll, setFlagsArrayAll] = useState<FlagOption[]>([])

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);


    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("edit-profile-modal-open");
            document.addEventListener('mousedown', handleEventListeners);
            document.addEventListener('scroll', handleOffset);
            window.addEventListener('resize', handleEventListeners);


            handleOffset();
            let countries: FlagOption[] = []
            for (let i = 0; i < GeneralServices.countries.length; i++) {
                countries.push({
                    id: parseInt(GeneralServices.countries[i].id),
                    tag: GeneralServices.countries[i].tag,
                    shortNameENG: GeneralServices.countries[i].shortNameENG,
                    shortNameNOL: GeneralServices.countries[i].shortNameNOL,
                    officialName: GeneralServices.countries[i].officialName,
                    flag: require('../../../assets/flags/' + GeneralServices.countries[i].tag + '.png')

                })
                if (i === GeneralServices.countries.length - 1) {
                    setFlagsArrayAll([...countries])
                }
            }
        } else {
            modalRef.current.classList.remove("edit-profile-modal-open");
        }


    }, [props.open])


    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {

                    if (refFlagsDropdownMenu.current && refFlagsDropdownButton.current && !refFlagsDropdownMenu.current.contains(event.target) && !refFlagsDropdownButton.current.contains(event.target)) {
                        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    }

                    if (event.target.contains(overlayRef.current)) {
                        closeModalHandler(GeneralServices.constants.child.closed);
                    }
                    break
                }
                case 'resize': {
                    setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    break
                }
                default: {

                }
            }
        }



    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editProfileModal-topOffset", posY + "px");
    }




    const closeModalHandler = (feedback: string) => {
        modalRef.current.classList.remove("edit-profile-modal-open");
        document.removeEventListener('mousedown', handleEventListeners);
        document.removeEventListener('scroll', handleOffset);
        window.removeEventListener('resize', handleEventListeners);

        //setFormSubmitValid(false);
        setFormError({ realnameError: '', userError: '', emailError: '', countryError: '', passwordError: '', cpasswordError: '' });
        setFormInput({ realname: '', user: '', email: '', country: '', password: '', cpassword: '' });
        setFormChange({ realnameChange: false, userChange: false, emailChange: false, countryChange: false, passwordChange: false, cpasswordChange: false });
        setFormValid({ realnameValid: false, userValid: false, emailValid: false, countryValid: false, passwordValid: false, cpasswordValid: false });
        setShowPassword({ type: 'password', icon: visibility_off })
        setTempDisabled(0)
        setFlagsArrayAll([])

        props.callback(feedback)

    }


    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);


        const name: string = formInput.realname === "" ? props.user.realName : formInput.realname;
        const email: any = formInput.email === "" ? props.user.email : formInput.email;
        const pass: string = formInput.password;
        const country: any = formInput.country === "" ? props.user.country : formInput.country;

        /*
        const user: string = formInput.user === "" ? props.user.userId : formInput.user;
        const cpass: any = formInput.cpassword;
        console.log(user)
        console.log(name)
        console.log(email)
        console.log(pass)
        console.log(cpass)
        console.log(country)
        */
        if (formChange.emailChange || formChange.realnameChange || formChange.countryChange) {
            httpUserPutAccountRequest({
                realName: name,
                email: email,
                country: country
            }).then(
                async res => {

                    if (formChange.passwordChange && formChange.cpasswordChange) {
                        await httpUserPutPwdAccountRequest(pass).then(
                            res => {
                            }
                        ).catch(
                            e => {
                            }
                        )
                    }
                    closeModalHandler(GeneralServices.constants.child.worked);

                }

            ).catch(
                e => {
                    closeModalHandler(GeneralServices.constants.child.failed);
                }
            )

            //closeModalHandler(GeneralServices.constants.child.closed)
        } else if (formChange.passwordChange && formChange.cpasswordChange) {
            httpUserPutPwdAccountRequest(pass).then(
                res => {
                    closeModalHandler(GeneralServices.constants.child.worked);

                }
            ).catch(
                e => {
                    closeModalHandler(GeneralServices.constants.child.failed);

                }
            )
        }

    }

    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMREALNAMEID: {
                return !formValid.realnameValid && formChange.realnameChange ?
                    <span className="input-invalid-feedback-kekax">{formError.realnameError}</span>
                    : formValid.realnameValid && formChange.realnameChange ?
                        <span className="input-valid-feedback-kekax">{formError.realnameError}</span>
                        : null
            }

            case FORMUSERID: {
                return !formValid.userValid && formChange.userChange ?
                    <span className="input-invalid-feedback-kekax">{formError.userError}</span>
                    : formValid.userValid && formChange.userChange ?
                        <span className="input-valid-feedback-kekax">{formError.userError}</span>
                        : null
            }

            case FORMEMAILID: {
                return !formValid.emailValid && formChange.emailChange ?
                    <span className="input-invalid-feedback-kekax">{formError.emailError}</span>
                    : formValid.emailValid && formChange.emailChange ?
                        <span className="input-valid-feedback-kekax">{formError.emailError}</span>
                        : null
            }

            case FORMCOUNTRYID: {
                return !formValid.countryValid && formChange.countryChange ?
                    <span className="input-invalid-feedback-kekax">{formError.countryError}</span>
                    : formValid.countryValid && formChange.countryChange ?
                        <span className="input-valid-feedback-kekax">{formError.countryError}</span>
                        : null
            }

            case FORMPASSWORDID: {
                return !formValid.passwordValid && formChange.passwordChange ?
                    <span className="input-invalid-feedback-kekax">{formError.passwordError}</span>
                    : formValid.passwordValid && formChange.passwordChange ?
                        <span className="input-valid-feedback-kekax">{formError.passwordError}</span>
                        : null
            }
            case FORMCPASSWORDID: {
                return !formValid.cpasswordValid && formChange.cpasswordChange ?
                    <span className="input-invalid-feedback-kekax">{formError.cpasswordError}</span>
                    : formValid.cpasswordValid && formChange.cpasswordChange ?
                        <span className="input-valid-feedback-kekax">{formError.cpasswordError}</span>
                        : null
            }

            default: {
                break;
            }
        }//switch end
    }


    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMREALNAMEID: {
                setFormInput({ ...formInput, realname: value })
                break;
            }
            case FORMUSERID: {
                setFormInput({ ...formInput, user: value })
                break;
            }
            case FORMEMAILID: {
                setFormInput({ ...formInput, email: value })
                break;
            }
            case FORMCOUNTRYID: {
                setFormInput({ ...formInput, country: value })
                break;
            }
            case FORMPASSWORDID: {
                setFormInput({ ...formInput, password: value })
                break;
            }
            case FORMCPASSWORDID: {
                setFormInput({ ...formInput, cpassword: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    const editFieldHandler = (formId: any) => {

        switch (formId) {
            case FORMREALNAMEID: {
                setFormChange({ ...formChange, realnameChange: !formChange.realnameChange })
                if (formChange.realnameChange) {//vai ficar disabled
                    setFormInput({ ...formInput, realname: "" })
                }
                break;
            }
            case FORMUSERID: {
                setFormChange({ ...formChange, userChange: !formChange.userChange })
                if (formChange.userChange) {//vai ficar disabled
                    setFormInput({ ...formInput, user: "" })
                }
                break;
            }
            case FORMEMAILID: {
                setFormChange({ ...formChange, emailChange: !formChange.emailChange })
                if (formChange.emailChange) {//vai ficar disabled
                    setFormInput({ ...formInput, email: "" })
                }
                break;
            }
            case FORMCOUNTRYID: {
                setFormChange({ ...formChange, countryChange: !formChange.countryChange })
                if (formChange.countryChange) {//vai ficar disabled
                    setFormInput({ ...formInput, country: "" })
                }
                break;
            }
            case FORMPASSWORDID: {
                setFormChange({ ...formChange, passwordChange: !formChange.passwordChange, cpasswordChange: false })
                setShowPassword({ type: 'password', icon: visibility_off })
                if (formChange.passwordChange) {//vai ficar disabled
                    setFormInput({ ...formInput, password: "", cpassword: "" })
                }
                break;
            }

            default: {
                break;
            }
        }//switch end
    }



    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_realnameValid = formValid.realnameValid;
        let upd_userValid = formValid.userValid;
        let upd_emailValid = formValid.emailValid;
        let upd_countryValid = formValid.countryValid;
        let upd_passwordValid = formValid.passwordValid;
        let upd_cpasswordValid = formValid.cpasswordValid;


        /*REAL NAME VALIDATION*/
        if (formInput.realname === '' && formChange.realnameChange) {
            upd_realnameValid = false;
            fieldValidationErrors.realnameError = props.appLanguage.profile.editProfileModal.name.feedback[0];
        } else if (formInput.realname === '' && !formChange.realnameChange) { //generic case
            upd_realnameValid = true;
        } else {
            upd_realnameValid = true;
            fieldValidationErrors.realnameError = props.appLanguage.profile.editProfileModal.name.feedback[2];
        }

        /*USER VALIDATION*/
        if (formInput.user === '' && formChange.userChange) {
            upd_userValid = false;
            fieldValidationErrors.userError = props.appLanguage.profile.editProfileModal.user.feedback[0];
        } else if (formInput.user === '' && !formChange.userChange) { //generic case
            upd_userValid = true;
        } else {
            upd_userValid = true;
            fieldValidationErrors.userError = props.appLanguage.profile.editProfileModal.user.feedback[2];
        }


        //eslint-disable-next-line
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (formInput.email.match(emailRegex) !== null) {
            upd_emailValid = true;
        } else {
            upd_emailValid = false;
        }

        /*EMAIL VALIDATION*/
        if (formInput.email === '' && formChange.emailChange) {
            upd_emailValid = false;
            fieldValidationErrors.emailError = props.appLanguage.profile.editProfileModal.email.feedback[0];
        } else if (!upd_emailValid && formInput.email !== '' && formChange.emailChange) {
            upd_emailValid = false;
            fieldValidationErrors.emailError = props.appLanguage.profile.editProfileModal.email.feedback[3];
        } else if (formInput.email === '' && !formChange.emailChange) { //generic case
            upd_emailValid = true;
        } else {
            upd_emailValid = true;
            fieldValidationErrors.emailError = props.appLanguage.profile.editProfileModal.email.feedback[2];
        }



        /*COUNTRY VALIDATION*/
        if (formInput.country === '' && formChange.countryChange) {
            upd_countryValid = false;
            fieldValidationErrors.countryError = props.appLanguage.profile.editProfileModal.country.feedback[0];
        } else if (formInput.country === '' && !formChange.countryChange) { //generic case
            upd_countryValid = true;
        } else {
            upd_countryValid = true;
            fieldValidationErrors.countryError = props.appLanguage.profile.editProfileModal.country.feedback[2];
        }



        upd_passwordValid = formInput.password.length >= 6;
        /*PASSWORD VALIDATION*/
        if (formInput.password === '' && formChange.passwordChange) {
            upd_passwordValid = false;
            fieldValidationErrors.passwordError = props.appLanguage.profile.editProfileModal.password.feedback[0];
            if (formInput.cpassword !== '') {
                setFormInput({ ...formInput, cpassword: '' })
            }
        } else if (!upd_passwordValid && formInput.password !== '' && formChange.passwordChange) {
            upd_passwordValid = false;
            fieldValidationErrors.passwordError = props.appLanguage.profile.editProfileModal.password.feedback[3];
            if (formInput.cpassword !== '') {
                setFormInput({ ...formInput, cpassword: '' })
            }
        } else if (formInput.password === '' && !formChange.passwordChange) { //generic case
            upd_passwordValid = true;
        } else {
            upd_passwordValid = true;
            fieldValidationErrors.passwordError = props.appLanguage.profile.editProfileModal.password.feedback[2];
        }

        upd_cpasswordValid = formInput.cpassword.length >= 6 && formInput.password === formInput.cpassword;

        if (formInput.cpassword === '' && formChange.passwordChange) {
            fieldValidationErrors.cpasswordError = props.appLanguage.profile.editProfileModal.cpassword.feedback[0];
            upd_cpasswordValid = false;
        } else if (!upd_cpasswordValid && formChange.passwordChange) {
            fieldValidationErrors.cpasswordError = props.appLanguage.profile.editProfileModal.cpassword.feedback[4];
            upd_cpasswordValid = false;
        } else if (formInput.cpassword === '' && !formChange.passwordChange) { //generic case
            upd_cpasswordValid = true;
        } else {
            upd_cpasswordValid = true;
            fieldValidationErrors.cpasswordError = props.appLanguage.profile.editProfileModal.cpassword.feedback[3];
        }




        setFormError(fieldValidationErrors)
        setFormValid({ realnameValid: upd_realnameValid, userValid: upd_userValid, emailValid: upd_emailValid, countryValid: upd_countryValid, passwordValid: upd_passwordValid, cpasswordValid: upd_cpasswordValid })


        //eslint-disable-next-line
    }, [formInput, formChange])

    const checkIsOkToSubmit = () => {
        let atLeastOneChecked: boolean = false;
        if (formChange.realnameChange || formChange.userChange || formChange.emailChange || formChange.countryChange || formChange.passwordChange) {
            atLeastOneChecked = true;
        } else {
            atLeastOneChecked = false;
        }

        if (atLeastOneChecked && formValid.realnameValid && formValid.emailValid && formValid.passwordValid && formValid.cpasswordValid && formValid.countryValid) {
            return true;
        }
        return false;

    }

    const confirmPasswordHandler = () => {
        setFormChange({ ...formChange, cpasswordChange: !formChange.cpasswordChange })
        setShowPassword({ type: 'password', icon: visibility_off })

    }

    const showPasswordHandler = () => {
        if (showPassword.type === 'password') {
            setShowPassword({ type: 'text', icon: visibility })
        } else {
            setShowPassword({ type: 'password', icon: visibility_off })
        }


    }
    //<div className="input-icon" onClick={() => editFieldHandler(FORMUSERID)}></div>


    const flagDropdownHandler = () => {
        if (refFlagsDropdownMenu.current !== null) {
            const myElement: HTMLElement | null = document.getElementById("open-flags-drop");
            const myParent: HTMLElement | null = document.getElementById("editprofile-parent-container");


            if (myElement !== null && myParent !== null) {
                const yPos = myElement.getBoundingClientRect().y - myParent.getBoundingClientRect().y + myElement.getBoundingClientRect().height;
                refFlagsDropdownMenu.current.style.top = yPos + 'px';
                refFlagsDropdownMenu.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';
            }

        }

        if (!flagsDropdown.open) {
            setFlagsDropdown({ open: true, class: 'flags-menu-drop flags-menu-drop-open' })
        } else {
            setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
        }

    }

    const changeInputCountryHandler = (option: string) => {
        setFormInput({ ...formInput, country: option })
    }



    return (
        <div className="wrapper-edit-profile" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>
            <div className="content" ref={contentRef} id="editprofile-parent-container">
                <div className="header">
                    <span className="modal-title">{props.appLanguage.profile.editProfileModal.title}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <form className="body-form" ref={formRef} id='edit-profile-form' onSubmit={(event: any) => submitHandler(event)}>

                        <div className="left">
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.user.title} *
                                    </label>
                                    <div className="field">
                                        <input className={!formChange.userChange ? "input-text input-user" : "input-text text-active"}
                                            value={formInput.user}
                                            type="text"
                                            placeholder={props.user.userId}
                                            id={FORMUSERID}
                                            disabled={!formChange.userChange}
                                            onChange={(event: any) => changeInputHandler(event)}
                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMUSERID)
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.password.title} *
                                    </label>
                                    <div className="field field-password">
                                        <div className="input-icon" onClick={() => editFieldHandler(FORMPASSWORDID)}></div>
                                        {
                                            !formChange.cpasswordChange ?
                                                <>
                                                    <input className={!formChange.passwordChange ? "input-text" : "input-text text-active"}
                                                        value={formInput.password}
                                                        type={showPassword.type}
                                                        placeholder={props.appLanguage.profile.editProfileModal.password.placeholder}
                                                        id={FORMPASSWORDID}
                                                        disabled={!formChange.passwordChange}
                                                        onChange={(event: any) => changeInputHandler(event)}
                                                    />
                                                    {
                                                        formChange.passwordChange ?
                                                            <div className="show-pass" onClick={showPasswordHandler}>
                                                                <div className="showpass-icon" style={{ "backgroundImage": "url(" + showPassword.icon + ")" }}></div>
                                                            </div>
                                                            : null
                                                    }

                                                    <div className={formValid.passwordValid && formChange.passwordChange ? "confirm" : ""} onClick={() => confirmPasswordHandler()}>
                                                        <TooltipKekax content={props.appLanguage.profile.editProfileModal.password.tooltip} direction="top">
                                                            <div className="confirm-icon"></div>
                                                        </TooltipKekax>

                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <input className={"input-text text-active"}
                                                        value={formInput.cpassword}
                                                        type={showPassword.type}
                                                        placeholder={props.appLanguage.profile.editProfileModal.cpassword.placeholder}
                                                        id={FORMCPASSWORDID}
                                                        disabled={!formChange.cpasswordChange}
                                                        onChange={(event: any) => changeInputHandler(event)}
                                                    />
                                                    {
                                                        formChange.passwordChange ?
                                                            <div className="show-pass" onClick={showPasswordHandler}>
                                                                <div className="showpass-icon" style={{ "backgroundImage": "url(" + showPassword.icon + ")" }}></div>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={formValid.passwordValid && formChange.passwordChange ? "confirm" : ""} onClick={() => confirmPasswordHandler()}>
                                                        <TooltipKekax content={props.appLanguage.profile.editProfileModal.cpassword.tooltip} direction="top">
                                                            <div className="goback-icon"></div>
                                                        </TooltipKekax>
                                                    </div>
                                                </>
                                        }


                                    </div>
                                    {
                                        !formChange.cpasswordChange ?
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMPASSWORDID)
                                                }
                                            </div>
                                            :
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMCPASSWORDID)
                                                }
                                            </div>
                                    }

                                </div>
                            </div>
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.country.title} *
                                    </label>
                                    <div className="field">
                                        <div className="input-icon" onClick={() => editFieldHandler(FORMCOUNTRYID)}></div>
                                        <div className={!formChange.countryChange ? "input-text input-country" : "input-text input-country text-active"} onClick={formChange.countryChange ? flagDropdownHandler : undefined} ref={refFlagsDropdownButton} id="open-flags-drop">
                                            {
                                                formInput.country === "" ?
                                                    <>
                                                        {
                                                            props.user.country !== "" ?
                                                                <>
                                                                    <div className="opt-flag" style={{ backgroundImage: "url(" + require("../../../assets/flags/" + props.user.country + ".png") + ")" }}></div>
                                                                    <div className="text-country"><span>{props.user.country.toUpperCase()}</span></div>
                                                                    <div className="arrow-down"></div>
                                                                </>
                                                                :
                                                                <>                                                                    

                                                                    <div className="text-ph"><span>{props.appLanguage.register.countryph}</span></div>
                                                                    <div className="arrow-down"></div>
                                                                </>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <div className="opt-flag" style={{ backgroundImage: "url(" + require("../../../assets/flags/" + formInput.country + ".png") + ")" }}></div>
                                                        <div className="text-country"><span>{formInput.country.toUpperCase()}</span></div>
                                                        <div className="arrow-down"></div>

                                                    </>
                                            }

                                        </div>
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMCOUNTRYID)
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.email.title} *
                                    </label>
                                    <div className="field">
                                        <div className="input-icon" onClick={() => editFieldHandler(FORMEMAILID)}></div>
                                        <input className={!formChange.emailChange ? "input-text" : "input-text text-active"}
                                            value={formInput.email}
                                            type="text"
                                            placeholder={props.user.email}
                                            id={FORMEMAILID}
                                            disabled={!formChange.emailChange}
                                            onChange={(event: any) => changeInputHandler(event)}
                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMEMAILID)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="main-row">
                                <div className="input">
                                    <label className="label">
                                        {props.appLanguage.profile.editProfileModal.name.title} *
                                    </label>
                                    <div className="field">
                                        <div className="input-icon" onClick={() => editFieldHandler(FORMREALNAMEID)}></div>
                                        <input className={!formChange.realnameChange ? "input-text" : "input-text text-active"}
                                            value={formInput.realname}
                                            type="text"
                                            placeholder={props.user.realName}
                                            id={FORMREALNAMEID}
                                            disabled={!formChange.realnameChange}
                                            onChange={(event: any) => changeInputHandler(event)}
                                        />
                                    </div>
                                    <div className="feedback-block">
                                        {
                                            validatorFeedback(FORMREALNAMEID)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div className={flagsDropdown.class} ref={refFlagsDropdownMenu}>
                    <div className="wrapper-flags-menu-dropdown">
                        <div className="drop-filter-wrapper">
                            {
                                flagsArrayAll.map((flag: FlagOption, index: number) => (

                                    <div key={"editProfile-flag-" + index} className={formInput.country === flag.tag ? "drop-option opt-active" : "drop-option"} onClick={() => { changeInputCountryHandler(flag.tag); setFlagsDropdown({ open: false, class: 'flags-menu-drop' }) }}>
                                        <div className="icon" style={{ backgroundImage: "url(" + flag.flag + ")" }}></div>
                                        <span className="text">{flag.shortNameENG.toUpperCase()}</span>
                                    </div>
                                ))

                            }
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="submit"><button type="submit" className="submit-btn" form='edit-profile-form' disabled={!checkIsOkToSubmit() || tempDisabled === 1} ><span className="info">{props.appLanguage.profile.editProfileModal.confirm}</span></button></div>
                    <div className="cancel"><button className="cancel-btn" ><span className="info" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}>{props.appLanguage.profile.editProfileModal.cancel}</span></button></div>
                </div>
            </div>
        </div>
    );

}


export default connect(mapStateToProps)(EditProfileModal);
/*
                                        <select className="input-text input-country"
                                            id={FORMCOUNTRYID}
                                            onChange={(event: any) => changeInputHandler(event)}
                                            value={formInput.country}
                                            disabled={!formChange.countryChange}
                                        >
                                            <option hidden>{props.appLanguage.register.countryph}</option>
                                            <option value={GeneralServices.languages.por.tag}>{GeneralServices.languages.por.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.eng.tag}>{GeneralServices.languages.eng.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.rus.tag}>{GeneralServices.languages.rus.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.ger.tag}>{GeneralServices.languages.ger.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.esp.tag}>{GeneralServices.languages.esp.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.ita.tag}>{GeneralServices.languages.ita.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.fra.tag}>{GeneralServices.languages.fra.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.pol.tag}>{GeneralServices.languages.pol.tag.toUpperCase()}</option>
                                            <option value={GeneralServices.languages.ned.tag}>{GeneralServices.languages.ned.tag.toUpperCase()}</option>
                                        </select>

*/