import { createStore, combineReducers } from "redux";
import sessionReducer  from "./session/reducers";
import languageReducer from "./language/reducers";

import { SessionCookies, UserImage, UserSituation } from "./session/types";
import { LanguageJson } from "../services/languages/langTypes";


const rootReducer = combineReducers({
  sessionApp: sessionReducer,
  languageApp: languageReducer
});
const store = createStore(rootReducer);
export default store;

/*MAP REDUX STATES*/
export const mapStateToProps = (state: ReturnType<typeof rootReducer>) => {
  return {
    loggedIn: state.sessionApp.loggedIn,
    sessionToken: state.sessionApp.sessionToken,
    sessionUser: state.sessionApp.sessionUser,
    sessionRole: state.sessionApp.sessionRole,
    userImage: state.sessionApp.userImage,
    userSituation: state.sessionApp.situation,
    appLanguage: state.languageApp
  }
}
/*MAP REDUX ACTIONS*/
export const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutSession: (newSituation: UserSituation) => dispatch({ type: 'LOGOUT_SESSION', data: newSituation}),
    loginSession: (newSession: SessionCookies) => dispatch({ type: 'LOGIN_SESSION', data: newSession }),
    changeUserImage: (newImage: UserImage) => dispatch({ type: 'SET_USERIMAGE', data: newImage}),
    setLanguage: (newLanguage: LanguageJson) => dispatch({ type: 'SET_LANGUAGE', data: newLanguage })
  }
}
/*REDUX ALL PROPS*/
export type StoreProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
/*REDUX ONLY STATE PROPS*/
export type StoreStateProps = ReturnType<typeof mapStateToProps>;


/*MAP REDUX STANDARD*/
export const mapStateLanguageToProps = (state: ReturnType<typeof rootReducer>) => {
  return {
    appLanguage: state.languageApp
  }
}
/*REDUX MULTILINGUE PROPS*/
export type StoreLanguageProps = ReturnType<typeof mapStateLanguageToProps>;
