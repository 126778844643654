import React, { useEffect } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './CalendarPage.css'
import Calendar from '../calendar/Calendar';
import { IEvent } from '../eventPage/EventPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GeneralServices from '../../../services/generalService.json'


interface CalendarCell {
    dateStart: any
    dateEnd: any
    day: any
    paint: any
    in: boolean
    hasEvent: boolean
    dayOfWeek: any;
}

export interface VisorCalendar {
    date: CalendarCell
    events: IEvent[]
}

interface IProps extends StoreProps, RouteComponentProps<any> { }

export const CalendarPage: React.FC<IProps> = (props) => {

    const [currentEventDate, setCurrentEventDate] = React.useState<VisorCalendar>({ date: { dateStart: null, dateEnd: null, day: null, paint: null, in: false, hasEvent: false, dayOfWeek: null }, events: [] });

    const handleCbCurrentEventDate = (childData: VisorCalendar) => {
        if (childData) {
            //const dateLanguage = require('date-fns/locale/' + props.appLanguage.lang_code + '/index.js');
            //const date = new Date(childData.date.dateStart); //timestamp to date
            //const dayOfWeek = dateLanguage.localize.day(dateFns.getDay(date));
            const thisDate = new Date(childData.date.dateStart)
            const visor = props.appLanguage.events.daysOfWeekExt[thisDate.getDay()] + ", " + props.appLanguage.events.monthsOfYear[thisDate.getDay()] + " " + thisDate.getFullYear();
            setCurrentEventDate({ date: { ...childData.date, dayOfWeek: visor }, events: childData.events });
        }
    }

    const handleAccessEvent = (id: any) => {
        let eventURL = GeneralServices.eventPath.replace(':id', id)
        props.history.push(eventURL);
    }

    useEffect(() => {
        const thisDate = new Date(currentEventDate.date.dateStart)
        const visor = props.appLanguage.events.daysOfWeekExt[thisDate.getDay()] + ", " + props.appLanguage.events.monthsOfYear[thisDate.getDay()] + " " + thisDate.getFullYear();
        
        setCurrentEventDate({ ...currentEventDate, date: { ...currentEventDate.date, 
            dayOfWeek: visor }});
    }, [props.appLanguage])

    return (
        <div className="wrapper-kekax-calendar-page">
            <div className="container-info-flex">
                <div className="kekax-info-wrapper">
                    <div className="day-visor">
                        <div className="island">
                            <span className="kxDay">{currentEventDate.date.day}</span>
                            <span className="kxDayWeek">{currentEventDate.date.dayOfWeek}</span>
                        </div>
                    </div>
                    <div className="day-details">
                        <span className="day-details-title">{props.appLanguage.events.visor.title}</span>
                        <div className="day-details-list">
                            {
                                currentEventDate.events.length > 0 ?
                                    currentEventDate.events.map((event: IEvent, index: any) => (
                                        <div className="day-details-list-object object-linked" key={index} onClick={() => handleAccessEvent(event.id)}>
                                            <span className="dot">•</span>
                                            <span className="text">{event.title}</span>
                                        </div>
                                    ))
                                    :
                                    <div className="day-details-list-object">
                                        <span>{props.appLanguage.events.visor.noEvents}</span>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="kekax-calendar-wrapper">
                    <Calendar cbCurrentEventDate={handleCbCurrentEventDate} />
                </div>
            </div>
        </div>
    );

}

export default connect(mapStateToProps)(withRouter(CalendarPage));
