import React, { useState, useEffect, useRef } from 'react'
import "./AuxMineTypes.css"
import GeneralServices from "../../../../services/generalService.json"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
//import { httpGetAuxMineTypes } from '../../../../services/requestService';

import AddAuxMineTypes from './addAuxMineTypes/AddAuxMineTypes';
import EditAuxMineTypes from './editAuxMineTypes/EditAuxMineTypes';
import RmvAuxMineTypes from './rmvAuxMineTypes/RmvAuxMineTypes';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShowAuxMineTypes from './showAuxMineTypes/ShowAuxMineTypes';
import { httpGetAuxMineTypeAll } from '../../../../services/requestService';


interface IProps extends StoreProps {

}


interface IOpenAuxMineTypes {
    add: boolean
    edit: boolean
    rmv: boolean
    show: boolean
    mineTypes: IAuxMineTypes

}

const AUXCOUNTRYADD = "add"
const AUXCOUNTRYRMV = "rmv"
const AUXCOUNTRYEDIT = "edit"
const AUXCOUNTRYSHOW= "show"

export interface IAuxMineTypes {
    id: string
    type: string
    desc: string
    explanation: string
}

export const AuxMineTypes: React.FC<IProps> = (props) => {

    const maxArray: number = 10;
    const maxPagesArray: number = 5;

    const [mineTypesArray, setMineTypesArray] = useState<IAuxMineTypes[]>([]);
    //const [mineTypesArrayDisplay, setMineTypesArrayDisplay] = useState<IAuxMineTypes[]>([]);

    const [totalCountries, setTotalCountries] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const snackKekaxRef: any = useRef();

    const [openAuxMineTypes, setOpenAuxMineTypes] = useState<IOpenAuxMineTypes>({ edit: false, rmv: false, add: false, show:false, mineTypes: {id:"",type:"",desc:"",explanation:""}})



    useEffect(() => {
        /*
        const dummyAuxCountries = GeneralServices.taux5MineTypes
        let dummyAuxCountriesToAdd: IAuxMineTypes[] = []
        for (let i = 0; i < dummyAuxCountries.length; i++) {
            dummyAuxCountriesToAdd.push({
                id: i+1+"", type: dummyAuxCountries[i].type, desc: dummyAuxCountries[i].description,
                explanation: dummyAuxCountries[i].explanation
            })
        }
        setMineTypesArray([...dummyAuxCountriesToAdd])
        setTotalCountries(dummyAuxCountriesToAdd.length)
        */
        httpGetAuxMineTypeAll('?limit=' + maxArray).then(
            res => {
                //SETTING TOTAL Publication

                if (res.headers.total !== undefined) {
                    setTotalCountries(parseInt(res.headers.total, 10));
                } else {
                    setTotalCountries(parseInt('1', 10));
                }

                setMineTypesArray([...res.data]);


            }
        ).catch(
            err => {

            }
        )
    }, [])



    const handleOpenAuxMineTypes = (what: string, auxMineTypes: IAuxMineTypes | any) => {

        if (what === AUXCOUNTRYADD) {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...data, add: !data.add }))

        } else if (what === AUXCOUNTRYEDIT) {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...data, edit: !data.edit, mineTypes:auxMineTypes }))

        } else if (what === AUXCOUNTRYRMV) {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...data, rmv: !data.rmv, mineTypes: auxMineTypes }))

        } else if (what === AUXCOUNTRYSHOW) {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...data, show: !data.show, mineTypes: auxMineTypes }))

        }
    }

    const addAuxMineTypesHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.add.submitSuccess)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, add: false })
        } else {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...openAuxMineTypes, add: !data.add }))
        }
        loadNewPage(counter,false)
    }

    const editAuxMineTypesHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.edit.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.edit.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, edit: false })
        } else {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...openAuxMineTypes, edit: !data.edit }))
        }
        loadNewPage(counter,false)
    }

    const rmvAuxMineTypesHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.delete.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.mineTypes.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, rmv: false })
        } else {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...openAuxMineTypes, rmv: !data.rmv }))
        }
        loadNewPage(counter,false)
    }

    const showAuxMineTypesHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxMineTypes({ ...openAuxMineTypes, show: false })
        } else {
            setOpenAuxMineTypes((data: IOpenAuxMineTypes) => ({ ...openAuxMineTypes, show: !data.show }))
        }
        loadNewPage(counter,false)
    }


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalCountries / maxArray);
        const extra = totalCountries % maxArray;

        if (extra !== 0 || totalCountries === 0) {
            result += 1
        }

        setMaxCounter(result);
    }, [totalCountries])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, init: boolean) => {
        /*
        const startFrom = page === 0 ? 0 : (page - 1) * maxArray

        let newPageMines: IAuxMineTypes[] = []
        for (let i = startFrom, counter = 0; i < mineTypesArray.length && counter < maxArray; i++, counter++) {
            newPageMines.push(mineTypesArray[i]);

            if (newPageMines.length === maxArray || i === mineTypesArray.length - 1) {
                setMineTypesArrayDisplay([...newPageMines])
            }

            if (init) {
                window.scrollTo({
                    top: 0,
                    behavior: 'auto'
                })

            }
        }
        */
        const queryPage = page - 1;
        httpGetAuxMineTypeAll('?page=' + queryPage + '&limit=' + maxArray).then(
            res => {
                setMineTypesArray([...res.data]);
                if (init) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )

    }

    /*
    useEffect(() => {
        if (mineTypesArray.length > 0) {
            loadNewPage(0, true)
        }
    }, [mineTypesArray])
    */
    return (
        <React.Fragment>
            <div className="wrapper-aux-mineTypes">

                <div className="container-auxMineTypes">
                    <div className="auxMineTypes-menu">
                        <div className="auxMineTypes-menu-title"><span>{props.appLanguage.backoffice.manageMines.aux.mineTypes.title}</span></div>
                        <div className="auxMineTypes-menu-options">
                            <div className="auxMineTypes-opt-add" onClick={() => handleOpenAuxMineTypes(AUXCOUNTRYADD, null)}>
                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.mineTypes.addOpt} direction="top">
                                    <div className="auxMineTypes-opt-add-icon"></div>
                                    <span>{props.appLanguage.backoffice.manageMines.aux.mineTypes.addOpt}</span>
                                </TooltipKekax>
                            </div>
                        </div>
                    </div>

                    <div className="auxMineTypes-list">
                        {
                            mineTypesArray.map((auxMineTypes: IAuxMineTypes, index: number) => (
                                <div className="auxMineTypes-list-obj" key={"mineType"+index}>
                                    <div className="auxMineTypes-id"><span>{auxMineTypes.id}</span></div>
                                    <div className="auxMineTypes-title">
                                        <span>{auxMineTypes.type} </span>
                                    </div>
                                    <div className="auxMineTypes-options">
                                    <div className="auxMineTypes-obj-show" onClick={() => handleOpenAuxMineTypes(AUXCOUNTRYSHOW, auxMineTypes)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.mineTypes.showOpt} direction="top">
                                                <div className="auxMineTypes-obj-show-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                        <div className="auxMineTypes-obj-rmv" onClick={() => handleOpenAuxMineTypes(AUXCOUNTRYRMV, auxMineTypes)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.mineTypes.rmvOpt} direction="top">
                                                <div className="auxMineTypes-obj-rmv-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>

                </div>
                <div className="container-auxMineTypes-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>

            </div>

            <SnackKekax ref={snackKekaxRef} />
            <AddAuxMineTypes data={{ open: openAuxMineTypes.add, mineTypes: openAuxMineTypes.mineTypes }} callback={addAuxMineTypesHandler} />
            <RmvAuxMineTypes data={{ open: openAuxMineTypes.rmv, mineTypes: openAuxMineTypes.mineTypes }} callback={rmvAuxMineTypesHandler} />
            <ShowAuxMineTypes data={{ open: openAuxMineTypes.show, mineTypes: openAuxMineTypes.mineTypes }} callback={showAuxMineTypesHandler} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(AuxMineTypes);
