import React, { useEffect, useRef, useState } from 'react';
import { IGameRound, IRound } from '../QuizPage';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import "./GodCall.css"
import { alphabet } from '../questionPage/QuestionPage';

interface IProps extends StoreProps {
    open: boolean
    what: number
    callback: any
    breakTime: number

    gameRound: IGameRound
    allRounds: IRound[]
}

interface IGodCallStats {
    total: number
    roundsPlayed: number
    correct: number
    wrong: number
    empty: number
}

export const GodCall: React.FC<IProps> = (props) => {


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);

    const msgsRef = useRef<any>([]);
    const [stats, setStats] = useState<IGodCallStats>({ correct: 0, wrong: 0, empty: 0, total: 0, roundsPlayed: 0 })
    const [answer, setAnswer] = useState<string>("")


    useEffect(() => {
        if (props.open && modalRef) {
            modalRef.current.classList.add("godCall-modal-open");
            //document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();
        }
        else {
            modalRef.current.classList.remove("godCall-modal-open");
        }
    }, [props.open])


    useEffect(() => {
        if (props.open && modalRef) {
            if (msgsRef.current[0] !== null && props.breakTime === 14) {
                msgsRef.current[0].classList.add("chat-msg-active")
            } else if (msgsRef.current[1] !== null && props.breakTime === 12) {
                msgsRef.current[1].classList.add("chat-msg-active")
            } else if (msgsRef.current[2] !== null && props.breakTime === 10) {
                msgsRef.current[2].classList.add("chat-msg-active")
            } else if (props.breakTime === 0) {
                closeModalHandler()
            }
        }

    }, [props.open, props.breakTime])

    useEffect(() => {
        if (props.allRounds.length > 0 && props.open && modalRef) {
            let finalStats: IGodCallStats = { correct: 0, wrong: 0, empty: 0, total: props.gameRound.maxRound, roundsPlayed: props.gameRound.nRound - 1 }
            for (let i = 0; i < props.gameRound.nRound - 1; i++) {
                if (props.allRounds[i].decision === "") {
                    finalStats.empty += 1
                } else if (props.allRounds[i].decision !== props.allRounds[i].correct) {
                    finalStats.wrong += 1
                } else if (props.allRounds[i].decision === props.allRounds[i].correct) {
                    finalStats.correct += 1
                }
            }
            setStats({ ...stats, ...finalStats })
        }
    }, [props.open, props.allRounds, props.gameRound])


    useEffect(() => {
        if (stats.total !== 0 && props.open && modalRef) {


            let winRate = 0
            let playedRate = 0

   
            if (stats.roundsPlayed > 0) {
                winRate = Math.round(stats.correct * 100.0 / stats.roundsPlayed) / 100
                playedRate = Math.round(stats.roundsPlayed * 100.0 / stats.total) / 100
            }
     

            let minNum = 0.2
            let maxNum = 1.0


            if (playedRate >= 0.5 && winRate <= 0.5) {
                minNum = 0.4
            } else if (playedRate >= 0.5 && winRate >= 0.5) {
                minNum = 0.3
            }




            let num = Math.random() * (maxNum - minNum) + minNum;
            num = Math.round(num * 10) / 10 //decimal
            num = Math.round(num) //unidade
         
            //num===1 answer é certa || num===0 gera answer falsa
            let answer = props.allRounds[props.gameRound.nRound - 1].correct
            const nAnswers = props.allRounds[props.gameRound.nRound - 1].answers.length
            if (num === 0) {
                let randomAnswer = getRandomIntInclusive(0, nAnswers-1)
                while (alphabet[randomAnswer] === props.allRounds[props.gameRound.nRound - 1].correct || props.allRounds[props.gameRound.nRound - 1].answers[randomAnswer].available === false) {
                    randomAnswer = getRandomIntInclusive(0, nAnswers-1)
                }
                answer = alphabet[randomAnswer]
            }
            setAnswer(answer)
            /*
            console.log(winRate)
            console.log(playedRate)
            console.log(minNum)
            console.log(maxNum)
            console.log(answer)
            */
        }
    }, [props.open, stats, props.allRounds, props.gameRound])

    const getRandomIntInclusive = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--godCallModal-topOffset", posY + "px");
    }

    /*
    const handleClickAway = (event: any) => {
        if (contentRef && !contentRef.current.contains(event.target)) {
            closeModalHandler(false);
        }
    }
    */

    const closeModalHandler = () => {

        modalRef.current.classList.remove("godCall-modal-open");
        //document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);

        props.callback('closed');
    }


    return (


        <div className="wrapper-godCall-modal" ref={modalRef}>
            <div className="overlay"></div>
            <div className="close" onClick={() => closeModalHandler()}>
                <div className="close-mark"></div>
            </div>
            <div className="content" ref={contentRef}>

                <div className="body">
                    <div className="god-chat">
                        <div className="chat-god-msg" ref={el => msgsRef.current[0] = el}>
                            <div className="chat-msg">
                                <div className="pic"><div className="photo"></div></div>
                                <div className="msg"><span>{props.appLanguage.quizPage.quiz.godCallHelper.msgs[0]}</span></div>
                            </div>
                        </div>
                        <div className="chat-user-msg" ref={el => msgsRef.current[1] = el}>
                            <div className="chat-msg">
                                <div className="msg"><span>{props.appLanguage.quizPage.quiz.godCallHelper.msgs[1]}</span></div>
                                <div className="pic"><div className="photo"></div></div>
                            </div>
                        </div>
                        <div className="chat-god-msg" ref={el => msgsRef.current[2] = el}>
                            <div className="chat-msg">
                                <div className="pic"><div className="photo"></div></div>
                                <div className="msg"><span>{props.appLanguage.quizPage.quiz.godCallHelper.msgs[2]}</span> <span className="solution">{props.appLanguage.quizPage.quiz.godCallHelper.msgs[3]} {answer}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="return">
                        <div className="text"><span>{props.appLanguage.quizPage.quiz.godCallHelper.endCall}</span></div>
                        <div className="time"><span >{props.breakTime}</span></div>
                    </div>

                </div>
            </div>
        </div>
    )

}
export default connect(mapStateToProps)(GodCall);
