import React, { useState, useEffect, useRef } from 'react'
import "./ListEventStats.css"
import { httpGetListEvents } from '../../../../services/requestService'
import { IEvent } from '../../../calendarEvents/eventPage/EventPage';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from './../../../../store/storeApp';
import { translateFormatTimestamp } from '../../../calendarEvents/calendar/Calendar';
import { isSameDay } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteEventModal, { IDeleteEvent } from '../deleteEventModal/DeleteEventModal';
import EditEventModal, { IEditEvent } from '../editEventModal/EditEventModal';
import GeneralServices from '../../../../services/generalService.json'
import SnackKekax from '../../../kekax/SnackKekax';


interface IProps extends StoreProps {

}

interface SnackKekax {
    open: boolean,
    message: string
}



export const ListEventStats: React.FC<IProps> = (props) => {

    const [eventsArray, setEventsArray] = useState<IEvent[]>([]);
    const [totalEvents, setTotalEvents] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);



    const [deleteModal, setDeleteModal] = useState<IDeleteEvent>({ open: false, event: '', eventId: '' });
    const [editModal, setEditModal] = useState<IEditEvent>({ open: false, eventId: "" });//KEKAXEDIT - false

    const maxEventArray: number = 10;
    const maxPagesArray: number = 5;
    const snackKekaxRef: any = useRef();


    useEffect(() => {

        httpGetListEvents(null).then(
            res => {
                //SETTING TOTAL EVENT
                if (res.headers.total !== undefined) {
                    setTotalEvents(parseInt(res.headers.total, 10));
                } else {
                    setTotalEvents(parseInt('1', 10));
                }
                setEventsArray([...res.data]);
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }, [])




    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalEvents / maxEventArray);
        const extra = totalEvents % maxEventArray;

        if (extra !== 0 || totalEvents === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalEvents])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax,true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax,true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax,true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }

        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }
            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, scroll: boolean) => {
        const queryPage = page - 1;
        httpGetListEvents('?page=' + queryPage).then(
            res => {
                setEventsArray([...res.data]);
                if (scroll) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )
    }

    const handleSameDate = (start: any, end: any) => {
        if (isSameDay(start, end)) {
            return true
        }
        return false;
    }


    const deleteEventHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.delete.success)
            setTotalEvents((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal({ ...deleteModal, open: false });
        } else {
            setDeleteModal({ ...deleteModal, open: !deleteModal.open });
        }
        loadNewPage(counter,false)
    }

    const editEventHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setEditModal({ ...editModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.edit.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageEvents.edit.failed)
            setEditModal({ ...editModal, open: false });
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal({ ...editModal, open: false });
        } else {
            setEditModal({ ...editModal, open: !editModal.open });
        }
        loadNewPage(counter,false)
    }






    return (
        <React.Fragment>
            <div className="wrapper-list-event-stats">
                <div className="list-event-stats-header">

                </div>
                <div className="list-event-stats-body">
                    {
                        eventsArray.map((event: IEvent, index: number) => (
                            <div className="event-stats-object" key={index}>
                                <div className="event-stats-object-union">
                                    <div className="event-stats-object-content">
                                        <span className="event-object-title">{event.title}</span>
                                        {
                                            !handleSameDate(event.timestamp, event.timestampEnd) ?
                                                <div className="event-object-date">
                                                    <span className="event-object-datestart">{translateFormatTimestamp(event.timestamp, props.appLanguage)}</span>
                                                    <span className="event-object-dot">•</span>
                                                    <span className="event-object-dateend">{translateFormatTimestamp(event.timestamp, props.appLanguage)}</span>
                                                </div>
                                                :
                                                <div className="event-object-date">
                                                    <span className="event-object-datestart">{translateFormatTimestamp(event.timestamp, props.appLanguage)}</span>
                                                </div>
                                        }
                                    </div>
                                    <div className="event-stats-object-menu">
                                        <div className="object-menu-opt opt-delete" onClick={() => setDeleteModal({ open: true, event: event.title, eventId: event.id })}>
                                            <DeleteIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageEvents.delete.option}</span>
                                        </div>
                                        <div className="object-menu-opt opt-edit" onClick={() => setEditModal({ open: true, eventId: event.id })}>
                                            <EditIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageEvents.edit.option}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="list-event-stats-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>
            </div>

            <DeleteEventModal event={deleteModal} callback={deleteEventHandler} />
            <EditEventModal event={editModal} callback={editEventHandler} />

            <SnackKekax ref={snackKekaxRef} />


        </React.Fragment>
    );

}


export default connect(mapStateToProps)(ListEventStats);

