import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './AddConsortium.css';
import { httpPostConsortium } from '../../../../services/requestService';

import GeneralServices from "../../../../services/generalService.json"
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';

import ReactMarkdown from 'react-markdown';
import TooltipInfo from '../../newsManage/addNewsletter/tooltipInfo/TooltipInfo';
import dummyConsortium from '../../../../assets/news/newsDelta.png'

interface IProps extends StoreProps {
    cbMessage: any;
}

export interface IFormErrorConsortium {
    titleError: string
    websiteError: string
    contentError: string
}
export interface IFormInputConsortium {
    title: string
    website: string
    content: string

}
export interface IFormValidConsortium {
    titleValid: boolean
    contentValid: boolean
    websiteValid: boolean

}
export interface IFormFocusConsortium {
    titleFocus: boolean
    contentFocus: boolean
    websiteFocus: boolean
}


interface SnackKekax {
    open: boolean,
    message: string
}

const FORMTITLEID: string = 'title';
const FORMCONTENTID: string = 'content';
const FORMWEBSITEID: string = 'website';


export const AddConsortium: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(false);
    const [formError, setFormError] = useState<IFormErrorConsortium>({ titleError: '', websiteError: '', contentError: '' });
    const [formInput, setFormInput] = useState<IFormInputConsortium>({ title: '', website: '', content: '' });
    const [formValid, setFormValid] = useState<IFormValidConsortium>({ titleValid: false, contentValid: false, websiteValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusConsortium>({ titleFocus: false, contentFocus: false, websiteFocus: false });


    const formRef = useRef<any>(null);
    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);

    const [selectedImage, setSelectedImage] = useState<any>(dummyConsortium);
    const [consortiumImage, setConsortiumImage] = useState<any>(null);
    const refSelectedImage: any = useRef(null);

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);






    const closeAddConsortiumHandler = (what: string) => {
        formRef.current.reset();
        setFormSubmitValid(false);
        setFormError({ titleError: '', websiteError: '', contentError: '' });
        setFormInput({ title: '', website: '', content: '' })
        setFormValid({ titleValid: false, contentValid: false, websiteValid: false })
        setFormFocus({ titleFocus: false, contentFocus: false, websiteFocus: false })
        setSelectedImage(dummyConsortium);
        setConsortiumImage(null);


        setTempDisabled(0)

        props.cbMessage(what);

    }

    const onClickFile = () => {
        refSelectedImage.current.click();
    }

    const onFileSelected = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.files[0]) {
            //setConsortiumImage(event.target.files[0])
            let reader = new FileReader();
            reader.onloadend = () => {
                setConsortiumImage(reader.result)
                setSelectedImage(reader.result);
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    const removePhotoHandler = () => {
        if (consortiumImage !== null) {
            setConsortiumImage(null)
            setSelectedImage(dummyConsortium)
        }
    }




    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            case FORMCONTENTID: {
                setFormInput({ ...formInput, content: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_contentValid = formValid.contentValid;
        let upd_titleValid = formValid.titleValid;
        let upd_websiteValid = formValid.websiteValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageConsortiums.add.titleAccept;
        }
        /*WEBSITE VALIDATION*/
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageConsortiums.add.websiteAccept;
        }
        /*CONTENT VALIDATION*/
        if (formInput.content === '') {
            upd_contentValid = false;
            fieldValidationErrors.contentError = props.appLanguage.backoffice.manageConsortiums.add.requiredError;
        } else {
            upd_contentValid = true;
            fieldValidationErrors.contentError = props.appLanguage.backoffice.manageConsortiums.add.contentAccept;
        }

        setFormError(fieldValidationErrors)
        setFormValid({ contentValid: upd_contentValid, titleValid: upd_titleValid, websiteValid: upd_websiteValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid(formValid.contentValid && formValid.titleValid && formValid.websiteValid)
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMCONTENTID: {
                setFormFocus({ ...formFocus, contentFocus: true })
                break;
            }
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1);

        /*
        console.log("title " + formInput.title)
        console.log("website " + formInput.website)
        console.log("content " + formInput.content)

        if (consortiumImage !== null) {
            console.log("image name" + consortiumImage)
        }
        */
        
        httpPostConsortium({
            title: formInput.title,
            website: formInput.website,
            text: formInput.content,
            img: consortiumImage !== null ? consortiumImage : ""

        }).then(
            async res => {
                closeAddConsortiumHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            err => {
                closeAddConsortiumHandler(GeneralServices.constants.child.failed);
            }
        )
        

        /*closeAddConsortiumHandler(GeneralServices.constants.child.closed);*/

    }




    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMCONTENTID: {
                return formFocus.contentFocus && !formValid.contentValid ?
                    <span className="input-invalid-feedback-kekax">{formError.contentError}</span>
                    : formValid.contentValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.contentAccept}</span>
                        : null
            }
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.titleAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && !formValid.websiteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageConsortiums.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }


    return (
        <React.Fragment>
            <div className="wrapper-add-consortium">
                <form ref={formRef} id='add-consortium-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="container-add-consortium-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.main}</span></div>

                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageConsortiums.add.title} *
                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageConsortiums.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-website">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageConsortiums.add.website} *

                                </label>
                                <input className="input-website-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageConsortiums.add.websiteph}
                                    id={FORMWEBSITEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.website || ""}

                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMWEBSITEID)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container-add-consortium-content">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.title}</span></div>
                        <div className="add-consortium-image">
                            <div className="add-consortium-image-preview">
                                <img className="image" src={selectedImage} alt='consortiumImgAdd'></img>
                                <div className="image-options" >
                                    <TooltipKekax content={props.appLanguage.backoffice.manageConsortiums.add.imageOptions[0]} direction="right">
                                        <button type="button" className="image-change-fab" onClick={onClickFile}><div className="change" /></button >
                                    </TooltipKekax>
                                    <input ref={refSelectedImage} hidden type="file" accept='image/*' onChange={onFileSelected} />
                                    <TooltipKekax content={props.appLanguage.backoffice.manageConsortiums.add.imageOptions[1]} direction="right">
                                        <button type="button" className={consortiumImage !== null ? "image-delete-fab" : "image-delete-fab image-hidden-fab"} onClick={removePhotoHandler}><div className="delete" /></button >
                                    </TooltipKekax>
                                </div>
                            </div>
                            <div className="add-consortium-image-content">
                                <span className="text-one">{props.appLanguage.backoffice.manageConsortiums.add.imageph[0]}</span>
                                <span className="text-two">{props.appLanguage.backoffice.manageConsortiums.add.imageph[1]}</span>

                            </div>
                        </div>
                        <div className="container-toolbar">
                            <div className="title"><span>{props.appLanguage.backoffice.manageConsortiums.add.content} *</span></div>
                            <div className="options">
                                <TooltipInfo content={props.appLanguage.backoffice.manageConsortiums.add.information.content.info} direction="top">
                                    <div className="opt-info">
                                        <div className="icon"></div>
                                    </div>
                                </TooltipInfo>
                            </div>
                        </div>


                        <div className="feedback-block">
                            {
                                validatorFeedback(FORMCONTENTID)
                            }
                        </div>

                        <div className="add-consortium-content-label">
                            <div className="side-label"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.input}</span></div>
                            <div className="side-label"><span>{props.appLanguage.backoffice.manageConsortiums.add.information.content.preview}</span></div>
                        </div>
                        <div className="add-consortium-content-text">
                            <div className="add-consortium-content-text-input">
                                <textarea required className="content-text" id={FORMCONTENTID} placeholder={props.appLanguage.backoffice.manageConsortiums.add.contentph}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.content || ""}
                                />
                            </div>
                            <div className="add-consortium-content-text-preview">
                                <ReactMarkdown className="content-preview" source={formInput.content + ""} escapeHtml={false} />
                            </div>
                        </div>


                    </div>

                    <div className="container-form-bottom">
                        <div className="submit"><button type="submit" className="submit-btn" disabled={!formSubmitValid || tempDisabled === 1}><span className="info">{props.appLanguage.backoffice.manageConsortiums.add.submit}</span></button></div>
                    </div>
                </form>
            </div>

            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );
}

export default connect(mapStateToProps)(AddConsortium);