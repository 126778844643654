import React, { useEffect, useState } from 'react';
import './Background.css';
import Clouds from '../../../assets/images/clouds.png';

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import Mine from '../../../assets/icons/mine.png';
import Exam from '../../../assets/icons/exam.png';
import Save from '../../../assets/icons/save.png';

/*
interface ISlides {
    indicator: boolean;
    interval: number;
    controls: boolean;
    fade: boolean;
    loop: boolean;
}
*/

interface ICarouselInfo {
    title: string
    text: string[]
}

interface IProps extends StoreProps { }

export const Background: React.FC<IProps> = (props) => {

    const [current, setCurrent] = useState<number>(0);

    useEffect(() => {
        /*
        const interval = setInterval(() => {

        }, 1000);
        return () => clearInterval(interval);
        */

        const interval = setInterval(() => {


            if (current === carouselData.length - 1) {
                setCurrent(0);
            } else {
                setCurrent(current + 1);
            }

        }, 8000);
        return () => clearInterval(interval)
    }, [current]);


    const carouselData: ICarouselInfo[] = [
        {
            title: props.appLanguage.wallpaper.slide1.text1,
            text: props.appLanguage.wallpaper.slide1.text2
        },
        {
            title: props.appLanguage.wallpaper.slide2.text1,
            text: props.appLanguage.wallpaper.slide2.text2
        },
        {
            title: props.appLanguage.wallpaper.slide3.text1,
            text: props.appLanguage.wallpaper.slide3.text2
        },
    ]

    const deploySlide = (index: number) => {
        if (current === index) {
            return (
                <div className="info active" key={"slide" + index}>
                    <div><span className="text1">{carouselData[current].title}</span></div>
                    <div><span className="text2">{carouselData[current].text}</span></div>
                </div>
            )
        }
    }


    return (
        <div className="wallpaper-wrapper">
            <div className="slide">

                {
                    carouselData.map((slide: ICarouselInfo, index: number) => (
                        deploySlide(index)
                    ))
                }

            </div>
            <img src={Clouds} className="container-clouds" alt="kximg" />
        </div>
    );


}


export default connect(mapStateToProps)(Background);
