import React, { useEffect, useRef, useState } from 'react';
import "./EditMineModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import { FlagOption, IFormErrorMine, IFormFocusMine, IFormInputMine, IFormRound, IFormRoundValid, IFormRoundError, IFormRoundFocus, IFormValidMine, IMineValidationSteps } from '../addMine/AddMine';
import GeneralServices from '../../../../services/generalService.json'
import SnackKekax from '../../../kekax/SnackKekax';
//import { httpGetMineDetails, httpPutMine } from '../../../../services/requestService';
import ReactMapGL, { Marker } from 'react-map-gl';
import { IMap, IMineInfo, mapToken } from '../../../mineMap/MineMap';
import { alphabet } from '../../../game/quizPage/questionPage/QuestionPage';
import StepperKekax from '../../../kekax/StepperKekax';
import BibliographyAdd, { IFormErrorBibliography, IFormFocusBibliography, IFormInputBibliography, IFormValidBibliography } from '../addMine/bibliographyAdd/BibliographyAdd';
import LegendsHistoryEventsAdd, { IFormErrorLegendsHistoryEvents, IFormFocusLegendsHistoryEvents, IFormInputLegendsHistoryEvents, IFormValidLegendsHistoryEvents } from '../addMine/legendsHistoryEventsAdd/LegendsHistoryEventsAdd';
import MineProductionAdd, { IFormErrorMineProductions, IFormFocusMineProductions, IFormInputMineProductions, IFormValidMineProductions } from '../addMine/mineProductionAdd/MineProductionAdd';
import FacilitiesInfoAdd, { IFormErrorFacilitiesInfo, IFormFocusFacilitiesInfo, IFormInputFacilitiesInfo, IFormValidFacilitiesInfo } from '../addMine/facilitiesInfoAdd/FacilitiesInfoAdd';
import TooltipKekax from '../../../kekax/TooltipKekax';
import { IFormInputAuxCountry } from '../auxCountry/addAuxCountry/AddAuxCountry';
import { IFormInputAuxMineTypes } from '../auxMineTypes/addAuxMineTypes/AddAuxMineTypes';
import { IFormInputAuxSituation } from '../auxSituation/addAuxSituation/AddAuxSituation';
import { IFormInputAuxOfficialLanguages } from '../auxOfficialLanguages/addAuxOfficialLanguages/AddAuxOfficialLanguages';
import { IFormInputAuxFacilityType } from '../auxFacilityType/addAuxFacilityType/AddAuxFacilityType';
import { IFormInputAuxCategories } from '../auxCategories/addAuxCategories/AddAuxCategories';
import { httpPutMineInventory, httpGetAuxCategoriesAll, httpGetAuxCountryAll, httpGetAuxFacilityTypeAll, httpGetAuxMineTypeAll, httpGetAuxOfficialLanguageAll, httpGetAuxSituationAll, httpGetMineBibliography, httpGetMineFacility, httpGetMineProdution, httpGetMineSiteHistoricEvents, httpGetMineSiteLegends, httpGetQuestionMine, httpPutMineFacility, httpPostMineFacility, httpDeleteMineFacility, httpPostMineProduction, httpDeleteMineProduction, httpDeleteMineSiteHistoricEvents, httpPostMineSiteHistoricEvents, httpPostMineSiteLegends, httpDeleteMineSiteLegends, httpDeleteMineBibliography, httpPostMineBibliography, httpPutMineSiteHistoricEvents, httpPutMineSiteLegends, httpPutMineBibliography } from '../../../../services/requestService';
import FacilitiesInfoEdit from './facilitiesInfoEdit/FacilitiesInfoEdit';
import MineProductionEdit from './mineProductionEdit/MineProductionEdit';
import LegendsHistoryEventsEdit from './legendsHistoryEventsEdit/LegendsHistoryEventsEdit';
import BibliographyEdit from './bibliographyEdit/BibliographyEdit';

interface IProps extends StoreProps {
    callback: any
    mine: IEditMine
}

export interface IEditMine {
    open: boolean
    info: IMineInfo
}



interface SnackKekax {
    open: boolean
    message: string
}


//Site
const FORMMINECODEID: string = 'mineCode';
const FORMWEBSITEID: string = 'website';
const FORMSITENAMEENGID: string = 'siteNameENG;'
const FORMSITENAMENOLID: string = 'siteNameNOL';
const FORMCOUNTRYID: string = 'country';
const FORMREGIONSTATEPROVINCEID: string = 'regionStateProvince'
const FORMMUNICIPALITYID: string = 'municipality'
const FORMTOURISTICNAMEID: string = 'touristicname'
const FORMMINEPOPDESCID: string = 'minePopDesc'
const FORMCOMPLEXID: string = 'complex'
const FORMCOMPLEXNAMEID: string = 'complexName'
const FORMMINEHERITAGEROUTEID: string = 'mineHeritageRoute'
const FORMPROJECTFIGID: string = 'projectFig'
const FORMINFOFIGUREID: string = 'infoFigure'
const FORMCARPARKID: string = 'carPark'
const FORMCAFETARIAID: string = 'cafeteria'
const FORMGIFTSHOPID: string = 'giftShop'
const FORMADDRESSID: string = 'address'
const FORMADDRESS2ID: string = 'address2'
const FORMPOSTALCODEID: string = 'postalCode'
const FORMPHONEID: string = 'phone'
const FORMMAILID: string = 'mail'
const FORMOTHERINFOCONTID: string = 'otherInfoCont'
const FORMPUBTRANSPORTID: string = 'pubTransport'
const FORMPUBTRANSPORTDESCID: string = 'pubTransportDesc'

//Quiz
const FORMROUNDID: string = 'round';
const FORMQUESTIONID: string = 'question';
const FORMANSWERID: string = 'answer';



const MAX_STEPS = 5

export function EditMineModal(props: IProps) {

    const [formSubmitValid, setFormSubmitValid] = useState<IMineValidationSteps>({ inventory: false, facilities: false, mineProductions: false, quiz: false, });
    const [facilitiesInfoValid, setFacilitiesInfoValid] = useState<boolean>(true)
    const [mineProductionsValid, setMineProductionsValid] = useState<boolean>(true)
    const [legendsHistoryEventsValid, setLegendsHistoryEventsValid] = useState<boolean>(true)
    const [bibliographyValid, setBibliographyValid] = useState<boolean>(true)

    const [formError, setFormError] = useState<IFormErrorMine>({
        mineCodeError: '', websiteError: '', siteNameENGError: '', siteNameNOLError: '', countryError: '',
        regionStateProvinceError: '', municipalityError: '', touristicnameError: '', minePopDescError: '', complexError: '', complexNameError: '', mineHeritageRouteError: ''
        , projectFigError: '', infoFigureError: '', carParkError: '', cafeteriaError: '', giftShopError: '', addressError: '', address2Error: '', postalCodeError: '', phoneError: '',
        mailError: '', otherInfoContError: '', pubTransportError: '', pubTransportDescError: '',
        roundsError: []
    });
    const [formInput, setFormInput] = useState<IFormInputMine>({
        lat: null, lon: null, mineCode: '', website: '', siteNameENG: '', siteNameNOL: '', country: '',
        regionStateProvince: '', municipality: '', touristicname: '', minePopDesc: '', complex: '', complexName: '', mineHeritageRoute: '', projectFig: '', infoFigure: '',
        carPark: '', cafeteria: '', giftShop: '', address: '', address2: '', postalCode: '', phone: '', mail: '', otherInfoCont: '', pubTransport: '', pubTransportDesc: '',
        rounds: []
    });
    const [formValid, setFormValid] = useState<IFormValidMine>({
        mineCodeValid: false, websiteValid: false, siteNameENGValid: false, siteNameNOLValid: false, countryValid: false,
        regionStateProvinceValid: false, municipalityValid: false, touristicnameValid: false, minePopDescValid: false, complexValid: false, complexNameValid: false,
        mineHeritageRouteValid: false, projectFigValid: false, infoFigureValid: false, carParkValid: false, cafeteriaValid: false, giftShopValid: false, addressValid: false,
        address2Valid: false, postalCodeValid: false, phoneValid: false, mailValid: false, otherInfoContValid: false, pubTransportValid: false, pubTransportDescValid: false,
        roundsValid: [], pointValid: false
    });

    const [formFocus, setFormFocus] = useState<IFormFocusMine>({
        mineCodeFocus: false, websiteFocus: false, siteNameENGFocus: false, siteNameNOLFocus: false, countryFocus: false,
        regionStateProvinceFocus: false, municipalityFocus: false, touristicnameFocus: false, minePopDescFocus: false, complexFocus: false, complexNameFocus: false,
        mineHeritageRouteFocus: false, projectFigFocus: false, infoFigureFocus: false, carParkFocus: false, cafeteriaFocus: false, giftShopFocus: false, addressFocus: false,
        address2Focus: false, postalCodeFocus: false, phoneFocus: false, mailFocus: false, otherInfoContFocus: false, pubTransportFocus: false, pubTransportDescFocus: false,
        roundsFocus: []
    });

    const [formInputFacilitiesInfo, setFormInputFacilitiesInfo] = useState<IFormInputFacilitiesInfo[]>([]);
    const [formErrorFacilitiesInfo, setFormErrorFacilitiesInfo] = useState<IFormErrorFacilitiesInfo[]>([]);
    const [formValidFacilitiesInfo, setFormValidFacilitiesInfo] = useState<IFormValidFacilitiesInfo[]>([]);
    const [formFocusFacilitiesInfo, setFormFocusFacilitiesInfo] = useState<IFormFocusFacilitiesInfo[]>([]);

    const [formInputMineProductions, setFormInputMineProductions] = useState<IFormInputMineProductions[]>([]);
    const [formErrorMineProductions, setFormErrorMineProductions] = useState<IFormErrorMineProductions[]>([]);
    const [formValidMineProductions, setFormValidMineProductions] = useState<IFormValidMineProductions[]>([]);
    const [formFocusMineProductions, setFormFocusMineProductions] = useState<IFormFocusMineProductions[]>([]);

    const [formInputLegendsHistoryEvents, setFormInputLegendsHistoryEvents] = useState<IFormInputLegendsHistoryEvents | null>(null);
    const [formErrorLegendsHistoryEvents, setFormErrorLegendsHistoryEvents] = useState<IFormErrorLegendsHistoryEvents | null>(null);
    const [formValidLegendsHistoryEvents, setFormValidLegendsHistoryEvents] = useState<IFormValidLegendsHistoryEvents | null>(null);
    const [formFocusLegendsHistoryEvents, setFormFocusLegendsHistoryEvents] = useState<IFormFocusLegendsHistoryEvents | null>(null);


    const [formInputBibliography, setFormInputBibliography] = useState<IFormInputBibliography | null>(null);
    const [formErrorBibliography, setFormErrorBibliography] = useState<IFormErrorBibliography | null>(null);
    const [formValidBibliography, setFormValidBibliography] = useState<IFormValidBibliography | null>(null);
    const [formFocusBibliography, setFormFocusBibliography] = useState<IFormFocusBibliography | null>(null);


    const snackKekaxRef: any = useRef();
    const [tempDisabled, setTempDisabled] = useState<number>(0);

    const [viewport, setViewport] = useState<IMap>({ longitude: 0, latitude: 0, zoom: 0 });
    const mapRef = useRef(null)

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [flagsDropdown, setFlagsDropdown] = useState({ open: false, class: 'flags-menu-drop' });
    const refFlagsDropdownMenu = useRef<any>(null);
    const refFlagsDropdownButton = useRef<any>(null);
    //const [flagsArrayAll, setFlagsArrayAll] = useState<FlagOption[]>([])

    const [countryAuxArray, setCountryAuxArray] = useState<IFormInputAuxCountry[]>([])
    const [facilityTypeAuxArray, setFacilityTypeAuxArray] = useState<IFormInputAuxFacilityType[]>([])
    const [mineTypeAuxArray, setMineTypeAuxArray] = useState<IFormInputAuxMineTypes[]>([])
    const [officialLanguageAuxArray, setOfficialLanguageAuxArray] = useState<IFormInputAuxOfficialLanguages[]>([])
    const [situationeAuxArray, setSituationAuxArray] = useState<IFormInputAuxSituation[]>([])
    const [categoriesAuxArray, setCategoriesAuxArray] = useState<IFormInputAuxCategories[]>([])

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);


    useEffect(() => {
        if (props.mine.open && modalRef.current) {
            modalRef.current.classList.add("editMine-modal-open");
            loadMineById();
            loadAuxTables();
            handleOffset();

            setViewport({
                longitude: 3.4834177590148587,
                latitude: 43.59630686976215,
                zoom: 5
            })
            //setFormInput({ ...formInput, rounds: [{ question: "", correct: "", answers: ["", ""] }] })
            document.addEventListener('mousedown', handleEventListeners);
            window.addEventListener('resize', handleEventListeners);
            document.addEventListener('scroll', handleOffset);
            /*
            let countries: FlagOption[] = []
    
            for (let i = 0; i < GeneralServices.countries.length; i++) {
                countries.push({
                    id: parseInt(GeneralServices.countries[i].id),
                    tag: GeneralServices.countries[i].tag,
                    shortNameENG: GeneralServices.countries[i].shortNameENG,
                    shortNameNOL: GeneralServices.countries[i].shortNameNOL,
                    officialName: GeneralServices.countries[i].officialName,
                    flag: require('../../../../assets/flags/' + GeneralServices.countries[i].tag + '.png')

                })
                if (i === GeneralServices.countries.length - 1) {
                    setFlagsArrayAll([...countries])
                }
            }
            */


        } else {
            modalRef.current.classList.remove("editMine-modal-open");

        }
        return () => {
            document.removeEventListener('mousedown', handleEventListeners);
            window.removeEventListener('resize', handleEventListeners);
        }
    }, [props.mine.open])

    const loadMineById = async () => {
        const mineCode = props.mine.info.mineCode;

        httpGetMineFacility(mineCode).then(
            (res: any) => {
                const data = [...res.data];
                let dataFocus: IFormFocusFacilitiesInfo[] = []
                let dataValid: IFormValidFacilitiesInfo[] = []
                let dataError: IFormErrorFacilitiesInfo[] = []
                let dataInput: IFormInputFacilitiesInfo[] = []
                for (let i = 0; i < data.length; i++) {
                    dataError.push(
                        {
                            facilityCodeError: '', facilityTypeError: '', facilityNameError: '', facilityOtherNameError: '', facilityDescriptionError: '',
                            mineCategoryError: '', mineTypeError: '', usesMineError: '', situationError: '', openingYearError: '', visitRouteAvailableError: '', timeNeededForTheVisitError: '', routeDescriptionError: ''
                            , guidedVisitError: '', freeTourAvailableError: '', adventureError: '', adaptedVisitAvailableError: '', brochuresPrintedInfoError: '', audioVisualResourcesError: '', workshopsForYoungAdultsError: ''
                            , explanationsForChildrenError: '', arVrResourcesError: '', workshopsForDisabledError: '', explanationsForDisabledError: '', workshopsForChildrenError: '', workshopsForAdventureError: ''
                            , languagesError: '', educationalVisitAvailableInEnglishError: ''

                        }
                    )
                    dataValid.push(
                        {
                            facilityCodeValid: false, facilityTypeValid: false, facilityNameValid: false, facilityOtherNameValid: false, facilityDescriptionValid: false,
                            mineCategoryValid: false, mineTypeValid: false, usesMineValid: false, situationValid: false, openingYearValid: false, visitRouteAvailableValid: false, timeNeededForTheVisitValid: false, routeDescriptionValid: false
                            , guidedVisitValid: false, freeTourAvailableValid: false, adventureValid: false, adaptedVisitAvailableValid: false, brochuresPrintedInfoValid: false, audioVisualResourcesValid: false, workshopsForYoungAdultsValid: false
                            , explanationsForChildrenValid: false, arVrResourcesValid: false, workshopsForDisabledValid: false, explanationsForDisabledValid: false, workshopsForChildrenValid: false, workshopsForAdventureValid: false
                            , languagesValid: false, educationalVisitAvailableInEnglishValid: false
                        }
                    )
                    dataFocus.push(
                        {
                            facilityCodeFocus: false, facilityTypeFocus: false, facilityNameFocus: false, facilityOtherNameFocus: false, facilityDescriptionFocus: false,
                            mineCategoryFocus: false, mineTypeFocus: false, usesMineFocus: false, situationFocus: false, openingYearFocus: false, visitRouteAvailableFocus: false, timeNeededForTheVisitFocus: false, routeDescriptionFocus: false
                            , guidedVisitFocus: false, freeTourAvailableFocus: false, adventureFocus: false, adaptedVisitAvailableFocus: false, brochuresPrintedInfoFocus: false, audioVisualResourcesFocus: false, workshopsForYoungAdultsFocus: false
                            , explanationsForChildrenFocus: false, arVrResourcesFocus: false, workshopsForDisabledFocus: false, explanationsForDisabledFocus: false, workshopsForChildrenFocus: false, workshopsForAdventureFocus: false
                            , languagesFocus: false, educationalVisitAvailableInEnglishFocus: false
                        }
                    )
                    dataInput.push(
                        {
                            id: data[i].id, facilityCode: i + 1 + "", facilityType: data[i].faciType ? data[i].faciType : "", facilityName: data[i].faciName ? data[i].faciName : "", facilityOtherName: data[i].faciName2 ? data[i].faciName2 : "", facilityDescription: data[i].faciPopDesc ? data[i].faciPopDesc : "",
                            mineCategory: data[i].category ? data[i].category : "", mineType: data[i].mineType ? data[i].mineType : "", usesMine: data[i].otherMineUses ? data[i].otherMineUses : "", situation: data[i].situation ? data[i].situation : "", openingYear: data[i].openingYear ? data[i].openingYear : "", visitRouteAvailable: data[i].visitRoute ? data[i].visitRoute : "", timeNeededForTheVisit: data[i].timeNeed ? data[i].timeNeed : "", routeDescription: data[i].routeDesc ? data[i].routeDesc : ""
                            , guidedVisit: data[i].guided, freeTourAvailable: data[i].typeFreeTour, adventure: data[i].typeAdventure, adaptedVisitAvailable: data[i].typeAdapted, brochuresPrintedInfo: data[i].eduresourBrochures, audioVisualResources: data[i].eduresourAudioVisual, workshopsForYoungAdults: data[i].eduresourWorkshopYoung
                            , explanationsForChildren: data[i].eduresourExplainChild ? data[i].eduresourExplainChild : "", arVrResources: data[i].eduresourARVR ? data[i].eduresourARVR : "", workshopsForDisabled: data[i].eduresourWorkshopDisabled ? data[i].eduresourWorkshopDisabled : "", explanationsForDisabled: data[i].eduresourExplainDisabled ? data[i].eduresourExplainDisabled : "", workshopsForChildren: data[i].eduresourWorkshopChild ? data[i].eduresourWorkshopChild : "", workshopsForAdventure: data[i].eduresourWorkshopAdventure ? data[i].eduresourWorkshopAdventure : ""
                            , languages: data[i].language ? data[i].language : "", educationalVisitAvailableInEnglish: data[i].english ? data[i].english : "", status: 0,
                        }
                    )
                    if (i === data.length - 1) {
                        setFormFocusFacilitiesInfo(dataFocus)
                        setFormValidFacilitiesInfo(dataValid)
                        setFormErrorFacilitiesInfo(dataError)
                        setFormInputFacilitiesInfo(dataInput)

                    }
                }


            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetMineProdution(mineCode).then(
            (res: any) => {
                const data = [...res.data];
                let dataFocus: IFormFocusMineProductions[] = []
                let dataValid: IFormValidMineProductions[] = []
                let dataError: IFormErrorMineProductions[] = []
                let dataInput: IFormInputMineProductions[] = []
                for (let i = 0; i < data.length; i++) {
                    dataError.push(
                        {
                            faciCodeError: '', productError: '', mainOresProducedError: '', workingPeriodError: '', productionDestinationError: '',
                            productionDestination2Error: ''
                        }
                    )
                    dataValid.push(
                        {
                            faciCodeValid: false, productValid: false, mainOresProducedValid: false, workingPeriodValid: false, productionDestinationValid: false,
                            productionDestination2Valid: false
                        }
                    )
                    dataFocus.push(
                        {
                            faciCodeFocus: false, productFocus: false, mainOresProducedFocus: false, workingPeriodFocus: false, productionDestinationFocus: false,
                            productionDestination2Focus: false
                        }
                    )
                    dataInput.push(
                        {
                            id: data[i].id, faciCode: "", product: data[i].product ? data[i].product : "", mainOresProduced: data[i].mainOres ? data[i].mainOres : "", workingPeriod: data[i].workingPeriod ? data[i].workingPeriod : "", productionDestination: data[i].productDestination ? data[i].productDestination : "",
                            productionDestination2: data[i].productDestination2 ? data[i].productDestination2 : "", status: 0,
                        }
                    )

                    if (i === data.length - 1) {
                        setFormFocusMineProductions(dataFocus)
                        setFormValidMineProductions(dataValid)
                        setFormErrorMineProductions(dataError)
                        setFormInputMineProductions(dataInput)

                    }
                }
            }
        ).catch(
            (err: any) => {
            }
        )
        const siteLegends = await httpGetMineSiteLegends(mineCode).then(
            (res: any) => {
                return { ...res.data[0] }
                /*
                id: 8
                legendDatePeriod: "a"
                mineCode: "fd003"
                storyDesc: "b"
                */
            }
        ).catch(
            (err: any) => {
                return null
            }
        )

        const siteHistoricEvents = await httpGetMineSiteHistoricEvents(mineCode).then(
            (res: any) => {

                return { ...res.data[0] }
                /*
                datePeriod: "c"
                event: "d"
                id: 2
                mineCode: "fd003"
                */
            }
        ).catch(
            (err: any) => {
                return null

            }
        )
        if (siteLegends !== null && siteHistoricEvents !== null) {
            setFormInputLegendsHistoryEvents({
                idSiteLegends: siteLegends.id, idSiteHistoricEvents: siteHistoricEvents.id,
                timePeriodLegend: siteLegends.legendDatePeriod ? siteLegends.legendDatePeriod : "", storyLegendDesc: siteLegends.storyDesc ? siteLegends.storyDesc : "",
                datePeriodHistoricRecord: siteHistoricEvents.datePeriod ? siteHistoricEvents.datePeriod : "", historyEventDesc: siteHistoricEvents.event ? siteHistoricEvents.event : ""
            })
            setFormErrorLegendsHistoryEvents({ timePeriodLegendError: '', storyLegendDescError: '', datePeriodHistoricRecordError: '', historyEventDescError: '' })
            setFormValidLegendsHistoryEvents({ timePeriodLegendValid: false, storyLegendDescValid: false, datePeriodHistoricRecordValid: false, historyEventDescValid: false })
            setFormFocusLegendsHistoryEvents({ timePeriodLegendFocus: false, storyLegendDescFocus: false, datePeriodHistoricRecordFocus: false, historyEventDescFocus: false })


        }


        httpGetMineBibliography(mineCode).then(
            (res: any) => {

                const data = { ...res.data[0] };
                /*
     biblioSource: "bb"
     id: 5
     mineCode: "fd003"
     otherBibInfo: "bb"
     webInfo: "bb"
     */
                setFormInputBibliography({ id: data.id, mineCode: data.mineCode ? data.mineCode : "", bibliographicalResource: data.biblioSource ? data.biblioSource : "", webInfoResource: data.webInfo ? data.webInfo : "", otherBibliographicalInfo: data.otherBibInfo ? data.otherBibInfo : "" })
                setFormErrorBibliography({ mineCodeError: '', bibliographicalResourceError: '', webInfoResourceError: '', otherBibliographicalInfoError: '' })
                setFormValidBibliography({ mineCodeValid: false, bibliographicalResourceValid: false, webInfoResourceValid: false, otherBibliographicalInfoValid: false })
                setFormFocusBibliography({ mineCodeFocus: false, bibliographicalResourceFocus: false, webInfoResourceFocus: false, otherBibliographicalInfoFocus: false })

            }
        ).catch(
            (err: any) => {
            }
        )

        httpGetQuestionMine(mineCode).then(
            (res: any) => {
                const roundsRes = [...res.data]
                let roundsProcessed: IFormRound[] = []
                let roundsProcessedValid: IFormRoundValid[] = []
                let roundsProcessedFocus: IFormRoundFocus[] = []
                let roundsProcessedError: IFormRoundError[] = []

                for (let i = 0; i < roundsRes.length; i++) {
                    const round = roundsRes[i];
                    let answersProcessed = []
                    let answersProcessedValid = []
                    let answersProcessedFocus = []
                    let answersProcessedError = []

                    if (round.ansA) { answersProcessed.push(round.ansA); answersProcessedValid.push(false); answersProcessedFocus.push(false); answersProcessedError.push("") }
                    if (round.ansB) { answersProcessed.push(round.ansB); answersProcessedValid.push(false); answersProcessedFocus.push(false); answersProcessedError.push("") }
                    if (round.ansC) { answersProcessed.push(round.ansC); answersProcessedValid.push(false); answersProcessedFocus.push(false); answersProcessedError.push("") }
                    if (round.ansD) { answersProcessed.push(round.ansD); answersProcessedValid.push(false); answersProcessedFocus.push(false); answersProcessedError.push("") }
                    if (round.ansE) { answersProcessed.push(round.ansE); answersProcessedValid.push(false); answersProcessedFocus.push(false); answersProcessedError.push("") }




                    roundsProcessed.push({ question: round.question, answers: [...answersProcessed], correct: round.correctAns, status: 0 })
                    roundsProcessedValid.push({ questionValid: false, answersValid: [...answersProcessedValid], correctValid: false })
                    roundsProcessedFocus.push({ questionFocus: false, answersFocus: [...answersProcessedFocus], correctFocus: round.correctAns })
                    roundsProcessedError.push({ questionError: "", answersError: [...answersProcessedError], correctError: "" })
                    if (i === roundsRes.length - 1) {
                        setFormValid((b4: IFormValidMine) => ({ ...b4, roundsValid: [...roundsProcessedValid] }));
                        setFormError((b4: IFormErrorMine) => ({ ...b4, roundsError: [...roundsProcessedError] }));
                        setFormInput((b4: IFormInputMine) => ({ ...b4, ...props.mine.info, lon: props.mine.info.lng, rounds: [...roundsProcessed] }));
                        setFormFocus((b4: IFormFocusMine) => ({ ...b4, roundsFocus: [...roundsProcessedFocus] }));
                    }
                }

                if(roundsRes.length===0){
                    setFormInput((b4: IFormInputMine) => ({ ...b4, ...props.mine.info, lon: props.mine.info.lng, rounds: [] }));
                }



                //setFormInput((b4: IFormInputMine) => ({ ...b4, ...props.mine.info, lon: props.mine.info.lng, rounds: [...res.data] }));
                /*
                question: string
                correct: string
                answers: string[]
                status: number


                
                ansA: "ff"
                ansB: "ff"
                correctAns: "A"
                explanation: ""
                id: 345
                institute: ""
                mineCode: "fd001"
                photo: ""
                question: "FF"
                */
            }
        ).catch(
            (err: any) => {
            }
        )



    }



    const loadAuxTables = () => {
        httpGetAuxCountryAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setCountryAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )

        httpGetAuxFacilityTypeAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                let bb: IFormInputAuxFacilityType[] = []

                aa.forEach(single => {
                    bb.push({ facilityType: single.faciType })
                })

                setFacilityTypeAuxArray([...bb])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxMineTypeAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setMineTypeAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxOfficialLanguageAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setOfficialLanguageAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxSituationAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setSituationAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
        httpGetAuxCategoriesAll(null).then(
            (res: any) => {
                const aa = [...res.data]
                setCategoriesAuxArray([...res.data])
            }
        ).catch(
            (err: any) => {
            }
        )
    }









    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);



    const handleEventListeners = (event: any) => {
        if (event !== null) {


            switch (event.type) {
                case 'mousedown': {
                    if (event.target.contains(overlayRef.current)) {
                        closeModalHandler(GeneralServices.constants.child.closed);
                    } else if (refFlagsDropdownMenu.current && refFlagsDropdownButton.current && !refFlagsDropdownMenu.current.contains(event.target) && !refFlagsDropdownButton.current.contains(event.target)) {
                        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    }
                    /*
                    if (event.target.contains(overlayRef.current)) {
                        closeModalHandler(GeneralServices.constants.child.closed);
                    }
                    */
                    break
                }
                case 'resize': {
                    setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
                    break
                }
                default: {

                }
            }
        }



    }


    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editModalMine-topOffset", posY + "px");
    }




    /*
    const changeStepperSelectedHandler = (active: any) => {
        setSelectedStepper(active)
    }

    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }
    */



    /*MODAL SETUP UP*/



    const formEndup = () => {

        contentRef.current.reset();
        setFormSubmitValid({ inventory: false, quiz: false, mineProductions: false, facilities: false });
        setFormError({
            mineCodeError: '', websiteError: '', siteNameENGError: '', siteNameNOLError: '', countryError: '',
            regionStateProvinceError: '', municipalityError: '', touristicnameError: '', minePopDescError: '', complexError: '', complexNameError: '', mineHeritageRouteError: ''
            , projectFigError: '', infoFigureError: '', carParkError: '', cafeteriaError: '', giftShopError: '', addressError: '', address2Error: '', postalCodeError: '', phoneError: '',
            mailError: '', otherInfoContError: '', pubTransportError: '', pubTransportDescError: '',
            roundsError: []
        });
        setFormInput({
            lat: null, lon: null, mineCode: '', website: '', siteNameENG: '', siteNameNOL: '', country: '',
            regionStateProvince: '', municipality: '', touristicname: '', minePopDesc: '', complex: '', complexName: '', mineHeritageRoute: '', projectFig: '', infoFigure: '',
            carPark: '', cafeteria: '', giftShop: '', address: '', address2: '', postalCode: '', phone: '', mail: '', otherInfoCont: '', pubTransport: '', pubTransportDesc: '',
            rounds: []
        })
        setFormValid({
            mineCodeValid: false, websiteValid: false, siteNameENGValid: false, siteNameNOLValid: false, countryValid: false,
            regionStateProvinceValid: false, municipalityValid: false, touristicnameValid: false, minePopDescValid: false, complexValid: false, complexNameValid: false,
            mineHeritageRouteValid: false, projectFigValid: false, infoFigureValid: false, carParkValid: false, cafeteriaValid: false, giftShopValid: false, addressValid: false,
            address2Valid: false, postalCodeValid: false, phoneValid: false, mailValid: false, otherInfoContValid: false, pubTransportValid: false, pubTransportDescValid: false,
            roundsValid: [], pointValid: false
        });
        setFormFocus({
            mineCodeFocus: false, websiteFocus: false, siteNameENGFocus: false, siteNameNOLFocus: false, countryFocus: false,
            regionStateProvinceFocus: false, municipalityFocus: false, touristicnameFocus: false, minePopDescFocus: false, complexFocus: false, complexNameFocus: false,
            mineHeritageRouteFocus: false, projectFigFocus: false, infoFigureFocus: false, carParkFocus: false, cafeteriaFocus: false, giftShopFocus: false, addressFocus: false,
            address2Focus: false, postalCodeFocus: false, phoneFocus: false, mailFocus: false, otherInfoContFocus: false, pubTransportFocus: false, pubTransportDescFocus: false,
            roundsFocus: []
        })
        setFlagsDropdown({ open: false, class: 'flags-menu-drop' })

        setTempDisabled(0)
        setSelectedStepper("0");
        //setFlagsArrayAll([])
        /*
        setCountryAuxArray([])
        setFacilityTypeAuxArray([])
        setMineTypeAuxArray([])
        setOfficialLanguageAuxArray([])
        setSituationAuxArray([])
        setCategoriesAuxArray([])
        */
    }


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, indexRound: any, indexAnswer: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {

            /*INVENTORY*/
            case FORMMINECODEID: {
                setFormInput({ ...formInput, mineCode: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            case FORMSITENAMEENGID: {
                setFormInput({ ...formInput, siteNameENG: value })
                break;
            }
            case FORMSITENAMENOLID: {
                setFormInput({ ...formInput, siteNameNOL: value })
                break;
            }
            case FORMCOUNTRYID: {
                setFormInput({ ...formInput, country: value })
                break;
            }
            case FORMREGIONSTATEPROVINCEID: {
                setFormInput({ ...formInput, regionStateProvince: value })
                break;
            }
            case FORMMUNICIPALITYID: {
                setFormInput({ ...formInput, municipality: value })
                break;
            }
            case FORMTOURISTICNAMEID: {
                setFormInput({ ...formInput, touristicname: value })
                break;
            }
            case FORMMINEPOPDESCID: {
                setFormInput({ ...formInput, minePopDesc: value })
                break;
            }
            case FORMCOMPLEXID: {
                setFormInput({ ...formInput, complex: value })
                break;
            }
            case FORMCOMPLEXNAMEID: {
                setFormInput({ ...formInput, complexName: value })
                break;
            }
            case FORMMINEHERITAGEROUTEID: {
                setFormInput({ ...formInput, mineHeritageRoute: value })
                break;
            }
            case FORMPROJECTFIGID: {
                setFormInput({ ...formInput, projectFig: value })
                break;
            }
            case FORMINFOFIGUREID: {
                setFormInput({ ...formInput, infoFigure: value })
                break;
            }
            case FORMCARPARKID: {
                setFormInput({ ...formInput, carPark: value })
                break;
            }
            case FORMCAFETARIAID: {
                setFormInput({ ...formInput, cafeteria: value })
                break;
            }
            case FORMGIFTSHOPID: {
                setFormInput({ ...formInput, giftShop: value })
                break;
            }
            case FORMADDRESSID: {
                setFormInput({ ...formInput, address: value })
                break;
            }
            case FORMADDRESS2ID: {
                setFormInput({ ...formInput, address2: value })
                break;
            }
            case FORMPOSTALCODEID: {
                setFormInput({ ...formInput, postalCode: value })
                break;
            }
            case FORMPHONEID: {
                setFormInput({ ...formInput, phone: value })
                break;
            }
            case FORMMAILID: {
                setFormInput({ ...formInput, mail: value })
                break;
            }
            case FORMOTHERINFOCONTID: {
                setFormInput({ ...formInput, otherInfoCont: value })
                break;
            }
            case FORMPUBTRANSPORTID: {
                setFormInput({ ...formInput, pubTransport: value })
                break;
            }
            case FORMPUBTRANSPORTDESCID: {
                setFormInput({ ...formInput, pubTransportDesc: value })
                break;
            }
            /*----INVENTORY*/

            case FORMROUNDID + indexRound + FORMQUESTIONID: {

                let copyQuiz = [...formInput.rounds]
                copyQuiz[indexRound].question = value
                setFormInput({ ...formInput, rounds: [...copyQuiz] })
                break;
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                let copyQuiz = [...formInput.rounds]
                copyQuiz[indexRound].answers[indexAnswer] = value
                setFormInput({ ...formInput, rounds: [...copyQuiz] })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;


        let upd_mineCodeValid = formValid.mineCodeValid;
        if (formInput.mineCode === '') {
            upd_mineCodeValid = false;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mineCodeValid = true;
            fieldValidationErrors.mineCodeError = props.appLanguage.backoffice.manageMines.add.mineCodeAccept;
        }

        let upd_websiteValid = formValid.websiteValid;
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
        }

        let upd_siteNameENGValid = formValid.siteNameENGValid;
        if (formInput.siteNameENG === '') {
            upd_siteNameENGValid = false;
            fieldValidationErrors.siteNameENGError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_siteNameENGValid = true;
            fieldValidationErrors.siteNameENGError = props.appLanguage.backoffice.manageMines.add.siteNameENGAccept;
        }

        let upd_siteNameNOLValid = formValid.siteNameNOLValid;
        if (formInput.siteNameNOL === '') {
            upd_siteNameNOLValid = false;
            fieldValidationErrors.siteNameNOLError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_siteNameNOLValid = true;
            fieldValidationErrors.siteNameNOLError = props.appLanguage.backoffice.manageMines.add.siteNameNOLAccept;
        }

        let upd_countryValid = formValid.countryValid;
        if (formInput.country === '') {
            upd_countryValid = false;
            fieldValidationErrors.countryError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_countryValid = true;
            fieldValidationErrors.countryError = props.appLanguage.backoffice.manageMines.add.countryAccept;
        }

        let upd_regionStateProvinceValid = formValid.regionStateProvinceValid;
        if (formInput.regionStateProvince === '') {
            upd_regionStateProvinceValid = false;
            fieldValidationErrors.regionStateProvinceError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_regionStateProvinceValid = true;
            fieldValidationErrors.regionStateProvinceError = props.appLanguage.backoffice.manageMines.add.regionStateProvinceAccept;
        }

        let upd_municipalityValid = formValid.municipalityValid;
        if (formInput.municipality === '') {
            upd_municipalityValid = false;
            fieldValidationErrors.municipalityError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_municipalityValid = true;
            fieldValidationErrors.municipalityError = props.appLanguage.backoffice.manageMines.add.municipalityAccept;
        }

        let upd_touristicnameValid = formValid.touristicnameValid;
        if (formInput.touristicname === '') {
            upd_touristicnameValid = false;
            fieldValidationErrors.touristicnameError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_touristicnameValid = true;
            fieldValidationErrors.touristicnameError = props.appLanguage.backoffice.manageMines.add.touristicnameAccept;
        }

        let upd_minePopDescValid = formValid.minePopDescValid;
        if (formInput.minePopDesc === '') {
            upd_minePopDescValid = false;
            fieldValidationErrors.minePopDescError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_minePopDescValid = true;
            fieldValidationErrors.minePopDescError = props.appLanguage.backoffice.manageMines.add.minePopDescAccept;
        }

        let upd_complexValid = formValid.complexValid;
        if (formInput.complex === '') {
            upd_complexValid = false;
            fieldValidationErrors.complexError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_complexValid = true;
            fieldValidationErrors.complexError = props.appLanguage.backoffice.manageMines.add.complexAccept;
        }

        let upd_complexNameValid = formValid.complexNameValid;
        if (formInput.complexName === '') {
            upd_complexNameValid = false;
            fieldValidationErrors.complexNameError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_complexNameValid = true;
            fieldValidationErrors.complexNameError = props.appLanguage.backoffice.manageMines.add.complexNameAccept;
        }

        let upd_mineHeritageRouteValid = formValid.mineHeritageRouteValid;
        if (formInput.mineHeritageRoute === '') {
            upd_mineHeritageRouteValid = false;
            fieldValidationErrors.mineHeritageRouteError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mineHeritageRouteValid = true;
            fieldValidationErrors.mineHeritageRouteError = props.appLanguage.backoffice.manageMines.add.mineHeritageRouteAccept;
        }

        let upd_projectFigValid = formValid.projectFigValid;
        if (formInput.projectFig === '') {
            upd_projectFigValid = false;
            fieldValidationErrors.projectFigError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_projectFigValid = true;
            fieldValidationErrors.projectFigError = props.appLanguage.backoffice.manageMines.add.projectFigAccept;
        }

        let upd_infoFigureValid = formValid.infoFigureValid;
        if (formInput.infoFigure === '') {
            upd_infoFigureValid = false;
            fieldValidationErrors.infoFigureError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_infoFigureValid = true;
            fieldValidationErrors.infoFigureError = props.appLanguage.backoffice.manageMines.add.infoFigureAccept
        }

        let upd_carParkValid = formValid.carParkValid;
        if (formInput.carPark === '') {
            upd_carParkValid = false;
            fieldValidationErrors.carParkError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_carParkValid = true;
            fieldValidationErrors.carParkError = props.appLanguage.backoffice.manageMines.add.carParkAccept;
        }

        let upd_cafeteriaValid = formValid.cafeteriaValid;
        if (formInput.cafeteria === '') {
            upd_cafeteriaValid = false;
            fieldValidationErrors.cafeteriaError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_cafeteriaValid = true;
            fieldValidationErrors.cafeteriaError = props.appLanguage.backoffice.manageMines.add.cafeteriaAccept;
        }

        let upd_giftShopValid = formValid.giftShopValid;
        if (formInput.giftShop === '') {
            upd_giftShopValid = false;
            fieldValidationErrors.giftShopError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_giftShopValid = true;
            fieldValidationErrors.giftShopError = props.appLanguage.backoffice.manageMines.add.giftShopAccept;
        }


        let upd_addressValid = formValid.addressValid;
        if (formInput.address === '') {
            upd_addressValid = false;
            fieldValidationErrors.addressError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_addressValid = true;
            fieldValidationErrors.addressError = props.appLanguage.backoffice.manageMines.add.addressAccept;
        }

        let upd_address2Valid = formValid.address2Valid;
        if (formInput.address2 === '') {
            upd_address2Valid = false;
            fieldValidationErrors.address2Error = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_address2Valid = true;
            fieldValidationErrors.address2Error = props.appLanguage.backoffice.manageMines.add.address2Accept;
        }

        let upd_postalCodeValid = formValid.postalCodeValid;
        if (formInput.postalCode === '') {
            upd_postalCodeValid = false;
            fieldValidationErrors.postalCodeError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_postalCodeValid = true;
            fieldValidationErrors.postalCodeError = props.appLanguage.backoffice.manageMines.add.postalCodeAccept;
        }

        let upd_phoneValid = formValid.phoneValid;
        if (formInput.phone === '') {
            upd_phoneValid = false;
            fieldValidationErrors.phoneError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_phoneValid = true;
            fieldValidationErrors.phoneError = props.appLanguage.backoffice.manageMines.add.phoneAccept;
        }

        let upd_mailValid = formValid.mailValid;
        if (formInput.mail === '') {
            upd_mailValid = false;
            fieldValidationErrors.mailError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_mailValid = true;
            fieldValidationErrors.mailError = props.appLanguage.backoffice.manageMines.add.mailAccept;
        }

        let upd_otherInfoContValid = formValid.otherInfoContValid;
        if (formInput.otherInfoCont === '') {
            upd_otherInfoContValid = false;
            fieldValidationErrors.otherInfoContError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_otherInfoContValid = true;
            fieldValidationErrors.otherInfoContError = props.appLanguage.backoffice.manageMines.add.otherInfoContAccept;
        }

        let upd_pubTransportValid = formValid.pubTransportValid;
        if (formInput.pubTransport === '') {
            upd_pubTransportValid = false;
            fieldValidationErrors.pubTransportError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_pubTransportValid = true;
            fieldValidationErrors.pubTransportError = props.appLanguage.backoffice.manageMines.add.pubTransportAccept;
        }

        let upd_pubTransportDescValid = formValid.pubTransportDescValid;
        if (formInput.pubTransportDesc === '') {
            upd_pubTransportDescValid = false;
            fieldValidationErrors.pubTransportDescError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
        } else {
            upd_pubTransportDescValid = true;
            fieldValidationErrors.pubTransportDescError = props.appLanguage.backoffice.manageMines.add.pubTransportDescAccept;
        }


        let upd_roundsValid = formValid.roundsValid;
        for (let i = 0; i < upd_roundsValid.length && upd_roundsValid.length === formInput.rounds.length; i++) {
            if (formInput.rounds[i].question === '') {
                upd_roundsValid[i].questionValid = false;
                fieldValidationErrors.roundsError[i].questionError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_roundsValid[i].questionValid = true;
                fieldValidationErrors.roundsError[i].questionError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
            }

            if (formInput.rounds[i].correct === '') {
                upd_roundsValid[i].correctValid = false;
                fieldValidationErrors.roundsError[i].correctError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_roundsValid[i].correctValid = true;
                fieldValidationErrors.roundsError[i].correctError = props.appLanguage.backoffice.manageMines.add.websiteAccept;
            }

            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (formInput.rounds[i].answers[z] === '') {
                    upd_roundsValid[i].answersValid[z] = false;
                    fieldValidationErrors.roundsError[i].answersError[z] = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
                } else {
                    upd_roundsValid[i].answersValid[z] = true;
                    fieldValidationErrors.roundsError[i].answersError[z] = props.appLanguage.backoffice.manageMines.add.websiteAccept;
                }
            }
        }

        let upd_pointValid = formValid.pointValid;
        if (formInput.lat === null || formInput.lon === null) {
            upd_pointValid = false;
        } else {
            upd_pointValid = true;
        }


        setFormError(fieldValidationErrors)
        setFormValid({
            mineCodeValid: upd_mineCodeValid, websiteValid: upd_websiteValid, siteNameENGValid: upd_siteNameENGValid, siteNameNOLValid: upd_siteNameNOLValid, countryValid: upd_countryValid,
            regionStateProvinceValid: upd_regionStateProvinceValid, municipalityValid: upd_municipalityValid, touristicnameValid: upd_touristicnameValid, minePopDescValid: upd_minePopDescValid,
            complexValid: upd_complexValid, complexNameValid: upd_complexNameValid, mineHeritageRouteValid: upd_mineHeritageRouteValid, projectFigValid: upd_projectFigValid,
            infoFigureValid: upd_infoFigureValid, carParkValid: upd_carParkValid, cafeteriaValid: upd_cafeteriaValid, giftShopValid: upd_giftShopValid, addressValid: upd_addressValid,
            address2Valid: upd_address2Valid, postalCodeValid: upd_postalCodeValid, phoneValid: upd_phoneValid, mailValid: upd_mailValid, otherInfoContValid: upd_otherInfoContValid,
            pubTransportValid: upd_pubTransportValid, pubTransportDescValid: upd_pubTransportDescValid,
            roundsValid: upd_roundsValid, pointValid: upd_pointValid
        })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        let roundValidation = true

        for (let i = 0; i < formInput.rounds.length && formInput.rounds.length === formValid.roundsValid.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                roundValidation = false;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    roundValidation = false;
                    break;
                }
            }
        }


        //setFormSubmitValid({ inventory: formValid.pointValid && formValid.siteNameENGValid && formValid.addressValid && formValid.countryValid, quiz: roundValidation })
        setFormSubmitValid({ inventory: true, facilities: facilitiesInfoValid, mineProductions: mineProductionsValid, quiz: roundValidation })

    }, [formValid, facilitiesInfoValid, mineProductionsValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, indexRound: any, indexAnswer: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            /*INVENTORY*/
            case FORMMINECODEID: {
                setFormFocus({ ...formFocus, mineCodeFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            case FORMSITENAMEENGID: {
                setFormFocus({ ...formFocus, siteNameENGFocus: true })
                break;
            }
            case FORMSITENAMENOLID: {
                setFormFocus({ ...formFocus, siteNameNOLFocus: true })
                break;
            }
            case FORMCOUNTRYID: {
                setFormFocus({ ...formFocus, countryFocus: true })
                break;
            }
            case FORMREGIONSTATEPROVINCEID: {
                setFormFocus({ ...formFocus, regionStateProvinceFocus: true })
                break;
            }
            case FORMMUNICIPALITYID: {
                setFormFocus({ ...formFocus, municipalityFocus: true })
                break;
            }
            case FORMTOURISTICNAMEID: {
                setFormFocus({ ...formFocus, touristicnameFocus: true })
                break;
            }
            case FORMMINEPOPDESCID: {
                setFormFocus({ ...formFocus, minePopDescFocus: true })
                break;
            }
            case FORMCOMPLEXID: {
                setFormFocus({ ...formFocus, complexFocus: true })
                break;
            }
            case FORMCOMPLEXNAMEID: {
                setFormFocus({ ...formFocus, complexNameFocus: true })
                break;
            }
            case FORMMINEHERITAGEROUTEID: {
                setFormFocus({ ...formFocus, mineHeritageRouteFocus: true })
                break;
            }
            case FORMPROJECTFIGID: {
                setFormFocus({ ...formFocus, projectFigFocus: true })
                break;
            }
            case FORMINFOFIGUREID: {
                setFormFocus({ ...formFocus, infoFigureFocus: true })
                break;
            }
            case FORMCARPARKID: {
                setFormFocus({ ...formFocus, carParkFocus: true })
                break;
            }
            case FORMCAFETARIAID: {
                setFormFocus({ ...formFocus, cafeteriaFocus: true })
                break;
            }
            case FORMGIFTSHOPID: {
                setFormFocus({ ...formFocus, giftShopFocus: true })
                break;
            }
            case FORMADDRESSID: {
                setFormFocus({ ...formFocus, addressFocus: true })
                break;
            }
            case FORMADDRESS2ID: {
                setFormFocus({ ...formFocus, address2Focus: true })
                break;
            }
            case FORMPOSTALCODEID: {
                setFormFocus({ ...formFocus, postalCodeFocus: true })
                break;
            }
            case FORMPHONEID: {
                setFormFocus({ ...formFocus, phoneFocus: true })
                break;
            }
            case FORMMAILID: {
                setFormFocus({ ...formFocus, mailFocus: true })
                break;
            }
            case FORMOTHERINFOCONTID: {
                setFormFocus({ ...formFocus, otherInfoContFocus: true })
                break;
            }
            case FORMPUBTRANSPORTID: {
                setFormFocus({ ...formFocus, pubTransportFocus: true })
                break;
            }
            case FORMPUBTRANSPORTDESCID: {
                setFormFocus({ ...formFocus, pubTransportDescFocus: true })
                break;
            }
            /*----INVENTORY*/


            case FORMROUNDID + indexRound + FORMQUESTIONID: {

                let copyQuiz = [...formFocus.roundsFocus]
                copyQuiz[indexRound].questionFocus = true
                setFormFocus({ ...formFocus, roundsFocus: [...copyQuiz] })
                break;
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                let copyQuiz = [...formFocus.roundsFocus]
                copyQuiz[indexRound].answersFocus[indexAnswer] = true
                setFormFocus({ ...formFocus, roundsFocus: [...copyQuiz] })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)
        /*
        console.log(formInput)
        for (let i = 0; i < formInputFacilitiesInfo.length; i++) {
            console.log(formInputFacilitiesInfo[i])
        }
        for (let i = 0; i < formInputMineProductions.length; i++) {
            console.log(formInputMineProductions[i])
        }
        console.log(formInputLegendsHistoryEvents)
        console.log(formInputBibliography)
        */

        httpPutMineInventory(
            {
                id: props.mine.info.id + "",
                website: formInput.website,
                mineCode: formInput.mineCode,
                siteNameENG: formInput.siteNameENG,
                siteNameNOL: formInput.siteNameNOL,
                country: formInput.country,
                regionStateProvince: formInput.regionStateProvince,
                municipality: formInput.municipality,
                lat: formInput.lat,
                lng: formInput.lon,
                touristicname: formInput.touristicname,
                minePopDesc: formInput.minePopDesc,
                complex: formInput.complex,
                complexName: formInput.complexName,
                mineHeritageRoute: formInput.mineHeritageRoute,
                projectFig: formInput.projectFig,
                infoFigure: formInput.infoFigure,
                carPark: formInput.carPark,
                cafeteria: formInput.cafeteria,
                giftShop: formInput.giftShop,
                address: formInput.address,
                address2: formInput.address2,
                postalCode: formInput.postalCode,
                phone: formInput.phone,
                mail: formInput.mail,
                otherInfoCont: formInput.otherInfoCont,
                pubTransport: formInput.pubTransport,
                pubTransportDesc: formInput.pubTransportDesc
            }
        ).then(
            async (res: any) => {
                const facilities: IFormInputFacilitiesInfo[] = [...formInputFacilitiesInfo]

                for (let i = 0; i < facilities.length; i++) {
                    const facility: IFormInputFacilitiesInfo = { ...facilities[i] }

                    // se ja existia
                    if (facility.status === -1 || facility.status === 0) {
                        await httpDeleteMineFacility(facility.id).then(
                            async (res: any) => {
                                // e nao foi removida, adiciona outra vez
                                if (facility.status === 0) {
                                    await httpPostMineFacility(
                                        {
                                            id: "",
                                            mineCode: formInput.mineCode,
                                            faciType: facility.facilityType,
                                            faciName: facility.facilityName,
                                            faciName2: facility.facilityOtherName,
                                            faciPopDesc: facility.facilityDescription,
                                            category: facility.mineCategory,
                                            mineType: facility.mineType,
                                            situation: facility.situation,
                                            openingYear: facility.openingYear,
                                            otherMineUses: facility.usesMine,
                                            visitRoute: facility.visitRouteAvailable,
                                            timeNeed: facility.timeNeededForTheVisit,
                                            routeDesc: facility.routeDescription,
                                            guided: facility.guidedVisit,
                                            typeFreeTour: facility.freeTourAvailable,
                                            typeAdventure: facility.adventure,
                                            typeAdapted: facility.adaptedVisitAvailable,
                                            eduresourBrochures: facility.brochuresPrintedInfo,
                                            eduresourExplainChild: facility.explanationsForChildren,
                                            eduresourExplainDisabled: facility.explanationsForDisabled,
                                            eduresourAudioVisual: facility.audioVisualResources,
                                            eduresourARVR: facility.arVrResources,
                                            eduresourWorkshopChild: facility.workshopsForChildren,
                                            eduresourWorkshopYoung: facility.workshopsForYoungAdults,
                                            eduresourWorkshopDisabled: facility.workshopsForDisabled,
                                            eduresourWorkshopAdventure: facility.workshopsForAdventure,
                                            language: facility.languages,
                                            english: facility.educationalVisitAvailableInEnglish
                                        }
                                    ).then(
                                        (res: any) => {
                                        }
                                    ).catch(
                                        (err: any) => {
                                        }
                                    )
                                }

                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    } else if (facility.status === 1) {
                        await httpPostMineFacility(
                            {
                                id: "",
                                mineCode: formInput.mineCode,
                                faciType: facility.facilityType,
                                faciName: facility.facilityName,
                                faciName2: facility.facilityOtherName,
                                faciPopDesc: facility.facilityDescription,
                                category: facility.mineCategory,
                                mineType: facility.mineType,
                                situation: facility.situation,
                                openingYear: facility.openingYear,
                                otherMineUses: facility.usesMine,
                                visitRoute: facility.visitRouteAvailable,
                                timeNeed: facility.timeNeededForTheVisit,
                                routeDesc: facility.routeDescription,
                                guided: facility.guidedVisit,
                                typeFreeTour: facility.freeTourAvailable,
                                typeAdventure: facility.adventure,
                                typeAdapted: facility.adaptedVisitAvailable,
                                eduresourBrochures: facility.brochuresPrintedInfo,
                                eduresourExplainChild: facility.explanationsForChildren,
                                eduresourExplainDisabled: facility.explanationsForDisabled,
                                eduresourAudioVisual: facility.audioVisualResources,
                                eduresourARVR: facility.arVrResources,
                                eduresourWorkshopChild: facility.workshopsForChildren,
                                eduresourWorkshopYoung: facility.workshopsForYoungAdults,
                                eduresourWorkshopDisabled: facility.workshopsForDisabled,
                                eduresourWorkshopAdventure: facility.workshopsForAdventure,
                                language: facility.languages,
                                english: facility.educationalVisitAvailableInEnglish
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    }

                    if (i === facilities.length - 1) {

                        const mineProductions: IFormInputMineProductions[] = [...formInputMineProductions]
                        for (let x = 0; x < mineProductions.length; x++) {
                            const mineProduction: IFormInputMineProductions = { ...mineProductions[i] }


                            if (mineProduction.status === -1 || mineProduction.status === 0) {
                                await httpDeleteMineProduction(mineProduction.id).then(
                                    async (res: any) => {
                                        // e nao foi removida, adiciona outra vez
                                        if (mineProduction.status === 0) {
                                            await httpPostMineProduction(
                                                {
                                                    id: "",
                                                    mineCode: formInput.mineCode,
                                                    faciCode: mineProduction.faciCode,
                                                    product: mineProduction.product,
                                                    mainOres: mineProduction.mainOresProduced,
                                                    workingPeriod: mineProduction.workingPeriod,
                                                    productDestination: mineProduction.productionDestination,
                                                    productDestination2: mineProduction.productionDestination2
                                                }
                                            ).then(
                                                (res: any) => {
                                                }
                                            ).catch(
                                                (err: any) => {
                                                }
                                            )
                                        }

                                    }
                                ).catch(
                                    (err: any) => {
                                    }
                                )
                            } else if (mineProduction.status === 1) {
                                await httpPostMineProduction(
                                    {
                                        id: "",
                                        mineCode: formInput.mineCode,
                                        faciCode: mineProduction.faciCode,
                                        product: mineProduction.product,
                                        mainOres: mineProduction.mainOresProduced,
                                        workingPeriod: mineProduction.workingPeriod,
                                        productDestination: mineProduction.productionDestination,
                                        productDestination2: mineProduction.productionDestination2
                                    }
                                ).then(
                                    (res: any) => {
                                    }
                                ).catch(
                                    (err: any) => {
                                    }
                                )
                            }
                        }
                    }

                }

                //facility and mineproduction end -----------------
                if (formInputLegendsHistoryEvents && formInputLegendsHistoryEvents !== null) {
                    if (formInputLegendsHistoryEvents.idSiteHistoricEvents) {
                        await httpPutMineSiteHistoricEvents(
                            {
                                id: formInputLegendsHistoryEvents.idSiteHistoricEvents,
                                mineCode: formInput.mineCode,
                                datePeriod: formInputLegendsHistoryEvents.datePeriodHistoricRecord,
                                event: formInputLegendsHistoryEvents.historyEventDesc
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )

                    } else {
                        await httpPostMineSiteHistoricEvents(
                            {
                                id: "",
                                mineCode: formInput.mineCode,
                                datePeriod: formInputLegendsHistoryEvents.datePeriodHistoricRecord,
                                event: formInputLegendsHistoryEvents.historyEventDesc
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    }
                    if (formInputLegendsHistoryEvents.idSiteLegends) {
                        await httpPutMineSiteLegends(
                            {
                                id: formInputLegendsHistoryEvents.idSiteLegends,
                                mineCode: formInput.mineCode,
                                legendDatePeriod: formInputLegendsHistoryEvents.timePeriodLegend,
                                storyDesc: formInputLegendsHistoryEvents.storyLegendDesc
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )

                    } else {
                        await httpPostMineSiteLegends(
                            {
                                id: "",
                                mineCode: formInput.mineCode,
                                legendDatePeriod: formInputLegendsHistoryEvents.timePeriodLegend,
                                storyDesc: formInputLegendsHistoryEvents.storyLegendDesc
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    }

                }
                if (formInputBibliography && formInputBibliography !== null) {

                    if (formInputBibliography.id) {
                        await httpPutMineBibliography(
                            {
                                id: formInputBibliography.id,
                                mineCode: formInput.mineCode,
                                biblioSource: formInputBibliography.bibliographicalResource,
                                webInfo: formInputBibliography.webInfoResource,
                                otherBibInfo: formInputBibliography.otherBibliographicalInfo
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    } else {
                        await httpPostMineBibliography(
                            {
                                id: "",
                                mineCode: formInput.mineCode,
                                biblioSource: formInputBibliography.bibliographicalResource,
                                webInfo: formInputBibliography.webInfoResource,
                                otherBibInfo: formInputBibliography.otherBibliographicalInfo
                            }
                        ).then(
                            (res: any) => {
                            }
                        ).catch(
                            (err: any) => {
                            }
                        )
                    }


                }

                closeModalHandler(GeneralServices.constants.child.worked);


            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
        //closeModalHandler(GeneralServices.constants.child.closed);

    }



    const closeModalHandler = (feedback: string) => {
        formEndup()
        modalRef.current.classList.remove("editMine-modal-open");
        setSelectedStepper("0");
        props.callback(feedback)

    }



    const newMarkerHandler = (e: any) => {
        setFormInput({ ...formInput, lat: e.lngLat[1], lon: e.lngLat[0], })
    }

    const addRoundHandler = () => {

        if (!showListCondition()) {
            setFormInput({ ...formInput, rounds: [{ question: "", correct: "", answers: ["", ""], status: 0 }] })
            setFormFocus({ ...formFocus, roundsFocus: [{ questionFocus: false, correctFocus: false, answersFocus: [false, false] }] })
            setFormError({ ...formError, roundsError: [{ questionError: "", correctError: "", answersError: ["", ""] }] })
            setFormValid({ ...formValid, roundsValid: [{ questionValid: false, correctValid: false, answersValid: [false, false] }], pointValid: false })

        }

    }

    const addRoundUpHandler = (index: number) => {

        let ilegal = false;
        for (let i = 0; i < formInput.rounds.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                ilegal = true;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    ilegal = true;
                    break;
                }
            }
        }


        if (!ilegal) {
            const copyRoundInput = []
            const copyRoundValid = []
            const copyRoundFocus = []
            const copyRoundError = []

            for (let i = 0; i < formInput.rounds.length; i++) {
                if (index === i) {
                    copyRoundInput.push({ question: "", correct: "", answers: ["", ""], status: 0 })
                    copyRoundValid.push({ questionValid: false, correctValid: false, answersValid: [false, false] })
                    copyRoundFocus.push({ questionFocus: false, correctFocus: false, answersFocus: [false, false] })
                    copyRoundError.push({ questionError: "", correctError: "", answersError: ["", ""] })

                }
                copyRoundInput.push(formInput.rounds[i])
                copyRoundValid.push(formValid.roundsValid[i])
                copyRoundFocus.push(formFocus.roundsFocus[i])
                copyRoundError.push(formError.roundsError[i])


            }
            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundYesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundCantAdd)

        }
    }
    const addRoundDownHandler = (index: number) => {
        let ilegal = false;
        for (let i = 0; i < formInput.rounds.length; i++) {
            if (!formValid.roundsValid[i].questionValid ||
                !formValid.roundsValid[i].correctValid) {
                ilegal = true;
                break;
            }
            for (let z = 0; z < formInput.rounds[i].answers.length; z++) {
                if (!formValid.roundsValid[i].answersValid[z]) {
                    ilegal = true;
                    break;
                }
            }
        }


        if (!ilegal) {
            const copyRoundInput = []
            const copyRoundValid = []
            const copyRoundFocus = []
            const copyRoundError = []

            for (let i = 0; i < formInput.rounds.length; i++) {
                copyRoundInput.push(formInput.rounds[i])
                copyRoundValid.push(formValid.roundsValid[i])
                copyRoundFocus.push(formFocus.roundsFocus[i])
                copyRoundError.push(formError.roundsError[i])
                if (index === i) {
                    copyRoundInput.push({ question: "", correct: "", answers: ["", ""], status: 1 })
                    copyRoundValid.push({ questionValid: false, correctValid: false, answersValid: [false, false] })
                    copyRoundFocus.push({ questionFocus: false, correctFocus: false, answersFocus: [false, false] })
                    copyRoundError.push({ questionError: "", correctError: "", answersError: ["", ""] })
                }
            }
            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundYesAdd)

        } else {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.add.quiz.roundCantAdd)

        }
    }


    const rmvRoundHandler = (index: number) => {
        const copyRoundInput = [...formInput.rounds]

        /*
        copyRoundInput.splice(index, 1);
        copyRoundValid.splice(index, 1);
        copyRoundFocus.splice(index, 1);
        copyRoundError.splice(index, 1);
        */
        copyRoundInput[index].status = -1
        setFormInput({ ...formInput, rounds: [...copyRoundInput] })
        //setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
        //setFormError({ ...formError, roundsError: [...copyRoundError] })
        //setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        if (copyRoundInput[index].status === 0) {
            copyRoundInput[index].status = -1

        } else if (copyRoundInput[index].status === 1) {
            const copyRoundValid = [...formValid.roundsValid]
            const copyRoundFocus = [...formFocus.roundsFocus]
            const copyRoundError = [...formError.roundsError]

            copyRoundInput.splice(index, 1);
            copyRoundValid.splice(index, 1);
            copyRoundFocus.splice(index, 1);
            copyRoundError.splice(index, 1);

            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        }
        setFormInput({ ...formInput, rounds: [...copyRoundInput] })


    }


    const addAnswerHandler = (roundIndex: number) => {
        if (formInput.rounds[roundIndex].answers.length < alphabet.length) {
            const copyRoundInput = [...formInput.rounds]
            const copyRoundValid = [...formValid.roundsValid]
            const copyRoundFocus = [...formFocus.roundsFocus]
            const copyRoundError = [...formError.roundsError]


            const copyRoundInputAnswers = [...copyRoundInput[roundIndex].answers]
            const copyRoundValidAnswers = [...copyRoundValid[roundIndex].answersValid]
            const copyRoundFocusAnswers = [...copyRoundFocus[roundIndex].answersFocus]
            const copyRoundErrorAnswers = [...copyRoundError[roundIndex].answersError]

            copyRoundInputAnswers.push("")
            copyRoundValidAnswers.push(false)
            copyRoundFocusAnswers.push(false)
            copyRoundErrorAnswers.push("")


            copyRoundInput[roundIndex].answers = [...copyRoundInputAnswers]
            copyRoundValid[roundIndex].answersValid = [...copyRoundValidAnswers]
            copyRoundFocus[roundIndex].answersFocus = [...copyRoundFocusAnswers]
            copyRoundError[roundIndex].answersError = [...copyRoundErrorAnswers]



            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        }
    }

    const rmvAnswerHandler = (roundIndex: number) => {
        if (formInput.rounds[roundIndex].answers.length > 2) {

            const copyRoundInput = [...formInput.rounds]
            const copyRoundValid = [...formValid.roundsValid]
            const copyRoundFocus = [...formFocus.roundsFocus]
            const copyRoundError = [...formError.roundsError]


            copyRoundInput[roundIndex].answers.splice(copyRoundInput[roundIndex].answers.length - 1, 1);
            copyRoundValid[roundIndex].answersValid.splice(copyRoundValid[roundIndex].answersValid.length - 1, 1);
            copyRoundFocus[roundIndex].answersFocus.splice(copyRoundFocus[roundIndex].answersFocus.length - 1, 1);
            copyRoundError[roundIndex].answersError.splice(copyRoundError[roundIndex].answersError.length - 1, 1);

            if (formInput.rounds[roundIndex].correct === alphabet[formInput.rounds[roundIndex].answers.length]) {
                copyRoundInput[roundIndex].correct = ""
            }

            setFormInput({ ...formInput, rounds: [...copyRoundInput] })
            setFormFocus({ ...formFocus, roundsFocus: [...copyRoundFocus] })
            setFormError({ ...formError, roundsError: [...copyRoundError] })
            setFormValid({ ...formValid, roundsValid: [...copyRoundValid] })
        }

    }




    const validatorFeedback = (formId: any, indexRound: any, indexAnswer: any) => {
        for (let i = 0; i < formValid.roundsValid.length; i++) {
        }

        switch (formId) {

            /*INVENTORY*/
            case FORMMINECODEID: {
                return formFocus.mineCodeFocus && !formValid.mineCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.mineCodeError}</span>
                    : formValid.mineCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.mineCodeAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && !formValid.websiteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{formError.websiteError}</span>
                        : null

                /*
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
                */
            }
            case FORMSITENAMEENGID: {
                return formFocus.siteNameENGFocus && !formValid.siteNameENGValid ?
                    <span className="input-invalid-feedback-kekax">{formError.siteNameENGError}</span>
                    : formValid.siteNameENGValid ?
                        <span className="input-valid-feedback-kekax">{formError.siteNameENGError}</span>
                        : null
            }
            case FORMSITENAMENOLID: {
                return formFocus.siteNameNOLFocus && !formValid.siteNameNOLValid ?
                    <span className="input-invalid-feedback-kekax">{formError.siteNameNOLError}</span>
                    : formValid.siteNameNOLValid ?
                        <span className="input-valid-feedback-kekax">{formError.siteNameNOLError}</span>
                        : null
            }
            case FORMCOUNTRYID: {
                return formFocus.countryFocus && !formValid.countryValid ?
                    <span className="input-invalid-feedback-kekax">{formError.countryError}</span>
                    : formValid.countryValid ?
                        <span className="input-valid-feedback-kekax">{formError.countryError}</span>
                        : null
            }
            case FORMREGIONSTATEPROVINCEID: {
                return formFocus.regionStateProvinceFocus && !formValid.regionStateProvinceValid ?
                    <span className="input-invalid-feedback-kekax">{formError.regionStateProvinceError}</span>
                    : formValid.regionStateProvinceValid ?
                        <span className="input-valid-feedback-kekax">{formError.regionStateProvinceError}</span>
                        : null
            }
            case FORMMUNICIPALITYID: {
                return formFocus.municipalityFocus && !formValid.municipalityValid ?
                    <span className="input-invalid-feedback-kekax">{formError.municipalityError}</span>
                    : formValid.municipalityValid ?
                        <span className="input-valid-feedback-kekax">{formError.municipalityError}</span>
                        : null
            }
            case FORMTOURISTICNAMEID: {
                return formFocus.touristicnameFocus && !formValid.touristicnameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.touristicnameError}</span>
                    : formValid.touristicnameValid ?
                        <span className="input-valid-feedback-kekax">{formError.touristicnameError}</span>
                        : null
            }
            case FORMMINEPOPDESCID: {
                return formFocus.minePopDescFocus && !formValid.minePopDescValid ?
                    <span className="input-invalid-feedback-kekax">{formError.minePopDescError}</span>
                    : formValid.minePopDescValid ?
                        <span className="input-valid-feedback-kekax">{formError.minePopDescError}</span>
                        : null
            }
            case FORMCOMPLEXID: {
                return formFocus.complexFocus && !formValid.complexValid ?
                    <span className="input-invalid-feedback-kekax">{formError.complexError}</span>
                    : formValid.complexValid ?
                        <span className="input-valid-feedback-kekax">{formError.complexError}</span>
                        : null
            }
            case FORMCOMPLEXNAMEID: {
                return formFocus.complexNameFocus && !formValid.complexNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError.complexNameError}</span>
                    : formValid.complexNameValid ?
                        <span className="input-valid-feedback-kekax">{formError.complexNameError}</span>
                        : null
            }
            case FORMMINEHERITAGEROUTEID: {
                return formFocus.mineHeritageRouteFocus && !formValid.mineHeritageRouteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.mineHeritageRouteError}</span>
                    : formValid.mineHeritageRouteValid ?
                        <span className="input-valid-feedback-kekax">{formError.mineHeritageRouteError}</span>
                        : null
            }
            case FORMPROJECTFIGID: {
                return formFocus.projectFigFocus && !formValid.projectFigValid ?
                    <span className="input-invalid-feedback-kekax">{formError.projectFigError}</span>
                    : formValid.projectFigValid ?
                        <span className="input-valid-feedback-kekax">{formError.projectFigError}</span>
                        : null
            }
            case FORMINFOFIGUREID: {
                return formFocus.infoFigureFocus && !formValid.infoFigureValid ?
                    <span className="input-invalid-feedback-kekax">{formError.infoFigureError}</span>
                    : formValid.infoFigureValid ?
                        <span className="input-valid-feedback-kekax">{formError.infoFigureError}</span>
                        : null
            }
            case FORMCARPARKID: {
                return formFocus.carParkFocus && !formValid.carParkValid ?
                    <span className="input-invalid-feedback-kekax">{formError.carParkError}</span>
                    : formValid.carParkValid ?
                        <span className="input-valid-feedback-kekax">{formError.carParkError}</span>
                        : null
            }
            case FORMCAFETARIAID: {
                return formFocus.cafeteriaFocus && !formValid.cafeteriaValid ?
                    <span className="input-invalid-feedback-kekax">{formError.cafeteriaError}</span>
                    : formValid.cafeteriaValid ?
                        <span className="input-valid-feedback-kekax">{formError.cafeteriaError}</span>
                        : null
            }
            case FORMGIFTSHOPID: {
                return formFocus.giftShopFocus && !formValid.giftShopValid ?
                    <span className="input-invalid-feedback-kekax">{formError.giftShopError}</span>
                    : formValid.giftShopValid ?
                        <span className="input-valid-feedback-kekax">{formError.giftShopError}</span>
                        : null
            }
            case FORMADDRESSID: {
                return formFocus.addressFocus && !formValid.addressValid ?
                    <span className="input-invalid-feedback-kekax">{formError.addressError}</span>
                    : formValid.addressValid ?
                        <span className="input-valid-feedback-kekax">{formError.addressError}</span>
                        : null
            }
            case FORMADDRESS2ID: {
                return formFocus.address2Focus && !formValid.address2Valid ?
                    <span className="input-invalid-feedback-kekax">{formError.address2Error}</span>
                    : formValid.address2Valid ?
                        <span className="input-valid-feedback-kekax">{formError.address2Error}</span>
                        : null
            }
            case FORMPOSTALCODEID: {
                return formFocus.postalCodeFocus && !formValid.postalCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError.postalCodeError}</span>
                    : formValid.postalCodeValid ?
                        <span className="input-valid-feedback-kekax">{formError.postalCodeError}</span>
                        : null
            }
            case FORMPHONEID: {
                return formFocus.phoneFocus && !formValid.phoneValid ?
                    <span className="input-invalid-feedback-kekax">{formError.phoneError}</span>
                    : formValid.phoneValid ?
                        <span className="input-valid-feedback-kekax">{formError.phoneError}</span>
                        : null
            }
            case FORMMAILID: {
                return formFocus.mailFocus && !formValid.mailValid ?
                    <span className="input-invalid-feedback-kekax">{formError.mailError}</span>
                    : formValid.mailValid ?
                        <span className="input-valid-feedback-kekax">{formError.mailError}</span>
                        : null
            }
            case FORMOTHERINFOCONTID: {
                return formFocus.otherInfoContFocus && !formValid.otherInfoContValid ?
                    <span className="input-invalid-feedback-kekax">{formError.otherInfoContError}</span>
                    : formValid.otherInfoContValid ?
                        <span className="input-valid-feedback-kekax">{formError.otherInfoContError}</span>
                        : null
            }
            case FORMPUBTRANSPORTID: {
                return formFocus.pubTransportFocus && !formValid.pubTransportValid ?
                    <span className="input-invalid-feedback-kekax">{formError.pubTransportError}</span>
                    : formValid.pubTransportValid ?
                        <span className="input-valid-feedback-kekax">{formError.pubTransportError}</span>
                        : null
            }
            case FORMPUBTRANSPORTDESCID: {
                return formFocus.pubTransportDescFocus && !formValid.pubTransportDescValid ?
                    <span className="input-invalid-feedback-kekax">{formError.pubTransportDescError}</span>
                    : formValid.pubTransportDescValid ?
                        <span className="input-valid-feedback-kekax">{formError.pubTransportDescError}</span>
                        : null
            }
            /*----INVENTORY*/




            case FORMROUNDID + indexRound + FORMQUESTIONID: {

                return formValid.roundsValid[indexRound] && formFocus.roundsFocus[indexRound] && formFocus.roundsFocus[indexRound].questionFocus && !formValid.roundsValid[indexRound].questionValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.roundsValid[indexRound] && formValid.roundsValid[indexRound].questionValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
            }
            case FORMROUNDID + indexRound + FORMANSWERID + indexAnswer: {
                return formFocus.roundsFocus[indexRound] && formValid.roundsValid[indexRound] && formFocus.roundsFocus[indexRound].answersFocus[indexAnswer] && !formValid.roundsValid[indexRound].answersValid[indexAnswer] ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.roundsValid[indexRound] && formValid.roundsValid[indexRound].answersValid[indexAnswer] ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end

    }

    const checkCorrectOptionHandler = (indexRound: any, indexAnswer: any) => {
        let copyRounds = [...formInput.rounds]

        if (formInput.rounds[indexRound].correct === alphabet[indexAnswer]) {
            copyRounds[indexRound].correct = ""

        } else {
            copyRounds[indexRound].correct = alphabet[indexAnswer]

        }
        setFormInput({ ...formInput, rounds: copyRounds })

    }

    const checkIsCorrectOption = (indexRound: any, indexAnswer: any) => {
        if (formInput.rounds[indexRound].correct === alphabet[indexAnswer]) {
            return true
        } else {
            return false;
        }

    }

    const flagDropdownHandler = () => {
        if (refFlagsDropdownMenu.current !== null) {
            const myElement: HTMLElement | null = document.getElementById(FORMCOUNTRYID);
            const myParent: HTMLElement | null = document.getElementById("editmine-parent-container");


            if (myElement !== null && myParent !== null) {
                const yPos = (myParent.getBoundingClientRect().y + myParent.getBoundingClientRect().height - myElement.getBoundingClientRect().y)
                refFlagsDropdownMenu.current.style.bottom = yPos + 'px';
                refFlagsDropdownMenu.current.style.left = myElement.getBoundingClientRect().left - myParent.getBoundingClientRect().left + 'px';
            }

        }

        if (!flagsDropdown.open) {
            setFlagsDropdown({ open: true, class: 'flags-menu-drop flags-menu-drop-open' })
        } else {
            setFlagsDropdown({ open: false, class: 'flags-menu-drop' })
        }

    }

    const changeInputCountryHandler = (option: string) => {
        setFormInput({ ...formInput, country: option })
    }

    const showListCondition = () => {
        let countStatus = 0;
        for (let i = 0; i < formInput.rounds.length; i++) {
            if (formInput.rounds[i].status === -1) {
                countStatus++;
            }
        }
        return countStatus === formInput.rounds.length || formInput.rounds.length === 0 ? false : true;
    }

    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div className="container-edit-mine-inventory">
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.firstTitle}</span></div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.profile.editProfileModal.country.title} *
                            </label>
                            <button type="button" className="input-country" id={FORMCOUNTRYID} onClick={(event: any) => { validateFocusFields(event, null, null); flagDropdownHandler() }} ref={refFlagsDropdownButton}>
                                {
                                    formInput.country === "" ?
                                        <>
                                            <div className="text-ph"><span>{props.appLanguage.register.countryph}</span></div>
                                            <div className="arrow-down"></div>
                                        </>
                                        :
                                        <>
                                            <div className="text-country"><span>{formInput.country.toUpperCase()}</span></div>
                                            <div className="arrow-down"></div>

                                        </>
                                }

                            </button>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOUNTRYID, null, null)
                                }
                            </div>

                        </div>
                    </div>
                    <div className="map-wrapper">
                        <div className="sidebar">
                            {props.appLanguage.mapPage.lng}: {viewport.longitude} | {props.appLanguage.mapPage.lat}: {viewport.latitude} | {props.appLanguage.mapPage.zoom}: {viewport.zoom}
                        </div>

                        <ReactMapGL className='map-container' {...viewport} width="60%" height="30vmax" onViewportChange={(viewport) => setViewport(viewport)}
                            ref={mapRef}
                            mapboxApiAccessToken={mapToken}
                            mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
                            onClick={(e: any) => newMarkerHandler(e)}
                        >
                            {
                                formInput.lat !== null && formInput.lon !== null ?
                                    <Marker latitude={formInput.lat} longitude={formInput.lon} offsetLeft={-20} offsetTop={-50} draggable onDragEnd={(e: any) => newMarkerHandler(e)}>
                                        <div className="marker"></div>
                                    </Marker>
                                    : null
                            }
                        </ReactMapGL>
                        <div className="info-container">
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[0]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[1]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[2]}</span>
                            <span>{props.appLanguage.backoffice.manageMines.add.mapInfo[3]}</span>
                        </div>
                    </div>


                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mineCode} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.mineCodeph}
                                id={FORMMINECODEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mineCode || ""}
                                disabled

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINECODEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.siteNameENG}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.siteNameENGph}
                                id={FORMSITENAMEENGID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.siteNameENG || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSITENAMEENGID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.siteNameNOL}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.siteNameNOLph}
                                id={FORMSITENAMENOLID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.siteNameNOL || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMSITENAMENOLID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.regionStateProvince}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.regionStateProvinceph}
                                id={FORMREGIONSTATEPROVINCEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.regionStateProvince || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMREGIONSTATEPROVINCEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.municipality}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.municipalityph}
                                id={FORMMUNICIPALITYID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.municipality || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMUNICIPALITYID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.address} *

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.addressph}
                                id={FORMADDRESSID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.address || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMADDRESSID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.address2}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.address2ph}
                                id={FORMADDRESS2ID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.address2 || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMADDRESS2ID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.postalCode}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.postalCodeph}
                                id={FORMPOSTALCODEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.postalCode || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPOSTALCODEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.phone}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.phoneph}
                                id={FORMPHONEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.phone || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPHONEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.website}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.websiteph}
                                id={FORMWEBSITEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.website || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMWEBSITEID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mail}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.mailph}
                                id={FORMMAILID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mail || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMAILID, null, null)
                                }
                            </div>
                        </div>
                    </div>


                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.touristicname}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.touristicnameph}
                                id={FORMTOURISTICNAMEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.touristicname || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMTOURISTICNAMEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.complex}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCOMPLEXID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.complex || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.complexph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOMPLEXID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.complexName}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.complexNameph}
                                id={FORMCOMPLEXNAMEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.complexName || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCOMPLEXNAMEID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row-split">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.projectFig}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMPROJECTFIGID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.projectFig || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.projectFigph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPROJECTFIGID, null, null)
                                }
                            </div>
                        </div>



                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.infoFigure}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.infoFigureph}
                                id={FORMINFOFIGUREID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.infoFigure || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMINFOFIGUREID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.minePopDesc}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.minePopDescph}
                                id={FORMMINEPOPDESCID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.minePopDesc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINEPOPDESCID, null, null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.secondTitle}</span></div>

                    <div className="main-row-triple">

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.carPark}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCARPARKID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.carPark || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.carParkph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCARPARKID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.cafeteria}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMCAFETARIAID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.cafeteria || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.cafeteriaph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMCAFETARIAID, null, null)
                                }
                            </div>
                        </div>

                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.giftShop}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMGIFTSHOPID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.giftShop || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.giftShop}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMGIFTSHOPID, null, null)
                                }
                            </div>
                        </div>

                    </div>


                    <div className="main-row-split">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.pubTransport}

                            </label>
                            <input className="input-normal-text kekax-input-box"
                                type="text"
                                placeholder={props.appLanguage.backoffice.manageMines.add.pubTransportph}
                                id={FORMPUBTRANSPORTID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.pubTransport || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPUBTRANSPORTID, null, null)
                                }
                            </div>
                        </div>
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.pubTransportDesc}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.pubTransportDescph}
                                id={FORMPUBTRANSPORTDESCID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.pubTransportDesc || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMPUBTRANSPORTDESCID, null, null)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.sites.thirdTitle}</span></div>

                    <div className="main-row">
                        <div className="input-medium">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.mineHeritageRoute}
                            </label>
                            <select className="input-normal-text kekax-input-box"
                                id={FORMMINEHERITAGEROUTEID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.mineHeritageRoute || ""}

                            >
                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.mineHeritageRouteph}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                            </select>
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMMINEHERITAGEROUTEID, null, null)
                                }
                            </div>
                        </div>
                    </div>



                    <div className="main-row">
                        <div className="input-normal">
                            <label className="label">
                                {props.appLanguage.backoffice.manageMines.add.otherInfoCont}

                            </label>
                            <textarea className="input-area-text kekax-input-box"
                                placeholder={props.appLanguage.backoffice.manageMines.add.otherInfoContph}
                                id={FORMOTHERINFOCONTID}
                                onChange={(event: any) => changeInputHandler(event, null, null)}
                                onFocus={(event: any) => validateFocusFields(event, null, null)}
                                value={formInput.otherInfoCont || ""}

                            />
                            <div className="feedback-block">
                                {
                                    validatorFeedback(FORMOTHERINFOCONTID, null, null)
                                }
                            </div>
                        </div>
                    </div>





                </div>
            )
        } else if (selectedStepper === "1") {
            return (
                <FacilitiesInfoEdit
                    formInput={(childData: any) => setFormInputFacilitiesInfo(childData)}
                    formError={(childData: any) => setFormErrorFacilitiesInfo(childData)}
                    formValid={(childData: any) => setFormValidFacilitiesInfo(childData)}
                    formFocus={(childData: any) => setFormFocusFacilitiesInfo(childData)}
                    formValidation={(childData: any) => setFacilitiesInfoValid(childData)}
                    injectData={{
                        injectInput: formInputFacilitiesInfo, injectValid: formValidFacilitiesInfo, injectFocus: formFocusFacilitiesInfo, injectError: formErrorFacilitiesInfo,
                        injectAuxCountry: facilityTypeAuxArray, injectAuxMineType: mineTypeAuxArray, injectAuxSituation: situationeAuxArray, injectAuxCategories: categoriesAuxArray, injectAuxFaciltyType: facilityTypeAuxArray
                    }}


                />
            )

        } else if (selectedStepper === "2") {
            return (
                <MineProductionEdit
                    formInput={(childData: any) => setFormInputMineProductions(childData)}
                    formError={(childData: any) => setFormErrorMineProductions(childData)}
                    formValid={(childData: any) => setFormValidMineProductions(childData)}
                    formFocus={(childData: any) => setFormFocusMineProductions(childData)}
                    formValidation={(childData: any) => setMineProductionsValid(childData)}
                    injectData={{ injectInput: formInputMineProductions, injectValid: formValidMineProductions, injectFocus: formFocusMineProductions, injectError: formErrorMineProductions, facilities: formInputFacilitiesInfo }}


                />
            )
        } else if (selectedStepper === "3") {
            return (
                <LegendsHistoryEventsEdit
                    formInput={(childData: any) => setFormInputLegendsHistoryEvents(childData)}
                    formError={(childData: any) => setFormErrorLegendsHistoryEvents(childData)}
                    formValid={(childData: any) => setFormValidLegendsHistoryEvents(childData)}
                    formFocus={(childData: any) => setFormFocusLegendsHistoryEvents(childData)}
                    formValidation={(childData: any) => setLegendsHistoryEventsValid(childData)}
                    injectData={{ injectInput: formInputLegendsHistoryEvents, injectValid: formValidLegendsHistoryEvents, injectFocus: formFocusLegendsHistoryEvents, injectError: formErrorLegendsHistoryEvents }}


                />
            )

        } else if (selectedStepper === "4") {
            return (
                <BibliographyEdit
                    formInput={(childData: any) => setFormInputBibliography(childData)}
                    formError={(childData: any) => setFormErrorBibliography(childData)}
                    formValid={(childData: any) => setFormValidBibliography(childData)}
                    formFocus={(childData: any) => setFormFocusBibliography(childData)}
                    formValidation={(childData: any) => setBibliographyValid(childData)}
                    injectData={{ injectInput: formInputBibliography, injectValid: formValidBibliography, injectFocus: formFocusBibliography, injectError: formErrorBibliography }}


                />
            )

        } else if (selectedStepper === "5") {
            return (
                <div className="container-edit-mine-quiz">

                    {
                        !showListCondition() &&
                        <div className="quiz-header">
                            <span className="start-quiz">{props.appLanguage.backoffice.manageMines.add.quiz.start[0]}</span>
                            <div className="add-round" onClick={addRoundHandler}>
                                <span>{props.appLanguage.backoffice.manageMines.add.quiz.start[1]}</span>
                            </div>

                        </div>
                    }
                    {
                        showListCondition() &&
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.game[0]}</span></div>

                    }
                    {
                        showListCondition() &&
                        formInput.rounds.map((round: IFormRound, index1: number) => (
                            <div className="quiz-round" key={"round" + index1}>
                                <div className="round-header">
                                    <div className="add-up" onClick={() => addRoundUpHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addRound[0]} direction="top">
                                            <div className="add-up-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                    <div className="add-down" onClick={() => addRoundDownHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addRound[1]} direction="top">
                                            <div className="add-down-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                    <div className="rmv" onClick={() => rmvRoundHandler(index1)}>
                                        <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.rmvRound} direction="top">
                                            <div className="rmv-icon"></div>
                                        </TooltipKekax>
                                    </div>
                                </div>
                                <div className="main-question">
                                    <div className="input-question">
                                        <input className="input-question-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.quiz.questionph}
                                            id={FORMROUNDID + index1 + FORMQUESTIONID}
                                            onChange={(event: any) => changeInputHandler(event, index1, null)}
                                            onFocus={(event: any) => validateFocusFields(event, index1, null)}
                                            value={round.question || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMROUNDID + index1 + FORMQUESTIONID, index1, null)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="container-answers">
                                    <div className="answers-header">
                                        {
                                            formInput.rounds[index1].answers.length < alphabet.length &&
                                            <div className="add-answer" onClick={() => addAnswerHandler(index1)}>
                                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.addAnswer} direction="top">
                                                    <div className="add-icon"></div>
                                                </TooltipKekax>
                                            </div>
                                        }
                                        {
                                            round.answers.length > 2 &&
                                            <div className="rmv-answer" onClick={() => rmvAnswerHandler(index1)}>
                                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.quiz.rmvAnswer} direction="top">
                                                    <div className="rmv-icon"></div>
                                                </TooltipKekax>
                                            </div>
                                        }
                                    </div>

                                    {
                                        round.answers.map((answer: string, index2: number) => (
                                            <div key={"round" + index1 + "answer" + index2} className="main-answer">
                                                <div className="input-answer">
                                                    <input className="input-answer-text kekax-input-box"
                                                        type="text"
                                                        placeholder={props.appLanguage.backoffice.manageMines.add.quiz.answerph + alphabet[index2]}
                                                        id={FORMROUNDID + index1 + FORMANSWERID + index2}
                                                        onChange={(event: any) => changeInputHandler(event, index1, index2)}
                                                        onFocus={(event: any) => validateFocusFields(event, index1, index2)}
                                                        value={answer || ""}

                                                    />
                                                    <div className="feedback-block">
                                                        {
                                                            validatorFeedback(FORMROUNDID + index1 + FORMANSWERID + index2, index1, index2)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={checkIsCorrectOption(index1, index2) ? "input-check input-check-active" : "input-check"} onClick={() => checkCorrectOptionHandler(index1, index2)}>
                                                    <TooltipKekax content={checkIsCorrectOption(index1, index2) ? props.appLanguage.backoffice.manageMines.add.quiz.uncheckCorrect :
                                                        props.appLanguage.backoffice.manageMines.add.quiz.checkCorrect} direction="top">
                                                        <div className="check-icon">
                                                        </div>
                                                    </TooltipKekax>
                                                </div>

                                            </div>
                                        ))
                                    }

                                </div>

                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const printNextButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 1: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 2: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 3: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid || !legendsHistoryEventsValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            case 4: {
                return (
                    <div className="next"><button type="button" disabled={!formSubmitValid.inventory || !facilitiesInfoValid || !mineProductionsValid || !legendsHistoryEventsValid || !bibliographyValid} className="next-btn" onClick={() => changeStepperHandler("NEXT")} >{props.appLanguage.backoffice.manageMines.add.next}</button></div>
                )
            }
            default: {
                return null;
            }
        }
    }

    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        if (count === 5) {
            return <div className="submit"><button type="submit" disabled={!formSubmitValid.inventory || !formSubmitValid.facilities || !mineProductionsValid || !legendsHistoryEventsValid || !bibliographyValid || !formSubmitValid.quiz || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageMines.add.submit} </button></div>
        } else {
            return null
        }
    }

    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }


    return (
        <React.Fragment>
            <div className="wrapper-edit-mine" ref={modalRef} >
                <div className="overlay" ref={overlayRef}></div>
                <form className="content" ref={contentRef} id='editmine-parent-container' onSubmit={(event: any) => submitHandler(event)} >
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageMines.edit.title}</span>
                        <div className="close"><button type="button" className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}></button></div>
                    </div>
                    <div className="body">
                        <div className="container-stepper">
                            <StepperKekax active={selectedStepper}>
                                <div key="0">{props.appLanguage.backoffice.manageMines.add.information.sites.tab}</div>
                                <div key="1">{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.tab}</div>
                                <div key="2">{props.appLanguage.backoffice.manageMines.add.information.mineProductions.tab}</div>
                                <div key="3">{props.appLanguage.backoffice.manageMines.add.information.legendsHistoryEvents.tab}</div>
                                <div key="4">{props.appLanguage.backoffice.manageMines.add.information.bibliography.tab}</div>
                                <div key="5">{props.appLanguage.backoffice.manageMines.add.information.game[1]}</div>

                            </StepperKekax>
                        </div>
                        <div className="container-stepper-body">
                            <div className="container-form-edit-mine">

                                {
                                    printActiveContent()
                                }


                            </div>
                        </div>

                    </div>
                    <div className={flagsDropdown.class} ref={refFlagsDropdownMenu}>
                        <div className="wrapper-flags-menu-dropdown">
                            <div className="drop-filter-wrapper">
                                {
                                    countryAuxArray.map((flag: IFormInputAuxCountry, index: number) => (

                                        <div key={"countryMine" + index} className={formInput.country === flag.countryCode ? "drop-option opt-active" : "drop-option"} onClick={() => { changeInputCountryHandler(flag.countryCode); setFlagsDropdown({ open: false, class: 'flags-menu-drop' }) }}>
                                            <span className="text">{flag.officialName.toUpperCase()}</span>
                                        </div>
                                    ))

                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageMines.edit.cancel}</span></button></div>
                        <div className="previous"><button type="button" className="previous-btn" disabled={selectedStepper === "0" ? true : false} onClick={() => changeStepperHandler("PREVIOUS")}><span className="info">{props.appLanguage.backoffice.manageEvents.edit.previous}</span></button></div>
                        {

                            printNextButton()
                        }
                        {
                            printSubmitButton()

                        }
                    </div>
                </form>

            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}



export default connect(mapStateToProps)(EditMineModal);

/*
<div className="opt-flag" style={{ backgroundImage: "url(" + require("../../../../assets/flags/" + formInput.country + ".png") + ")" }}></div>




*/