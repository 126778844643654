import * as React from 'react'
import './Models.css';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import { connect } from 'react-redux';
import { useState } from 'react';
import TooltipKekax from '../kekax/TooltipKekax';

interface IProps extends StoreProps { }

interface IOpenTheseDrops {

}

export const Models: React.FC<IProps> = (props) => {

    const [openDrop, setOpenDrop] = useState<boolean[]>([false, false, false])


    const handleOpenDrop = (idx: number) => {
        let dummby: boolean[] = [...openDrop]

        for (let i = 0; i < dummby.length; i++) {
            if (i === idx) {
                dummby[i] = !dummby[i]
                break;
            }
        }
        setOpenDrop([...dummby])
    }

    return (
        <div className="wrapper-models">
            <div className="container-header">
                <div className="content">
                    <span>{props.appLanguage.models.header[0]}</span>
                </div>
            </div>
            <div className="container-models">
                <div className="model-box">
                    <div className="model-box-ayy">

                        <div className="model-title">
                            <div className="model-title-a">
                                <span>GO UNDERGROUND</span>
                            </div>
                            <div className="model-title-b">
                                {
                                    !openDrop[0] ?
                                        <TooltipKekax content={props.appLanguage.models.expand} direction="top">
                                            <div className="model-title-b-icon-down" onClick={() => handleOpenDrop(0)}></div>
                                        </TooltipKekax>
                                        :
                                        <TooltipKekax content={props.appLanguage.models.minimize} direction="top">
                                            <div className="model-title-b-icon-up" onClick={() => handleOpenDrop(0)}></div>
                                        </TooltipKekax>
                                }
                            </div>
                        </div>
                        {openDrop[0] &&
                            <div className="model">
                                <div className="model-big-block">
                                    <div className="big-block-title">
                                        <div className="big-block-title-a"><span>TIBÃES MINE – MineHeritage Case Study 1 [MH1]</span></div>

                                    </div>

                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>GeoTec System - Main tunnel (100000530 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/geotec/main_tunnel/main_tunnel"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/geotec/main_tunnel/main_tunnel</a></div>
                                    </div>
                                    <hr className="block-separator"></hr>
                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>GeoTec System - Secondary tunnel (99999858 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/geotec/secondary_tunnel/secondary_tunnel"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/geotec/secondary_tunnel/secondary_tunnel</a></div>
                                    </div>


                                </div>

                                <div className="model-big-block">
                                    <div className="big-block-title">
                                        <div className="big-block-title-a"><span>VALONGO MINE  – MineHeritage Case Studies 2 and 3 [MH2 and MH3]</span></div>
                                    </div>

                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>FARO Laser Scan – Fojo das pombas (1433526511 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/fojo_das_pombas/fojo_das_pombas"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/fojo_das_pombas/fojo_das_pombas</a></div>
                                    </div>
                                    <hr className="block-separator"></hr>
                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>FARO Laser Scan – Gallery G1 (1095141160 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/galeria_g1/galeria_g1"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/galeria_g1/galeria_g1</a></div>
                                    </div>
                                    <hr className="block-separator"></hr>
                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>FARO Laser Scan – Gallery G1 and Fojo das pombas (2528667671 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/valongo_mine/valongo_mine"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/static_lidar/valongo_mine/valongo_mine</a></div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="model-box">
                    <div className="model-box-ayy">
                        <div className="model-title">

                            <div className="model-title-a">
                                <span>FLY WITH US</span>
                            </div>
                            <div className="model-title-b">
                                {
                                    !openDrop[1] ?
                                        <TooltipKekax content={props.appLanguage.models.expand} direction="top">
                                            <div className="model-title-b-icon-down" onClick={() => handleOpenDrop(1)}></div>
                                        </TooltipKekax>
                                        :
                                        <TooltipKekax content={props.appLanguage.models.minimize} direction="top">
                                            <div className="model-title-b-icon-up" onClick={() => handleOpenDrop(1)}></div>
                                        </TooltipKekax>
                                }
                            </div>


                        </div>
                        {openDrop[1] &&
                            <div className="model">
                                <div className="model-big-block">
                                    <div className="big-block-title">
                                        <div className="big-block-title-a"><span>TTIBÃES MINE – MineHeritage Case Study 1 [MH1]</span></div>
                                    </div>


                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>UAV - Stairway and Enclosure (19774640 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/uav/enclosure_stairway/enclosure_stairway"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/uav/enclosure_stairway/enclosure_stairway</a></div>
                                    </div>



                                </div>
                                <div className="model-big-block">
                                    <div className="big-block-title"
                                    ><div className="big-block-title-a"><span>VALONGO MINE  – MineHeritage Case Studies 2 and 3 [MH2 and MH3]</span></div>

                                    </div>



                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>UAV – Fojo das pombas (10278926 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/uav/valongo_mine/valongo_mine"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/valongo_mine/uav/valongo_mine/valongo_mine</a></div>
                                    </div>


                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="model-box">
                    <div className="model-box-ayy">

                        <div className="model-title">

                            <div className="model-title-a">
                                <span>WALK WITH US</span>
                            </div>
                            <div className="model-title-b">
                                {
                                    !openDrop[2] ?
                                        <TooltipKekax content={props.appLanguage.models.expand} direction="top">
                                            <div className="model-title-b-icon-down" onClick={() => handleOpenDrop(2)}></div>
                                        </TooltipKekax>
                                        :
                                        <TooltipKekax content={props.appLanguage.models.minimize} direction="top">
                                            <div className="model-title-b-icon-up" onClick={() => handleOpenDrop(2)}></div>
                                        </TooltipKekax>
                                }
                            </div>
                        </div>
                        {openDrop[2] &&
                            <div className="model">
                                <div className="model-big-block">
                                    <div className="big-block-title">
                                        <div className="big-block-title-a"><span>TTIBÃES MINE – MineHeritage Case Study 1 [MH1]</span></div>

                                    </div>

                                    <div className="model-small-block">
                                        <div className="small-block-title"><span>FARO Laser Scan - Stairway and Chapel (1105824076 points)</span></div>
                                        <div className="small-block-link"><a href={"http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/static_lidar/stairway_chapel/stairway_chapel"} target="_blank" rel="noopener noreferrer">http://www.lsa.isep.ipp.pt/~adias/cloud/minas/projects/mineheritage/pointclouds/tibaes_mine/static_lidar/stairway_chapel/stairway_chapel</a></div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div >
    );

}

export default connect(mapStateToProps)(Models);
