import React, { useState, useEffect, useRef } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './QuizPage.css'
import { RouteComponentProps } from 'react-router-dom';

import StartPage from './startPage/StartPage';
import QuestionPage, { alphabet } from './questionPage/QuestionPage';
import EndPage from './endPage/EndPage';

import GeneralServices from '../../../services/generalService.json'
import GodCall from './godCall/GodCall';
import SnackKekax from '../../kekax/SnackKekax';
import { httpGetQuestionMine } from '../../../services/requestService';


interface IProps extends StoreProps, RouteComponentProps<any> { }


interface IAnswer {
    id: string
    answer: string
    available: boolean
}
export interface IRound {
    question: string
    answers: IAnswer[]
    correct: string
    decision: string
}

interface IGameTime {
    time: number
    timeInterval: any
    status: boolean
}

export interface IGameProgress {
    stage: number
    gameIsOver: boolean
    type: string
}

export interface IGameRound {
    nRound: number
    maxRound: number
}

export interface IGameHelper {
    fifty: number
    freeze: number
    call: number

}

export interface ITalkAboutOption {
    option: number
    show: boolean
}

interface IGodCall {
    what: number
    show: boolean
    answer: string
}


interface IGameResult {
    ansA: string
    ansB: string
    ansC: string
    ansD: string
    ansE: string
    correctAns: string
    explanation: string
    id: number
    institute: string
    mineCode: string
    photo: string
    question: string
}

export interface IGameHelpInterpretatition {
    id: number
    info: string
}

export const GAME: string = "GAME"
export const START: string = "START"
export const BEEPBOOP: string = "BEEP"


const maxBreakTime: number = 3
const maxRoundTime: number = 30
const maxBeepBoopTime: number = 6

export const maxGodCallTIme: number = 15
export const maxFreezeTime: number = 15

export const QuizPage: React.FC<IProps> = (props) => {

   
    const dummyRound: IGameResult[] = [
        {
            ansA: "A111111111111111 22222222222222 3333333333333333333 B4444444444444444 B4444444444444444B4444444444444444B4444444444444444B4444444444444444B4444444444444444B4444444444444444B4444444444444444B4444444444444444 55555555555555 666666666 AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AAAnswer number AA 55555555 66666666 TTTTTTTTTTTTTTThis is the question number 1, make sure you have study before this game!! Do you know the answer?",
            ansB: "Answer number B",
            ansC: "Answer number C",
            ansD: "Answer number D",
            ansE: "",
            correctAns: "A",
            explanation: "A",
            id: 0,
            institute: "A",
            mineCode: "A",
            photo: "A",
            question: "T111111111111111 22222222222222 3333333333333333333 4444444444444444 55555555555555 66666666 TTTTTTTTTTTTTTThis is the question number 1, make sure you have study before this game!! Do you know the answer? T111111111111111 22222222222222 3333333333333333333 4444444444444444 55555555555555 66666666 TTTTTTTTTTTTTTThis is the question number 1, make sure you have study before this game!! Do you know the answer? T111111111111111 22222222222222 3333333333333333333 4444444444444444 55555555555555 66666666 TTTTTTTTTTTTTTThis is the question number 1, make sure you have study before this game!! Do you know the answer?"
        }
        
    ]
 

    const [gameTime, setGameTime] = useState<IGameTime>({ time: maxRoundTime, timeInterval: null, status: false })
    const [breakTime, setBreakTime] = useState<IGameTime>({ time: maxBreakTime, timeInterval: null, status: false })

    const [gameRound, setGameRound] = useState<IGameRound>({ nRound: 1, maxRound: 0 })
    const [allRounds, setAllRounds] = useState<IRound[]>([{ question: "", answers: [], correct: "", decision: "" }])
    const [gameProgress, setGameProgress] = useState<IGameProgress>({ stage: 0, gameIsOver: false, type: START })
    const [gameHelper, setGameHelper] = useState<IGameHelper>({ fifty: -1, freeze: -1, call: -1 }) //-1 nunca | 0 usou | 1 ativo

    const [talkAboutAnswer, setTalkAboutAnswer] = useState<number>(-1)///-1 none / -69 colors display / -99 bye / -1337 miss / 1-x answer blinky
    const [talkAboutOption, setTalkAboutOption] = useState<ITalkAboutOption>({ option: -1, show: false }) //dar trigger aos helpers

    const [godCallModal, setGodCallModal] = useState<IGodCall>({ what: -1, show: false, answer: "" });
    const [gameResult, setGameResult] = useState<IGameResult[]>([]);

    const snackKekaxRef: any = useRef();



    /*
        FREEZE{
            SITUAÇÕES: 
                - ACABA DURANTE A RONDA
                - BEEPBOOP E O FREEZE TEM DE ACABAR
    
        }
    */


    useEffect(() => {
        const quizId = props.match.params.id;
        let timerGame: any = null;
        let timerBreak: any = null;;

        httpGetQuestionMine(quizId).then(
            res => {
                if (res.data.length === 0) {
                    props.history.push(GeneralServices.gamePagePath);
                    /*
                    setGameResult([...dummyRound])
                    timerGame = window.setInterval(handleGameTimer, 1000)
                    setGameTime({ ...gameTime, timeInterval: timerGame })
                    timerBreak = window.setInterval(handleBreakTimer, 1000)
                    setBreakTime({ ...breakTime, timeInterval: timerBreak, status: true })
                */
                } else {
                    setGameResult([...res.data])

                    //setGameRound({ ...gameRound, maxRound: dummyRound.length })
                    //setGameResult([...dummyRound])

                    // GAME TIME ----------
                    timerGame = window.setInterval(handleGameTimer, 1000)
                    setGameTime({ ...gameTime, timeInterval: timerGame })
                    // ---------- GAME TIME

                    // BREAK TIME ----------
                    timerBreak = window.setInterval(handleBreakTimer, 1000)
                    setBreakTime({ ...breakTime, timeInterval: timerBreak, status: true })
                    // ---------- BREAK TIME

                    /*
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                    */
                }

            }
        ).catch(
            err => {
                //props.history.push(GeneralServices.gamePagePath);
            }
        )

        return () => {
            window.clearInterval(timerGame);
            window.clearInterval(timerBreak);

        }
    }, [])

    useEffect(() => {
        if (gameResult.length !== 0) {

            let choosen: number[] = [];
            const maxNumberOfQuestions = 5;

            const min = Math.ceil(0); //arredonda para cima no matter what
            const max = Math.floor(gameResult.length - 1); //arredonda para baixo no matter what
            for (let i = 0; i < gameResult.length && i < maxNumberOfQuestions; i++) {
                if (choosen.length === 0) {
                    let result = Math.floor(Math.random() * (max - min + 1)) + min;
                    choosen.push(result)
                } else {
                    let keepCicle = true;
                    do {
                        let foundIt = false;
                        let result = Math.floor(Math.random() * (max - min + 1)) + min;
                        for (let x = 0; x < choosen.length; x++) {
                            if (choosen[x] === result) {
                                foundIt = true;
                                break;
                            }
                        }
                        if (!foundIt) {
                            keepCicle = false;
                            choosen.push(result)
                        }
                    }
                    while (keepCicle);
                }
            }
            //choosen = [4, 3, 2, 1, 5]
            let rounds: IRound[] = [];
            for (let z = 0; z < choosen.length; z++) {
                const rawRound = gameResult[choosen[z]];
                const question = rawRound.question;
                //const correctAnswer = rawRound.correctAns.slice(0, -1).toUpperCase();
                const correctAnswer = rawRound.correctAns.replace(")", "").toUpperCase();
                const rawAnswers: string[] = [rawRound.ansA, rawRound.ansB, rawRound.ansC, rawRound.ansD, rawRound.ansE]
                let answers: IAnswer[] = [];

                for (let i = 0; i < rawAnswers.length; i++) {
                    if (rawAnswers[i] !== "") {
                        answers.push({ answer: rawAnswers[i], id: alphabet[i], available: true })
                    }
                }
                rounds.push({ question: question, answers: answers, correct: correctAnswer, decision: "" })

            }
            setAllRounds([...rounds])
            setGameRound({ ...gameRound, maxRound: rounds.length })


        }
    }, [gameResult])





    useEffect(() => {
        if (breakTime.timeInterval !== null && breakTime.time === 0 && breakTime.status && gameProgress.type === START) {
            //SETUP FOR GAME
            setGameTime({ ...gameTime, status: true })
            setBreakTime({ ...breakTime, status: false, time: maxBreakTime })
            setGameProgress({ ...gameProgress, stage: 1, type: GAME })
        }
    }, [breakTime, gameProgress])


    const handleGameTimer = () => {
        setGameTime(prevState => (
            {
                ...prevState,
                time: prevState.status ? prevState.time - 1 : prevState.time
            }));
    }

    const handleBreakTimer = () => {
        setBreakTime(prevState => (
            {
                ...prevState,
                time: prevState.status ? prevState.time - 1 : prevState.time
            }));
    }


    useEffect(() => {

        if (!gameProgress.gameIsOver && gameRound.maxRound !== 0 && (gameTime.time <= 0 && allRounds[gameRound.nRound - 1].decision === "") && gameProgress.type === GAME) {
            startBeepBoop()
            setTalkAboutAnswer(-1337)
        } else if (!gameProgress.gameIsOver && gameRound.maxRound !== 0 && gameRound.nRound < gameRound.maxRound && !breakTime.status && gameProgress.type === BEEPBOOP) {
            setGameTime({ ...gameTime, time: maxRoundTime, status: true })
            setGameRound(prevState => ({
                ...prevState,
                nRound: prevState.nRound + 1
            }));
            setGameProgress({ ...gameProgress, type: GAME })

            //refresh nos helpers
            setGameHelper(prevState => ({
                ...prevState,
                fifty: prevState.fifty === 1 ? 0 : prevState.fifty,
                freeze: prevState.freeze === 1 ? 0 : prevState.freeze,
                call: prevState.call === 1 ? 0 : prevState.call
            }));


        } else if (!gameProgress.gameIsOver && gameRound.maxRound !== 0 && gameRound.nRound === gameRound.maxRound && !breakTime.status && gameProgress.type === BEEPBOOP) {

            //window.clearInterval(gameTime.timeInterval);
            //setGameTime({ time: maxRoundTime, timeInterval: null })

            setGameTime({ ...gameTime, time: maxRoundTime, status: false })
            setGameProgress({ ...gameProgress, gameIsOver: true, stage: 2, type: GAME })


            //refresh nos helpers
            setGameHelper(prevState => ({
                ...prevState,
                fifty: prevState.fifty === 1 ? 0 : prevState.fifty,
                freeze: prevState.freeze === 1 ? 0 : prevState.freeze,
                call: prevState.call === 1 ? 0 : prevState.call

            }));


        }


    }, [gameTime, gameRound, allRounds, gameProgress, breakTime])

    const handleReceiveAnswer = (answer: any) => {
        if (gameProgress.type === GAME) {
            setTalkAboutAnswer(answer)

            let copyAllRounds = [...allRounds]
            copyAllRounds[gameRound.nRound - 1].decision = alphabet[answer]
            setAllRounds([...copyAllRounds])

            startBeepBoop()

            //FREEZE CLOCK - SE FOR BEEPBOOP O FREEZE ACABA AUTOMATICAMENTE (preocupar TALKABOUT)
            if (gameHelper.freeze === 1) {
                setTalkAboutOption({ option: 1, show: false })
            }

        } else {
        }
    }

    /*
        CONDITIONS: answered or missed
    */
    const startBeepBoop = () => {
        //place beepboop here
        setGameProgress({ ...gameProgress, type: BEEPBOOP })
        setBreakTime({ ...breakTime, time: maxBeepBoopTime, status: true })
        setGameTime({ ...gameTime, status: false })
    }


    useEffect(() => {
        if (gameProgress.type === BEEPBOOP) {
            if (breakTime.time === 0) {
                //setGameProgress({ ...gameProgress, type: GAME })
                setBreakTime({ ...breakTime, time: maxBeepBoopTime, status: false })
                setTalkAboutAnswer(-1)
            } else if (breakTime.time === 3) {
                setTalkAboutAnswer(-69)
            } else if (breakTime.time === 2) {
                setTalkAboutAnswer(-99)
            }
        }
        //FREEZE CLOCK - SE JOGO TIVER A DECORRER E O FREEZE TIME ACABAR (preocupar TIME, BREAK, TALKABOUT)
        if (gameHelper.freeze === 1 && breakTime.time === 0 && gameProgress.type === GAME) {
            setBreakTime({ ...breakTime, time: maxBreakTime, status: false })
            setTalkAboutOption({ option: 1, show: false })
            setGameTime({ ...gameTime, status: true })

            //HELPER FREEZE FOI USADO
            setGameHelper({ ...gameHelper, freeze: 0 })
        }

    }, [gameProgress, breakTime, gameHelper.freeze])




    const handleQuitGame = (answer: any) => {
        if (answer) {
            const gamePageUrl = GeneralServices.gamePagePath
            props.history.push(gamePageUrl);
        }
    }

    const handleGameHelperManager = (help: IGameHelpInterpretatition) => {



        if (help.id === 0 && gameHelper.fifty === -1 && gameProgress.type === GAME) {
            //HELPER FIFTY FOI USADO
            setGameHelper({ ...gameHelper, fifty: 0 })

            setTimeout(() => {
                setTalkAboutOption({ option: help.id, show: false })

            }, 150)
            snackKekaxRef.current.openSnackBar(props.appLanguage.quizPage.quiz.snackKekax[1])

        } else if (help.id === 0 && help.info !== "" && gameHelper.fifty === 0 && gameProgress.type === GAME) {

            const arrayChildData = help.info.split(";")
            if (arrayChildData[0] === "answers") {
                let arrayOfAnswers: number[] = []
                for (let i = 1; i < arrayChildData.length; i++) {
                    arrayOfAnswers.push(parseInt(arrayChildData[i]))
                }
                let copyAllRounds: IRound[] = [...allRounds]

                for (let x = 0; x < copyAllRounds[gameRound.nRound - 1].answers.length; x++) {
                    for (let z = 0; z < arrayOfAnswers.length; z++) {
                        if (x === arrayOfAnswers[z]) {
                            copyAllRounds[gameRound.nRound - 1].answers[x].available = false
                        }
                    }
                }
                setAllRounds([...copyAllRounds])
            }

        } else if (help.id === 1 && gameHelper.freeze === -1 && gameProgress.type === GAME) {
            //HELPER FREEZE FICOU ATIVO
            setGameHelper({ ...gameHelper, freeze: 1 })

            setTimeout(() => {
                setTalkAboutOption({ option: help.id, show: true })

                //timers stuff
                setBreakTime({ ...breakTime, time: maxFreezeTime, status: true })
                setGameTime({ ...gameTime, status: false })

            }, 150)
            snackKekaxRef.current.openSnackBar(props.appLanguage.quizPage.quiz.snackKekax[2])

        } else if (help.id === 2 && gameHelper.call === -1 && gameHelper.freeze !== 1 && gameProgress.type === GAME) {
            //HELPER CALL FICOU ATIVO
            setGameHelper({ ...gameHelper, call: 1 })

            setTimeout(() => {
                setTalkAboutOption({ option: help.id, show: false })
                setGodCallModal({ ...godCallModal, show: true, what: 0, answer: allRounds[gameRound.nRound - 1].correct })

                //timers stuff
                setBreakTime({ ...breakTime, time: maxGodCallTIme, status: true })
                setGameTime({ ...gameTime, status: false })

            }, 150)
            snackKekaxRef.current.openSnackBar(props.appLanguage.quizPage.quiz.snackKekax[3])

        } else if (help.id === 2 && gameHelper.call === -1 && gameHelper.freeze === 1 && gameProgress.type === GAME) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.quizPage.quiz.snackKekax[0])
        }
    }
    /*
        useEffect(() => {
            if (gameProgress.gameIsOver) {
                
                console.log("-------------------STATS-------------------")
                console.log("GAME HELPER")
                console.log(gameHelper)
                console.log("GAME PROGRESS")
                console.log(gameProgress)
                console.log("GAME TIME")
                console.log(gameTime)
                console.log("BREAK TIME")
                console.log(breakTime)
                console.log("GAME ROUND")
                console.log(gameRound)
                
            }
        }, [gameProgress.gameIsOver])
    */

    const callbackGodCallModal = (childData: any) => {

        if (childData === "closed") {
            setGodCallModal({ ...godCallModal, show: false });
            setBreakTime({ ...breakTime, time: maxBreakTime, status: false })
            setGameTime({ ...gameTime, status: true })

            //HELPER GODCALL FOI USADO
            setGameHelper({ ...gameHelper, call: 0 })
        }
    }

    return (
        <React.Fragment>
            <div className="wrapper-quizpage">
                {
                    gameProgress.stage === 0 ?
                        <div className="container-quizpage">
                            <StartPage time={breakTime.time} />
                        </div>
                        :
                        gameProgress.stage === 1 ?
                            <div className="container-quizpage">
                                <QuestionPage gameRound={gameRound} gameHelper={gameHelper} time={gameTime.time} roundInfo={allRounds[gameRound.nRound - 1]} sendAnswer={handleReceiveAnswer} talkAboutAnswer={talkAboutAnswer} talkAboutOption={talkAboutOption} gameHelperManager={handleGameHelperManager} breakTime={breakTime.time} />
                            </div>

                            : gameProgress.stage === 2 ?
                                <div className="container-quizpage">
                                    <EndPage allRounds={allRounds} quitGame={handleQuitGame} {...props} />
                                </div>
                                :
                                <div className="container-quizpage">
                                </div>
                }

            </div>
            <GodCall open={godCallModal.show} what={godCallModal.what} callback={callbackGodCallModal} gameRound={gameRound} allRounds={allRounds} breakTime={breakTime.time} />
            <SnackKekax ref={snackKekaxRef} />
        </React.Fragment>
    );

}

export default connect(mapStateToProps)(QuizPage);
