import React, { useEffect, useRef } from 'react'
import './ConsortiumModal.css';

import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import { IConsortium } from '../Consortium';
import ReactMarkdown from 'react-markdown';


interface IProps extends StoreProps {
    open: boolean
    what: IConsortium
    callback: any
}

export const ConsortiumModal: React.FC<IProps> = (props) => {


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);


    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("consortium-modal-open");
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);

            handleOffset();
        } else {
            modalRef.current.classList.remove("consortium-modal-open");

        }
    }, [props.open])



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--consortiumModal-topOffset", posY + "px");
    }

    const handleClickAway = (event: any) => {
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(false);
        }
    }


    const closeModalHandler = (feedback: boolean) => {

        modalRef.current.classList.remove("consortium-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);


        feedback ? props.callback('worked') : props.callback('closed');
    }





    return (


        <div className="wrapper-consortium-modal" ref={modalRef}>
            <div className="overlay"></div>
            <div className="content" ref={contentRef}>
                <div className="header">
                    <div className="header-content">
                        <span className="modal-title">{props.what.title}</span>
                        <div className="close"><button className="close-btn" onClick={() => closeModalHandler(false)}><div className="img"></div></button></div>
                    </div>
                    <hr></hr>
                </div>
                <div className="body">
                    {
                        props.what.img !== undefined && props.what.img !== null && props.what.img.trim() !== "" &&
                        <div className="block-image">
                            <img src={props.what.img} alt="consortiumImg" />
                        </div>
                    }

                    <div className="block-text">
                        <ReactMarkdown source={props.what.text} escapeHtml={false} />
                    </div>
                </div>
                <div className="footer">
                    <hr></hr>
                </div>
            </div>
        </div>
    )

}

/*
<div className="footer-content">
<div className="cancel"><button className="cancel-btn" onClick={() => closeModalHandler(false)}><span className="info">{props.appLanguage.register.close}</span></button></div>
</div>
*/

export default connect(mapStateToProps)(ConsortiumModal);
