import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mineStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import './MineProductionEdit.css';

import GeneralServices from "../../../../../services/generalService.json"
import TooltipKekax from '../../../../kekax/TooltipKekax';
import { IFormInputFacilitiesInfo } from '../facilitiesInfoEdit/FacilitiesInfoEdit';

interface IProps extends StoreProps {
    formInput: any
    formError: any
    formValid: any
    formFocus: any
    injectData: IInjectData
    formValidation: any

}



export interface IFormErrorMineProductions {
    faciCodeError: string
    productError: string
    mainOresProducedError: string
    workingPeriodError: string
    productionDestinationError: string
    productionDestination2Error: string


}
export interface IFormInputMineProductions {
    id: string
    faciCode: string
    product: string
    mainOresProduced: string
    workingPeriod: string
    productionDestination: string
    productionDestination2: string
    status: number

}

export interface IFormValidMineProductions {
    faciCodeValid: boolean
    productValid: boolean
    mainOresProducedValid: boolean
    workingPeriodValid: boolean
    productionDestinationValid: boolean
    productionDestination2Valid: boolean
}

export interface IFormFocusMineProductions {
    faciCodeFocus: boolean
    productFocus: boolean
    mainOresProducedFocus: boolean
    workingPeriodFocus: boolean
    productionDestinationFocus: boolean
    productionDestination2Focus: boolean
}

interface IInjectData {
    injectInput: IFormInputMineProductions[]
    injectValid: IFormValidMineProductions[]
    injectFocus: IFormFocusMineProductions[]
    injectError: IFormErrorMineProductions[]
    facilities: IFormInputFacilitiesInfo[]

}

//MineProductions information
const FORMFACICODEID: string = 'faciCode'
const FORMPRODUCTID: string = 'product'
const FORMMAINORESPRODUCEDID: string = 'mainOresProduced'
const FORMWORKINGPERIODID: string = 'workingPeriod'
const FORMPRODUCTIONDESTINATIONID: string = 'productionDestination'
const FORMPRODUCTIONDESTINATION2ID: string = 'productionDestination2'


export const MineProductionEdit: React.FC<IProps> = (props) => {

    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(true);

    const [formInput, setFormInput] = useState<IFormInputMineProductions[]>([]);
    const formInputRef = useRef<any>(null)

    const [formError, setFormError] = useState<IFormErrorMineProductions[]>([]);
    const formErrorRef = useRef<any>(null)

    const [formValid, setFormValid] = useState<IFormValidMineProductions[]>([]);
    const formValidRef = useRef<any>(null)

    const [formFocus, setFormFocus] = useState<IFormFocusMineProductions[]>([]);
    const formFocusRef = useRef<any>(null)

    const [formFacilities, setFormFacilities] = useState<string[]>([]);
    const formFacilitiesRef = useRef<any>(null)

    const [iDidSomething, setIDidSomething] = useState(false)

    const didSomethingRef = useRef<any>(null)
    useEffect(() => {
        didSomethingRef.current = iDidSomething
    }, [iDidSomething])


    const [openMineProduction, setOpenMineProduction] = useState<number>(-1)

    useEffect(() => {

        if (!iDidSomething) {
            if (props.injectData.injectError.length > 0) {
                setFormError(props.injectData.injectError)
            }
            if (props.injectData.injectFocus.length > 0) {
                setFormFocus(props.injectData.injectFocus)
            }
            if (props.injectData.injectValid.length > 0) {
                setFormValid(props.injectData.injectValid)
            }
            if (props.injectData.injectInput.length > 0 && props.injectData.facilities.length > 0) {
                let copyFormInput: IFormInputMineProductions[] = [...props.injectData.injectInput]

                for (let i = 0; i < copyFormInput.length; i++) {
                    let found = false;
                    for (let x = 0; x < props.injectData.facilities.length; x++) {

                        if (copyFormInput[i].faciCode === props.injectData.facilities[x].facilityCode) {
                            found = true
                        }

                        if (!found && x === props.injectData.facilities.length - 1) {
                            copyFormInput[i].faciCode = ""
                        }

                        if (i === copyFormInput.length - 1 && x === props.injectData.facilities.length - 1) {
                            setFormInput([...copyFormInput])

                        }
                    }

                }

            } else if (props.injectData.injectInput.length > 0) {
                let copyFormInput: IFormInputMineProductions[] = [...props.injectData.injectInput]

                for (let i = 0; i < copyFormInput.length; i++) {
                    copyFormInput[i].faciCode = ""
                }

                setFormInput([...copyFormInput])
            }

            if (props.injectData.facilities.length > 0) {
                const dummyFacilities: string[] = []
                for (let i = 0; i < props.injectData.facilities.length; i++) {
                    if (props.injectData.facilities[i].status !== -1)
                        dummyFacilities.push(props.injectData.facilities[i].facilityCode)
                }
                setFormFacilities(dummyFacilities)
            } else {

                setFormFacilities([])
            }
        }

    }, [props.injectData, iDidSomething])

    useEffect(() => {
        /*
        if (!didSomethingRef.current) {
            if (props.injectData.injectError.length > 0) {
                setFormError(props.injectData.injectError)
            }
            if (props.injectData.injectFocus.length > 0) {
                setFormFocus(props.injectData.injectFocus)
            }
            if (props.injectData.injectValid.length > 0) {
                setFormValid(props.injectData.injectValid)
            }
            if (props.injectData.injectInput.length > 0 && props.injectData.facilities.length > 0) {
                let copyFormInput: IFormInputMineProductions[] = [...props.injectData.injectInput]

                for (let i = 0; i < copyFormInput.length; i++) {
                    let found = false;
                    for (let x = 0; x < props.injectData.facilities.length; x++) {

                        if (copyFormInput[i].faciCode === props.injectData.facilities[x].facilityCode) {
                            found = true
                        }

                        if (!found && x === props.injectData.facilities.length - 1) {
                            copyFormInput[i].faciCode = ""
                        }

                        if (i === copyFormInput.length - 1 && x === props.injectData.facilities.length - 1) {
                            setFormInput([...copyFormInput])

                        }
                    }

                }

            } else if (props.injectData.injectInput.length > 0) {
                let copyFormInput: IFormInputMineProductions[] = [...props.injectData.injectInput]

                for (let i = 0; i < copyFormInput.length; i++) {
                    copyFormInput[i].faciCode = ""
                }

                setFormInput([...copyFormInput])
            }

            if (props.injectData.facilities.length > 0) {
                const dummyFacilities: string[] = []
                for (let i = 0; i < props.injectData.facilities.length; i++) {
                    dummyFacilities.push(props.injectData.facilities[i].facilityCode)
                }
                setFormFacilities(dummyFacilities)
            } else {

                setFormFacilities([])
            }
        }
        */
        return () => {
            props.formError(formErrorRef.current)
            props.formFocus(formFocusRef.current)
            props.formInput(formInputRef.current)
            props.formValid(formValidRef.current)
            setIDidSomething(false)

            setOpenMineProduction(-1)
        }
    }, [])

    useEffect(() => {
        props.formValidation(formSubmitValid)

    }, [formSubmitValid])

    useEffect(() => {
        formInputRef.current = formInput
    }, [formInput])
    useEffect(() => {
        formValidRef.current = formValid
    }, [formValid])
    useEffect(() => {
        formFocusRef.current = formFocus
    }, [formFocus])
    useEffect(() => {
        formErrorRef.current = formError
    }, [formError])

    useEffect(() => {
        formFacilitiesRef.current = formFacilities
    }, [formFacilities])



    useEffect(() => {
        let mineProducValid = true

        for (let i = 0; i < formValid.length; i++) {
            if (formInput[i].status !== -1 && !formValid[i].faciCodeValid) {
                mineProducValid = false;
                break;
            }

        }
        //setFormSubmitValid({ inventory: formValid.pointValid && formValid.siteNameENGValid && formValid.addressValid && formValid.countryValid, quiz: roundValidation })
        setFormSubmitValid(mineProducValid)

    }, [formValid, formInput])


    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, indexMineProduction: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;
        let copyMineProductions = [...formInput]

        switch (formId) {

            case FORMFACICODEID + indexMineProduction: {
                copyMineProductions[indexMineProduction].faciCode = value
                setFormInput([...copyMineProductions])
                break;
            }
            case FORMPRODUCTID + indexMineProduction: {
                copyMineProductions[indexMineProduction].product = value
                setFormInput([...copyMineProductions])
                break;
            }
            case FORMMAINORESPRODUCEDID + indexMineProduction: {
                copyMineProductions[indexMineProduction].mainOresProduced = value
                setFormInput([...copyMineProductions])
                break;
            }
            case FORMWORKINGPERIODID + indexMineProduction: {
                copyMineProductions[indexMineProduction].workingPeriod = value
                setFormInput([...copyMineProductions])
                break;
            }
            case FORMPRODUCTIONDESTINATIONID + indexMineProduction: {
                copyMineProductions[indexMineProduction].productionDestination = value
                setFormInput([...copyMineProductions])
                break;
            }
            case FORMPRODUCTIONDESTINATION2ID + indexMineProduction: {
                copyMineProductions[indexMineProduction].productionDestination2 = value
                setFormInput([...copyMineProductions])
                break;
            }


            default: {
                break;
            }
        }//switch end
    }

    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors: IFormErrorMineProductions[] = [];
        let copyMineProductions: IFormValidMineProductions[] = [];

        for (let i = 0; i < formFocus.length; i++) {



            let dummy: IFormErrorMineProductions = {
                faciCodeError: '', productError: '', mainOresProducedError: '', workingPeriodError: '', productionDestinationError: '', productionDestination2Error: ''
            }

            const staticValue = { ...formInput[i] }
            let upd_faciCodeValid = false;
            if (staticValue.faciCode === '') {
                upd_faciCodeValid = false;
                dummy.faciCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_faciCodeValid = true;
                dummy.faciCodeError = props.appLanguage.backoffice.manageMines.add.mineProductions.faciCodeAccept;
            }

            let upd_productValid = false;
            if (staticValue.product === '') {
                upd_productValid = false;
                dummy.productError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_productValid = true;
                dummy.productError = props.appLanguage.backoffice.manageMines.add.mineProductions.productAccept;
            }

            let upd_mainOresProducedValid = false;
            if (staticValue.mainOresProduced === '') {
                upd_mainOresProducedValid = false;
                dummy.mainOresProducedError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_mainOresProducedValid = true;
                dummy.mainOresProducedError = props.appLanguage.backoffice.manageMines.add.mineProductions.mainOresProducedAccept;
            }

            let upd_workingPeriodValid = false;
            if (staticValue.workingPeriod === '') {
                upd_workingPeriodValid = false;
                dummy.workingPeriodError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_workingPeriodValid = true;
                dummy.workingPeriodError = props.appLanguage.backoffice.manageMines.add.mineProductions.workingPeriodAccept;
            }

            let upd_productionDestinationValid = false;
            if (staticValue.productionDestination === '') {
                upd_productionDestinationValid = false;
                dummy.productionDestinationError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_productionDestinationValid = true;
                dummy.productionDestinationError = props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestinationAccept;
            }

            let upd_productionDestination2Valid = false;
            if (staticValue.productionDestination2 === '') {
                upd_productionDestination2Valid = false;
                dummy.productionDestination2Error = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_productionDestination2Valid = true;
                dummy.productionDestination2Error = props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestination2Accept;
            }

            fieldValidationErrors.push(
                {
                    ...dummy
                }
            )

            copyMineProductions.push(
                {
                    faciCodeValid: upd_faciCodeValid, productValid: upd_productValid, mainOresProducedValid: upd_mainOresProducedValid, workingPeriodValid: upd_workingPeriodValid, productionDestinationValid: upd_productionDestinationValid,
                    productionDestination2Valid: upd_productionDestination2Valid

                }
            )

        }


        setFormError([...fieldValidationErrors])
        setFormValid([...copyMineProductions])

        //eslint-disable-next-line
    }, [formInput, formFocus])



    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, indexMineProduction: any) => {
        const input = e.currentTarget;
        const formId = input.id;
        let copyMineProductions = [...formFocus]

        switch (formId) {
            case FORMFACICODEID + indexMineProduction: {
                copyMineProductions[indexMineProduction].faciCodeFocus = true
                setFormFocus([...copyMineProductions])
                break;
            }
            case FORMPRODUCTID + indexMineProduction: {
                copyMineProductions[indexMineProduction].productFocus = true
                setFormFocus([...copyMineProductions])
                break;
            }
            case FORMMAINORESPRODUCEDID + indexMineProduction: {
                copyMineProductions[indexMineProduction].mainOresProducedFocus = true
                setFormFocus([...copyMineProductions])
                break;
            }
            case FORMWORKINGPERIODID + indexMineProduction: {
                copyMineProductions[indexMineProduction].workingPeriodFocus = true
                setFormFocus([...copyMineProductions])
                break;
            }
            case FORMPRODUCTIONDESTINATIONID + indexMineProduction: {
                copyMineProductions[indexMineProduction].productionDestinationFocus = true
                setFormFocus([...copyMineProductions])
                break;
            }
            case FORMPRODUCTIONDESTINATION2ID + indexMineProduction: {
                copyMineProductions[indexMineProduction].productionDestination2Focus = true
                setFormFocus([...copyMineProductions])
                break;
            }

            default: {
                break;
            }
        }//switch end
    }


    const validatorFeedback = (formId: any, indexMineProduction: any) => {

        switch (formId + indexMineProduction) {
            /*INVENTORY*/
            case FORMFACICODEID + indexMineProduction: {
                return formFocus[indexMineProduction].faciCodeFocus && !formValid[indexMineProduction].faciCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].faciCodeError}</span>
                    : formValid[indexMineProduction].faciCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.mineProductions.faciCodeAccept}</span>
                        : null
            }


            case FORMPRODUCTID + indexMineProduction: {
                return formFocus[indexMineProduction].productFocus && !formValid[indexMineProduction].productValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].productError}</span>
                    : formValid[indexMineProduction].productValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexMineProduction].productError}</span>
                        : null
            }
            case FORMMAINORESPRODUCEDID + indexMineProduction: {
                return formFocus[indexMineProduction].mainOresProducedFocus && !formValid[indexMineProduction].mainOresProducedValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].mainOresProducedError}</span>
                    : formValid[indexMineProduction].mainOresProducedValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexMineProduction].mainOresProducedError}</span>
                        : null
            }
            case FORMWORKINGPERIODID + indexMineProduction: {
                return formFocus[indexMineProduction].workingPeriodFocus && !formValid[indexMineProduction].workingPeriodValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].workingPeriodError}</span>
                    : formValid[indexMineProduction].workingPeriodValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexMineProduction].workingPeriodError}</span>
                        : null
            }
            case FORMPRODUCTIONDESTINATIONID + indexMineProduction: {
                return formFocus[indexMineProduction].productionDestinationFocus && !formValid[indexMineProduction].productionDestinationValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].productionDestinationError}</span>
                    : formValid[indexMineProduction].productionDestinationValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexMineProduction].productionDestinationError}</span>
                        : null
            }

            case FORMPRODUCTIONDESTINATION2ID + indexMineProduction: {
                return formFocus[indexMineProduction].productionDestination2Focus && !formValid[indexMineProduction].productionDestination2Valid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexMineProduction].productionDestination2Error}</span>
                    : formValid[indexMineProduction].productionDestination2Valid ?
                        <span className="input-valid-feedback-kekax">{formError[indexMineProduction].productionDestination2Error}</span>
                        : null
            }


            default: {
                break;
            }
        }//switch end
    }


    const addNewMineProduction = () => {

        const copyFormInput: IFormInputMineProductions[] = [...formInput]
        copyFormInput.push(
            {
                id:'',
                faciCode: '', product: '', mainOresProduced: '', workingPeriod: '', productionDestination: '',
                productionDestination2: '', status: 1
            }
        )

        const copyFormError: IFormErrorMineProductions[] = [...formError]
        copyFormError.push(
            {
                faciCodeError: '', productError: '', mainOresProducedError: '', workingPeriodError: '', productionDestinationError: '',
                productionDestination2Error: ''
            }
        )

        const copyFormValid: IFormValidMineProductions[] = [...formValid]
        copyFormValid.push(
            {
                faciCodeValid: false, productValid: false, mainOresProducedValid: false, workingPeriodValid: false, productionDestinationValid: false,
                productionDestination2Valid: false
            }
        )

        const copyFormFocus: IFormFocusMineProductions[] = [...formFocus]
        copyFormFocus.push(
            {
                faciCodeFocus: false, productFocus: false, mainOresProducedFocus: false, workingPeriodFocus: false, productionDestinationFocus: false,
                productionDestination2Focus: false
            }
        )

        setFormError([...copyFormError]);
        setFormInput([...copyFormInput]);
        setFormValid([...copyFormValid]);
        setFormFocus([...copyFormFocus]);
        setOpenMineProduction(formInput.length)
        setIDidSomething(true)

    }


    const removeMineProduction = (index: number) => {

        const copyFormInput: IFormInputMineProductions[] = [...formInput]
        if (index === openMineProduction) {
            setOpenMineProduction(-1)
        }
        if (copyFormInput[index].status === 0) {
            copyFormInput[index].status = -1
            setFormInput([...copyFormInput]);


        } else if (copyFormInput[index].status === 1) {
            const copyFormError: IFormErrorMineProductions[] = [...formError]
            const copyFormValid: IFormValidMineProductions[] = [...formValid]
            const copyFormFocus: IFormFocusMineProductions[] = [...formFocus]

            let newFormError: IFormErrorMineProductions[] = []
            let newFormValid: IFormValidMineProductions[] = []
            let newFormFocus: IFormFocusMineProductions[] = []
            let newFormInput: IFormInputMineProductions[] = []
            for (let i = 0; i < copyFormInput.length; i++) {
                if (i !== index) {
                    newFormInput.push(
                        copyFormInput[i]
                    )
                    newFormError.push(
                        copyFormError[i]
                    )
                    newFormValid.push(
                        copyFormValid[i]
                    )
                    newFormFocus.push(
                        copyFormFocus[i]
                    )

                }
                if (i === copyFormInput.length - 1) {
                    setFormInput([...newFormInput]);
                    setFormError([...newFormError]);
                    setFormValid([...newFormValid]);
                    setFormFocus([...newFormFocus])
                }
            }

        }
        setIDidSomething(true)

    }

    const clickOnMineProductionHandler = (index: number) => {
        setOpenMineProduction((current: number) => current === index ? -1 : index)
    }



    return (
        <div className="wrapper-edit-mine-mineProductions">
            <div className="edit-mine-mineProductions-menu">
                <button type="button" className="edit-new-mineProduction" onClick={addNewMineProduction}><span>{props.appLanguage.backoffice.manageMines.add.mineProductions.addNewMineProduction}</span></button>
            </div>
            {
                formInput.map((mineProduction: IFormInputMineProductions, index: number) => (
                    (mineProduction.status === 0 || mineProduction.status === 1) ?
                        <div className="container-mineProduction" key={"mineProduction-" + index}>

                            <div className="container-mineProduction-icon" >
                                <div className="mineProduction-icon-text" onClick={() => clickOnMineProductionHandler(index)}>

                                    <span>
                                        {
                                            openMineProduction === index ?
                                                props.appLanguage.backoffice.manageMines.add.mineProductions.minimizeMineProduction
                                                :
                                                props.appLanguage.backoffice.manageMines.add.mineProductions.expandMineProduction

                                        }
                                    </span>
                                </div>

                                <div className="mineProduction-icon-options">

                                    <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.mineProductions.deleteMineProduction} direction="top">
                                        <div className="remove-mineProduction" onClick={() => removeMineProduction(index)}>
                                            <div className="icon"></div>
                                        </div>
                                    </TooltipKekax>

                                </div>
                            </div>

                            {
                                openMineProduction === index &&

                                <div className="container-mineProduction-form">
                                    <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.mineProductions.firstTitle}</span></div>

                                    <div className="main-row">
                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.faciCode} *
                                            </label>
                                            <select className="input-normal-text kekax-input-box"
                                                id={FORMFACICODEID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.faciCode || ""}

                                            >

                                                <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.mineProductions.faciCodeph}</option>
                                                {
                                                    formFacilities.map((fac: string, index: number) => (
                                                        <option className="ayy" value={fac} key={"facCodeMineProd" + fac + index}>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facility + " " + fac}</option>
                                                    ))
                                                }

                                            </select>
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMFACICODEID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-row">

                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.product}
                                            </label>
                                            <textarea className="input-area-text kekax-input-box"
                                                placeholder={props.appLanguage.backoffice.manageMines.add.mineProductions.productph}
                                                id={FORMPRODUCTID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.product || ""}

                                            />
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMPRODUCTID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-row">

                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.mainOresProduced}
                                            </label>
                                            <textarea className="input-area-text kekax-input-box"
                                                placeholder={props.appLanguage.backoffice.manageMines.add.mineProductions.mainOresProducedph}
                                                id={FORMMAINORESPRODUCEDID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.mainOresProduced || ""}

                                            />
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMMAINORESPRODUCEDID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-row">
                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.workingPeriod}

                                            </label>
                                            <textarea className="input-area-text kekax-input-box"
                                                placeholder={props.appLanguage.backoffice.manageMines.add.mineProductions.workingPeriodph}
                                                id={FORMWORKINGPERIODID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.workingPeriod || ""}

                                            />
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMWORKINGPERIODID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>



                                    <div className="main-row">
                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestination}

                                            </label>
                                            <textarea className="input-area-text kekax-input-box"
                                                placeholder={props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestinationph}
                                                id={FORMPRODUCTIONDESTINATIONID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.productionDestination || ""}

                                            />
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMPRODUCTIONDESTINATIONID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-row">
                                        <div className="input-normal">
                                            <label className="label">
                                                {props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestination2}
                                            </label>
                                            <textarea className="input-area-text kekax-input-box"
                                                placeholder={props.appLanguage.backoffice.manageMines.add.mineProductions.productionDestination2ph}
                                                id={FORMPRODUCTIONDESTINATION2ID + index}
                                                onChange={(event: any) => changeInputHandler(event, index)}
                                                onFocus={(event: any) => validateFocusFields(event, index)}
                                                value={mineProduction.productionDestination2 || ""}

                                            />
                                            <div className="feedback-block">
                                                {
                                                    validatorFeedback(FORMPRODUCTIONDESTINATION2ID, index)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div> : null
                ))
            }

        </div >

    )
}


export default connect(mineStateToProps)(MineProductionEdit);