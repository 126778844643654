import React, { useEffect, useState } from "react";
import { getBookletUrl, httpGetListBooklets } from '../../services/requestService';
import './Documents.css'
import { connect } from "react-redux";
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../store/storeApp';
import Icon from '../../assets/icons/pickicon.png';
import TooltipKekax from "../kekax/TooltipKekax";

interface Doc {
    bookletName: string
    bookletId: string
}


interface DocToMap {
    bookletName: string
    bookletId: string
    content: string
}

export interface IBooklet {
    bookletId: string,
    bookletName: string,
    views: number
    path: string,
    order: number
}

//let docListToMap: { bookletId: string; bookletName: string; content: string; }[];


interface IProps extends StoreProps { }


export const Documents: React.FC<IProps> = (props) => {



    const [allDocuments, setAllDocuments] = useState<DocToMap[]>([]);
    const [totalDocuments, setTotalDocuments] = useState<number>(0);
    const [actualPage, setActualPage] = useState<number>(0);
    const [reachedMaxPage, setReachedMaxPage] = useState<boolean>(false);

    const limitPerPage: number = 12


    useEffect(() => {
        httpGetListBooklets('?limit=' + limitPerPage)
            .then(
                res => {
                    //SETTING TOTAL DOCUMENTS
                    const totalDocs = res.headers.total
                    if (totalDocs !== undefined) {
                        setTotalDocuments(parseInt(totalDocs, 10));
                        /*
                        let pages = Math.floor(totalDocs / limitPerPage);
                        const extra = totalDocs % limitPerPage;
                
                        if (extra !== 0 || totalDocs === 0) {
                            pages += 1
                        }
                        */
                    } else {
                        setTotalDocuments(parseInt('1', 10));
                    }


                    const DocListRes: Doc[] = res.data;
                    const DocList: DocToMap[] = [];

                    for (let i = 0; i < DocListRes.length; i++) {
                        DocList.push({
                            bookletId: DocListRes[i].bookletId,
                            bookletName: DocListRes[i].bookletName,
                            content: getBookletUrl.replace('{booklet:id}', DocListRes[i].bookletId)
                        })
                    }
                    setAllDocuments(DocList)
                })
            .catch(
                err => {
                }
            )
    }, [])

    const handleShowMore = () => {

        const thisPage = actualPage + 1
        setActualPage(thisPage)
        httpGetListBooklets('?limit=' + limitPerPage + '&page=' + thisPage)
            .then(
                res => {
                    const DocListRes: Doc[] = res.data;
                    const DocList: DocToMap[] = [];

                    for (let i = 0; i < DocListRes.length; i++) {
                        DocList.push({
                            bookletId: DocListRes[i].bookletId,
                            bookletName: DocListRes[i].bookletName,
                            content: getBookletUrl.replace('{booklet:id}', DocListRes[i].bookletId)
                        })
                    }
                    setAllDocuments([...allDocuments, ...DocList])

                })
            .catch(
                err => {
                }
            )

    }

    useEffect(() => {
        console.log(allDocuments.length)
        console.log(totalDocuments)

        if (allDocuments.length !== 0 && allDocuments.length === totalDocuments && totalDocuments !== 0) {
            setReachedMaxPage(true)
        }
    }, [allDocuments])


    return (

        <div className="wrapper-documents">
            <div className="content-all">
                <div className="container-header">
                    <div className="content">
                        <span>{props.appLanguage.documents.header[0]}</span>
                    </div>
                </div>
                <div className="container-body">
                    {
                        allDocuments.length > 0 ?

                            <React.Fragment>
                                {
                                    (allDocuments.map((file: any, index: number) =>
                                        <div key={index} className="documents-object">

                                            <div className="object-flex">
                                                <div className="document-object-img">
                                                    <img src={Icon} alt={'documentIcon' + index} />
                                                </div>
                                                <div className="document-object-name">
                                                    <span>{file.bookletName}</span>
                                                </div>
                                            </div>
                                            <div className="document-object-menu">
                                                <a className="option-show" href={file.content} target="_blank" rel="noopener noreferrer">
                                                    <TooltipKekax content={props.appLanguage.documents.optShow} direction="bottom">
                                                        <div className="option-show-icon">
                                                        </div>
                                                    </TooltipKekax>
                                                </a>
                                                <a className="option-download" href={file.content} download={file.bookletName}>
                                                    <TooltipKekax content={props.appLanguage.documents.optDownload} direction="bottom">
                                                        <div className='option-download-icon' >
                                                        </div>
                                                    </TooltipKekax>
                                                </a>
                                            </div>


                                        </div>

                                    ))
                                }
                                {
                                    !reachedMaxPage ?
                                        <div className="show-more">
                                            <button className="button-show" onClick={handleShowMore}>{props.appLanguage.documents.showmore}</button>
                                        </div>
                                        : null
                                }

                            </React.Fragment>

                            :
                            <span className="no-content">{props.appLanguage.msg_feedback.no_content}</span>
                    }


                </div>
            </div>

        </div>);

}

export default connect(mapStateToProps)(Documents);
