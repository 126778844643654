import React, { useEffect, useState, useRef } from 'react'
import { mapStateLanguageToProps as mineStateToProps, StoreLanguageProps as StoreProps } from '../../../../../store/storeApp';
import { connect } from 'react-redux';
import './FacilitiesInfoEdit.css';
import GeneralServices from "../../../../../services/generalService.json"
import TooltipKekax from '../../../../kekax/TooltipKekax';
import { IFormInputAuxCategories } from '../../auxCategories/addAuxCategories/AddAuxCategories';
import { IFormInputAuxMineTypes } from '../../auxMineTypes/addAuxMineTypes/AddAuxMineTypes';
import { IFormInputAuxSituation } from '../../auxSituation/addAuxSituation/AddAuxSituation';
import { IFormInputAuxFacilityType } from '../../auxFacilityType/addAuxFacilityType/AddAuxFacilityType';


interface IProps extends StoreProps {
    formInput: any
    formError: any
    formValid: any
    formFocus: any
    injectData: IInjectData
    formValidation: any

}


export interface IFormErrorFacilitiesInfo {
    facilityCodeError: string
    facilityTypeError: string
    facilityNameError: string
    facilityOtherNameError: string
    facilityDescriptionError: string
    mineCategoryError: string
    mineTypeError: string
    usesMineError: string
    situationError: string
    openingYearError: string
    visitRouteAvailableError: string
    timeNeededForTheVisitError: string
    routeDescriptionError: string
    guidedVisitError: string

    freeTourAvailableError: string
    adventureError: string
    adaptedVisitAvailableError: string
    brochuresPrintedInfoError: string
    audioVisualResourcesError: string
    workshopsForYoungAdultsError: string
    explanationsForChildrenError: string
    arVrResourcesError: string
    workshopsForDisabledError: string
    explanationsForDisabledError: string
    workshopsForChildrenError: string
    workshopsForAdventureError: string
    languagesError: string
    educationalVisitAvailableInEnglishError: string


}
export interface IFormInputFacilitiesInfo {
    id:string
    facilityCode: string
    facilityType: string
    facilityName: string
    facilityOtherName: string
    facilityDescription: string
    mineCategory: string
    mineType: string
    usesMine: string
    situation: string
    openingYear: string
    visitRouteAvailable: string
    timeNeededForTheVisit: string
    routeDescription: string
    guidedVisit: string

    freeTourAvailable: string
    adventure: string
    adaptedVisitAvailable: string
    brochuresPrintedInfo: string
    audioVisualResources: string
    workshopsForYoungAdults: string
    explanationsForChildren: string
    arVrResources: string
    workshopsForDisabled: string
    explanationsForDisabled: string
    workshopsForChildren: string
    workshopsForAdventure: string
    languages: string
    educationalVisitAvailableInEnglish: string

    status: number

}

export interface IFormValidFacilitiesInfo {
    facilityCodeValid: boolean
    facilityTypeValid: boolean
    facilityNameValid: boolean
    facilityOtherNameValid: boolean
    facilityDescriptionValid: boolean
    mineCategoryValid: boolean
    mineTypeValid: boolean
    usesMineValid: boolean
    situationValid: boolean
    openingYearValid: boolean
    visitRouteAvailableValid: boolean
    timeNeededForTheVisitValid: boolean
    routeDescriptionValid: boolean
    guidedVisitValid: boolean

    freeTourAvailableValid: boolean
    adventureValid: boolean
    adaptedVisitAvailableValid: boolean
    brochuresPrintedInfoValid: boolean
    audioVisualResourcesValid: boolean
    workshopsForYoungAdultsValid: boolean
    explanationsForChildrenValid: boolean
    arVrResourcesValid: boolean
    workshopsForDisabledValid: boolean
    explanationsForDisabledValid: boolean
    workshopsForChildrenValid: boolean
    workshopsForAdventureValid: boolean
    languagesValid: boolean
    educationalVisitAvailableInEnglishValid: boolean
}

export interface IFormFocusFacilitiesInfo {
    facilityCodeFocus: boolean
    facilityTypeFocus: boolean
    facilityNameFocus: boolean
    facilityOtherNameFocus: boolean
    facilityDescriptionFocus: boolean
    mineCategoryFocus: boolean
    mineTypeFocus: boolean
    usesMineFocus: boolean
    situationFocus: boolean
    openingYearFocus: boolean
    visitRouteAvailableFocus: boolean
    timeNeededForTheVisitFocus: boolean
    routeDescriptionFocus: boolean
    guidedVisitFocus: boolean

    freeTourAvailableFocus: boolean
    adventureFocus: boolean
    adaptedVisitAvailableFocus: boolean
    brochuresPrintedInfoFocus: boolean
    audioVisualResourcesFocus: boolean
    workshopsForYoungAdultsFocus: boolean
    explanationsForChildrenFocus: boolean
    arVrResourcesFocus: boolean
    workshopsForDisabledFocus: boolean
    explanationsForDisabledFocus: boolean
    workshopsForChildrenFocus: boolean
    workshopsForAdventureFocus: boolean
    languagesFocus: boolean
    educationalVisitAvailableInEnglishFocus: boolean
}

interface IInjectData {
    injectInput: IFormInputFacilitiesInfo[]
    injectValid: IFormValidFacilitiesInfo[]
    injectFocus: IFormFocusFacilitiesInfo[]
    injectError: IFormErrorFacilitiesInfo[]
    injectAuxCountry: IFormInputAuxFacilityType[]
    injectAuxMineType: IFormInputAuxMineTypes[]
    injectAuxSituation: IFormInputAuxSituation[]
    injectAuxCategories: IFormInputAuxCategories[]
    injectAuxFaciltyType: IFormInputAuxFacilityType[]

}





//Facilities information
const FORMMFACILITYCODEID: string = 'facilityCode'
const FORMFACILITYTYPEID: string = 'facilityType'
const FORMFACILITYNAMEID: string = 'facilityName'
const FORMFACILITYOTHERNAMEID: string = 'facilityOtherName'
const FORMFACILITYDESCRIPTIONID: string = 'facilityDescription'
const FORMMINECATEGORYID: string = 'mineCategory'
const FORMMINETYPEID: string = 'mineType'
const FORMUSESMINEID: string = 'usesMine'
const FORMSITUATIONID: string = 'situation'

const FORMOPENINGYEARID: string = 'openingYear'
const FORMVISITROUTEAVAILABLEID: string = 'visitRouteAvailable'
const FORMTIMENEEDEDFORTHEVISITID: string = 'timeNeededForTheVisit'
const FORMROUTEDESCRIPTIONID: string = 'routeDescription'
const FORMGUIDEDVISITID: string = 'guidedVisit'

const FORMFREETOURAVAILABLEID: string = 'freeTourAvailable'
const FORMADVENTUREID: string = 'adventure'
const FORMADAPTEDVISITAVAILABLEID: string = 'adaptedVisitAvailable'
const FORMBROCHURESPRINTEDINFOID: string = 'brochuresPrintedInfo'
const FORMAUDIOVISUALRESOURCESID: string = 'audioVisualResources'
const FORMWORKSHOPSFORYOUNGADULTSID: string = 'workshopsForYoungAdults'
const FORMEXPLANATIONSFORCHILDRENID: string = 'explanationsForChildren'
const FORMARVRRESOURCESID: string = 'arVrResources'
const FORMWORKSHOPSFORDISABLEDID: string = 'workshopsForDisabled'
const FORMEXPLANATIONSFORDISABLEDID: string = 'explanationsForDisabled'
const FORMWORKSHOPSFORCHILDRENID: string = 'workshopsForChildren'
const FORMWORKSHOPSFORADVENTUREID: string = 'workshopsForAdventure'
const FORMLANGUAGESID: string = 'languages'
const FORMEDUCATIONALVISITAVAILABLEINENGLISHID: string = 'educationalVisitAvailableInEnglish'


export const FacilitiesInfoEdit: React.FC<IProps> = (props) => {
    const [formSubmitValid, setFormSubmitValid] = useState<boolean>(true);

    const [formInput, setFormInput] = useState<IFormInputFacilitiesInfo[]>([]);
    const formInputRef = useRef<any>(null)

    const [formError, setFormError] = useState<IFormErrorFacilitiesInfo[]>([]);
    const formErrorRef = useRef<any>(null)

    const [formValid, setFormValid] = useState<IFormValidFacilitiesInfo[]>([]);
    const formValidRef = useRef<any>(null)

    const [formFocus, setFormFocus] = useState<IFormFocusFacilitiesInfo[]>([]);
    const formFocusRef = useRef<any>(null)


    const [openFacility, setOpenFacility] = useState<number>(-1)



    useEffect(() => {
        if (props.injectData.injectValid.length > 0) {
            setFormValid(props.injectData.injectValid)
        }
        if (props.injectData.injectError.length > 0) {
            setFormError(props.injectData.injectError)
        }
        if (props.injectData.injectFocus.length > 0) {
            setFormFocus(props.injectData.injectFocus)
        }
        if (props.injectData.injectInput.length > 0) {
            setFormInput(props.injectData.injectInput)
        }
        return () => {
            props.formError(formErrorRef.current)
            props.formFocus(formFocusRef.current)
            props.formInput(formInputRef.current)
            props.formValid(formValidRef.current)
            setOpenFacility(-1)
        }
    }, [])

    useEffect(() => {
        props.formValidation(formSubmitValid)
    }, [formSubmitValid])

    useEffect(() => {
        formInputRef.current = formInput
    }, [formInput])
    useEffect(() => {
        formValidRef.current = formValid
    }, [formValid])
    useEffect(() => {
        formFocusRef.current = formFocus
    }, [formFocus])
    useEffect(() => {
        formErrorRef.current = formError
    }, [formError])



    /*CHANGE INPUT*/
    const changeInputHandler = (e: any, indexFacility: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;
        let copyFacilities = [...formInput]

        switch (formId) {

            case FORMMFACILITYCODEID + indexFacility: {
                copyFacilities[indexFacility].facilityCode = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMFACILITYTYPEID + indexFacility: {
                copyFacilities[indexFacility].facilityType = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMFACILITYNAMEID + indexFacility: {
                copyFacilities[indexFacility].facilityName = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMFACILITYOTHERNAMEID + indexFacility: {
                copyFacilities[indexFacility].facilityOtherName = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMFACILITYDESCRIPTIONID + indexFacility: {
                copyFacilities[indexFacility].facilityDescription = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMMINECATEGORYID + indexFacility: {
                copyFacilities[indexFacility].mineCategory = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMMINETYPEID + indexFacility: {
                copyFacilities[indexFacility].mineType = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMUSESMINEID + indexFacility: {
                copyFacilities[indexFacility].usesMine = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMSITUATIONID + indexFacility: {
                copyFacilities[indexFacility].situation = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMOPENINGYEARID + indexFacility: {
                copyFacilities[indexFacility].openingYear = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMVISITROUTEAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].visitRouteAvailable = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMTIMENEEDEDFORTHEVISITID + indexFacility: {
                copyFacilities[indexFacility].timeNeededForTheVisit = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMROUTEDESCRIPTIONID + indexFacility: {
                copyFacilities[indexFacility].routeDescription = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMGUIDEDVISITID + indexFacility: {
                copyFacilities[indexFacility].guidedVisit = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMFREETOURAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].freeTourAvailable = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMADVENTUREID + indexFacility: {
                copyFacilities[indexFacility].adventure = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMADAPTEDVISITAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].adaptedVisitAvailable = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMBROCHURESPRINTEDINFOID + indexFacility: {
                copyFacilities[indexFacility].brochuresPrintedInfo = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMAUDIOVISUALRESOURCESID + indexFacility: {
                copyFacilities[indexFacility].audioVisualResources = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORYOUNGADULTSID + indexFacility: {
                copyFacilities[indexFacility].workshopsForYoungAdults = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMEXPLANATIONSFORCHILDRENID + indexFacility: {
                copyFacilities[indexFacility].explanationsForChildren = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMARVRRESOURCESID + indexFacility: {
                copyFacilities[indexFacility].arVrResources = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORDISABLEDID + indexFacility: {
                copyFacilities[indexFacility].workshopsForDisabled = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMEXPLANATIONSFORDISABLEDID + indexFacility: {
                copyFacilities[indexFacility].explanationsForDisabled = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORCHILDRENID + indexFacility: {
                copyFacilities[indexFacility].workshopsForChildren = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORADVENTUREID + indexFacility: {
                copyFacilities[indexFacility].workshopsForAdventure = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMLANGUAGESID + indexFacility: {
                copyFacilities[indexFacility].languages = value
                setFormInput([...copyFacilities])
                break;
            }
            case FORMEDUCATIONALVISITAVAILABLEINENGLISHID + indexFacility: {
                copyFacilities[indexFacility].educationalVisitAvailableInEnglish = value
                setFormInput([...copyFacilities])
                break;
            }

            default: {
                break;
            }
        }//switch end

    }


    useEffect(() => {
        let fieldValidationErrors: IFormErrorFacilitiesInfo[] = [];
        let copyFacilities: IFormValidFacilitiesInfo[] = [];


        for (let i = 0; i < formFocus.length; i++) {

            let dummy: IFormErrorFacilitiesInfo = {
                facilityCodeError: '', facilityTypeError: '', facilityNameError: '', facilityOtherNameError: '', facilityDescriptionError: '',
                mineCategoryError: '', mineTypeError: '', usesMineError: '', situationError: '', openingYearError: '', visitRouteAvailableError: '', timeNeededForTheVisitError: '', routeDescriptionError: ''
                , guidedVisitError: '', freeTourAvailableError: '', adventureError: '', adaptedVisitAvailableError: '', brochuresPrintedInfoError: '', audioVisualResourcesError: '', workshopsForYoungAdultsError: ''
                , explanationsForChildrenError: '', arVrResourcesError: '', workshopsForDisabledError: '', explanationsForDisabledError: '', workshopsForChildrenError: '', workshopsForAdventureError: ''
                , languagesError: '', educationalVisitAvailableInEnglishError: ''
            }

            const staticValue = { ...formInput[i] }

            let upd_facilityCodeValid = false;
            if (staticValue.facilityCode === '') {
                upd_facilityCodeValid = false;
                dummy.facilityCodeError = props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_facilityCodeValid = true;
                dummy.facilityCodeError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityCodeAccept;
            }

            let upd_facilityTypeValid = false;
            if (staticValue.facilityType === '') {
                upd_facilityTypeValid = false;
                dummy.facilityTypeError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_facilityTypeValid = true;
                dummy.facilityTypeError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityTypeAccept;
            }

            let upd_facilityNameValid = false;
            if (staticValue.facilityName === '') {
                upd_facilityNameValid = false;
                dummy.facilityNameError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_facilityNameValid = true;
                dummy.facilityNameError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityNameAccept;
            }

            let upd_facilityOtherNameValid = false;
            if (staticValue.facilityOtherName === '') {
                upd_facilityOtherNameValid = false;
                dummy.facilityOtherNameError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_facilityOtherNameValid = true;
                dummy.facilityOtherNameError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityOtherNameAccept;
            }

            let upd_facilityDescriptionValid = false;
            if (staticValue.facilityDescription === '') {
                upd_facilityDescriptionValid = false;
                dummy.facilityDescriptionError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_facilityDescriptionValid = true;
                dummy.facilityDescriptionError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityDescriptionAccept;
            }

            let upd_mineCategoryValid = false;
            if (staticValue.mineCategory === '') {
                upd_mineCategoryValid = false;
                dummy.mineCategoryError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_mineCategoryValid = true;
                dummy.mineCategoryError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineCategoryAccept;
            }

            let upd_mineTypeValid = false;
            if (staticValue.mineType === '') {
                upd_mineTypeValid = false;
                dummy.mineTypeError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_mineTypeValid = true;
                dummy.mineTypeError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineTypeAccept;
            }

            let upd_usesMineValid = false;
            if (staticValue.usesMine === '') {
                upd_usesMineValid = false;
                dummy.usesMineError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_usesMineValid = true;
                dummy.usesMineError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.usesMineAccept;
            }

            let upd_situationValid = false;
            if (staticValue.situation === '') {
                upd_situationValid = false;
                dummy.situationError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_situationValid = true;
                dummy.situationError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.situationAccept;
            }


            let upd_openingYearValid = false;
            if (staticValue.openingYear === '') {
                upd_openingYearValid = false;
                dummy.openingYearError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_openingYearValid = true;
                dummy.openingYearError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.openingYearAccept;
            }

            let upd_visitRouteAvailableValid = false;
            if (staticValue.visitRouteAvailable === '') {
                upd_visitRouteAvailableValid = false;
                dummy.visitRouteAvailableError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_visitRouteAvailableValid = true;
                dummy.visitRouteAvailableError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.visitRouteAvailableAccept;
            }

            let upd_timeNeededForTheVisitValid = false;
            if (staticValue.timeNeededForTheVisit === '') {
                upd_timeNeededForTheVisitValid = false;
                dummy.timeNeededForTheVisitError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_timeNeededForTheVisitValid = true;
                dummy.timeNeededForTheVisitError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.timeNeededForTheVisitAccept;
            }

            let upd_routeDescriptionValid = false;
            if (staticValue.routeDescription === '') {
                upd_routeDescriptionValid = false;
                dummy.routeDescriptionError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_routeDescriptionValid = true;
                dummy.routeDescriptionError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.routeDescriptionAccept;
            }

            let upd_guidedVisitValid = false;
            if (staticValue.guidedVisit === '') {
                upd_guidedVisitValid = false;
                dummy.guidedVisitError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_guidedVisitValid = true;
                dummy.guidedVisitError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.guidedVisitAccept;
            }

            let upd_freeTourAvailableValid = false;
            if (staticValue.freeTourAvailable === '') {
                upd_freeTourAvailableValid = false;
                dummy.freeTourAvailableError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_freeTourAvailableValid = true;
                dummy.freeTourAvailableError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.freeTourAvailableAccept;
            }

            let upd_adventureValid = false;
            if (staticValue.adventure === '') {
                upd_adventureValid = false;
                dummy.adventureError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_adventureValid = true;
                dummy.adventureError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adventureAccept;
            }

            let upd_adaptedVisitAvailableValid = false;
            if (staticValue.adaptedVisitAvailable === '') {
                upd_adaptedVisitAvailableValid = false;
                dummy.adaptedVisitAvailableError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_adaptedVisitAvailableValid = true;
                dummy.adaptedVisitAvailableError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adaptedVisitAvailableAccept;
            }

            let upd_brochuresPrintedInfoValid = false;
            if (staticValue.brochuresPrintedInfo === '') {
                upd_brochuresPrintedInfoValid = false;
                dummy.brochuresPrintedInfoError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_brochuresPrintedInfoValid = true;
                dummy.brochuresPrintedInfoError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.brochuresPrintedInfoAccept;
            }

            let upd_audioVisualResourcesValid = false;
            if (staticValue.audioVisualResources === '') {
                upd_audioVisualResourcesValid = false;
                dummy.audioVisualResourcesError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_audioVisualResourcesValid = true;
                dummy.audioVisualResourcesError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.audioVisualResourcesAccept;
            }

            let upd_workshopsForYoungAdultsValid = false;
            if (staticValue.workshopsForYoungAdults === '') {
                upd_workshopsForYoungAdultsValid = false;
                dummy.workshopsForYoungAdultsError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_workshopsForYoungAdultsValid = true;
                dummy.workshopsForYoungAdultsError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForYoungAdultsAccept;
            }

            let upd_explanationsForChildrenValid = false;
            if (staticValue.explanationsForChildren === '') {
                upd_explanationsForChildrenValid = false;
                dummy.explanationsForChildrenError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_explanationsForChildrenValid = true;
                dummy.explanationsForChildrenError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForChildrenAccept;
            }

            let upd_arVrResourcesValid = false;
            if (staticValue.arVrResources === '') {
                upd_arVrResourcesValid = false;
                dummy.arVrResourcesError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_arVrResourcesValid = true;
                dummy.arVrResourcesError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.arVrResourcesAccept;
            }

            let upd_workshopsForDisabledValid = false;
            if (staticValue.workshopsForDisabled === '') {
                upd_workshopsForDisabledValid = false;
                dummy.workshopsForDisabledError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_workshopsForDisabledValid = true;
                dummy.workshopsForDisabledError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForDisabledAccept;
            }

            let upd_explanationsForDisabledValid = false;
            if (staticValue.explanationsForDisabled === '') {
                upd_explanationsForDisabledValid = false;
                dummy.explanationsForDisabledError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_explanationsForDisabledValid = true;
                dummy.explanationsForDisabledError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForDisabledAccept;
            }

            let upd_workshopsForChildrenValid = false;
            if (staticValue.workshopsForChildren === '') {
                upd_workshopsForChildrenValid = false;
                dummy.workshopsForChildrenError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_workshopsForChildrenValid = true;
                dummy.workshopsForChildrenError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForChildrenAccept;
            }

            let upd_workshopsForAdventureValid = false;
            if (staticValue.workshopsForAdventure === '') {
                upd_workshopsForAdventureValid = false;
                dummy.workshopsForAdventureError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_workshopsForAdventureValid = true;
                dummy.workshopsForAdventureError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForAdventureAccept;
            }

            let upd_languagesValid = false;
            if (staticValue.languages === '') {
                upd_languagesValid = false;
                dummy.languagesError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_languagesValid = true;
                dummy.languagesError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.languagesAccept;
            }

            let upd_educationalVisitAvailableInEnglishValid = false;
            if (staticValue.educationalVisitAvailableInEnglish === '') {
                upd_educationalVisitAvailableInEnglishValid = false;
                dummy.educationalVisitAvailableInEnglishError = ""//props.appLanguage.backoffice.manageMines.add.requiredError;
            } else {
                upd_educationalVisitAvailableInEnglishValid = true;
                dummy.educationalVisitAvailableInEnglishError = props.appLanguage.backoffice.manageMines.add.facilitiesInfo.educationalVisitAvailableInEnglishAccept;
            }

            fieldValidationErrors.push(
                { ...dummy }
            )

            copyFacilities.push(
                {
                    facilityCodeValid: upd_facilityCodeValid, facilityTypeValid: upd_facilityTypeValid, facilityNameValid: upd_facilityNameValid, facilityOtherNameValid: upd_facilityOtherNameValid, facilityDescriptionValid: upd_facilityDescriptionValid,
                    mineCategoryValid: upd_mineCategoryValid, mineTypeValid: upd_mineTypeValid, usesMineValid: upd_usesMineValid, situationValid: upd_situationValid, openingYearValid: upd_openingYearValid, visitRouteAvailableValid: upd_visitRouteAvailableValid,
                    timeNeededForTheVisitValid: upd_timeNeededForTheVisitValid, routeDescriptionValid: upd_routeDescriptionValid, guidedVisitValid: upd_guidedVisitValid, freeTourAvailableValid: upd_freeTourAvailableValid,
                    adventureValid: upd_adventureValid, adaptedVisitAvailableValid: upd_adaptedVisitAvailableValid, brochuresPrintedInfoValid: upd_brochuresPrintedInfoValid, audioVisualResourcesValid: upd_audioVisualResourcesValid, workshopsForYoungAdultsValid: upd_workshopsForYoungAdultsValid,
                    explanationsForChildrenValid: upd_explanationsForChildrenValid, arVrResourcesValid: upd_arVrResourcesValid, workshopsForDisabledValid: upd_workshopsForDisabledValid, explanationsForDisabledValid: upd_explanationsForDisabledValid,
                    workshopsForChildrenValid: upd_workshopsForChildrenValid, workshopsForAdventureValid: upd_workshopsForAdventureValid, languagesValid: upd_languagesValid, educationalVisitAvailableInEnglishValid: upd_educationalVisitAvailableInEnglishValid

                }
            )

        }


        setFormError([...fieldValidationErrors])
        setFormValid([...copyFacilities])

    }, [formInput, formFocus])



    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any, indexFacility: any) => {
        const input = e.currentTarget;
        const formId = input.id;
        let copyFacilities = [...formFocus]

        switch (formId) {
            case FORMMFACILITYCODEID + indexFacility: {
                copyFacilities[indexFacility].facilityCodeFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMFACILITYTYPEID + indexFacility: {
                copyFacilities[indexFacility].facilityTypeFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMFACILITYNAMEID + indexFacility: {
                copyFacilities[indexFacility].facilityNameFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMFACILITYOTHERNAMEID + indexFacility: {
                copyFacilities[indexFacility].facilityOtherNameFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMFACILITYDESCRIPTIONID + indexFacility: {
                copyFacilities[indexFacility].facilityDescriptionFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMMINECATEGORYID + indexFacility: {
                copyFacilities[indexFacility].mineCategoryFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMMINETYPEID + indexFacility: {
                copyFacilities[indexFacility].mineTypeFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMUSESMINEID + indexFacility: {
                copyFacilities[indexFacility].usesMineFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMSITUATIONID + indexFacility: {
                copyFacilities[indexFacility].situationFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMOPENINGYEARID + indexFacility: {
                copyFacilities[indexFacility].openingYearFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMVISITROUTEAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].visitRouteAvailableFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMTIMENEEDEDFORTHEVISITID + indexFacility: {
                copyFacilities[indexFacility].timeNeededForTheVisitFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMROUTEDESCRIPTIONID + indexFacility: {
                copyFacilities[indexFacility].routeDescriptionFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMGUIDEDVISITID + indexFacility: {
                copyFacilities[indexFacility].guidedVisitFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMFREETOURAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].freeTourAvailableFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMADVENTUREID + indexFacility: {
                copyFacilities[indexFacility].adventureFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMADAPTEDVISITAVAILABLEID + indexFacility: {
                copyFacilities[indexFacility].adaptedVisitAvailableFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMBROCHURESPRINTEDINFOID + indexFacility: {
                copyFacilities[indexFacility].brochuresPrintedInfoFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMAUDIOVISUALRESOURCESID + indexFacility: {
                copyFacilities[indexFacility].audioVisualResourcesFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORYOUNGADULTSID + indexFacility: {
                copyFacilities[indexFacility].workshopsForYoungAdultsFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMEXPLANATIONSFORCHILDRENID + indexFacility: {
                copyFacilities[indexFacility].explanationsForChildrenFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMARVRRESOURCESID + indexFacility: {
                copyFacilities[indexFacility].arVrResourcesFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORDISABLEDID + indexFacility: {
                copyFacilities[indexFacility].workshopsForDisabledFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMEXPLANATIONSFORDISABLEDID + indexFacility: {
                copyFacilities[indexFacility].explanationsForDisabledFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORCHILDRENID + indexFacility: {
                copyFacilities[indexFacility].workshopsForChildrenFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMWORKSHOPSFORADVENTUREID + indexFacility: {
                copyFacilities[indexFacility].workshopsForAdventureFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMLANGUAGESID + indexFacility: {
                copyFacilities[indexFacility].languagesFocus = true
                setFormFocus([...copyFacilities])
                break;
            }
            case FORMEDUCATIONALVISITAVAILABLEINENGLISHID + indexFacility: {
                copyFacilities[indexFacility].educationalVisitAvailableInEnglishFocus = true
                setFormFocus([...copyFacilities])
                break;
            }


            default: {
                break;
            }
        }//switch end
    }


    const validatorFeedback = (formId: any, indexFacility: any) => {

        switch (formId + indexFacility) {
            /*INVENTORY*/
            case FORMMFACILITYCODEID + indexFacility: {
                return formFocus[indexFacility].facilityCodeFocus && !formValid[indexFacility].facilityCodeValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].facilityCodeError}</span>
                    : formValid[indexFacility].facilityCodeValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityCodeAccept}</span>
                        : null
            }



            case FORMFACILITYTYPEID + indexFacility: {
                return formFocus[indexFacility].facilityTypeFocus && !formValid[indexFacility].facilityTypeValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].facilityTypeError}</span>
                    : formValid[indexFacility].facilityTypeValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].facilityTypeError}</span>
                        : null
            }
            case FORMFACILITYNAMEID + indexFacility: {
                return formFocus[indexFacility].facilityNameFocus && !formValid[indexFacility].facilityNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].facilityNameError}</span>
                    : formValid[indexFacility].facilityNameValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].facilityNameError}</span>
                        : null
            }
            case FORMFACILITYOTHERNAMEID + indexFacility: {
                return formFocus[indexFacility].facilityOtherNameFocus && !formValid[indexFacility].facilityOtherNameValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].facilityOtherNameError}</span>
                    : formValid[indexFacility].facilityOtherNameValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].facilityOtherNameError}</span>
                        : null
            }
            case FORMFACILITYDESCRIPTIONID + indexFacility: {
                return formFocus[indexFacility].facilityDescriptionFocus && !formValid[indexFacility].facilityDescriptionValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].facilityDescriptionError}</span>
                    : formValid[indexFacility].facilityDescriptionValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].facilityDescriptionError}</span>
                        : null
            }

            case FORMMINECATEGORYID + indexFacility: {
                return formFocus[indexFacility].mineCategoryFocus && !formValid[indexFacility].mineCategoryValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].mineCategoryError}</span>
                    : formValid[indexFacility].mineCategoryValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].mineCategoryError}</span>
                        : null
            }
            case FORMMINETYPEID + indexFacility: {
                return formFocus[indexFacility].mineTypeFocus && !formValid[indexFacility].mineTypeValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].mineTypeError}</span>
                    : formValid[indexFacility].mineTypeValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].mineTypeError}</span>
                        : null
            }
            case FORMUSESMINEID + indexFacility: {
                return formFocus[indexFacility].usesMineFocus && !formValid[indexFacility].usesMineValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].usesMineError}</span>
                    : formValid[indexFacility].usesMineValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].usesMineError}</span>
                        : null
            }
            case FORMSITUATIONID + indexFacility: {
                return formFocus[indexFacility].situationFocus && !formValid[indexFacility].situationValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].situationError}</span>
                    : formValid[indexFacility].situationValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].situationError}</span>
                        : null
            }
            case FORMOPENINGYEARID + indexFacility: {
                return formFocus[indexFacility].openingYearFocus && !formValid[indexFacility].openingYearValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].openingYearError}</span>
                    : formValid[indexFacility].openingYearValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].openingYearError}</span>
                        : null
            }
            case FORMVISITROUTEAVAILABLEID + indexFacility: {
                return formFocus[indexFacility].visitRouteAvailableFocus && !formValid[indexFacility].visitRouteAvailableValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].visitRouteAvailableError}</span>
                    : formValid[indexFacility].visitRouteAvailableValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].visitRouteAvailableError}</span>
                        : null
            }
            case FORMTIMENEEDEDFORTHEVISITID + indexFacility: {
                return formFocus[indexFacility].timeNeededForTheVisitFocus && !formValid[indexFacility].timeNeededForTheVisitValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].timeNeededForTheVisitError}</span>
                    : formValid[indexFacility].timeNeededForTheVisitValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].timeNeededForTheVisitError}</span>
                        : null
            }
            case FORMROUTEDESCRIPTIONID + indexFacility: {
                return formFocus[indexFacility].routeDescriptionFocus && !formValid[indexFacility].routeDescriptionValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].routeDescriptionError}</span>
                    : formValid[indexFacility].routeDescriptionValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].routeDescriptionError}</span>
                        : null
            }
            case FORMGUIDEDVISITID + indexFacility: {
                return formFocus[indexFacility].guidedVisitFocus && !formValid[indexFacility].guidedVisitValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].guidedVisitError}</span>
                    : formValid[indexFacility].guidedVisitValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].guidedVisitError}</span>
                        : null
            }
            case FORMFREETOURAVAILABLEID + indexFacility: {
                return formFocus[indexFacility].freeTourAvailableFocus && !formValid[indexFacility].freeTourAvailableValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].freeTourAvailableError}</span>
                    : formValid[indexFacility].freeTourAvailableValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].freeTourAvailableError}</span>
                        : null
            }
            case FORMADVENTUREID + indexFacility: {
                return formFocus[indexFacility].adventureFocus && !formValid[indexFacility].adventureValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].adventureError}</span>
                    : formValid[indexFacility].adventureValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].adventureError}</span>
                        : null
            }
            case FORMADAPTEDVISITAVAILABLEID + indexFacility: {
                return formFocus[indexFacility].adaptedVisitAvailableFocus && !formValid[indexFacility].adaptedVisitAvailableValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].adaptedVisitAvailableError}</span>
                    : formValid[indexFacility].adaptedVisitAvailableValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].adaptedVisitAvailableError}</span>
                        : null
            }
            case FORMBROCHURESPRINTEDINFOID + indexFacility: {
                return formFocus[indexFacility].brochuresPrintedInfoFocus && !formValid[indexFacility].brochuresPrintedInfoValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].brochuresPrintedInfoError}</span>
                    : formValid[indexFacility].brochuresPrintedInfoValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].brochuresPrintedInfoError}</span>
                        : null
            }
            case FORMAUDIOVISUALRESOURCESID + indexFacility: {
                return formFocus[indexFacility].audioVisualResourcesFocus && !formValid[indexFacility].audioVisualResourcesValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].audioVisualResourcesError}</span>
                    : formValid[indexFacility].audioVisualResourcesValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].audioVisualResourcesError}</span>
                        : null
            }
            case FORMWORKSHOPSFORYOUNGADULTSID + indexFacility: {
                return formFocus[indexFacility].workshopsForYoungAdultsFocus && !formValid[indexFacility].workshopsForYoungAdultsValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].workshopsForYoungAdultsError}</span>
                    : formValid[indexFacility].workshopsForYoungAdultsValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].workshopsForYoungAdultsError}</span>
                        : null
            }
            case FORMEXPLANATIONSFORCHILDRENID + indexFacility: {
                return formFocus[indexFacility].explanationsForChildrenFocus && !formValid[indexFacility].explanationsForChildrenValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].explanationsForChildrenError}</span>
                    : formValid[indexFacility].explanationsForChildrenValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].explanationsForChildrenError}</span>
                        : null
            }
            case FORMARVRRESOURCESID + indexFacility: {
                return formFocus[indexFacility].arVrResourcesFocus && !formValid[indexFacility].arVrResourcesValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].arVrResourcesError}</span>
                    : formValid[indexFacility].arVrResourcesValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].arVrResourcesError}</span>
                        : null
            }
            case FORMWORKSHOPSFORDISABLEDID + indexFacility: {
                return formFocus[indexFacility].workshopsForDisabledFocus && !formValid[indexFacility].workshopsForDisabledValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].workshopsForDisabledError}</span>
                    : formValid[indexFacility].workshopsForDisabledValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].workshopsForDisabledError}</span>
                        : null
            }
            case FORMEXPLANATIONSFORDISABLEDID + indexFacility: {
                return formFocus[indexFacility].explanationsForDisabledFocus && !formValid[indexFacility].explanationsForDisabledValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].explanationsForDisabledError}</span>
                    : formValid[indexFacility].explanationsForDisabledValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].explanationsForDisabledError}</span>
                        : null
            }
            case FORMWORKSHOPSFORCHILDRENID + indexFacility: {
                return formFocus[indexFacility].workshopsForChildrenFocus && !formValid[indexFacility].workshopsForChildrenValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].workshopsForChildrenError}</span>
                    : formValid[indexFacility].workshopsForChildrenValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].workshopsForChildrenError}</span>
                        : null
            }
            case FORMWORKSHOPSFORADVENTUREID + indexFacility: {
                return formFocus[indexFacility].workshopsForAdventureFocus && !formValid[indexFacility].workshopsForAdventureValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].workshopsForAdventureError}</span>
                    : formValid[indexFacility].workshopsForAdventureValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].workshopsForAdventureError}</span>
                        : null
            }
            case FORMLANGUAGESID + indexFacility: {
                return formFocus[indexFacility].languagesFocus && !formValid[indexFacility].languagesValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].languagesError}</span>
                    : formValid[indexFacility].languagesValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].languagesError}</span>
                        : null
            }
            case FORMEDUCATIONALVISITAVAILABLEINENGLISHID + indexFacility: {
                return formFocus[indexFacility].educationalVisitAvailableInEnglishFocus && !formValid[indexFacility].educationalVisitAvailableInEnglishValid ?
                    <span className="input-invalid-feedback-kekax">{formError[indexFacility].educationalVisitAvailableInEnglishError}</span>
                    : formValid[indexFacility].educationalVisitAvailableInEnglishValid ?
                        <span className="input-valid-feedback-kekax">{formError[indexFacility].educationalVisitAvailableInEnglishError}</span>
                        : null
            }




            default: {
                break;
            }
        }//switch end
    }



    const addNewFacility = () => {

        const copyFormInput: IFormInputFacilitiesInfo[] = [...formInput]
        let newFaciCode = "1"
        if (copyFormInput.length > 0) {
            /*
            const lastFaciCode: number = parseInt(copyFormInput[copyFormInput.length - 1].facilityCode)
            newFaciCode = lastFaciCode + 1 + ""
            */
            newFaciCode =parseInt( getNextFacilityId()) +1 +"";
        }
        copyFormInput.push(
            {
                id:'',
                facilityCode: newFaciCode, facilityType: '', facilityName: '', facilityOtherName: '', facilityDescription: '',
                mineCategory: '', mineType: '', usesMine: '', situation: '', openingYear: '', visitRouteAvailable: '', timeNeededForTheVisit: '', routeDescription: ''
                , guidedVisit: '', freeTourAvailable: '', adventure: '', adaptedVisitAvailable: '', brochuresPrintedInfo: '', audioVisualResources: '', workshopsForYoungAdults: ''
                , explanationsForChildren: '', arVrResources: '', workshopsForDisabled: '', explanationsForDisabled: '', workshopsForChildren: '', workshopsForAdventure: ''
                , languages: '', educationalVisitAvailableInEnglish: '', status: 1
            }
        )

        const copyFormError: IFormErrorFacilitiesInfo[] = [...formError]
        copyFormError.push(
            {
                facilityCodeError: '', facilityTypeError: '', facilityNameError: '', facilityOtherNameError: '', facilityDescriptionError: '',
                mineCategoryError: '', mineTypeError: '', usesMineError: '', situationError: '', openingYearError: '', visitRouteAvailableError: '', timeNeededForTheVisitError: '', routeDescriptionError: ''
                , guidedVisitError: '', freeTourAvailableError: '', adventureError: '', adaptedVisitAvailableError: '', brochuresPrintedInfoError: '', audioVisualResourcesError: '', workshopsForYoungAdultsError: ''
                , explanationsForChildrenError: '', arVrResourcesError: '', workshopsForDisabledError: '', explanationsForDisabledError: '', workshopsForChildrenError: '', workshopsForAdventureError: ''
                , languagesError: '', educationalVisitAvailableInEnglishError: ''
            }
        )

        const copyFormValid: IFormValidFacilitiesInfo[] = [...formValid]
        copyFormValid.push(
            {
                facilityCodeValid: false, facilityTypeValid: false, facilityNameValid: false, facilityOtherNameValid: false, facilityDescriptionValid: false,
                mineCategoryValid: false, mineTypeValid: false, usesMineValid: false, situationValid: false, openingYearValid: false, visitRouteAvailableValid: false, timeNeededForTheVisitValid: false, routeDescriptionValid: false
                , guidedVisitValid: false, freeTourAvailableValid: false, adventureValid: false, adaptedVisitAvailableValid: false, brochuresPrintedInfoValid: false, audioVisualResourcesValid: false, workshopsForYoungAdultsValid: false
                , explanationsForChildrenValid: false, arVrResourcesValid: false, workshopsForDisabledValid: false, explanationsForDisabledValid: false, workshopsForChildrenValid: false, workshopsForAdventureValid: false
                , languagesValid: false, educationalVisitAvailableInEnglishValid: false
            }
        )

        const copyFormFocus: IFormFocusFacilitiesInfo[] = [...formFocus]
        copyFormFocus.push(
            {
                facilityCodeFocus: false, facilityTypeFocus: false, facilityNameFocus: false, facilityOtherNameFocus: false, facilityDescriptionFocus: false,
                mineCategoryFocus: false, mineTypeFocus: false, usesMineFocus: false, situationFocus: false, openingYearFocus: false, visitRouteAvailableFocus: false, timeNeededForTheVisitFocus: false, routeDescriptionFocus: false
                , guidedVisitFocus: false, freeTourAvailableFocus: false, adventureFocus: false, adaptedVisitAvailableFocus: false, brochuresPrintedInfoFocus: false, audioVisualResourcesFocus: false, workshopsForYoungAdultsFocus: false
                , explanationsForChildrenFocus: false, arVrResourcesFocus: false, workshopsForDisabledFocus: false, explanationsForDisabledFocus: false, workshopsForChildrenFocus: false, workshopsForAdventureFocus: false
                , languagesFocus: false, educationalVisitAvailableInEnglishFocus: false
            }
        )

        setFormError([...copyFormError]);
        setFormInput([...copyFormInput]);
        setFormValid([...copyFormValid]);
        setFormFocus([...copyFormFocus]);
        setOpenFacility(formInput.length)

    }


    const removeFacility = (index: number) => {
        /*
        const copyFormError: IFormErrorFacilitiesInfo[] = [...formError]
        const copyFormValid: IFormValidFacilitiesInfo[] = [...formValid]
        const copyFormFocus: IFormFocusFacilitiesInfo[] = [...formFocus]

        const newFormInput: IFormInputFacilitiesInfo[] = []

        */


        const copyFormInput: IFormInputFacilitiesInfo[] = [...formInput]
        if (index === openFacility) {
            setOpenFacility(-1)
        }
        if (copyFormInput[index].status === 0) {
            copyFormInput[index].status = -1
            setFormInput([...copyFormInput]);

        } else if (copyFormInput[index].status === 1) {
            const copyFormError: IFormErrorFacilitiesInfo[] = [...formError]
            const copyFormValid: IFormValidFacilitiesInfo[] = [...formValid]
            const copyFormFocus: IFormFocusFacilitiesInfo[] = [...formFocus]

            let newFormError: IFormErrorFacilitiesInfo[] = []
            let newFormValid: IFormValidFacilitiesInfo[] = []
            let newFormFocus: IFormFocusFacilitiesInfo[] = []
            let newFormInput: IFormInputFacilitiesInfo[] = []
            for (let i = 0; i < copyFormInput.length; i++) {
                if (i !== index) {
                    newFormInput.push(
                        copyFormInput[i]
                    )
                    newFormError.push(
                        copyFormError[i]
                    )
                    newFormValid.push(
                        copyFormValid[i]
                    )
                    newFormFocus.push(
                        copyFormFocus[i]
                    )

                }
                if(i === copyFormInput.length-1){
                    setFormInput([...newFormInput]);
                    setFormError([...newFormError]);
                    setFormValid([...newFormValid]);
                    setFormFocus([...newFormFocus])
                }
            }

        }

    }

    const clickOnFacilityHandler = (index: number) => {
        setOpenFacility((current: number) => current === index ? -1 : index)
    }

    const getNextFacilityId = () =>{
        const copyFormInput: IFormInputFacilitiesInfo[] = [...formInput]
        let found:boolean = false;
        for (let i = 0; i < copyFormInput.length; i++) {
            if (copyFormInput[i].status !== -1  ) {
                found = true
                break;
            }
        }
        if(found){
            return copyFormInput[copyFormInput.length - 1].facilityCode;
        }else{
            return "0";
        }

    }


    return (
        <div className="wrapper-edit-mine-facilitiesInfo">
            <div className="edit-mine-facilities-menu">
                <button type="button" className="edit-new-facility" onClick={addNewFacility}><span>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.addNewFacility}</span></button>
            </div>
            {
                formInput.map((facility: IFormInputFacilitiesInfo, index: number) => (
                    (facility.status === 0 || facility.status === 1) ?
                    <div className="container-facilityInfo" key={"facilityInfo-" + index}>

                        <div className="container-facilityInfo-icon" >
                            <div className="facilityInfo-icon-text" onClick={() => clickOnFacilityHandler(index)}>

                                <span>
                                    {
                                        openFacility === index ?
                                            props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facility + " " + facility.facilityCode + " - " + props.appLanguage.backoffice.manageMines.add.facilitiesInfo.minimizeFacility
                                            :
                                            props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facility + " " + facility.facilityCode + " - " + props.appLanguage.backoffice.manageMines.add.facilitiesInfo.expandFacility

                                    }
                                </span>
                            </div>

                            <div className="facilityInfo-icon-options">

                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.deleteFacility} direction="top">
                                    <div className="remove-facility" onClick={() => removeFacility(index)}>
                                        <div className="icon"></div>
                                    </div>
                                </TooltipKekax>

                            </div>
                        </div>

                        {
                            openFacility === index &&

                            <div className="container-facilityInfo-form">
                                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.firstTitle}</span></div>

                                <div className="main-row-triple">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityCode} *

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityCodeph}
                                            id={FORMMFACILITYCODEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={""}
                                            disabled

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMMFACILITYCODEID, index)
                                            }
                                        </div>
                                    </div>

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityType}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMFACILITYTYPEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.facilityType || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityTypeph}</option>
                                            {
                                                props.injectData.injectAuxFaciltyType.map((aux: IFormInputAuxFacilityType, index: number) => (
                                                    <option className="ayy" key={"auxFacilityType" + index} value={aux.facilityType}>{aux.facilityType}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMFACILITYTYPEID, index)
                                            }
                                        </div>
                                    </div>

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityName}

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityNameph}
                                            id={FORMFACILITYNAMEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.facilityName || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMFACILITYNAMEID, index)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="main-row-triple" style={{ justifyContent: "flex-end" }}>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityOtherName}

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityOtherNameph}
                                            id={FORMFACILITYOTHERNAMEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.facilityOtherName || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMFACILITYOTHERNAMEID, index)
                                            }
                                        </div>
                                    </div>
                                </div>



                                <div className="main-row">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityDescription}

                                        </label>
                                        <textarea className="input-area-text kekax-input-box"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.facilityDescriptionph}
                                            id={FORMFACILITYDESCRIPTIONID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.facilityDescription || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMFACILITYDESCRIPTIONID, index)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.secondTitle}</span></div>


                                <div className="main-row">
                                    <div className="input-medium">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineCategory}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMMINECATEGORYID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.mineCategory || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineCategoryph}</option>
                                            {
                                                props.injectData.injectAuxCategories.map((aux: IFormInputAuxCategories, index: number) => (
                                                    <option className="ayy" key={"auxCategory" + index} value={aux.category}>{aux.category + " | " + aux.desc}</option>
                                                ))
                                            }

                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMMINECATEGORYID, index)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="main-row">

                                    <div className="input-medium">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineType}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMMINETYPEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.mineType || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.mineTypeph}</option>

                                            {
                                                props.injectData.injectAuxMineType.map((aux: IFormInputAuxMineTypes, index: number) => (
                                                    <option className="ayy" key={"auxMineType" + index} value={aux.type}>{aux.type + " | " + aux.desc}</option>
                                                ))
                                            }

                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMMINETYPEID, index)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="main-row">

                                    <div className="input-medium">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.usesMine}

                                        </label>
                                        <textarea className="input-area-text kekax-input-box"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.usesMineph}
                                            id={FORMUSESMINEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.usesMine || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMUSESMINEID, index)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.thirdTitle}</span></div>

                                <div className="main-row-split">

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.situation}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMSITUATIONID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.situation || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.situationph}</option>
                                            {
                                                props.injectData.injectAuxSituation.map((aux: IFormInputAuxSituation, index: number) => (
                                                    <option className="ayy" key={"auxSituation" + index} value={aux.situation}>{aux.situation}</option>
                                                ))
                                            }

                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMSITUATIONID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.openingYear}

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.openingYearph}
                                            id={FORMOPENINGYEARID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.openingYear || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMOPENINGYEARID, index)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="main-row-split">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.visitRouteAvailable}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMVISITROUTEAVAILABLEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.visitRouteAvailable || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.visitRouteAvailableph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMVISITROUTEAVAILABLEID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.timeNeededForTheVisit}

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.timeNeededForTheVisitph}
                                            id={FORMTIMENEEDEDFORTHEVISITID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.timeNeededForTheVisit || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMTIMENEEDEDFORTHEVISITID, index)
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="main-row">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.routeDescription}

                                        </label>
                                        <textarea className="input-area-text kekax-input-box"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.routeDescriptionph}
                                            id={FORMROUTEDESCRIPTIONID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.routeDescription || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMROUTEDESCRIPTIONID, index)
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="main-row-quadra">

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.guidedVisit}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMGUIDEDVISITID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.guidedVisit || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.guidedVisitph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMGUIDEDVISITID, index)
                                            }
                                        </div>
                                    </div>




                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.freeTourAvailable}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMFREETOURAVAILABLEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.freeTourAvailable || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.freeTourAvailableph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMFREETOURAVAILABLEID, index)
                                            }
                                        </div>
                                    </div>

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adventure}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMADVENTUREID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.adventure || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adventureph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMADVENTUREID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adaptedVisitAvailable}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMADAPTEDVISITAVAILABLEID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.adaptedVisitAvailable || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.adaptedVisitAvailableph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMADAPTEDVISITAVAILABLEID, index)
                                            }
                                        </div>
                                    </div>


                                </div>
                                <div className="container-title"><span>{props.appLanguage.backoffice.manageMines.add.information.facilitiesInfo.fourthTitle}</span></div>

                                <div className="main-row-triple">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.brochuresPrintedInfo}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMBROCHURESPRINTEDINFOID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.brochuresPrintedInfo || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.brochuresPrintedInfoph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMBROCHURESPRINTEDINFOID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.audioVisualResources}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMAUDIOVISUALRESOURCESID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.audioVisualResources || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.audioVisualResourcesph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMAUDIOVISUALRESOURCESID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForYoungAdults}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMWORKSHOPSFORYOUNGADULTSID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.workshopsForYoungAdults || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForYoungAdultsph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMWORKSHOPSFORYOUNGADULTSID, index)
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="main-row-triple">


                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForChildren}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMEXPLANATIONSFORCHILDRENID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.explanationsForChildren || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForChildrenph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>


                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMEXPLANATIONSFORCHILDRENID, index)
                                            }
                                        </div>
                                    </div>

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.arVrResources}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMARVRRESOURCESID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.arVrResources || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.arVrResourcesph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMARVRRESOURCESID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForDisabled}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMWORKSHOPSFORDISABLEDID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.workshopsForDisabled || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForDisabledph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMWORKSHOPSFORDISABLEDID, index)
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="main-row-triple">

                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForDisabled}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMEXPLANATIONSFORDISABLEDID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.explanationsForDisabled || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.explanationsForDisabledph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMEXPLANATIONSFORDISABLEDID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForChildren}
                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMWORKSHOPSFORCHILDRENID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.workshopsForChildren || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForChildrenph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMWORKSHOPSFORCHILDRENID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForAdventure}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMWORKSHOPSFORADVENTUREID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.workshopsForAdventure || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.workshopsForAdventureph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMWORKSHOPSFORADVENTUREID, index)
                                            }
                                        </div>
                                    </div>
                                </div>



                                <div className="main-row-split">
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.languages}

                                        </label>
                                        <input className="input-normal-text kekax-input-box"
                                            type="text"
                                            placeholder={props.appLanguage.backoffice.manageMines.add.facilitiesInfo.languagesph}
                                            id={FORMLANGUAGESID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.languages || ""}

                                        />
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMLANGUAGESID, index)
                                            }
                                        </div>
                                    </div>
                                    <div className="input-normal">
                                        <label className="label">
                                            {props.appLanguage.backoffice.manageMines.add.facilitiesInfo.educationalVisitAvailableInEnglish}

                                        </label>
                                        <select className="input-normal-text kekax-input-box"
                                            id={FORMEDUCATIONALVISITAVAILABLEINENGLISHID + index}
                                            onChange={(event: any) => changeInputHandler(event, index)}
                                            onFocus={(event: any) => validateFocusFields(event, index)}
                                            value={facility.educationalVisitAvailableInEnglish || ""}

                                        >
                                            <option className="ayy" value="" disabled>{props.appLanguage.backoffice.manageMines.add.facilitiesInfo.educationalVisitAvailableInEnglishph}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[0]}></option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[1]}>{GeneralServices.taux2YesNo[1]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[2]}>{GeneralServices.taux2YesNo[2]}</option>
                                            <option className="ayy" value={GeneralServices.taux2YesNo[3]}>{GeneralServices.taux2YesNo[3]}</option>
                                        </select>
                                        <div className="feedback-block">
                                            {
                                                validatorFeedback(FORMEDUCATIONALVISITAVAILABLEINENGLISHID, index)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>:null
                ))
            }

        </div>

    )
}


export default connect(mineStateToProps)(FacilitiesInfoEdit);