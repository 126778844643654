import React, { useState, useEffect, useRef } from 'react'
import "./AuxCountry.css"
import GeneralServices from "../../../../services/generalService.json"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import SnackKekax from '../../../kekax/SnackKekax';
import TooltipKekax from '../../../kekax/TooltipKekax';
import { httpGetAuxCountryAll } from '../../../../services/requestService';

import AddAuxCountry from './addAuxCountry/AddAuxCountry';
import EditAuxCountry from './editAuxCountry/EditAuxCountry';
import RmvAuxCountry from './rmvAuxCountry/RmvAuxCountry';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShowAuxCountry from './showAuxCountry/ShowAuxCountry';


interface IProps extends StoreProps {

}


interface IOpenAuxCountry {
    add: boolean
    edit: boolean
    rmv: boolean
    show: boolean
    country: IAuxCountry

}

const AUXCOUNTRYADD = "add"
const AUXCOUNTRYRMV = "rmv"
const AUXCOUNTRYEDIT = "edit"
const AUXCOUNTRYSHOW = "show"

export interface IAuxCountry {
    id: string
    shortName: string
    NOLName: string
    officialName: string
    countryCode: string
}

export const AuxCountry: React.FC<IProps> = (props) => {

    const maxArray: number = 10;
    const maxPagesArray: number = 5;

    const [countryArray, setCountryArray] = useState<IAuxCountry[]>([]);
    //const [countryArrayDisplay, setCountryArrayDisplay] = useState<IAuxCountry[]>([]);

    const [totalCountries, setTotalCountries] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const snackKekaxRef: any = useRef();

    const [openAuxCountry, setOpenAuxCountry] = useState<IOpenAuxCountry>({ edit: false, rmv: false, add: false, show: false, country: { id: "", shortName: "", NOLName: "", officialName: "", countryCode: "" } })



    useEffect(() => {
        /*
        const dummyAuxCountries = GeneralServices.countries
        let dummyAuxCountriesToAdd: IAuxCountry[] = []
        for (let i = 0; i < dummyAuxCountries.length; i++) {
            dummyAuxCountriesToAdd.push({
                id: dummyAuxCountries[i].id, shortName: dummyAuxCountries[i].shortNameENG, NOLName: dummyAuxCountries[i].shortNameNOL,
                officialName: dummyAuxCountries[i].officialName, countryCode: dummyAuxCountries[i].tag
            })
        }
        setCountryArray([...dummyAuxCountriesToAdd])
        setTotalCountries(dummyAuxCountriesToAdd.length)
        */
        httpGetAuxCountryAll('?limit=' + maxArray).then(
            res => {
                //SETTING TOTAL Publication

                if (res.headers.total !== undefined) {
                    setTotalCountries(parseInt(res.headers.total, 10));
                } else {
                    setTotalCountries(parseInt('1', 10));
                }

                setCountryArray([...res.data]);


            }
        ).catch(
            err => {

            }
        )

    }, [])



    const handleOpenAuxCountry = (what: string, auxCountry: IAuxCountry | any) => {

        if (what === AUXCOUNTRYADD) {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...data, add: !data.add }))

        } else if (what === AUXCOUNTRYEDIT) {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...data, edit: !data.edit, country: auxCountry }))

        } else if (what === AUXCOUNTRYRMV) {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...data, rmv: !data.rmv, country: auxCountry }))

        } else if (what === AUXCOUNTRYSHOW) {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...data, show: !data.show, country: auxCountry }))

        }
    }

    const addAuxCountryHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxCountry({ ...openAuxCountry, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.add.submitSuccess)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxCountry({ ...openAuxCountry, add: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.add.submitFailed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxCountry({ ...openAuxCountry, add: false })
        } else {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...openAuxCountry, add: !data.add }))
        }
        loadNewPage(counter, false)
    }

    const editAuxCountryHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxCountry({ ...openAuxCountry, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.edit.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxCountry({ ...openAuxCountry, edit: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.edit.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxCountry({ ...openAuxCountry, edit: false })
        } else {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...openAuxCountry, edit: !data.edit }))
        }
        loadNewPage(counter, false)
    }

    const rmvAuxCountryHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setOpenAuxCountry({ ...openAuxCountry, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.delete.success)
            //setTotalPublication((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setOpenAuxCountry({ ...openAuxCountry, rmv: false })
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.aux.country.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxCountry({ ...openAuxCountry, rmv: false })
        } else {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...openAuxCountry, rmv: !data.rmv }))
        }
        loadNewPage(counter, false)
    }

    const showAuxCountryHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.closed) {
            setOpenAuxCountry({ ...openAuxCountry, show: false })
        } else {
            setOpenAuxCountry((data: IOpenAuxCountry) => ({ ...openAuxCountry, show: !data.show }))
        }
        loadNewPage(counter, false)
    }


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalCountries / maxArray);
        const extra = totalCountries % maxArray;

        if (extra !== 0 || totalCountries === 0) {
            result += 1
        }

        setMaxCounter(result);
    }, [totalCountries])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, init: boolean) => {
        /*
        const startFrom = page === 0 ? 0 : (page - 1) * maxArray

        let newPageMines: IAuxCountry[] = []
        for (let i = startFrom, counter = 0; i < countryArray.length && counter < maxArray; i++, counter++) {
            newPageMines.push(countryArray[i]);

            if (newPageMines.length === maxArray || i === countryArray.length - 1) {
                setCountryArrayDisplay([...newPageMines])
            }

            if (init) {
                window.scrollTo({
                    top: 0,
                    behavior: 'auto'
                })

            }
        }
        */
        const queryPage = page - 1;
        httpGetAuxCountryAll('?page=' + queryPage + '&limit=' + maxArray).then(
            res => {
                setCountryArray([...res.data]);
                if (init) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    })
                }
            }
        ).catch(
            err => {
                //console.log(err)
            }
        )

    }

    /*
    useEffect(() => {
        if (countryArray.length > 0) {
            loadNewPage(0, true)
        }
    }, [countryArray])
    */
    return (
        <React.Fragment>
            <div className="wrapper-aux-country">

                <div className="container-auxCountry">
                    <div className="auxCountry-menu">
                        <div className="auxCountry-menu-title"><span>{props.appLanguage.backoffice.manageMines.aux.country.title}</span></div>
                        <div className="auxCountry-menu-options">
                            <div className="auxCountry-opt-add" onClick={() => handleOpenAuxCountry(AUXCOUNTRYADD, null)}>
                                <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.country.addOpt} direction="top">
                                    <div className="auxCountry-opt-add-icon"></div>
                                    <span>{props.appLanguage.backoffice.manageMines.aux.country.addOpt}</span>
                                </TooltipKekax>
                            </div>
                        </div>
                    </div>

                    <div className="auxCountry-list">
                        {
                            countryArray.map((auxCountry: IAuxCountry, index: number) => (
                                <div className="auxCountry-list-obj" key={"country" + index}>
                                    <div className="auxCountry-id"><span>{auxCountry.id}</span></div>
                                    <div className="auxCountry-title">
                                        <span>{auxCountry.officialName} </span>
                                    </div>
                                    <div className="auxCountry-options">
                                        <div className="auxCountry-obj-show" onClick={() => handleOpenAuxCountry(AUXCOUNTRYSHOW, auxCountry)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.country.showOpt} direction="top">
                                                <div className="auxCountry-obj-show-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                        <div className="auxCountry-obj-rmv" onClick={() => handleOpenAuxCountry(AUXCOUNTRYRMV, auxCountry)}>
                                            <TooltipKekax content={props.appLanguage.backoffice.manageMines.aux.country.rmvOpt} direction="top">
                                                <div className="auxCountry-obj-rmv-icon"></div>
                                            </TooltipKekax>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>

                </div>
                <div className="container-auxCountry-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>

            </div>

            <SnackKekax ref={snackKekaxRef} />
            <AddAuxCountry data={{ open: openAuxCountry.add, country: openAuxCountry.country }} callback={addAuxCountryHandler} />
            <RmvAuxCountry data={{ open: openAuxCountry.rmv, country: openAuxCountry.country }} callback={rmvAuxCountryHandler} />
            <ShowAuxCountry data={{ open: openAuxCountry.show, country: openAuxCountry.country }} callback={showAuxCountryHandler} />

        </React.Fragment>
    );

}


export default connect(mapStateToProps)(AuxCountry);
