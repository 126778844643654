import React, { useState } from 'react';
import "./TooltipKekax.css"

interface IProps{
    children: any
    direction?: any
    content?: any
    delay?: any
}

export function TooltipKekax(props: IProps) {

    let timeout: any;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 1);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div
            className="wrapper-kekax-tooltip" onMouseEnter={showTip} onMouseLeave={hideTip} onMouseDown={hideTip}>
            {props.children}
            {active && (
                <div className={`tooltip-object ${props.direction || "top"}`}>
                    {props.content}
                </div>
            )}
        </div>
    );

}

export default TooltipKekax
