import React from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import './StartPage.css'

interface IProps extends StoreProps {
    time: number
}


export const StartPage: React.FC<IProps> = (props) => {

    return (
        <div className="wrapper-quiz-start">
            <div className="container-countdown">
                <div className="countdown">
                    <div className="countdown-number"><span>{props.time}</span></div>
                    <svg>
                        <circle className="circle-grey" r="7vmax" cx="10vmax" cy="10vmax"></circle>
                        <circle className="circle-colour" r="7vmax" cx="10vmax" cy="10vmax"></circle>

                    </svg>
                </div>
                <div className="msg"><span >{props.appLanguage.quizPage.start.loading} </span></div>

            </div>

        </div>
    );

}

export default connect(mapStateToProps)(StartPage);


/*
            <div className="container-quiz-start">
                <span className="msg">{props.appLanguage.quizPage.start.loading} </span>
                <span className="timer">{props.time}</span>
            </div>
            */