import React, { useEffect, useRef, useState } from 'react';
import "./DeleteNewsModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
//import { httpDeleteNewsletter } from '../../../../services/requestService'
import GeneralServices from '../../../../services/generalService.json'
import { httpDeleteNewsletter } from '../../../../services/requestService';

interface IProps extends StoreProps {
    callback: any
    news: IDeleteNews
}

export interface IDeleteNews {
    open: boolean
    news: string
    newsId: string
}

export function DeleteNewsModal(props: IProps) {

    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);


    const [tempDisabled, setTempDisabled] = useState<number>(0);

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    useEffect(() => {

        if (props.news.open && modalRef.current) {
            modalRef.current.classList.add("deleteNews-modal-open");

            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();

        } else {
            modalRef.current.classList.remove("deleteNews-modal-open");
        }

    }, [props.news.open])

    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }

    const closeModalHandler = (feedback: string) => {
        setTempDisabled(0)
        props.callback(feedback);
        modalRef.current.classList.remove("deleteNews-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);

    }

    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--deleteModalNews-topOffset", posY + "px");
    }


    const deleteNewsHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setTempDisabled(1)


        httpDeleteNewsletter(props.news.newsId).then(
            res => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            err => {
                closeModalHandler(GeneralServices.constants.child.failed);

            }
        )
    }





    return (
        <div className="wrapper-delete-news" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>
            <div className="content" ref={contentRef}>
                <div className="header">
                    <span className="modal-title">{props.appLanguage.backoffice.manageNews.delete.title}</span>
                    <div className="close"><button className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><div className="img"></div></button></div>
                </div>
                <div className="body">
                    <span>{props.appLanguage.backoffice.manageNews.delete.question}</span>
                    <span className="news-title">{props.news.news}</span>
                </div>
                <div className="footer">
                    <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageNews.delete.cancel}</span></button></div>
                    <div className="submit"><button type="button" className="submit-btn" disabled={tempDisabled === 1} onClick={(event: any) => deleteNewsHandler(event)} ><span className="info">{props.appLanguage.backoffice.manageNews.delete.submit}</span></button></div>
                </div>
            </div>
        </div>
    );

}


export default connect(mapStateToProps)(DeleteNewsModal);
