import React, { useState, useEffect } from 'react';
import "./ListEvents.css"
import { IEvent } from '../eventPage/EventPage';
import { getTime, isSameDay } from 'date-fns';
import dummyEvent from '../../../assets/news/newsDelta.png'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from './../../../store/storeApp';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GeneralServices from '../../../services/generalService.json'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { translateFormatTimestamp } from '../calendar/Calendar';



export const dummyEventsArray: IEvent[] = [
    {
        id: '0', tag: "0", title: "Event Title Main", desc: "Event main description", img: dummyEvent, timestamp: 1582651866000, timestampEnd: 1582651866000, interest: 1

    },
    /*{ id: 1, tag: "Sport", title: "Event Title Number 1", desc: "Event number 1 description", img: dummyEvent, timestamp: (dateFormat(new Date(), "MMMM dd, yyyy")), importance: 1, views: 1 },*/
    {
        id: '2', tag: "0", title: "Event Title Number 2", desc: "Event number 2 description", img: dummyEvent, timestamp: 1582651866000, timestampEnd: 1582651866000, interest: 1

    },
    {
        id: '3', tag: "0", title: "Event Title Number 3", desc: "Event number 3 description", img: dummyEvent, timestamp: 1582651866000, timestampEnd: 1582651866000, interest: 1

    },
    {
        id: '4', tag: "0", title: "Event Title Number 4", desc: "Event number 4 description", img: dummyEvent, timestamp: 1582651866000, timestampEnd: 1582651866000, interest: 1

    },
    {
        id: '6', tag: "0", title: "Event Title Number 5", desc: "Event number 5 description", img: dummyEvent, timestamp: getTime(new Date()), timestampEnd: getTime(new Date()), interest: 1

    },
    {
        id: '7', tag: "0", title: "Event Title Number 5", desc: "Event number 5 description", img: dummyEvent, timestamp: getTime(new Date()), timestampEnd: getTime(new Date()), interest: 1
    },
    {
        id: '8', tag: "0", title: "Event Title Number 5", desc: "Event number 5 description", img: dummyEvent, timestamp: getTime(new Date()), timestampEnd: getTime(new Date()), interest: 1
    },
    {
        id: '9', tag: "0", title: "Event Title Number 5", desc: "Event number 5 description", img: dummyEvent, timestamp: getTime(new Date()), timestampEnd: getTime(new Date()), interest: 1
    },
    {
        id: '10', tag: "0", title: "Event Title Number 5", desc: "Event number 5 description", img: dummyEvent, timestamp: getTime(new Date()), timestampEnd: getTime(new Date()), interest: 1
    }
]

interface IProps extends StoreProps, RouteComponentProps<any> {
    eventsArray: IEvent[];
    cbPageChange: any;
    totalEvents: number;

}
export const maxEventArray: number = 8;

export const ListEvents: React.FC<IProps> = (props) => {

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const maxPagesArray: number = 5;


    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(props.totalEvents / maxEventArray);
        const extra = props.totalEvents % maxEventArray;

        if (extra !== 0 || props.totalEvents === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [props.totalEvents])

    /*
    useEffect(() => {
        if(props.eventsArray.length<=0){

        }
    }, [props.eventsArray])
    */

    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])


    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            props.cbPageChange(counterHax - 1);
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                props.cbPageChange(counterHax - 1);
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                props.cbPageChange(counterHax - 1);
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }
        setCounter(counterHax)
    }

    useEffect(() => {
        const myParent = document.getElementById("listevent-parent-container")
        if (myParent) {
            
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            })
            
        }

        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }
            setPagesArray([...dummyArray])

        }
    }, [counter])


    const handleAccessEvent = (id: any) => {
        let eventURL = GeneralServices.eventPath.replace(':id', id)
        props.history.push(eventURL);
    }

    const handleSameDate = (start: any, end: any) => {
        if (isSameDay(start, end)) {
            return true
        }
        return false;
    }

    /*
        useEffect(() => {
            const myParent = document.getElementById("listevent-parent-container")
            if(myParent){
                window.scrollTo({
                    top: myParent.getBoundingClientRect().top + myParent.offsetHeight,
                    behavior: 'auto'
                })
            }
        }, [props.eventsArray])
    */
    return (
        <div className="wrapper-list-event" id="listevent-parent-container">
            <div className="container-list-event">
                {
                    props.eventsArray.length > 0 ?
                        <div className="container-event-flex">

                            {
                                props.eventsArray.map((event: IEvent, index: number) => (
                                    <div className="event-object" key={index} onClick={() => handleAccessEvent(event.id)}>
                                        <div className="event-object-img">
                                            <img src={event.img} alt={'eventsImg' + index} />
                                        </div>
                                        <div className="event-object-content">
                                            <span className="event-object-title">{event.title}</span>
                                            {
                                                !handleSameDate(event.timestamp, event.timestampEnd) ?
                                                    <div className="event-object-date">
                                                        <span className="event-object-datestart">{translateFormatTimestamp(event.timestamp, props.appLanguage)}</span>
                                                        <span className="event-object-dot">•</span>
                                                        <span className="event-object-dateend">{translateFormatTimestamp(event.timestampEnd, props.appLanguage)}</span>
                                                    </div>
                                                    :
                                                    <div className="event-object-date">
                                                        <span className="event-object-datestart">{translateFormatTimestamp(event.timestamp, props.appLanguage)}</span>
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                ))
                            }

                        </div>
                        :
                        <div className="container-event-nocontent">
                            <span>{props.appLanguage.events.noContent}</span>
                        </div>

                }
            </div>
            <div className="container-event-footer">
                <div className="event-footer-info">

                </div>
                <div className="event-footer-options">
                    <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                    {
                        pagesArray.map((pos, index) => (
                            pos === counter ?
                                <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                :
                                <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                        ))
                    }

                    <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                </div>
            </div>
        </div>
    );

}

export default connect(mapStateToProps)(withRouter(ListEvents));

/*
        <div className="wrapper-future-events">
            {
                eventsArray.map((event: IEvents, index: number) => (
                    <div className="future-events-obj" key={index}>
                        <div className="future-events-obj-image">
                            <img src={event.img} className="future-events-obj-image-main" alt="object-event" />

                        </div>
                        <div className="future-events-obj-content">
                            <span className="future-events-obj-content-title">{event.title}</span>
                            <div className="date-dot-type">
                                <span className="future-events-obj-content-date">{event.timestamp}</span>
                                <span className="future-events-obj-content-dot">•</span>
                                <span className="future-events-obj-content-type">{eventTagLanguage(props, event.tag)}</span>
                            </div>
                            <span className="future-events-obj-content-desc">{event.desc}</span>
                        </div>
                    </div>
                ))
            }
        </div >
*/