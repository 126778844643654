import React, { useState, useEffect, useRef } from 'react'
import "./ListMineStats.css"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import GeneralServices from '../../../../services/generalService.json'


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DeleteMineModal, { IDeleteMine } from '../../mineManage/deleteMineModal/DeleteMineModal';
import EditMineModal, { IEditMine } from '../../mineManage/editMineModal/EditMineModal';
import SnackKekax from '../../../kekax/SnackKekax';
import { IMineInfo } from '../../../mineMap/MineMap';
import { httpGetAllMines, httpGetListBooklets } from '../../../../services/requestService';

interface IProps extends StoreProps {

}



export const ListMineStats: React.FC<IProps> = (props) => {

    const [mineArray, setMineArray] = useState<IMineInfo[]>([]);
    const [mineArrayDisplay, setMineArrayDisplay] = useState<IMineInfo[]>([]);

    const [totalMine, setTotalMine] = useState<number>(0);

    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(1);
    const [pagesArray, setPagesArray] = useState<number[]>([]);

    const [deleteModal, setDeleteModal] = useState<IDeleteMine>({ open: false, mine: '', mineId: '' });
    const [editModal, setEditModal] = useState<IEditMine>({
        open: false, info: {
            id: 0, lat: 0, lng: 0, mineCode: '', website: '', siteNameENG: '', siteNameNOL: '', country: '',
            regionStateProvince: '', municipality: '', touristicname: '', minePopDesc: '', complex: '', complexName: '', mineHeritageRoute: '', projectFig: '', infoFigure: '',
            carPark: '', cafeteria: '', giftShop: '', address: '', address2: '', postalCode: '', phone: '', mail: '', otherInfoCont: '', pubTransport: '', pubTransportDesc: ''
        }
    });//KEKAXEDIT - false

    const maxMineArray: number = 10;
    const maxPagesArray: number = 5;
    const snackKekaxRef: any = useRef();



    useEffect(() => {
        loadNewPage(0, true, true)


    }, [])




    useEffect(() => {
        //SETTING PAGINATION
        let result = Math.floor(totalMine / maxMineArray);
        const extra = totalMine % maxMineArray;

        if (extra !== 0 || totalMine === 0) {
            result += 1
        }
        setMaxCounter(result);
    }, [totalMine])


    useEffect(() => {
        //set pages array
        if (maxCounter > maxPagesArray) {
            setPagesArray([1, 2, 3, 4, 5])
        } else {
            let dummyArray = [];
            for (let i = 1; i <= maxCounter; i++) {
                dummyArray.push(i)
            }
            setPagesArray([...dummyArray])
        }
    }, [maxCounter])

    const handlePagination = (page: any, option: any) => {
        let counterHax = -1;

        if (page !== null) {
            counterHax = page;
            loadNewPage(counterHax, true, false)
        } else if (option !== null) {
            if (option === 'back' && counter > 1) {
                counterHax = counter - 1;
                loadNewPage(counterHax, true, false)
            } else if (option === 'back' && counter === 1) {
                counterHax = counter;
            } else if (option === 'next' && counter < maxCounter) {
                counterHax = counter + 1;
                loadNewPage(counterHax, true, false)
            } else if (option === 'next' && counter === maxCounter) {
                counterHax = counter;
            }
        }


        setCounter(counterHax)
    }

    useEffect(() => {
        let position = -1;
        for (let i = 0; i <= maxPagesArray - 1; i++) {
            if (pagesArray[i] === counter) {
                position = i;
            }
        }

        if (maxCounter > 5) {
            const counterToFinish = maxCounter - pagesArray[maxPagesArray - 1];
            let dummyArray: number[] = [...pagesArray];
            if (position === 3) {
                if (counterToFinish >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 1;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 4) {
                if (counterToFinish >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += 2;
                    }
                } else {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] += counterToFinish;
                    }
                }
            } else if (position === 0) {
                if (pagesArray[0] - 1 >= 2) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 2;
                    }
                } else if (pagesArray[0] - 1 === 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            } else if (position === 1) {
                if (pagesArray[1] - 2 >= 1) {
                    for (let i = 0; i <= maxPagesArray - 1; i++) {
                        dummyArray[i] -= 1;
                    }
                }
            }

            setPagesArray([...dummyArray])
        }
    }, [counter])


    const loadNewPage = (page: any, init: boolean, reload: boolean) => {

        if (reload) {
            httpGetAllMines().then(
                res => {
                    const mines = [...res.data]
                    const totalMines = parseInt(mines.length + "", 10);
                    setTotalMine(totalMines);
                    setMineArray(mines);
                    const startFrom = page === 0 ? 0 : (page - 1) * maxMineArray

                    let newPageMines: IMineInfo[] = []
                    for (let i = startFrom, counter = 0; i < mines.length && counter < maxMineArray; i++, counter++) {
                        newPageMines.push(mines[i]);

                        if (newPageMines.length === maxMineArray || i === mines.length - 1) {
                            setMineArrayDisplay([...newPageMines])
                            if (init) {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'auto'
                                })

                            }
                        }
                    }

                }
            ).catch(
                err => {
                    //console.log(err)
                }
            )
        } else {

            const startFrom = page === 0 ? 0 : (page - 1) * maxMineArray

            let newPageMines: IMineInfo[] = []
            for (let i = startFrom, counter = 0; i < mineArray.length && counter < maxMineArray; i++, counter++) {
                newPageMines.push(mineArray[i]);

                if (newPageMines.length === maxMineArray || i === mineArray.length - 1) {
                    setMineArrayDisplay([...newPageMines])
                    if (init) {
                        window.scrollTo({
                            top: 0,
                            behavior: 'auto'
                        })

                    }
                }
            }
        }


    }
    /*
     useEffect(() => {
         if (mineArray.length > 0) {
             loadNewPage(0, true)
         }
     }, [mineArray])
 */
    const deleteMineHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.delete.success)
            setTotalMine((total: number) => total - 1)
        } else if (childData === GeneralServices.constants.child.failed) {
            setDeleteModal({ ...deleteModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.delete.failed)
        } else if (childData === GeneralServices.constants.child.closed) {
            setDeleteModal({ ...deleteModal, open: false });
        } else {
            setDeleteModal({ ...deleteModal, open: !deleteModal.open });
        }
        loadNewPage(counter, false, true)

    }



    const editMineHandler = (childData: any) => {
        if (childData === GeneralServices.constants.child.worked) {
            setEditModal({ ...editModal, open: false });
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.edit.success)
        } else if (childData === GeneralServices.constants.child.failed) {
            snackKekaxRef.current.openSnackBar(props.appLanguage.backoffice.manageMines.edit.failed)
            setEditModal({ ...editModal, open: false });
        } else if (childData === GeneralServices.constants.child.closed) {
            setEditModal({ ...editModal, open: false });
        } else {
            setEditModal({ ...editModal, open: !editModal.open });
        }
        loadNewPage(counter, false, true)

    }


    return (
        <React.Fragment>
            <div className="wrapper-list-mine-stats">
                <div className="list-mine-stats-header">

                </div>
                <div className="list-mine-stats-body">
                    {
                        mineArrayDisplay.map((mine: IMineInfo, index: number) => (
                            <div className="mine-stats-object" key={index}>
                                <div className="mine-stats-object-union">
                                    <div className="mine-stats-object-content">
                                        <span className="mine-object-title">{mine.mineCode}</span>
                                    </div>

                                    <div className="mine-stats-object-menu">
                                        <div className="object-menu-opt opt-delete" onClick={() => setDeleteModal({ open: true, mine: mine.mineCode, mineId: mine.id + "" })}>
                                            <DeleteIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageMines.delete.option}</span>
                                        </div>
                                        <div className="object-menu-opt opt-edit" onClick={() => setEditModal({ open: true, info: mine })}>
                                            <EditIcon className="menu-opt-icon" />
                                            <span className="menu-opt-text">{props.appLanguage.backoffice.manageMines.edit.option}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
                <div className="list-mine-stats-footer">
                    <div className="footer-info"></div>
                    <div className="footer-pagination">
                        <ChevronLeftIcon className="go-left" onClick={() => handlePagination(null, 'back')} />
                        {
                            pagesArray.map((pos, index) => (
                                pos === counter ?
                                    <span key={index} className="go-pages selected-go-pages">{pos}</span>
                                    :
                                    <span key={index} className="go-pages" onClick={() => handlePagination(pos, null)}>{pos}</span>
                            ))
                        }

                        <ChevronRightIcon className="go-right" onClick={() => handlePagination(null, 'next')} />
                    </div>
                </div>
            </div>

            <EditMineModal mine={editModal} callback={editMineHandler} />
            <DeleteMineModal mine={deleteModal} callback={deleteMineHandler} />
            <SnackKekax ref={snackKekaxRef} />


        </React.Fragment>
    );

}
/*


<EditMineModal mine={editModal} callback={editMineHandler} />
onClick={() => setEditModal({ open: true, mineId: mine.id })}
*/
export default connect(mapStateToProps)(ListMineStats);

