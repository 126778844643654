import React, { useEffect, useRef, useState } from 'react';
import "./EditLinkModal.css"
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../../store/storeApp';
import { connect } from 'react-redux';
import StepperEditLink from './stepperEditLink/StepperEditLink';
import { IFormErrorLink, IFormFocusLink, IFormInputLink, IFormValidLink } from '../addLink/AddLink';
import GeneralServices from '../../../../services/generalService.json'
import SnackKekax from '../../../kekax/SnackKekax';
import { httpGetLinkDetails, httpPutLink } from '../../../../services/requestService';
import StepperKekax from '../../../kekax/StepperKekax';


interface IProps extends StoreProps {
    callback: any
    link: IEditLink
}

export interface IEditLink {
    open: boolean
    linkId: any
    linkName: string
}

interface SnackKekax {
    open: boolean
    message: string
}

interface IEditLinkValidation {
    main: boolean
}

const FORMTITLEID: string = 'title';
const FORMDESCRIPTIONID: string = 'description';
const FORMWEBSITEID: string = 'website';

const MAX_STEPS = 0

export function EditLinkModal(props: IProps) {

    const [formSubmitValid, setFormSubmitValid] = useState<IEditLinkValidation>({ main: true });
    const [formError, setFormError] = useState<IFormErrorLink>({ titleError: '', descriptionError: '', websiteError: '' });
    const [formInput, setFormInput] = useState<IFormInputLink>({ title: '', description: '', website: '' });
    const [formValid, setFormValid] = useState<IFormValidLink>({ titleValid: false, descriptionValid: false, websiteValid: false });
    const [formFocus, setFormFocus] = useState<IFormFocusLink>({ titleFocus: false, descriptionFocus: false, websiteFocus: false });


    const snackKekaxRef: any = useRef(null);


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);

    const [selectedStepper, setSelectedStepper] = useState<string>("0")

    const [tempDisabled, setTempDisabled] = useState<number>(0);

    useEffect(() => {
        // On first render, counter will be 0. The condition will be false and setTimeout() won't start
        if (tempDisabled === 1)
            var timer = setTimeout(() => { setTempDisabled(0) }, 3000);
        return () => clearTimeout(timer);

    }, [tempDisabled]);

    const loadLinkById = () => {
        const linkId = props.link.linkId
        httpGetLinkDetails(linkId).then(
            async res => {
                setFormInput({ title: res.data.title, description: res.data.description, website: res.data.website })
            }
        ).catch(
            err => {
            }
        )
    }



    useEffect(() => {

        if (props.link.open && modalRef.current) {
            modalRef.current.classList.add("editLink-modal-open");

            loadLinkById()
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);
            handleOffset();



        } else {
            modalRef.current.classList.remove("editLink-modal-open");

        }

    }, [props.link.open])




    const handleClickAway = (event: any) => {
        /*
        if (contentRef && !contentRef.current.contains(event.target)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(GeneralServices.constants.child.closed);
        }
    }



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--editModalLink-topOffset", posY + "px");
    }




    /*
    const changeStepperSelectedHandler = (active: any) => {
        setSelectedStepper(active)
    }

    const changeStepperHandler = (what: string) => {
        let count = parseInt(selectedStepper)
        switch (what) {
            case "PREVIOUS": {
                if (count > 0) {
                    count--
                }
                break;

            }
            case "NEXT": {
                if (count < MAX_STEPS) {
                    count++
                }
                break;
            }
            default: {
                break;
            }
        }
        setSelectedStepper(count.toString())
    }
    */



    /*MODAL SETUP UP*/



    const formEndup = () => {

        contentRef.current.reset();
        setFormSubmitValid({ main: false });
        setFormError({ titleError: '', descriptionError: '', websiteError: '' });
        setFormInput({ title: '', description: '', website: '' })
        setFormValid({ titleValid: false, descriptionValid: false, websiteValid: false })
        setFormFocus({ titleFocus: false, descriptionFocus: false, websiteFocus: false })

        setTempDisabled(0)
    }



    /*CHANGE INPUT*/
    const changeInputHandler = (e: any) => {
        const input = e.currentTarget;
        const value = input.value;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormInput({ ...formInput, title: value })
                break;
            }
            case FORMDESCRIPTIONID: {
                setFormInput({ ...formInput, description: value })
                break;
            }
            case FORMWEBSITEID: {
                setFormInput({ ...formInput, website: value })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }


    //VALIDATE FIELDS
    useEffect(() => {
        let fieldValidationErrors = formError;
        let upd_titleValid = formValid.titleValid;
        let upd_descriptionValid = formValid.descriptionValid;
        let upd_websiteValid = formValid.websiteValid;

        /*TITLE VALIDATION*/
        if (formInput.title === '') {
            upd_titleValid = false;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_titleValid = true;
            fieldValidationErrors.titleError = props.appLanguage.backoffice.manageLinks.add.titleAccept;
        }

        /*DESCRIPTION VALIDATION*/
        if (formInput.description === '') {
            upd_descriptionValid = false;
            fieldValidationErrors.descriptionError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_descriptionValid = true;
            fieldValidationErrors.descriptionError = props.appLanguage.backoffice.manageLinks.add.descriptionAccept;
        }

        /*WEBSITE VALIDATION*/
        if (formInput.website === '') {
            upd_websiteValid = false;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageLinks.add.requiredError;
        } else {
            upd_websiteValid = true;
            fieldValidationErrors.websiteError = props.appLanguage.backoffice.manageLinks.add.websiteAccept;
        }



        setFormError(fieldValidationErrors)
        setFormValid({ titleValid: upd_titleValid, descriptionValid: upd_descriptionValid, websiteValid: upd_websiteValid })


        //eslint-disable-next-line
    }, [formInput, formFocus])


    /*SUBMIT BUTTON AVAILABLE EFFECT*/
    useEffect(() => {
        setFormSubmitValid({ main: formValid.titleValid && formValid.descriptionValid && formValid.websiteValid });
    }, [formValid])


    /*VALIDATE FIELD FOCUS*/
    const validateFocusFields = (e: any) => {
        const input = e.currentTarget;
        const formId = input.id;

        switch (formId) {
            case FORMTITLEID: {
                setFormFocus({ ...formFocus, titleFocus: true })
                break;
            }
            case FORMDESCRIPTIONID: {
                setFormFocus({ ...formFocus, descriptionFocus: true })
                break;
            }
            case FORMWEBSITEID: {
                setFormFocus({ ...formFocus, websiteFocus: true })
                break;
            }
            default: {
                break;
            }
        }//switch end
    }

    /*HTTP REQUEST SUBMIT*/
    const submitHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.reset();
        setTempDisabled(1)
        //console.log(fileStatus)
        
        httpPutLink(
            {
                linkId:props.link.linkId,
                title: formInput.title,
                description: formInput.description,
                website: formInput.website
            },
            
        ).then(
            (res: any) => {
                closeModalHandler(GeneralServices.constants.child.worked);
            }
        ).catch(
            (err: any) => {
                closeModalHandler(GeneralServices.constants.child.failed);
            }
        )
        
        closeModalHandler(GeneralServices.constants.child.closed);

    }



    const closeModalHandler = (feedback: string) => {
        formEndup()
        modalRef.current.classList.remove("editLink-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);
        setSelectedStepper("0");
        props.callback(feedback)

    }




    const validatorFeedback = (formId: any) => {
        switch (formId) {
            case FORMTITLEID: {
                return formFocus.titleFocus && !formValid.titleValid ?
                    <span className="input-invalid-feedback-kekax">{formError.titleError}</span>
                    : formValid.titleValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.titleAccept}</span>
                        : null
            }
            case FORMDESCRIPTIONID: {
                return formFocus.descriptionFocus && !formValid.descriptionValid ?
                    <span className="input-invalid-feedback-kekax">{formError.descriptionError}</span>
                    : formValid.descriptionValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.descriptionAccept}</span>
                        : null
            }
            case FORMWEBSITEID: {
                return formFocus.websiteFocus && !formValid.websiteValid ?
                    <span className="input-invalid-feedback-kekax">{formError.websiteError}</span>
                    : formValid.websiteValid ?
                        <span className="input-valid-feedback-kekax">{props.appLanguage.backoffice.manageLinks.add.websiteAccept}</span>
                        : null
            }
            default: {
                break;
            }
        }//switch end
    }

    const printActiveContent = () => {
        if (selectedStepper === "0") {
            return (
                <div>
                    <div className="container-edit-link-main">
                        <div className="container-title"><span>{props.appLanguage.backoffice.manageLinks.add.information.main}</span></div>

                        <div className="main-row">
                            <div className="input-title">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.title} *

                                </label>
                                <input className="input-title-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.titleph}
                                    id={FORMTITLEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.title || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMTITLEID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-description">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.description} *

                                </label>
                                <input className="input-description-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.descriptionph}
                                    id={FORMDESCRIPTIONID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.description || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMDESCRIPTIONID)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="main-row">
                            <div className="input-website">
                                <label className="label">
                                    {props.appLanguage.backoffice.manageLinks.add.website} *

                                </label>
                                <input className="input-website-text kekax-input-box"
                                    type="text"
                                    placeholder={props.appLanguage.backoffice.manageLinks.add.websiteph}
                                    id={FORMWEBSITEID}
                                    onChange={(event: any) => changeInputHandler(event)}
                                    onFocus={(event: any) => validateFocusFields(event)}
                                    value={formInput.website || ""}
                                />
                                <div className="feedback-block">
                                    {
                                        validatorFeedback(FORMWEBSITEID)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            )
        } else if (selectedStepper === "1") {
            return null
        } else if (selectedStepper === "2") {
            return null
        }
    }


    const printSubmitButton = () => {
        const count = parseInt(selectedStepper)

        switch (count) {
            case 0: {
                return (
                    <div className="submit"><button type="submit" disabled={!formSubmitValid.main || tempDisabled === 1} className="submit-btn" >{props.appLanguage.backoffice.manageLinks.edit.submit} </button></div>
                )
            }
            default: {
                return null;
            }
        }
    }



    return (
        <React.Fragment>
            <div className="wrapper-edit-link" ref={modalRef}>
                <div className="overlay" ref={overlayRef}></div>
                <form className="content" ref={contentRef} id='edit-link-form' onSubmit={(event: any) => submitHandler(event)}>
                    <div className="header">
                        <span className="modal-title">{props.appLanguage.backoffice.manageLinks.edit.title}</span>
                        <div className="close"><button type="button" className="close-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}></button></div>
                    </div>
                    <div className="body">
                        <div className="container-stepper">
                            <StepperKekax active={selectedStepper}>
                                <div key="0">{props.appLanguage.backoffice.manageLinks.edit.steps[0]}</div>
                            </StepperKekax>
                        </div>
                        <div className="container-stepper-body">
                            <div className="container-form-edit-link">

                                {
                                    printActiveContent()
                                }


                            </div>
                        </div>

                    </div>
                    <div className="footer">
                        <div className="cancel"><button type="button" className="cancel-btn" onClick={() => closeModalHandler(GeneralServices.constants.child.closed)}><span className="info">{props.appLanguage.backoffice.manageLinks.edit.cancel}</span></button></div>
                        {
                            printSubmitButton()

                        }



                    </div>
                </form>
            </div>
            <SnackKekax ref={snackKekaxRef} />

        </React.Fragment>
    );

}



export default connect(mapStateToProps)(EditLinkModal);
