import React, { useState, useEffect, useCallback } from 'react'
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { connect } from 'react-redux';
import './Calendar.css'
import CalendarHeader from './calendarHeader/CalendarHeader';
import CalendarMain from './calendarMain/CalenderMain';
import { httpGetListEvents } from '../../../services/requestService';
import { IEvent } from '../eventPage/EventPage';
import { startOfMonth, getTime } from 'date-fns';
import { lastDayOfMonth } from 'date-fns/esm';
import * as dateFns from "date-fns";
import { VisorCalendar } from '../calendarPage/CalendarPage';
import { LanguageJson } from '../../../services/languages/langTypes';



export const translateFormatTimestamp = (timestamp: any, lang: LanguageJson) => {
   // const dateLanguage = require('date-fns/locale/' + lang + '/index.js');
    const currentDate = new Date(timestamp);
    //return dateLanguage.localize.month(dateFns.getMonth(currentDate)) + ' ' +  dateFns.getDate(currentDate) +  ', ' + dateFns.format(currentDate, "yyyy");
    return lang.events.monthsOfYear[(dateFns.getMonth(currentDate))] + ' ' +  dateFns.getDate(currentDate) +  ', ' + dateFns.format(currentDate, "yyyy");

}

export const translateTodayDate = (lang: LanguageJson) => {
    //const dateLanguage = require('date-fns/locale/' + lang + '/index.js');
    const currentDate = new Date();
    //return dateLanguage.localize.day(dateFns.getDay(currentDate)) + ', ' + dateFns.getDate(currentDate) + ' ' + dateLanguage.localize.month(dateFns.getMonth(currentDate)) + ' ' + dateFns.format(currentDate, "yyyy");
    return lang.events.daysOfWeekExt[(dateFns.getDay(currentDate))] + ', ' + dateFns.getDate(currentDate) + ' ' + lang.events.monthsOfYear[(dateFns.getMonth(currentDate))] + ' ' + dateFns.format(currentDate, "yyyy");

}


interface IProps extends StoreProps {
    cbCurrentEventDate: any;
 }

export const Calendar: React.FC<IProps> = (props) => {

    const [cbCurrentDate, setCurrentDate] = useState<Date>(new Date());
    const [eventsArray, setEventsArray] = useState<IEvent[]>([]);


    useEffect(() => {
        /*
        httpGetListEvents(null).then(
            res => {
                const dataArray: IEvent[] = [...res.data];
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        //const timeToDate = dateFormat(new Date(dataArray[i].timestamp), "MMMM dd, yyyy");
                        //dataArray[i].timestamp = dateFormat(new Date(timeToDate), "MMMM dd, yyyy");
                        setEventsArray([...dataArray]);
                    }
                }
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
        */
    }, [])


    const cbCurrentDateFunction = useCallback(
        (response: any) => {
            const monthStart = startOfMonth(response); //first day month
            const monthEnd = lastDayOfMonth(response); //last day moth

            httpGetListEvents('?from='+getTime(monthStart)+'&to='+getTime(monthEnd)).then(
                res => {
                    //console.log(res)
                    const dataArray: IEvent[] = [...res.data];
                    if (dataArray.length > 0) {
                        for (let i = 0; i < dataArray.length; i++) {
                            //const timeToDate = dateFormat(new Date(dataArray[i].timestamp), "MMMM dd, yyyy");
                            //dataArray[i].timestamp = dateFormat(new Date(timeToDate), "MMMM dd, yyyy");
                            setEventsArray([...dataArray]);
                        }
                    }
                }
            ).catch(
                err => {
                }
            )

            setCurrentDate(response)

        }, []
    )

    const handleCbCurrentEventDate = (childData: VisorCalendar) =>{
        if(childData)
            props.cbCurrentEventDate(childData);
    }


    return (
        <div className="wrapper-kekax-calendar">
            <CalendarHeader cbCurrentDate={cbCurrentDateFunction} />
            <CalendarMain eventsArray={eventsArray} currentDate={cbCurrentDate} cbCurrentEventDate={handleCbCurrentEventDate} />
        </div>
    );

}

export default connect(mapStateToProps)(Calendar);
