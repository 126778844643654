import React from 'react'
import "./GraphMine.css"

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Line, LineChart } from 'recharts';

interface IProps {

}


const data = [
    {
        name: 'date 1', clicks: 10
    },
    {
        name: 'date 2', clicks: 20
    },
    {
        name: 'date 3', clicks: 70
    },
    {
        name: 'date 4', clicks: 100
    },
    {
        name: 'date 5', clicks: 2
    }
];

export const GraphMine: React.FC<IProps> = (props) => {

    return (

        <div className="wrapper-mine-graph">
            <div className="container-graph-display">
                <div className="main-graph">
                    <ResponsiveContainer>
                        <LineChart
                            data={data}
                            margin={{
                                top: 0, right: 0, left: 0, bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Legend verticalAlign="bottom" align="right" />
                            <Line type="monotone" dataKey="clicks" stroke="#B1BB97" />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer >
                </div>

            </div>
        </div>
    );

}


export default GraphMine;

