import React, { useState, useEffect } from 'react';
import "./EventPage.css"
import { connect } from 'react-redux';
import { mapStateLanguageToProps as mapStateToProps, StoreLanguageProps as StoreProps } from './../../../store/storeApp';
import { isSameDay } from "date-fns";
import { httpGetEventDetails, httpGetEventImg, httpGetEventContent, httpGetEventAttachList, getEventImageUrl, getEventAttachUrl, getEventGalleryUrl } from '../../../services/requestService';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import GeneralServices from '../../../services/generalService.json'
import ReactMarkdown from 'react-markdown/with-html';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { translateFormatTimestamp } from '../calendar/Calendar';

interface IProps extends StoreProps, RouteComponentProps<any> {
}

export interface IEventRes {
    contentList: ContentFile[]
    data: IEvent
    picList: GalleryFile[]

}


export interface IEvent {
    id: any
    tag: string
    title: string
    desc: string
    timestamp: any
    timestampEnd: any
    interest: number
    img: any
}

export interface AttachFile {
    content: any

    attachId: string
    eventId: string
    fileName: string
    path: string
}

export interface ContentFile {
    contentName: string
    eventId: string
    order: number
    views: number

    content: string
    gallery: GalleryFile[]
}



export interface GalleryFile {
    desc: string
    extension: string
    eventId: string
    order: number
    path: string
    picId: string

    content: string
}


/*
export const eventTagLanguage = (propsLanguage: StoreProps, idTag: string) => {
    switch (idTag) {
        case '0': {
            return propsLanguage.appLanguage.event.tag[idTag]
        }

        default: {
            return propsLanguage.appLanguage.event.tag[0]
        }
    }
}
*/

/*
const dummyEventObject: IEvent = {
    id: '0', tag: "0", title: "Event Title Main", desc: "Im a description", img: dummyEvent, timestamp: dateFormat(new Date(), "MMMM dd, yyyy"), timestampEnd: getTime(new Date()), interest: 1,
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
}
*/

export const EventPage: React.FC<IProps> = (props) => {
    const [eventDisplay, setEventDisplay] = useState<IEvent>({ id: null, tag: '', title: '', desc: '', img: null, timestamp: null, timestampEnd: null, interest: -1})
    const [contentArray, setContentArray] = useState<ContentFile[]>([{ contentName: '', eventId: '', order: 0, views: 0, content: '', gallery: [] }]);
    const [attachArray, setAttachArray] = useState<AttachFile[]>([{ content: null, attachId: '', eventId: '', fileName: '', path: '' }]);

    useEffect(() => {
        /*
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        */
        const eventId = props.match.params.id;
        httpGetEventDetails(eventId).then(
            async res => {
                let result: IEventRes = { ...res.data };
                let allContent: ContentFile[] = [...result.contentList];
                let allGallery: GalleryFile[] = [...result.picList];
                
                const eventImage = await httpGetEventImg(eventId).then(
                    res => {
                        return getEventImageUrl.replace('{event:id}', eventId);
                    }
                ).catch(
                    err => {
                        return null;
                    }
                )



                for (let i = 0; i < allContent.length; i++) {
                    let contentOrder = allContent[i].order;
                    let nextContentOrder = allContent[allContent.length - 1].order;

                    if (allContent[i + 1] !== undefined) {
                        nextContentOrder = allContent[i + 1].order
                    }


                    allContent[i].content = await httpGetEventContent(eventId, contentOrder.toString()).then(
                        res => {
                            allContent[i].gallery = []

                            for (let x = 0; x < allGallery.length; x++) {

                                if (allGallery[x].order > contentOrder && (allGallery[x].order < nextContentOrder || contentOrder === nextContentOrder)) {

                                    allGallery[x].content = getEventGalleryUrl.replace('{gallery:id}', allGallery[x].picId)
                                    allContent[i].gallery.push(allGallery[x])
                                }
                            }

                            return res.data;
                        }
                    ).catch(
                        err => {
                            return [];
                        }
                    )
                }


                const eventAttach = await httpGetEventAttachList('?eventId=' + eventId).then(
                    async res => {
                        let eventAttachRes: AttachFile[] = [];
                        for (let i = 0; i < eventAttachRes.length; i++) {
                            eventAttachRes[i].content = getEventAttachUrl.replace('{attach:id}', eventAttachRes[i].attachId)
                        }
                        return eventAttachRes;
                    }
                ).catch(
                    err => {
                        return [];
                    }
                )

                
                setEventDisplay({ ...result.data, img: eventImage});
                setContentArray([...allContent]);
                setAttachArray([...eventAttach])

            }
        ).catch(
            err => {
                props.history.push(GeneralServices.calendarEventsPath);
                //setEventDisplay({ ...dummyEventObject, timestamp: dummyEventObject.timestamp })
            }
        )
    }, [])

    const handleSameDate = (start: any, end: any) => {
        if (isSameDay(start, end)) {
            return true
        }
        return false;
    }



    return (
        <div className="wrapper-event-page">
            {
                eventDisplay.id !== null ?
                    <React.Fragment>
                        <div className="header-event-page">
                            <span className="header-event-page-title">
                                {eventDisplay.title}
                            </span>
                            <div className="header-event-page-sub">
                                <span className="event-page-sub-datestart">{translateFormatTimestamp(eventDisplay.timestamp,props.appLanguage)}</span>
                                {
                                    !handleSameDate(eventDisplay.timestamp, eventDisplay.timestampEnd) ?
                                        <React.Fragment>
                                            <span className="event-page-sub-dot">•</span>
                                            <span className="event-page-sub-dateend">{translateFormatTimestamp(eventDisplay.timestampEnd, props.appLanguage)}</span>
                                        </React.Fragment>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="body-event-page">
                            {
                                contentArray && contentArray.map((contentBlock: ContentFile, index1: number) => (
                                    <div key={"content" + index1}>
                                        <div className="body-event-page-content">
                                            <span>
                                                <ReactMarkdown source={contentBlock.content+""} escapeHtml={false} />
                                            </span>
                                        </div>
                                        <div className="body-event-page-gallery">
                                            {
                                                (contentBlock.gallery.map((file: any, index2) =>
                                                    <div className="media-object" key={"gallery" + index2}>
                                                        <div className="media-object-img">
                                                            <img src={file.content} alt="media-event" />
                                                            <div className="media-object-legend">
                                                                <span>{file.desc}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))

                            }

                            <div className="body-event-page-attach">
                                {
                                    attachArray.length > 0 ?
                                        (attachArray.map((file: any, index) =>
                                            <div key={index} className="attach-object">
                                                <div className="attach-icon"><PictureAsPdfIcon className="ayy-icon" /></div>
                                                <div className="attach-name"><span>{file.name}</span></div>
                                                <div className="attach-menu">
                                                    <div className="attach-menu-option-show">
                                                        <a href={file.content} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                                                            <VisibilityIcon className="byy-icon" />
                                                        </a>
                                                    </div>
                                                    <div className="attach-menu-option-download">
                                                        <a href={file.content} download={file.name} style={{ color: 'black' }}>
                                                            <GetAppIcon className="byy-icon" />
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                        : null
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    : null
            }
        </div>
    );


}


export default connect(mapStateToProps)(withRouter(EventPage));

/*

                    {
                        eventDisplay.img !== null && galleryArray.length <= 0 ?
                            <div className="body-event-page-media">
                                <img src={eventDisplay.img} alt="object-event" />
                            </div>
                            : null
                    }

*/