import React, { useEffect, useRef } from 'react'
import './WannaPlayModal.css';

import { StoreLanguageProps as StoreProps } from '../../../store/storeApp';
import { IMineInfoForList } from '../GamePage';
import GeneralServices from '../../../services/generalService.json'
import { RouteComponentProps } from 'react-router-dom';


interface IProps extends StoreProps, RouteComponentProps<any> {
    open: boolean
    what: IMineInfoForList
    callback: any
}

export const WannaPlayModal: React.FC<IProps> = (props) => {


    const modalRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const overlayRef: any = useRef(null);


    useEffect(() => {

        if (props.open && modalRef) {
            modalRef.current.classList.add("wannaPlay-modal-open");
            document.addEventListener('mousedown', handleClickAway);
            document.addEventListener('scroll', handleOffset);

            handleOffset();
        } else {
            modalRef.current.classList.remove("wannaPlay-modal-open");

        }
    }, [props.open])



    const handleOffset = () => {
        const posY: any = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.setProperty("--wannaPlayModal-topOffset", posY + "px");
    }

    const handleClickAway = (event: any) => {
        /*
        if (contentRef.current !== null && !contentRef.current.contains(event.target)) {
            closeModalHandler(false);
        }
        */
        if (event.target.contains(overlayRef.current)) {
            closeModalHandler(false);
        }
    }


    const closeModalHandler = (feedback: boolean) => {

        modalRef.current.classList.remove("wannaPlay-modal-open");
        document.removeEventListener('mousedown', handleClickAway);
        document.removeEventListener('scroll', handleOffset);


        feedback ? props.callback('worked') : props.callback('closed');
    }


    const playGameHandler = () => {
        if(props.what.quizz){
            const quizPageUrl = GeneralServices.quizPagePath.replace(":id", props.what.mineCode)
            props.history.push(quizPageUrl);
        }else{
            const quizPageUrl = GeneralServices.quizPagePath.replace(":id", "general")
            props.history.push(quizPageUrl);
        }

    }




    return (


        <div className="wrapper-wannaPlay-modal" ref={modalRef}>
            <div className="overlay" ref={overlayRef}></div>
            <div className="content" ref={contentRef}>
                <div className="header">
                    <div className="header-content">
                        <span className="modal-title">{props.appLanguage.gamepage.wannaPlay.title}</span>
                        <div className="close"><button className="close-btn" onClick={() => closeModalHandler(false)}><div className="img"></div></button></div>
                    </div>
                    <hr></hr>
                </div>
                <div className="body">
                    <div className="body-info">
                        <div className="info-name">
                            <span>{props.appLanguage.gamepage.wannaPlay.mine}</span>
                            <span>{props.what.name}</span>
                        </div>
                        <div className="info-country">
                            <span>{props.appLanguage.gamepage.wannaPlay.country}</span>
                            <span>{props.what.country}</span>
                        </div>

                    </div>

                    <div className="body-menu">
                        <span>{props.appLanguage.gamepage.wannaPlay.question}</span>

                        <div className="menu-options">
                            <div onClick={playGameHandler}>
                                <span>{props.appLanguage.gamepage.wannaPlay.yes}</span>
                            </div>
                            <div onClick={() => closeModalHandler(false)}>
                                <span>{props.appLanguage.gamepage.wannaPlay.no}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <hr></hr>
                </div>
            </div>
        </div>
    )

}


export default WannaPlayModal;
